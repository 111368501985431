<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            v-model:validation-errors="validationErrors"
            :strikethrough-if-true="['inactive']"
            target-inactive-column="inactive"
            focus-after-insert="none"
            v-model:search-value="searchText"
            @activate="onActivateItem"
            integrated-search
            rq-filters
            rq-editable
            show-include-inactive
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { MoneyMovementApprovalThresholdDto }  from "../models";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { MoneyMovementType } from '@config/enums';
    import { h } from "vue";

    export default {
        name:"MoneyMovementApprovalThresholdList",
        mixins: [GridSystemLookupMixin],
        data () {
            return {
                items: [],
                selectedItem: {},
                validationErrors: [],
                addEventName: "",
                validationContext: {},
                searchText: ""
            };
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user,
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            moneyMovementType(){ return MoneyMovementType.lookupItems; },
            itemKey() { return _.get(this.$route.meta, "itemKey") || ""; },
            itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            itemTypeDescription() { return _.get(this.$route.meta, "itemTypeDescription") || ""; },
            regions() { return this.lookupHelpers.getRegions(); },
            gridInstance() { return _.get(this.$refs, "dataGrid.gridInstance", null); },
            selectionActions() {
                return [
                    //{ name: "delete", text: "Delete", eventName: "delete", allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}` },
                    { name: "activate", text: "Activate", eventName: "activate", requireSelection: true, tooltip: `Activate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return !_.every(e.data, ['inactive', true]); } },
                    { name: "inactivate", text: "Inactivate", eventName: "activate", requireSelection: true, tooltip: `Inactivate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return !_.every(e.data, ['inactive', false]); }  }
                ];
            },
        },

        watch: {
            validationErrors(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.$events.emit("update-config-error", { message: _.isEmpty(newValue) ? "" : "Please correct the highlighted errors on screen to continue.", hasError: !_.isEmpty(newValue) });
            }
        },

        created(){
            const self = this;
            self.fetchData();
            self.initGridConfig();
            self.initListeners();
        },

        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },

        methods: {
            onActivateItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 1
                    ? self.itemTypeNamePlural
                    : self.itemTypeName;
                let verb = _.every(items, ['inactive', true]) ? "Activate" : "Inactivate";

                let okHandler = function (args) {
                    let keys = _.map(items, self.itemKey);
                    self.activate(keys, verb);
                    return true;
                }

                self.$dialog.confirm(
                    `Confirm ${verb}`,
                    `Are you sure you want to ${verb} the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            activate(keys, verb) {
                const self = this;
                let apiPromise = self.$api.MoneyMovementApprovalThresholdsApi.activateMoneyMovementApprovalThresholds(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.fetchData();
                        let message = keys.length > 1
                            ? `${keys.length} ${self.itemTypeNamePlural} were ${verb}d.`
                            : `${self.itemTypeName} was ${verb}d.`
                        self.$toast.success(message);
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error(`Error trying to ${verb} ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },
            initGridConfig(){
                const self = this;
                let uniqueThresholdRule = {
                    type: "custom",
                    message: "The selected region/type combination already exists.",
                    validationCallback: params => !self.thresholdExists(params.data)
                };
                self.gridConfig = {
                    sorting: { enabled: true },
                    columns: [
                        {
                            dataField: "moneyMovementTypeID",
                            dataType: "number",
                            caption: "Type",
                            setCellValue(rowData, value) {
                                rowData.moneyMovementTypeID = value;
                            },
                            lookup: {
                                dataSource: self.moneyMovementType,
                                displayExpr: "name",
                                valueExpr: "id",
                            },
                            validationRules: [{ type: "required" }, uniqueThresholdRule]
                        },
                        {
                            dataField: "regionList",
                            dataType: "object",
                            caption: "Region",
                            width: 300,
                            minWidth: 200,
                            calculateSortValue: rowData => {
                                let regionNames = _.sortBy(_.map(_.filter(self.regions, r => _.includes(rowData.regionList, r.regionID)), "description"));
                                return _.join(regionNames, "");
                            },
                            setCellValue(rowData, value) {
                                rowData.regionList = value.regionList;
                                rowData.allRegions = value.allRegions;
                            },
                            calculateFilterExpression: (filterValue, operator) => {
                                if (_.isString(filterValue)) {
                                    return rowData => {
                                        let regionNames = _.sortBy(_.map(_.filter(self.regions, r => _.includes(rowData.regionList, r.regionID)), "description"));
                                        return _.includes(_.toLower(regionNames), _.toLower(filterValue))
                                    }
                                }
                                if(operator !== "contains") return () => false;
                            
                                return rowData => {
                                        let ids = rowData.allRegions
                                        ? _.map(self.regions, "regionID")
                                        : rowData.regionList;
                                        return _.includes(ids, filterValue);
                                }
                            },
                            rqFilter: {
                                valueExpr: "regionID",
                                displayExpr: "description",
                                dataSource: _.sortBy(self.regions, "description"),
                                listOperator: "and",
                                valueOperator: "contains",
                            },
                            cellTemplate: function(cellElement, cellInfo) {
                                if(_.isEmpty(cellInfo.value) && !cellInfo.data.allRegions) return;
                                const searchHighlightTemplate = '<span class="dx-datagrid-search-text">$&</span>';
                                let displayText = self.getRegionDisplayText(cellInfo.data);
                                let searchHighlighted = _.isEmpty(_.trim(self.searchText)) ? displayText : _.replace(displayText, new RegExp(self.searchText, "ig"), searchHighlightTemplate);
                                $("<span />")
                                    .addClass("text-truncate")
                                    .attr("title", displayText)
                                    .append(searchHighlighted)
                                    .appendTo(cellElement);
                            },
                            editCellTemplate: function(cellElement, cellInfo) {
                                let initialValue = (cellInfo.data.allRegions) ? _.map(self.regions, "regionID") : cellInfo.value;
                                $("<div />").dxTagBox({
                                    dataSource: {
                                        loadMode: "raw",
                                        load() {
                                            return self.regions;
                                        }
                                    },
                                    displayExpr: "description",
                                    valueExpr: "regionID",
                                    value: initialValue,
                                    showSelectionControls: true,
                                    showDropDownButton: true,
                                    applyValueMode: "useButtons",
                                    maxDisplayedTags: 1,
                                    onValueChanged: function(e) {
                                        cellInfo.setValue({
                                            regionList: e.value,
                                            allRegions: e.value.length === self.regions.length
                                        });
                                    }
                                }).appendTo(cellElement);
                            },
                            validationRules: [{
                                type: "custom",
                                message: "Region is required.",
                                validationCallback: e => _.parseBool(e.data.allRegions) || !_.isEmpty(e.data.regionList)
                            }, uniqueThresholdRule],
                        },
                        {
                            dataField: "thresholdAmount",
                            caption: "Threshold",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            validationRules: [{ type: "required" }]
                        },
                        {
                            dataField: "inactive",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate
                        }
                    ],
                    onInitNewRow: e => {
                        e.data.inactive = false;
                        e.data.thresholdAmount = 0;
                    },
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },

            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },

            fetchData() {
                const self = this;

                let apiPromise = self.$api.MoneyMovementApprovalThresholdsApi.getAll();

                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new MoneyMovementApprovalThresholdDto(i));
                    })
                    .catch(error => {
                        self.items = [];
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            getRegionDisplayText(item) {
                const self = this;
                if(_.isEmpty(item.regionList) && !item.allRegions) return;
                let selectedRegions = item.allRegions ?
                    self.regions :
                    _.filter(self.regions, r => _.includes(item.regionList, r.regionID));
                return selectedRegions.length <= 2
                    ? _.joinParts(_.map(selectedRegions, "description"), ", ")
                    : `${item.allRegions ? 'All' : selectedRegions.length} Regions Selected`;
            },

            onAddItem() {
                if(!this.gridInstance) return;
                this.gridInstance.addRow();
            },

            onGridInsert(values) {
                const self = this;
                let originalItem = new MoneyMovementApprovalThresholdDto();
                let newItem = new MoneyMovementApprovalThresholdDto(values);
                let changes = self.getAuditChanges(originalItem.toDataObject(), newItem.toDataObject());
                return self.save(newItem, changes)
                    .then(result => {
                        self.fetchData();
                    });
            },

            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item.moneyMovementApprovalThresholdID === key);
                if(itemIndex < 0) return self.onGridInsert(values);

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new MoneyMovementApprovalThresholdDto(_.assign({}, self.items[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                let saveChanges = function() {
                    self.save(updatedItem, changes)
                        .then(result => {
                            self.fetchData();
                        });
                }

                saveChanges();
            },

            // onDeleteItem(e) {
            //     if(!e || !e.data) return;
            //     const self = this;
            //     let items = e.data;
            //     let itemLabel = items.length > 1
            //         ? self.itemTypeNamePlural
            //         : self.itemTypeName;

            //     let okHandler = function (args) {
            //         let keys = _.map(items, self.itemKey);
            //         self.delete(keys);
            //         return true;
            //     }

            //     self.$dialog.confirm(
            //         "Confirm Delete",
            //         `Are you sure you want to delete the selected ${itemLabel}?`,
            //         okHandler,
            //         null, { cancelTitle: 'No', okTitle: 'Yes'});
            // },

            save(item, changes){
                const self = this;
                if (changes.length == 0) {
                    return Promise.resolve(item);
                }
                let apiPromise = self.$api.MoneyMovementApprovalThresholdsApi.saveMoneyMovementApprovalThreshold(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$toast.success(`${self.itemTypeName} was saved.`);
                        return result;
                    }).catch(error => {
                        self.$toast.error({ message: `Error saving ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },

            // delete(keys) {
            //     const self = this;
            //     let apiPromise = self.$api.MoneyMovementApprovalThresholdsApi.deleteMoneyMovementApprovalThresholds(keys);
            //     return self.$rqBusy.wait(apiPromise)
            //         .then(() => {
            //             self.removeItems(keys);
            //             let message = keys.length > 1
            //                 ? `${keys.length} ${self.itemTypeNamePlural} were deleted.`
            //                 : `${self.itemTypeName} was deleted.`
            //             self.$toast.success(message);
            //             return true;
            //         })
            //         .catch(error => {
            //             if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
            //                 self.$dialog.messageBox(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
            //             } else {
            //                 self.$toast.error(`Error deleting ${self.itemTypeName}.`);
            //             }
            //             console.error(error);
            //             return error;
            //         });
            // },

            thresholdExists(targetItem) {
                const self = this;
                return _.some(self.items, i => 
                    i.moneyMovementApprovalThresholdID !== targetItem.moneyMovementApprovalThresholdID
                    // && !i.inactive
                    && i.moneyMovementTypeID === targetItem.moneyMovementTypeID
                    && (i.allRegions ||
                        targetItem.allRegions ||
                        (_.intersection(i.regionList, targetItem.regionList).length > 0))
                );
            },

            refresh() {
                if(_.isNull(this.gridInstance)) return;
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },

            removeItems(keys) {
                const self = this;
                _.forEach(keys, key => {
                    let itemIndex = _.findIndex(self.items, item => item[self.itemKey] === key);
                    if(itemIndex >= 0) self.items.splice(itemIndex, 1);
                });
                self.refresh();
            }
        }
    }
</script>
