import { Categories } from "./categories"
import { Topics } from "../topics"
import FundTypeList from "./components/FundTypeList";
import EscrowAccountSettingsForm from "./components/EscrowAccountSettingsForm";
import CompanyList from "../enterprise/components/CompanyList";
import AutoReconMappingsList from "./components/AutoReconMappingsList";
import RequiredWireFieldsForm from "./components/RequiredWireFieldsForm";
import AutoConsolidatePayeesList from "./components/AutoConsolidatePayeesList";
import CheckCoverLettersList from './components/CheckCoverLettersList';
import MoneyMovementApprovalRangeList from "./components/MoneyMovementApprovalRangeList";
import MoneyMovementApprovalThresholdList from "./components/MoneyMovementApprovalThresholdList";
import LossAdvanceReasonCategoryList from "./components/LossAdvanceReasonCategoryList";
import LossAdvanceReasonList from "./components/LossAdvanceReasonList";
import EscrowUnitsList from "./components/EscrowUnitsList";
import PositivePayExportFormatViewer from "./components/PositivePayExportFormatViewer";
import LossAdvanceSettingsForm from "./components/LossAdvanceSettingsForm";
import EscheatDormancyPeriodList from "./components/EscheatDormancyPeriodList";

import {ConfigurationActions} from "../ConfigurationActions";

import LookupNames from "@/store/lookupNames";

let routes = [

    {
        name: "cfg:chk:settings",
        path: "settings",
        component: EscrowAccountSettingsForm,
        meta: {
            label: "Check Writing Settings",
            itemKey: "",
            itemTypeName: "Check Writing Setting",
            itemTypeDescription: "Configure system level escrow account and Check Writing settings.",
            skipSaveOnLeave: false,
            topic: Topics.CheckWritingEscrow,
            category: Categories.CheckWriting,
            scrollLayout: true,
            actions: ConfigurationActions.MAIN,
            sysAdminRequired: true
        }
    },
    {
        name: "cfg:ent:fundtype",
        path: "fundtype",
        component: FundTypeList,
        meta: {
            label: "Type of Funds",
            itemKey: "typeFundID",
            itemTypeName: "Type of Fund",
            itemTypeDescription: "Define the types of funds to display in Check Writing.",
            skipSaveOnLeave: true,
            topic: Topics.CheckWritingEscrow,
            category: Categories.CheckWriting,
            refreshLookup: LookupNames.TYPE_FUNDS,
            sysAdminRequired: true
        }
    },
    {
        name: "cfg:chk:escrowAccounts",
        path: "escrowAccounts",
        component: CompanyList,
        meta: {
            label: "Escrow Accounts",
            itemKey: "",
            itemTypeName: "Escrow Account",
            itemTypeDescription: "Define escrow accounts to be used on files.",
            skipSaveOnLeave: true,
            topic: Topics.CheckWritingEscrow,
            category: Categories.CheckWriting,
            refreshLookup: [LookupNames.ESCROW_ACCOUNTS, LookupNames.USER_ESCROW_ACCOUNTS]
        },
        props: { roleTypeId: 1 }
    },
    {
        name: "cfg:chk:escrowUnits",
        path: "escrowUnits",
        component: EscrowUnitsList,
        meta: {
            label: "Escrow Units",
            itemKey: "escrowUnitID",
            itemTypeName: "Escrow Unit",
            itemTypeDescription: "Define escrow units to represent groups or teams that can control the file pool and check number ranges assigned to new files.",
            skipSaveOnLeave: true,
            topic: Topics.CheckWritingEscrow,
            category: Categories.CheckWriting,
            refreshLookup: LookupNames.ESCROW_UNITS
        }
    },
    {
        name: "cfg:chk:consolidatedPayees",
        path: "consolidatedPayees",
        component: AutoConsolidatePayeesList,
        meta: {
            label: "Auto-consolidate Payees",
            itemKey: "autoConsolidatePayeeID",
            itemTypeName: "Auto-consolidate Payee",
            itemTypeDescription: "Configure the auto-consolidated payees for consolidated checks.",
            skipSaveOnLeave: true,
            topic: Topics.CheckWritingEscrow,
            category: Categories.CheckWriting,
            refreshLookup: LookupNames.AUTO_CONSOLIDATE_PAYEES
        }
    },
    {
        name: "cfg:chk:checkCoverLetters",
        path: "checkCoverLetters",
        component: CheckCoverLettersList,
        meta: {
            label: "Check Cover Letters",
            itemKey: "checkCoverLetterID",
            itemTypeName: "Check Cover Letter",
            itemTypeDescription: "Configure cover letter generation based on a specific payee type or for a specific payoff type and template.",
            skipSaveOnLeave: true,
            topic: Topics.CheckWritingEscrow,
            category: Categories.CheckWriting,
            refreshLookup: LookupNames.CHECK_COVER_LETTERS
        }
    },
    {
        name: "cfg:chk:wireRequired",
        path: "requiredWire",
        component: RequiredWireFieldsForm,
        meta: {
            label: "Required Wire Fields",
            itemKey: "",
            itemTypeName: "Required Wire Field",
            itemTypeDescription: "Define the fields that are required for user to disburse wire funds.",
            topic: Topics.CheckWritingEscrow,
            category: Categories.CheckWriting,
            scrollLayout: true,
            sysAdminRequired: true
        }
    },
    {
        name: "cfg:chk:eBankRecon",
        path: "eBankRecon",
        component: AutoReconMappingsList,
        meta: {
            label: "eBank Reconciliation",
            itemKey: "autoReconMappingID",
            itemTypeName: "eBank Reconciliation",
            itemTypeDescription: "Allows the user to import BAI files",
            skipSaveOnLeave: true,
            topic: Topics.CheckWritingEscrow,
            category: Categories.CheckWriting,
            sysAdminRequired: true,
            refreshLookup: LookupNames.USER_ESCROW_ACCOUNTS
        }
    },
    {
        name: "cfg:chk:positivePayExportFormats",
        path: "positivePayExportFormats",
        component: PositivePayExportFormatViewer,
        meta: {
            label: "Positive Pay Export Formats",
            itemKey: "",
            itemTypeName: "Positive Pay Export Format",
            itemTypeDescription: "View Positive Pay Export Formats",
            skipSaveOnLeave: true,
            topic: Topics.CheckWritingEscrow,
            category: Categories.CheckWriting
        }
    },
    {
        name: "cfg:chk:approvalThresholds",
        path: "approvalThresholds",
        component: MoneyMovementApprovalThresholdList,
        meta: {
            label: "Approval Thresholds",
            itemKey: "moneyMovementApprovalThresholdID",
            itemTypeName: "Approval Threshold",
            itemTypeDescription: "Configure the dollar amount for each type of financial approval that requires approval.",
            skipSaveOnLeave: true,
            topic: Topics.CheckWritingEscrow,
            category: Categories.CheckWriting,
        }
    },
    {
        name: "cfg:chk:approvalRanges",
        path: "approvalRanges",
        component: MoneyMovementApprovalRangeList,
        meta: {
            label: "Approval Ranges",
            itemKey: "moneyMovementApprovalRangeID",
            itemTypeName: "Approval Range",
            itemTypeDescription: "Configure the dollar range of users for approving financials.",
            skipSaveOnLeave: true,
            topic: Topics.CheckWritingEscrow,
            category: Categories.CheckWriting,
        }
    },
    {
        name: "cfg:chk:lossAdvanceReasonCategories",
        path: "lossAdvanceReasonCategories",
        component: LossAdvanceReasonCategoryList,
        meta: {
            label: "Loss/Advance Categories",
            itemKey: "lossAdvanceReasonCategoryID",
            itemTypeName: "Loss/Advance Category",
            itemTypeDescription: "Configure Loss/Advance categories.",
            skipSaveOnLeave: true,
            topic: Topics.CheckWritingEscrow,
            category: Categories.LossAdvance,
            sysAdminRequired: true
        }
    },
    {
        name: "cfg:chk:lossAdvanceReasons",
        path: "lossAdvanceReasons",
        component: LossAdvanceReasonList,
        meta: {
            label: "Loss/Advance Reasons",
            itemKey: "lossAdvanceReasonID",
            itemTypeName: "Loss/Advance Reason",
            itemTypeDescription: "Configure Loss/Advance reasons.",
            skipSaveOnLeave: true,
            topic: Topics.CheckWritingEscrow,
            category: Categories.LossAdvance,
            sysAdminRequired: true
        }
    },
    // hiding per RQO-25585, don't delete just yet
    // {
    //     name: "cfg:chk:lossAdvanceSettings",
    //     path: "lossadvance",
    //     component: LossAdvanceSettingsForm,
    //     meta: {
    //         label: "Loss/Advance Settings",
    //         itemKey: "",
    //         itemTypeName: "Loss/Advance Settings",
    //         itemTypeDescription: "Configure Loss/Advance settings.",
    //         skipSaveOnLeave: false,
    //         topic: Topics.CheckWritingEscrow,
    //         category: Categories.LossAdvance,
    //         scrollLayout: true,
    //         actions: ConfigurationActions.MAIN,
    //         sysAdminRequired: true
    //     }
    // },
    {
        name: "cfg:chk:escheatDormancyPeriod",
        path: "escheatDormancyPeriod",
        component: EscheatDormancyPeriodList,
        meta: {
            label: "Escheat Dormancy Periods",
            itemKey: "escheatDormancyPeriodID",
            itemTypeName: "Escheat Dormancy Period",
            itemTypeDescription: "Configure the escheat dormancy periods.",
            skipSaveOnLeave: true,
            topic: Topics.CheckWritingEscrow,
            category: Categories.CheckWriting,
        }
    },

];

export default routes;
