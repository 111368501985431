import AutomationMain from "./AutomationMain.vue";
import Routes from "./routes";

export default {
    path: "/configuration/automation",
    component: AutomationMain,
    meta: {
        label: "Automation",
        include: true,
        skipSaveOnLeave: true
    },
    children: Routes
};