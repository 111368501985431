<template>
    <div class="content-wrapper">
        <rq-banner
            variant="error"
            :message="uploadFormatErrorMessage"
            icon="fas fa-exclamation-triangle"
            :visible="showUploadFormatError"
        />
        <rq-banner
            variant="warn"
            :message="importMessage"
            icon="fas fa-exclamation-triangle"
            :visible="showImportMessage"
            @dismiss="onDismissImportMessage"
            dismissable
        />
        <rq-page-section :title="itemTypeNamePlural" headerSize="lg" borderless>
            <template #header-actions>
                <ul class="nav navbar-nav me-auto">
                    <li class="nav-item">
                        <b-btn variant="theme" @click="onLoadFileClick">Load Import File (*{{$utils.lodash.toUpper(uploadFileFormat)}})</b-btn>
                    </li>
                    <form ref="fileForm" enctype="multipart/form-data" novalidate v-show="false">
                        <input
                            ref="fileInput"
                            type="file"
                            automation_id="txt_upload_file"
                            name="imports"
                            @change="onUploadFileChange"
                            :accept="uploadFileFormat"
                        >
                    </form>
                </ul>
            </template>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
        </rq-page-section>
        <RqTabs
            :tabs="tabItems"
            v-model="selectedTabIndex"
            @activate-tab="onActivateTab">
            <template #rates>
                <import-manager
                    ref="rateImportManager"
                    automation_id="tbl_rate_import"
                    title="Import Rates"
                    :import-type="1"
                    @xml-loaded="onXmlLoaded"
                    @queued="onQueued"
                />
            </template>
            <template #endorsements>
                <import-manager
                    ref="endorsementImportManager"
                    automation_id="tbl_endorsement_import"
                    title="Import Endorsements"
                    :import-type="2"
                    @xml-loaded="onXmlLoaded"
                    @queued="onQueued"
                />
            </template>
        </RqTabs>
    </div>
</template>

<script>
    import ImportManager from "./ImportManager";
    export default {
        name: "ImportUpdateList",
        components: { ImportManager },
        data() {
            return {
                selectedTabIndex: 0,
                uploadFormatErrorMessage: "",
                importMessage: "",
            };
        },
        computed:{
            itemTypeNamePlural() { return _.get(this, "$route.meta.label") || ""; },
            itemTypeDescription() { return _.get(this, "$route.meta.itemTypeDescription") || ""; },
            isRateView() { return this.selectedTabIndex === 0; },
            uploadFileFormat() { return this.selectedTabIndex === 0 ? ".rqr" : ".rqe"; },
            showUploadFormatError() { return !_.isEmpty(this.uploadFormatErrorMessage); },
            showImportMessage() { return !_.isEmpty(this.importMessage); },
            tabItems() {
                const self = this;
                return [
                    { automation_id: "btn_tab_rates", name: "rates", title: "Rates" },
                    { automation_id: "btn_tab_endorsements", name: "endorsements", title: "Endorsements" }
                ];
            }
        },
        methods: {
            onLoadFileClick () {
                this.clearFileInput();
                this.$nextTick(() => {
                    this.uploadFormatErrorMessage = "";
                    _.invoke(this, "$refs.fileInput.click");
                });
            },

            onActivateTab() {
                this.clearFileInput();
            },

            onXmlLoaded() {
                this.clearFileInput();
            },

            onUploadFileChange (e) {
                const self = this;
                let files = e.target.files;
                if (_.isEmpty(files)) {
                    self.clearFileInput();
                    return;
                }
                if(!files[0].name.includes(self.uploadFileFormat)) {
                    self.uploadFormatErrorMessage = `Cannot load selected file.  For ${self.isRateView ? "rates" : "endorsements"}, only files with the extension, <em>${self.uploadFileFormat}</em>, can be loaded.`;
                    return;
                }
                let reader = new FileReader();
                reader.onload = function (evt) {
                    self.loadXml(evt.target.result);
                }
                reader.readAsText(files[0]);
            },

            loadXml(xml) {
                let componentName = this.isRateView ? "rateImportManager" : "endorsementImportManager";
                _.invoke(this, `$refs.${componentName}.loadXml`, xml);
            },

            clearFileInput() {
                this.uploadFormatErrorMessage = "";
                if(this.$refs?.fileInput?.value)
                    this.$refs.fileInput.value = null;
                _.invoke(this, "$refs.fileForm.reset");
            },

            onDismissImportMessage() {
                this.importMessage = "";
            },

            onQueued({ success, message }) {
                const self = this;

                self.onDismissImportMessage();

                if (!success) return;

                self.$nextTick()
                    .then(() => {
                        self.importMessage = message;
                    });
            }
        }
    }
</script>