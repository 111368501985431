<template>
    <div class="content-wrapper user-sec-lists">
        <rq-banner
            variant="error"
            title="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="showRequiredErrorBanner"
        />
        <rq-banner
            variant="warning"
            :title="licensedUsersAlertMessage"
            icon="fas fa-exclamation-triangle"
            :visible="showLicensedUsersWarning"
        />
        <rq-page-section title="Users &amp; Security Roles" headerSize="lg" borderless header-only>
            <template #header-actions>
                <div class="rq-section-widget-aside">
                    <LicensedUsersWidget
                        ref="licensedUsersWidget"
                        v-model:alert-message="licensedUsersAlertMessage"
                    />
                </div>
            </template>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">Configure one or more Users or Security Roles</div>
            </template>
        </rq-page-section>
        <rq-tabs
            :tabs="tabItems"
            v-model="tab">
            <template #userItemNamePlural>
                <div class="rq-container">
                    <div class="row">
                        <div class="col col-6 form-group">
                            <label for="grid_grouping">Group By</label>
                            <rq-radio-group
                                class="mt-1"
                                id="grid_grouping"
                                @change="onChangeUserGridGroupBy"
                                v-model="groupUsersBy"
                                :inline="true"
                                :options="[
                                    { automation_id: 'radio_name', value: '', text: 'Name' },
                                    { automation_id: 'radio_region', value: 'regionDisplay', text: 'Region' },
                                    { automation_id: 'radio_role', value: 'groupDisplay', text: 'Security Role' },
                                    { automation_id: 'radio_branch', value: 'branchDisplay', text: 'Branch' },
                                ]"
                            />
                        </div>
                    </div>
                </div>
                <rqdx-action-data-grid
                    ref="userDataGrid"
                    :automation_id="elementName('tbl', userItemNamePlural)"
                    :actions="userSelectionActions"
                    :config="userGridConfig"
                    :data-source="userGridDataSource"
                    :export-file-name="elementName('', userItemNamePlural, 'data')"
                    :strikethrough-if-true="['isInactive']"
                    target-inactive-column="isInactive"
                    v-model:validation-errors="validationErrors"
                    @rowDoubleClick="onEditUser"
                    @activate="onActivate($event, 'user', 'Activate')"
                    @inactivate="onActivate($event, 'user', 'Inactivate')"
                    @copy="onCopyUser"
                    @edit="onEditUser"
                    show-include-inactive
                    integrated-search
                    rq-filters>
                    <template #toolbar>
                        <div class="rq-title navbar-text me-auto">
                            {{userItemNamePlural}}
                            <button
                                type="button"
                                class="btn btn-sm btn-theme ms-2"
                                variant="theme"
                                @click="onAddUser">
                                Add
                            </button>
                        </div>
                    </template>
                </rqdx-action-data-grid>
            </template>
            <template #groupItemNamePlural>
                <rqdx-action-data-grid
                    ref="groupDataGrid"
                    :automation_id="elementName('tbl', groupItemNamePlural)"
                    :actions="groupSelectionActions"
                    :config="groupGridConfig"
                    :data-source="groupGridDataSource"
                    :export-file-name="elementName('', groupItemNamePlural, 'data')"
                    :strikethrough-if-true="['isInactive']"
                    target-inactive-column="isInactive"
                    v-model:validation-errors="validationErrors"
                    @activate="onActivate($event, 'group', 'Activate')"
                    @inactivate="onActivate($event, 'group', 'Inactivate')"
                    @copy="onCopyGroup"
                    @edit="onEditGroup"
                    show-include-inactive
                    integrated-search
                    hide-show-column-chooser
                    rq-filters
                    rq-editable>
                    <template #toolbar>
                        <div class="rq-title navbar-text me-auto">
                            {{groupItemNamePlural}}
                            <button
                                type="button"
                                class="btn btn-sm btn-theme ms-2"
                                variant="theme"
                                @click="onAddGroup">
                                Add
                            </button>
                        </div>
                    </template>
                </rqdx-action-data-grid>
            </template>
        </rq-tabs>
    </div>
</template>

<script>
    import { ref } from "vue";
    import { mapState, mapGetters } from "vuex";
    import { SET_SESSION_USER } from "@/store/mutations";
    import { UsersDto, UserGroupDto, UserDataDto }  from "../models";
    import UserForm  from "./UserForm.vue";
    import UserGroupCopyForm  from "./UserGroupCopyForm";
    import UserCopyForm from "./UserCopyForm";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { useRqTabAlerts } from "@/shared/composables/useRqTabAlerts";
    import { OnboardingRoles } from "@config/enums";
    import LicensedUsersWidget from "@/shared/components/widgets/LicensedUsersWidget.vue";

    export default {
        name:"UsersAndGroupsList",
        components: { LicensedUsersWidget },
        setup() {
            const validationErrors = ref([]);
            const alertMapping = {
                securityRolesTabAlert: { type: "list-ref", list: validationErrors }
            };
            const { securityRolesTabAlert } = useRqTabAlerts({ alertMapping });
            return {
                validationErrors,
                securityRolesTabAlert
            };
        },
        data () {
            return {
                users: [],
                groups: [],
                selectedItem: {},
                verifiedItem: {},
                groupUsersBy: "",
                searchText: "",
                tab: 0,
                licensedUsersAlertMessage: ""
            };
        },
        computed: {
            ...mapState({
                currentUser: state => state.authentication.session.user
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "regionsLookup"
            ]),
            groupGridInstance() { return _.get(this.$refs, "groupDataGrid.gridInstance", null) || {}; },
            userGridInstance() { return _.get(this.$refs, "userDataGrid.gridInstance", null) || {}; },
            showRequiredErrorBanner(){ return !_.isEmpty(this.validationErrors); },
            quickLaunchId() { return _.getNumber(this, "$route.params.openUserId", null) || 0; },
            tabItems() {
                const self = this;
                return [
                    { automation_id: "btn_tab_users", name: "userItemNamePlural", title: "Users" },
                    { automation_id: "btn_tab_groups", name: "groupItemNamePlural", title: "Security Roles", alertCount: this.securityRolesTabAlert.alertCount },
                ];
            },
            onboardingRoles() { return OnboardingRoles.lookupItems; },
            showLicensedUsersWarning() { return !_.isEmpty(this.licensedUsersAlertMessage); }
        },
        watch: {
            tab(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.$nextTick().then(() => {
                    if(newValue === 0)
                        this.userGridInstance.updateDimensions();
                    if(newValue === 1) {
                        this.userGridInstance.hideColumnChooser();
                        this.groupGridInstance.updateDimensions();
                    }
                });
            }
        },
        created(){
            this.tab = _.parseNumber(_.get(this.$route, "params.tab", 0), 0);
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.initListeners();
            this.fetchData();
            if(this.quickLaunchId === 0) return;
            this.editUser(this.quickLaunchId);
        },
        beforeUnmount () {
            this.$events.off("add:config-user", this.onAddUser);
            this.$events.off("add:config-group", this.onAddGroup);
        },
        methods: {
            activate(keys, itemType, verb) {
                const self = this;
                let apiPromise = self.$api.UsersApi.activate(keys);
                let itemLabel = keys.length > 1
                    ? _.get(self, `${itemType}ItemNamePlural`)
                    : _.get(self, `${itemType}ItemName`);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.toggleInactiveFlag(keys, itemType);
                        self.refresh();
                        let message = keys.length > 1
                            ? `${keys.length} ${itemLabel} were ${verb}d.`
                            : `${itemLabel} was ${verb}d.`
                        self.$toast.success(message);
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error(`Error trying to ${verb} ${itemLabel}.`);
                        console.error(error);
                        return error;
                    });
            },

            addUser(user) {
                this.users.push(new UsersDto(user));
            },

            elementName(prefix="", item="", suffix="") { return _.snakeCase(`${prefix} ${item} ${suffix}`); },

            onActivate(e, itemType, verb) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 1
                    ? _.get(self, `${itemType}ItemNamePlural`)
                    : _.get(self, `${itemType}ItemName`);

                let okHandler = function (args) {
                    let keys = _.map(items, _.get(self, `${itemType}ItemKey`));
                    self.activate(keys, itemType, verb);
                    return true;
                }

                self.$dialog.confirm(
                    `Confirm ${verb}`,
                    `Are you sure you want to ${verb} the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onAddUser() {
                this.clearUsers();
                if (this.groups.length == 0) {
                    this.$toast.info({ message: `Please create a ${this.groupItemName} first.` });
                    return;
                }
                this.showUser(new UserDataDto());
            },

            onCopyUser(e) {
                this.showCopyUser(e.data);
            },

            onAddGroup() {
                if(!this.groupGridInstance) return;
                this.clearGroups();
                this.groupGridInstance.addRow();
            },

            onCopyGroup(e) {
                this.selectedItem = new UserGroupDto(e.data);
                this.showCopyGroup(new UserGroupDto(e.data));
            },

            onEditGroup(e) {
                this.clearGroups();
                this.$router.push({ name: 'cfg:ent:securityRole', params: { groupUsersID: e.data.groupUsersID, displayName: e.data.login }, meta:{ title: 'Edit Security Role: ' + e.data.login  } });
            },

            onEditUser(e) {
                const self = this;
                self.clearUsers();
                self.editUser(e.data.usersID);
            },

            onChangeUserGridGroupBy(e) {
                const self = this;
                self.groupUsersBy = e;
                let columns = self.userGridInstance.option("columns");
                _.each(columns, column => {
                    if (_.hasIn(column, 'groupIndex') && (column.dataField == self.groupUsersBy || column.calculateSortValue == self.groupUsersBy)) {
                        column.groupIndex = 0;
                    } else if (_.hasIn(column, 'groupIndex')) {
                        column.groupIndex = -1;
                    }
                });
                self.userGridInstance.beginUpdate();
                self.userGridInstance.option("columns", columns);
                self.userGridInstance.endUpdate();
            },

            onGroupGridInsert(values) {
                const self = this;
                let newItem = new UserGroupDto(values);
                return self.saveGroup(newItem, null)
                    .then(result => {
                        self.groups.push(new UserGroupDto(_.clone(result)));
                        self.refreshGroups();
                    });
            },

            onGroupGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.groups, item => _.getNumber(item, self.groupItemKey, -1) === key);
                if(itemIndex < 0) return self.onGroupGridInsert(values);

                let originalItem = _.cloneDeep(self.groups[itemIndex]);
                let updatedItem = new UserGroupDto(_.assign({}, self.groups[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                return self.saveGroup(updatedItem, changes)
                    .then(result => {
                        self.groups[itemIndex] = new UserGroupDto(_.clone(result));
                        self.refreshGroups();
                        self.fetchUserData();
                    });
            },

            initGridConfig(){
                const self = this;
                self.userGridConfig = {
                    columns: [
                        { dataField: self.userItemKey, visible: false},
                        { dataField: "fullName", dataType: "string", sortIndex: 0, sortOrder: "asc"},
                        { dataField: "displayName", dataType: "string" },
                        { dataField: "login", dataType: "string", visible: false },
                        { dataField: "groupUsersID", dataType: "number", visible: false },
                        { dataField: "groupDisplay", dataType: "string", caption: self.groupItemName, groupIndex: -1 },
                        {
                            // dataField: "regionDisplay",
                            dataField: "regionID",
                            caption: "Region",
                            calculateSortValue: "regionDisplay",
                            lookup: {
                                dataSource: self.regionsLookup,
                                displayExpr: "displayName",
                                valueExpr: "regionID",
                            },
                            groupIndex: -1
                        },
                        { dataField: "branchID", dataType: "number", visible: false },
                        { dataField: "branchDisplay", dataType: "string", caption: "Branch", groupIndex: -1 },
                        { dataField: "titleUnitID", dataType: "number", visible: false },
                        { dataField: "titleUnitDisplay", dataType: "string", caption: "Title Unit", visible: false },
                        { dataField: "biRoleID", dataType: "number", visible: false },
                        { dataField: "biRoleDisplay", dataType: "string", caption: "Reports Role", groupIndex: -1 },
                        { dataField: "emailAddress", dataType: "string", caption: "Email" },
                        { dataField: "phone", dataType: "string" },
                        { dataField: "cellPhone", dataType: "string", caption: "Mobile" },
                        { dataField: "isInactive", caption: "Inactive", dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate,visible: false },
                        { dataField: "fax", dataType: "string", caption: "Fax", visible: false },
                        { dataField: "initials", dataType: "string", visible: false },
                        { dataField: "title", dataType: "string", visible: false },
                        { dataField: "allowOpenFileInUse", dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate, visible: false },
                        {
                            dataField: "modifiedBy",
                            dataType: "number",
                            caption: "Modified By",
                            lookup: {
                                dataSource: {
                                    load: () => self.users
                                },
                                displayExpr: "displayName",
                                valueExpr: "usersID"
                            }
                        },
                        { dataField: 'modifiedDate', dataType: "datetime", visible: false },
                        {
                            dataField: "status",
                            dataType: "number",
                            caption: "Status",
                            lookup: {
                                loadMode: "raw",
                                dataSource: [ { displayName: "Online", value: 1 }, { displayName: "Offline", value: 0 } ],
                                displayExpr: "displayName",
                                valueExpr: "value"
                            }
                        },
                        { dataField: 'lastActive', dataType: "datetime", caption: "Last Active" },
                    ]
                };
                self.userGridDataSource = {
                    loadMode: "raw",
                    key: self.userItemKey,
                    load: () => Promise.resolve(self.users)
                };
                self.groupGridConfig = {
                    columns: [
                        {
                            dataField: "regionID",
                            caption: "Region",
                            dataType: "number",
                            alignment: "left",
                            calculateSortValue: DxGridUtils.regionDisplaySortValue,
                            lookup: {
                                loadMode: "raw",
                                dataSource: self.regions,
                                displayExpr: "displayName",
                                valueExpr: "regionID"
                            },
                            validationRules: [{ type: "required" }]
                        },
                        {
                            dataField: "login",
                            caption: "Group Name",
                            dataType: "string",
                            sortIndex: 0,
                            sortOrder: "asc",
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "custom",
                                    validationCallback: self.isNotDuplicateGroupName,
                                    message: "Group Name already exists"
                                }
                            ],
                            editorOptions: {
                                maxLength: 45
                            }
                        },
                        {
                            dataField: "userOnboardingRoleIDs",
                            dataType: "object",
                            caption: "Onboarding Roles",
                            width: 200,
                            minWidth: 100,
                            cellTemplate: function(cellElement, cellInfo) {
                                if(_.isEmpty(cellInfo.value)) return;
                                let onboardingRoleNames = _.map(cellInfo.value, id => _.find(self.onboardingRoles, { id }).name);
                                let displayText = _.joinParts(onboardingRoleNames, ", ");
                                $("<span />")
                                    .addClass("text-truncate")
                                    .attr("title",displayText)
                                    .append(displayText)
                                    .appendTo(cellElement);
                            },
                            editCellTemplate: function(cellElement, cellInfo) {
                                $("<div />").dxTagBox({
                                    items: self.onboardingRoles,
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    value: cellInfo.value,
                                    showSelectionControls: true,
                                    showDropDownButton: true,
                                    maxDisplayedTags: 1,
                                    onValueChanged: function(e) {
                                        cellInfo.setValue(e.value);
                                    }
                                }).appendTo(cellElement);
                            }
                        },
                        { dataField: "isInactive", caption: "Inactive", dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate, width: 100 },
                    ],
                    filterPanel: { filterEnabled: true }
                };
                self.groupGridDataSource = {
                    loadMode: "raw",
                    key: self.groupItemKey,
                    load: () => Promise.resolve(self.groups),
                    insert: self.onGroupGridInsert,
                    update: self.onGroupGridUpdate
                };
            },

            initListeners(){
                this.$events.on("add:config-user", this.onAddUser);
                this.$events.on("add:config-group", this.onAddGroup);
            },

            initNonReactiveVariables() {
                const self = this;
                self.regions = self.lookupHelpers.getRegions();
                self.entityName = _.get(self.$route.meta, "itemTypeName");
                self.entityDescription = _.get(self.$route.meta, "itemTypeDescription");
                self.userItemName = "User";
                self.userItemNamePlural = "Users";
                self.userItemKey = "usersID";
                self.userSelectionActions = [
                    { name: "edit", text: "Edit", eventName: "edit", requireSelection: true, tooltip: `Edit ${this.userItemName}` },
                    { name: "copy", text: "Copy", eventName: "copy", requireSelection: true, tooltip: `Copy ${this.userItemName}` },
                    { name: "activate", text: "Activate", eventName: "activate", requireSelection: true, tooltip: `Activate ${this.userItemName}`, allowMultiSelection: true, disabled: function(e) { return _.some(e.data, ['isInactive', false]); } },
                    { name: "Inactivate", text: "Inactivate", eventName: "inactivate", requireSelection: true, tooltip: `Inactivate ${this.userItemName}`, allowMultiSelection: true, disabled: function(e) { return _.some(e.data, ['isInactive', true]); } },
                    ];
                self.groupItemName = "Security Role";
                self.groupItemNamePlural = "Security Roles";
                self.groupItemKey = "groupUsersID";
                self.groupSelectionActions = [
                    { name: "edit", text: "Edit", eventName: "edit", requireSelection: true, tooltip: `Edit ${this.groupItemName}` },
                    { name: "copy", text: "Copy", eventName: "copy", requireSelection: true, tooltip: `Copy ${this.groupItemName}` },
                    { name: "activate", text: "Activate", eventName: "activate", requireSelection: true, tooltip: `Activate ${this.groupItemName}`, allowMultiSelection: true, disabled: function(e) { return _.some(e.data, ['isInactive', false]); } },
                    { name: "Inactivate", text: "Inactivate", eventName: "inactivate", requireSelection: true, tooltip: `Inactivate ${this.groupItemName}`, allowMultiSelection: true, disabled: function(e) { return _.some(e.data, ['isInactive', true]); } },
                    ];
            },

            fetchData(){
                this.fetchUserData();
                this.fetchGroupData();
            },

            fetchUserData() {
                const self = this;
                let apiPromise = self.$api.UsersApi.getUsers();
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.users = _.map(result, i => new UsersDto(i));
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.userItemNamePlural}.` });
                    })
                    .finally(() => {
                        self.refreshUsers();
                    });
                return apiPromise;
            },

            fetchGroupData() {
                const self = this;
                let apiPromise = self.$api.UsersApi.getGroups();
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.groups = _.map(result, i => new UserGroupDto(i));
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.userItemNamePlural}.` });
                    })
                    .finally(() => {
                        self.refreshGroups();
                    });
            },

            editUser(id) {
                const self = this;
                let apiPromise = self.$api.UsersApi.getUser(id);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.showUser(new UserDataDto(result));
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error(`Error loading ${self.userItemNamePlural}.`);
                    });
            },

            clearGroups() {
                this.groupGridInstance.option("focusedRowIndex", -1);
                this.groupGridInstance.clearSelection();
            },

            clearUsers() {
                this.userGridInstance.option("focusedRowIndex", -1);
                this.userGridInstance.clearSelection();
            },

            refresh() {
                this.refreshGroups();
                this.refreshUsers();
                this.refreshLicensedUsersWidget();
            },

            refreshGroups() {
                this.clearGroups();
                this.groupGridInstance.refresh();
            },

            refreshUsers() {
                this.clearUsers();
                this.userGridInstance.refresh();
            },

            refreshLicensedUsersWidget() {
                this.$refs?.licensedUsersWidget?.refresh?.();
            },

            isNotDuplicateGroupName(group) {
                const self = this;
                let dup = {};
                dup = _.find(self.groups, (i) => {
                    return _.toLower(_.trim(i.login)) === _.toLower(_.trim(group.data.login))
                            && _.parseNumber(i.regionID, -1) === _.parseNumber(group.data.regionID, -1)
                            && _.parseNumber(_.get(i, self.groupItemKey, -1), -1) != _.parseNumber(_.get(group.data, self.groupItemKey, -1), -1);
                });

                return dup ? false : true;
            },

            isDuplicateGroupName(regionId, name) {
                const self = this;
                let trimLower = val => _.toLower(_.trim(val));
                return _.some(self.groups, g =>
                    trimLower(g.login) === trimLower(name)
                    && _.parseNumber(g.regionID) === _.parseNumber(regionId)
                );
            },

            isDuplicateLogin(key, login) {
                const self = this;
                let trimLower = val => _.toLower(_.trim(val));
                return _.some(self.users, u =>
                    trimLower(u.login) === trimLower(login)
                    && _.parseNumber(u.usersID) !== _.parseNumber(key)
                );
            },

            saveGroup(item, changes) {
                const self = this;

                if(changes != null && changes.length === 0) {
                    self.$toast.error("No changes detected.");
                    return Promise.resolve(item);
                }
                let apiPromise = self.$api.UsersApi.saveGroup(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$toast.success(`${self.groupItemName} was saved.`);
                        return result;
                    }).catch(error => {
                        self.$toast.error(`Error saving ${self.groupItemName}.`);
                        console.error(error);
                        return error;
                    });
            },

            showCopyGroup(group) {
                const self = this;
                let onOk = (e) => e.component.save()
                    .then(result => {
                        if(!result.success) return false;
                        self.onEditGroup({ data: result.data });
                        return result.success;
                    });
                let onCancel = e => {
                };
                self.$dialog.open({
                    title: `Copy ${self.groupItemName}`,
                    width: 650,
                    height: "auto",
                    resizable: false,
                    scrollable: false,
                    adaptive: true,
                    closeOnEsc: true,
                    component: UserGroupCopyForm,
                    props: {
                        item: group,
                        uniqueValidator: (regionId, name) => !self.isDuplicateGroupName(regionId, name),
                    },
                    okTitle: "Save & Edit",
                    onOk,
                    onCancel
                });
            },

            showCopyUser(user) {
                const self = this;
                let onOk = (e) => e.component.save()
                    .then(result => {
                        if(!result.success) return false;
                        self.fetchUserData().then(() => {
                            self.onEditUser({ data: result.data });
                        })

                        return result.success;
                    });
                let onCancel = e => {
                };
                self.$dialog.open({
                    title: `Copy User ${user.displayName}`,
                    width: 650,
                    height: "auto",
                    resizable: false,
                    scrollable: false,
                    adaptive: true,
                    closeOnEsc: true,
                    component: UserCopyForm,
                    props: {
                        item: user,
                        uniqueValidator: (key, login) => !self.isDuplicateLogin(key, login),
                    },
                    okTitle: "Save & Edit",
                    onOk,
                    onCancel
                });
            },

            showUser(user) {
                const self = this;
                let dialogId = null;
                let isNew = _.parseBool(user.isNew);
                let onOk = (e, addAnother=false) => e.component.save()
                    .then(result => {
                        if(!result.success) return false;
                        if (isNew)
                            self.addUser(result.data.user);
                        else
                            self.updateUser(result.data.user);
                        if (addAnother) {
                            self.$dialog.reloadComponent({
                                dialogId,
                                props: {
                                    userData: new UserDataDto(),
                                    uniqueValidator: (key, login) => !self.isDuplicateLogin(key, login),
                                    userGroups: self.groups
                                }
                            });
                            return false;
                        }
                        self.refreshUsers();
                        return result.success;
                    });
                let onCancel = e => {
                    self.refreshUsers();
                };
                dialogId = self.$dialog.open({
                    title: `${(user.isNew ? "Add": "Edit")} ${self.userItemName}${(user.isNew ? "" : `: ${user.user.displayName}`)}`,
                    width: "85%",
                    height: "auto",
                    adaptive: true,
                    component: UserForm,
                    props: {
                        userData: user,
                        uniqueValidator: (key, login) => !self.isDuplicateLogin(key, login),
                        userGroups: self.groups
                    },
                    buttons: [
                        { title: "Cancel", automationId: "btn_dm_modal_cancel", cssClass: "btn btn-secondary", onClick: onCancel },
                        { title: "Save and Add Another", automationId: "btn_dm_modal_save_and_another", cssClass: "btn btn-primary", isVisible: user.isNew, onClick: (e) => onOk(e, true) },
                        { title: "Save", automationId: "btn_dm_modal_save", cssClass: "btn btn-primary", onClick: (e) => onOk(e, false) }
                    ]
                });
            },

            toggleInactiveFlag(keys, itemType) {
                _.each(keys, k => {
                    let e = _.find(_.get(this, `${itemType}s`), [_.get(this, `${itemType}ItemKey`), k]);
                    e.isInactive = !e.isInactive;
                });
            },

            updateUser(user) {
                let editIem = _.find(this.users, (i) => {
                    return _.parseNumber(_.get(i, this.userItemKey, -1), -1) == _.parseNumber(_.get(user, this.userItemKey, -1), -1);
                });
                _.assign(editIem, user);

                if(user.usersID !== this.currentUser.usersID) return;
                let userChanges = this.getAuditChanges(this.currentUser, user);
                if(userChanges.length === 0) return;
                this.$store.commit(SET_SESSION_USER, user);
            },

        }
    }
</script>
