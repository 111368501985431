<template>
    <div class="rq-container">
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && v$.$error"
        />
        <div class="row">
            <div class="col col-12 form-group form-required" :class="{ 'has-error': v$.item.regionIDs.$error }">
                <label for="tag_region">Region(s)</label>
                <dx-tag-box
                    :element-attr="{ automation_id: 'tag_region', id: 'tag_region' }"
                    class="form-control"
                    :data-source="regions"
                    display-expr="displayName"
                    value-expr="regionID"
                    :show-selection-controls="true"
                    :show-clear-button="true"
                    :max-displayed-tags="3"
                    :show-drop-down-button="true"
                    apply-value-mode="useButtons"
                    :search-enabled="true"
                    v-model="v$.item.regionIDs.$model"
                />
                <rq-validation-feedback>Region(s) is required</rq-validation-feedback>
            </div>
            <div class="col col-6 form-group form-required" :class="{ 'has-error': v$.item.endorsement.code.$error }">
                <label for="txt_code">Code</label>
                <input automation_id="txt_code" v-model="v$.item.endorsement.code.$model" type="text" class="form-control" placeholder="Code" maxlength="50">
                <rq-validation-feedback>Code is required</rq-validation-feedback>
            </div>
            <div class="col col-6 form-group form-required" :class="{'has-error' : v$.item.endorsement.endorsementPolicyTypeID.$error}">
                <label for="drp_endorsement_policy_type">Type</label>
                <dx-select-box
                    :input-attr="{ automation_id: 'drp_endorsement_policy_type', id: 'drp_endorsement_policy_type' }"
                    :items="endorsementPolicyTypes"
                    value-expr="id"
                    display-expr="name"
                    :search-enabled="true"
                    v-model="v$.item.endorsement.endorsementPolicyTypeID.$model"
                />
                <rq-validation-feedback>Type is required</rq-validation-feedback>
            </div>
            <div class="col col-12 form-group form-required" :class="{ 'has-error': v$.item.endorsement.description.$error }">
                <label for="txt_description">Description</label>
                <input automation_id="txt_description" v-model="v$.item.endorsement.description.$model" type="text" class="form-control" placeholder="Description">
                <rq-validation-feedback>Description is required</rq-validation-feedback>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { useVuelidate } from "@vuelidate/core";
    import { required } from "@vuelidate/validators";
    import { EndorsementPolicy } from "@settlement/models/enums";
    import { EndorsementDataDto }  from "../models";

    export default {
        name: "EndorsementFormAdd",
        setup: () => ({ v$: useVuelidate() }),
        data() {
            const self = this;
            return {
                item: new EndorsementDataDto(),
                showBanner: false
            }
        },
        computed: {
            ...mapGetters([ "lookupHelpers" ]),
            regions() { return this.lookupHelpers.getRegions(); },
            endorsementPolicyTypes() { return EndorsementPolicy.lookupItems; }
        },
        validations: () => ({
            item: {
                regionIDs: { required },
                endorsement: {
                    code: { required },
                    endorsementPolicyTypeID: { required },
                    description: { required }
                }
            }
        }),
        methods: {
            isValid() {
                this.v$.$touch();
                this.showBanner = true;
                return !this.v$.$error;
            },

            save() {
                const self = this;
                let invalidResult = () => Promise.resolve({ data: null, isValid: false });
                if(!self.isValid()) return invalidResult();
                let apiPromise = self.$api.EndorsementsApi.save(self.item, []);
                return self.$rqBusy.wait(apiPromise)
                    .then(({ endorsement }) => {
                        self.$toast.success({ message: `Endorsement ${endorsement.code} was saved.` });
                        return {
                            data: endorsement,
                            isValid: true
                        };
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error("Error saving endorsement.");
                        return invalidResult();
                    });
            }
        }
    }
</script>