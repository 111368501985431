<template>
    <div class="content-wrapper">
        <rq-page-section title="Clauses" header-size="lg" header-only borderless>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
            <template #header-actions>
                <ul v-show="activeTabIndex < 3" class="nav">
                    <li class="nav-item">
                        <b-btn automation_id="btn_add" variant="theme" @click="onAddClicked">Add</b-btn>
                    </li>
                </ul>
            </template>
        </rq-page-section>

        <RqTabs v-if="dataLoaded"
            :tabs="tabItems"
            v-model="activeTabIndex">
            <template #clauses>
                <standard-language-list
                    :ref="componentRefs[0]"
                    :categories="categories"
                    v-model:validation-list="clauseTabValidationList"
                />
            </template>
            <template #categories>
                <standard-language-category-list
                    :ref="componentRefs[1]"
                    :items="categories"
                    :category-sections="categorySectionData"
                    @add="onAddCategory"
                    @update="onUpdateCategory"
                    @remove="onRemoveCategories"
                    @refresh-sections="onRefreshCategorySectionData"
                    @refresh-clauses="onRefreshClauses"
                    v-model:validation-list="categoriesTabValidationList"
                />
            </template>
            <template #packages>
                <standard-language-package-list
                    :ref="componentRefs[2]"
                    :categories="categories"
                />
            </template>
            <template #sections>
                <standard-language-section-list
                    :ref="componentRefs[3]"
                    :categories="categories"
                    :category-sections="categorySectionData"
                    @refresh="onRefreshCategorySectionData"
                />
            </template>
        </RqTabs>
    </div>
</template>

<script>
    import { ref } from "vue";
    import { StandardLanguageCategoryDto }  from "../models";
    import {
        StandardLanguageList,
        StandardLanguagePackageList,
        StandardLanguageCategoryList,
        StandardLanguageSectionList
    }  from "./clauses/";
    import { useRqTabAlerts } from "@/shared/composables/useRqTabAlerts";


    export default {
        name: "StandardLanguageMain",
        components: {
            StandardLanguageList,
            StandardLanguagePackageList,
            StandardLanguageCategoryList,
            StandardLanguageSectionList
        },
        setup() {
            const clauseTabValidationList = ref([]);
            const categoriesTabValidationList = ref([]);
            const alertMapping = {
                clauseTabAlert: { type: "list-ref", list: clauseTabValidationList },
                categoriesTabAlert: { type: "list-ref", list: categoriesTabValidationList }
            };
            return {
                clauseTabValidationList,
                categoriesTabValidationList,
                ...useRqTabAlerts({ alertMapping })
            };
        },

        data() {
            return {
                categories: [],
                categorySectionData: [],
                activeTabIndex: 0,
                componentRefs: [
                    "stdLangComponent",
                    "categoryComponent",
                    "packageComponent",
                    "sectionComponent"
                ],
                dataLoaded: false,
                clauseRefreshPending: false
            };
        },

        computed:{
            itemTypeDescription() { return _.get(this.$route.meta, "itemTypeDescription") || ""; },
            activeComponentRef() { return this.componentRefs[this.activeTabIndex]; },
            tabItems() {
                return [
                    { title: "Clauses", name: "clauses", alertCount: this.clauseTabAlert.alertCount },
                    { title: "Categories", name: "categories", alertCount: this.categoriesTabAlert.alertCount },
                    { title: "Packages", name: "packages" },
                    { title: "Sections", name: "sections" },
                ]
            }
        },

        watch: {
            activeTabIndex(newValue, oldValue) {
                if(newValue === oldValue) return;
                if(newValue === 0 && this.clauseRefreshPending){
                    _.invoke(this, `$refs.${this.componentRefs[0]}.fetchData`);
                    this.clauseRefreshPending = false;
                }

                //TG - adding a slight delay to ensure tab is fully rendered before calling updateDimensions on tab component
                _.delay(() => {
                    _.invoke(this, `$refs.${this.activeComponentRef}.updateDimensions`);
                }, 200);
            }
        },

        created() {
            const self = this;
            self.fetchCategoryData()
                .then(() => {
                    self.dataLoaded = true;
                });
        },

        methods:{
            onAddClicked(e){
                _.invoke(this, `$refs.${this.activeComponentRef}.onAddItem`);
            },

            onRefreshCategoryData() {
                const self = this;
                self.fetchCategoryData()
                    .then(() => {
                        self.refresh();
                    });
            },

            onRefreshCategorySectionData() {
                this.refreshCategorySections();
            },

            onRefreshClauses(){
                this.clauseRefreshPending = true;
            },

            onRemoveCategories({ keys }) {
                const self = this;
                if(!_.isArray(keys) || _.isEmpty(keys)) return;
                _.forEach(keys, key => {
                    let itemIndex = _.findIndex(self.categories, item => item.id === key);
                    if(itemIndex < 0) return;
                    self.categories.splice(itemIndex, 1);
                });
                self.refresh();
            },

            onAddCategory(item) {
                const self = this;
                self.categories.push(new StandardLanguageCategoryDto(item));
                self.refresh();
            },

            onUpdateCategory(item) {
                const self = this;

                let itemInstance = new StandardLanguageCategoryDto(item);
                itemInstance.sectionsApplied = _.some(self.categorySectionData, cs => cs.standardLanguageCategoryID === itemInstance.id);

                let index = _.findIndex(self.categories, c => c.id === itemInstance.id);
                self.categories[index] = itemInstance;

                self.refresh();
            },

            refreshCategorySections() {
                const self = this;
                let apiPromise = self.fetchCategorySections();
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        _.updateAll(self.categories, "sectionsApplied", cat => _.some(self.categorySectionData, cs => cs.standardLanguageCategoryID === cat.id));
                        self.refresh();
                    });
            },

            fetchCategoryData() {
                const self = this;
                let apiPromises = [
                    self.fetchCategorySections(),
                    self.fetchCategories()
                ];
                return self.$rqBusy.wait(Promise.all(apiPromises));
            },

            handleDataError(msg) {
                const self = this;
                return error => {
                    self.$toast.error(msg);
                    console.error(error);
                    return error;
                };
            },

            fetchCategories() {
                const self = this;
                let errorHandler = self.handleDataError("Error loading categories.");
                return self.$api.StandardLanguageCategoryApi.get()
                    .then(result => {
                        self.categories = _.map(result, item => new StandardLanguageCategoryDto(item));
                        return result;
                    })
                    .catch(errorHandler);
            },

            fetchCategorySections() {
                const self = this;
                let errorHandler = self.handleDataError("Error loading category sections.");
                return self.$api.StandardLanguageCategoryApi.getSections()
                    .then(result => {
                        self.categorySectionData = result;
                        return result;
                    })
                    .catch(errorHandler);
            },

            refresh() {
                const self = this;
                self.categories = _.sortBy(self.categories, c => c.isDefaultCategory ? 0 : _.toLower(c.category));
                _.each(self.componentRefs, refVal => {
                    _.invoke(self, `$refs.${refVal}.refresh`);
                });
            }
        }
    };
</script>
