<template>
    <div class="document-category-form content-wrapper">
        <rq-banner
            variant="error"
            title="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && (v$.$error || v$.$invalid)"/>
        <rq-page-section borderless>
            <div class="row">
                <div class="col col-3 col-sm-12 col-md-4 col-lg-4 form-group  form-required" :class="{ 'has-error' : v$.categoryItem.regionID.$error || !v$.categoryItem.uniqueDescription }">
                    <label for="region">Region</label>
                    <dx-select-box
                        :input-attr="$utils.idAttrs('drp_region')"
                        :items="regions"
                        value-expr="regionID"
                        display-expr="displayName"
                        v-model="v$.categoryItem.regionID.$model"
                    />
                    <rq-validation-feedback
                        :messages="{
                            'Region is required.': v$.categoryItem.description.$error,
                            'A Document Category already exists with that combination of Region & Description.': !v$.categoryItem.uniqueDescription
                        }"
                    />
                </div>
                <div class="col col-3 col-sm-12 col-md-4 col-lg-4 form-group  form-required" :class="{'has-error' : v$.categoryItem.description.$error || (!v$.categoryItem.uniqueDescription && !disableDescription) }">
                    <label for="description">Description</label>
                    <input
                        id="txt_description"
                        automation_id="txt_description"
                        type="text"
                        class="form-control"
                        placeholder="Description"
                        maxlength="40"
                        :disabled="disableDescription"
                        v-model="v$.categoryItem.description.$model">
                    <rq-validation-feedback
                        :messages="{
                            'Description is required.': v$.categoryItem.description.$error,
                            'A Document Category already exists with that combination of Region & Description.': !v$.categoryItem.uniqueDescription
                        }"
                    />
                </div>
            </div>
        </rq-page-section>
        <grid-selector
            ref="gridSelector"
            :items="allTemplatesList"
            item-key="documentTemplateID"
            item-name="Documents"
            sort-description-field="description"
            sort-field="sequence"
            :grid-configs="gridConfigs"
            v-model="v$.selectedTemplateIDs.$model"
            hide-reorder
            flex-layout
        />
    </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import { DocumentCategoryDto, DocumentTemplateDto }  from "../models";
    import GridSelector from "../../shared/GridSelector";
    import { required, minValue } from "validators";
    import { useVuelidate } from "@vuelidate/core";

    export default {
        name: "DocumentCategoryForm",
        components: { GridSelector },
        props: {
            item: { type: Object, default: null },
            uniqueValidator: { type: Function, default: (() => true) }
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                categoryItem: new DocumentCategoryDto(this.item),
                showBanner: false,
                allTemplatesList: [],
                selectedTemplateIDs: []
            }
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            disableDescription() { return !this.categoryItem.isNew; },
            regions() { return this.lookupHelpers.getRegions(); }
        },

        validations: () => ({
            categoryItem: {
                regionID: {
                    required,
                    minValue: minValue(1),

                },
                description: { required },
                uniqueDescription: (val, vm) => vm.uniqueValidator(val, "description")
            },
            selectedTemplateIDs: {}
        }),

        created(){
            const self = this;
            self.initGridConfig();
            self.fetchRelatedData();
        },

        methods: {

            initGridConfig(){
                const self = this;
                self.gridConfigs =  {
                    height: "100%",
                    columns: [ { dataField: "description", sortIndex: 0, sortOrder: "asc", } ]
                };
            },

            fetchRelatedData() {
                const self = this;
                let apiPromises = [];
                apiPromises.push(self.$api.DocumentTemplatesApi.getByCategoryId(self.categoryItem.documentCategoryID));
                apiPromises.push(self.$api.DocumentTemplatesApi.getList());
                return self.$rqBusy.wait(Promise.all(apiPromises))
                    .then(result => {
                        self.allTemplatesList = _.map(result[1], i => new DocumentTemplateDto(i));
                        self.selectedTemplateIDs = _.map(result[0], "documentTemplateID");
                        self.$nextTick(() => {
                            self.v$.$reset();
                        });
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading Document Templates.` });
                        return error;
                    });
            },

            reset() {
                const self = this;
                self.selectedTemplateIDs = [];
                self.categoryItem.regionID = null;
                self.categoryItem.description = "";
                self.v$.$reset();
            },

            save() {
                const self = this;
                self.showBanner = true;

                if(self.categoryItem.isNew) {
                    self.v$.$touch();
                }
                else if(!self.v$.$anyDirty) {
                    return Promise.resolve({ noChange: true });
                }

                if (self.v$.$error || self.v$.$invalid) return Promise.resolve({ isValid: false });

                self.categoryItem.documentTemplates = _.map(self.selectedTemplateIDs, i => new DocumentTemplateDto({ documentTemplateID: i }));
                let apiPromise = self.$api.DocumentCategoriesApi.save([self.categoryItem.toDataObject()]);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.showBanner = false;
                        self.$toast.success("Document category was saved.");
                        return { data: result, isValid: true };
                    }).catch(error => {
                        self.showBanner = false;
                        self.$toast.error("Error saving document category.");
                        console.error(error);
                        return { isValid: false };
                    });
            }
        }
    }
</script>