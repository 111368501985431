<template>
    <div class="content-wrapper">
        <rq-banner
            variant="error"
            :message="errorMessage"
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && (v$.$error || unitCheckErrors || !isImageValid)"
            sticky
        />
        <div class="rq-container mt-3">
            <div class="row">
                <div class="col col-12 col-lg-4 form-group form-required" :class="{ 'has-error' : v$.item.regionID.$error }">
                    <label for="region">Region</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_region' }"
                        :items="regionsLookup"
                        value-expr="regionID"
                        display-expr="displayName"
                        v-model="v$.item.regionID.$model"
                        v-focus
                        :search-enabled="true"
                    />
                    <rq-validation-feedback
                        message="Region is required."
                    />
                </div>
                <div class="col col-12 col-lg-4 form-group form-required" :class="{ 'has-error' : v$.item.description.$error || !v$.item.uniqueDescription }">
                    <label for="description">Description</label>
                    <input
                        automation_id="txt_description"
                        type="text"
                        class="form-control"
                        placeholder="Description"
                        maxlength="150"
                        v-model="v$.item.description.$model">
                    <rq-validation-feedback
                        :messages="{
                            'Description is required.': v$.item.description.$error,
                            'An Escrow Unit already exists with that description.': !v$.item.uniqueDescription
                        }"
                    />
                </div>
                <div class="col col-12 col-lg-4 form-group form-required" :class="{ 'has-error' : v$.item.shortDescription.$error || !v$.item.uniqueName }">
                    <label for="name">Name</label>
                    <input
                        automation_id="txt_name"
                        type="text"
                        class="form-control"
                        placeholder="Name"
                        maxlength="100"
                        v-model="v$.item.shortDescription.$model">
                    <rq-validation-feedback
                        :messages="{
                            'Name is required.': v$.item.shortDescription.$error,
                            'An Escrow Unit already exists with that name.': !v$.item.uniqueName
                        }"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col col-12 col-lg-4 form-group mb-0">
                    <label for="drp_file_number_pool">File Pool</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_file_number_pool' }"
                        :items="fileNumberPools"
                        value-expr="id"
                        display-expr="name"
                        v-model="v$.item.fileNumberPoolID.$model"
                        :search-enabled="true"
                    />
                </div>
                <div class="col col-12 col-lg-4 form-group mb-0">
                    <label for="prefix">Prefix</label>
                    <input
                        automation_id="txt_prefix"
                        type="text"
                        class="form-control"
                        id="prefix"
                        placeholder="Prefix"
                        maxlength="5"
                        v-model="v$.item.prefix.$model"
                    >
                </div>
                <div class="col col-12 col-lg-4 form-group mb-0">
                    <label for="suffix">Suffix</label>
                    <input
                        automation_id="txt_suffix"
                        type="text"
                        class="form-control"
                        placeholder="Suffix"
                        maxlength="5"
                        v-model="v$.item.suffix.$model"
                    >
                </div>
            </div>
            <div class="row">
                <div class="col col-12 col-lg-4">
                    <rq-page-section
                        title="Signature"
                        header-class="pb-0"
                        body-class="p-0"
                        borderless>
                        <rq-image-preview
                            ref="signatureImage"
                            class="mt-2"
                            v-model="v$.item.signatureImage.$model"
                        />
                    </rq-page-section>
                </div>
                <div class="col col-12 col-lg-8">
                    <user-member-selection
                        :key-name="itemKey"
                        :key-value="item.escrowUnitID"
                        :region-id="item.regionID"
                        v-model:leader-id="v$.item.leaderUsersID.$model"
                        v-model:leader-name="v$.item.leaderDisplay.$model"
                        v-model="v$.item.memberUserIDs.$model"
                    />
                </div>
            </div>
            <escrow-unit-checks-grid
                ref="checkRanges"
                :escrow-unit-id="item.escrowUnitID"
                :region-id="item.regionID"
                v-model:has-errors="unitCheckErrors"
                v-model="v$.item.unitChecks.$model"
            />
        </div>
    </div>

</template>
<script>
    import { mapGetters } from "vuex";
    import { required } from "validators";
    import { useVuelidate } from "@vuelidate/core";
    import { EscrowUnitDto, EscrowUnitCheckDto }  from "../models";
    import { ImageDataModel } from "@/shared/models/models";
    import UserMemberSelection from "../../shared/UsersMemberSelection";
    import EscrowUnitChecksGrid from "./EscrowUnitChecksGrid";

    const defaultErrorMessage = "Please correct the highlighted errors on screen to continue.";

    export default {

        name: "EscrowUnitsForm",
        components: { UserMemberSelection, EscrowUnitChecksGrid },
        props: {
            item: { type: Object, default: null },
            uniqueValidator: { type: Function, default: (() => true) }
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            const self = this;
            return {
                unitCheckErrors: false,
                showBanner: false,
                errorMessage: defaultErrorMessage,
                isImageValid: true,
            }
        },
        provide(){
            return {
                imageInfoCallback: {
                    onImageChanged: (data) => {
                        if(data.validImage){
                            //reset to default behavior
                            this.showBanner = false;
                            this.isImageValid = true;
                            this.errorMessage = defaultErrorMessage;
                        }
                        else{
                            this.showBanner = true;
                            this.isImageValid = false;
                            this.errorMessage = data.message;
                        }
                        
                    }
                }
            }
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "regionsLookup"
            ]),
            itemKey() { return _.get(this, "$route.meta.itemKey") || ""; },
            imageExists () { return (this.item.signatureImage && this.item.signatureImage.dataUrl); },
            escrowUnitId() { return _.getNumber(this, "item.escrowUnitID", 0); },
            fileNumberPools() { return this.lookupHelpers.getLookupItems(this.lookupItems.FILE_NUMBER_POOLS); }
        },

        validations: () => ({
            item: {
                regionID: { required },
                description: { required },
                shortDescription: { required },
                fileNumberPoolID: {},
                prefix: {},
                suffix: {},
                signatureImage: {},
                leaderUsersID: {},
                leaderDisplay: {},
                unitChecks: { $each: {} },
                memberUserIDs: {},
                uniqueName: (val, vm) => vm.uniqueValidator(val, "shortDescription"),
                uniqueDescription: (val, vm) => vm.uniqueValidator(val, "description")
            }
        }),

        created(){
            const self = this;
            self.fetchCheckData();
        },
        methods: {
            fetchCheckData() {
                const self = this;
                if(self.escrowUnitId === 0) return;
                let apiPromise = self.$api.EscrowUnitsApi.getCheckRange(self.escrowUnitId);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.item.unitChecks = result;
                        _.invoke(self, "$refs.checkRanges.refresh");
                        self.v$.$reset();
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading check number ranges.` });
                        return error;
                    });
            },

            commitCheckData() {
                const self = this;
                let validItems = _.filter(self.unitChecks, item => item.escrowUnitCheckID > 0 || (item.escrowUnitCheckID < 0 && !_.parseBool(item.isDeleted)));
                return _.map(validItems, item => item.toDataObject());
            },

            async save(){
                const self = this;

                self.showBanner = true;
                let checkRagesAreValid = await _.invoke(self, "$refs.checkRanges.saveEditData")
                if(!checkRagesAreValid) return false;

                self.v$.$touch();

                if (self.v$.$error || self.unitCheckErrors) return false;
                if (!self.v$.$anyDirty) return true;
                self.commitCheckData();

                let itemData = self.item.toDataObject();

                try{
                    let apiPromise = self.$api.EscrowUnitsApi.saveEscrowUnit(itemData);
                    await self.$rqBusy.wait(apiPromise);
                    self.$toast.success({ message: `Escrow unit saved successfully.` });
                    return true;
                }
                catch(err){
                    self.$toast.error("An error occurred while saving this escrow unit.");
                    console.error(err);
                    return err;
                }
            }
        }
    }
</script>