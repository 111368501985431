<template>
    <div class="content-wrapper">
        <rq-banner
            variant="error"
            title="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && (v$.$error || v$.$invalid)"
            sticky
        />
        <div class="rq-container">
            <div class="row">
                <div class="col col-12 col-lg-3 form-group form-required" :class="{ 'has-error': v$.packageInfo.regionID.$error }">
                    <label for="drp_region">Region</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_region', id: 'drp_region' }"
                        :items="regions"
                        value-expr="regionID"
                        display-expr="displayName"
                        v-model="v$.packageInfo.regionID.$model"
                    />
                    <rq-validation-feedback>Region is required</rq-validation-feedback>
                </div>
                <div class="col col-12 col-lg-3 form-group form-required" :class="{ 'has-error' : v$.packageInfo.name.$error || v$.packageInfo.uniqueName.$invalid }">
                    <label for="txt_package_name">Name</label>
                    <input
                        id="txt_package_name"
                        automation_id="txt_package_name"
                        type="text"
                        class="form-control"
                        placeholder="Name"
                        maxlength="50"
                        v-model="v$.packageInfo.name.$model">
                    <rq-validation-feedback
                        :messages="{
                            'Name is required.': v$.packageInfo.name.$error,
                            'A package already exists with that name.': v$.packageInfo.uniqueName.$invalid
                        }"
                    />
                </div>
                <div class="col col-12 col-lg-3 form-group form-required" :class="{'has-error' : v$.packageInfo.description.$error || v$.packageInfo.uniqueDescription.$invalid }">
                    <label for="txt_package_desc">Description</label>
                    <input
                        id="txt_package_desc"
                        automation_id="txt_package_desc"
                        type="text"
                        class="form-control"
                        placeholder="Description"
                        maxlength="50"
                        v-model="v$.packageInfo.description.$model">
                    <rq-validation-feedback
                        :messages="{
                            'Description is required.': v$.packageInfo.description.$error,
                            'A package already exists with that description.': v$.packageInfo.uniqueDescription.$invalid
                        }"
                    />
                </div>
                <div class="col col-12 col-lg-3 form-group form-required" :class="{ 'has-error': v$.packageInfo.standardLanguageCategoryID.$error }">
                    <label for="drp_category">Category</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_category', id: 'drp_category' }"
                        :items="categories"
                        value-expr="id"
                        display-expr="name"
                        v-model="packageInfo.standardLanguageCategoryID"
                    />
                    <rq-validation-feedback>Category is required</rq-validation-feedback>
                </div>
            </div>
        </div>
        <clause-list-manager
            ref="clauseListManager"
            :package-id="packageInfo.id"
            :category-id="packageInfo.standardLanguageCategoryID"
            v-model="packageInfo.clauses"
        />
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { required } from "validators";
    import { useVuelidate } from "@vuelidate/core";
    import { StandardLanguagePackageDto } from "../../models";
    import ClauseListManager from "./ClauseListManager";

    const MAX_LEVEL_VALUE = 10;

    export default {
        name: "ClausePackageForm",

        components: { ClauseListManager },

        setup: () => ({ v$: useVuelidate() }),

        props: {
            item: { type: Object, default: () => ({}) },
            categories: { Type: Array, default: () => [] },
            uniqueValidator: { type: Function, default: (() => true) }
        },

        data() {
            return {
                packageInfo: new StandardLanguagePackageDto(this.item),
                showBanner: false
            };
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            regions() { return this.lookupHelpers.getRegions(); },
            hasItem() { return !_.isEmpty(this.item); }
        },

        watch: {
            item(newValue, oldValue) {
                this.packageInfo = new StandardLanguagePackageDto(newValue);
                this.v$.$reset();
            }
        },

        validations: () => ({
            packageInfo: {
                regionID: { required },
                name: { required },
                description: { required },
                standardLanguageCategoryID: { required },
                clauses: {},
                uniqueName: (val, vm) => vm.uniqueValidator(val, "name"),
                uniqueDescription: (val, vm) => vm.uniqueValidator(val, "description")
            }
        }),

        methods: {
            getPackageData() { return this.packageInfo.toDataObject(); },
            save() {
                const self = this;

                self.showBanner = true;
                self.v$.$touch();

                if (self.v$.$error) return Promise.resolve({ data: null, isValid: false });

                let apiPromise = self.$api.StandardLanguageApi.savePackage(self.packageInfo.toDataObject());
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.showBanner = false;
                        self.$toast.success("Clause Package was saved.");
                        return { data: result, isValid: true };
                    })
                    .catch(error => {
                        self.$toast.error("Error saving clause package.");
                        return { isValid: false };
                    });
            }
        }
    };
</script>