<template>
    <div class="content-wrapper">
        <rq-page-section borderless>
            <div class="row">                
                <div :class="{ 'col col-2 col-sm-12 col-md-12 col-lg-12 form-group form-required':true, 'has-error':v$.item.sequence.$error }">
                    <label for="txt_sequence">Enter the new Sequence for the Workflow Task</label>
                    <input
                        id="txt_sequence" 
                        automation_id="txt_sequence"
                        type="text"
                        class="form-control"
                        v-model="v$.item.sequence.$model"
                        @keypress="onKeyPress"
                        autocomplete="off"
                    />
                    <rq-validation-feedback
                        :container="tooltipContainer"
                        :boundary="tooltipContainer"
                        :messages="{ 'Sequence Number Required': v$.item.sequence.required.$error, 'Sequence Number is Out of Range': !v$.item.sequence.isInRange.$error}"
                    />
                </div>
            </div>
        </rq-page-section>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";    
import { WorkflowTasksDto }  from "../models";
import { required } from '@vuelidate/validators';
import { useVuelidate } from "@vuelidate/core";

export default {
    name: 'WorkflowTasksSequenceForm',  
    components: {
    },      
    props: {
        item: {},
        itemTypeName: {type: String},
        maxSequence: {},
    },
    inject: ["dialogId"],
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            originalData: {},          
            validationErrors: [],
            sequence: null
        };
    },
    computed: {
        tooltipContainer() { return `#${this.dialogId}`; },
    },     
    created() {
        const self = this;
        self.originalData = new WorkflowTasksDto(self.item);
    },    
    
    watch: {
        "v$.$error":{
            handler: function(newValue, oldValue) {
                this.$emit(`${newValue ? "disable" : "enable"}-ok`);
            },
            deep: true,
            immediate: true
        },
    },

    validations: () => ({
        item: {
            sequence: {
                required,
                isInRange(val) {
                    return _.inRange(_.parseNumber(val, 0), 1, _.getNumber(this, "maxSequence", 1));
                }
            }
            
        }
    }),
    methods: {   
        onKeyPress(e) {
            let currentValue = e.target.value;
            let validKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Tab"];
            let isValid = _.includes(validKeys, e.key);

            if(isValid){
                return;
            }

            e.preventDefault();
        },  
    }
}
</script>
<style lang="scss" scoped>
    .modal-header .modal-title .header-badge-message { vertical-align: top; line-height: 1.5; margin-left:1rem; }    
    .company-lookup { height: 100%; }   
</style>
