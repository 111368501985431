
/**
    Example Usage:
        import { mapState, mapGetters } from "vuex";
        import WebDefaultsFormMixin from "../shared/WebDefaultsFormMixin";
        export default {
            name: "SomeWebDefaultsSubset",
            mixins: [WebDefaultsFormMixin],
            computed: {
                ...mapGetters([
                    "lookupHelpers",
                    "lookupItems"
                ]),
                someLookupItems(){ return SomeEnum.LookupItems; },
                someOtherLookupItems(){ return SomeOtherEnum.lookupItems; }
            }
        }
**/
import { GlobalEventManager } from "@/app.events";
import { WebDefaultsDto }  from "../models";

export default {
    data() {
        return {
            originalData: {
                editedData: {},
            },
            editedData: {},
        }
    },
    computed: {
        itemKey() { return _.get(this.$route.meta, "itemKey") || ""; },
        itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
        itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
        itemTypeDescription() { return _.get(this.$route.meta, "itemTypeDescription") || ""; },
    },
    created(){
        const self = this;
        GlobalEventManager.onSave(self, self.onSave);
        self.fetchData();
        self.fetchAdditionalData();
    },
    beforeUnmount() {
        GlobalEventManager.unregister(this);
    },
    methods :{
        onSave: _.debounce(function(e){
            const self = this;

            if (self.hasChanges() || self.hasAdditionalChanges()) {
                return Promise.all([
                        self.save(),
                        self.saveAdditional()
                    ])
                    .then(results => {
                        let overallResult = _.every(results);
                        if(!overallResult) return overallResult;
                        self.$toast.success(`${this.itemTypeNamePlural} were saved.`);
                        GlobalEventManager.saveCompleted({ success: false });
                        return overallResult;
                    })
                    .catch(error => {
                        GlobalEventManager.saveCompleted({ success: false });
                        self.$toast.error(`Error updating ${self.itemTypeNamePlural}.`);
                        console.error(error);
                        return error;
                    });
            } else {
                if (!_.isEmpty(e)) { self.$toast.info("No changes detected"); }
                GlobalEventManager.saveCompleted({ success: true });
                return Promise.resolve(true) ;
            }
        }, 500, { leading: true, trailing: false }),

        onCancel(){
            const self = this;
            if (self.hasChanges() || self.hasAdditionalChanges()) {
                self.$dialog.confirm("Confirm Cancel", "Discard changes and reload data?", function () {
                    self.restoreOriginalData();
                    self.restoreAdditionalData();
                });
            } else {
                self.$toast.info("No changes detected");
            }
        },

        hasChanges() {
            const self = this;

            let sdChanges = self.getChanges();

            return !_.isEmpty(sdChanges);
        },

        //for use by implementing component
        hasAdditionalChanges() { return false; },

        fetchData () {
            const self = this;
            let apiPromise = self.$api.WebDefaultsApi.getWebDefaults();
            return self.$rqBusy.wait(apiPromise)
                .then(result => {
                    self.editedData = new WebDefaultsDto(result[0]);
                    self.originalData = new WebDefaultsDto(self.editedData);
                    return result;
                })
                .catch(error => {
                    console.error(error);
                    self.$toast.error(`Error loading ${this.itemTypeNamePlural}.`);
                    return error;
                });
        },

        getChanges() {
            const self = this;

            let originalSd = self.originalData.toDataObject();
            let currentSd = self.editedData.toDataObject();
            let sdChanges = self.getAuditChanges(originalSd, currentSd);

            return sdChanges;
        },

        //for use by implementing component
        fetchAdditionalData() { return Promise.resolve([]); },

        save() {
            const self = this;
            if(!self.hasChanges()) return Promise.resolve(true);
            let requestData = self.getRequestData();

            let changes = self.getChanges();

            let apiPromise = self.$api.WebDefaultsApi.updateWebDefaults(requestData, changes);
            return self.$rqBusy.wait(apiPromise)
                .then(() => {
                    self.commitCurrentData();
                    return true;
                });
        },

        //for use by implementing component
        saveAdditional() { return Promise.resolve(true); },

        getRequestData() {
            const self = this;
            
            return self.editedData.toDataObject();
        },

        commitCurrentData() {
            const self = this;
            self.originalData = new WebDefaultsDto(self.editedData);
        },

        restoreOriginalData() {
            const self = this;
            self.editedData = new WebDefaultsDto(self.originalData);
            self.restoreAdditionalData();
        },

        //for use by implementing component
        restoreAdditionalData() { }
    }
}