<template>
    <div class="content-wrapper">
        <rq-page-section :title="itemTypeNamePlural" headerSize="lg" borderless>
             <template #header-actions>
                <ul class="nav config-actions ms-auto">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_save"
                            variant="action"
                            v-rq-tooltip.hover.top title="Discard changes and reload data"
                            @click="onSave"
                            :disabled="readOnly">Save</b-btn>
                    </li>
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_cancel"
                            variant="action"
                            @click="onCancel"
                            :disabled="readOnly">Cancel</b-btn>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
            <div class="row mb-1">
                <div class="col-auto ms-auto">
                    <rq-section-expand-collapse-all />
                </div>
            </div>
            <rq-section-card title="Enterprise" collapsible>
                <div class="row">
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="txt_applicationUrl">Application URL</label>
                        <input
                            id="txt_applicationUrl"
                            automation_id="txt_applicationUrl"
                            type="text"
                            class="form-control"
                            placeholder="Application URL"
                            maxlength="255"
                            v-model="editedData.applicationUrl"
                            :disabled="readOnly"
                        >
                    </div>
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="txt_tenantId">Tenant Name</label>
                        <input
                            id="txt_tenantId"
                            automation_id="txt_tenantId"
                            type="text"
                            class="form-control"
                            placeholder="Tenant Name"
                            maxlength="100"
                            v-model="editedData.tenantClientId"
                            :disabled="readOnly"
                        >
                    </div>
                </div>
            </rq-section-card>
        </rq-page-section>
    </div>
</template>

<script>
    import { GlobalEventManager } from "@/app.events";
    import { mapState } from "vuex";
    import WebDefaultsFormMixin from "../../shared/WebDefaultsFormMixin";

    export default {
        name: "Integrations",
        mixins: [WebDefaultsFormMixin],
        data() {
            return { }
        },
        computed: {
            ...mapState({
                globalRegionId: state => state.system.globalRegionId,
                readOnly: state => state.isPageReadOnly,
            }),
        },
    }
</script>
