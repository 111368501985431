<template>
    <div class="content-wrapper">
        <rq-page-section :title="itemTypeNamePlural" headerSize="lg" borderless>
             <template #header-actions>
                <ul class="nav config-actions ms-auto">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_cancel"
                            variant="action"
                            v-rq-tooltip.hover.top title="Discard changes and reload data"
                            @click="onCancel"
                            :disabled="readOnly">Cancel
                        </b-btn>
                    </li>
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_save"
                            variant="action"
                            @click="onSave"
                            :disabled="readOnly">Save
                        </b-btn>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
            <div class="row">
                <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                    <label for="txt_restrictive_covenants">Restrictive Covenants</label>
                    <input automation_id="txt_restrictive_covenants" v-model="systemDefaults.listTypeRestrictiveCovenants" type="text" class="form-control" id="txt_restrictive_covenants" placeholder="Restrictive Covenants" maxlength="200" :disabled="readOnly">
                </div>
                <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                    <label for="txt_mortgage_sched_b_restrictive_covenants">Mortgage Schedule B Restrictive Covenants</label>
                    <input automation_id="txt_mortgage_sched_b_restrictive_covenants" v-model="systemDefaults.listTypeMortSchedBResCov" type="text" class="form-control" id="txt_mortgage_sched_b_restrictive_covenants" placeholder="Mortgage Schedule B Restrictive Covenants" maxlength="200" :disabled="readOnly">
                </div>
            </div>
            <div class="row">
                <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                    <label for="txt_requirements">Requirements</label>
                    <input automation_id="txt_requirements" v-model="systemDefaults.listTypeRequirements" type="text" class="form-control" id="txt_requirements" placeholder="Requirements" maxlength="200" :disabled="readOnly">
                </div>
                <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                    <label for="txt_mortgage_sched_b_exceptions">Mortgage Schedule B Exceptions</label>
                    <input automation_id="txt_mortgage_sched_b_exceptions" v-model="systemDefaults.listTypeMortSchedBExceptions" type="text" class="form-control" id="txt_mortgage_sched_b_exceptions" placeholder="Mortgage Schedule B Exceptions" maxlength="200" :disabled="readOnly">
                </div>
            </div>
            <div class="row">
                <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                    <label for="txt_exceptions">Exceptions</label>
                    <input automation_id="txt_exceptions" v-model="systemDefaults.listTypeExceptions" type="text" class="form-control" id="txt_exceptions" placeholder="Exceptions" maxlength="200" :disabled="readOnly">
                </div>
                <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                    <label for="txt_mortgage_sched_b_subordinate_liens">Mortgage Schedule B Subordinate Liens</label>
                    <input automation_id="txt_mortgage_sched_b_subordinate_liens" v-model="systemDefaults.listTypeMortSchedBSubLiens" type="text" class="form-control" id="txt_mortgage_sched_b_subordinate_liens" placeholder="Mortgage Schedule B Subordinate Liens" maxlength="200" :disabled="readOnly">
                </div>
            </div>
            <div class="row">
                <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                    <label for="txt_subordinate_liens">Subordinate Liens</label>
                    <input automation_id="txt_subordinate_liens" v-model="systemDefaults.listTypeSubordinateLiens" type="text" class="form-control" id="txt_subordinate_liens" placeholder="Subordinate Liens" maxlength="200" :disabled="readOnly">
                </div>
                <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                    <label for="txt_insured_mortgage">Insured Mortgage</label>
                    <input automation_id="txt_insured_mortgage" v-model="systemDefaults.listTypeInsuredMortgage" type="text" class="form-control" id="txt_insured_mortgage" placeholder="Insured Mortgage" maxlength="200" :disabled="readOnly">
                </div>
            </div>
            <div class="row">
                <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                    <label for="txt_owner_sched_b">Owner Schedule B</label>
                    <input automation_id="txt_owner_sched_b" v-model="systemDefaults.listTypeOwnerSchedB" type="text" class="form-control" id="txt_owner_sched_b" placeholder="Owner Schedule B" maxlength="200" :disabled="readOnly">
                </div>
                <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                    <label for="txt_required_documents">Required Documents</label>
                    <input automation_id="txt_required_documents" v-model="systemDefaults.listTypeRequiredDocuments" type="text" class="form-control" id="txt_required_documents" placeholder="Required Documents" maxlength="200" :disabled="readOnly">
                </div>
            </div>
        </rq-page-section>
    </div>
</template>
<script>
    import { mapState } from "vuex";
    import SystemDefaultsFormMixin from "../../shared/SystemDefaultsFormMixin";
    export default {
        name: "AssignedLanguageListTypeForm",
        mixins: [SystemDefaultsFormMixin],
        computed: {
            ...mapState({
                readOnly: state => state.isPageReadOnly,
            }),
        },
    }
</script>
