<template>
    <div class="content-wrapper">
        <rq-page-section :title="itemTypeNamePlural" headerSize="lg" borderless>
             <template #header-actions>
                <ul class="nav send_test_email">
                    <li class="nav-item">
                        <b-button automation_id="btn_send_test_email"
                            ref="btn_send_test_email"
                            variant="theme"
                            @click="sendTestEmail"
                            :disabled="readOnly">Send Test Email</b-button>
                    </li>
                </ul>
                <ul class="nav config-actions ms-auto">
                    <li class="nav-item">
                        <button
                            automation_id="btn_cancel"
                            type="button"
                            class="btn btn-action"
                            :disabled="readOnly"
                            @click="onCancel">Cancel
                        </button>
                    </li>
                    <li class="nav-item">
                        <button
                            automation_id="btn_save"
                            type="button"
                            class="btn btn-action"
                            :disabled="readOnly"
                            @click="onSave">Save
                        </button>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
                <!-- <router-link automation_id="lnk_back" :to="{path: parentRoute.path, query: {category: parentRoute.category}}" class="btn btn-link btn-theme">Back to {{parentRoute.category}}</router-link> -->
            </template>
            <div class="content-wrapper config-email-automation">
                <rq-page-section borderless>
                    <div class="row">
                        <div class="col col-2 col-sm-12 col-md-6 col-lg-6 form-group">
                            <label for="smtp_server">SMTP Server</label>
                            <input automation_id="txt_smtp_server" v-model="editedData.smtpServer" type="text" class="form-control" id="txt_smtp_server" placeholder="SMTP Server" maxlength="40" :disabled="readOnly">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-2 col-sm-12 col-md-6 col-lg-6 form-group">
                            <label for="smtp_port">SMTP Port</label>
                            <input automation_id="txt_smtp_port" v-model="editedData.smtpPort" type="numeric" class="form-control" id="txt_smtp_port" placeholder="SMTP Port" :disabled="readOnly">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-2 col-sm-12 col-md-6 col-lg-6 form-group">
                            <label for="default_email_name">Default Email Name</label>
                            <input automation_id="txt_default_email_name" v-model="editedData.defaultEmailName" type="text" class="form-control" id="txt_default_email_name" placeholder="Default Email Name" maxlength="40" :disabled="readOnly">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-2 col-sm-12 col-md-6 col-lg-6 form-group">
                            <label for="default_email_address">Default Email Address</label>
                            <input automation_id="txt_default_email_address" v-model="editedData.defaultEmailAddress" type="text" class="form-control" id="txt_default_email_address" placeholder="Default Email Address" :disabled="readOnly">
                        </div>
                    </div>
                    <div class="row checkbox-alignment">
                        <div class="col col-12 col-md-6 label-lg">
                            <b-form-checkbox
                                automation_id="chk_include_user_on_draft_email_cc_line"
                                id="chk_include_user_on_draft_email_cc_line"
                                v-model="editedData.includeUserOnDraftEmailCCLine" :disabled="readOnly">Include User on Draft Email CC Line</b-form-checkbox>
                        </div>
                    </div>
                    <div class="row checkbox-alignment">
                        <div class="col col-12 col-md-6 label-lg">
                            <b-form-checkbox
                                automation_id="chk_allow_bcc_on_auto_email"
                                id="chk_allow_bcc_on_auto_email"
                                v-model="editedData.allowBCConAutoEmails" :disabled="readOnly">Allow BCC on Auto Emails</b-form-checkbox>
                        </div>
                    </div>
                </rq-page-section>
            </div>
         </rq-page-section>
    </div>
</template>

<script>
import { GlobalEventManager } from "@/app.events";
import RegEx from "@/shared/utilities/RegEx";
import { mapState, mapGetters } from "vuex";
import { WebDefaultsDto }  from "../../models";
import EmailModal from "@/shared/components/rq/dialogs/EmailForm";

export default {
    name: 'EmailAutomation',
    props: { },
    data() {
        return {
            originalData: {},
            editedData: {},
            errors: []
        }
    },

    computed: {
        ...mapGetters([
            "lookupHelpers",
            "lookupItems"
        ]),
        ...mapState({
            userId: state => state.authentication.session.userId,
            readOnly: state => state.isPageReadOnly,
        }),
        itemTypeDescription() { return _.get(this.$route.meta, "itemTypeDescription") || ""; },
        itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
    },
    created(){
        const self = this;
        GlobalEventManager.onSave(self, self.onSave);
        self.fetchData();
    },
    beforeUnmount() {
        GlobalEventManager.unregister(this);
    },
    methods :{
        onCancel(){
            const self = this;
            let original = new WebDefaultsDto(self.originalData);
            let newValues = new WebDefaultsDto(self.editedData);
            let changes = self.getAuditChanges(original, newValues);
                let ok = function (args) {
                self.fetchData();
            };
            if (changes.length === 0) {
                self.$toast.info("No changes detected");
            } else {
                self.$dialog.confirm("Confirm Cancel", "Discard changes and reload data?", ok);
            }
        },
        fetchData () {
            const self = this;
            let apiPromise = self.$api.WebDefaultsApi.getWebDefaults();
            return self.$rqBusy.wait(apiPromise)
                .then(result => {
                    self.originalData = result[0];
                    self.editedData = new WebDefaultsDto(self.originalData);
                    return result;
                })
                .catch(error => {
                    console.error(error);
                    self.$toast.error({ message: 'Error loading updateWebDefaults information.' });
                    return error;
                });
        },
        updateItem(args) {
           const self = this;
           if (!self.isValid({ data: new WebDefaultsDto(self.editedData) })){
                let errMsg = '';
                 _.forEach(self.errors, (item) => {
                        errMsg += item + '<br>';
                });

                self.$dialog.confirm(`Updating Error`, errMsg);
                return Promise.resolve(false);
            }
            let newValues = new WebDefaultsDto(self.editedData);
            let original = new WebDefaultsDto(self.originalData);
            let changes = self.getAuditChanges(original, newValues);
            if (changes.length > 0) {
                let apiPromise = self.$api.WebDefaultsApi.updateWebDefaults(newValues, changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        GlobalEventManager.saveCompleted({ success: true });
                        self.originalData = new WebDefaultsDto(newValues);
                        self.$toast.success({ message: `E-mail automation was saved.` }); return true;
                    })
                    .catch(error => {
                        GlobalEventManager.saveCompleted({ success: false });
                        self.$toast.error({ message: 'Error updating E-mail automation.' });
                        return Promise.resolve(false) ;
                    });
            } else {
                if (!_.isEmpty(args)) { self.$toast.info("No changes detected"); }
                GlobalEventManager.saveCompleted({ success: true });
                return Promise.resolve(false) ;
            }
        },
        onSave(e) {
            return this.save(e);
        },
        save(args={}) {
            const self = this;
            return self.updateItem(args);
        },
        isValid(params){

            const self = this;
            self.errors = [];
            if (isNaN(this.editedData.smtpPort))
            {
                self.errors.push(`Invalid SMTP Port`);
            }
            if (!RegEx.Email.test(this.editedData.defaultEmailAddress))
            {
                self.errors.push(`Invalid Email Format / example@email.com`);
            }
            return self.errors.length === 0;

        },
        sendTestEmail(){
            const self = this;

            let promise = self.$api.UsersApi.getUser(self.userId).then(
                userData => {
                    let props = { to: userData.user.emailAddress, subject: "Test Email", body: "Test Email" };
                    self.$dialog.open({
                        title: "Send Test Email",
                        width: "90%",
                        height: "90%",
                        okTitle: "Send",
                        component: EmailModal,
                        props,
                        onOk(e) { return e.component.onOk(); }
                    });
                }
            );

            self.$rqBusy.wait(promise);
            return promise;

        }

    }
}
</script>
