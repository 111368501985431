<template>
    <div class="content-wrapper">
        <rq-page-section :title="itemTypeNamePlural" borderless header-size="lg">
             <template #header-actions>
                <ul class="nav config-actions ms-auto">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_cancel"
                            variant="action"
                            v-rq-tooltip.hover.top title="Discard changes and reload data"
                            @click="onCancel"
                            :disabled="readOnly">Cancel
                        </b-btn>
                    </li>
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_save"
                            variant="action"
                            @click="onSave"
                            :disabled="readOnly">Save
                        </b-btn>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
            <rq-section-card title="Options" collapsible>
                <div class="row">
                    <div class="col-md-6 col-lg-5 mb-lg-0 mb-xl-3 checkbox-group label-lg">
                        <b-form-checkbox automation_id="chk_showExpectedWireDates" id="showExpectedWireDates" v-model="systemDefaults.showExpectedWireDates" :disabled="readOnly">Show Expected Wire Dates </b-form-checkbox>
                        <b-form-checkbox automation_id="chk_showReissueInMemoField" id="showReissueInMemoField" v-model="systemDefaults.showReissueInMemoField" :disabled="readOnly">Show Re-Issue in Memo Field </b-form-checkbox>
                        <b-form-checkbox automation_id="chk_enforceDateValidationsOfDepositSlips" id="enforceDateValidationsOfDepositSlips" v-model="systemDefaults.enforceDateValidationsOfDepositSlips" :disabled="readOnly">Enforce Date Validations of Deposit Slips</b-form-checkbox>
                        <b-form-checkbox automation_id="chk_closeFileOnZeroBalance" id="closeFileOnZeroBalance" v-model="systemDefaults.closeFileOnZeroBalance" :disabled="readOnly">Close File upon Zero Balance</b-form-checkbox>
                        <b-form-checkbox automation_id="chk_receiptsAndDisbursementsSortedByDate" id="receiptsAndDisbursementsSortedByDate" v-model="systemDefaults.receiptsAndDisbursementsSortedByDate" :disabled="readOnly">R &amp; D Sort by Date</b-form-checkbox>
                        <b-form-checkbox automation_id="chk_onlySystemAdminsCanEditAccountingCodes" id="onlySystemAdminsCanEditAccountingCodes" v-model="systemDefaults.onlySystemAdminsCanEditAccountingCodes" :disabled="readOnly">Only System Admins can Edit Account Codes</b-form-checkbox>
                        <b-form-checkbox automation_id="chk_permissibleUseDocumentRequired" id="permissibleUseDocumentRequired" v-model="systemDefaults.permissibleUseDocumentRequired" :disabled="readOnly">Permissible Use Document Required</b-form-checkbox>
                    </div>
                    <div class="col-md-6 col-lg-7 checkbox-group label-lg">
                        <b-form-checkbox automation_id="chk_showExpectedWireOutDates" id="showExpectedWireOutDates" v-model="systemDefaults.showExpectedWireOutDates" :disabled="readOnly">Show Expected Wire Out Dates</b-form-checkbox>
                        <b-form-checkbox automation_id="chk_useAutoGeneratedWireNumbers"
                                                    id="useAutoGeneratedWireNumbers"
                                                    v-model="systemDefaults.useAutoGeneratedWireNumbers"
                                                    :disabled="readOnly || systemDefaults.wireOutWireNumberRequired"
                                                    v-rq-tooltip.hover.left="useAutoGeneratedWireNumbersToolTip"
                                                    >Use Auto Generated Wire Numbers</b-form-checkbox>
                        <b-form-checkbox automation_id="chk_allowNegatibeDepositsOnDepositsSlips" id="allowNegatibeDepositsOnDepositsSlips" v-model="systemDefaults.allowNegatibeDepositsOnDepositsSlips" :disabled="readOnly">Allow Negative Deposits on Deposit Slips</b-form-checkbox>
                        <b-form-checkbox automation_id="chk_closeFileOnZeroBalanceAndDate" id="closeFileOnZeroBalanceAndDate" v-model="systemDefaults.closeFileOnZeroBalanceAndDate" :disabled="readOnly">Close File upon Zero Balance and After Close Date</b-form-checkbox>
                        <b-form-checkbox automation_id="chk_useCheckPrinterForPrintingDepositSlips" id="useCheckPrinterForPrintingDepositSlips" v-model="systemDefaults.useCheckPrinterForPrintingDepositSlips" :disabled="readOnly">Use Check Printer for Printing Deposit Slips</b-form-checkbox>
                        <b-form-checkbox automation_id="chk_onlySystemAdminsCanChangeEscrowAccounting" id="onlySystemAdminsCanChangeEscrowAccounting" v-model="systemDefaults.onlySystemAdminsCanChangeEscrowAccounting" :disabled="readOnly">Only System Admins can change Escrow Accounts for a File</b-form-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 col-lg-6 col-xl-4 form-group">
                        <label for="checkDisplay">Check Display</label>
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_checkDisplay', id: 'drp_checkDisplay' }"
                            :items="checkPrintingOptions"
                            value-expr="id"
                            display-expr="name"
                            v-model="systemDefaults.checkDisplay"
                            :search-enabled="true"
                            :disabled="readOnly"
                        />
                    </div>
                    <div class="col-md-12 col-lg-6 col-xl-5 offset-xl-1 form-group">
                        <label for="goodFundDefinition text-truncate">Restrict Disbursements to Available Funds only</label>
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_goodFundDefinition', id: 'drp_goodFundDefinition' }"
                            :items="goodFundDefinitionOptions"
                            value-expr="id"
                            display-expr="name"
                            v-model="systemDefaults.goodFundDefinition"
                            :search-enabled="true"
                            :disabled="readOnly"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 col-lg-6 col-xl-4 form-group">
                        <label for="outgoingWireRequest">Outgoing Wire Request</label>
                        <rq-document-template-select
                            id="txt_outgoingWireRequest"
                            v-model:document-name="systemDefaults.wireOutRequestDocumentTemplate"
                            v-model="systemDefaults.wireOutRequestDocumentTemplateID"
                            :disabled="readOnly"
                        />
                    </div>
                    <div class="col-md-12 col-lg-6 col-xl-4 offset-xl-1 form-group">
                        <rq-action-form-group
                            label="Time Zone"
                            labelFor="drp_timeZone"
                            required
                            :hasError="v$.systemDefaults.timeZone.$error"
                        >
                            <template #default>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_timeZone' }"
                                    :items="timezones"
                                    display-expr="display"
                                    value-expr="zoneId"
                                    :search-expr="['display']"
                                    :disabled="readOnly"
                                    v-model="v$.systemDefaults.timeZone.$model">
                                </dx-select-box>
                            </template>
                            <template #below>
                                <b-form-checkbox
                                    automation_id="chk_daylightSavingsEnabled"
                                    id="daylightSavingsEnabled"
                                    v-model="systemDefaults.daylightSavingsEnabled"
                                    :disabled="readOnly">
                                    Daylight Savings Enabled?
                                </b-form-checkbox>
                            </template>
                        </rq-action-form-group>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-md-6 col-lg-5 mb-lg-0 mb-xl-3 checkbox-group label-lg">
                        <b-form-checkbox automation_id="chk_useMoneyMovementApproval" id="useMoneyMovementApproval" v-model="systemDefaults.useMoneyMovementApproval" :disabled="readOnly">Use Financial Approval Thresholds</b-form-checkbox>
                    </div>
                </div>
                <div class="row" v-if="systemDefaults.useMoneyMovementApproval">
                    <div class="col-md-12 col-lg-6 col-xl-4 form-group ms-3">
                        <label for="consolidatedDisbursementAdjustmentThreshold">Consolidated Disbursement Adjustment Threshold</label>
                        <rqInputNumber
                            automation_id="txt_consolidated_disbursement_adjustment_threshold"
                            formatType="money"
                            defaultValue="0"
                            :minValue="0"
                            decimals="2"
                            input-group
                            no-prefix
                            v-focus-select-all
                            prependIcon="fas fa-dollar-sign"
                            v-model="systemDefaults.consolidatedDisbursementApprovalThreshold"
                            :disabled="readOnly"/>
                    </div>
                </div>
                <div class="row" v-if="systemDefaults.useMoneyMovementApproval">
                    <div class="col-md-12 col-lg-6 col-xl-4 form-group ms-3">
                        <label for="disbursementApprovalThreshold">Disbursement Approval Threshold</label>
                        <rqInputNumber
                            automation_id="txt_disbursement_approval_threshold"
                            formatType="money"
                            defaultValue="0"
                            :minValue="0"
                            decimals="2"
                            input-group
                            no-prefix
                            v-focus-select-all
                            prependIcon="fas fa-dollar-sign"
                            v-model="systemDefaults.disbursementApprovalThreshold"
                            :disabled="readOnly"/>
                    </div>
                </div> -->
            </rq-section-card>
         </rq-page-section>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import SystemDefaultsFormMixin from "../../shared/SystemDefaultsFormMixin";
    import { CheckPrintingOptions } from '../../enums';
    import { GoodFundDefinitionOptions } from '../../enums';
    import RqDocumentTemplateSelect from "@/shared/components/rq/RqDocumentTemplateSelect";
    import { required } from '@vuelidate/validators';
    import { useVuelidate } from "@vuelidate/core";

    export default {
        name: "EscrowAccountSettingsForm",
        mixins: [SystemDefaultsFormMixin],
        components: { RqDocumentTemplateSelect },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                timezones:  [
                            { zoneId: "Pacific/Honolulu", display: "UTC-10 - US/Hawaii" },
                            { zoneId: "America/Anchorage", display: "UTC-9 - US/Alaska" },
                            { zoneId: "America/Los_Angeles", display: "UTC-8 - US/Pacific" },
                            { zoneId: "America/Phoenix", display: "UTC-7 - US/Arizona" },
                            { zoneId: "America/Denver", display: "UTC-7 - US/Mountain" },
                            { zoneId: "America/Chicago", display: "UTC-6 - US/Central" },
                            { zoneId: "America/New_York", display: "UTC-5 - US/Eastern" }
                            ],
            }
        },
        computed: {
            ...mapState({
                readOnly: state => _.parseBool(state.isPageReadOnly),
            }),
            checkPrintingOptions(){ return CheckPrintingOptions.lookupItems; },
            goodFundDefinitionOptions() {return GoodFundDefinitionOptions.lookupItems;},
            useAutoGeneratedWireNumbersToolTip (){ return this.readonly ? '' : this.systemDefaults.wireOutWireNumberRequired ? 'Wire Number field cannot be required if you wish to turn on Auto-Generated Wire Numbers.' : ''}
        },
        validations() {
            var result = {
                systemDefaults: {
                    timeZone: {
                        required
                    },
                }
            }
            return result;
        },
    }
</script>
