<template>
    <div class="rq-container pt-3">
        <div class="form-group form-required">
            <label for="txt_companyName">Underwriter</label>
            <input
                type="text"
                class="form-control"
                disabled
                readonly
                :value="item.underwriter">
        </div>
        <div class="form-group">
            <label for="dtp_lockdate">Lock Date</label>
            <rqdx-date-box
                id="dtp_lockdate"
                v-model="item.remitLockDate"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: "LockRemitForm",
        props: {
            item: { type: Object, default: () => ({}) }
        }
    }
</script>