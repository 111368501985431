<template>
    <div class="content-wrapper documentPackage">
        <rq-banner
            variant="error"
            title="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && v$.$error" />
        <div class="rq-container">
            <div class="row">
                <div class="col col-3 col-sm-12 col-md-4 col-lg-4 form-group  form-required" :class="{'has-error' : v$.item.regionID.$anyDirty && v$.item.regionID.greaterThanZero.$invalid}">
                    <label for="region">Region</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_region', id: 'drp_region' }"
                        :items="regionsLookup"
                        value-expr="regionID"
                        display-expr="displayName"
                        v-model="v$.item.regionID.$model"
                        :search-enabled="true"
                    />
                    <rq-validation-feedback
                        message="Region is required."
                    />
                </div>
                <div class="col col-3 col-sm-12 col-md-4 col-lg-4 form-group  form-required" :class="{'has-error' : v$.item.description.$error || !v$.item.uniqueDescription }">
                    <label for="description">Description</label>
                    <input
                        automation_id="txt_description"
                        v-model="v$.item.description.$model"
                        type="text"
                        class="form-control"
                        id="description"
                        placeholder="Description"
                        maxlength="1000" >
                    <rq-validation-feedback
                        :messages="{
                            'Description is required.': v$.item.description.$error,
                            'A Document Package already exists with that description.': !v$.item.uniqueDescription
                        }"
                    />
                </div>
                <div class="col col-3 col-sm-12 col-md-4 col-lg-4 form-group form-required"  :class="{'has-error' : v$.item.defaultUserSecurityAccess.$error}">
                    <label for="drp_default_user_security">Default User Security</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_default_user_security', id: 'drp_default_user_security' }"
                        :items="defaultUserSecurity"
                        value-expr="id"
                        display-expr="name"
                        v-model="v$.item.defaultUserSecurityAccess.$model"
                        :search-enabled="true"
                    />
                </div>
            </div>
        </div>
         <rq-page-section
            title="Assign Document Templates"
            borderless
            flex-full
            flex-body>
            <grid-selector
                id="documentGridSelector"
                ref="documentGridSelector"
                :items="availableTemplatesList"
                :item-key="docItemKey"
                item-name="Document"
                sort-description-field="description"
                sort-field="sequence"
                :grid-configs="gridConfigs"
                v-model="selectedTemplateIDs"
                @reorder="onSelectedTasksReorder"
                flex-layout
            />
        </rq-page-section>

    </div>

</template>
<script>
    import { mapGetters } from "vuex";
    import { DocumentTemplateDto }  from "../models";
    import { DefaultUserSecurity } from '../../enums';
    //import RqSelectionListBox from "@/shared/components/rq/list-box/RqSelectionListBox";
    import GridSelector from "../../shared/GridSelector";
    import { required } from "validators";
    import { useVuelidate } from "@vuelidate/core";

    export default {
        name: 'DocumentPackageForm',
        components: {  GridSelector },
        props: {
            item: {type: Object, default: null},
            documentTemplates: {type: Array, default: null},
            uniqueValidator: { type: Function, default: (() => true) }
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                validationErrors: [],
                originalTemplateList: [],
                availableTemplatesList: [],
                selectedTemplateIDs: [],
                showBanner: false
            }
        },
        watch: {
            selectedTemplateIDs: {
                handler(newValue, oldValue) {
                    this.setSequence(this.availableTemplatesList, this.selectedTemplateIDs);
                }
            }
        },
        computed: {
            ...mapGetters([
                "regionsLookup",
            ]),
            defaultUserSecurity() { return  DefaultUserSecurity.lookupItems; }
        },

        validations: () => ({
            item: {
                description: { required },
                defaultUserSecurityAccess: { required, greaterThanZero: (val, vm) => {
                    return !_.isNil(val) && val > -1;
                }},
                regionID: { required, greaterThanZero: (val, vm) => {
                    return !_.isNil(val) && val > 0;
                }},
                uniqueDescription: (val, vm) => vm.uniqueValidator(val, "description")
            }
        }),

        created(){
            const self = this;
            self.fetchData();
            self.initNonReactiveVariables();
            self.initGridConfig();
        },

        methods: {
            initGridConfig(){
                const self = this;
                self.gridConfigs = {
                    available: {
                        height: "100%",
                        columns: [
                            { dataField: "description", dataType: "string", sortIndex: 0, sortOrder: "asc", }
                        ]
                    },
                    selected: {
                        height: "100%",
                        sorting: { mode: "none" },
                        columns: [
                            { dataField: "sequence", visible: false, sortIndex: 0, sortOrder: "asc", allowSearch: false },
                            { dataField: "description", dataType: "string", }
                        ]
                    }
                };
            },
            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = "Document Package";
                self.itemTypeDescription = _.get(self.$route.meta, "itemTypeDescription");
                self.docItemKey = "documentTemplateID";
            },
            onSelectedTasksReorder(e) {
                for (var i = 0; i < e.length; i++){
                    let itemIndex = _.findIndex(this.availableTemplatesList, t =>
                                                t.documentTemplateID === e[i]);
                    if (itemIndex > -1){
                        this.availableTemplatesList[itemIndex].sequence = (i+1) * 10;
                    }
                }
                this.selectedTemplateIDs = _.clone(e);
            },
           fetchData() {
                const self = this;
                let apiPromises = [];
                apiPromises.push(self.$api.DocumentTemplatesApi.getByPackageId(self.item.documentPackageID));
                apiPromises.push(self.$api.DocumentTemplatesApi.getListVisible());
                return self.$rqBusy.wait(Promise.all(apiPromises))
                    .then(result => {
                        self.item.documentTemplates = _.map(_.clone(result[0]), i => new DocumentTemplateDto(i));
                        self.selectedTemplateIDs = _.map(_.clone(result[0]),'documentTemplateID');
                        self.setSequence(result[1], self.selectedTemplateIDs);
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading Document Templates.` });
                        return error;
                    });
            },
            setSequence(items, selectedItemIDs){
                const self = this;
                let templates = _.map(_.clone(items), i => new DocumentTemplateDto(i));
                _.forEach (selectedItemIDs, (ID, index) => {
                        let itemIndex = _.findIndex(templates, t => t.documentTemplateID === ID);
                        if (itemIndex == -1) return;
                        templates[itemIndex].sequence = index + 1;
                    })
                self.availableTemplatesList = _.clone(templates);
            },
            save(){
                const self = this;
                self.showBanner = true;
                self.v$.$touch();
                let isEqual = _.isEqual(self.selectedTemplateIDs, _.map(self.item.documentTemplates, 'documentTemplateID'));
                if (!self.v$.$anyDirty && isEqual) { return Promise.resolve(true); }
                if (self.v$.$error) return Promise.resolve(false);

                if(!isEqual) {
                    self.item.documentTemplates = _.map(_.clone(self.selectedTemplateIDs), i => new DocumentTemplateDto({ 'documentTemplateID': i}));
                    self.item.documentTemplates.forEach((template, index) => { template.sequence = index + 1 })
                }

                let apiPromise = self.$api.DocumentPackagesApi.save([self.item.toDataObject()]);
                return self.$rqBusy.wait(apiPromise)
                    .then(item => {
                        self.$toast.success("Document Package saved successfully.");
                        return item;
                    }).catch(err => {
                        self.$toast.error("An error occurred while saving this document package.");
                        console.error(err);
                        return err;
                    });
            },
            updateDimensions: _.debounce(function(e) {
                let gridHeight = Math.floor(e.contentHeight - 270);
                _.invoke(this, "$refs.documentGridSelector.updateHeight", gridHeight);
            }, 385, { "leading": true, "trailing": true })

        }
    }
</script>
