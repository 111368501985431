<template>
    <div class="content-wrapper" v-if="hasData">
        <rq-banner
            variant="error"
            title="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="showValidationMessage"
        />
        <rq-page-section :title="editTitle" headerSize="lg" header-border borderless scrollable>
            <template #header-actions>
                <ul class="nav config-actions ms-auto">
                    <li class="nav-item form-group mb-0">
                        <b-form-checkbox
                            automation_id="chk_inactive"
                            id="chk_inactive"
                            v-model="userGroupData.userGroup.isInactive"
                            >Inactive</b-form-checkbox>
                    </li>
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_cancel"
                            variant="action"
                            v-rq-tooltip.hover.top title="Discard changes and reload data"
                            @click="onCancel">CANCEL</b-btn>
                    </li>
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_save"
                            variant="action"
                            :disabled="disableSave"
                            @click="onSave">SAVE</b-btn>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <b-btn
                    automation_id="lnk_back"
                    :to="{ name:'cfg:ent:users', params: { tab: 1 } }"
                    variant="link"
                    class="btn-theme mt-2"
                >View Security Roles</b-btn>
            </template>
            <div class="role-settings">
                <div class="row">
                    <div class="col col-4 form-group">
                        <label class="form-control-label" for="txt_config_search_term">Search Settings:</label>
                        <rq-search-input-group
                            id="txt_config_search_term"
                            v-model="searchText"
                            placeholder="Search..."
                            tabindex="0"
                        />
                    </div>
                    <div class="col-auto ms-auto mb-1 d-flex align-items-end">
                        <rq-section-expand-collapse-all />
                    </div>
                </div>
                <component
                    v-for="(section,index) in sections"
                    :key="index"
                    :is="section.component"
                    :label="section.label"
                    :search-term="searchText"
                    v-model:role-security-data="userGroupData"
                />
            </div>
        </rq-page-section>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { GlobalEventManager } from "@/app.events";
    import { UserGroupDataDto }  from "../models";
    import * as SectionComponents from "./security-role-sections";
    import useVuelidate from '@vuelidate/core';

    export default {
        name: "UserGroupForm",
        components: { ...SectionComponents },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                disableSave: false,
                item: {},
                userGroupData: {},
                originalData: {},
                searchText: "",
                sections: [
                    { label: "Administration", component:"administration-section" },
                    { label: "Calculators and Invoices", component:"calc-and-ss-section" },
                    { label: "Companies", component:"companies-section" },
                    { label: "Workflow", component:"workflow-section" },
                    { label: "Title Production/Policy", component:"title-policy-section" },
                    { label: "Check Writing", component:"check-writing-section" },
                    { label: "General", component:"general-section" },
                    { label: "Escrow", component:"escrow-section" },
                    { label: "Documents & Reports", component:"docs-reports-section" },
                    { label: "File Permissions", component:"audit-log-files-section" },
                    { label: "Access", component:"access-section" }
                ],
                validationErrors: []
            };
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            editTitle() {
                return `Edit ${this.entityName}: ${_.get(this.userGroupData, "userGroup.login", _.get(this, "$route.params.displayName", "(Blank)"))}`;
            },
            groupUsersID() {
                return _.getNumber(this, "$route.params.groupUsersID", 0)
                    || _.getNumber(this, "userGroupData.userGroup.groupUsersID", 0);
            },
            hasData() { return !_.isEmpty(_.get(this.userGroupData, "userGroup")); },
            showValidationMessage() { return !_.isEmpty(this.validationErrors); }
        },

        created() {
            GlobalEventManager.onSave(this, this.onSave);
            this.$events.on("disable-save", this.onDisableSave);
            this.fetchData();
            this.initNonReactiveVariables();
        },

        beforeUnmount() {
            GlobalEventManager.unregister(this);
            this.$events.off("disable-save");
        },

        methods: {
            initNonReactiveVariables() {
                const self = this;
                self.entityName = _.get(self.$route.meta, "label");
                self.entityDescription = _.get(self.$route.meta, "itemTypeDescription");
                //add all lookup assignments here that are in global
            },
            onDisableSave(e) {
               this.disableSave = e;
               if (this.disableSave) {
                   this.validationErrors.push('Please correct the highlighted errors on screen to continue.');
               } else {
                   this.validationErrors = [];
               }
            },
            onSave(e) {
               return this.save(e);
            },
            onCancel(e) {
                const self = this;
                let changes = self.getAuditChanges(self.originalData, self.userGroupData);
                let ok = function (args) {
                    self.userGroupData = new UserGroupDataDto(_.clone(self.originalData));
                };
                if (changes.length === 0) {
                    self.$toast.info("No changes detected");
                } else {
                    self.$dialog.confirm("Confirm Cancel", "Discard changes and reload data?", ok);
                }
            },
            fetchData() {
                const self = this;
                if(self.groupUsersID <= 0) return;
                let apiPromise = self.$api.UsersApi.getGroup(self.groupUsersID);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.userGroupData = new UserGroupDataDto(_.clone(result));
                        self.originalData = new UserGroupDataDto(_.clone(result));
                        //add all lookup assignments here that are NOT in global
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.rateItemNamePlural}.` });
                    });
            },
            save(args={}) {
                const self = this;

                if (_.isFunction(_.get(self, 'v$.$touch'))) {
                    self.v$.$touch();
                    if(self.v$.$error) {
                        GlobalEventManager.saveCompleted({ success: false, abort: true });
                        return Promise.resolve(false);
                    }
                }
                self.validationErrors = [];
                let changes = self.getAuditChanges(self.originalData, self.userGroupData);
                if (_.isEmpty(args) && changes.length < 1){
                    GlobalEventManager.saveCompleted({ success: true });
                    return Promise.resolve(true);
                }
                if (changes.length > 0) {
                    let apiPromise = self.$api.UsersApi.saveSecurityRoleData(self.userGroupData, changes);
                    return self.$rqBusy.wait(apiPromise)
                        .then(result => {
                            self.$toast.success({ message: `${self.entityName} was saved.` });
                            self.userGroupData = new UserGroupDataDto(_.clone(result));
                            self.originalData = new UserGroupDataDto(_.clone(result));
                            GlobalEventManager.saveCompleted({ success: true });
                            return Promise.resolve(true) ;
                        })
                        .catch(error => {
                            self.$toast.error(`Error Saving ${self.entityName}`);
                            GlobalEventManager.saveCompleted({ success: false });
                            return Promise.resolve(false);
                        });
                } else {
                    self.$toast.info("No changes detected");
                    GlobalEventManager.saveCompleted({ success: true });
                    return Promise.resolve(true) ;
                }
            }
        }
    }
</script>
