<template>
    <div class="content-wrapper enterprise" :key="$route.name">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        beforeRouteUpdate(to, from, next) {
            _.invoke(this, "$parent.scrollToTop");
            next();
        }
    };
</script>