import { DateTimeHelper } from '@/shared/utilities';
import { TypeOfPolicies } from '../enums';
import { DocumentListFormat, StandardLanguageDto } from "@/shared/models/models";

// export class PolicyNumberRangeDto {
//     constructor(options) {
//         options = options || {};
//         this.policyNumberRangeID = _.parseNumber(options.policyNumberRangeID, 0);
//         this.description = options.description || null;
//         this.endNumber = options.endNumber|| null;
//         this.startNumber = options.startNumber|| null;
//         this.underwriterCompanyID = _.parseNumber(options.underwriterCompanyID, null);
//         this.underwriterDisplay = options.underwriterDisplay|| null;
//         this.regionID = _.parseNumber(options.regionID,0);
//         this.regionDisplay = options.regionDisplay|| null;
//         this.readOnly = _.parseBool(options.readOnly);
//     }

//     get isNew() { return this.policyNumberRangeID == 0; }
//     get isValid() { return this.validationErrors.length === 0; }
//     get validationErrors()
//     {
//         let errorList = [];
//         if (this.regionID == 0) {
//             errorList.push("Region is required.");
//         }
//         if (this.underwriterCompanyID == 0) {
//             errorList.push("Underwriter is required.");
//         }
//         if (_.isEmpty(this.description)) {
//             errorList.push("Description is required.");
//         }
//         return errorList;
//     }

//     toDataObject(){ return _.toPlainObject(this); }
// }

export class PolicyNumberPoolDto {
	constructor(options) {
        options = options || {};
        this.policyNumberPoolID = _.parseNumber(options.policyNumberPoolID, -1);
        this.description = options.description || null;
        this.prefix = options.prefix || null;
        this.suffix = options.suffix || null;
        this.underwriterCompanyID = _.parseNumber(options.underwriterCompanyID, 0);
        this.regionID = _.parseNumber(options.regionID, null);
        this.regionDisplay = options.regionDisplay|| null;
        this.numChars = options.numChars || null;
        //this.policyNumberRangeID = _.parseNumber(options.policyNumberRangeID, null);
        this.typePolicies = _.parseNumber(options.typePolicies, null);
        this.startNumber = _.parseNumber(options.startNumber, null);
        this.endNumber = _.parseNumber(options.endNumber, null);
        this.isInactive = _.parseBool(options.isInactive);
    }

    get typePoliciesName() { return TypeOfPolicies.displayValue(this.typePolicies) || "Unknown"; }

    get isNew() { return this.policyNumberPoolID <= 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.parseNumber(this.regionID,0) == 0) {
            errorList.push("Region is required.");
        }
        if (_.parseNumber(this.underwriterCompanyID,0) == 0) {
            errorList.push("Underwriter is required.");
        }
        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }
        if (_.parseNumber(this.policyNumberRangeID,0) == 0) {
            errorList.push("Number Range is required.");
        }
        if (_.parseNumber(this.numChars,0) == 0) {
            errorList.push("Number of Characters is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class UnderwriterRemitLockDateDto {
    constructor (options) {
        options = options || {};
        this.underwriterRemitLockDateID = _.parseNumber(options.underwriterRemitLockDateID, 0);
        this.underwriterCompanyID = _.parseNumber(options.underwriterCompanyID, 0);
        this.underwriter = options.underwriter || null;
        this.remitLockDate = options.remitLockDate || null;
    }

    get isNew() { return this.underwriterRemitLockDateID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get hasRemitDate() { return this.remitLockDate != null; }

    get validationErrors() {
        let errorList = [];
        if (this.remitLockDate != null && !DateTimeHelper.isValidDate(this.remitLockDate)) {
            errorList.push("Invalid Lock Date.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class CustomTabItem {
    constructor(options) {
        options = options || { };
        this.id = _.parseNumber(options.id, 0) || _.parseNumber(_.uniqueId());
        this.parentId = _.parseNumber(options.parentId, 0);
        this.label = options.label || "";
        this.sequence = _.parseNumber(options.sequence, 999);
        this.display = _.parseBool(options.display, true);
        this.parentDisplay = _.parseBool(options.parentDisplay, true);
        this.tabId = _.parseNumber(options.tabId, 0);
        this.sectionId = _.parseNumber(options.sectionId, 0);
        this.regionID = _.parseNumber(options.regionID, null);
        this.globalSequence = _.parseNumber(options.globalSequence, 999);
        this.isDeleted = _.parseBool(options.isDeleted);
        this.data = options.data || { canDelete: true };
    }

    get canDelete() {return _.parseBool(this.data.canDelete, true); }

    static fromDto(item, parent=null) {
        if(!(item instanceof TitleProdCustomTabDto || item instanceof StandardLanguageSectionDto)) {
            return new CustomTabItem(item);
        }
        let sequence = _.parseNumber(item.displayOrder);
        return new CustomTabItem({
            parentId: _.isNil(parent) ? 0 : parent.id,
            label: (_.isNil(parent) ? item.tabLabel : item.sectionLabel) || "",
            sequence,
            globalSequence: _.isEmpty(item) ? 999 : _.isNil(parent) ? sequence : parent.sequence + (sequence/10),
            display: _.parseBool(item.display, true),
            parentDisplay: _.isNil(parent) ? true : _.parseBool(parent.display),
            tabId: _.isEmpty(item) ? _.parseNumber(_.uniqueId()) * -1 : _.parseNumber(item.titleProdCustomTabID, 0),
            sectionId: _.isEmpty(item) ? 0 : _.parseNumber(item.standardLanguageSectionID, 0),
            regionID: _.isNil(parent) ? _.isNil(item) ? null : _.parseNumber(item.regionID, null) : _.parseNumber(parent.regionID, null),
            data: item
        });
    }

    toDataObject(parent=null) {
        let result = {
            titleProdCustomTabID: this.tabId,
            display: this.display,
            displayOrder: this.sequence,
            regionID: this.regionID,
            isDeleted: this.isDeleted
        };
        if(this.parentId > 0) {
            result.standardLanguageSectionID = this.sectionId;
            result.sectionLabel = this.label;
            if(!_.isNil(parent)) {
                result.name = `${parent.label}_${this.label}`;
                result.description = `${parent.label} ${this.label}`;
            }
        }
        else {
            result.tabLabel = this.label;
        }
        return result;
    }
}

export class CustomTabNode extends CustomTabItem {
    constructor(item, parent=null) {
        super(item, parent);
        this.sections = [];
    }
}

export class TitleProdCustomTabDto {
	constructor(options, defaultRegionId=null) {
        options = options || {};
        this.titleProdCustomTabID = _.parseNumber(options.titleProdCustomTabID, 0);
        this.tabLabel = options.tabLabel || null;
        this.displayOrder = _.parseNumber(options.displayOrder, null);
        this.display = _.parseBool(options.display, true);
        this.regionID = _.parseNumber(options.regionID, defaultRegionId);
        this.canDelete = _.parseBool(options.canDelete);
    }

    get isNew() { return this.titleProdCustomTabID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isNullOrEmpty(this.tabLabel)) {
            errorList.push("Tab Label is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

const getSystemTabLabel = section => {
    if(section.titleProdCustomTabID > 0) return "Custom";
    let firstIndex = _.indexOf(section.description, " ");
    let secondIndex = _.indexOf(section.description, " ", firstIndex+1);
    return section.description.substr(0, secondIndex);
};

export class StandardLanguageSectionDto {
    constructor(options) {
        options = options || {};
        this.standardLanguageSectionID = _.parseNumber(options.standardLanguageSectionID, 0);
        this.id = this.standardLanguageSectionID;
        this.name = options.name || null;
        this.description = options.description || null;
        this.sectionLabel = options.sectionLabel || null;
        this.displayOrder = _.parseNumber(options.displayOrder, null);
        this.display = _.parseBool(options.display, true);
        this.canDelete = _.parseBool(options.canDelete);
        this.categories = _.isEmpty(options.categories) ? [] : _.map(options.categories, cat => new StandardLanguageSectionCategoryDto(cat));
        this.defaultCategoryId = _.parseNumber(options.defaultCategoryId, null);

        this.titleProdCustomTabID = _.parseNumber(options.titleProdCustomTabID, null);
        this.tabLabel = options.tabLabel || getSystemTabLabel(options);
        this.tabDisplayOrder = _.parseNumber(options.tabDisplayOrder, -1);
    }

    get isNew() { return this.titleProdCustomTabID == 0; }

    get isCustom() { return _.parseNumber(this.titleProdCustomTabID, 0) > 0; }

    setCustomTabInfo({ tabLabel=null, displayOrder=-1 }={ tabLabel: null, displayOrder: -1 }) {
        if(!_.isNil(tabLabel)) this.tabLabel = tabLabel;
        if(_.parseNumber(displayOrder, -1) >= 0) this.tabDisplayOrder = _.parseNumber(displayOrder, 0);
    }

    toDataObject() { return _.toPlainObject(this); }

    static get systemDefaultFields() {
        return {
            "ScheduleBRequirements": "ListTypeRequirements",
            "ScheduleBExceptions": "ListTypeExceptions",
            "ScheduleBSubordinateLiens": "ListTypeSubordinateLiens",
            "ScheduleBRequiredDocuments": "ListTypeRequiredDocuments",
            "ScheduleBRestrictiveCovenants": "ListTypeRestrictiveCovenants",
            "ScheduleCRequirements": "ListTypeRequirements",
            "OwnerPolicyExceptions": "ListTypeOwnerSchedB",
            "LoanPolicyExceptions": "ListTypeMortSchedBExceptions",
            "LoanPolicyRestrictiveCovenants": "ListTypeMortSchedBResCov",
            "LoanPolicySubordinateLiens": "ListTypeMortSchedBSubLiens",
            "LoanPolicyInsuredMortgage": "ListTypeInsuredMortgage"
        };
    }
}

export class StandardLanguageCategoryDto {
    constructor(options) {
        options = options || {};
        this.standardLanguageCategoryID = _.parseNumber(options.standardLanguageCategoryID,0);
        this.category = options.category || null;
        this.description = options.description || null;
        this.isDefaultCategory = _.parseBool(options.isDefaultCategory);
        this.defaultUserSecurityAccess = _.parseNumber(options.defaultUserSecurityAccess,0);
        this.userSecurityStatus = _.parseNumber(options.userSecurityStatus, 0);
        this.hasSecurityExceptions = this.userSecurityStatus !== 0;
        this.filteredCategoryID = _.parseNumber(options.filteredCategoryID,0);
        this.sectionsApplied = _.parseBool(options.sectionsApplied);

        this.id = this.standardLanguageCategoryID;
        this.name = this.category;
    }

    get isNew() { return this.standardLanguageCategoryID == 0; }

    toDataObject() { return _.toPlainObject(this); }
}

export class StandardLanguageSectionCategoryDto {
    constructor(options, categories=[]) {
        options = options || {};
        this.standardLanguageCategorySectionID = _.parseNumber(options.standardLanguageCategorySectionID, 0) || -_.parseNumber(_.uniqueId());
        this.id = this.standardLanguageCategorySectionID;
        this.standardLanguageSectionID = _.parseNumber(options.standardLanguageSectionID, 0);
        this.standardLanguageCategoryID = _.parseNumber(options.standardLanguageCategoryID, 0);
        this.regionID = _.parseNumber(options.regionID, 0);
        this.isDefault = _.parseBool(options.isDefault);
        this.sequence = _.parseNumber(options.sequence, 0);
        this.categoryName = options.categoryName || null;
        this.categoryDescription = options.categoryDescription || null;
        this.isDeleted = _.parseBool(options.isDeleted);
        this.updateCategoryInfo(categories);
    }

    updateCategoryInfo(categories) {
        if(_.isEmpty(categories) || this.standardLanguageCategoryID === 0) return;
        let category = _.find(categories, cat => cat.standardLanguageCategoryID === this.standardLanguageCategoryID);
        this.categoryName = category.category;
        this.categoryDescription = category.description;
    }
}

export class StandardLanguagePackageDto {
    constructor(options) {
        options = options || {};
        this.standardLanguagePackageID = _.parseNumber(options.standardLanguagePackageID, 0);
        this.id = this.standardLanguagePackageID;
        this.standardLanguageCategoryID = _.parseNumber(options.standardLanguageCategoryID, null);
        this.name = options.name || null;
        this.description = options.description || null;
        this.regionID = _.parseNumber(options.regionID, null);
        this.clauses = _.map(options.clauses, item => new StandardLanguagePackageDetailDto(item));
    }

    toDataObject(omitClauses=false, flatten=false) {
        if(!omitClauses && !flatten) return _.toPlainObject(this);
        let result = _.omit(_.toPlainObject(this), ["clauses"]);
        if(omitClauses) return result;
        result.clauses = _.flatMap(this.clauses, sl => sl.toFlattenedList());
        return result;
    }
}

export class StandardLanguagePackageDetailDto extends StandardLanguageDto {
    constructor(options) {
        super(options);
        this.clientKey = options.clientKey || _.uniqueId("standard_language_");
        this.id = this.standardLanguagePackageDetailID;
        this.children = _.map(options.children, item => new StandardLanguagePackageDetailDto(item));

        this.originalValues = _.toPlainObject(options);
        this.childCount = options.childCount || null;
        this.siblingCount = options.siblingCount || null;
        this.isEditing = _.parseBool(options.isEditing);
        this.isCollapsed = _.parseBool(options.isCollapsed);
        this.isCollapsible = _.parseBool(options.isCollapsible, true);
        this.displayHeight = options.displayHeight || 75;
        this.isSelected = _.parseBool(options.isSelected);
        this.isDeleted = _.parseBool(options.isDeleted);
    }

    setListFormat(documentListStyle=null) {
        _.assignIn(this, documentListStyle || DocumentListFormat.nullDefaults);
    }

    get someSelected() { return this.isSelected || _.some(this.children, child => child.someSelected); }

    toFlattenedList() {
        let result = [new StandardLanguagePackageDetailDto(this).toDataObject()];
        if(_.isEmpty(this.children)) return result;
        let childList = _.flatMap(this.children, c => c.toFlattenedList());
        result.push(...childList);
        return result;
    }

    toDataObject(withChildren=false) {
        let plainObj = _.toPlainObject(this);
        return withChildren
            ? plainObj
            : _.omit(plainObj, ["children", "originalValues"]);
    }
}

export class StandardLanguageShortDto {
    constructor(options) {
        options = options || {};
        this.standardLanguageID = _.parseNumber(options.standardLanguageID, 0);
        this.standardLanguageCategoryID = _.parseNumber(options.standardLanguageCategoryID, 0);
        this.code = options.code || null;
        this.description = options.description || null;
        this.regionID = _.parseNumber(options.regionID, 0);
        this.regionDisplay = options.regionDisplay || null;
        this.workflowTaskID = _.parseNumber(options.workflowTaskID, null);
        this.isManualLanguage = _.parseBool(options.isManualLanguage);
        this.appliesToType = _.parseNumber(options.appliesToType, null);
        this.overrideOption = _.parseNumber(options.overrideOption, null);
        this.notes = options.notes || null;
    }

    get isNew() { return this.standardLanguageID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get hasNotes() { return _.size(_.trim(this.notes)) > 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isNullOrEmpty(this.code)) {
            errorList.push("Code is required.");
        }
        if (_.isNullOrEmpty(this.description)) {
            errorList.push("Description is required.");
        }
        if (this.regionID == 0) {
            errorList.push("Region is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class StandardLanguageCategorySecurityUserDto {
	constructor(options, defaultUserSecurity) {
        options = options || {};
        this.usersID = _.parseNumber(options.usersID,0);
        this.standardLanguageCategorySecurityUserID = _.parseNumber(options.standardLanguageCategorySecurityUserID, 0);
        this.standardLanguageCategoryID = _.parseNumber(options.standardLanguageCategoryID,0);
        this.securityAccess = _.parseNumber(options.securityAccess, defaultUserSecurity);
        this.userFullName = options.userFullName || null;
        this.securityAccessName = options.securityAccessName || (this.securityAccess === 0 ? "No Access" : "Full Access");
        this.regionDisplay = options.regionDisplay || null;
        this.userGroupName = options.userGroupName || null;
    }
    toDataObject() { return _.toPlainObject(this); }
}

export class StandardLanguageCategorySectionDto {
	constructor(options) {
        options = options || {};
        this.standardLanguageCategorySectionID = _.parseNumber(options.standardLanguageCategorySectionID, 0);
        this.standardLanguageCategoryID = _.parseNumber(options.standardLanguageCategoryID, 0);
        this.standardLanguageSectionID = _.parseNumber(options.standardLanguageSectionID, 0);
        this.sequence = _.parseNumber(options.sequence, 0);//not used apparently
        this.regionID = _.parseNumber(options.regionID, 0);
        this.isDefault = _.parseBool(options.isDefault, false);
    }

    get isNew() { return this.standardLanguageCategorySectionID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (this.regionID == 0) {
            errorList.push("Region is required.");
        }
        if (this.standardLanguageCategoryID == 0) {
            errorList.push("Category is required.");
        }
        if (this.standardLanguageSectionID == 0) {
            errorList.push("Section is required.");
        }
        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}
export class PolicyFormType {
	constructor(options) {
        options = options || {};
        this.policyFormTypeID = _.parseNumber(options.policyFormTypeID, 0);
        this.name = options.name || null;
        this.description = options.description || null;
        this.isSystem = _.parseBool(options.isSystem);
        this.inactive = _.parseBool(options.inactive);
    }

    get isNew() { return this.policyFormTypeID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.name)) {
            errorList.push("Name is required.");
        }
        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }
        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}