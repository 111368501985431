<template>
    <div class="content-wrapper">
        <rq-page-section :title="itemTypeNamePlural" borderless headerSize="lg">
            <template #header-actions>
                <ul class="nav config-actions ms-auto">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_cancel"
                            variant="action"
                            v-rq-tooltip.hover.top title="Discard changes and reload data"
                            @click="onCancel"
                            :disabled="readOnly">Cancel
                        </b-btn>
                    </li>
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_save"
                            variant="action"
                            @click="onSave"
                            :disabled="readOnly">Save
                        </b-btn>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
         </rq-page-section>
        <rq-page-section>
            <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                <label for="txt_enterprise_id">Pavaso Party Role Indicator</label>
                <input 
                    id="txt_enterprise_id" 
                    v-model="systemDefaults.partyRoleID" 
                    type="text" maxlength="50" 
                    :disabled="readOnly" 
                    class="form-control"
                    allow-null />
            </div>
        </rq-page-section>
    </div>
</template>
<script>
import { mapState } from "vuex";
    import SystemDefaultsFormMixin from "../../shared/SystemDefaultsFormMixin";

    export default {
        name: "PavasoPartyRole",
        mixins: [SystemDefaultsFormMixin],
        computed: {
            ...mapState({
                readOnly: state => state.isPageReadOnly,
            }),
        },
        methods: {
        }
    }
</script>
