<template>
    <div class="content-wrapper">
        <div v-if="noSelection" class="rq-stmt-form-container">
            <rq-no-data :text="noDataText" />
        </div>
        <template v-else>
            <rq-page-section :title="title" header-size="sm" borderless :header-only="!showDefaults" >
                <template #header-actions>
                    <ul class="nav">
                        <li class="nav-item">
                            <b-btn automation_id="btn_add" variant="theme" @click="onAddItem" >Add</b-btn>
                        </li>
                        <li class="nav-item ms-3" v-if="showToggle">
                            <b-form-checkbox
                                automation_id="chk_inheritFlag"
                                id="chk_inheritFlag"
                                v-model="itemValue.content.inherit_flag"
                                @change="onChange"
                                >Default Permissions to all Reports and Folders</b-form-checkbox>
                        </li>
                    </ul>
                </template>
                <template v-if="showDefaults">
                    <div class="row">
                        <div class="col col-12 col-sm-12 col-md-6 col-lg-4 form-group">
                            <label for="drp_permission_type">Default Permission Type</label>
                            <dx-select-box
                                :input-attr="{ automation_id: 'drp_permission_type', id: 'drp_permission_type' }"
                                :items="availableParties"
                                value-expr="id"
                                display-expr="name"
                                v-model="itemValue.content.default_party_type_id"
                                @value-changed="onChange"
                            />
                        </div>
                        <div class="col col-12 col-sm-12 col-md-6 col-lg-4 form-group">
                            <label for="tb_permissions">Default Permissions</label>
                            <dx-tag-box
                                automation_id="tb_permissions"
                                ref="tb_permissions"
                                class="form-control"
                                :data-source="availablePermissions"
                                display-expr="name"
                                value-expr="id"
                                :show-selection-controls="true"
                                :show-clear-button="true"
                                :max-displayed-tags="100"
                                :show-drop-down-button="true"
                                apply-value-mode="useButtons"
                                v-model="permissions"
                                @value-changed="onChange"
                                :search-enabled="true"
                            />
                        </div>
                    </div>
                </template>
            </rq-page-section>
            <bi-report-permission-grid
                ref="grid"
                v-model="itemValue"
                @modified="onChange"
                @update-config-error="onError"
                :lookup-users="lookupUsers"
                :lookup-groups="lookupGroups"
                :available-permissions="availablePermissions"
            />
        </template>
    </div>
</template>

<script>
    import BiReportPermissionGrid from './BiReportPermissionGrid';
    import { mapGetters} from "vuex";
    import {
        ExagoPermissionType,
        ExagoPartyType,
    } from "../models.js";
    import { useDeepTwoWayBinding } from "@/shared/composables/useDeepTwoWayBinding";

    export default {
        name: "BiReportEditForm",
        components: { BiReportPermissionGrid },
        props: {
            item: { type: Object, default: () => {}},
            lookupUsers: { type: Array, default: () => [] },
            lookupGroups: { type: Array, default: () => [] },
        },

        setup(props, { emit }) {
            return useDeepTwoWayBinding(props, emit, {
                itemValue: "item"
            });
        },
        data () {
            return {
                availablePermissions: ExagoPermissionType.lookupItems
            };
        },
        computed: {
            ...mapGetters([
                "identityTokenParsed",
                "tenantName",
                "isDevelopment"
            ]),
            noSelection() { return _.isEmpty(this.itemValue); },
            noDataText() {
                if(this.noSelection) return "Select an item from the tree to the left.";
                return "";
            },
            title() {
                return _.join([this.itemValue.content.content_type == 1 ? 'Folder' : 'Report', this.itemValue.name], ': ')
            },
            permissions: {
                get: function() {
                    return _.map(ExagoPermissionType.toList(this.itemValue.content.default_access_flags), (item) => (item.id));
                },
                set: function(val) {
                    this.itemValue.content.default_access_flags = ExagoPermissionType.fromList(val);
                }
            },
            showDefaults() {
                return !_.getBool(this.itemValue, 'content.inherit_flag', false) && _.get(this.itemValue, 'content.content_type', 0);
            },
            showToggle() {
                return _.get(this.itemValue, 'content.content_type', 0) == 1;
            },
            topLevelParent() {
                // Must iterate to the top-most parent as that is the folder JUST below the root.
                let currentIteration = this.itemValue;
                while (currentIteration.parent() != null) {
                    currentIteration = currentIteration.parent();
                }
                return currentIteration.name;
            },
            isCurrentTenant() {
                return this.topLevelParent == this.tenantName;
            },
            allowAdminUsage() {
                return this.isCurrentTenant || this.isDevelopment;
            },
            availableParties() {
                // Filtering for what a user SEES and is able to add/edit.
                let base = _.cloneDeep(ExagoPartyType.lookupItems);
                let allowedParties = [ExagoPartyType.User, ExagoPartyType.Group];
                if (this.isDevelopment) {
                    allowedParties.push(ExagoPartyType.Everyone);
                }

                return _.filter(base, (item) => {
                    return _.includes(allowedParties, item.id);
                });
            },
        },

        methods: {

            onAddItem() {
                const self = this;
                self.$refs.grid.addNewRow();
            },

            save() {
                const self = this;
                let grid = _.get(self, '$refs.grid');
                if (!grid) return Promise.resolve(true);
                return self.$refs.grid.save();
            },

            onError(e) {
                const self = this;
                self.$events.emit("update-config-error", e);
            },

            onChange(e) {
                const self = this;
                self.$emit('modified', self.modelValue);
            }
        },
    }
</script>
