<template>
    <div class="rq-container content-wrapper pt-3 pb-4">
        <rq-banner
            variant="error"
            :message="errorMessage"
            icon="fas fa-exclamation-triangle"
            :visible="!isImageValid"
        />
        <rq-image-preview
            ref="signatureImage"
            label="Signature"
            v-model="item.signatureImage"
        />
    </div>

</template>
<script>
    export default {
        name: 'StaffSignature',
        props: {
            item: {type: Object, default: null},
        },
        data() {
            return {
                isImageValid: true,
                errorMessage: ""
            };
        },
        provide(){
            return {
                imageInfoCallback: {
                    onImageChanged: (data) => {
                        if(data.validImage){
                            //reset to default behavior
                            this.isImageValid = true;
                            this.errorMessage = "";
                        }
                        else{
                            this.isImageValid = false;
                            this.errorMessage = data.message;
                        }
                        
                    }
                }
            }
        },
        computed: {
            imageChanged() { return _.get(this, "$refs.signatureImage.imageChanged", null) || false; }
        }
    }
</script>