<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            focus-after-insert="none"
            v-model:search-value="searchText"
            v-model:validation-errors="validationErrors"
            rq-editable
            target-inactive-column="inactive"
            :strikethrough-if-true="['inactive']"
            @activate="onActivateItem"
            rq-filters
            show-include-inactive
            integrated-search
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { NoteCategoryDto }  from "../models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name:"NoteCategoriesList",
        data () {
            return {
                items: [],
                selectedItem: {},
                validationErrors: [],
                addEventName: "",
                searchText: "",
            };
        },

        computed: {
            ...mapState({
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            itemKey() { return _.get(this.$route.meta, "itemKey") || ""; },
            gridInstance() { return _.get(this.$refs, "dataGrid.gridInstance", null); },
            selectionActions() {
                return [
                    // { name: "delete", text: "Delete", eventName: "delete", allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}` },
                    { name: "activate", text: "Activate", eventName: "activate", requireSelection: true, tooltip: `Activate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return (_.some(e.data, ['inactive', false]) || _.some(e.data, ['isSystem', true]));}},
                    { name: "inactivate", text: "Inactivate", eventName: "activate", requireSelection: true, tooltip: `Inactivate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return (_.some(e.data, ['inactive', true]) || _.some(e.data, ['isSystem', true]));}}
                ];
            },
            errorMsg(){ return _.get(this, "validationErrors.length", 0) > 0 ?  "Please correct the highlighted errors on screen to continue." : _.get(this, "validationErrors")[0]; }  
        },

        watch: {
            validationErrors: function () {
                const self = this;  
                self.$events.emit("update-config-error", { message: "Please correct the highlighted errors on screen to continue.", hasError: self.validationErrors.length > 0 });                 
            }
        },
        created(){
            const self = this;
            self.initGridConfig();
            self.initListeners();
            self.fetchData();
        },

        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },

        methods: {


            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    sorting: { enabled: true },
                    columns: [
                        {
                            dataField: "name",
                            dataType: "string",
                            caption: "Category",
                            editorOptions: { maxLength: 50 },
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "custom",
                                    validationCallback: self.isNotDuplicateCategory,
                                    message: "Category Name already exists"
                                }
                            ]
                        },
                        { dataField: "isSystem", caption: "Is System", dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate, allowEditing: false },
                        { dataField: "inactive", dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate },
                    ],
                    onRowUpdating: self.onRowUpdating,
                    onInitNewRow: e => {
                        _.set(e.data, self.itemKey, 0);
                    },
                    onEditorPreparing: e => {
                        if (e.dataField === "name" || e.dataField === "inactive") e.editorOptions.disabled = e.row.data.isSystem;
                        if (e.parentType !== "dataRow" || e.dataField !== "noteCategoryID") return;
                        e.editorOptions.disabled = !e.row.isNewRow;
                    }
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },

            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },

            fetchData() {
                const self = this;
                let apiPromise = self.$api.NoteCategoriesApi.getAll();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new NoteCategoryDto(i));
                        self.refresh();
                    })
                    .catch(error => {
                        console.log(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },
            isNotDuplicateCategory(item) {
                const self = this;
                let dup = {};
                dup = _.find(self.items, (i) => { 
                    return _.toLower(_.trim(i.name)) === _.toLower(_.trim(item.data.name))
                            && _.parseNumber(_.get(i, self.itemKey, -1), -1) != _.parseNumber(_.get(item.data, self.itemKey, -1), -1); 
                });

                return dup ? false : true;
            },

            onAddItem() {
                if(!this.gridInstance) return;
                this.gridInstance.addRow();
            },

            onGridInsert(values) {
                const self = this;
                let newItem = new NoteCategoryDto(values);
                let changes = []
                return self.save(newItem)
                    .then(result => {
                        self.fetchData();
                    });
            },

            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item.noteCategoryID === key);
                if(itemIndex < 0) return self.onGridInsert(values);

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new NoteCategoryDto(_.assign({}, self.items[itemIndex], values));
                self.save(updatedItem)
                    .then(result => {
                        self.fetchData();
                    });

            },

            save(item){
                const self = this;
                let apiPromise = self.$api.NoteCategoriesApi.saveNoteCategory(item);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$toast.success(`${self.itemTypeName} was saved.`);
                        return result;
                    }).catch(error => {
                        self.$toast.error({ message: `Error saving ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },
            categoryExists(targetItem) {
                const self = this;
                return _.some(self.items, i => 
                    i.noteCategoryID !== targetItem.noteCategoryID
                    && !i.inactive
                    && i.name == targetItem.name
                );
            },

            // onDeleteItem(e) {
            //     if(!e || !e.data) return;
            //     const self = this;
            //     let items = e.data;
            //     let itemLabel = items.length > 1
            //         ? self.itemTypeNamePlural
            //         : self.itemTypeName;

            //     let okHandler = function (args) {
            //         let keys = _.map(items, 'noteCategoryID');
            //         self.delete(keys);
            //         return true;
            //     }

            //     self.$dialog.confirm(
            //         "Confirm Delete",
            //         `Are you sure you want to delete the selected ${itemLabel}?`,
            //         okHandler,
            //         null, { cancelTitle: 'No', okTitle: 'Yes'});
            // },

            // delete(keys) {
            //     const self = this;
            //     let apiPromise = self.$api.NoteCategoriesApi.deleteNoteCategory(keys);
            //     return self.$rqBusy.wait(apiPromise)
            //         .then(() => {
            //             self.removeItems(keys);
            //             let message = keys.length > 1
            //                 ? `${keys.length} ${self.itemTypeNamePlural} were deleted.`
            //                 : `${self.itemTypeName} was deleted.`
            //             self.$toast.success(message);
            //             return true;
            //         })
            //         .catch(error => {
            //             self.$toast.error(`Error deleting ${self.itemTypeName}.`);
            //             console.error(error);
            //             return error;
            //         });
            // },

            // removeItems(keys) {
            //     const self = this;
            //     _.forEach(keys, key => {
            //         let itemIndex = _.findIndex(self.items, item => item[self.itemKey] === key);
            //         if(itemIndex >= 0) self.items.splice(itemIndex, 1);
            //     });
            //     self.refresh();
            // },

            refresh() {
                if(_.isNull(this.gridInstance)) return;
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },

            onActivateItem(e){
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 1
                    ? self.itemTypeNamePlural
                    : self.itemTypeName;
                let verb = _.every(items, ['inactive', true]) ? "Activate" : "Inactivate";

                let okHandler = function (args) {
                    let keys = _.map(items, 'noteCategoryID');
                    self.toggleIsInactive(keys, verb);
                    return true;
                }

                self.$dialog.confirm(
                    `Confirm ${verb}`,
                    `Are you sure you want to ${verb} the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            toggleIsInactive(keys, verb) {
                const self = this;
                let apiPromise = self.$api.NoteCategoriesApi.toggleIsInactive(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.fetchData();
                        self.gridInstance.clearSelection();
                        let message = keys.length > 1
                            ? `${keys.length} ${self.itemTypeNamePlural} were ${verb}d.`
                            : `${self.itemTypeName} was ${verb}d.`
                        self.$toast.success(message);
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error(`Error trying to ${verb} ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },
        }
    }
</script>