<template>
    <rq-section-card v-show="sectionVisible" :title="label" collapsible>
        <div class="row">
            <div class="col-2 col-sm-6">
                <rq-page-section  title="File Permissions" headerSize="sm" borderless v-show="categoryVisible('File Permissions')">
                    <div class="row" v-show="fieldVisible('changeFileNumber')">
                        <div class="col">Change File Number</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_changeFileNumber"
                                v-model="securityData.userGroupSecurity.canChangeFileNumber"
                                :disabled="isCanChangeFileNumberReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('changeFileBranch')">
                        <div class="col">Change File Branch</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_changeFileBranch"
                                v-model="securityData.userGroupSecurity.allowChangeBranch"
                                :disabled="isAllowChangeBranchReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('changeFileRegion')">
                        <div class="col">Change File Region</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_changeFileRegion"
                                v-model="securityData.userGroupSecurity.allowChangeRegion"
                                :disabled="isAllowChangeRegionReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('allowOpenFileInUser')">
                        <div class="col">Open File in use</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_allowOpenFileInUser"
                                v-model="securityData.userGroupSecurity.allowOpenFileInUse"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('createOrder')">
                        <div class="col">Create File</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_createOrder"
                                v-model="securityData.userGroupSecurity.allowCreateOrder"
                                :disabled="isAllowCreateOrderReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('secondaryFileCreate')">
                        <div class="col">Create Secondary File</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_secondaryFileCreate"
                                v-model="securityData.userGroupSecurity.allowSecondaryFileCreate"
                                :disabled="isAllowSecondaryFileCreateReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('useFileTemplate')">
                        <div class="col">Use File Template</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_useFileTemplate"
                                v-model="securityData.userGroupSecurity.canUseOrderTemplate"
                                :disabled="isCanUseOrderTemplateReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('deleteLoan')">
                        <div class="col">Delete Loan</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_deleteLoan"
                                v-model="securityData.userGroupSecurity.allowDeleteLoan"
                                :disabled="isSysAdmin"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('viewFilesInOtheBranches')">
                        <div class="col">View Files in other Branches</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_viewFilesInOtheBranches"
                                v-model="securityData.userGroupSecurity.allowViewBranches"
                                :disabled="isSysAdmin"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('MakeSettlementStatementTemplateDefault')">
                        <div class="col">Make Settlement Statement Template Default</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_MakeSettlementStatementTemplateDefault"
                                v-model="securityData.userGroupSecurity.allowMakeCSSDefaultTemplate"
                                :disabled="isSysAdmin"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('editSalesRep')">
                        <div class="col">Edit Sales Rep</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_editSalesRep"
                                v-model="securityData.userGroupSecurity.allowEditSalesRep"
                                :disabled="isAllowEditSalesRepReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('resetHUDCDF')">
                        <div class="col">Reset HUD/CDF</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_resetHUDCDF"
                                v-model="securityData.userGroupSecurity.allowResetHUDorCDF"
                                :disabled="isAllowResetHUDorCDFReadOnly"
                            />
                        </div>
                    </div>
                        <div class="row" v-show="fieldVisible('markFileGlobal')">
                        <div class="col">Mark Files Global</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_markFileGlobal"
                                v-model="securityData.userGroupSecurity.markFileGlobal"
                                :disabled="isMarkFileGlobalReadOnly"
                            />
                        </div>
                    </div>
                        <div class="row" v-show="fieldVisible('changeCSSType')">
                        <div class="col">Change CSS Type</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_changeCSSType"
                                v-model="securityData.userGroupSecurity.canChangeCSSType"
                                :disabled="isCanChangeCSSTypeReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('resetCSS')">
                        <div class="col">Reset CSS</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_resetCSS"
                                v-model="securityData.userGroupSecurity.allowResetCSS"
                                :disabled="isAllowResetCSSReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('editTaxIDSSN')">
                        <div class="col">Edit Tax ID/SSN</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                id="rdo_editTaxIDSSN"
                                automation_id="au_editTaxIDSSN"
                                v-model="securityData.userGroupSecurity.canEditSSN"
                                :disabled="isCanEditSSNReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('viewTaxIDSSN')">
                        <div class="col">View Last 4 Digits Tax ID/SSN</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                id="rdo_viewTaxIDSSN"
                                name="viewTaxIDSSN"
                                automation_id="au_viewTaxIDSSN"
                                :on=switchOn
                                :off=switchOff
                                size="md"
                                v-model="canViewSSNValue"
                                :disabled="isCanViewSSNReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('recordingInformation')">
                        <div class="col">Edit Recording Information</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_recordingInformation"
                                v-model="securityData.userGroupSecurity.allowEditRecordingInformation"
                                :disabled="isAllowEditRecordingInformationReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('initiateDataConversion') && isAdminRegion">
                        <div class="col">Initiate Data Conversion</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_initiateDataConversion"
                                v-model="securityData.userGroupSecurity.allowInitiateDataConversion"
                                :disabled="isAllowInitiateDataConversionReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('fileLockOptions')">
                        <div class="col-7 drpdwnColumn form-group">
                            <label for="drp_fileLockOptions">FILE LOCK OPTIONS</label>
                            <rq-select-box
                                id="drp_fileLockOptions"
                                :items="fileLockOptions"
                                v-model="securityData.userGroupSecurity.orderLockOption"
                                :disabled="isOrderLockOptionReadOnly"
                            />
                        </div>
                    </div>
                </rq-page-section>
            </div>
            <div class="col-2 col-sm-6">
                <rq-page-section  title="Audit Log Permissions" headerSize="sm" borderless v-show="false">
                    <div class="row" v-show="fieldVisible('titleProductionPolicy')">
                        <div class="col">Title Production/Policy</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_titleProductionPolicy"
                                v-model="securityData.userGroupSecurity.allowCommitmentPolicyLoggingAccess"
                                :disabled="isAllowCommitmentPolicyLoggingAccessReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('allowHUDLoggingAccess')">
                        <div class="col">HUD</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_allowHUDLoggingAccess"
                                v-model="securityData.userGroupSecurity.allowHUDLoggingAccess"
                                :disabled="isAllowHUDLoggingAccessReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('allowCSSLoggingAccess')">
                        <div class="col">CSS</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_allowCSSLoggingAccess"
                                v-model="securityData.userGroupSecurity.allowCSSLoggingAccess"
                                :disabled="isAllowCSSLoggingAccessReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('allowEscrowLoggingAccess')">
                        <div class="col">Escrow</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_allowEscrowLoggingAccess"
                                v-model="securityData.userGroupSecurity.allowEscrowLoggingAccess"
                                :disabled="isAllowEscrowLoggingAccessReadOnly"
                            />
                        </div>
                    </div>
                    <!-- <div class="row" v-show="fieldVisible('webUsage')">
                        <div class="col">Web Usage</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_webUsage"
                                v-model="securityData.userGroupSecurity.allowWebUsageLogggingAccess"
                            />
                        </div>
                    </div> -->
                    <div class="row" v-show="fieldVisible('documentPrompts')">
                        <div class="col">Document Prompts</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_documentPrompts"
                                v-model="securityData.userGroupSecurity.allowDocumentPromptLoggingAccess"
                                :disabled="isSysAdmin"
                            />
                        </div>
                    </div>
                    <!--<div class="row" v-show="fieldVisible('scheduler')">
                        <div class="col">Scheduler</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_scheduler"
                                v-model="securityData.userGroupSecurity.allowSchedulerLoggingAccess"
                            />
                        </div>
                    </div>-->
                    <div class="row" v-show="fieldVisible('st_cDFAuditLog')">
                        <div class="col">CDF Audit Log</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="st_cDFAuditLog"
                                v-model="securityData.userGroupSecurity.allowCDFLoggingAccess"
                                :disabled="isSysAdmin"
                            />
                        </div>
                    </div>
                </rq-page-section>
            </div>
        </div>
    </rq-section-card>
</template>
<script>
    import SecurityRoleSectionMixin from "./SecurityRoleSectionMixin";
    import { FileLockOptions } from "@config/enums";

    export default {
        name: "AuditLogFilesSection",
        mixins: [SecurityRoleSectionMixin],
        data() {
            return {
                sectionFields: [
                    { label: "Change File Number", name: "changeFileNumber", category: "File Permissions" },
                    { label: "Change File Branch", name: "changeFileBranch", category: "File Permissions" },
                    { label: "Change File Region", name: "changeFileRegion", category: "File Permissions" },
                    { label: "Open File in use", name: "allowOpenFileInUse", category: "File Permissions" },
                    { label: "Create File", name: "createOrder", category: "File Permissions" },
                    { label: "Create Secondary File", name: "secondaryFileCreate", category: "File Permissions" },
                    { label: "Use File Template", name: "useFileTemplate", category: "File Permissions" },
                    { label: "Delete Loan", name: "deleteLoan", category: "File Permissions" },
                    { label: "View Files in other Branches", name: "viewFileInOtherBranches", category: "File Permissions" },
                    { label: "Make Settlement Statement Template Default", name: "makeSettlementStatementTemplateDefault", category: "File Permissions" },
                    { label: "Edit Sales Rep", name: "editSalesRep", category: "File Permissions" },
                    { label: "Reset HUD/CDF", name: "resetHUDCDF", category: "File Permissions" },
                    { label: "Mark Files Global", name: "markFileGlobal", category: "File Permissions" },
                    { label: "Change CSS Type", name: "changeCSSType", category: "File Permissions" },
                    { label: "Reset CSS", name: "resetCSS", category: "File Permissions" },
                    { label: "File Lock Options", name: "fileLockOptions", category: "File Permissions" },
                    { label: "Title Production/Policy", name: "titleProductionPolicy", category: "Audit Log Permissions" },
                    { label: "HUD", name: "allowHUDLoggingAccess", category: "Audit Log Permissions" },
                    { label: "CSS", name: "allowCSSLoggingAccess", category: "Audit Log Permissions" },
                    { label: "Escrow", name: "allowEscrowLoggingAccess", category: "Audit Log Permissions" },
                    { label: "Document Prompts", name: "au_documentPrompts", category: "Audit Log Permissions" },
                    { label: "Scheduler", name: "scheduler", category: "Audit Log Permissions" },
                    { label: "Edit Tax ID/SSN", name: "editTaxIDSSN", category: "File Permissions" },
                    { label: "View Tax ID/SSN", name: "viewTaxIDSSN", category: "File Permissions" },
                    { label: "Edit Recording Information", name: "recordingInformation", category: "File Permissions" },
                    { label: "Initiate Data Conversion", name: "initiateDataConversion", category: "File Permissions" }
                ],
                viewTaxIDSSNoptions: [
                    { text: "No View", value: 0 },
                    { text: "View Last 4", value: 1 }
                    // { text: "View", value: 2 }
                ],
            };
        },
        computed: {
            fileLockOptions() { return FileLockOptions.lookupItems; },
            isCanChangeFileNumberReadOnly() { return this.isSysAdmin || this.isAdmin; },
            isAllowChangeBranchReadOnly() { return this.isSysAdmin || this.isAdmin; },
            isAllowChangeRegionReadOnly() { return this.isSysAdmin || this.isAdmin; },
            isAllowCreateOrderReadOnly() { return this.isSysAdmin || this.isAdmin || this.isEscrowAdmin; },
            isAllowSecondaryFileCreateReadOnly() { return this.isSysAdmin || this.isAdmin || this.isEscrowAdmin; },
            isCanUseOrderTemplateReadOnly() { return this.isSysAdmin || this.isAdmin ||  this.isEscrowAdmin; },
            isOrderLockOptionReadOnly() { return this.isSysAdmin || this.isAdmin ||  this.isEscrowAdmin; },
            isAllowEscrowLoggingAccessReadOnly() { return this.isSysAdmin || this.isAdmin ||  this.isEscrowAdmin; },
            isAllowEditSalesRepReadOnly() { return this.isSysAdmin ||  this.isAdmin; },
            isAllowResetHUDorCDFReadOnly() { return this.isSysAdmin ||  this.isAdmin; },
            isMarkFileGlobalReadOnly() { return this.isSysAdmin ||  this.isAdmin; },
            isCanChangeCSSTypeReadOnly() { return this.isSysAdmin ||  this.isAdmin; },
            isCanEditSSNReadOnly() { return this.isSysAdmin ||  this.isAdmin; },
            isCanViewSSNReadOnly() { return this.isSysAdmin ||  this.isAdmin; },
            isAllowCommitmentPolicyLoggingAccessReadOnly() { return this.isSysAdmin ||  this.isAdmin; },
            isAllowHUDLoggingAccessReadOnly() { return this.isSysAdmin ||  this.isAdmin; },
            isAllowCSSLoggingAccessReadOnly() { return this.isSysAdmin ||  this.isAdmin; },
            isAllowResetCSSReadOnly() { return this.isSysAdmin ||  this.isAdmin; },
            isAllowEditRecordingInformationReadOnly() { return this.isSysAdmin ||  this.isAdmin; },
            isAllowInitiateDataConversionReadOnly() { return !(this.isSysAdmin || this.isAdmin); },
            isAdminRegion() { return this.securityData.userGroup.regionID === this.globalRegionId },
            canViewSSNValue:{
                get() {
                    return this.securityData.userGroupSecurity.canViewSSN === 1;
                },
                set(value) {
                    this.securityData.userGroupSecurity.canViewSSN = value === true ? 1 : 0;
                }
            }
        },
        watch: {
            isSysAdmin: function(newValue){
                this.setDefaultValues();
            },
            isAdmin: function(newValue) {
                this.setDefaultValues();
            },
            isEscrowAdmin: function(newValue) {
                this.setDefaultValues();
            }
        },
        methods: {
            setDefaultValues(){
                const self = this;

                // Escrow Admin
                if (self.isEscrowAdmin) {
                    self.securityData.userGroupSecurity.canChangeFileNumber = false;
                    self.securityData.userGroupSecurity.allowChangeBranch = false;
                    self.securityData.userGroupSecurity.allowChangeRegion = false;
                    self.securityData.userGroupSecurity.allowCreateOrder = true;
                    self.securityData.userGroupSecurity.allowSecondaryFileCreate = true;
                    self.securityData.userGroupSecurity.canUseOrderTemplate = true;
                    self.securityData.userGroupSecurity.allowDeleteLoan = false;
                    self.securityData.userGroupSecurity.allowEditSalesRep = false;
                    self.securityData.userGroupSecurity.allowResetHUDorCDF = false;
                    self.securityData.userGroupSecurity.markFileGlobal = false;
                    self.securityData.userGroupSecurity.canChangeCSSType = false;
                    self.securityData.userGroupSecurity.canEditSSN = true;
                    self.securityData.userGroupSecurity.allowResetCSS = false;
                    self.securityData.userGroupSecurity.canViewSSN = 1 // View Last 4
                    self.securityData.userGroupSecurity.orderLockOption = FileLockOptions.All; // All
                    self.securityData.userGroupSecurity.allowEditRecordingInformation = false;
                    self.securityData.userGroupSecurity.allowInitiateDataConversion = false;
                    self.securityData.userGroupSecurity.allowCommitmentPolicyLoggingAccess = true;
                    self.securityData.userGroupSecurity.allowHUDLoggingAccess = true;
                    self.securityData.userGroupSecurity.allowCSSLoggingAccess = true;
                    self.securityData.userGroupSecurity.allowEscrowLoggingAccess = true;
                }

                // Admin
                if(self.isAdmin) {
                     // File Permissions
                    self.securityData.userGroupSecurity.canChangeFileNumber = true;
                    self.securityData.userGroupSecurity.allowChangeBranch = true;
                    self.securityData.userGroupSecurity.allowChangeRegion = true;
                    self.securityData.userGroupSecurity.allowCreateOrder = true;
                    self.securityData.userGroupSecurity.allowSecondaryFileCreate = true;
                    self.securityData.userGroupSecurity.canUseOrderTemplate = true;
                    self.securityData.userGroupSecurity.allowDeleteLoan = false;
                    self.securityData.userGroupSecurity.allowEditSalesRep = true;
                    self.securityData.userGroupSecurity.allowResetHUDorCDF = true;
                    self.securityData.userGroupSecurity.markFileGlobal = true;
                    self.securityData.userGroupSecurity.canChangeCSSType = true;
                    self.securityData.userGroupSecurity.allowResetCSS = true;
                    self.securityData.userGroupSecurity.canEditSSN = true;
                    self.securityData.userGroupSecurity.canViewSSN = 1;
                    self.securityData.userGroupSecurity.allowEditRecordingInformation = true;
                    self.securityData.userGroupSecurity.allowInitiateDataConversion = true;
                    self.securityData.userGroupSecurity.orderLockOption = FileLockOptions.All;

                    // Audit Log Permissions
                    self.securityData.userGroupSecurity.allowCommitmentPolicyLoggingAccess = true;
                    self.securityData.userGroupSecurity.allowHUDLoggingAccess = true;
                    self.securityData.userGroupSecurity.allowCSSLoggingAccess = true;
                    self.securityData.userGroupSecurity.allowEscrowLoggingAccess = true;
                }

                // System Admin
                if(self.isSysAdmin){
                    self.securityData.userGroupSecurity.canChangeFileNumber = true;
                    self.securityData.userGroupSecurity.allowCreateOrder = true;
                    self.securityData.userGroupSecurity.allowSecondaryFileCreate = true;
					self.securityData.userGroupSecurity.allowChangeBranch = true;
                    self.securityData.userGroupSecurity.allowChangeRegion = true;
                    self.securityData.userGroupSecurity.canUseOrderTemplate = true;
                    self.securityData.userGroupSecurity.allowDeleteLoan = true;
                    self.securityData.userGroupSecurity.allowEditSalesRep = true;
                    self.securityData.userGroupSecurity.allowResetHUDorCDF = true;
                    self.securityData.userGroupSecurity.markFileGlobal = true;
                    self.securityData.userGroupSecurity.canChangeCSSType = true;
                    self.securityData.userGroupSecurity.allowResetCSS = true;
                    self.securityData.userGroupSecurity.canEditSSN = true;
                    self.securityData.userGroupSecurity.canViewSSN = 1; // default to viewTaxIDSSNoptions.ViewLast4
                    self.securityData.userGroupSecurity.allowEditRecordingInformation = true;
                    self.securityData.userGroupSecurity.allowInitiateDataConversion = true;
                    self.securityData.userGroupSecurity.orderLockOption = FileLockOptions.All;
                    self.securityData.userGroupSecurity.allowCommitmentPolicyLoggingAccess = true;
                    self.securityData.userGroupSecurity.allowHUDLoggingAccess = true;
                    self.securityData.userGroupSecurity.allowCSSLoggingAccess = true;
                    self.securityData.userGroupSecurity.allowEscrowLoggingAccess = true;
                }

                if (!self.isAdmin) {
                    self.securityData.userGroupSecurity.allowInitiateDataConversion = false;
                }
            }
        }
    }
</script>