<template>
    <div class="content-wrapper ">
        <configuration-mega-menu />
        <rq-banner v-for="(alert,index) in alerts" :key="index" v-bind="alert" />
        <rq-banner
            variant="error"
            :title="errorTitle"
            :message="errorMessage"
            icon="fas fa-exclamation-triangle"
            :visible="hasConfigError"
        />
        <rq-page-section v-if="showAddItem" :title="itemTypeNamePlural" headerSize="lg" borderless header-only>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <b-button
                            :automation_id="elementName('btn_add')"
                            :ref="elementName('btn_add')"
                            variant="theme"
                            @click="onClick"
                            v-rq-tooltip.hover.top="{ title: `Add ${itemTypeName}` }"
                            :disabled="readOnly">Add
                        </b-button>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
        </rq-page-section>
        <rq-scroll-container v-if="useScrollLayout" ref="rqScrollContainer">
            <router-view></router-view>
        </rq-scroll-container>
        <router-view v-else></router-view>
    </div>
</template>
<script>
    import ConfigurationMegaMenu from "./shared/ConfigurationMegaMenu";
    import { RqBannerAlert } from '../../shared/models/models';
    import { mapState } from "vuex";
    export default {
        name:"ConfigurationMain",
        components:{ ConfigurationMegaMenu },
        data() {
            return {
                term: "",
                alerts: [],
                hasConfigError: false,
                errorTitle: null,
                errorMessage: null
            }
        },

        computed: {
            ...mapState({
                readOnly: state => state.isPageReadOnly,
            }),
            itemTypeNamePlural() { return _.get(this.$route.meta, "title") || _.get(this.$route.meta, "label") ||  ""; },
            itemLabel() { return _.get(this.$route.meta, "label") ||  ""; },
            itemKey() { return _.get(this.$route.meta, "itemKey") || ""; },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            itemTypeDescription() { return _.get(this.$route.meta, "itemTypeDescription") || ""; },
            showAddItem() { return !_.isEmpty(this.itemKey); },
            useScrollLayout() { return _.getBool(this, "$route.meta.scrollLayout"); },
            actions() { return  _.get(this.$route.meta, "actions") || ""; },
            showAdd() { return _.getBool(this, "$route.meta.showAdd"); },
            showTitle() { return _.getBool(this, "$route.meta.showTitle"); },
            showMegaMenu() { return _.getBool(this, "$route.meta.showMegaMenu"); }
        },

        beforeRouteUpdate(to, from, next) {
            if(this.useScrollLayout)
                _.invoke(this, "$refs.rqScrollContainer.scrollToTop");
            this.hasConfigError = false;
            this.errorTitle = null;
            this.errorMessage = null;
            this.alerts = [];
            next();
        },

        created() {
            this.$events.on("update-config-alerts", this.onUpdateConfigAlerts);
            this.$events.on("update-config-error", this.onUpdateConfigError);
        },

        beforeUnmount() {
            this.$events.off("update-config-alerts", this.onUpdateConfigAlerts);
            this.$events.off("update-config-error", this.onUpdateConfigError);
        },

        methods: {
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            onClick(e) {
                this.$events.$emit(`add:${this.elementName()}`);
            },

            onSave(){},

            onCancel(){},

            onUpdateConfigAlerts(e) {
                this.alerts = _.map(_.get(e, "alerts", []), a => new RqBannerAlert(a));
            },

            onUpdateConfigError(e) {
                this.hasConfigError = false;
                this.$nextTick()
                    .then(() => {
                        if(_.isString(e) && !_.isEmpty(e)) {
                            this.hasConfigError = true;
                            this.errorMessage = e;
                            return;
                        }
                        this.hasConfigError = _.getBool(e, "hasError");
                        this.errorTitle = _.get(e, "title", null);
                        this.errorMessage = _.get(e, "message", null);
                    });
            }
        }
    }
</script>
