<template>
    <div class="content-wrapper taskPackage">
        <div class="rq-container">
            <rq-banner
            variant="error"
            :title="errorMessage"
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && v$.$error" />
            <div class="row">
                <div class="col col-3 col-sm-12 col-md-4 col-lg-6 form-group form-required" :class="{ 'has-error': v$.item.workflowTaskPackageName.$error || v$.item.uniquePackageName.$invalid}">
                    <label for="region">Workflow Task Package Name</label>
                    <input
                        automation_id="txt_wft_Pkg_Name"
                        type="text"
                        class="form-control"
                        id="workflowTaskPackageName"
                        placeholder="Package Name"
                        maxlength="40"
                        v-model="item.workflowTaskPackageName">
                    <rq-validation-feedback
                       :messages="{
                            'Package name is required.': v$.item.workflowTaskPackageName.$error,
                            'A Package already exists with that name.': v$.item.uniquePackageName.$invalid
                        }"
                    />
                </div>
                <div class="col col-3 col-sm-12 col-md-4 col-lg-6 form-group">
                    <label for="drp_wft_pkg_category">Category</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_wft_pkg_category', id: 'drp_wft_pkg_category' }"
                        :items="categoryList"
                        value-expr="id"
                        display-expr="name"
                        :search-enabled="true"
                        v-model="item.workflowTaskPackageCategoryID"
                    />
                </div>
            </div>
        </div>
        <rq-page-section
            borderless
            flex-full
            flex-body>
            <grid-selector
                id="taskGridSelector"
                ref="taskGridSelector"
                :items="availableTaskList"
                :item-key="taskItemKey"
                item-name="Tasks"
                sort-description-field="taskName"
                sort-field="sequence"
                :grid-configs="gridConfigs"
                v-model="selectedTaskIDs"
                flex-layout
                @reorder="onSelectedTaskReorder"
                selectAllPages
            />
        </rq-page-section>
    </div>

</template>
<script>
    import { mapGetters } from "vuex";
    import { WorkflowTasksDto, WorkflowTaskPackageDto }  from "../models";
    import GridSelector from "../../shared/GridSelector";
    import { required } from "validators";
    import { useVuelidate } from "@vuelidate/core";

    export default {
        name: 'WorkflowTaskPackagesForm',
        components: {  GridSelector },
        props: {
            item: {type: Object, default: null},
            workflowTaskIDs: {type: Array, default: null},
            uniqueValidator: { type: Function, default: (() => true) }
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                originalTaskList: [],
                availableTaskList: [],
                selectedTaskList: [],
                selectedTaskIDs: [],
                showBanner: false
            }
        },
        watch: {
            selectedTaskIDs: {
                handler(newValue, oldValue) {
                    this.setSequence(this.availableTaskList, this.selectedTaskIDs);
                }
            }
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            categoryList() {
                return this.lookupHelpers.getLookupItems(this.lookupItems.WORKFLOW_TASK_PACKAGE_CATEGORIES, null, _.parseNumber(this.item.workflowTaskPackageCategoryID, null));
            },
            hasSelectedTasks() {
                return this.selectedTaskIDs.length > 0;
            },
            errorMessage() { return this.hasSelectedTasks ? "Please correct the highlighted errors on screen to continue.": "Please Select One or More tasks."},
        },

        validations() {
            return {
                item: {
                    workflowTaskPackageName: {
                        required
                    },
                    uniquePackageName: (val, vm) => vm.uniqueValidator(val, "workflowTaskPackageName")
                },
                    hasSelectedTasks(val){
                     return this.hasSelectedTasks;
                }
            };
        },

        created(){
            const self = this;
            self.fetchData();
            self.initNonReactiveVariables();
            self.initGridConfig();
        },

        methods: {
            initGridConfig(){
                const self = this;
                self.gridConfigs = {
                    available: {
                        height: "100%",
                        columns: [
                            { dataField: "taskName", dataType: "string", sortIndex: 0, sortOrder: "asc", }
                        ]
                    },
                    selected: {
                        height: "100%",
                        sorting: { mode: "none" },
                        columns: [
                            { dataField: "taskName", dataType: "string", }
                        ]
                    }
                };
            },
            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = "Workflow Task Package";
                self.taskItemKey = "workflowTaskID";
            },

           fetchData() {
                const self = this;
                let apiPromises = [];
                apiPromises.push(self.$api.ConfigWorkflowTasksApi.get());
                if(self.item.workflowTaskPackageID !== -1)
                    apiPromises.push(self.$api.WorkflowTaskPackagesApi.getAssociatedTasks(_.parseNumber(self.item.workflowTaskPackageID, 0)));

                return self.$rqBusy.wait(Promise.all(apiPromises))
                    .then(result => {
                        self.originalTaskList = _.map(_.clone(result[0].details), i => new WorkflowTasksDto(i));
                        self.selectedTaskIDs = _.map(_.clone(result[1]));
                        self.setSequence(result[1], self.selectedTaskIDs);
                        self.availableTaskList = _.filter(self.originalTaskList, task => !task.isInActive);
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading Workflow Tasks.` });
                        return error;
                    });
            },
            onSelectedTaskReorder(e){
                for (var i = 0; i < e.length; i++){
                    let itemIndex = _.findIndex(this.availableTaskList, wft =>
                                                wft.workflowTaskID === e[i]);
                    if (itemIndex > -1){
                        this.availableTaskList[itemIndex].sequence = (i+1) * 10;
                    }
                }
                this.selectedTaskIDs = _.clone(e);
            },
            setSequence(items, selectedTaskIDs){
                const self = this;
                let tasks = _.map(_.clone(items), i => new WorkflowTasksDto(i));
                _.forEach (selectedTaskIDs, (ID, index) => {
                        let itemIndex = _.findIndex(tasks, t => t.workflowTaskID === ID);
                        if (itemIndex == -1) return;
                        tasks[itemIndex].sequence = index + 1;
                    })
                self.availableTaskList = _.clone(tasks);
            },
            save(){
                const self = this;
                self.showBanner = true;
                self.v$.$touch();
                // let isEqual = _.isEqual(self.selectedTaskIDs, _.map(self.item.workflowTasks, 'ID'));
                // if (!self.v$.$anyDirty && isEqual) { return Promise.resolve(true); }
                if (self.v$.$error) return Promise.resolve(false);

                self.item.workflowTaskIDs = self.selectedTaskIDs
                
                const wftPackage = new WorkflowTaskPackageDto();
                wftPackage.workflowTaskIDs = self.selectedTaskIDs;
                wftPackage.workflowTaskPackageID = self.item.workflowTaskPackageID;
                wftPackage.workflowTaskPackageName = self.item.workflowTaskPackageName;
                wftPackage.workflowTaskPackageCategoryID = self.item.workflowTaskPackageCategoryID;
                wftPackage.isInactive = self.item.isInactive;

                let apiPromise = self.$api.WorkflowTaskPackagesApi.saveWorkflowTaskPackage(wftPackage);
                return self.$rqBusy.wait(apiPromise)
                    .then(item => {
                        self.$toast.success("Workflow Task Package saved successfully.");
                        return item;
                    }).catch(err => {
                        self.$toast.error("An error occurred while saving this workflow task package.");
                        console.error(err);
                        return err;
                    });
            },
            updateDimensions: _.debounce(function(e) {
                _.invoke(this, "$refs.taskGridSelector.updateHeight", "100%");
            }, 385, { "leading": true, "trailing": true })

        }
    }
</script>
