<template>
    <div class="rq-container">
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && v$.$error"
        />
        <div class="row">
            <div class="col col-12 form-group form-required" :class="{ 'has-error' : v$.linkedPremiumRateID.$error || v$.linkedPremiumRateID.differentPremium.$invalid }">
                <label for="txt_code">Link Premium</label>
                <premium-picker
                    automation_id="drp_link_premium"
                    dialog-title="Premium Rates"
                    v-model="v$.linkedPremiumRateID.$model"
                />
                <rq-validation-feedback
                    :offset="feedbackOffset"
                    :messages="{
                        'Linked Premium is required.': v$.linkedPremiumRateID.$error,
                        'Cannot link the Premium to itself.': v$.linkedPremiumRateID.differentPremium.$invalid
                    }"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { required } from "@vuelidate/validators";
    import { useVuelidate } from "@vuelidate/core";
    import PremiumPicker from './PremiumPicker';

    export default {
        name: "PremiumLinkForm",
        components: {
            PremiumPicker
        },
        props: {
            modelValue: { type: Number, default: null },
            rateID: { type: Number, default: 0 }
        },
        inject: ["dialogId"],
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                linkedPremiumRateID: this.modelValue,
                feedbackOffset: 95,
                showBanner: false
            }
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ])
        },
        validations() {
            const self = this;
            return {
                linkedPremiumRateID: {
                    required,
                    differentPremium: val => self.rateID != val
                }
            };
        },
        methods: {
            isValid() {
                this.showBanner = true;
                this.v$.$touch();
                return !this.v$.$error;
            }
        }
    }
</script>