<template>
    <div class="content-wrapper">
        <rq-page-section :title="itemTypeNamePlural" headerSize="lg" header-only borderless>
             <template #header-actions>
                <ul class="nav">
                    <li class="nav-item" v-rq-tooltip.hover :title="!canDoAnything ? '(Access Restricted)' : ''">
                        <b-button
                            :automation_id="elementName('btn_add', this.itemTypeName)"
                            :ref="elementName('btn_add', this.itemTypeName)"
                            variant="theme"
                            @click="onAddItem"
                            :disabled=!canDoAnything
                            >
                            Add
                        </b-button>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            target-inactive-column="inactive"
            @activate="onActivateItem"
            @rowDoubleClick="onEditItem"
            @edit="onEditItem"
            @delete="onDeleteItem"
            @copy="onCopyItem"
            @reset="onReset"
            @contentReady="onGridContentReady"
            :strikethrough-if-true="['inactive']"
            integrated-search
            show-include-inactive
            rq-filters
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import TransferTaxConfigurationFormAdd from "./TransferTaxConfigurationFormAdd.vue";
    import { TransferTaxConfigurationShortDto }  from "./../models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";
    import { TransferTaxEffectiveDateBasedOn } from '../../enums';

    export default {
        name:"TransferTaxConfigurationList",
        props: { },
        data () {
            return {
                items: [],
                selectedItem: {},
                validationErrors: [],
                verifiedItem: {},
                addEventName: "",
                gridReady: false
            };
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "regionsLookup"
            ]),
            gridInstance() { return _.get(this.$refs, "dataGrid.gridInstance", null) || {}; },
            itemKey() { return "transferTaxConfigurationID";},
            itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            itemTypeDescription() { return _.get(this.$route.meta, "itemTypeDescription") || ""; },
            selectionActions() {
                const self = this;
                return [{ name: "edit", text: (!self.canDoAnything) ? "View":"Edit", eventName: "edit", requireSelection: true, tooltip: (!self.canDoAnything) ? `View ${this.itemTypeName}`: `Edit ${this.itemTypeName}`},
                        { name: "delete", text: `Delete`, eventName: "delete", requireSelection: true, allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}` , disabled:!self.canDoAnything  },
                        { name: "copy", text: `Copy`, eventName: "copy", requireSelection: true, allowMultiSelection: false, tooltip: `Copy ${this.itemTypeName}` , disabled:!self.canDoAnything  },
                        { name: "activate", text: "Activate", eventName: "activate", requireSelection: true, tooltip: `Activate ${this.itemTypeName}`, allowMultiSelection: true,
                            disabled: function(e) {
                                   return (!self.canDoAnything) ? true : !_.every(e.data, ['inactive', true]); }
                            },
                        { name: "inactivate", text: "Inactivate", eventName: "activate", requireSelection: true, tooltip: `Inactivate ${this.itemTypeName}`, allowMultiSelection: true,
                            disabled: function(e) {
                                   return (!self.canDoAnything) ? true : !_.every(e.data, ['inactive', false]); }
                            }
                        ];
            },
            localSecurity()
            {
                 return this.securitySettings.findValues(["AllowAddEditTransferTaxes"]);
            },
            canDoAnything(){
                return this.localSecurity.AllowAddEditTransferTaxes;
            },
        },

        created(){
           this.fetchData();
           this.initGridConfig();
           this.initListeners();
        },

        beforeUnmount() {
           this.$events.off(this.addEventName, this.onAddItem);
        },

        methods: {
            onActivateItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 1
                    ? self.itemTypeNamePlural
                    : self.itemTypeName;
                let verb = _.every(items, ['inactive', true]) ? "Activate" : "Inactivate";

                let okHandler = function (args) {
                    let keys = _.map(items, self.itemKey);
                    self.activate(keys, verb);
                    return true;
                }

                self.$dialog.confirm(
                    `Confirm ${verb}`,
                    `Are you sure you want to ${verb} the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            activate(keys, verb) {
                const self = this;
                let apiPromise = self.$api.TransferTaxConfigurationApi.activateTransferTax(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.fetchData();
                        self.refresh();
                        let message = keys.length > 1
                            ? `${keys.length} ${self.itemTypeNamePlural} were ${verb}d.`
                            : `${self.itemTypeName} was ${verb}d.`
                        self.$toast.success(message);
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error(`Error trying to ${verb} ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },

            create(item){
                const self = this;
                let apiPromise = self.$api.TransferTaxConfigurationApi.create(item);
                return self.$rqBusy.wait(apiPromise)
                    .then(data => {
                        self.onEditItem({data: {transferTaxConfigurationID: data.transferTaxConfigurationID}});
                        self.$toast.success("Transfer Tax was created.");
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error("Error trying to save transfer tax.")
                        console.error(error);
                        return error;
                    });
            },

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            gridSearch: _.debounce(function(searchTextValue)
            {
                if(!this.gridReady) return;
                this.gridInstance.searchByText(searchTextValue);
            }, 300),

            onGridContentReady (e) {
                const self = this;
                self.gridReady = true;
            },

            onAddItem() {
                const self = this;
                self.clear();
                let branch = self.lookupHelpers.getBranch(self.user.branchID);
                let item = new TransferTaxConfigurationShortDto({regionID: self.user.regionID, effectiveDate: DateTimeHelper.nowClientStartOfDay()});
                if (!_.isEmpty(branch)) {
                    item.stateAssociated = branch.defaultState;
                }
                self.showAddItem(item);
            },

            onEditItem(e) {
                const self = this;
                var message = "";
                if(!self.localSecurity) return ;
                this.$router.push({ name: 'cfg:sscalc:transfertax', params: { transferTaxConfigurationID: e.data.transferTaxConfigurationID, transferTaxName: e.data.description }});
            },

            onCopyItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let item = e.data;

                let ok = function (args) {
                    let key = item.transferTaxConfigurationID;
                    let apiPromise = self.$api.TransferTaxConfigurationApi.copy(key);
                    self.$rqBusy.wait(apiPromise)
                        .then(data => {
                            self.onEditItem({data: {transferTaxConfigurationID: data.transferTaxConfigurationID}});
                            let message = `${self.itemTypeName} was copied.`
                            self.$toast.success({ message: message });
                            return true;
                        })
                        .catch(error => {
                            self.$toast.error({ message: `Error copying ${self.itemTypeName}.` });
                            return false;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }

                 self.$dialog.confirm(`Confirm Copy`, `Are you sure you want to copy the selected ${self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let toBeDeletedKeys = _.map(items, self.itemKey);

                    let apiPromise = self.$api.TransferTaxConfigurationApi.delete(toBeDeletedKeys);
                    return self.$rqBusy.wait(apiPromise)
                        .then(keys => {
                            self.removeGridItems(items, self.itemKey);
                            let message = keys.length > 1 ? `${keys.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success({ message: message });
                            return true;
                        })
                        .catch(error => {
                          if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                 self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            }
                            return error;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onReset(e) {
                this.fetchData();
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: self.itemKey,
                            dataType: "number",
                            visible: false
                        },
                        {
                            // dataField: "regionDisplay",
                            dataField: "regionID",
                            dataType: "number",
                            caption: "Region",
                            // calculateSortValue: DxGridUtils.regionDisplaySortValue,
                            calculateSortValue: "regionDisplay",
                            lookup: {
                                dataSource: self.regionsLookup,
                                displayExpr: "displayName",
                                valueExpr: "regionID",
                            },
                            groupIndex: -1
                        },
                        {
                            dataField: "friendlyName",
                            dataType: "string",
                            visible: false
                        },
                        {
                            dataField: "description",
                            dataType: "string"
                        },
                        {
                            dataField: "countyList",
                            dataType: "string",
                            caption: "County",
                            cellTemplate: DxGridUtils.truncateCellTemplate,
                            width: 150
                        },
                        {
                            dataField: "stateAssociated",
                            dataType: "string",
                            caption: "State",
                        },
                          {
                            dataField: "cityAssociated",
                            dataType: "string",
                            caption: "City"
                        },
                        { dataField: "effectiveBasedOn", dataType: "number", caption: "Effective Date Based On",
                            lookup: {
                                dataSource: TransferTaxEffectiveDateBasedOn.lookupItems,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                        },
                        DxGridUtils.dateColumn({
                            dataField: 'effectiveDate',
                        }),
                        {
                            dataField: "inactive",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate
                        },
                    ],
                };

                self.gridDataSource = {
                    loadMode: "raw",
                    key: self.itemKey,
                    load () {
                        return Promise.resolve(self.items);
                    },
                };
            },

            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },

            fetchData() {
                const self = this;
                let apiPromise = self.$api.TransferTaxConfigurationApi.getList();
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new TransferTaxConfigurationShortDto(i));
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            showAddItem(item) {
                const self = this;
                self.$dialog.open({
                    title: "Add Transfer Tax",
                    width: 700,
                    adaptive: true,
                    component: TransferTaxConfigurationFormAdd,
                    props: { item },
                    okTitle: "Save & Edit",
                    onOk: e => {
                        if (!e.component.isValid()) return false;
                        return self.create(item);
                    }
                });

            },
            clear() {
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            refresh() {
                this.clear();
                this.gridInstance.refresh();
            },

            removeGridItems(items, key) {
                _.pullAllBy(this.items, items, key);
            },
        }
    }
</script>
