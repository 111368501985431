
export const Topics = {
	Main: "Main",
	CheckWritingEscrow: "Check Writing & Escrow",
	DocumentsReports: "Documents & Reports",
	TitleProductionPolicy: "Title Production & Policy",
	CalculatorsSettlementStatements: "Calculators & Settlement Statements",
	Integrations: "Integrations",
	WorkflowVendorServices: "Workflow & Vendor Services",
	CustomData: "Custom Data",
	Automation: "Automation"
};
