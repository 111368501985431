import { DateTimeHelper } from "@/shared/utilities";
import { EndorsementDto, RateEngineSimultaneousDto, EndorsementYesNo } from "@settlement/models";

export class PayoffDescriptionDto {
constructor(options) {
        options = options || {};
        this.payoffDescriptionID = _.parseNumber(options.payoffDescriptionID, 0);
        this.description = options.description || null;
        this.isInactive = _.parseBool(options.isInactive);
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class SettlementStatementSettingsDto {
constructor(options) {
        this.altassBorrowerDocument = options.altassBorrowerDocument || null;
        this.altassCashDocument = options.altassCashDocument || null;
        this.altassCombinedDocument = options.altassCombinedDocument || null;
        this.altassSellerDocument = options.altassSellerDocument || null;
        this.always_Use_pg2ab_on_the_CDF = options.always_Use_pg2ab_on_the_CDF || null;
        this.auto_Calculate_Down_Payment = options.auto_Calculate_Down_Payment || null;
        this.auto_Calculate_HUD_Line_901_Per_deim = options.auto_Calculate_HUD_Line_901_Per_deim || null;
        this.borrowerCDFDocumentName = options.borrowerCDFDocumentName || null;
        this.borrowerCDFDocumentName_P2ab = options.borrowerCDFDocumentName_P2ab || null;
        this.borrowerCSSDocumentName = options.borrowerCSSDocumentName || null;
        this.borrowerHUDDocumentName = options.borrowerHUDDocumentName || null;
        this.cdfDocumentName = options.cdfDocumentName || null;
        this.cdfDocumentName_P2ab = options.cdfDocumentName_P2ab || null;
        this.cdfSystemName = options.cdfSystemName || null;
        this.cdfUrl = options.cdfUrl || null;
        this.cssCharges1Description = options.cssCharges1Description || null;
        this.cssCharges2Description = options.cssCharges2Description || null;
        this.cssCharges2Hidden = options.cssCharges2Hidden || null;
        this.cssCharges3Description = options.cssCharges3Description || null;
        this.cssCharges3Hidden = options.cssCharges3Hidden || null;
        this.cssCharges4Description = options.cssCharges4Description || null;
        this.cssCharges4Hidden = options.cssCharges4Hidden || null;
        this.cssCreditsDescription = options.cssCreditsDescription || null;
        this.cssCreditsHidden = options.cssCreditsHidden || null;
        this.huD1ADocumentName = options.huD1ADocumentName || null;
        this.huD1DocumentName = options.huD1DocumentName || null;
        this.huD1974_1ADocumentName = options.huD1974_1ADocumentName || null;
        this.huD1974_1DocumentName = options.huD1974_1DocumentName || null;
        this.huD1974_BorrowerDocumentName = options.huD1974_BorrowerDocumentName || null;
        this.huD1974_SellerDocumentName = options.huD1974_SellerDocumentName || null;
        this.log_Changes_to_CDF_ALTA = options.log_Changes_to_CDF_ALTA || null;
        this.log_Changes_to_HUD_SS = options.log_Changes_to_HUD_SS || null;
        this.premium_Totals_Only_for_Non_BreakOuts = options.premium_Totals_Only_for_Non_BreakOuts || null;
        this.prompt_for_CDF_Transaction_Without_Seller = options.prompt_for_CDF_Transaction_Without_Seller || null;
        this.purchaserCSSDocumentName = options.purchaserCSSDocumentName || null;
        this.purchaserSellerCSSDocumentName = options.purchaserSellerCSSDocumentName || null;
        this.sellerCDFDocumentName = options.sellerCDFDocumentName || null;
        this.sellerCDFDocumentName_P2ab = options.sellerCDFDocumentName_P2ab || null;
        this.sellerCSSDocumentName = options.sellerCSSDocumentName || null;
        this.sellerHUDDocumentName = options.sellerHUDDocumentName || null;
        this.show_Premium_Splits_on_Settlement_Statements = options.show_Premium_Splits_on_Settlement_Statements || null;
        this.split_Amount = options.split_Amount || null;
        this.split_Realtor_Commissions = options.split_Realtor_Commissions || null;
        this.toscdfDocumentName = options.toscdfDocumentName || null;
        this.toscdfDocumentName_P2ab = options.toscdfDocumentName_P2ab || null;
        this.transfer_MIP_Amount_from_Prepaids_to_Escrow = options.transfer_MIP_Amount_from_Prepaids_to_Escrow || null;
        this.transfer_Propert_Tax_Amount_from_Prepaids_to_Escrow = options.transfer_Propert_Tax_Amount_from_Prepaids_to_Escrow || null;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class HudStatusDto {
	constructor(options) {
        options = options || {};
        this.hudStatusID = _.parseNumber(options.hudStatusID, 0);
        this.description = options.description || null;
    }

    get isNew() { return this.hudStatusID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
    toListItem() {
        return { itemID: this.hudStatusID, itemName: this.description };
    }
    static fromListItem(listItem) {
        return new HudStatusDto({ hudStatusID: listItem.itemID || 0, description: listItem.itemName || "" });
    }
}

export class TaxRateDto {
    constructor(options) {
            options = options || {};
            this.taxRateID = _.parseNumber(options.taxRateID, 0);
            this.effectiveDate = options.effectiveDate || null;
            this.state = options.state || null;
            this.allCounties = _.parseNumber(options.allCounties, -1);
            this.city = options.city || null;
            this.taxType = _.parseNumber(options.taxType, 0);
            this.taxRate = options.taxRate || null;
            this.taxTypeName = options.taxTypeName || null;
            this.countyIDs = options.countyIDs || null;
        }

        get isNew() { return this.taxRateID == 0; }

       toDataObject() { return _.toPlainObject(this); }
}

export class DefaultHUDLine1974Dto {
    constructor(options) {
            options = options || {};
            this.defaultHUDLineID = _.parseNumber(options.defaultHUDLineID, 0);
            this.line = _.parseNumber(options.line, 0);
            this.description = options.description || null;
            this.accountingCodeID = _.parseNumber(options.accountingCodeID, null);
            this.payeeCompanyDisplay = options.payeeCompanyDisplay || null;
            this.payeeCompanyID = _.parseNumber(options.payeeCompanyID, 0);
            this.payeeContactDisplay = options.payeeContactDisplay || null;
            this.payeeContactID = _.parseNumber(options.payeeContactID, 0);
            this.required = _.parseBool(options.required , false);
            this.hUDVersionCode = 1; // always 1 for 1974
            this.systemRequired = _.parseBool(options.systemRequired);
            this.readOnly = _.parseBool(options.readOnly);
            this.descriptionReadOnly = _.parseBool(options.descriptionReadOnly);
            this.hudLine1974Section = options.hudLine1974Section || parseInt(this.line / 100);
        }

        get isNew() { return this.defaultHUDLineID == 0; }

        get isValid() { return this.validationErrors.length === 0; }

        get validationErrors() {
            let errorList = [];
            if (_.isEmpty(this.description)) {
                errorList.push("Description is required.");
            }

            if (this.line == 0) {
                errorList.push("Line number is required.");
            }

            return errorList;
        }


        get payeeCompany() {
            return {
                companyID: this.payeeCompanyID,
                companyName: this.payeeCompanyDisplay,
                contactID: this.payeeContactID,
                contactName: this.payeeContactDisplay,
            };
        }

        set payeeCompany(val) {
            this.payeeCompanyID = _.get(val, "companyID", null);
            this.payeeCompanyDisplay= _.get(val, "companyName", null);
            this.payeeContactID = _.get(val, "contactID", null);
            this.payeeContactDisplay= _.get(val, "contactName", null);
        }

       toDataObject() { return _.toPlainObject(this); }
}

export class DefaultHUDLine2010Dto {
    constructor(options) {
            options = options || {};
            this.defaultHUDLineID = _.parseNumber(options.defaultHUDLineID, 0);
            this.line = _.parseNumber(options.line, null);
            this.description = options.description || null;
            this.accountingCodeID = _.parseNumber(options.accountingCodeID, null);
            this.payeeCompanyDisplay = options.payeeCompanyDisplay || null;
            this.payeeCompanyID = _.parseNumber(options.payeeCompanyID, 0);
            this.payeeContactDisplay = options.payeeContactDisplay || null;
            this.payeeContactID = _.parseNumber(options.payeeContactID, 0);
            this.lineTypeDisplay = options.lineTypeDisplay || null;
            this.lineType =_.parseNumber(options.lineType, null) == 0 ? null : _.parseNumber(options.lineType, null);
            this.required = _.parseBool (options.required , false);
            this.hUDVersionCode = 2; // always 2 for 2010
            this.readOnly = _.parseBool(options.readOnly);
            this.descriptionReadOnly = _.parseBool(options.descriptionReadOnly);
            this.payeeReadOnly = _.parseBool(options.payeeReadOnly);
            this.lineTypeReadOnly = _.parseBool(options.lineTypeReadOnly);
            this.hudLine2010Section = options.hudLine2010Section || parseInt(this.line / 100) *  100;
            this.systemRequired = _.parseBool(options.systemRequired);
        }

        get isNew() { return this.defaultHUDLineID == 0; }

        get isValid() { return this.validationErrors.length === 0; }

        get validationErrors() {
            let errorList = [];
            if (_.isEmpty(this.description)) {
                errorList.push("Description is required.");
            }

            if (this.line == 0) {
                errorList.push("Line number is required.");
            }

            return errorList;
        }

        get payeeCompany() {
            return {
                companyID: this.payeeCompanyID,
                companyName: this.payeeCompanyDisplay,
                contactID: this.payeeContactID,
                contactName: this.payeeContactDisplay,
            };
        }

        set payeeCompany(val) {
            this.payeeCompanyID = _.get(val, "companyID", null);
            this.payeeCompanyDisplay= _.get(val, "companyName", null);
            this.payeeContactID = _.get(val, "contactID", null);
            this.payeeContactDisplay= _.get(val, "contactName", null);
        }

        toDataObject() { return _.toPlainObject(this); }
}
export class CdfSectionLookupDto {
    constructor(options) {
            options = options || {};
            this.id = _.parseNumber(options.id, 0);
            this.name = options.name || null;
            this.sectionCodePrefix = options.sectionCodePrefix || null;
            this.maxValue = _.parseNumber(options.maxValue, null);
            this.reachedMax = _.parseBool(options.reachedMax);
        }

    toDataObject() { return _.toPlainObject(this); }
}

export class DefaultCDFLineDto {
    constructor(options) {
            options = options || {};
            this.id = _.parseNumber(options.id, 0);
            this.sectionCode = _.parseNumber(options.sectionCode, 0);
            this.line = _.parseNumber(options.line, 0);
            this.sectionLineDisplay = options.sectionLineDisplay || null;
            this.description = options.description || null;
            this.required = _.parseBool(options.required);
            this.acctCode = _.parseNumber(options.acctCode, null);
            this.payeeCompanyID = _.parseNumber(options.payeeCompanyID, null);
            this.payeeCompanyDisplay = options.payeeCompanyDisplay || null;
            this.systemRequired = _.parseBool(options.systemRequired);
        }

        get isNew() { return this.id == 0; }

        get isValid() { return this.validationErrors.length === 0; }

        get validationErrors() {
            let errorList = [];
            if (_.isEmpty(this.description)) {
                errorList.push("Description is required.");
            }

            if (this.sectionCode == 0) {
                errorList.push("Section is required.");
            }

            return errorList;
        }

        get payeeCompany() {
            return {
                companyID: this.payeeCompanyID,
                companyName: this.payeeCompanyDisplay,
            };
        }

        set payeeCompany(val) {
            this.payeeCompanyID = _.get(val, "companyID", null);
            this.payeeCompanyDisplay= _.get(val, "companyName", null);
        }

        toDataObject() { return _.toPlainObject(this); }
}

export class RateContentDto
    {
        constructor(options) {
            options = options || {};
            this.importedID = _.parseNumber(options.importedID, 0);
            this.selected = _.parseBool ( options.selected , false);
            this.exists = _.parseBool ( options.exists , false);
            this.importedAssociatedRateId = _.parseNumber ( options.importedAssociatedRateId , 0);
            this.importedLinkedId = _.parseNumber ( options.importedLinkedId , 0);
            this.successfullyLinked = _.parseBool ( options.successfullyLinked , false);

            //table contents
            this.associatedRateID =  _.parseNumber(options.associatedRateID,0);
            this.code =  options.code || null;
            this.countyID =  _.parseNumber(options.countyID,0);
            this.effectiveDateBasisID =  _.parseNumber(options.effectiveDateBasisID,0);
            this.extendedDescription =  options.extendedDescription || null;
            this.description =  options.extendedDescription || null;
            this.filingCode =  options.filingCode || null;
            this.isInActive =  _.parseBool(options.isInActive,false);
            this.linkedPremiumRateID =  _.parseNumber(options.linkedPremiumRateID,0);
            this.loan_AccountingCode =  _.parseNumber(options.loan_AccountingCode,0);
            this.owner_AccountingCode =  _.parseNumber(options.owner_AccountingCode,0);
            this.policyCode =  options.policyCode || null;
            this.premiumTypeID =  _.parseNumber(options.premiumTypeID,0);
            this.rateEngineFileTypeNameID =  _.parseNumber(options.rateEngineFileTypeNameID,0);
            this.rateID =  _.parseNumber(options.rateID,0);
            this.rateRuleReference =  options.rateRuleReference || null;
            this.reportCode =  options.reportCode || null;
            this.ricOverride =  _.parseBool(options.ricOverride,false);
            this.state =  options.state || null;
            this.taxContactRole =  _.parseNumber(options.taxContactRole,0);
            this.taxRateBasedOn =  _.parseNumber(options.taxRateBasedOn,0);
            this.taxRateEffectiveBasis =  _.parseNumber(options.taxRateEffectiveBasis,0);
            this.taxType =  _.parseNumber(options.taxType,0);
            // Rate Effective Collection
            this.rateEffectiveDate = options.rateEffectiveDate;
        }

        get isNew() { return this.rateID == 0; }

        get universalID() {return this.importedID;}

        get isValid() { return this.validationErrors.length === 0; }

        get validationErrors() {
            let errorList = [];
            return errorList;
        }

        toDataObject() { return _.toPlainObject(this); }
}

export class EndorsementContentDto {
        constructor(options) {
            options = options || {};
            this.importedID = _.parseNumber(options.importedID, 0);
            this.selected = _.parseBool ( options.selected , false);
            this.exists = _.parseBool ( options.exists , false);
            // table properties
            this.alwaysApplyEndorsement =  _.parseBool(options.alwaysApplyEndorsement,false);
            this.applyToTitleServices =  _.parseBool(options.applyToTitleServices,false);
            this.basedOnAmount =  _.parseNumber(options.basedOnAmount,0);
            this.basedOnMaximum =  _.parseNumber(options.basedOnMaximum,0);
            this.basedOnMinimum =  _.parseNumber(options.basedOnMinimum,0);
            this.basedOnPer =  _.parseNumber(options.basedOnPer,0);
            this.behavesLikeRecordingFee =  _.parseBool(options.behavesLikeRecordingFee,false);
            this.breakoutOverride =  _.parseNumber(options.breakoutOverride,0);
            this.cdfLineType =  _.parseNumber(options.cdfLineType,0);
            this.chargePercentageBasisID = _.parseNumber(options.chargePercentageBasisID,0);
            this.code =  options.code || null;
            this.coverageRoundingMethod =  _.parseNumber(options.coverageRoundingMethod,0);
            this.deletedYN =  _.parseBool(options.deletedYN,false);
            this.description =  options.description || null;
            this.endorsementBasedOnID = _.parseNumber(options.endorsementBasedOnID,0);
            this.rateEngineSplitRulesID = _.parseNumber(options.rateEngineSplitRulesID,0);
            this.endorsementChargeTypeID = _.parseNumber(options.endorsementChargeTypeID,0);
            this.endorsementPolicyTypeID= _.parseNumber(options.endorsementPolicyTypeID,0);
            this.endorsementChargesSettlementFeeRoundingMethod =  _.parseNumber(options.endorsementChargesSettlementFeeRoundingMethod,0);
            this.endorsementHasDocumentTemplatesYN =  _.parseBool(options.endorsementHasDocumentTemplatesYN,false);
            this.endorsementID =  _.parseNumber(options.endorsementID,0);
            this.filingCode =  options.filingCode || null;
            this.hudLineType =  _.parseNumber(options.hudLineType,0);
            this.isInactiveYN =  _.parseBool(options.isInactiveYN,false);
            this.isSystemRecordYN =  _.parseBool(options.isSystemRecordYN,false);
            this.loan =  _.parseNumber(options.loan,0);
            this.loanConcurrentPolicy =  _.parseNumber(options.loanConcurrentPolicy,0);
            this.loanFlatExcessAmount =  _.parseNumber(options.loanFlatExcessAmount,0);
            this.loanFlatExcessCalcPctDiff =  _.parseNumber(options.loanFlatExcessCalcPctDiff,0);
            this.loanFlatExcessPolicy =  _.parseNumber(options.loanFlatExcessPolicy,0);
            this.loanFlatFeeAmount =  _.parseNumber(options.loanFlatFeeAmount,0);
            this.loanFlatFeeDifferenceAmount =  _.parseNumber(options.loanFlatFeeDifferenceAmount,0);
            this.loanFlatFeeDifferenceOwnersMin =  _.parseNumber(options.loanFlatFeeDifferenceOwnersMin,0);
            this.loanFlatFeePercentageAmount =  _.parseNumber(options.loanFlatFeePercentageAmount,0);
            this.loanFlatFeePercentageExcessAmount =  _.parseNumber(options.loanFlatFeePercentageExcessAmount,0);
            this.loanFlatFeePercentageExcessBasedOn =  _.parseNumber(options.loanFlatFeePercentageExcessBasedOn,0);
            this.loanFlatFeePercentageExcessCalcPctDiff =  _.parseNumber(options.loanFlatFeePercentageExcessCalcPctDiff,0);
            this.loanFlatFeePercentageExcessPct =  _.parseNumber(options.loanFlatFeePercentageExcessPct,0);
            this.loanFlatFeePercentageExcessPctPolicy =  _.parseNumber(options.loanFlatFeePercentageExcessPctPolicy,0);
            this.loanFlatFeePercentageExcessPolicy =  _.parseNumber(options.loanFlatFeePercentageExcessPolicy,0);
            this.loanFlatFeePercentagePct =  _.parseNumber(options.loanFlatFeePercentagePct,0);
            this.loanFlatFeePercentagePctPolicy =  _.parseNumber(options.loanFlatFeePercentagePctPolicy,0);
            this.loanPercentageExcessCalcPctDiff =  _.parseNumber(options.loanPercentageExcessCalcPctDiff,0);
            this.loanPercentageExcessPct =  _.parseNumber(options.loanPercentageExcessPct,0);
            this.loanPercentageExcessPctPolicy =  _.parseNumber(options.loanPercentageExcessPctPolicy,0);
            this.loanPercentageExcessPolicy =  _.parseNumber(options.loanPercentageExcessPolicy,0);
            this.loanPercentagePct =  _.parseNumber(options.loanPercentagePct,0);
            this.loanPercentagePctPolicy =  _.parseNumber(options.loanPercentagePctPolicy,0);
            this.loanPolicyCode =  options.loanPolicyCode || null;
            this.loanRoundingAfterCalc =  _.parseBool(options.loanRoundingAfterCalc,false);
            this.loanUsePremiumRounding =  _.parseBool(options.loanUsePremiumRounding,false);
            this.overrideAmountsByDefault =  _.parseBool(options.overrideAmountsByDefault,false);
            this.overrideDefaultSplitRulesYN =  _.parseBool(options.overrideDefaultSplitRulesYN,false);
            this.owner =  _.parseNumber(options.owner,0);
            this.payPercent =  _.parseNumber(options.payPercent,0);
            this.percentageMaximum =  _.parseNumber(options.percentageMaximum,0);
            this.percentageMinimum =  _.parseNumber(options.percentageMinimum,0);
            this.percentagePercent =  _.parseNumber(options.percentagePercent,0);
            this.policyCode =  options.policyCode || null;
            this.rateRuleReference =  options.rateRuleReference || null;
            this.salesConcurrentPolicy =  _.parseNumber(options.salesConcurrentPolicy,0);
            this.salesFlatExcessAmount =  _.parseNumber(options.salesFlatExcessAmount,0);
            this.salesFlatExcessCalcPctDiff =  _.parseNumber(options.salesFlatExcessCalcPctDiff,0);
            this.salesFlatExcessPolicy =  _.parseNumber(options.salesFlatExcessPolicy,0);
            this.salesFlatFeeAmount =  _.parseNumber(options.salesFlatFeeAmount,0);
            this.salesFlatFeeDifferenceAmount =  _.parseNumber(options.salesFlatFeeDifferenceAmount,0);
            this.salesFlatFeeDifferenceOwnersMin =  _.parseNumber(options.salesFlatFeeDifferenceOwnersMin,0);
            this.salesFlatFeePercentageAmount =  _.parseNumber(options.salesFlatFeePercentageAmount,0);
            this.salesFlatFeePercentageExcessAmount =  _.parseNumber(options.salesFlatFeePercentageExcessAmount,0);
            this.salesFlatFeePercentageExcessBasedOn =  _.parseNumber(options.salesFlatFeePercentageExcessBasedOn,0);
            this.salesFlatFeePercentageExcessCalcPctDiff =  _.parseNumber(options.salesFlatFeePercentageExcessCalcPctDiff,0);
            this.salesFlatFeePercentageExcessPct =  _.parseNumber(options.salesFlatFeePercentageExcessPct,0);
            this.salesFlatFeePercentageExcessPctPolicy =  _.parseNumber(options.salesFlatFeePercentageExcessPctPolicy,0);
            this.salesFlatFeePercentageExcessPolicy =  _.parseNumber(options.salesFlatFeePercentageExcessPolicy,0);
            this.salesFlatFeePercentagePct =  _.parseNumber(options.salesFlatFeePercentagePct,0);
            this.salesFlatFeePercentagePctPolicy =  _.parseNumber(options.salesFlatFeePercentagePctPolicy,0);
            this.salesPercentageExcessCalcPctDiff =  _.parseNumber(options.salesPercentageExcessCalcPctDiff,0);
            this.salesPercentageExcessPct =  _.parseNumber(options.salesPercentageExcessPct,0);
            this.salesPercentageExcessPctPolicy =  _.parseNumber(options.salesPercentageExcessPctPolicy,0);
            this.salesPercentageExcessPolicy =  _.parseNumber(options.salesPercentageExcessPolicy,0);
            this.salesPercentagePct =  _.parseNumber(options.salesPercentagePct,0);
            this.salesPercentagePctPolicy =  _.parseNumber(options.salesPercentagePctPolicy,0);
            this.salesPolicyCode =  options.salesPolicyCode || null;
            this.salesRoundingAfterCalc =  _.parseBool(options.salesRoundingAfterCalc,false);
            this.salesUsePremiumRounding =  _.parseBool(options.salesUsePremiumRounding,false);
            this.sectionNumber =  _.parseNumber(options.sectionNumber,0);
            this.showOutsideOfPremiums =  _.parseNumber(options.showOutsideOfPremiums, EndorsementYesNo.No);
            this.splitOverridePCT =  _.parseNumber(options.splitOverridePCT,0);
            this.state =  options.state || null;
            this.whoPays =  _.parseNumber(options.whoPays,0);
            //Collections
            this.premiumTypes = options.premiumTypes;
            this.endorsementCounty = options.endorsementCounty;
            this.rateEngineEndorsementRate = options.rateEngineEndorsementRate;
        }

        get isNew() { return this.endorsementID == 0; }

        get universalID() {return this.importedID;}

        get isValid() { return this.validationErrors.length === 0; }

        get validationErrors() {
            let errorList = [];
            return errorList;
        }

        toDataObject() { return _.toPlainObject(this); }
}

export class RecordingFeeConfigurationDto {
      constructor(options) {
            options = options || {};
            this.recordingFeeConfigurationID = _.parseNumber(options.recordingFeeConfigurationID, 0);
            this.regionID = _.parseNumber(options.regionID, 0);
            this.effectiveDate = options.effectiveDate || null;
            this.description = options.description || null;
            this.recordingTypeID = _.parseNumber(options.recordingTypeID,0);
            this.effectiveBasedOn = _.parseNumber(options.effectiveBasedOn, 0);
            this.stateAssociated = options.stateAssociated || null;
            this.cityAssociated = options.cityAssociated || null;
            this.whoPays = _.parseNumber(options.whoPays,0);
            this.percentage = _.parseNumber(options.percentage,0);
            this.payeeCompanyId = options.payeeCompanyId || null;
            this.accountCodeId = options.accountCodeId || null;
            this.inactive = _.parseBool(options.inactive);
            this.huD1974LineType = _.parseNumber(options.huD1974LineType,0);
            this.huD2010LineType = _.parseNumber(options.huD2010LineType,0);
            this.cdfLineType = _.parseNumber(options.cdfLineType,0);
            this.cssLineType = _.parseNumber(options.cssLineType,0);
            this.calculationBasis = _.parseNumber(options.calculationBasis,0);
            this.flatFeeAmount = _.parseNumber(options.flatFeeAmount,0);
            this.initialPages = _.parseNumber(options.initialPages,1);
            this.initialPageAmount = _.parseNumber(options.initialPageAmount,0);
            this.additionalPageAmount = _.parseNumber(options.additionalPageAmount,0);
            this.regionDisplay = options.regionDisplay || null;
            this.recordingTypeDescription = options.recordingTypeDescription || null;
            this.effectiveBasedOnDescription = options.effectiveBasedOnDescription || null;
            this.calculationBasisDescription = options.calculationBasisDescription || null;
            this.countyIDs = options.countyIDs || [];
            this.payeeCompanyName = options.payeeCompanyName || null;
            this.countyList = options.countyList || null;
      }

      get isNew() { return this.recordingFeeConfigurationID == 0; }

      get isValid() { return this.validationErrors.length === 0; }

      get validationErrors() {
        let errorList = [];
        if (_.isNullOrEmpty(this.regionID)) {
            errorList.push("Region is required.");
        }
        if (_.isNullOrEmpty(this.description)) {
            errorList.push("Description is required.");
        }
        if (_.isNullOrEmpty(this.effectiveDate)) {
            errorList.push("Effective Date is required.");
        }
        if (_.isNullOrEmpty(this.effectiveBasedOn)) {
            errorList.push("Effective Date Based On is required.");
        }
        if (_.isNullOrEmpty(this.stateAssociated)) {
            errorList.push("State is required.");
        }

        return errorList;
    }
    get verifiedItem() {
        let errorObject = {};
        if (_.isNullOrEmpty(this.regionID)) {
            errorObject.requiredRegion = true;
        }
        if (_.isNullOrEmpty(this.description)) {
            errorObject.requiredDesc = true;
        }
        if (_.isNullOrEmpty(this.effectiveDate)) {
            errorObject.requiredEff = true;
        }
        if (_.isNullOrEmpty(this.effectiveBasedOn)) {
            errorObject.requiredEffBased = true;
        }
        if (_.isNullOrEmpty(this.stateAssociated)) {
            errorObject.requiredState = true;
        }

        return errorObject;
    }

      get payeeCompany() {
          return {
              companyID: this.payeeCompanyId,
              companyName: this.payeeCompanyName,
          };
      }

      set payeeCompany(val) {
          this.payeeCompanyId = _.get(val, "companyID", null);
          this.payeeCompanyName = _.get(val, "companyName", null);
      }


      toDataObject() { return _.toPlainObject(this); }

  }

export class RecordingDocumentDto {
    constructor(options) {
        options = options || {};
        this.recordingDocumentID = _.parseNumber(options.recordingDocumentID,0);
        this.description = options.description || null;
        this.accountingCodeID = _.parseNumber(options.accountingCodeID,0);
        this.regionID = _.parseNumber(options.regionID,0);
        this.systemRecord = _.parseBool(options.systemRecord);
        this.seedType = _.parseNumber(options.seedType,0);
        this.recordingDocumentTypeID = _.parseNumber(options.recordingDocumentTypeID,null);
    }

    get isNew() { return this.recordingDocumentID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }

}

export class ProrateRegionDefaultDto {
    constructor(options) {
            options = options || {};
            this.prorateRegionDefaultID = _.parseNumber(options.prorateRegionDefaultID, 0);
            this.regionID = _.parseNumber(options.regionID, null);
            this.applyTaxDescriptionToSection1000 = _.parseBool(options.applyTaxDescriptionToSection1000);
            this.overrideTax1 = _.parseBool(options.overrideTax1);
            this.overrideTax2 = _.parseBool(options.overrideTax2);
            this.overrideTax3 = _.parseBool(options.overrideTax3);
            this.overrideTax4 = _.parseBool(options.overrideTax4);
            this.overrideTax5 = _.parseBool(options.overrideTax5);
            this.overrideTax6 = _.parseBool(options.overrideTax6);
            this.paymentDue = _.parseNumber(options.paymentDue, null);
            this.periodsPerYear = _.parseNumber(options.periodsPerYear, null);
            this.prorationMethod = _.parseNumber(options.prorationMethod, null);
            this.taxDescription1 = options.taxDescription1 || null;
            this.taxDescription2 = options.taxDescription2 || null;
            this.taxDescription3 = options.taxDescription3 || null;
            this.taxDescription4 = options.taxDescription4 || null;
            this.taxDescription5 = options.taxDescription5 || null;
            this.taxDescription6 = options.taxDescription6 || null;
            this.daysInYear = _.parseNumber(options.daysInYear, null);
        }

        get isValid() { return this.validationErrors.length === 0; }

        get validationErrors() {
            let errorList = [];
            if (_.isNullOrEmpty(this.taxDescription1)) {
                errorList.push("Tax Description 1 is required.");
            }
            if (_.isNullOrEmpty(this.taxDescription2)) {
                errorList.push("Tax Description 2 is required.");
            }
            if (_.isNullOrEmpty(this.taxDescription3)) {
                errorList.push("Tax Description 3 is required.");
            }
            if (_.isNullOrEmpty(this.taxDescription4)) {
                errorList.push("Tax Description 4 is required.");
            }
            if (_.isNullOrEmpty(this.taxDescription5)) {
                errorList.push("Tax Description 5 is required.");
            }
            if (_.isNullOrEmpty(this.taxDescription6)) {
                errorList.push("Tax Description 6 is required.");
            }
            if (_.isNullOrEmpty(this.paymentDue)) {
                errorList.push("Payment Due is required.");
            }
            if (_.isNullOrEmpty(this.periodsPerYear)) {
                errorList.push("Billing Periods per Year is required.");
            }
            if (_.isNullOrEmpty(this.prorationMethod)) {
                errorList.push("Proration Method is required.");
            }
            if (_.isNullOrEmpty(this.daysInYear)) {
                errorList.push("Proration Type is required.");
            }
            return errorList;
        }

        toDataObject() { return _.toPlainObject(this); }
}

export class ProrateDefaultDto {
    constructor(options) {
            options = options || {};
            this.prorateDefaultID = _.parseNumber(options.prorateDefaultID, 0);
            this.regionID = _.parseNumber(options.regionID, null);
            this.taxCategory = _.parseNumber(options.taxCategory, null);
            this.paymentDue = _.parseNumber(options.paymentDue, null);
            this.description = _.isNullOrEmpty(options.description) ? options.defaultDescription : options.description;
            this.defaultDescription = options.defaultDescription || null;
            this.sequence = _.parseNumber(options.sequence, null);
            this.periodsPerYear = _.parseNumber(options.periodsPerYear, null);
            this.periodsInArrears = _.parseNumber(options.periodsInArrears, null);
            this.firstPeriodStartDate = options.firstPeriodStartDate || null;
            this.includein1099 = _.parseBool(options.includein1099);
            this.daysInYear = _.parseNumber(options.daysInYear, null);
            this.overrideDescription = _.parseBool(options.overrideDescription);
        }

        get isValid() { return this.validationErrors.length === 0; }

        // get overrideDescription() {
        //     return !_.isEqual(this.description, this.defaultDescription);
        // }
        // set overrideDescription(val) {
        //     this.description = val ? null : this.defaultDescription;
        // }
        get taxCategoryDisplay() {
            return `Cat. ${this.taxCategory}`;
        }

        get validationErrors() {
            let errorList = [];
            if (_.isNullOrEmpty(this.description)) {
                errorList.push("Description is required.");
            }
            if (_.isNullOrEmpty(this.paymentDue)) {
                errorList.push("Payment Due is required.");
            }
            if (_.isNullOrEmpty(this.periodsPerYear)) {
                errorList.push("Billing Periods per Year is required.");
            }
            if (_.isNullOrEmpty(this.periodsInArrears)) {
                errorList.push("Periods in Arrears is required.");
            }
            if (_.isNullOrEmpty(this.daysInYear)) {
                errorList.push("Days in Year is required.");
            }
            return errorList;
        }

        toDataObject() {
            return _.pick(this,
                ["prorateDefaultID",
                "regionID",
                "taxCategory",
                "overrideDescription",
                "paymentDue",
                "description",
                "defaultDescription",
                "sequence",
                "periodsPerYear",
                "periodsInArrears",
                "firstPeriodStartDate",
                "includein1099",
                "daysInYear"]);
        }
}

export class RateEngineEndorsementRateDto {
    constructor(options) {
        options = options || {};
        this.rateEngineEndorsementRateID = _.parseNumber(options.rateEngineEndorsementRateID, 0);
        this.endorsementID = _.parseNumber(options.endorsementID, null);
        this.fromAmount = _.parseNumber(options.fromAmount, 0);
        this.toAmount = _.parseNumber(options.toAmount, 0);
        this.rate = _.parseNumber(options.rate, 0);
        this.per = _.parseNumber(options.per, 0);
        this.maxRate = _.parseNumber(options.maxRate, 0);
        this.deletedYN = _.parseBool(options.deletedYN);
        this.isSystemRecordYN = _.parseBool(options.isSystemRecordYN);
        this.usePerRate = _.parseBool(options.usePerRate);
        this.underwriterSplitPercentage = _.parseNumber(options.underwriterSplitPercentage, 0);
        this.underwriterSplitAmount = _.parseNumber(options.underwriterSplitAmount, 0);
        this.underwriterSplitAmountPer = _.parseNumber(options.underwriterSplitAmountPer, 0);
        this.underwriterSplitMaxRate = _.parseNumber(options.underwriterSplitMaxRate, 0);
        this.rateEngineRoundingRuleID = _.parseNumber(options.rateEngineRoundingRuleID, null);
        this.nearestIncrement = _.parseNumber(options.nearestIncrement, null);
        this.roundingRowType = _.parseNumber(options.roundingRowType, 0);
    }

    get isNew() { return this.rateEngineEndorsementRateID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.toAmount)) {
            errorList.push("To Amount is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }

}

export class EndorsementShortDto {
    constructor(options) {
        options = options || {};
        this.endorsementID = _.parseNumber(options.endorsementID,0);
        this.code = options.code || null;
        this.rateRuleReference = options.rateRuleReference || null;
        this.description = options.description || null;
        this.endorsementPolicyTypeDisplay = options.endorsementPolicyTypeDisplay || null;
        this.state = options.state || null;
        this.whoPaysDisplay = options.whoPaysDisplay || null;
        this.lastModified = options.lastModified || null;
        this.lastModifiedBy = options.lastModifiedBy || null;
        this.accountingCodeDisplay = options.accountingCodeDisplay || null;
        this.policyCode = options.policyCode || null;
        this.filingCode = options.filingCode || null;
        this.breakoutOverrideDisplay = options.breakoutOverrideDisplay || null;
        this.isInactiveYN = _.parseBool(options.isInactiveYN);
        this.payPercent = _.parseNumber(options.payPercent, 0);
        this.endorsementHasDocumentTemplates = _.parseBool(options.endorsementHasDocumentTemplates);
        this.canDelete = _.parseBool(options.canDelete);
        this.canEdit = _.parseBool(options.canEdit);
        this.whoPays = _.parseNumber(options.whoPays, null);
        this.breakoutOverride = _.parseNumber(options.breakoutOverride, null);
        this.AccountingCodeID = _.parseNumber(options.AccountingCodeID, null);
    }

}

export class EndorsementDataDto {
    constructor(options) {
        options = options || {};
        this.endorsement = new EndorsementDto(options.endorsement);
        this.countyIDs = options.countyIDs || [];
        this.documentTemplateIDs = options.documentTemplateIDs || [];
        this.orderCategoryIDs = options.orderCategoryIDs || [];
        this.premiumTypeIDs = options.premiumTypeIDs || [];
        this.regionIDs = options.regionIDs || [];
        this.underwriterIDs = options.underwriterIDs || [];
    }
    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.regionIDs)) {
            errorList.push("Region is required.");
        }
        return _.concat( errorList, this.endorsement.validationErrors);
    }
    get verifiedItem() {
        let errorSetting = {};
        if (_.isEmpty(this.regionIDs)) {
            errorSetting.requiredRegion = true;
        }
        return _.merge( errorSetting, this.endorsement.verifiedItem);
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class DocumentTemplateShortDto {
    constructor(options) {
        options = options || {};
        this.documentTemplateID = _.parseNumber(options.documentTemplateID, 0);
        this.regionID = _.parseNumber(options.regionID, null);
        this.regID = _.parseNumber(options.regID, null);
        this.regionDisplay = options.regionDisplay || null;
        this.name = options.name || null;
        this.description = options.description || null;
        // this.usStatesID = _.parseNumber(options.usStatesID, null);
        // this.usStateName = options.usStateName || null;
    }
}

export class TransferTaxConfigurationDataDto {
    constructor(options) {
        options = options || {};
        this.transferTaxConfiguration = new TransferTaxConfigurationDto(options.transferTaxConfiguration);
        this.countyIDs = options.countyIDs || [];
    }
    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.transferTaxConfiguration)) {
            errorList.push("Tax Configuration is required.");
        }
        return errorList;
    }

    toShortObject() {
        let returnObj = {
            transferTaxConfigurationID: this.transferTaxConfiguration.transferTaxConfigurationID,
            regionID: this.transferTaxConfiguration.regionID,
            effectiveDate: this.transferTaxConfiguration.effectiveDate,
            effectiveBasedOn: this.transferTaxConfiguration.effectiveBasedOn,
            friendlyName: this.transferTaxConfiguration.friendlyName,
            stateAssociated: this.transferTaxConfiguration.stateAssociated || null,
            cityAssociated: this.transferTaxConfiguration.cityAssociated || null,
            inactive: this.transferTaxConfiguration.inactive,
            description: this.transferTaxConfiguration.description
        }
        return new TransferTaxConfigurationShortDto(returnObj);
    }

    toDataObject() {
        let returnObj = {
            transferTaxConfiguration: this.transferTaxConfiguration.toDataObject(),
            countyIDs: this.countyIDs,
        }
        return returnObj;
    }
}

export class TransferTaxConfigurationShortDto {
    constructor(options) {
        options = options || {};
        this.transferTaxConfigurationID = _.parseNumber(options.transferTaxConfigurationID, 0);
        this.regionID = _.parseNumber(options.regionID, 0);
        this.regionDisplay = options.regionDisplay || null;
        this.effectiveDate = options.effectiveDate || null;
        this.countyList = options.countyList || null;
        this.transferTaxTypeDescription = options.transferTaxTypeDescription || null;
        this.effectiveBasedOn = _.parseNumber(options.effectiveBasedOn, 0);
        this.effectiveBasedOnDescription = options.effectiveBasedOnDescription || null;
        this.friendlyName = options.friendlyName || null;
        this.stateAssociated = options.stateAssociated || null;
        this.cityAssociated = options.cityAssociated || null;
        this.inactive = _.parseBool(options.inactive);
        this.description = options.description || null;
    }

    get isNew() { return this.transferTaxConfigurationID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isNullOrEmpty(this.regionID)) {
            errorList.push("Region is required.");
        }
        if (_.isNullOrEmpty(this.description)) {
            errorList.push("Description is required.");
        }
        if (_.isNullOrEmpty(this.effectiveDate)) {
            errorList.push("Effective Date is required.");
        }
        if (_.isNullOrEmpty(this.effectiveBasedOn)) {
            errorList.push("Effective Date Based On is required.");
        }
        if (_.isNullOrEmpty(this.stateAssociated)) {
            errorList.push("State is required.");
        }

        return errorList;
    }
    get verifiedItem() {
        let errorObject = {};
        if (_.isNullOrEmpty(this.regionID)) {
            errorObject.requiredRegion = true;
        }
        if (_.isNullOrEmpty(this.description)) {
            errorObject.requiredDesc = true;
        }
        if (_.isNullOrEmpty(this.effectiveDate)) {
            errorObject.requiredEff = true;
        }
        if (_.isNullOrEmpty(this.effectiveBasedOn)) {
            errorObject.requiredEffBased = true;
        }
        if (_.isNullOrEmpty(this.stateAssociated)) {
            errorObject.requiredState = true;
        }

        return errorObject;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class TransferTaxConfigurationDto {
    constructor(options) {
        options = options || {};
        this.transferTaxConfigurationID = _.parseNumber(options.transferTaxConfigurationID,0);
        this.regionID = _.parseNumber(options.regionID, 0);
        this.effectiveDate = options.effectiveDate || null;
        this.transferTaxTypeID = _.parseNumber(options.transferTaxTypeID,null);
        this.effectiveBasedOn = _.parseNumber(options.effectiveBasedOn, 0);
        this.stateAssociated = options.stateAssociated || null;
        this.cityAssociated = options.cityAssociated || null;
        this.whoPays = _.parseNumber(options.whoPays,0);
        this.percentage = _.parseNumber(options.percentage,0);
        this.payeeCompanyId = _.parseNumber(options.payeeCompanyId,0);
        this.accountCodeId = _.parseNumber(options.accountCodeId, null);
        this.friendlyName = options.friendlyName || null;
        this.inactive = _.parseBool(options.inactive);
        this.huD1974LineType = _.parseNumber(options.huD1974LineType,0);
        this.huD2010LineType = _.parseNumber(options.huD2010LineType,0);
        this.cdfLineType = _.parseNumber(options.cdfLineType,0);
        this.cssLineType = _.parseNumber(options.cssLineType,0);
        this.huD1974AdditionalDescription = options.huD1974AdditionalDescription || null;
        this.huD2010AdditionalDescription = options.huD2010AdditionalDescription || null;
        this.cdfAdditionalDescription = options.cdfAdditionalDescription || null;
        this.cssAdditionalDescription = options.cssAdditionalDescription || null;
        this.calculationBasis = _.parseNumber(options.calculationBasis,-1);
        this.flatFeeAmount = _.parseNumber(options.flatFeeAmount,0);
        this.coverageRoundingID = _.parseNumber(options.coverageRoundingID,0);
        this.calculationRoundingID = _.parseNumber(options.calculationRoundingID,0);
        this.description = options.description || null;
        this.percentageMinimum = _.parseNumber(options.percentageMinimum,0);
        this.percentageMaximum = _.parseNumber(options.percentageMaximum,0);
        this.percentagePercent = _.parseNumber(options.percentagePercent,0);
        this.chargePercentageBasisID = _.parseNumber(options.chargePercentageBasisID,0);
        this.scalableBasisID = _.parseNumber(options.scalableBasisID, null);
        this.scalableMinimum = _.parseNumber(options.scalableMinimum,0);
        this.scalableMaximum = _.parseNumber(options.scalableMaximum,0);
        this.payeeCompanyName = options.payeeCompanyName || null;
    }

    get isNew() { return this.transferTaxConfigurationID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isNullOrEmpty(this.regionID) || this.regionID == 0) {
            errorList.push("Region is required.");
        }
        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }
        if (_.isEmpty(this.effectiveDate)) {
            errorList.push("Effective Date is required.");
        }
        if (_.isNullOrEmpty(this.effectiveBasedOn)) {
            errorList.push("Effective Date Based On is required.");
        }
        if (_.isEmpty(this.stateAssociated)) {
            errorList.push("State is required.");
        }

        return errorList;
    }

    get verifiedItem() {
        let errorObject = {};
        if (_.isNullOrEmpty(this.regionID)) {
            errorObject.requiredRegion = true;
        }
        if (_.isNullOrEmpty(this.description)) {
            errorObject.requiredDesc = true;
        }
        if (_.isNullOrEmpty(this.effectiveDate)) {
            errorObject.requiredEff = true;
        }
        if (_.isNullOrEmpty(this.effectiveBasedOn)) {
            errorObject.requiredEffBased = true;
        }
        if (_.isNullOrEmpty(this.stateAssociated)) {
            errorObject.requiredState = true;
        }

        return errorObject;
    }
    get payeeCompany() {
        return {
            companyID: this.payeeCompanyId,
            companyName: this.payeeCompanyName,
        };
    }

    set payeeCompany(val) {
        this.payeeCompanyId = _.get(val, "companyID", null);
        this.payeeCompanyName = _.get(val, "companyName", null);
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class TransferTaxRatesDto {
    constructor(options) {
        options = options || {};
        this.transferTaxRatesID = _.parseNumber(options.transferTaxRatesID,0);
        this.transferTaxConfigurationID = _.parseNumber(options.transferTaxConfigurationID,0);
        this.fromAmount = _.parseNumber(options.fromAmount,0);
        this.toAmount = _.parseNumber(options.toAmount,0);
        this.rate = _.parseNumber(options.rate,0);
        this.per = _.parseNumber(options.per,0);
        this.usePerRate = _.parseBool(options.usePerRate);
        this.maxRate = _.parseNumber(options.maxRate,0);
        this.disableDelete = true;
    }

    get isNew() { return this.transferTaxRatesID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.toAmount) || this.toAmount == 0) {
            errorList.push("To Amount is required.");
        }
        return errorList;
    }
    toDataObject() { return _.toPlainObject(this); }
}
export class TransferTaxCalculationRoundingDto {
    constructor(options) {
        options = options || {};
        this.transferTaxCalculationRoundingID = _.parseNumber(options.transferTaxCalculationRoundingID,0);
        this.transferTaxConfigurationID = _.parseNumber(options.transferTaxConfigurationID,0);
        this.fromAmount = _.parseNumber(options.fromAmount,0);
        this.toAmount = _.parseNumber(options.toAmount,0);
        this.calculationRoundingRuleID = _.parseNumber(options.calculationRoundingRuleID,0);
        this.toNearest = _.parseNumber(options.toNearest,0);
        this.deletedYN = _.parseBool(options.deletedYN);
        this.isSystemRecordYN = _.parseBool(options.isSystemRecordYN);
    }

    get isNew() { return this.transferTaxCalculationRoundingID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.toAmount) || this.toAmount == 0) {
            errorList.push("To Amount is required.");
        }
        return errorList;
    }
    toDataObject() { return _.toPlainObject(this); }
}
export class TransferTaxCoverageRoundingDto {
    constructor(options) {
        options = options || {};
        this.transferTaxCoverageRoundingID = _.parseNumber(options.transferTaxCoverageRoundingID,0);
        this.transferTaxConfigurationID = _.parseNumber(options.transferTaxConfigurationID,0);
        this.fromAmount = _.parseNumber(options.fromAmount,0);
        this.toAmount = _.parseNumber(options.toAmount,0);
        this.coverageRoundingRuleID = _.parseNumber(options.coverageRoundingRuleID,0);
        this.toNearest = _.parseNumber(options.toNearest,0);
        this.deletedYN = _.parseBool(options.deletedYN);
        this.isSystemRecordYN = _.parseBool(options.isSystemRecordYN);
    }

    get isNew() { return this.transferTaxCalculationRoundingID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.toAmount) || this.toAmount == 0) {
            errorList.push("To Amount is required.");
        }
        return errorList;
    }
    toDataObject() { return _.toPlainObject(this); }
}

export class RateDataShortDto {
    constructor(options) {
            options = options || {};
            this.rateID = _.parseNumber(options.rateID, 0);
            this.rateEffectiveDateID = _.parseNumber(options.rateEffectiveDateID, 0);
            this.effectiveDate = options.effectiveDate || null;
            this.effectiveDateBasisID = _.parseNumber(options.effectiveDateBasisID, 1);
            this.linkedPremiumRateID = _.parseNumber(options.linkedPremiumRateID, null);
            this.extendedDescription = options.extendedDescription || null;
            this.code = options.code || null;
            this.state = options.state || null;
            this.rateEngineFileTypeNameID = _.parseNumber(options.rateEngineFileTypeNameID, 3);
            this.regionIDs = options.regionIDs || [];
            this.copyData = _.parseBool(options.copyData, true);
            this.rateCreateType = _.parseNumber(options.rateCreateType, 0);
        }

        get isNew() { return this.rateID == 0; }

        get isNewEffectiveDate() { return this.rateEffectiveDateID == 0; }

        get hasLinkedPremium() { return _.parseNumber(this.linkedPremiumRateID, 0) > 0; }

        get isValid() { return this.validationErrors.length === 0; }

        get validationErrors() {
            let errorList = [];
            if (_.isNullOrEmpty(this.effectiveDateBasisID)) {
                errorList.push("Effective Date Basis is required.");
            }
            if (_.isNullOrEmpty(this.rateEngineFileTypeNameID) && this.rateCreateType !=2) {
                errorList.push("File Type is required.");
            }
            if (_.isNullOrEmpty(this.state) && this.rateCreateType !=2) {
                errorList.push("State is required.");
            }
            if (_.isNullOrEmpty(this.code) && this.rateCreateType !=2) {
                errorList.push("Code is required.");
            }
            if (_.size(this.regionIDs) == 0 && this.rateCreateType !=2) {
                errorList.push("Region is required.");
            }
            if (_.isNullOrEmpty(this.effectiveDate)) {
                errorList.push("Effective Date is required.");
            }
            return errorList;
        }

        get verifiedItem() {
            let errorObject = {};
            if (_.isNullOrEmpty(this.effectiveDateBasisID)) {
                errorObject.requiredEffBasis = true;
            }
            if (_.isNullOrEmpty(this.rateEngineFileTypeNameID) && this.rateCreateType !=2) {
                errorObject.requiredFileType = true;
            }
            if (_.isNullOrEmpty(this.state) && this.rateCreateType !=2) {
                errorObject.requiredState = true;
            }
            if (_.isNullOrEmpty(this.code) && this.rateCreateType !=2) {
                errorObject.requiredCode = true;
            }
            if (_.size(this.regionIDs) == 0 && this.rateCreateType !=2) {
                errorObject.requiredRegion = true;
            }
            if (_.isNullOrEmpty(this.effectiveDate)) {
                errorObject.requiredEffDate = true;
            }
            return errorObject;
        }

        toDataObject() { return _.toPlainObject(this); }
}

export class RateDto {
    constructor(options) {
            options = options || {};
            this.rateID = _.parseNumber(options.rateID, 0);
            this.rateEffectiveDateID = _.parseNumber(options.rateEffectiveDateID, 0);
            this.reportCode = options.reportCode || null;
            this.effectiveDate = options.effectiveDate || null;
            this.premiumTypeID = _.parseNumber(options.premiumTypeID, null);
            this.countyID = _.parseNumber(options.countyID, null);
            this.effectiveDateBasisID = _.parseNumber(options.effectiveDateBasisID, 1);
            this.effectiveDateBasisDisplay = options.effectiveDateBasisDisplay || null;
            this.rateRuleReference = options.rateRuleReference || null;
            this.linkedPremiumRateID = _.parseNumber(options.linkedPremiumRateID, null);
            this.linkedPremiumRateDisplay = options.linkedPremiumRateDisplay || null;
            this.extendedDescription = options.extendedDescription || null;
            this.code = options.code || null;
            this.state = options.state || null;
            this.isInActive = _.parseBool(options.isInActive, false);
            this.rateEngineFileTypeNameID = _.parseNumber(options.rateEngineFileTypeNameID, 3);
            this.rateEngineFileTypeName = options.rateEngineFileTypeName || null;
            this.loan_AccountingCode = _.parseNumber(options.loan_AccountingCode, null);
            this.loan_AccountingCodeDisplay = options.loan_AccountingCodeDisplay || null;
            this.owner_AccountingCode = _.parseNumber(options.owner_AccountingCode, null);
            this.owner_AccountingCodeDisplay = options.owner_AccountingCodeDisplay || null;
            this.associatedRateID = _.parseNumber(options.associatedRateID, null);
            this.taxRateBasedOn = _.parseNumber(options.taxRateBasedOn, -1);
            this.taxRateBasedOnDisplay = options.taxRateBasedOnDisplay || null;
            this.taxContactRole = _.parseNumber(options.taxContactRole, null);
            this.taxType = _.parseNumber(options.taxType, null);
            this.taxTypeDisplay = options.taxTypeDisplay || null;
            this.taxRateEffectiveBasis = _.parseNumber(options.taxRateEffectiveBasis, null);
            this.taxRateEffectiveBasisDisplay = options.taxRateEffectiveBasisDisplay || null;
            this.policyCode = options.policyCode || null;
            this.ricOverride = _.parseBool(options.ricOverride, false);
            this.filingCode = options.filingCode || null;
            this.importDate = options.importDate || null;
            this.countyList = options.countyList || null;
            this.underwriterList = options.underwriterList || null;
            this.lastModified = options.lastModified || null;
            this.lastModifiedBy = options.lastModifiedBy || null;
            this.underwriterIDs = options.underwriterIDs || null;

            this.displayName = options.displayName;
            if(!_.isNil(this.displayName)) return;
            this.setDisplayName()
        }

        setDisplayName() {
            let nameParts = [ this.code, this.state, this.extendedDescription ];
            let result = _.joinParts(nameParts, " - ");
            if(_.isEmpty(result) && this.rateID > 0) {
                this.displayName = "[Display Name Unavailable]";
            }
            this.displayName = result;
        }

        get isNew() { return this.rateID == 0; }

        get isValid() { return this.validationErrors.length === 0; }

        get validationErrors() {
            let errorList = [];
            if (_.isNullOrEmpty(this.effectiveDateBasisID)) {
                errorList.push("Effective Date Basis is required.");
            }
            if (_.isNullOrEmpty(this.rateEngineFileTypeNameID)) {
                errorList.push("File Type is required.");
            }
            if (_.isNullOrEmpty(this.state)) {
                errorList.push("State is required.");
            }
            if (_.isNullOrEmpty(this.code)) {
                errorList.push("Code is required.");
            }
            if (_.isNullOrEmpty(this.effectiveDate)) {
                errorList.push("Effective Date is required.");
            }
            return errorList;
        }
        get verifiedItem() {
            let errorObject = {};
            if (_.isNullOrEmpty(this.effectiveDateBasisID)) {
                errorObject.requiredEffBasis = true;
            }
            if (_.isNullOrEmpty(this.rateEngineFileTypeNameID)) {
                errorObject.requiredFileType = true;
            }
            if (_.isNullOrEmpty(this.state)) {
                errorObject.requiredState = true;
            }
            if (_.isNullOrEmpty(this.code)) {
                errorObject.requiredCode = true;
            }
            if (_.isNullOrEmpty(this.effectiveDate)) {
                errorObject.requiredEffDate = true;
            }
            return errorObject;
        }

        toDataObject() { return _.omit(this, ["rateEffectiveDateID", "effectiveDate", "importDate", "countyList", "underwriterList", "lastModified", "lastModifiedBy"]); }

        static fromLookupItem(rateID, displayName) {
            let result = new RateDto({ rateID });
            if(!_.isEmpty(displayName)) result.displayName = displayName;
            return result;
        }
}
export class RateEffectiveDateDto {
    constructor(options) {
            options = options || {};
            this.rateEffectiveDateID = _.parseNumber(options.rateEffectiveDateID, 0);
            this.rateID = _.parseNumber(options.rateID, null);
            this.effectiveDate = options.effectiveDate || null;
            this.reissueCreditCalculationMethodID = _.parseNumber(options.reissueCreditCalculationMethodID, null);
            this.minimumCharge = _.parseNumber(options.minimumCharge, null);
            this.isLinked_CoverageRounding = _.parseBool(options.isLinked_CoverageRounding, false);
            this.isLinked_TitleInsurance = _.parseBool(options.isLinked_TitleInsurance, false);
            this.isLinked_PremiumRounding = _.parseBool(options.isLinked_PremiumRounding, false);
            this.isLinked_ReissueCredit = _.parseBool(options.isLinked_ReissueCredit, false);
            this.isLinked_SplitCalculation = _.parseBool(options.isLinked_SplitCalculation, false);
            this.importDate = options.importDate || null;
            this.isSystemRecord = _.parseBool(options.isSystemRecord, false);
            this.lastModified = options.lastModified || null;
            this.lastModifiedBy = options.lastModifiedBy || null;
            this.lastRamQuestUpdate = options.lastRamQuestUpdate || null;
            this.tiR_IncreaseAdjustmentPCT = _.parseNumber(options.tiR_IncreaseAdjustmentPCT, 0);
            this.tiR_IncreaseAdjustmentAmount = _.parseNumber(options.tiR_IncreaseAdjustmentAmount, 0);
            this.tiR_AdjustmnetPCT = _.parseNumber(options.tiR_AdjustmnetPCT, 0);
            this.tiR_AdjustmentAmount = _.parseNumber(options.tiR_AdjustmentAmount, 0);
            this.reissueCredit_IncreaseAdjustmentPCT = _.parseNumber(options.reissueCredit_IncreaseAdjustmentPCT, null);
            this.reissueCredit_IncreaseAdjustmentAmount = _.parseNumber(options.reissueCredit_IncreaseAdjustmentAmount, null);
            this.reissueCredit_AdjustmentPCT = _.parseNumber(options.reissueCredit_AdjustmentPCT, null);
            this.reissueCredit_AdjustmentAmount = _.parseNumber(options.reissueCredit_AdjustmentAmount, null);
            this.splitCalc_IncreaseAdjustmenttPCT = _.parseNumber(options.splitCalc_IncreaseAdjustmenttPCT, null);
            this.splitCalc_IncreaseAdjustmentAmount = _.parseNumber(options.splitCalc_IncreaseAdjustmentAmount, null);
            this.splitCalc_AdjustmentPCT = _.parseNumber(options.splitCalc_AdjustmentPCT, null);
            this.splitCalc_AdjustmentAmount = _.parseNumber(options.splitCalc_AdjustmentAmount, null);
            this.minPremiumAfterRIC = _.parseNumber(options.minPremiumAfterRIC, null);
            this.useUnderwriterDefaultSplit = _.parseBool(options.useUnderwriterDefaultSplit, false);
            this.premiumRoundingMethodID = _.parseNumber(options.premiumRoundingMethodID, 0);
            this.coverageRoundingMethodID = _.parseNumber(options.coverageRoundingMethodID, 0);
            this.isUsingGrossCalculation = _.parseBool(options.isUsingGrossCalculation, false);
            this.maximumCharge = _.parseNumber(options.maximumCharge, null);
            this.calculationBasis = _.parseBool(options.calculationBasis, true);
            this.isUsingExcessCalculation = _.parseBool(options.isUsingExcessCalculation, false);
            this.excessPremiumRateID = _.parseNumber(options.excessPremiumRateID, 0);
            this.useReissueExcessUWSplit = _.parseBool(options.useReissueExcessUWSplit, false);
            this.premiumRoundingReissueMethodID = _.parseNumber(options.premiumRoundingReissueMethodID, 5);
            this.coverageRoundingReissueMethodID = _.parseNumber(options.coverageRoundingReissueMethodID, 0);
            this.isLinked_CoverageRoundingReissue = _.parseBool(options.isLinked_CoverageRoundingReissue, 0);
            this.isLinked_PremiumRoundingReissue = _.parseBool(options.isLinked_PremiumRoundingReissue, 0);
            this.reissueCredit_DoNotUsePriorPolicyAmount = _.parseBool(options.reissueCredit_DoNotUsePriorPolicyAmount, false);
        }

        get isNew() { return this.rateEffectiveDateID == 0; }

        get isValid() { return this.validationErrors.length === 0; }

        get tiR_Amount() { return this.tiR_IncreaseAdjustmentAmount > 0 ? 1 : -1; }

        set tiR_Amount(val) {
            if (val == 1) {
                this.tiR_IncreaseAdjustmentAmount = 1;
            } else {
                this.tiR_IncreaseAdjustmentAmount = 0;
            }
        }

        get tiR_PCT() { return this.tiR_IncreaseAdjustmentPCT > 0 ? 1 : -1; }

        set tiR_PCT(val) {
            if (val == 1) {
                this.tiR_IncreaseAdjustmentPCT = 1;
            } else {
                this.tiR_IncreaseAdjustmentPCT = 0;
            }
        }

        get reissueCredit_Amount() { return this.reissueCredit_IncreaseAdjustmentAmount > 0 ? 1 : -1; }

        set reissueCredit_Amount(val) {
            if (val == 1) {
                this.reissueCredit_IncreaseAdjustmentAmount = 1;
            } else {
                this.reissueCredit_IncreaseAdjustmentAmount = 0;
            }
        }

        get reissueCredit_PCT() { return this.reissueCredit_IncreaseAdjustmentPCT > 0 ? 1 : -1; }

        set reissueCredit_PCT(val) {
            if (val == 1) {
                this.reissueCredit_IncreaseAdjustmentPCT = 1;
            } else {
                this.reissueCredit_IncreaseAdjustmentPCT = 0;
            }
        }

        get splitCalc_Amount() { return this.splitCalc_IncreaseAdjustmentAmount > 0 ? 1 : -1; }

        set splitCalc_Amount(val) {
            if (val == 1) {
                this.splitCalc_AdjustmentAmount = 0;
            } else {
                this.splitCalc_IncreaseAdjustmentAmount = 0;
            }
        }

        get splitCalc_PCT() { return this.splitCalc_IncreaseAdjustmentPCT > 0 ? 1 : -1; }

        set splitCalc_PCT(val) {
            if (val == 1) {
                this.splitCalc_AdjustmentPCT = 0;
            } else {
                this.splitCalc_IncreaseAdjustmentPCT = 0;
            }
        }

        get validationErrors() {
            let errorList = [];
            if (_.isNullOrEmpty(this.effectiveDate)) {
                errorList.push("Effective Date is required.");
            } else if (!DateTimeHelper.isValidDate(this.effectiveDate)) {
                errorList.push("Invalid Effective Date.");
            }
            return errorList;
        }

        toDataObject() { return _.toPlainObject(this); }
}

export class RateDataDto {
    constructor(options) {
        options = options || {};
        this.rate = new RateDto(options.rate);
        this.rateEffectiveDate = new RateEffectiveDateDto(options.rateEffectiveDate);
        this.simultaneous = new RateEngineSimultaneousDto(options.simultaneous);
        this.countyIDs = options.countyIDs || [];
        this.orderCategoryIDs = options.orderCategoryIDs || [];
        this.premiumTypeIDs = options.premiumTypeIDs || [];
        this.regionIDs = options.regionIDs || [];
        this.underwriterIDs = options.underwriterIDs || [];
    }
    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.regionIDs)) {
            errorList.push("Region is required.");
        }
        return errorList;
    }

    toShortObject() {
        let returnObj = {
            rateID: this.rate.rateID,
            rateEffectiveDateID: this.rateEffectiveDate.rateEffectiveDateID,
            effectiveDate: this.rateEffectiveDate.effectiveDate,
            effectiveDateBasisID: _.parseNumber(this.rate.effectiveDateBasisID, 1),
            linkedPremiumRateID: _.parseNumber(this.rate.linkedPremiumRateID, null),
            extendedDescription: this.rate.extendedDescription || null,
            code: this.rate.code || null,
            state: this.rate.state || null,
            rateEngineFileTypeNameID: _.parseNumber(this.rate.rateEngineFileTypeNameID, 3),
            regionIDs: this.regionIDs || []
        }
        return new RateDataShortDto(returnObj);
    }

    toDataObject() {
        let returnObj = {
            rate: this.rate.toDataObject(),
            rateEffectiveDate: this.rateEffectiveDate.toDataObject(),
            simultaneous: this.simultaneous.toDataObject(),
            countyIDs: this.countyIDs,
            orderCategoryIDs: this.orderCategoryIDs,
            premiumTypeIDs: this.premiumTypeIDs,
            regionIDs: this.regionIDs,
            underwriterIDs: this.underwriterIDs
        }
        return returnObj;
    }
}

export class RateEngineCoverageRoundingDto {
    constructor(options) {
        options = options || {};
        this.rateEngineCoverageRoundingID = _.parseNumber(options.rateEngineCoverageRoundingID, 0);
        this.rateEffectiveDateID = _.parseNumber(options.rateEffectiveDateID, null);
        this.fromAmount = _.parseNumber(options.fromAmount, 0);
        this.toAmount = _.parseNumber(options.toAmount, null);
        this.rateEngineRoundingRuleID = _.parseNumber(options.rateEngineRoundingRuleID, null);
        this.toNearest = _.parseNumber(options.toNearest, null);
        this.deletedYN = _.parseBool(options.deletedYN, false);
        this.isSystemRecordYN = _.parseBool(options.isSystemRecordYN, false);
    }

    get isNew() { return this.rateEngineCoverageRoundingID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isNil(this.toAmount)) {
            errorList.push("To Amount is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}
export class RateEngineCoverageRoundingReissueDto {
    constructor(options) {
        options = options || {};
        this.rateEngineCoverageRoundingReissueID = _.parseNumber(options.rateEngineCoverageRoundingReissueID, 0);
        this.rateEffectiveDateID = _.parseNumber(options.rateEffectiveDateID, null);
        this.fromAmount = _.parseNumber(options.fromAmount, null);
        this.toAmount = _.parseNumber(options.toAmount, null);
        this.rateEngineRoundingRuleID = _.parseNumber(options.rateEngineRoundingRuleID, null);
        this.toNearest = _.parseNumber(options.toNearest, null);
        this.deletedYN = _.parseBool(options.deletedYN, false);
        this.isSystemRecordYN = _.parseBool(options.isSystemRecordYN, false);
    }

    get isNew() { return this.rateEngineCoverageRoundingReissueID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isNil(this.toAmount)) {
            errorList.push("To Amount is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}
export class RateEnginePremiumRoundingDto {
    constructor(options) {
        options = options || {};
        this.rateEnginePremiumRoundingID = _.parseNumber(options.rateEnginePremiumRoundingID, 0);
        this.rateEffectiveDateID = _.parseNumber(options.rateEffectiveDateID, null);
        this.fromAmount = _.parseNumber(options.fromAmount, 0);
        this.toAmount = _.parseNumber(options.toAmount, 0);
        this.rateEngineRoundingRuleID = _.parseNumber(options.rateEngineRoundingRuleID, 0);
        this.toNearest = _.parseNumber(options.toNearest, 0);
        this.deletedYN = _.parseBool(options.deletedYN, false);
        this.isSystemRecordYN = _.parseBool(options.isSystemRecordYN, false);
    }

    get isNew() { return this.rateEnginePremiumRoundingID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isNullOrEmpty(this.toAmount)) {
            errorList.push("To Amount is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}
export class RateEnginePremiumRoundingReissueDto {
    constructor(options) {
        options = options || {};
        this.rateEnginePremiumRoundingReissueID = _.parseNumber(options.rateEnginePremiumRoundingReissueID, 0);
        this.rateEffectiveDateID = _.parseNumber(options.rateEffectiveDateID, null);
        this.fromAmount = _.parseNumber(options.fromAmount, null);
        this.toAmount = _.parseNumber(options.toAmount, null);
        this.rateEngineRoundingRuleID = _.parseNumber(options.rateEngineRoundingRuleID, null);
        this.toNearest = _.parseNumber(options.toNearest, null);
        this.deletedYN = _.parseBool(options.deletedYN, false);
        this.isSystemRecordYN = _.parseBool(options.isSystemRecordYN, false);
    }

    get isNew() { return this.rateEnginePremiumRoundingReissueID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isNullOrEmpty(this.toAmount)) {
            errorList.push("To Amount is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}
export class RateEngineTitleInsuranceRateDto {
    constructor(options) {
        options = options || {};
        this.rateEngineTitleInsuranceRateID = _.parseNumber(options.rateEngineTitleInsuranceRateID, 0);
        this.rateEffectiveDateID = _.parseNumber(options.rateEffectiveDateID, null);
        this.fromAmount = _.parseNumber(options.fromAmount, 0);
        this.toAmount = _.parseNumber(options.toAmount, null);
        this.rate = _.parseNumber(options.rate, null);
        this.per = _.parseNumber(options.per, 0);
        this.maxRate = _.parseNumber(options.maxRate, null);
        this.deletedYN = _.parseBool(options.deletedYN, false);
        this.isSystemRecordYN = _.parseBool(options.isSystemRecordYN, false);
        this.usePerRate = _.parseBool(options.usePerRate, false);
        this.underwriterSplitPercentage = _.parseNumber(options.underwriterSplitPercentage, null);
        this.underwriterSplitAmount = _.parseNumber(options.underwriterSplitAmount, null);
        this.underwriterSplitAmountPer = _.parseNumber(options.underwriterSplitAmountPer, null);
        this.underwriterSplitMaxRate = _.parseNumber(options.underwriterSplitMaxRate, null);
    }

    get isNew() { return this.rateEngineTitleInsuranceRateID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isNullOrEmpty(this.toAmount)) {
            errorList.push("To Amount is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}
export class RateEngineUnderwriterSplitDto {
    constructor(options) {
        options = options || {};
        this.rateEngineUnderwriterSplitID = _.parseNumber(options.rateEngineUnderwriterSplitID, 0);
        this.rateEffectiveDateID = _.parseNumber(options.rateEffectiveDateID, 0);
        this.fromAmount = _.parseNumber(options.fromAmount, null);
        this.toAmount = _.parseNumber(options.toAmount, null);
        this.splitPercent = _.parseNumber(options.splitPercent, null);
        this.rate = _.parseNumber(options.rate, null);
        this.per = _.parseNumber(options.per, null);
        this.usePerRate = _.parseBool(options.usePerRate, false);
        this.deletedYN = _.parseBool(options.deletedYN, false);
    }

    get isNew() { return this.rateEngineUnderwriterSplitID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isNullOrEmpty(this.toAmount)) {
            errorList.push("To Amount is required.");
        }
        if (_.isNullOrEmpty(this.rateEffectiveDateID)) {
            errorList.push("Rate Effective Date ID is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class EndorsmentExportDto{
    constructor(options) {
        options = options || {};
        this.endorsementID = _.parseNumber(options.endorsementID,0);
        this.policyCode = options.policyCode || null;
        this.code = options.code || null;
        this.description = options.description || null;
        this.regions = options.regions || null;
        this.regionIDs = options.regionIDs || null;
        this.underwriters = options.underwriters || null;
        this.underwriterIDs = options.underwriterIDs || null;
        this.state = options.state || null;
        this.counties = options.counties || null;
        this.countyIDs = options.countyIDs || null;
        this.regionsSelected =  _.parseNumber(options.regionsSelected,0);
        this.underwritersSelected =  _.parseNumber(options.underwritersSelected,0);
        this.countiesSelected =  _.parseNumber(options.countiesSelected,0);
    }

    get isNew() { return this.endorsementID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        return errorList;
    }
    toDataObject() { return _.toPlainObject(this); }
}
export class RateExportDto{
    constructor(options) {
        options = options || {};
        this.rateID = _.parseNumber(options.rateID,0);
        this.premiumCode = options.premiumCode || null;
        this.description = options.description || null;
        this.regions = options.regions || null;
        this.regionIDs = options.regionIDs || null;
        this.underwriters = options.underwriters || null;
        this.underwriterIDs = options.underwriterIDs || null;
        this.state = options.state || null;
        this.counties = options.counties || null;
        this.countyIDs = options.countyIDs || null;
        this.regionsSelected =  _.parseNumber(options.regionsSelected,0);
        this.underwritersSelected =  _.parseNumber(options.underwritersSelected,0);
        this.countiesSelected =  _.parseNumber(options.countiesSelected,0);
    }

    get isNew() { return this.rateID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        return errorList;
    }
    toDataObject() { return _.toPlainObject(this); }
}

export class PremiumSearchRequest {
	constructor(options) {
        options = options || {};
        this.regionID = _.parseNumber(options.regionID, null);
        this.usState = options.usState || null;
        this.countyID = options.countyID || null;
        this.underwriterID =  options.underwriterID || null;
        this.premiumCode = options.premiumCode || null;
        this.premiumDescription = options.premiumDescription || null;
    }
    toDataObject() { return _.toPlainObject(this); }
}