<template>
    <div class="content-wrapper">
        <rq-banner
            variant="error"
            title="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && v$.$error"
        />
        <rq-page-section borderless>
            <div class="row">
                <div class="col col-12 col-md-6 form-group form-required" :class="{'has-error' : v$.item.regID.$error || !v$.item.uniqueRegID }">
                    <label for="regID">Reg ID</label>
                    <input
                        automation_id="txt_regID"
                        type="text"
                        class="form-control"
                        placeholder="Reg ID"
                        :disabled="isAdmin"
                        v-model="v$.item.regID.$model"
                    >
                    <rq-validation-feedback
                        :messages="{
                            'RegID is required.': v$.item.regID.$error,
                            'A Region with this RegID already exists.': !v$.item.uniqueRegID
                        }"
                    />
                </div>
                <div class="col col-12 col-md-6 form-group form-required" :class="{ 'has-error': v$.item.description.$error || v$.item.uniqueDescription.$invalid }">
                    <label for="description">Description</label>
                    <input
                        automation_id="txt_description"
                        type="text"
                        class="form-control"
                        placeholder="Description"
                        maxlength="50"
                        v-model="v$.item.description.$model"
                        @blur="onBlurDescription"
                    >
                    <rq-validation-feedback
                        :messages="{
                            'Description is required.': v$.item.description.$error,
                            'A Region with this Description already exists.': v$.item.uniqueDescription.$invalid
                        }"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="default_duplicate_order_checking">Default Duplicate File Search</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_default_duplicate_order_checking' }"
                        value-expr="id"
                        display-expr="name"
                        :items="duplicateOrderSearchItems"
                        :search-enabled="true"
                        v-model="item.duplicateOrderSearchSetupID"
                    />
                </div>
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="workflow_process_template_id">Default Process Template</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_workflow_process_template_id' }"
                        value-expr="id"
                        display-expr="name"
                        :items="workflowProcessTemplates"
                        :search-enabled="true"
                        v-model="item.workflowProcessTemplateID"
                    />
                </div>
            </div>
             <div class="row">
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="seller_pays_percentage_mortgage_premium">Seller Pays % of Owner's Premium</label>

                    <div class="input-group">
                        <rqInputNumber v-model="item.sellerPaysPercentageOwnersPremium"
                                    defaultValue="0"
                                    decimals="0"
                                    minValue="0"
                                    maxValue="100"
                                    cssClass="form-control"
                                    automation_id="txt_seller_pays_percentage_mortgage_premium">
                        </rqInputNumber>
                        <span class="input-group-text">%</span>
                    </div>
                </div>
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="buyer_pays_percentage_mortgage_premium">Buyer Pays % of Mortgagee Premium</label>

                    <div class="input-group">
                        <rqInputNumber v-model="item.buyerPaysPercentageMortgagePremium"
                                   defaultValue="0"
                                   decimals="0"
                                   minValue="0"
                                   maxValue="100"
                                   cssClass="form-control"
                                   automation_id="txt_buyer_pays_percentage_mortgage_premium">
                        </rqInputNumber>
                        <span class="input-group-text">%</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="default_settlement_statement_type">Default Settlement Statement Type</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_default_settlement_statement_type' }"
                        value-expr="id"
                        display-expr="name"
                        :items="settlementTypeItems"
                        :search-enabled="true"
                        v-model="item.defaultSSType"
                    />
                </div>
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="default_lines_between_clauses">Default Lines Between Clauses</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_default_lines_between_clauses' }"
                        value-expr="id"
                        display-expr="name"
                        :items="linesBetweenClauses"
                        :search-enabled="true"
                        v-model="item.defaultLinesBetweenParagraphs"
                    />
                </div>
                <!--<div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                    <label for="one_time_use_company_notification_email">One-Time Use Company Email</label>
                    <input automation_id="txt_one_time_use_company_notification_email" v-model="item.oneTimeUseCompanyNotification" type="text" class="form-control" id="one_time_use_company_notification_email" placeholder="" maxlength="500">
                </div> -->
            </div>
            <div class="row">
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="endorsement_option_type">Endorsements</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_endorsement_option_type' }"
                        value-expr="id"
                        display-expr="name"
                        :items="endorsementOptionList"
                        :search-enabled="true"
                        v-model="item.breakoutEndorsement"
                    />
                </div>
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <b-form-checkbox
                        automation_id="chk_filter_alarms_by_branch"
                        id="filter_alarms_by_branch"
                        v-model="item.alarmBranchFilter">Filter Alarms by Branch</b-form-checkbox>
                </div>

            </div>
            <rq-section-card v-show="true" title="Additional Deposit" collapsible>
                <div class="row">
                    <div class="col col-12 col-md-6 form-group" :class="{ 'has-error': v$.item.additionalDepositLabel.$error, 'form-required': item.isAdditionalDeposit }">
                        <label for="additionalDeposit">Label</label>
                        <input
                            automation_id="txt_additional_deposit_label"
                            type="text"
                            class="form-control"
                            maxlength="50"
                            v-model="item.additionalDepositLabel"
                        >
                    </div>
                    <div class="col-3 form-group">
                        <label for="isAdditionalDeposit">Enabled</label>
                        <div>
                            <rqSwitch type="stop-light"
                                on="Yes"
                                off="No"
                                size="lg"
                                automation_id="au_isAdditionalDeposit"
                                v-model="item.isAdditionalDeposit"
                            />
                        </div>
                    </div>
                </div>
            </rq-section-card>
        </rq-page-section>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import { LinesBetweenClauses, EndorsementOptionList } from "../../enums";
    import { RegionDto }  from "../models";
    import { required } from "validators";
    import { useVuelidate } from "@vuelidate/core";

    export default {
        name: 'RegionForm',
        props: {
            item: {type: Object, default: null},
            uniqueValidator: { type: Function, default: (() => true) }
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                originalData: new RegionDto(this.item),
                showBanner: false
            };
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),

            ...mapState({
                globalRegionId: state => state.system.globalRegionId,
            }),

            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            isAdmin() { return this.item.regID === 0;},
            linesBetweenClauses() { return  LinesBetweenClauses.lookupItems; },
            settlementTypeItems() { return this.lookupHelpers.getLookupItems(this.lookupItems.SETTLEMENT_TYPES); },
            regionId() { return _.getNumber(this, "item.regionID", null) || -1; },
            duplicateOrderSearchItems() { return this.lookupHelpers.getLookupItems(this.lookupItems.DUPLICATE_ORDER_SEARCH_SETUP, this.regionId); },
            workflowProcessTemplates() { return this.lookupHelpers.getLookupItems(this.lookupItems.WORKFLOW_PROCESS_TEMPLATES, this.regionId); },
            endorsementOptionList() { return  EndorsementOptionList.lookupItems; }
        },

        validations: () => ({
            item: {
                regID: { required },
                description: { required },
                additionalDepositLabel: { required: (val, vm) => {
                    if(vm.isAdditionalDeposit && val === "")
                        return false;
                    else
                        return true;}
                },
                uniqueRegID: (val, vm) => vm.uniqueValidator(val, "regID"),
                uniqueDescription: (val, vm) => vm.uniqueValidator(val, "description")
            }
        }),

        created() {
            this.fetchNextRegID();
        },

        methods: {
            fetchNextRegID() {
                const self = this;
                if(!_.getBool(self, "item.isNew")) return;
                let apiPromise = self.$api.RegionsApi.getNextRegID();
                self.$rqBusy.wait(apiPromise)
                    .then(nextRegId => {
                        self.item.regID = nextRegId;
                    })
                    .catch(e => {
                        self.$toast.info({ message: "Could not load suggested RegID" });
                    });
            },
            save(){
                const self = this;

                self.showBanner = true;
                self.v$.$touch();

                if (self.v$.$error) return Promise.resolve({ item:null, isValid: false });

                let itemData = self.item.toDataObject();
                let changes = self.getAuditChanges(self.originalData.toDataObject(), itemData);

                if (changes.length === 0) return Promise.resolve(true);

                let apiPromise = self.$api.RegionsApi.saveRegion(itemData, changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(item => {
                        self.item.regionID = item.regionID
                        self.$toast.success(`${self.itemTypeName} ${self.item.description} was saved.`);
                        return { item, isValid: true };
                    }).catch(e => {
                        self.$toast.error({ message: `Save failed for ${self.itemTypeName}.` });
                        return { item:null, isValid: false };
                    });
            },
            onBlurDescription(e){
                this.item.description = _.trim(this.item.description);
            }
        }
    }
</script>