<template>
    <div class="config-grid-selector" :class="{ 'config-grid-selector-flexed': flexLayout }">
        <div v-if="hasFilterItems" class="row">
            <div class="col col-12 col-lg-6 col-xl-3 form-group">
                <label for="item_filter">{{filterItemName}}</label>
                    <dx-select-box
                        :input-attr="{ automation_id: elementName('drp', filterItemName), id: 'item_filter'}"
                        :items="filterItems"
                        :display-expr="filterTextField"
                        :value-expr="filterValueField"
                        v-model="filterID"
                        @valueChanged="onFilterChange"
                    />
            </div>
        </div>
        <rq-list-selection-layout
            automation-id-append="grid-selector"
            class="document-selection rq-ls-60-40 rq-ls-pc-50-50"
            :actions="listActions"
            @action="onListSelectionAction">
            <template #left-section>
                <rq-page-section
                    :title="availableItemTitle"
                    :flex-full="flexLayout"
                    :flex-body="flexLayout"
                    borderless>
                    <template v-if="!hideSearch && hasAvailableItems" #header-actions>
                        <ul class="nav ms-auto">
                            <li class="nav-item">
                                <rq-search-input-group
                                    tabindex="0"
                                    size="sm"
                                    v-model="searchText"
                                    @keyup="onItemSearch"
                                    @search="onItemSearch"
                                    @clear="onItemSearchClear"
                                />
                            </li>
                        </ul>
                    </template>
                    <div class="doc-grid-container">
                        <rqdx-data-grid
                            ref="availableItemsDataGrid"
                            automation_id="dg_availableItemsDataGrid"
                            class="rq-datagrid-bordered"
                            :data-source="availableItemsGridDataSource"
                            :config="availableItemsGridConfig"
                            @enterKeyDown="onAddSelectedAvailableItems"
                            @rowDoubleClick="onAvailableItemsGridRowDoubleClick"
                            @selectionChanged="onAvailableItemsSelectionChanged"
                        />
                    </div>
                </rq-page-section>
            </template>
            <template #right-section>
                <rq-page-section
                    :title="selectedItemTitle"
                    :flex-full="flexLayout"
                    :flex-body="flexLayout"
                    borderless>
                    <div class="doc-grid-container">
                        <rqdx-data-grid
                            ref="selectedItemsDataGrid"
                            automation_id="dg_selectedItemsDataGrid"
                            class="rq-datagrid-bordered"
                            :data-source="selectedItemsGridDataSource"
                            :config="selectedItemsGridConfig"
                            @enterKeyDown="onRemoveSelectedItems"
                            @rowDoubleClick="onSelectedItemsGridRowDoubleClick"
                            @selectionChanged="onSelectedItemsSelectionChanged"
                        />
                    </div>
                </rq-page-section>
            </template>
        </rq-list-selection-layout>
    </div>
</template>

<script>
    import { computed } from "vue";
    import { SortableList } from "@/shared/components/rq";
    import { useDualGridSelection } from "@/shared/composables/useDualGridSelection";

    export default {
        name: 'GridSelector',
        props: {
            modelValue: { type: Array, default: () => [] },
            items: { type: Array, default: () => [], required: true },
            itemKey: { type: String, default: null, required: true },
            itemName: { type: String, default: null, required: true },
            titles: { type: Object, default: () => ({ available: null, selected: null }) },
            filterItemName: { type: String, default: null },
            filterItems: { type: Array, default: () => [] },
            filterItemKey: { type: String, default: null },
            filterTextField: { type: String, default: null },
            filterValueField: { type: String, default: null },
            sortDescriptionField: { type: String, default: null },
            sortField: { type: String, default: null },
            gridConfigs: { type: Object, default: () => ({ available: null, selected: null }) },
            readOnly: { type: Boolean, default: false },
            hideSearch: { type: Boolean, default: false },
            hideReorder: { type: Boolean, default: false },
            disabledSearch: { type: Boolean, default: false },
            flexLayout: { type: Boolean, default: false },
            selectAllPages: { type: Boolean, default: false}
        },
        emits: ["update:modelValue"],
        setup(props) {
            const itemsProp = computed(() => props.items);
            // const gridConfigsProp = computed(() => props.gridConfigs);
            // const modelValueProp = computed(() => props.modelValue);
            // const sortFieldProp = computed(() => props.sortField);
            // const itemKeyProp = computed(() => props.itemKey);

            return useDualGridSelection({
                items: itemsProp,
                configs: props.gridConfigs,
                selected: props.modelValue,
                sortField: props.sortField,
                itemKey: props.itemKey
            });
        },
        data() {
            return {
                filterID: 0,
                searchText: ""
            };
        },
        computed: {
            hasFilterItems() { return !_.isEmpty(this.filterItems); },
            availableItemTitle() { return _.isNil(this.titles?.available) ? `Available ${this.itemName}` : this.titles.available; },
            selectedItemTitle() { return _.isNil(this.titles?.selected) ? `Selected ${this.itemName}` : this.titles.selected; },
            listActions() {
                let actions = [
                    { name: "transfer-all-right", tooltip: () => `Add All to Queue${this.hasAvailableItems ? "" : "<br/>(none available)"}`, disabled: () => !this.hasAvailableItems || this.readOnly },
                    { name: "transfer-right", tooltip: () => `Add to Queue${this.hasAvailableItemsSelected ? "" : "<br />(none selected)"}`, disabled: () => !this.hasAvailableItemsSelected || this.readOnly },
                    { name: "transfer-left", tooltip: () => `Remove From Queue${this.hasSelectedItemsSelected ? "" : "<br />(none selected)"}`, disabled: () => !this.hasSelectedItemsSelected || this.readOnly },
                    { name: "transfer-all-left", tooltip: () => `Remove All From Queue${this.hasSelectedItems ? "" : "<br/>(none queued)"}`, disabled: () => !this.hasSelectedItems || this.readOnly },
                ];
                if (!this.hideReorder){
                    actions.push({ name: "reorder", customActionPosition: 'right', tooltip: () => `Reorder Selected ${this.itemName}(s)`, disabled: () =>  !this.hasSelectedItems || this.readOnly });
                }
                return actions;
            }
        },
        watch: {
            items: {
                handler() {
                    this.refreshGrids();
                },
                deep: true
            },
            modelValue: {
                handler(newValue) {
                    if(!_.differs(newValue, this.selectedIDs)) return;
                    this.selectedIDs = newValue?.slice() || [];
                    this.refreshGrids();
                },
                immediate: true,
                deep: true
            },
            selectedIDs: {
                handler(newValue) {
                    if(!_.differs(newValue, this.modelValue)) return;
                    this.$emit("update:modelValue", newValue?.slice() || []);
                    this.refreshGrids();
                },
                deep: true
            },
        },
        mounted() {
            _.delay(() => {
                this.repaintGrids();
            }, 250);
        },
        methods: {
            elementName(prefix="", item="", suffix="") { return _.snakeCase(`${prefix} ${item} ${suffix}`); },

            onListSelectionAction(e) {
                switch(e.name) {
                    case "transfer-all-right": this.onAddAllAvailableItems(); break;
                    case "transfer-right": this.onAddSelectedAvailableItems(); break;
                    case "transfer-left": this.onRemoveSelectedItems(); break;
                    case "transfer-all-left": this.onRemoveAllSelectedItems(); break;
                    case "reorder": this.onSelectedItemsReorder(); break;
                }
            },

            onAddAllAvailableItems(e) {
                if (this.readOnly) return;
                this.$dialog.confirm("Confirm Add All", "Are you sure?  This will add all items.", () => {
                    let ds = this.invokeAvailableItemsGridMethod("getDataSource");
                    
                    let gridItems = [];
                    if(this.selectAllPages){
                        //Gets all items that are available (not just the ones visible on the page)
                        gridItems = this.getItemData("available");
                    }
                    else{
                        //Only gets items that are visible on the page
                        gridItems = ds.items();
                    }
                    let filteredKeys = _.map(gridItems, this.itemKey);
                    this.selectedIDs.push(...filteredKeys);
                });
            },

            onAddSelectedAvailableItems(e) {
                if (this.readOnly) return;
                let allKeys = this.invokeAvailableItemsGridMethod("getSelectedRowKeys") || [];
                this.selectedIDs.push(...allKeys);
            },

            onAvailableItemsSelectionChanged(e) {
                if (this.readOnly) return;
                this.hasAvailableItemsSelected = !_.isEmpty(e.selectedRowKeys);
            },

            onAvailableItemsGridRowDoubleClick(e) {
                if (this.readOnly) return;
                this.selectedIDs.push(e.key);
            },

            onFilterChange(e) {
                if(e.previousValue === e.value) return;
                this.invokeAvailableItemsGridMethod("filter", [this.filterItemKey, "=", e.value]);
            },

            onSelectedItemsGridRowDoubleClick(e) {
                if (this.readOnly) return;
                let itemIndex = _.indexOf(this.selectedIDs, e.key);
                this.selectedIDs.splice(itemIndex, 1);
            },

            onSelectedItemsSelectionChanged(e) {
                if (this.readOnly) return;
                this.hasSelectedItemsSelected = !_.isEmpty(e.selectedRowKeys);
            },

            onSelectedItemsReorder() {
                if (this.readOnly) return;
                const self = this;
                let selectedItemsData = self.getItemData("selected");
                let items = _.map(selectedItemsData, i => {
                    return {
                        id: _.get(i, self.itemKey),
                        description: _.get(i, self.sortDescriptionField),
                        ordinal: i.sequence
                    };
                });

                self.$dialog.open({
                    title: `Reorder ${self.itemName}`,
                    height:"auto",
                    minHeight:450,
                    width:600,
                    addaptive: true,
                    component: SortableList,
                    props: { orderId: 0, items },
                    onOk(e) {
                        let changes = e.component.getChanges();
                        if(_.isEmpty(changes)) return true;
                        self.selectedIDs = _.clone(_.map(_.sortBy(e.component.listItems, ['toOrdinal']), 'id'));
                        self.refreshSelectedGrid();
                        self.$emit('reorder', self.selectedIDs);
                        return true;
                    }
                });
            },
            onRemoveAllSelectedItems(e) {
                if (this.readOnly) return;
                this.$dialog.confirm("Confirm Remove All", "Are you sure?  This will remove all items.", () => {
                    this.selectedIDs = [];
                });
            },
            onRemoveSelectedItems(e) {
                if (this.readOnly) return;
                let allKeys = this.invokeSelectedItemsGridMethod("getSelectedRowKeys") || [];
                this.selectedIDs = _.pull(this.selectedIDs, ...allKeys);
            },
            onItemSearch: _.debounce(function (e) {
                this.invokeAvailableItemsGridMethod("searchByText", this.searchText);
            }, 300, {trailing: true, leading: false}),
            onItemSearchClear() {
                this.searchText = "";
                this.invokeAvailableItemsGridMethod("searchByText", this.searchText);
            }
        },
    }
</script>