<template>
    <div class="content-wrapper">
        <rq-page-section :title="itemTypeNamePlural" headerSize="lg" header-only borderless>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
        </rq-page-section>
        <div class="row ms-2 mt-1">
            <div class="col col-4 form-group">
                <label for="dtp_auto_refresh">Auto Refresh</label>
                <dx-select-box
                    :input-attr="{ automation_id: 'dtp_auto_refresh', id: 'dtp_auto_refresh' }"
                    :items="autoRefreshTimings"
                    value-expr="id"
                    display-expr="name"
                    v-model="selectedAutoRefreshID"
                    placeholder="Auto Refresh..."
                    @value-changed="onAutoRefreshChanged"
                    :show-clear-button="true"
                />
            </div>
            <div class="col col-4 form-group">
                <label for="txt_last_refresh">Last Refresh</label>
                <input automation_id="txt_last_refresh"
                    class="form-control"
                    type="text"
                    v-model="lastRefreshTime"
                    :disabled="true"
                    placeholder="Last Refresh"
                    />
            </div>
        </div>
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            v-model:validation-errors="validationErrors"
            @delete="onDeleteItem"
            integrated-search
            hide-show-column-chooser
            hide-export
            rq-filters
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { ActiveUserDto }  from "../models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";
    import ArrayStore from 'devextreme/data/array_store';

    export default {
        name:"ActiveUserList",
        data () {
            return {
                items: [],
                selectedItem: {},
                validationErrors: [],
                autoRefreshTimings: [{id: 5, name: "Every 5 Seconds"}, {id: 10, name: "Every 10 Seconds"}, {id: 30, name: "Every 30 Seconds"}, {id: 60, name: "Every 1 Minute"}],
                selectedAutoRefreshID: null,
                intervalID: null,
                lastRefreshTime: "",
                };
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            gridDataSource() {
                return new ArrayStore({
                    data: this.items,
                    key: this.itemKey
                });
            },
            activeUserLookup() {
                return _.map(this.items, i => { return { name: i.userDisplayName }; });
            },
            itemKey() { return "usersID"; },
            itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            itemTypeDescription() { return _.get(this.$route.meta, "itemTypeDescription") || ""; },
            selectionActions() {
                const self = this;
                return [{
                    name: "delete",
                    text: "Delete",
                    eventName: "delete",
                    allowMultiSelection: true,
                    tooltip: `Delete ${self.itemTypeName}`,
                    disabled: function(e) {
                        return (_.some(e.data, ['usersID', self.user.usersID])) ? 'Cannot delete your own account' : false;
                    }
                    }];
            },
        },
        watch: {
            validationErrors: function () {
                const self = this;
                self.$events.emit("update-config-error", { message: "Please correct the highlighted errors on screen to continue.", hasError: self.validationErrors.length > 0 });
            }
        },

        created(){
            const self = this;
            self.initGridConfig();
            self.fetchData();
        },

        beforeUnmount() {
            clearInterval(this.intervalID);
        },

        methods: {
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    repaintChangesOnly: true,
                    highlightChanges: true,
                    columns: [
                        {
                            dataField: "userDisplayName",
                            dataType: "string",
                            caption: "User",
                            rqFilter: {
                                displayExpr: "name",
                                valueExpr: "name",
                                filterType: "tags",
                                dataSource: {
                                    loadMode: "raw",
                                    load() {
                                        return self.activeUserLookup;
                                    }
                                }
                            }
                        },
                        { dataField: "gfNo", caption: "File #", dataType: "string" },
                        { dataField: "routeDescription", caption: "Page", dataType: "string" },
                        { dataField: "ignoreConcurrentFileLock", dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate, visible: false },
                        {
                            dataField: "lastActiveTime",
                            dataType: "datetime",
                            caption: "Last Active",
                        },
                        {
                            dataField: "loginTime",
                            dataType: "datetime",
                        },
                    ],
                };
            },

            fetchData() {
                const self = this;
                self.$api.ActiveUserApi.getAll()
                    .then(result => {
                        self.items = _.map(result, i => new ActiveUserDto(i));
                        self.gridInstance.refresh();
                        self.lastRefreshTime = DateTimeHelper.now("yyyy-MM-dd hh:mm:ss a");
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },

            onAutoRefreshChanged(e) {
                if(!e) return;
                const self = this;
                let intervalMs = _.isNil(e.value) ? 0 : e.value * 1000;
                clearInterval(self.intervalID);
                if (intervalMs > 0) {
                    self.intervalID = setInterval(function(){ self.fetchData(); }, intervalMs);
                }
            },


            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 1
                    ? self.itemTypeNamePlural
                    : self.itemTypeName;

                let okHandler = function (args) {
                    let keys = _.map(items, self.itemKey);
                    self.delete(keys);
                    return true;
                }

                self.$dialog.confirm(
                    "Confirm Delete",
                    `Are you sure you want to delete the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            delete(keys) {
                const self = this;
                let apiPromise = self.$api.ActiveUserApi.delete(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.removeItems(keys);
                        let message = keys.length > 1
                            ? `${keys.length} ${self.itemTypeNamePlural} were deleted.`
                            : `${self.itemTypeName} was deleted.`
                        self.$toast.success(message);
                        return true;
                    })
                    .catch(error => {
                        if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                            self.$dialog.messageBox(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                        } else {
                            self.$toast.error(`Error deleting ${self.itemTypeName}.`);
                        }
                        console.error(error);
                        return error;
                    });
            },

            removeItems(keys) {
                const self = this;
                _.forEach(keys, key => {
                    let itemIndex = _.findIndex(self.items, item => item[self.itemKey] === key);
                    if(itemIndex >= 0) self.items.splice(itemIndex, 1);
                });
                self.gridInstance.refresh();
            }
        }
    }
</script>
