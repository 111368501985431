<template>
    <div>
        <rq-page-section :title="category" hide-collapse-tooltip>
            <ul class="nav flex-column">
                <router-link
                    v-for="item in categoryRoutes(category)"
                    :key="item.name"
                    :to="item"
                    custom
                    v-slot="{ navigate }">
                    <li class="nav-item">
                        <a class="nav-link" @click="navigate">{{item.meta.label}}</a>
                    </li>
                </router-link>
            </ul>
        </rq-page-section>
    </div>
</template>

<script>
    export default {
        name: "ConfigNav",
        props: {
            routes: {type: Array},
            category: { type: String }
        },
        methods: {
            categoryRoutes(category) {
                return _.filter(this.routes, r => { return r.meta.category === category; });
            },
            selectedCategory(category) {
                return _.isEqual(category, _.get(this.$route.query, "category")) || _.size(this.categories) === 1;
            }
        }
    }
</script>