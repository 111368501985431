<template>
    <div class="content-wrapper config-search">
        <rq-page-section title="Configuration Search" headerSize="lg" borderless>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">Browse or search for Configuration Settings</div>
            </template>
            <div class="row">
                <div class="col col-12 col-md-6 col-lg-4 form-group">
                    <label for="txt_config_search_term">Search Configuration:</label>
                    <rq-search-input-group
                        id="txt_config_search_term"
                        placeholder="Search..."
                        variant="theme"
                        v-model="searchText"
                        show-search-button
                        v-focus-select-all
                        v-focus.input
                    />
                </div>
            </div>
            <div class="row mb-1">
                <div class="col-auto ms-auto">
                    <rq-section-expand-collapse-all />
                </div>
            </div>
            <rq-section-card
                v-for="(topic, index) in filteredTopics"
                :key="index"
                :title="topic.name"
                body-class="py-1"
                collapsible>
                <div class="row">
                    <div class="col col-3" v-for="category in categoriesByTopic(topic.name)" :key="category">
                        <div class="config-category">{{category}}</div>
                        <div v-for="route in routesByCategory(topic.name, category)"
                            :key="route.name"
                            :automation_id="elementName('btn', route.name)"
                            class="config-route"
                            :title="route.itemTypeDescription"
                            @click="onGoToRoute(route)"
                            @keypress.enter="onGoToRoute(route)">{{route.itemTypeNamePlural}}
                        </div>
                    </div>
                </div>
            </rq-section-card>
        </rq-page-section>
    </div>
</template>
<script>
    import { mapState, mapGetters } from "vuex";
    import { ConfigRouteViewModel, ConfigTopicViewModel }  from "./models";
    import { SYSTEM_MUTATIONS } from "@/store/mutations";
    import { UserScreenAccessLevel } from "@/shared/models/enums";
    import { flattenedRoutes as ConfigRoutes } from "./routes";
    import { useLicenseStore } from "@/store/modules/license";

    export default {
        name:"ConfigurationSearch",
        data() {
            return {
                configRoutes: [],
                topics: []
            }
        },
        computed: {
            ...mapState({
                configSearchTerm: state => state.system.configSearchTerm || "",
            }),
            ...mapGetters(["getRouteAccess"]),
            filteredTopics() {
                let items = this.applySearchFilter(this.configRoutes);
                let uniqueTopics = this.getUniqueBy(items, "topic");
                this.setDisplay(uniqueTopics);
                return _.filter(this.topics, "display");
            },
            searchText: {
                get() { return this.configSearchTerm; },
                set(val) { this.$store.commit(SYSTEM_MUTATIONS.SET_CONFIG_SEARCH, val); }
            }
        },

        created(){
            this.initData();
        },

        methods: {
            initData(){
                const self = this;
                const licenseStore = useLicenseStore();
                const routeFilter = r => {
                    let explicitlyHidden = _.getBool(r, "meta.hideFromSearch");
                    let hasAccess = self.hasRouteAccess(r);
                    let featureFlag = r?.meta?.featureFlag || "";
                    let hasFeatureEnabled = (_.isEmpty(featureFlag) || licenseStore.checkFeature(featureFlag));

                    return !explicitlyHidden
                        && hasAccess
                        && hasFeatureEnabled;
                };
                let searchRoutes = _.filter(ConfigRoutes, routeFilter);
                self.configRoutes = _.map(searchRoutes, r => new ConfigRouteViewModel({
                    itemTypeNamePlural: r.meta.label,
                    itemTypeDescription: r.meta.itemTypeDescription,
                    name: r.name || _.get(r, "children[0].name", ""),
                    topic: r.meta.topic,
                    category: r.meta.category
                }));
                let allTopics = self.getUniqueBy(this.configRoutes, "topic");
                self.topics = _.map(allTopics, r => new ConfigTopicViewModel({name: r, expanded: true, display: true}));
            },

            hasRouteAccess(route) {
                const self = this;
                let routeAccess = self.getRouteAccess(route, UserScreenAccessLevel.None);
                return _.getBool(routeAccess, "hasAccess", true);
            },

            applySearchFilter(items) {
                if (_.isNullOrEmpty(this.searchText)) return items;
                const includesSearchText = itemText => _.includes(_.toLower(itemText), _.toLower(this.searchText));
                return _.filter(items, r => includesSearchText(r.itemTypeNamePlural) || includesSearchText(r.itemTypeDescription));
            },

            categoriesByTopic(topic) {
                let topicItems = _.filter(this.configRoutes, r => { return r.topic === topic; });
                topicItems = this.applySearchFilter(topicItems);
                return this.getUniqueBy(topicItems, "category");
            },

            getUniqueBy(collection, by) {
                return _.map(_.uniqBy(collection, by), by);
            },

            onGoToRoute(route) {
                this.$router.push({name: route.name});
            },

            onSearchClear(e) {
                this.searchText = "";
            },

            routesByCategory(topic, category) {
                let categoryItems = _.filter(this.configRoutes, r => { return r.topic === topic && r.category === category; });
                categoryItems = this.applySearchFilter(categoryItems);
                return categoryItems;
            },

            setDisplay(activeTopics) {
                const self = this;
                _.forEach(self.topics, (topic) => {
                    topic.display = (_.indexOf(activeTopics, topic.name) >= 0);
                });
            },

            elementName(prefix="", item="", suffix="") {
                return _.snakeCase(`${prefix} ${item} ${suffix}`);
            }
        }
    }
</script>