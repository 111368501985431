<template>
    <div class="content-wrapper">
        <rq-page-section
            title="Title Production Custom Tabs"
            :description="itemTypeDescription"
            headerSize="lg"
            borderless
            flex-full
            flex-body>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_add_label"
                            variant="theme"
                            :disabled="readOnly"
                            @click="onAddItem">Add
                        </b-btn>
                    </li>
                </ul>
                <ul class="nav config-actions">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_cancel"
                            variant="action"
                            @click="onCancel">Cancel
                        </b-btn>
                    </li>
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_save"
                            variant="action"
                            @click="onSave({ userInitiated: true })">Save
                        </b-btn>
                    </li>
                </ul>
            </template>
            <div :class="treeContainerClassAttr">
                <rq-grid-action-header
                    v-model:search-value="searchText"
                    :selected-rows="selectedItems"
                    @clear-filters="onClearFilters"
                    @clear-selection="onClearSelection"
                    hide-show-column-chooser
                    hide-export
                    hide-reset
                    has-actions>
                    <ul
                        v-show="!hasSelectedItems"
                        class="nav ms-auto">
                        <li class="nav-item rq-nav-action me-4">
                            <b-form-checkbox
                                automation_id="grid_include_inactive"
                                class="mt-1"
                                v-model="includeInactive"
                            >Include Inactive</b-form-checkbox>
                        </li>
                        <li class="nav-item me-4">
                            <rq-expand-collapse-all
                                v-if="items.length > 1"
                                :all-expanded="allTabsExpanded"
                                :all-collapsed="allTabsCollapsed"
                                @expand-all="expandedRowKeys = tabsWithSectionsKeys.slice()"
                                @collapse-all="expandedRowKeys = []"
                                inline
                            />
                        </li>
                    </ul>
                </rq-grid-action-header>
                <rq-grid-action-bar
                    :actions="selectionActions"
                    :selected-items="selectedItems"
                    @action-click="onActionClick"
                />
                <div class="dx-grid-container">
                    <dx-tree-list
                        ref="treelist"
                        key-expr="id"
                        height="100%"
                        parent-id-expr="parentId"
                        :data-source="treeListDataSource"
                        :show-borders="true"
                        :row-alternation-enabled="true"
                        :allow-column-resizing="true"
                        v-model:expanded-row-keys="expandedRowKeys"
                        v-model:selected-row-keys="selectedRowKeys"
                        @init-new-row="onInitNewRow"
                        @cell-click="onCellClick"
                        @selection-changed="onSelectionChanged"
                        @row-prepared="onRowPrepared"
                        @editor-preparing="onEditorPreparing">
                        <dx-header-filter :visible="true" />
                        <dx-selection mode="multiple" />
                        <dx-editing
                            :allow-adding="true"
                            :allow-updating="true"
                            :allow-deleting="true"
                            mode="row"
                        />
                        <dx-row-dragging
                            :on-drag-change="onDragChange"
                            :on-reorder="onReorder"
                            :allow-drop-inside-item="true"
                            :allow-reordering="true"
                        />
                        <dx-column
                            data-field="label"
                            data-type="string">
                            <dx-required-rule />
                            <dx-custom-rule
                                message="An item with the entered label already exists."
                                :validation-callback="e => !isDuplicate(e.data)"
                            />
                        </dx-column>
                        <dx-column
                            data-field="regionID"
                            data-type="number"
                            caption="Region"
                            :width="200"
                            :min-width="200"
                            :cell-template="regionCellTemplate"
                            edit-cell-template="region-edit-template">
                            <dx-lookup
                                :data-source="regions"
                                value-expr="regionID"
                                display-expr="name"
                            />
                        </dx-column>
                        <dx-column
                            data-field="display"
                            data-type="boolean"
                            caption="Active"
                            :width="100"
                            :min-width="100"
                            cell-template="boolCellTemplate"
                            :allow-header-filtering="false"
                        />
                        <dx-column type="buttons" :visible="false" />
                        <template #boolCellTemplate="{ data: options }">
                            <rq-bool-cell-template :value="options.value" />
                        </template>
                        <template #region-edit-template="{ data: cellInfo }">
                            <div v-if="cellInfo.data.parentId > 0" class="rq-readonly-cell">
                                {{getRegionDisplay(cellInfo.data.parentId)}}
                            </div>
                            <dx-select-box v-else
                                :items="regions"
                                value-expr="regionID"
                                display-expr="name"
                                :value="cellInfo.value"
                                :on-value-changed="e => cellInfo.setValue(e.value)"
                            />
                        </template>
                    </dx-tree-list>
                </div>
                <div class="rq-grid-action-footer">
                    <ul class="nav">
                        <li class="nav-item">
                            <b-btn variant="blend" @click="onAddItem"><FontAwesomeIcon icon="fas fa-plus" class="me-2 text-blue" /> Add New Label</b-btn>
                        </li>
                    </ul>
                </div>
            </div>
        </rq-page-section>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { GlobalEventManager } from '@/app.events';
    import { CustomTabItem, TitleProdCustomTabDto, StandardLanguageSectionDto }  from "../models";
    import { DxTreeList, DxColumn, DxLookup, DxRowDragging, DxEditing, DxSelection, DxHeaderFilter, DxRequiredRule, DxCustomRule } from "devextreme-vue/tree-list";
    import { RqBoolCellTemplate } from "@/shared/components/dx-templates";

    const INACTIVE_FILTER = [
        [["parentId", "=", 0], "and", ["display", "=", true]],
        "or",
        [["parentId", ">", 0], "and", ["display", "=", true], "and", ["parentDisplay", "=", true]]
    ];

    export default {
        name:"TitleProdCustomTabList",
        components: {
            DxTreeList,
            DxColumn,
            DxLookup,
            DxRowDragging,
            DxEditing,
            DxSelection,
            RqBoolCellTemplate,
            DxHeaderFilter,
            DxRequiredRule,
            DxCustomRule,
        },
        data () {
            return {
                items: [],
                originalData: {},
                deletedItems: [],
                tabs: [],
                sections: [],
                gridDataSource: [],
                expandedRowKeys: [],
                selectedRowKeys: [],
                selectedItems: [],
                searchText: "",
                includeInactive: false,
                newTabId:0
            };
        },

        computed: {
            ...mapState({
                readOnly: state => state.isPageReadOnly,
                regions: state => state.system.lookups.regions,
                globalRegionId: state => state.system.globalRegionId
            }),
            treeContainerClassAttr() {
                return [
                    "grid-container",
                    "rq-grid-with-toolbar",
                    "rq-grid-header-floating",
                    "rq-grid-multi-select",
                    "rq-grid-allow-select-all",
                    "rq-grid-with-footer-actions",
                    "mt-2"
                ];
            },
            itemTypeDescription() { return _.get(this, "$route.meta.itemTypeDescription", null) || "Custom Tabs and Sections in Title Production."; },
            selectionActions() {
                const self = this;
                return [{
                    name: "delete-action",
                    eventName: "delete",
                    text: "Delete",
                    allowMultiSelection: true,
                    disabled: e => self.readOnly ? "Access Restricted" : _.some(e.data, item => !item.canDelete) ? "Cannot delete labels in use." : false
                }];
            },
            hasSelectedItems() { return !_.isEmpty(this.selectedItems); },
            tabsWithSectionsKeys() { return _.pull(_.uniq(_.map(this.items, "parentId")), 0); },
            allTabsExpanded() { return !_.isEmpty(this.expandedRowKeys) && this.expandedRowKeys.length === this.tabsWithSectionsKeys.length; },
            allTabsCollapsed() { return _.isEmpty(this.expandedRowKeys); },
        },

        watch: {
            searchText(newValue, oldValue) {
                if(newValue === oldValue) return;
                if(newValue.length > 3 || _.isEmpty(newValue)) {
                    this.invokeTreeListMethod("searchByText", newValue);
                }
            },
            includeInactive(newValue, oldValue) {
                if(newValue === oldValue) return;
                if(newValue)
                    this.invokeTreeListMethod("clearFilter", "dataSource");
                else
                    this.invokeTreeListMethod("filter", INACTIVE_FILTER);
            },
        },

        created() {
            const self = this;
            self.initTreeListDataSource();
            GlobalEventManager.onSave(self, self.onSave);
        },

        beforeUnmount() {
            const self = this;
            GlobalEventManager.unregister(self);
        },

        methods: {
            initTreeListDataSource() {
                const self = this;
                self.treeListDataSource = {
                    key: "id",
                    load: () => Promise.resolve(self.items),
                    filter: INACTIVE_FILTER,
                    insert: self.insertItem,
                    update: self.updateItem
                };
                self.fetchData();
            },
            fetchData() {
                const self = this;
                let apiPromise = self.$api.TitleProdCustomTabsApi.get();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.mapData(result);
                        self.deletedItems = [];
                        self.refresh();
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error("Error loading Custom Sections.");
                        return error;
                    });
            },

            mapData(result) {
                const self = this;
                let items = [];
                self.tabs = _.map(result.tabs, i => new TitleProdCustomTabDto(i, self.globalRegionId));
                self.sections = _.map(result.sections, i => new StandardLanguageSectionDto(i));

                _.forEach(self.tabs, t => {
                    let tabItem = CustomTabItem.fromDto(t);
                    let tabSections = _.filter(self.sections, { titleProdCustomTabID: t.titleProdCustomTabID });
                    let sectionItems = _.map(tabSections, s => CustomTabItem.fromDto(s, tabItem));
                    items.push(tabItem, ...sectionItems);
                });

                self.items = _.sortBy(items, "globalSequence");
                self.originalData = self.createSaveRequest();
            },

            getDragDropEventInfo(e, logOnConsole=false) {
                let dropInto = e.dropInsideItem;
                let visibleRows = e.component.getVisibleRows();
                let maxIndex = visibleRows.length - 1;
                let sourceNode = e.component.getNodeByKey(e.itemData.id);

                let targetVisibleIndex = (e.fromIndex > e.toIndex && !dropInto) ? e.toIndex - 1 : e.toIndex;
                targetVisibleIndex =  targetVisibleIndex > maxIndex ? maxIndex : targetVisibleIndex;
                let targetNode = targetVisibleIndex < 0 ? null : visibleRows[targetVisibleIndex].node;

                //DevEx chose to go with an incredibly convoluted way to portray potential drop location/index which
                //is not particularly reliable (their demo doesn't even work right and shows items in the wrong place after dropping them)
                //so this is attempting to brute force it by validating/deriving the relative visible siblings at the drop location then
                //readjusting sequences and resorting after that
                let preceeding = targetVisibleIndex >= 0 || dropInto ? targetNode.data : null;
                let succeeding = targetVisibleIndex === maxIndex ? null : visibleRows[targetVisibleIndex + 1].node.data;
                let parentExpanded = succeeding?.parentId > 0 ? this.invokeTreeListMethod("isRowExpanded", succeeding.parentId) : false;

                let sourceIndex = _.findIndex(this.items, item => item.id === sourceNode.data.id);
                let targetIndex = _.isNil(targetNode) ? 0 : _.findIndex(this.items, item => item.id === targetNode.data.id);

                let result = {
                    item: sourceNode.data,
                    sourceIndex,
                    targetIndex,
                    preceeding,
                    succeeding,
                    parentExpanded,
                    dropInto
                };

                if(logOnConsole) {
                    let isValidDrop = this.isDropValid(result);
                    let logInfo = `Target Item - ${this.items[sourceIndex].label}`;
                        logInfo += `\nfromIndex: ${e.fromIndex}, toIndex: ${e.toIndex}`;
                        logInfo += `\nsourceIndex: ${sourceIndex}, targetIndex: ${targetIndex}`;
                        logInfo += `\nDropping into? ${e.dropInsideItem}`;
                        if(!_.isNil(preceeding))
                            logInfo += `\nNew Preceding Item - ${preceeding?.label}`;
                        if(!_.isNil(succeeding))
                            logInfo += `\nNew Succeeding Item - ${succeeding.label}`;
                        logInfo += `\nIs Drop Valid? ${isValidDrop}`;
                        logInfo += "\n---------";
                    console.log(logInfo);
                }

                return result;
            },

            onAddItem() {
                this.invokeTreeListMethod("addRow");
            },

            onInitNewRow(e) {
                e.data.display = true;
            },

            onRowPrepared (e) {
                if(e.rowType !== "data" || _.getBool(e, "data.display")) return;
                e.rowElement.addClass("rq-strike-through");
            },

            onCellClick(e) {
                const self = this;
                if(e.rowType !== "data"
                    || e.column.type === "selection"
                    || e.row.isEditing
                    || e.columnIndex === 0
                    || (e.columnIndex === 1
                        && !e.event.target.classList.contains("dx-treelist-text-content"))) return;
                let rowIndex = e.rowIndex;
                e.component.clearSelection();
                self.$nextTick(() => {
                    if(e.component.hasEditData()) {
                        e.component.saveEditData()
                            .then(() => {
                                e.component.editRow(rowIndex);
                            });
                    }
                    else {
                        e.component.editRow(rowIndex);
                    }
                });
            },

            onSelectionChanged(e) {
                const self = this;
                let expectedRowKeys = self.getExpectedRowKeys(e);

                self.selectedItems = e.selectedRowsData.slice();

                if(expectedRowKeys.length === e.selectedRowKeys.length) return;

                self.selectedRowKeys = expectedRowKeys;
            },

            onEditorPreparing(e) {
                if(e.parentType !== "dataRow"
                    || e.type === "selection"
                    || e.dataField !== "regionID"
                    || _.getNumber(e, "row.data.parentId", 0) === 0) return;
                e.editorOptions.disabled = true;
            },

            regionCellTemplate(cellElement, cellInfo) {
                let tabId = cellInfo.data.parentId > 0
                    ? cellInfo.data.parentId
                    : cellInfo.data.id;
                let regionDisplay = this.getRegionDisplay(tabId);
                cellElement.append(regionDisplay);
            },

            getRegionDisplay(id) {
                let tab = _.find(this.items, { id });
                let regionID = _.parseNumber(tab.regionID, this.globalRegionId);
                let region = _.find(this.regions, { regionID });
                return region.name;
            },

            getExpectedRowKeys({ selectedRowKeys, selectedRowsData, currentDeselectedRowKeys }) {
                const self = this;
                let selectedTabs = _.filter(selectedRowsData, item => item.parentId === 0);
                let selectedTabKeys = _.map(selectedTabs, "id");
                let tabSections = _.filter(self.items, item => _.includes(selectedTabKeys, item.parentId));
                let tabSectionKeys = _.map(tabSections, "id");
                let result = _.uniq(_.concat(selectedRowKeys, tabSectionKeys));

                if(currentDeselectedRowKeys.length > 0) {
                    let deselectSections = _.filter(self.items, item => _.includes(currentDeselectedRowKeys, item.parentId));
                    let deselectSectionKeys = _.map(deselectSections, "id");
                    _.pullAll(result, deselectSectionKeys);
                }

                return result;
            },

            onDragChange(e) {
                let evt = this.getDragDropEventInfo(e);
                if(this.isDropValid(evt)) return;
                e.cancel = true;
            },

            onReorder(e) {
                let evt = this.getDragDropEventInfo(e, true);
                if(!this.isDropValid(evt)) return;
                this.repositionItem(evt);
            },

            onActionClick(e) {
                switch(e.name) {
                    case "delete-action":
                        this.onDeleteAction();
                        break;
                }
            },

            onClearFilters() {
                this.invokeTreeListMethod("clearFilter");
                this.$toast.success('Grid filters cleared.');
            },

            onClearSelection() {
                this.selectedRowKeys = [];
            },

            onDeleteAction() {
                const self = this;
                if(_.isEmpty(self.selectedItems)) return;
                let okHandler = () => {
                    let selectedKeys = _.map(self.selectedItems, "id");
                    self.delete(selectedKeys);
                    return true;
                };
                self.$dialog.confirm(
                    "Confirm",
                    "Are you sure you want to delete the selected tabs and/or sections?",
                    okHandler,
                    null,
                    { cancelTitle: "No", okTitle: "Yes"}
                );
            },

            onSave({ userInitiated=false }) {
                const self = this;

                if(self.invokeTreeListMethod("hasEditData")) {
                    self.invokeTreeListMethod("saveEditData")
                        .then(() => {
                            self.onSave({ userInitiated });
                        });
                    return;
                }

                let saveRequest = self.createSaveRequest();
                let changes = self.getAuditChanges(self.originalData, saveRequest);
                if(changes.length > 0) {
                    self.save(saveRequest);
                }
                else {
                    if(userInitiated) self.$toast.info("No changes detected.");
                    GlobalEventManager.saveCompleted({ success: true });
                }
            },

            onCancel() {
                const self = this;
                let hasEditData = self.invokeTreeListMethod("hasEditData");
                let currentData = self.createSaveRequest();
                let changes = self.getAuditChanges(self.originalData, currentData);
                if (!hasEditData && _.isEmpty(changes)) {
                    self.$toast.info("No changes detected.");
                    return;
                }
                let okHandler = () => {
                    if(hasEditData)
                        self.invokeTreeListMethod("cancelEditData");
                    if(!_.isEmpty(changes))
                        self.fetchData();
                    return true;
                };
                self.$dialog.confirm(
                    "Confirm",
                    "Are you sure you want to cancel your changes?",
                    okHandler,
                    null,
                    { cancelTitle: "No", okTitle: "Yes"}
                );
            },

            repositionItem(evt) {
                let items = this.items.slice();
                this.updateItemDependencies(evt, items);
                this.items = this.refreshHeirarchy(items);
                this.refresh();
            },

            updateItemDependencies({ item, targetIndex, preceeding, succeeding, parentExpanded, dropInto }, items) {
                let pid = _.getNumber(preceeding, "id", -1);
                let sid = _.getNumber(succeeding, "id", -1);
                let pParentId = _.getNumber(preceeding, "parentId", -1);
                let sParentId = _.getNumber(succeeding, "parentId", -1);
                let targetFirst = targetIndex === 0;
                let targetLast = targetIndex === items.length - 1;

                const setDeleted = () => {
                    this.deletedItems.push(_.cloneDeep(item));
                    item.data = { canDelete: true };
                };

                const setParentId = () => {
                    item.parentId = pParentId === 0 ? pid : pParentId;
                };

                const getTabSequence = () => pParentId === 0 ? preceeding.sequence + 0.5 : sParentId === 0 ? succeeding.sequence - 0.5 : item.sequence;
                const getSectionSequence = () => pParentId > 0 ? preceeding.sequence + 0.5 : sParentId > 0 ? succeeding.sequence - 0.5 : item.sequence;
                const setSequence = (isTab=false) => {
                    item.sequence = dropInto ? items.length + 1
                        : targetFirst ? 0
                        : targetLast ? items.length + 1
                        : isTab ? getTabSequence()
                        : getSectionSequence();
                };

                if(item.parentId === 0) {
                    if(dropInto || parentExpanded) {
                        if(this.hasSections(item.id)) return;
                        //if this tab is becoming a section, set the original as deleted and set the new parent id and sequence
                        setDeleted();
                        setParentId();
                        setSequence();
                    }
                    else {
                        //otherwise just set the new sequence
                        setSequence(true);
                    }
                }
                else if (item.parentId > 0) {
                    if(dropInto
                        || ((parentExpanded && pParentId === 0 && pid !== item.parentId) || (pParentId > 0 && pParentId !== item.parentId))
                            && (sParentId > 0 && sParentId !== item.parentId)) {
                        //if we're dropping a section into a tab or reordering to before or after a section in a different tab, set that parent
                        setParentId();
                        setSequence();
                    }
                    else if(targetFirst || targetLast || (sParentId === 0 && pParentId > 0 && pParentId !== item.parentId) || (pParentId === 0 && pid !== item.parentId)){
                        //if this section is becoming a tab, set the original as deleted, set the parent id to 0 and set the new tab sequence
                        setDeleted();
                        item.parentId = 0;
                        setSequence(true);
                    }
                    else {
                        //otherwise just set the new sequence
                        setSequence();
                    }
                }
            },

            refreshHeirarchy(items) {
                const getItems = (parentId=0) => {
                    let filtered = _.filter(items, { parentId });
                    return _.sortBy(filtered, "sequence");
                };
                let tabItems = getItems();
                _.forEach(tabItems, (t, tIdx) => {
                    let tabSections = getItems(t.id);
                    t.sequence = tIdx + 1;
                    t.globalSequence = t.sequence;
                    _.forEach(tabSections, (s, sIdx) => {
                        s.sequence = sIdx + 1
                        s.tabId = t.tabId;
                        if(s.sectionId === 0)
                            s.sectionId = _.parseNumber(_.uniqueId()) * -1;
                        s.globalSequence = t.sequence + (s.sequence/10);
                    });
                    if(t.sectionId > 0) {
                        t.sectionId = 0;
                        t.tabId = _.parseNumber(_.uniqueId()) * -1;
                    }
                });

                return _.sortBy(items, "globalSequence");
            },

            insertItem(values) {
                const self = this;
                let newItem = new CustomTabItem(values);
                newItem.tabId= self.newTabId--;
                self.items.push(newItem);
                return Promise.resolve(newItem);
            },

            updateItem(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item.id === key);
                if(itemIndex < 0) {
                    return self.insertItem(values);
                }
                _.assign(self.items[itemIndex], values);
                return Promise.resolve(self.items[itemIndex]);
            },

            delete(keys) {
                const self = this;
                let items = self.items.slice();
                let pendingDeletion = _.remove(items, item => _.includes(keys, item.id));
                _.remove(pendingDeletion, item => item.id <= 0); //remove any that never were saved to begin with
                if(!_.isEmpty(pendingDeletion))
                    self.deletedItems.push(...pendingDeletion);
                self.items = self.refreshHeirarchy(items);
                self.refresh();
            },

            isDropValid({ item, preceeding, succeeding, dropInto }) {
                if(_.isNil(preceeding)) return true;

                let pParentId = _.getNumber(preceeding, "parentId", -1);
                let sParentId = _.getNumber(succeeding, "parentId", -1);
                let parentExpanded = sParentId > 0 ? this.invokeTreeListMethod("isRowExpanded", sParentId) : false;

                if(item.parentId === 0
                    && (pParentId === item.id
                        || sParentId === item.id
                        || (this.hasSections(item.id)
                            && (dropInto || parentExpanded)))) {
                    return false;
                }
                if(item.parentId > 0 && !item.canDelete && ((dropInto && preceeding.id !== item.parentId) || (!dropInto && sParentId !== item.parentId))) {
                    return false
                }
                if(dropInto && pParentId > 0) {
                    return false;
                }
                return true;
            },

            isDuplicate(item){
                const self = this;
                let trimLower = val => _.toLower(_.trim(val));
                return _.some(self.items, i =>
                    trimLower(i.label) === trimLower(item.label)
                    && i.id !== item.id
                    && i.parentId === item.parentId
                );
            },

            createSaveRequest() {
                const self = this;
                let tabItems = _.filter(self.items, item => item.parentId === 0);
                let sectionItems = _.filter(self.items, item => item.parentId > 0);
                let resultData = {
                    tabs: _.map(tabItems, t => t.toDataObject()),
                    sections: _.map(sectionItems, s => {
                        let parent = _.find(tabItems, t => t.id === s.parentId);
                        return s.toDataObject(parent);
                    })
                };
                _.forEach(self.deletedItems, item => {
                    item.isDeleted = true;
                    let itemData = item.toDataObject();
                    if(item.data instanceof TitleProdCustomTabDto) {
                        resultData.tabs.push(itemData);
                    }
                    if(item.data instanceof StandardLanguageSectionDto) {
                        resultData.sections.push(itemData);
                    }
                });

                return resultData;
            },

            save(request) {
                const self = this;
                let apiPromise = self.$api.TitleProdCustomTabsApi.save(request);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$toast.success("Title Production Custom Tab/Section changes saved successfully.");
                        self.mapData(result);
                        self.refresh();
                    })
                    .catch(err => {
                        self.$toast.error("An issue occurred while saving Title Production Custom Tabs/Sections.");
                        console.error(err);
                    })
                    .finally(() => {
                        GlobalEventManager.saveCompleted({ success: true });
                    });
            },

            hasSections(parentId) {
                return _.sumBy(this.items, { parentId }) > 0;
            },

            refresh() {
                this.invokeTreeListMethod("refresh");
            },

            invokeTreeListMethod(method, ...params) {
                return _.invoke(this, `$refs.treelist.instance.${method}`, ...params);
            },
        }
    };
</script>