import WorkflowMain from "./WorkflowMain.vue";
import ConfigNav from "../shared/ConfigNav.vue";
import Routes from "./routes";
import { Categories } from "./categories";

export default {
    path: "/configuration/workflow",
    component: WorkflowMain,
    meta: {
        label: "Workflow & Vendor Services",
        include: true,
        skipSaveOnLeave: true
    },
    children: [{
            name: "cfg:workflow:root",
            path: "",
            component: ConfigNav,
            props: {routes: Routes, category : Categories.Workflow},
            meta: {
                label: Categories.Workflow,
                skipSaveOnLeave: true
            }
        },
        ...Routes
    ]
};