<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            @action="onAction"
            fixed-header
            rq-filters
            integrated-search
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { FileScanCategorySecurityUserDto }  from "../models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name:"FileScanCategorySecurityList",
        props: {
            fileScanCategoryId: { type: Number, default: null },
            fileScanCategoryRegionId: { type: Number, default: null },
            itemTypeName: {type: String, default: null},
            userGroups: { type: Array, default: () => [] }
        },
        data () {
            return {
                hasChanges: false,
                itemTypeNamePlural: "Document Management Category Security Users" ,
                items: [],
                originalItems: [],
                accessTypes: [
                    { id: 0, name: "No Access" },
                    { id: 1, name: "Read Only" },
                    { id: 2, name: "Full Access" }
                ]
            };
        },

        computed: {
            ...mapGetters([ "lookupHelpers" ]),
            ...mapState({
                globalRegionId: state => state.system.globalRegionId
            }),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            selectionActions() {
                return _.map(this.accessTypes, t => ({
                    name: _.kebabCase(t.name),
                    text: t.name,
                    tooltip: t.name,
                    allowMultiSelection: true
                }));
            }
        },

        created(){
            const self = this;
            self.$emit("disable-ok");
            self.initGridConfig();
            self.fetchData();
        },

        methods: {
            initGridConfig(){
                const self = this;
                let regions = _.map(self.lookupHelpers.getRegions(), r => ({ ...r, displayLabel: `${r.regID} - ${r.description}` }));
                self.gridConfig = {
                    columns: [
                        { dataField: "userFullName", dataType: "string" },
                        {
                            dataField: "regionDisplay",
                            dataType: "string",
                            caption: "User Default Region",
                            calculateSortValue: DxGridUtils.regionDisplaySortValue,
                            rqFilter: {
                                displayExpr: "displayLabel",
                                valueExpr: "displayLabel",
                                filterType: "tags",
                                dataSource: regions
                            }
                        },
                        {
                            dataField: "userGroupName",
                            dataType: "string",
                            caption: "User Security Role",
                            rqFilter: {
                                displayExpr: "login",
                                valueExpr: "login",
                                filterType: "tags",
                                dataSource: {
                                    loadMode: "raw",
                                    load() {
                                        return self.userGroups;
                                    }
                                }
                            }
                        },
                        {
                            dataField: "securityAccess",
                            dataType: "string",
                            lookup: {
                                dataSource: self.accessTypes,
                                valueExpr: "id",
                                displayExpr: "name"
                            }
                        }
                    ]
                };
                self.gridDataSource = {
                    key: "usersID",
                    load: () => Promise.resolve(self.items)
                };
            },

            fetchData() {
                const self = this;

                if (self.fileScanCategoryRegionId && self.fileScanCategoryRegionId == self.globalRegionId) {
                    let apiPromise = self.$api.FileScanCategorySecurityUsersApi.getAllFileScanCategorySecurityUsers(self.fileScanCategoryId);
                    return self.$rqBusy.wait(apiPromise)
                        .then(result => {
                            self.items = _.map(result, i => new FileScanCategorySecurityUserDto(i));
                            self.originalItems = _.map(result, i => new FileScanCategorySecurityUserDto(i));
                            self.gridInstance.refresh();
                        })
                        .catch(error => {
                            console.error(error);
                            self.$toast.error(`Error loading ${self.itemTypeNamePlural}.`);
                            return error;
                        });
                } else {
                    let apiPromise = self.$api.FileScanCategorySecurityUsersApi.getFileScanCategorySecurityUsers(self.fileScanCategoryId);
                    return self.$rqBusy.wait(apiPromise)
                        .then(result => {
                            self.items = _.map(result, i => new FileScanCategorySecurityUserDto(i));
                            self.originalItems = _.map(result, i => new FileScanCategorySecurityUserDto(i));
                            self.gridInstance.refresh();
                        })
                        .catch(error => {
                            console.error(error);
                            self.$toast.error(`Error loading ${self.itemTypeNamePlural}.`);
                            return error;
                        });
                }
            },

            onAction(e) {
                const self = this;
                const actionValues = { "no-access": 0, "read-only": 1, "full-access": 2 };

                if(!_.has(actionValues, e.action.name)) return;

                let userIds = _.map(e.data, "usersID");
                let accessVal = actionValues[e.action.name];

                self.updateAccess(userIds, accessVal);

                if(self.hasChanges) return;

                self.$emit("enable-ok");
                self.hasChanges = true;
            },

            updateAccess(userIDs, accessVal) {
                const self = this;

                _.forEach(self.items, item => {
                    if(!_.includes(userIDs, item.usersID)) return;
                    item.securityAccess = accessVal;
                    item.securityAccessName = self.accessTypes[accessVal].name;
                });

                self.gridInstance.clearSelection();
                self.gridInstance.refresh();
            },

            getUpdatedItems() {
                return _.filter(this.items, item => {
                    let original = _.find(this.originalItems, orig => orig.usersID === item.usersID);
                    return original?.securityAccess !== item.securityAccess;
                });
            },

            save() {
                const self = this;

                if(!self.hasChanges) return Promise.resolve(null);

                let items = self.getUpdatedItems();
                let apiPromise = self.$api.FileScanCategorySecurityUsersApi.saveFileScanCategorySecurityUsers(items);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$toast.success(`${self.itemTypeNamePlural} were saved.`);
                        return result;
                    }).catch(error => {
                        self.$toast.error(`Error saving ${_.toLower(self.itemTypeNamePlural)}.`);
                        console.error(error);
                        return error;
                    });
            },

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },
        }
    }
</script>
