<template>
    <div class="content-wrapper">
        <rq-page-section borderless>
            <div class="row">
                 <div class="col col-12 form-group">
                    <label for="commissionName">Commission Name</label>
                    <input
                        automation_id="txt_commissionName"
                        v-model="item.commissionName"
                        type="text"
                        class="form-control"
                        id="commissionName"
                        placeholder="Commission Name"
                        maxlength="100">
                </div>
            </div>
            <div class="row">
                <div class="col col-12 form-group">
                    <label for="region">Region</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_region', id: 'drp_region' }"
                        :items="regionsLookup"
                        :defaultItemEnabled="false"
                        v-model="item.regionID"
                        value-expr="regionID"
                        display-expr="displayName"
                        :search-enabled="true"
                    />
                </div>
            </div>
            <div class="row">
                 <div class="col col-12 form-group">
                    <label for="commissionExpires">Commission Expires</label>
                    <rqdx-date-box
                        id="dtp_lockdate"
                        v-model="item.commissionExpires"
                    />
                </div>
            </div>
            <div class="row">
                 <div class="col col-12 form-group">
                    <label for="commissionNumber">Commission Number</label>
                    <input
                        automation_id="txt_commissionNumber"
                        v-model="item.commissionNumber"
                        type="text"
                        class="form-control"
                        id="commissionNumber"
                        maxlength="50" />
                </div>
            </div>
            <div class="row">
                <div class="col col-6 form-group">
                    <label for="state">State</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_state', id: 'drp_state', ref: 'stateSelectBox' }"
                        :items="usStatesLookup"
                        v-model="item.state"
                        value-expr="id"
                        display-expr="id"
                        :search-enabled="true"
                        @valueChanged="onStateChanged"
                    />
                </div>
                <div class="col col-6 form-group">
                    <label for="county">County</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_county', id: 'drp_county', ref: 'countySelectBox' }"
                        :items="stateCounties"
                        v-model="item.countyID"
                        value-expr="id"
                        display-expr="name"
                        :search-enabled="true"
                        :disabled="isCountyDisabled"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col col-6 form-group">
                    <label for="additionalState">Additional State</label>
                    <dx-select-box
                        :element-attr="{ automation_id: 'drp_additional_state', id: 'drp_additional_state', ref: 'additionalStateSelectBox' }"
                        :items="usStatesLookup"
                        v-model="item.additionalState"
                        value-expr="id"
                        display-expr="id"
                        :search-enabled="true"
                        @valueChanged="onAdditionalStateChanged"
                    />
                </div>
                <div class="col col-6 form-group">
                    <label for="additionalCounty">Additional County</label>
                     <dx-select-box
                        :input-attr="{ automation_id: 'drp_additionalCounty', id: 'drp_additionalCounty', ref: 'additionalCountySelectBox' }"
                        :items="additionalStateCounties"
                        v-model="item.additionalCountyID"
                        value-expr="id"
                        display-expr="name"
                        :search-enabled="true"
                        :disabled="isAdditionalCountyDisabled"
                    />

                </div>
            </div>
        </rq-page-section>
    </div>
</template>
<script>
    import { mapGetters } from "vuex";

    export default {
        name: 'StaffNotary',
        components: {},
        props: {
            item: {type: Object, default: null},
        },
        data() {
            return {
            }
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "regionsLookup",
                "usStatesLookup"
            ]),
            stateCounties() { return this.lookupHelpers.getCountiesByState(_.get(this, "item.state", '')); },
            additionalStateCounties() { return this.lookupHelpers.getCountiesByState(_.get(this, "item.additionalState", '')); },
            isCountyDisabled() { return _.isNullOrEmpty(_.get(this, "item.state", '')) },
            isAdditionalCountyDisabled() { return _.isNullOrEmpty(_.get(this, "item.additionalState", '')) }
        },

        created(){
        },

        methods: {
            onAdditionalStateChanged(e) {
                if (_.isNullOrEmpty(e.value)) {
                    this.item.additionalCountyID = null;
                }
            },
            onStateChanged(e) {
                if (_.isNullOrEmpty(e.value)) {
                    this.item.countyID = null;
                }
            },
        }
    }
</script>