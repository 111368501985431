<template>
    <rqdx-action-data-grid
        id="workflow-task-packages-list"
        ref="packageDataGrid"
        :automation_id="elementName('tbl')"
        :actions="selectionActions"
        :config="gridConfig"
        :data-source="gridDataSource"
        :export-file-name="elementName('', 'data')"
        :show-include-inactive=!isInDialog
        @delete="onDeleteItem"
        @edit="onEditItem"
        @reset="onReset"
        @activate="onActivateItem"
        target-inactive-column="isInactive"
        :strikethrough-if-true="['isInactive']"
        integrated-search
        rq-filters
    />

</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { WorkflowTaskPackageDto }  from "../models";
    import WorkflowTaskPackagesForm from './WorkflowTaskPackagesForm.vue';
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";


    export default {
        name:"WorkflowTaskPackagesList",
        mixins: [GridSystemLookupMixin],
        props:{
            isInDialog: {type: Boolean, default: false}
        },
        data () {
            return {
                itemKey: "workflowTaskPackageID",
                itemTypeName: "Workflow Task Package",
                itemTypeNamePlural: "Workflow Task Packages",
                items: [],
                selectedItem: {},
                addEventName: "",
                listOptions: {},
                isNew: true
            };
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance() { return _.get(this, "$refs.packageDataGrid.gridInstance", null) || {}; },
            selectionActions() {
                if(this.isInDialog) return;
                return [
                    { name: "edit", text: "Edit", eventName: "edit", requireSelection: true, tooltip: `Edit ${this.itemTypeName}` },
                    { name: "delete", text: "Delete", eventName: "delete", allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}` },
                    { name: "activate", text: "Activate", eventName: "activate", requireSelection: true, tooltip: `Activate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return _.some(e.data, ['isInactive', false]); } },
                    { name: "inactivate", text: "Inactivate", eventName: "activate", requireSelection: true, tooltip: `Inactivate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return _.some(e.data, ['isInactive', true]); } }
                ];
            },
        },

        created(){
            const self = this;
            self.initGridConfig();
            self.initListeners();
            self.fetchData();
            self.listOptions = {
                showSequenceAction: false,
                showExtraAction: false,
            };

        },

        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },

        methods: {
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },

            initGridConfig(){
                const self = this;

                self.gridConfig = {
                    columns: [
                        { dataField: "WorkflowTaskPackageID", dataType: "number", visible: false, allowEditing: false, showInColumnChooser: false, },
                        {
                            dataField: "workflowTaskPackageName", dataType: "string", caption: "Package",
                        },
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "workflowTaskPackageCategoryID",
                                dataType: "number",
                                caption: "Category"
                            },
                            lookupKey: self.lookupItems.WORKFLOW_TASK_PACKAGE_CATEGORIES,
                        }),
                        {
                            dataField: "numberOfTasks", caption: "Number Of Tasks", dataType: "number"
                        },
                        {
                            dataField: "isInactive",
                            caption: "Inactive",
                            dataType: "boolean",
                            visible: false,
                            showInColumnChooser: !this.isInDialog,
                            cellTemplate: DxGridUtils.boolCellTemplate,
                        }
                    ],
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                };
            },

            fetchData() {
                const self = this;
                let apiPromise = self.$api.WorkflowTaskPackagesApi.getWorkflowTaskPackages();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new WorkflowTaskPackageDto(i));
                        if(self.isInDialog)
                            self.items = _.filter(self.items, item => !item.isInactive);
                        self.refresh();
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },

            onAddItem() {
                const self = this;
                self.gridInstance.deselectAll();
                self.selectedItem = new WorkflowTaskPackageDto();
                self.selectedItem.workflowTaskPackageID = -1;
                self.isNew = true;
                self.loadItem(self.selectedItem);
            },

            onEditItem(e) {
                const self = this;
                self.isNew = false;
                self.loadItem(new WorkflowTaskPackageDto(e.data));
            },

            onReset(e) {
                this.fetchData();
            },

            onActivateItem(e){
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 1
                    ? self.itemTypeNamePlural
                    : self.itemTypeName;
                let verb = _.every(items, ['isInactive', true]) ? "Activate" : "Inactivate";

                let okHandler = function (args) {
                    let keys = _.map(items, 'workflowTaskPackageID');
                    self.toggleIsInactive(keys, verb);
                    return true;
                }

                self.$dialog.confirm(
                    `Confirm ${verb}`,
                    `Are you sure you want to ${verb} the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            selectItems(e){
                const self = this;
                let selectedItems = _.invoke(self, "$refs.packageDataGrid.gridInstance.getSelectedRowsData");
                return selectedItems;
            },

            toggleIsInactive(keys, verb) {
                const self = this;
                let apiPromise = self.$api.WorkflowTaskPackagesApi.toggleIsInactive(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.fetchData();
                        self.gridInstance.clearSelection();
                        let message = keys.length > 1
                            ? `${keys.length} ${self.itemTypeNamePlural} were ${verb}d.`
                            : `${self.itemTypeName} was ${verb}d.`
                        self.$toast.success(message);
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error(`Error trying to ${verb} ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                     let itemsToDelete = _.map(items, 'workflowTaskPackageID');
                     let apiPromise = self.$api.WorkflowTaskPackagesApi.deleteWorkflowTaskPackage(itemsToDelete);
                     return self.$rqBusy.wait(apiPromise)
                        .then(results => {
                            self.items = _.map(results, i => new WorkflowTaskPackageDto(i));
                            let message = items.length > 1 ? `${items.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success({ message: message });
                            return true;
                        })
                        .catch(error => {
                            self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            return error;
                        })
                        .finally(() => {
                            self.fetchData();
                            self.refresh();
                        });

                }

                 self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            refresh() {
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },

            loadItem(item) {
                const self = this;
                let dialogID = null;
                let refreshOnExit = false;
                let onOk = (e, addAnother = false) => {
                    return e.component.save()
                        .then(success => {
                            if(!success) return false;
                            if (addAnother) {
                                self.selectedItem = new WorkflowTaskPackageDto();
                                self.selectedItem.workflowTaskPackageID = -1;
                                self.isNew = true;
                                refreshOnExit = true;
                                self.fetchData();
                                self.$dialog.reloadComponent({ dialogID, props: { item: self.selectedItem, itemTypeName: self.itemTypeName, uniqueValidator: (item,field) => !self.isDuplicate(item, field),}});
                                return false;
                            }
                            self.$dialog.close();
                            self.fetchData();
                            return success;
                        });
                };
                let onCancel = e => {
                    if(!refreshOnExit) return true;
                    self.fetchData();
                    self.refresh();
                };
                dialogID = self.$dialog.open({
                    title: `${self.isNew ? `Add ${self.itemTypeName}`: `Edit ${self.itemTypeName}: ${item.workflowTaskPackageName}`}`,
                    height: "85%",
                    width: "1200",
                    resizable: false,
                    scrollable: false,
                    adaptive: false,
                    closeOnEsc: true,
                    component: WorkflowTaskPackagesForm,
                    props: {
                        item: item,
                        itemTypeName: self.itemTypeName,
                        uniqueValidator: (item,field) => !self.isDuplicate(item, field),
                    },
                    buttons: [
                        { title: "Cancel", automationId: "btn_dm_modal_cancel", cssClass: "btn btn-secondary", closeOnComplete: true, onClick: onCancel },
                        { title: "Save and Add Another", automationId: "btn_dm_modal_save_and_another", cssClass: "btn btn-primary", isVisible: self.isNew, onClick: (e) => { return onOk(e, true); } },
                        { title: "Save", automationId: "btn_dm_modal_save", cssClass: "btn btn-primary", closeOnComplete: false, onClick:  (e) => { onOk(e, false); } }
                    ]
                });

            },

            save(item, form, addAnother){
                const self = this;
                let passedItem = item;

                let apiPromise = self.$api.WorkflowTaskPackagesApi.save([passedItem.toDataObject()]);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new WorkflowTaskPackageDto(i));
                        self.$toast.success(`${self.itemTypeName} was saved.`);
                        self.$dialog.close();
                        self.fetchData();
                        self.refresh();
                        return result;
                    })
                    .catch(error => {
                        self.$toast.error(`Error saving ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });

            },
            isDuplicate(item, fieldName){
                const self = this;
                let trimLower = val => _.toLower(_.trim(val));
                return _.some(self.items, unit =>
                    trimLower(unit[fieldName]) === trimLower(item[fieldName])
                    && _.parseNumber(unit[self.itemKey], -1) !== _.parseNumber(item[self.itemKey], -1)
                );
            },

            updateDimensions() {
                this.gridInstance.updateDimensions();
                this.$nextTick(() => {
                    this.gridInstance.repaint();
                });
            },
        }
    }
</script>
