<template>
    <rq-page-section
        title="Members"
        header-class="pb-0"
        body-class="p-0"
        borderless>
        <rq-selection-list-box
            ref="selectionListBox"
            left-label="Available Members"
            right-label="Selected Members"
            v-model:items="memberListData"
            :actions="listActions"
            @extra-action="onExtraAction"
        />
    </rq-page-section>
</template>
<script>
    import { ListBoxItemModel } from "@/shared/models/models";
    import RqSelectionListBox from "@/shared/components/rq/list-box/RqSelectionListBox";

    export default {
        name: "UserMemberSelection",
        components: { RqSelectionListBox },
        props: {
            modelValue: { type: Array, default: () => [] },
            regionId: { type: Number, default: 0 },
            leaderId: { type: Number, default: null },
            leaderName: { type: String, default: null },
            keyName: { type: String, required: true },
            keyValue: { type: Number, required: true }
        },
        data() {
            return {
                allMembers: [],
                memberListData: []
            }
        },

        computed: {
            selectedMembers() { return _.sortBy(_.filter(this.memberListData, { parentIndex: 1 }), "sequence"); },
            listActions() {
                let isDisabled = function(args, marked) {
                    let selected = _.get(args, "selectedItems", null) || [];
                    return selected.length !== 1 || selected[0].isMarked === marked;
                }
                return [
                    { automation_id: "btn_mark_leader", text: "Mark Leader", name: "mark-leader", disabled: args => isDisabled(args, true) },
                    { automation_id: "btn_remove_mark_leader", text: "Remove Leader", name: "remove-mark-leader", disabled: args => isDisabled(args, false) }
                ];
            }
        },

        watch: {
            regionId: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    this.fetchMemberData();
                },
                immediate: true
            },
            selectedMembers(newValue, oldValue) {
                if(this.compareLists(newValue, oldValue) || this.compareLists(newValue, this.modelValue)) return;
                this.$emit("update:modelValue", _.map(newValue, "itemID"));
            }
        },

        methods: {
            onExtraAction(e) {
                let markedValue = e.action.name === "mark-leader";
                _.updateAll(this.selectedMembers, "isMarked", member => {
                    if(markedValue) return e.selectedKeys[0] === member.itemID;
                    if(member.isMarked && e.selectedKeys[0] === member.itemID) return false;
                    return member.isMarked;
                });
                this.updateLeader();
            },

            fetchMemberData () {
                const self = this;

                if (_.lte(self.regionId, 0)) return;

                let apiPromise = self.$api.UsersApi.getUsersByDefaultRegion(self.regionId);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.allMembers = result;

                        let availableFiltered = _.filter(result, item => _.getNumber(item, self.keyName, 0) === 0);
                        let selectedFiltered = self.keyValue > 0
                            ? _.filter(result, { [self.keyName]: self.keyValue })
                            : [];

                        let availableMembers = self.mapMemberListItems(availableFiltered, 0);
                        let selectedMembers = self.mapMemberListItems(selectedFiltered, 1, true);
                        self.memberListData = _.concat(availableMembers, selectedMembers);

                        return result;
                    })
                    .catch(error => {
                        self.$toast.error("Error getting Associated Members.");
                        console.error(error);
                        return error;
                    });
            },

            mapMemberListItems(members, parentIndex, markLeader=false) {
                return _.map(members, item => new ListBoxItemModel({
                    itemID: item.usersID,
                    itemName: (item.fullName && item.fullName.length > 2)
                        ? item.fullName
                        : item.displayName,
                    isMarked: item.usersID === this.leaderId && markLeader,
                    parentIndex
                }));
            },

            updateLeader() {
                let leader = _.find(this.selectedMembers, { isMarked: true });
                this.$emit("update:leaderId", _.isNil(leader) ? null : leader.itemID);
                this.$emit("update:leaderName", _.isNil(leader) ? null : leader.itemName);
            },

            compareLists(listA, listB) {
                let plainListA = _.toPlainObject(listA);
                let plainListB = _.toPlainObject(listB);
                return _.isEqual(plainListA, plainListB);
            },
        }
    }
</script>