import { Categories } from "./categories";
import { Topics } from "../topics"
import AssignedLanguageListTypeForm from "./components/AssignedLanguageListTypeForm";
import LockRemitList from "./components/LockRemitList";
// import PolicyNumberRangeList from "./components/PolicyNumberRangeList";
import TitleProdPolicySettingsForm from "./components/TitleProdPolicySettingsForm";
import TitleProdCustomTabList from "./components/TitleProdCustomTabList";
import PolicyFormTypeList from "./components/PolicyFormTypeList";
import PolicyNumberPoolList from "./components/PolicyNumberPoolList"
import StandardLanguageMain from "./components/StandardlanguageMain";

import LookupNames from "@/store/lookupNames";

let routes = [

    {
        name: "cfg:titleprod:titleprodSettings",
        path: "titleprodSettings",
        component: TitleProdPolicySettingsForm,
        meta: {
            label: "Title Production Settings",
            itemKey: "",
            itemTypeName: "Title Production Setting",
            itemTypeDescription: "Configure default setup for Title Production & Policy.",
            //skipSaveOnLeave: true,
            topic: Topics.TitleProductionPolicy,
            category: Categories.TitleProduction,
            scrollLayout: true,
            sysAdminRequired: true
        }
    },
    {
        name: "cfg:titleprod:titleprodSections",
        path: "titleprodSections",
        component: TitleProdCustomTabList,
        meta: {
            label: "Title Production Custom Sections",
            itemKey: "",//trick our since we need a custom add/reorder
            itemTypeName: "Title Production Custom Section",
            itemTypeDescription: "Allows users to create new tabs with custom sections in Title Production.",
            topic: Topics.TitleProductionPolicy,
            category: Categories.TitleProduction,
            sysAdminRequired: true
        }
    },
    {
        name: "cfg:titleprod:assignLangType",
        path: "assignLangType",
        component: AssignedLanguageListTypeForm,
        meta: {
            label: "Section Labels",
            itemKey: "",
            itemTypeName: "Assigned Language List Type",
            itemTypeDescription: "Customize the section labels that appear in Title Production",
            skipSaveOnLeave: false,
            topic: Topics.TitleProductionPolicy,
            category: Categories.TitleProduction,
            scrollLayout: true,
            sysAdminRequired: true
        }
    },
    {
        name: "cfg:titleprod:remitDate",
        path: "remitDate",
        component: LockRemitList,
        meta: {
            label: "Lock/Unlock Remit Date",
            itemKey: "",//do this to trick out the ConfigurationMain page to NOT show the add button section
            itemTypeName: "Lock/Unlock Remit Date",
            itemTypeDescription: "Allows the Administrator to define the lock or unlock of the remit dates by Underwriter.",
            skipSaveOnLeave: true,
            topic: Topics.TitleProductionPolicy,
            category: Categories.Policy,
            sysAdminRequired: true
        }
    },
    {
        name: "cfg:titleprod:policyPools",
        path: "policyPools",
        component: PolicyNumberPoolList,
        meta: {
            label: "Policy Pools",
            itemKey: "policyNumberPoolID",
            itemTypeName: "Policy Pool",
            itemTypeDescription: "Configure policy number pools to be used on the Policy page.",
            skipSaveOnLeave: true,
            topic: Topics.TitleProductionPolicy,
            category: Categories.Policy,
            refreshLookup: LookupNames.POLICYNUMBERRANGES
        }
    },
    // {
    //     name: "cfg:titleprod:noRanges",
    //     path: "noRanges",
    //     component: PolicyNumberRangeList,
    //     meta: {
    //         label: "Policy Number Ranges",
    //         itemKey: "policyNumberRangeID",
    //         itemTypeName: "Number Range",
    //         itemTypeDescription: "Configure the policy pool number ranges.",
    //         skipSaveOnLeave: true,
    //         topic: Topics.TitleProductionPolicy,
    //         category: Categories.Policy,
    //         refreshLookup: LookupNames.POLICYNUMBERRANGES
    //     }
    // },
    {
        name: "cfg:titleprod:clauses",
        path: "clauses",
        component: StandardLanguageMain,
        meta: {
            label: "Clauses",
            itemKey: "",
            itemTypeName: "Clause",
            itemTypeDescription: "Create, edit, assign to categories and packages for use in Title Production, Policies and Documents.",
            skipSaveOnLeave: true,
            topic: Topics.TitleProductionPolicy,
            category: Categories.TitleProduction,
            permissionKeys: ["appStandardLanguage_AppAccess"],
            refreshLookup: [LookupNames.STANDARD_LANGUAGE_SECTIONS, LookupNames.STANDARD_LANGUAGE_CATEGORIES]
        }
    },
    {
        name: "cfg:titleprod:policyFormTypes",
        path: "policyFormTypes",
        component: PolicyFormTypeList,
        meta: {
            label: "Policy Form Types",
            itemKey: "policyFormTypeID",
            itemTypeName: "Policy Form Type",
            itemTypeDescription: "Define the types of policies to select on the file Main page.",
            skipSaveOnLeave: true,
            topic: Topics.TitleProductionPolicy,
            category: Categories.Policy,
            refreshLookup: LookupNames.POLICY_FORM_TYPES
        }
    },
    // {
    //     name: "cfg:titleprod:clauseCategories",
    //     path: "clauseCategories",
    //     component: StandardLanguageMain,
    //     meta: {
    //         label: "Clause Categories",
    //         selectedIndex: 1,
    //         itemKey: "",
    //         itemTypeName: "Clause Category",
    //         itemTypeDescription: "Edit Clause Categories to assign to Clauses",
    //         skipSaveOnLeave: true,
    //         topic: Topics.TitleProductionPolicy,
    //         category: Categories.TitleProduction
    //     }
    // },
    // {
    //     name: "cfg:titleprod:clausePackages",
    //     path: "clausePackages",
    //     component: StandardLanguageMain,
    //     meta: {
    //         label: "Clause Packages",
    //         selectedIndex: 2,
    //         itemKey: "",
    //         itemTypeName: "Clause Packages",
    //         itemTypeDescription: "Create/Edit Clause Packages to group Clauses together",
    //         skipSaveOnLeave: true,
    //         topic: Topics.TitleProductionPolicy,
    //         category: Categories.TitleProduction
    //     }
    // },
];

export default routes;

