<template>
    <div class="rq-container">
        <rq-banner
            variant="error"
            message="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && v$.$error"
        />
        <div class="row mt-2">
            <div class="col col-12 col-lg-6 form-group form-required" :class="{ 'has-error' : v$.userCopy.displayName.$error }">
                <label for="txt_display_name">Display Name</label>
                <input
                    id="txt_display_name"
                    automation_id="txt_display_name"
                    type="text"
                    class="form-control"
                    placeholder="Display Name"
                    maxlength="50"
                    spellcheck="false"
                    v-model="v$.userCopy.displayName.$model">
                <rq-validation-feedback
                    :container="tooltipContainer"
                    :boundary="tooltipContainer"
                    :messages="{
                        'Display Name is required': v$.userCopy.displayName.required.$invalid
                    }"
                />
            </div>
            <div class="col col-12 col-lg-6 form-group form-required" :class="{ 'has-error' : v$.userCopy.fullName.$error }">
                <label for="txt_full_name">Full Name</label>
                <input
                    id="txt_full_name"
                    automation_id="txt_full_name"
                    type="text"
                    class="form-control"
                    placeholder="Full Name"
                    maxlength="45"
                    spellcheck="false"
                    v-model="v$.userCopy.fullName.$model">
                <rq-validation-feedback
                    :container="tooltipContainer"
                    :boundary="tooltipContainer"
                    :messages="{
                        'Full Name is required': v$.userCopy.fullName.required.$invalid
                    }"
                />
            </div>
        </div>
        <div class="row mt-2">
            <div class="col col-12 col-lg-6 form-group form-required" :class="{ 'has-error' : v$.userCopy.login.$error }">
                <label for="txt_login_name">Login</label>
                <input
                    id="txt_login_name"
                    automation_id="txt_login_name"
                    type="text"
                    class="form-control"
                    placeholder="Login"
                    maxlength="50"
                    spellcheck="false"
                    v-model="v$.userCopy.login.$model">
                <rq-validation-feedback
                    :container="tooltipContainer"
                    :boundary="tooltipContainer"
                    :messages="{ 'Login is required': v$.userCopy.login.required.$invalid, 'Login is in use by another user': v$.userCopy.login.isUnique.$invalid, 'Login must be a valid email address': v$.userCopy.login.email.$invalid }"
                />
            </div>
            <div class="col col-12 col-lg-6 form-group form-required" :class="{ 'has-error' : v$.userCopy.emailAddress.$error }">
                <label for="txt_email_address">Email</label>
                <input
                    id="txt_email_address"
                    automation_id="txt_email_address"
                    type="text"
                    class="form-control"
                    placeholder="Login"
                    maxlength="50"
                    spellcheck="false"
                    v-model="v$.userCopy.emailAddress.$model">
                <rq-validation-feedback
                    :container="tooltipContainer"
                    :boundary="tooltipContainer"
                    :messages="{
                        'Email is required': v$.userCopy.emailAddress.required.$invalid, 'Email must be a valid email address': v$.userCopy.emailAddress.email.$invalid
                    }"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { required, email } from "validators";
    import { useVuelidate } from "@vuelidate/core";

    export default {
        name: 'UserCopyForm',
        props: {
            item: { type: Object, default: null },
            uniqueValidator: { type: Function, default: (() => true) }
        },
        inject: ["dialogId"],
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                userCopy: null,
                showBanner: false
            };
        },
        created() {
            this.tooltipContainer = `#${this.dialogId}`;
            this.userCopy = _.cloneDeep(this.item);
            this.userCopy.fullName = "";
            this.userCopy.displayName = "";
            this.userCopy.login = "";
            this.userCopy.emailAddress = "";
        },

        validations: () => ({
            userCopy: {
                displayName: { required },
                fullName: { required },
                login: {
                    email,
                    required,
                    isUnique(val) {
                        return this.uniqueValidator(0, val);
                    }
                },
                emailAddress: { email, required }
            }
        }),

        watch:{
            "userCopy.login" (newValue, oldValue){
                if(newValue === oldValue) return;
                this.userCopy.emailAddress = newValue;
            }
        },
        
        methods: {
            save() {
                const self = this;
                self.v$.$touch();
                self.showBanner = true;
                if(self.v$.$error) return Promise.resolve({ success: false });

                let apiPromise = self.$api.UsersApi.copyUser(self.userCopy);
                return self.$rqBusy.wait(apiPromise)
                    .then(data => {
                        self.$toast.success("User was copied successfully.");
                        return { data, success: true };
                    }).catch(error => {
                        self.$toast.error("Error copying user.");
                        console.error(error);
                    });
            },
        }
    }
</script>