<template>
    <div class="content-wrapper config-premium-form" v-if="hasRate">
        <rq-banner
            variant="error"
            title="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="hasValidationErrors"/>
        <rq-page-section :title="editTitle" headerSize="lg" header-only borderless>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item" v-rq-tooltip.hover :title="readOnly ? '(Access Restricted)' : ''">
                        <b-btn automation_id="btn_add_rate_effective_date" variant="theme" @click="onAddEffectiveDate" :disabled="readOnly">Add</b-btn>
                    </li>
                </ul>
                <ul v-if="effectiveRates.length > 1" class="nav jump-to-nav ms-auto">
                    <li class="navbar-text">Jump to Record</li>
                    <li class="nav-item form-item">
                        <dx-select-box
                            :input-attr="{ automation_id:'jump_to_effective_date' }"
                            value-expr="id"
                            display-expr="name"
                            :items="effectiveRates"
                            class="form-control form-control-sm"
                            v-model="activeEffectiveRateID"
                            @value-changed="onJumpToEffectiveDateChange"
                        />
                    </li>
                </ul>
                <ul class="nav config-actions">
                    <li class="nav-item rq-expand-collapse">
                        <b-btn
                            automation_id="btn_cancel"
                            variant="action"
                            @click="onCancel">Cancel
                        </b-btn>
                    </li>
                    <li class="nav-item" v-rq-tooltip.hover :title="readOnly ? '(Access Restricted)' : ''">
                        <b-btn
                            automation_id="btn_save"
                            variant="action"
                            :disabled="readOnly"
                            @click="onSave({ userInitiated: true })">Save
                        </b-btn>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <b-btn automation_id="lnk_back" variant="link" :to="{ name:'cfg:sscalc:premiums'}">View Premiums</b-btn>
            </template>
        </rq-page-section>
        <rq-tabs
            :tabs="tabItems"
            v-model="currentTabIndex" small>
            <template #tabs-end>
                <li class="nav-item ms-auto">
                    <rq-section-expand-collapse-all
                        v-show="currentTabIndex === 3"
                        :section-group="sections.reissue.group"
                    />
                    <rq-section-expand-collapse-all
                        v-show="currentTabIndex === 4"
                        :section-group="sections.simultaneous.group"
                    />
                </li>
            </template>
            <template #general>
                <rq-page-section headerSize="md" borderless>
                    <template #header-actions>
                        <ul class="nav" >
                            <li class="nav-item form-group">
                                <b-form-checkbox
                                    automation_id="chk_apply_to_title_services"
                                    id="chk_apply_to_title_services"
                                    :disabled="readOnly"
                                    v-model="data.rate.ricOverride"
                                    >Reissue Override</b-form-checkbox>
                            </li>
                            <li class="nav-item form-group">
                                <b-form-checkbox
                                    automation_id="chk_inactive"
                                    id="chk_inactive"
                                    :disabled="readOnly"
                                    v-model="data.rate.isInActive"
                                    >Inactive</b-form-checkbox>
                            </li>
                        </ul>
                    </template>
                    <fieldset :disabled="readOnly">
                        <div class="row">
                            <div class="col col-6 form-group">
                                <label for="txt_premium_name">Premium Name</label>
                                <input automation_id="txt_premium_name" v-model="premiumName" type="text" class="form-control" disabled>
                            </div>
                            <div class="col col-6 form-group" v-if="hasLinkedPremium">
                                <label for="txt_premium_name">Linked Premium Name</label>
                                <input automation_id="txt_premium_name" v-model="data.rate.linkedPremiumRateDisplay" type="text" class="form-control" disabled>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                                <label for="txt_description">Description</label>
                                <input automation_id="txt_description" v-model="data.rate.extendedDescription" type="text" class="form-control" placeholder="Description" maxlength="50" :disabled="readOnly">
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="tb_premium_types">File Service</label>
                                <dx-tag-box
                                    automation_id="tb_premium_types"
                                    ref="tb_premium_types"
                                    class="form-control"
                                    :data-source="orderServices"
                                    display-expr="name"
                                    value-expr="id"
                                    :show-selection-controls="true"
                                    :show-clear-button="true"
                                    :max-displayed-tags="1"
                                    :show-drop-down-button="true"
                                    apply-value-mode="useButtons"
                                    v-model:value="data.premiumTypeIDs"
                                    :search-enabled="true"
                                    :disabled="readOnly"
                                />
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="tb_order_category">File Category</label>
                                <dx-tag-box
                                    automation_id="tb_order_category"
                                    ref="tb_order_category"
                                    class="form-control"
                                    :data-source="orderCategories"
                                    display-expr="name"
                                    value-expr="id"
                                    :show-selection-controls="true"
                                    :show-clear-button="true"
                                    :max-displayed-tags="1"
                                    :show-drop-down-button="true"
                                    apply-value-mode="useButtons"
                                    v-model:value="data.orderCategoryIDs"
                                    :search-enabled="true"
                                    :disabled="readOnly"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group form-required" :class="{'has-error' : v$.data.rate.effectiveDateBasisID.$error}">
                                <label for="drp_effective_date_basis">Effective Date Basis</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_effective_date_basis', id: 'drp_effective_date_basis' }"
                                    :items="effectiveDateBasedOnOptions"
                                    v-model="v$.data.rate.effectiveDateBasisID.$model"
                                    value-expr="id"
                                    display-expr="name"
                                    :search-enabled="true"
                                    :disabled="readOnly"
                                />
                                <rq-validation-feedback>Effective Date Basis is required</rq-validation-feedback>
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group form-required" :class="{'has-error' : v$.data.rateEffectiveDate.effectiveDate.$error}">
                                <label for="dtp_effective_date">Effective Date</label>
                                <rqdx-date-box
                                    id="dtp_effective_date"
                                    v-model="v$.data.rateEffectiveDate.effectiveDate.$model"
                                    :show-clear-button="false"
                                    :disabled="readOnly"
                                />
                                <rq-validation-feedback
                                    :messages="{
                                        'Effective Date is required.': v$.data.rateEffectiveDate.effectiveDate.$error,
                                        'An Effective Date already exists with that Rate.': v$.data.rateEffectiveDate.effectiveDate.uniqueEffectiveDate.$invalid
                                    }"
                                />
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group form-required" :class="{'has-error' : v$.data.rate.rateEngineFileTypeNameID.$error}">
                                <label for="drp_file_type">File Type</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_file_type', id: 'drp_file_type' }"
                                    :items="rateEngineFileTypeNames"
                                    v-model="v$.data.rate.rateEngineFileTypeNameID.$model"
                                    :disabled="true"
                                    value-expr="id"
                                    display-expr="name"
                                    :search-enabled="true"
                                />
                                <rq-validation-feedback>File Type is required</rq-validation-feedback>
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="dtp_associated_rate">Associated Rate</label>
                                <premium-picker
                                    automation_id="dtp_associated_rate"
                                    dialog-title="Premium Rates"
                                    :disabled="readOnly"
                                    v-model="data.rate.associatedRateID"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_tax_rate_effective_basis">Tax Rate Effective Basis</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_tax_rate_effective_basis', id: 'drp_tax_rate_effective_basis' }"
                                    :items="taxRateEffectiveBasisOptions"
                                    value-expr="id"
                                    display-expr="name"
                                    :disabled="readOnly"
                                    v-model="data.rate.taxRateEffectiveBasis"
                                />
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_tax_rate_based_on">Tax Rate Based On</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_tax_rate_based_on', id: 'drp_tax_rate_based_on' }"
                                    :items="taxRateBasedOnOptions"
                                    :disabled="data.rate.taxRateEffectiveBasis === -1 || readOnly"
                                    value-expr="id"
                                    display-expr="name"
                                    v-model="data.rate.taxRateBasedOn"
                                />
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_tax_contact_role">Tax Contact Role</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_tax_contact_role', id: 'drp_tax_contact_role' }"
                                    :items="taxContactRoles"
                                    :disabled="data.rate.taxRateBasedOn === 1 || readOnly"
                                    value-expr="id"
                                    display-expr="name"
                                    v-model="data.rate.taxContactRole"
                                />
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_tax_type">Tax Type</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_tax_type', id: 'drp_tax_type' }"
                                    :items="taxTypes"
                                    :disabled="data.rate.taxRateEffectiveBasis === -1 || readOnly"
                                    value-expr="id"
                                    display-expr="name"
                                    v-model="data.rate.taxType"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group form-required" :class="{'has-error' : v$.regionIDs.$error}">
                                <label for="tb_regions">Region</label>
                                <dx-tag-box
                                    automation_id="tb_regions"
                                    ref="tb_regions"
                                    class="form-control"
                                    :data-source="regions"
                                    display-expr="displayName"
                                    value-expr="regionID"
                                    :show-selection-controls="true"
                                    :show-clear-button="true"
                                    :max-displayed-tags="1"
                                    :show-drop-down-button="true"
                                    apply-value-mode="useButtons"
                                    v-model:value="v$.regionIDs.$model"
                                    @valueChanged="onRegionChange"
                                    :disabled="readOnly"
                                    :search-enabled="true"
                                />
                                <rq-validation-feedback>Region is required</rq-validation-feedback>
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group form-required" :class="{'has-error' : v$.data.rate.state.$error}">
                                <label for="drp_state">State</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_state', id: 'drp_state' }"
                                    :items="usStates"
                                    value-expr="id"
                                    display-expr="id"
                                    :disabled="readOnly"
                                    v-model="v$.data.rate.state.$model"
                                    @change="onChangeState"
                                />
                                <rq-validation-feedback>State is required</rq-validation-feedback>
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="tb_county">County</label>
                                <dx-tag-box
                                    automation_id="tb_counties"
                                    ref="tb_counties"
                                    class="form-control"
                                    :data-source="stateCounties"
                                    display-expr="name"
                                    value-expr="id"
                                    :search-enabled="true"
                                    :show-selection-controls="true"
                                    :show-clear-button="true"
                                    :max-displayed-tags="1"
                                    :show-drop-down-button="true"
                                    apply-value-mode="useButtons"
                                    :disabled="readOnly"
                                    v-model:value="data.countyIDs"
                                />
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="tb_underwriter">Underwriter</label>
                                <dx-tag-box
                                    automation_id="tb_underwriter"
                                    ref="tb_underwriter"
                                    class="form-control"
                                    :data-source="underwriters"
                                    display-expr="name"
                                    value-expr="id"
                                    :show-selection-controls="true"
                                    :show-clear-button="true"
                                    :max-displayed-tags="0"
                                    :show-drop-down-button="true"
                                    apply-value-mode="useButtons"
                                    v-model:value="data.underwriterIDs"
                                    :disabled="readOnly"
                                    :search-enabled="true"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_loan_account_code">Loan Account Code</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_loan_account_code', id: 'drp_loan_account_code' }"
                                    :items="loanAccountingCodes"
                                    value-expr="id"
                                    display-expr="name"
                                    :disabled="readOnly"
                                    v-model="data.rate.loan_AccountingCode"
                                />
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_owner_account_code">Owner Account Code</label>
                                 <dx-select-box
                                    :input-attr="{ automation_id: 'drp_owner_account_code', id: 'drp_owner_account_code' }"
                                    :items="ownerAccountingCodes"
                                    value-expr="id"
                                    display-expr="name"
                                    :disabled="readOnly"
                                    v-model="data.rate.owner_AccountingCode"
                                />
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="txt_rate_rule_reference">Rate Rule Reference</label>
                                <input automation_id="txt_rate_rule_reference" v-model="data.rate.rateRuleReference" type="text" class="form-control" placeholder="Rate Rule Reference" maxlength="50" :disabled="readOnly">
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group form-required" :class="{'has-error' : v$.data.rate.code.$error}">
                                <label for="txt_code">Premium Code</label>
                                <input automation_id="txt_code" v-model="v$.data.rate.code.$model" type="text" class="form-control" placeholder="Premium Code" maxlength="20" :disabled="readOnly">
                                <rq-validation-feedback>Premium Code is required</rq-validation-feedback>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="txt_policy_code">Policy Code</label>
                                <input automation_id="txt_policy_code" v-model="data.rate.policyCode" type="text" class="form-control" placeholder="Policy Code" maxlength="50" :disabled="readOnly">
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="txt_report_code">Report Code</label>
                                <input automation_id="txt_report_code" v-model="data.rate.reportCode" type="text" class="form-control" placeholder="Report Code" maxlength="50" :disabled="readOnly">
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="txt_filing_code">Filing Code</label>
                                <input automation_id="txt_filing_code" v-model="data.rate.filingCode" type="text" class="form-control" placeholder="Filing Code" maxlength="50" :disabled="readOnly">
                            </div>
                            <div class="col col-auto ms-auto form-group mt-3 small">
                                Import Date: {{getDate("calcImportDate")}}<br/>Last Modified By: {{data.rate.lastModifiedBy}}<br/>Last Modified On: {{getDate("calcLastModified")}}
                            </div>
                        </div>
                    </fieldset>
                </rq-page-section>
            </template>
            <template #coverageRounding>
                <rq-page-section headerSize="md" borderless>
                    <template #header-actions v-if="hasLinkedPremium">
                        <ul class="nav" >
                            <li class="nav-item form-group">
                                <b-form-checkbox
                                    automation_id="chk_is_linked_coverage_rounding"
                                    id="chk_is_linked_coverage_rounding"
                                    v-show="hasLinkedPremium"
                                    :disabled="readOnly"
                                    v-model="data.rateEffectiveDate.isLinked_CoverageRounding"
                                    >Use Linked Premiums Rate</b-form-checkbox>
                            </li>
                        </ul>
                    </template>
                    <div class="row">
                        <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                            <label for="drp_coverage_rounding_method">Rounding Method</label>
                            <dx-select-box
                                :input-attr="{ automation_id: 'drp_coverage_rounding_method', id: 'drp_coverage_rounding_method' }"
                                :items="coverageRoundingMethods"
                                value-expr="id"
                                display-expr="name"
                                :disabled="readOnly || useLinkedPremiumForCoverage"
                                v-model="data.rateEffectiveDate.coverageRoundingMethodID"
                            />
                        </div>
                    </div>
                    <!-- Custom Coverage Rounding -->
                    <rqdx-action-data-grid
                        v-if="data.rateEffectiveDate.coverageRoundingMethodID === 4 && !useLinkedPremiumForCoverage"
                        ref="coverageRoundingDataGrid"
                        :automation_id="elementName('tbl', coverageRoundingName)"
                        :actions="coverageRoundingSelectionActions"
                        :config="coverageRoundingGridConfig"
                        :data-source="coverageRoundingGridDataSource"
                        :export-file-name="elementName('', coverageRoundingName, 'data')"
                        :rq-editable="!readOnly"
                        v-model:validation-errors="coverageRoundingValidationErrors"
                        @delete="onDeleteCoverageRounding"
                        :persist-state="false"
                        focus-after-insert="new-row"
                        hide-search
                        hide-show-column-chooser>
                        <template #toolbar>
                            <ul class="nav">
                                <li class="nav-item">
                                    <b-btn
                                        automation_id="btn_add_coverage_rounding"
                                        variant="theme"
                                        :disabled="readOnly"
                                        @click="onAddGridItem($event, 'CoverageRounding')">Add
                                    </b-btn>
                                </li>
                            </ul>
                        </template>
                    </rqdx-action-data-grid>
                </rq-page-section>
            </template>
            <template #titleInsurance>
                <rq-page-section headerSize="md" borderless>
                    <template #header-actions>
                        <ul v-if="!data.rateEffectiveDate.isLinked_TitleInsurance" class="nav me-auto">
                            <li class="nav-item">
                                <b-btn
                                    automation_id="btn_add_title_ins_rate"
                                    variant="theme"
                                    :disabled="readOnly"
                                    @click="onAddGridItem($event, 'TitleInsuranceRate')">Add
                                </b-btn>
                            </li>
                            <li class="nav-item">
                                <b-btn
                                    automation_id="btn_import_title_ins_rate"
                                    variant="theme"
                                    :disabled="readOnly"
                                    @click="onTitleInsuranceRateImportClick">Import
                                </b-btn>
                                <form enctype="multipart/form-data" novalidate ref="titleInsuranceRateFileForm">
                                    <input v-show="false"
                                        id="txt_title_insurance_rate_file"
                                        ref="titleInsuranceRateFile"
                                        type="file"
                                        automation_id="txt_title_insurance_rate_file"
                                        @change="onTitleInsuranceRateImport"
                                        accept=".csv"
                                    >
                                </form>
                            </li>
                            <li class="nav-item">
                                <b-btn
                                    automation_id="btn_export_title_ins_rate"
                                    variant="theme"
                                    :disabled="readOnly"
                                    @click="onTitleInsuranceRateExportClick">Export
                                </b-btn>
                            </li>
                            <li class="nav-item form-group">
                                <b-form-checkbox
                                    automation_id="chk_is_using_gross_calculation"
                                    id="chk_is_using_gross_calculation"
                                    :disabled="readOnly"
                                    v-model="data.rateEffectiveDate.isUsingGrossCalculation"
                                    @change="onIsUsingGrossCalculationChange"
                                    >Use Gross Calculation Method</b-form-checkbox>
                            </li>
                            <li class="nav-item form-group" v-if="!hasLinkedPremium">
                                <b-form-checkbox
                                    automation_id="chk_is_using_excess_calculation"
                                    id="chk_is_using_excess_calculation"
                                    :disabled="readOnly"
                                    v-model="data.rateEffectiveDate.isUsingExcessCalculation"
                                    >Use Excess Calculation Method</b-form-checkbox>
                            </li>
                            <li class="nav-item form-group" v-if="!hasLinkedPremium && data.rateEffectiveDate.isUsingExcessCalculation">
                                <premium-picker
                                    automation_id="drp_excess_premium_rate_id"
                                    dialog-title="Premium Rates"
                                    :disabled="readOnly"
                                    v-model="data.rateEffectiveDate.excessPremiumRateID"
                                />
                            </li>
                        </ul>
                        <ul v-if="hasLinkedPremium" class="nav ms-auto grid-tools-offset" >
                            <li class="nav-item form-group">
                                <b-form-checkbox
                                    automation_id="chk_is_linked_title_insurance"
                                    :disabled="readOnly"
                                    v-model="data.rateEffectiveDate.isLinked_TitleInsurance"
                                    >Use Linked Premium</b-form-checkbox>
                            </li>
                        </ul>
                    </template>

                    <rqdx-action-data-grid
                        v-if="!data.rateEffectiveDate.isLinked_TitleInsurance"
                        ref="titleInsuranceRateDataGrid"
                        :automation_id="elementName('tbl', titleInsuranceName)"
                        :actions="titleInsuranceSelectionActions"
                        :config="titleInsuranceGridConfig"
                        :data-source="titleInsuranceGridDataSource"
                        v-model:validation-errors="titleInsuranceValidationErrors"
                        :export-file-name="elementName('', titleInsuranceName, 'data')"
                        focus-after-insert="new-row"
                        class="mb-3"
                        @delete="onDeleteTitleInsurance"
                        :persist-state="false"
                        hide-search
                        hide-show-column-chooser
                        rq-editable
                    />

                    <fieldset :disabled="readOnly">
                        <div class="row" v-show="data.rateEffectiveDate.isLinked_TitleInsurance">
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_plus_minus_tir_percent">+/- Percent</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_plus_minus_tir_percent', id: 'drp_plus_minus_tir_percent' }"
                                    :items="plusMinusOptions"
                                    value-expr="id"
                                    display-expr="name"
                                    :disabled="readOnly"
                                    v-model="data.rateEffectiveDate.tiR_PCT"
                                />
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="txt_tir_adjustment_pct">Percent</label>
                                <div class="input-group">
                                    <rqInputNumber
                                        v-model="data.rateEffectiveDate.tiR_AdjustmnetPCT"
                                        defaultValue="0"
                                        decimals="2"
                                        minValue="0"
                                        maxValue="100"
                                        cssClass="form-control"
                                        automation_id="txt_tir_adjustment_pct">
                                    </rqInputNumber>
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_plus_minus_tir_amount">+/- Amount</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_plus_minus_tir_amount', id: 'drp_plus_minus_tir_amount' }"
                                    :items="plusMinusOptions"
                                    value-expr="id"
                                    display-expr="name"
                                    :disabled="readOnly"
                                    v-model="data.rateEffectiveDate.tiR_Amount"
                                />
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="txt_tir_adjustment_amount">Amount</label>
                                <div class="input-group">
                                    <span class="input-group-text">$</span>
                                    <rqInputNumber
                                        v-model="data.rateEffectiveDate.tiR_AdjustmentAmount"
                                        defaultValue="0"
                                        decimals="2"
                                        cssClass="form-control"
                                        automation_id="txt_tir_adjustment_amount">
                                    </rqInputNumber>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" :class="{'has-error' : v$.data.rateEffectiveDate.maximumCharge.isMaxGreaterThanMin.$invalid }">
                                <label for="txt_maximum_charge">Maximum Charge</label>
                                <div class="input-group">
                                    <span class="input-group-text">$</span>
                                    <rqInputNumber
                                        v-model="data.rateEffectiveDate.maximumCharge"
                                        defaultValue="0"
                                        decimals="2"
                                        cssClass="form-control"
                                        automation_id="txt_maximum_charge"
                                    />
                                </div>
                                <rq-validation-feedback>
                                        Maximum charge cannot be less than minimum charge
                                </rq-validation-feedback>
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="txt_minimum_charge">Minimum Charge</label>
                                <div class="input-group">
                                    <span class="input-group-text">$</span>
                                    <rqInputNumber
                                        v-model="data.rateEffectiveDate.minimumCharge"
                                        defaultValue="0"
                                        decimals="2"
                                        cssClass="form-control"
                                        automation_id="txt_minimum_charge">
                                    </rqInputNumber>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </rq-page-section>
            </template>
            <template #reIssueCredit>
                <rq-page-section borderless scrollable>
                    <rq-section-card
                        :title="sections.reissue.labels[0]"
                        :section-group="sections.reissue.group"
                        collapsible>
                            <ul class="nav" >
                                <li class="nav-item form-group" v-if="hasLinkedPremium">
                                    <b-form-checkbox
                                        automation_id="chk_is_linked_reissue_credit"
                                        :disabled="readOnly"
                                        v-model="data.rateEffectiveDate.isLinked_ReissueCredit"
                                        >Use Linked Premium</b-form-checkbox>
                                </li>
                                <li class="nav-item form-group">
                                    <b-form-checkbox
                                        automation_id="chk_do_not_use_prior_policy_amount"
                                        :disabled="readOnly"
                                        class="ms-2"
                                        v-model="data.rateEffectiveDate.reissueCredit_DoNotUsePriorPolicyAmount"
                                        >Do Not Use Prior Policy Amount</b-form-checkbox>
                                </li>
                                <li class="nav-item form-group" v-if="data.rate.state == 'FL'">
                                    <b-form-checkbox
                                        automation_id="chk_use_reissue_excess_uw_split"
                                        id="chk_use_reissue_excess_uw_split"
                                        class="ms-2"
                                        :disabled="readOnly"
                                        v-model="data.rateEffectiveDate.useReissueExcessUWSplit"
                                        @change="onUseReissueExcessUWSplitChange"
                                        >Use Re-Issue Excess UW Split</b-form-checkbox>
                                </li>
                            </ul>
                        <fieldset :disabled="readOnly">
                            <div class="row">
                                <div class="col col-12 col-md-6 col-lg-3 form-group">
                                    <label for="drp_reissue_credit_calculation_method_id">Calculate By</label>
                                    <dx-select-box
                                        :input-attr="{ automation_id: 'drp_reissue_credit_calculation_method_id', id: 'drp_reissue_credit_calculation_method_id', ref: 'drp_reissue_credit_calculation_method_id'  }"
                                        :items="reissueCreditCalculationMethods"
                                        value-expr="id"
                                        display-expr="name"
                                        :disabled="readOnly"
                                        v-model="data.rateEffectiveDate.reissueCreditCalculationMethodID"
                                    />
                                </div>
                                <div class="col col-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_min_premium_after_ric">Min Premium After Re-Issue Credit</label>
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <rqInputNumber
                                            v-model="data.rateEffectiveDate.minPremiumAfterRIC"
                                            defaultValue="0"
                                            decimals="2"
                                            cssClass="form-control"
                                            automation_id="txt_min_premium_after_ric">
                                        </rqInputNumber>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-show="!data.rateEffectiveDate.isLinked_ReissueCredit">
                                <div class="col">
                                    <rqdx-action-data-grid
                                        ref="reissueCreditDataGrid"
                                        :automation_id="elementName('tbl', reissueCreditName)"
                                        :actions="reissueCreditSelectionActions"
                                        :config="reissueCreditGridConfig"
                                        :data-source="reissueCreditGridDataSource"
                                        :export-file-name="elementName('', reissueCreditName, 'data')"
                                        v-model:validation-errors="reissueCreditValidationErrors"
                                        focus-after-insert="new-row"
                                        :rq-editable="!readOnly"
                                        @delete="onDeleteReissueCredit"
                                        :persist-state="false"
                                        hide-search
                                        hide-show-column-chooser>
                                        <template #toolbar>
                                            <ul class="nav">
                                                <li class="nav-item">
                                                    <b-btn
                                                        automation_id="btn_add_reissue_credit"
                                                        variant="theme"
                                                        :disabled="readOnly"
                                                        @click="onAddGridItem($event, 'ReissueCredit')">Add
                                                    </b-btn>
                                                </li>
                                                <li class="nav-item">
                                                    <b-btn
                                                        automation_id="btn_reissue_copy_liability_ranges"
                                                        variant="theme"
                                                        :disabled="readOnly"
                                                        @click="onCopyReissueCreditsFromRate">Copy Liability Ranges from Rate
                                                    </b-btn>
                                                </li>
                                            </ul>
                                        </template>
                                    </rqdx-action-data-grid>
                                </div>
                            </div>
                            <div class="row" v-show="data.rateEffectiveDate.isLinked_ReissueCredit">
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="drp_plus_minus_reissue_credit_percent">+/- Percent</label>
                                    <dx-select-box
                                        :input-attr="{ automation_id: 'drp_plus_minus_reissue_credit_percent', id: 'drp_plus_minus_reissue_credit_percent' }"
                                        :items="plusMinusOptions"
                                        value-expr="id"
                                        display-expr="name"
                                        :disabled="readOnly"
                                        v-model="data.rateEffectiveDate.reissueCredit_PCT"
                                    />
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-show="data.rateEffectiveDate.reissueCredit_PCT === 1">
                                    <label for="txt_reissue_credit_increase_adjustment_pct">Percent</label>
                                    <div class="input-group">
                                        <rqInputNumber
                                            v-model="data.rateEffectiveDate.reissueCredit_IncreaseAdjustmentPCT"
                                            defaultValue="0"
                                            decimals="2"
                                            minValue="0"
                                            maxValue="100"
                                            cssClass="form-control"
                                            automation_id="txt_reissue_credit_increase_adjustment_pct">
                                        </rqInputNumber>
                                        <span class="input-group-text">%</span>
                                    </div>
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-show="data.rateEffectiveDate.reissueCredit_PCT === -1">
                                    <label for="txt_reissue_credit_adjustment_pct">Percent</label>
                                    <div class="input-group">
                                        <rqInputNumber
                                            v-model="data.rateEffectiveDate.reissueCredit_AdjustmnetPCT"
                                            defaultValue="0"
                                            decimals="2"
                                            minValue="0"
                                            maxValue="100"
                                            cssClass="form-control"
                                            v-show="data.rateEffectiveDate.reissueCredit_PCT == -1"
                                            automation_id="txt_reissue_credit_adjustment_pct">
                                        </rqInputNumber>
                                        <span class="input-group-text">%</span>
                                    </div>
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="drp_plus_minus_reissue_credit_amount">+/- Amount</label>
                                    <dx-select-box
                                        :input-attr="{ automation_id: 'drp_plus_minus_reissue_credit_amount', id: 'drp_plus_minus_reissue_credit_amount' }"
                                        :items="plusMinusOptions"
                                        value-expr="id"
                                        display-expr="name"
                                        :disabled="readOnly"
                                        v-model="data.rateEffectiveDate.reissueCredit_Amount"
                                    />
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-show="data.rateEffectiveDate.reissueCredit_Amount === 1">
                                    <label for="txt_reissue_credit_increase_adjustment_amount">Amount</label>
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <rqInputNumber
                                            v-model="data.rateEffectiveDate.reissueCredit_IncreaseAdjustmentAmount"
                                            defaultValue="0"
                                            decimals="2"
                                            cssClass="form-control"
                                            automation_id="txt_reissue_credit_increase_adjustment_amount">
                                        </rqInputNumber>
                                    </div>
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-show="data.rateEffectiveDate.reissueCredit_Amount === -1">
                                    <label for="txt_reissue_credit_adjustment_amount">Amount</label>
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <rqInputNumber
                                            v-model="data.rateEffectiveDate.reissueCredit_AdjustmentAmount"
                                            defaultValue="0"
                                            decimals="2"
                                            cssClass="form-control"
                                            automation_id="txt_reissue_credit_adjustment_amount">
                                        </rqInputNumber>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </rq-section-card>
                    <rq-section-card
                        :title="sections.reissue.labels[1]"
                        :section-group="sections.reissue.group"
                        collapsible>
                        <ul class="nav ms-auto" v-show="hasLinkedPremium">
                            <li class="nav-item form-group">
                                <b-form-checkbox
                                    automation_id="chk_is_linked_coverage_rounding"
                                    :disabled="readOnly"
                                    v-model="data.rateEffectiveDate.isLinked_CoverageRoundingReissue"
                                    >Use Linked Premiums Rate</b-form-checkbox>
                            </li>
                        </ul>
                        <fieldset :disabled="readOnly">
                            <div class="row" v-if="!data.rateEffectiveDate.isLinked_CoverageRoundingReissue">
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="drp_coverage_rounding_reissue_method_id">Rounding Method</label>
                                    <dx-select-box
                                        :input-attr="{ automation_id: 'drp_coverage_rounding_reissue_method_id', id: 'drp_coverage_rounding_reissue_method_id' }"
                                        :items="coverageRoundingMethods"
                                        value-expr="id"
                                        display-expr="name"
                                        :disabled="readOnly"
                                        v-model="data.rateEffectiveDate.coverageRoundingReissueMethodID"
                                    />
                                </div>
                            </div>
                            <!-- Custom Coverage Rounding Reissue-->
                            <div class="row" v-if="data.rateEffectiveDate.coverageRoundingReissueMethodID == 4 && !data.rateEffectiveDate.isLinked_CoverageRoundingReissue">
                                <div class="col">
                                    <rqdx-action-data-grid
                                        ref="coverageRoundingReissueDataGrid"
                                        :automation_id="elementName('tbl', coverageRoundingReissueName)"
                                        :actions="coverageRoundingReissueSelectionActions"
                                        :config="coverageRoundingReissueGridConfig"
                                        :data-source="coverageRoundingReissueGridDataSource"
                                        :export-file-name="elementName('', coverageRoundingReissueName, 'data')"
                                        v-model:validation-errors="coverageRoundingReissueValidationErrors"
                                        @delete="onDeleteCoverageRoundingReissue"
                                        focus-after-insert="new-row"
                                        hide-search
                                        hide-show-column-chooser
                                        :rq-editable="!readOnly"
                                    >
                                    <template #toolbar>
                                        <ul class="nav">
                                            <li class="nav-item">
                                                <button
                                                    type="button"
                                                    class="btn btn-theme me-1"
                                                    variant="theme"
                                                    :disabled="readOnly"
                                                    @click="onAddGridItem($event, 'CoverageRoundingReissue')">
                                                    Add
                                                </button>
                                            </li>
                                        </ul>
                                    </template>
                                    </rqdx-action-data-grid>
                                </div>
                            </div>
                        </fieldset>
                    </rq-section-card>
                    <rq-section-card
                        :title="sections.reissue.labels[2]"
                        :section-group="sections.reissue.group"
                        collapsible>
                        <ul class="nav ms-auto" v-show="hasLinkedPremium">
                            <li class="nav-item form-group">
                                <b-form-checkbox
                                    automation_id="chk_is_linked_premium_rounding_reissue"
                                    :disabled="readOnly"
                                    v-model="data.rateEffectiveDate.isLinked_PremiumRoundingReissue"
                                    >Use Linked Premiums Rate</b-form-checkbox>
                            </li>
                        </ul>
                        <fieldset :disabled="readOnly" class="mb-4">
                            <div class="row" v-if="!data.rateEffectiveDate.isLinked_PremiumRoundingReissue">
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="drp_premium_rounding_reissue_method_id">Rounding Method</label>
                                    <dx-select-box
                                        :disabled="readOnly"
                                        :input-attr="{ automation_id: 'drp_premium_rounding_reissue_method_id', id: 'drp_premium_rounding_reissue_method_id' }"
                                        :items="roundingMethods"
                                        value-expr="id"
                                        display-expr="name"
                                        v-model="data.rateEffectiveDate.premiumRoundingReissueMethodID"
                                    />
                                </div>
                            </div>
                            <!-- Custom Premium Rounding Reissue-->
                            <div class="row" v-if="data.rateEffectiveDate.premiumRoundingReissueMethodID == 4 && !data.rateEffectiveDate.isLinked_PremiumRoundingReissue">
                                <div class="col">
                                    <rqdx-action-data-grid
                                        ref="premiumRoundingReissueDataGrid"
                                        :automation_id="elementName('tbl', premiumRoundingReissueName)"
                                        :actions="premiumRoundingReissueSelectionActions"
                                        :config="premiumRoundingReissueGridConfig"
                                        :data-source="premiumRoundingReissueGridDataSource"
                                        :export-file-name="elementName('', premiumRoundingReissueName, 'data')"
                                        v-model:validation-errors="premiumRoundingReissueValidationErrors"
                                        @delete="onDeletePremiumRoundingReissue"
                                        focus-after-insert="new-row"
                                        hide-search
                                        hide-show-column-chooser
                                        :rq-editable="!readOnly"
                                    >
                                    <template #toolbar>
                                         <ul class="nav">
                                            <li class="nav-item">
                                                <button
                                                    type="button"
                                                    class="btn btn-theme me-1"
                                                    variant="theme"
                                                    :disabled="readOnly"
                                                    @click="onAddGridItem($event, 'PremiumRoundingReissue')">
                                                    Add
                                                </button>
                                            </li>
                                        </ul>
                                    </template>
                                    </rqdx-action-data-grid>
                                </div>
                            </div>
                        </fieldset>
                    </rq-section-card>
                </rq-page-section>
            </template>
            <template #simultaneous>
                <rq-page-section headerSize="md" borderless>
                    <div class="row">
                        <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                            <label for="drp_cdf_rate_id">CDF Premium</label>
                            <premium-picker
                                automation_id="drp_cdf_rate_id"
                                dialog-title="Premium Rates"
                                :disabled="readOnly"
                                v-model="data.simultaneous.cdfRateID"
                            />
                        </div>
                    </div>
                    <rq-section-card
                        :title="sections.simultaneous.labels[0]"
                        :section-group="sections.simultaneous.group"
                        collapsible>
                        <fieldset :disabled="readOnly">
                            <div class="row">
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="drp_sales_policy_id">Simultaneous Policy</label>
                                    <dx-select-box
                                        :input-attr="{ automation_id: 'drp_sales_policy_id', id: 'drp_sales_policy_id' }"
                                        :items="simultaneousPolicyTypes"
                                        value-expr="id"
                                        display-expr="name"
                                        :disabled="readOnly"
                                        v-model="data.simultaneous.salesPolicyID"
                                        @value-changed="onSalesPolicyChange"
                                    />
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-show="data.simultaneous.salesPolicyID != 9">
                                    <label for="txt_sales_policy_code">Report Code</label>
                                    <input automation_id="txt_sales_policy_code" v-model="data.simultaneous.salesPolicyCode" type="text" class="form-control" placeholder="Report Code" maxlength="50">
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group pt-4" v-show="data.simultaneous.salesPolicyID != 9">
                                    <b-form-checkbox
                                        automation_id="chk_sales_use_premium_rounding"
                                        id="chk_sales_use_premium_rounding"
                                        v-model="data.simultaneous.salesUsePremiumRounding"
                                        >Use Premium Rounding</b-form-checkbox>
                                </div>
                            </div>
                            <!-- Sales FlatFee -->
                            <div class="row" v-if="data.simultaneous.salesPolicyID == 1">
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_sales_flat_fee_amount">Flat Fee Amount</label>
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <rqInputNumber
                                            v-model="data.simultaneous.salesFlatFeeAmount"
                                            defaultValue="0"
                                            decimals="2"
                                            cssClass="form-control"
                                            automation_id="txt_sales_flat_fee_amount">
                                        </rqInputNumber>
                                    </div>
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3">
                                    <rq-action-form-group
                                        action-class="overridden"
                                        label="Flat Fee UW Split Override"
                                        labelFor="txt_sales_flat_fee_uw_split_percent_1"
                                        :show-action="data.simultaneous.salesFlatFeeUWSplitOverride"
                                    >
                                        <template #default>
                                            <rqInputNumber
                                                id="txt_sales_flat_fee_uw_split_percent_1"
                                                automation_id="txt_sales_flat_fee_uw_split_percent_1"
                                                v-model="data.simultaneous.salesFlatFeeUWSplitPercent"
                                                defaultValue="0"
                                                decimals="2"
                                                minValue="0"
                                                maxValue="100"
                                                cssClass="form-control"
                                                :disabled="!data.simultaneous.salesFlatFeeUWSplitOverride"
                                                input-group
                                                append-text="%"
                                            />
                                        </template>
                                    </rq-action-form-group>
                                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group pt-4" v-show="data.simultaneous.salesPolicyID != 9"></div>
                                </div>
                                <div class="col col-sm-12 col-lg-6 col-xl-3 form-group pt-label">
                                    <b-form-checkbox
                                        automation_id="chk_sales_split_uw_override_1"
                                        id="chk_sales_split_uw_override_1"
                                        @click="onSalesFlatFeeUnderwriterSplitOverrideClicked"
                                        v-model="data.simultaneous.salesFlatFeeUWSplitOverride"
                                        >Use Flat Fee UW Split Override</b-form-checkbox>
                                </div>
                            </div>
                            <!-- Sales FlatFeePlusExcess -->
                            <div class="row" v-if="data.simultaneous.salesPolicyID == 2">
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_sales_flat_fee_excess_amount">Flat Fee Amount</label>
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <rqInputNumber
                                            v-model="data.simultaneous.salesFlatExcessAmount"
                                            defaultValue="0"
                                            decimals="2"
                                            cssClass="form-control"
                                            automation_id="txt_sales_flat_fee_excess_amount">
                                        </rqInputNumber>
                                    </div>
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3">
                                    <rq-action-form-group
                                        action-class="overridden"
                                        label="Flat Fee UW Split Override"
                                        labelFor="txt_sales_flat_fee_uw_split_percent_2"
                                        :show-action="data.simultaneous.salesFlatFeeUWSplitOverride"
                                    >
                                        <template #default>
                                            <rqInputNumber
                                                id="txt_sales_flat_fee_uw_split_percent_2"
                                                automation_id="txt_sales_flat_fee_uw_split_percent_2"
                                                v-model="data.simultaneous.salesFlatFeeUWSplitPercent"
                                                defaultValue="0"
                                                decimals="2"
                                                minValue="0"
                                                maxValue="100"
                                                cssClass="form-control"
                                                :disabled="!data.simultaneous.salesFlatFeeUWSplitOverride"
                                                input-group
                                                append-text="%"
                                            />
                                        </template>
                                    </rq-action-form-group>
                                </div>
                                <div class="col col-sm-12 col-lg-6 col-xl-3 form-group pt-label">
                                    <b-form-checkbox
                                        automation_id="chk_sales_split_uw_override_2"
                                        id="chk_sales_split_uw_override_2"
                                        @click="onSalesFlatFeeUnderwriterSplitOverrideClicked"
                                        v-model="data.simultaneous.salesFlatFeeUWSplitOverride"
                                        >Use Flat Fee UW Split Override</b-form-checkbox>
                                </div>
                            </div>
                            <div class="row" v-if="data.simultaneous.salesPolicyID == 2">
                                <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                                    <label for="drp_sales_flat_excess_policy">Excess Premium</label>
                                   <premium-picker
                                        automation_id="drp_sales_flat_excess_policy"
                                        dialog-title="Premium Rates"
                                        :disabled="readOnly"
                                        v-model="data.simultaneous.salesFlatExcessPolicy"
                                    />
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="drp_sales_flat_excess_calc_method_id">Excess Method</label>
                                    <dx-select-box
                                        :input-attr="{ automation_id: 'drp_sales_flat_excess_calc_method_id', id: 'drp_sales_flat_excess_calc_method_id' }"
                                        automation_id="drp_sales_flat_excess_calc_method_id"
                                        :items="excessMethods"
                                        value-expr="id"
                                        display-expr="name"
                                        v-model="data.simultaneous.salesFlatExcessCalcMethodID"
                                    />
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-show="data.simultaneous.salesFlatExcessCalcMethodID === 1">
                                    <label for="txt_sales_flat_excess_calc_pct_diff">Excess % Diff</label>
                                    <div class="input-group">
                                        <rqInputNumber
                                            v-model="data.simultaneous.salesFlatExcessCalcPctDiff"
                                            defaultValue="0"
                                            decimals="2"
                                            minValue="0"
                                            cssClass="form-control"
                                            automation_id="txt_sales_flat_excess_calc_pct_diff">
                                        </rqInputNumber>
                                        <span class="input-group-text">%</span>
                                    </div>
                                </div>
                            </div>
                            <!-- Sales Percentage -->
                            <div class="row" v-if="data.simultaneous.salesPolicyID == 4">
                                <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                                    <label for="drp_sales_percentage_pct_policy">Percent Premium</label>
                                    <premium-picker
                                        automation_id="drp_sales_percentage_pct_policy"
                                        dialog-title="Premium Rates"
                                        :disabled="readOnly"
                                        v-model="data.simultaneous.salesPercentagePctPolicy"
                                    />
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_sales_percentage_pct">Percent</label>
                                    <div class="input-group">
                                        <rqInputNumber
                                            v-model="data.simultaneous.salesPercentagePct"
                                            defaultValue="0"
                                            decimals="2"
                                            minValue="0"
                                            maxValue="100"
                                            cssClass="form-control"
                                            automation_id="txt_sales_percentage_pct">
                                        </rqInputNumber>
                                        <span class="input-group-text">%</span>
                                    </div>
                                </div>
                            </div>
                            <!-- Sales PercentagePlusExcess -->
                            <div class="row" v-if="data.simultaneous.salesPolicyID == 6">
                                <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                                    <label for="drp_sales_percentage_excess_pct_policy">Percent Premium</label>
                                   <premium-picker
                                        automation_id="drp_sales_percentage_excess_pct_policy"
                                        dialog-title="Premium Rates"
                                        :disabled="readOnly"
                                        v-model="data.simultaneous.salesPercentageExcessPctPolicy"
                                    />
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_sales_percentage_excess_pct">Percent</label>
                                    <div class="input-group">
                                        <rqInputNumber
                                            v-model="data.simultaneous.salesPercentageExcessPct"
                                            defaultValue="0"
                                            decimals="2"
                                            minValue="0"
                                            maxValue="100"
                                            cssClass="form-control"
                                            automation_id="txt_sales_percentage_excess_pct">
                                        </rqInputNumber>
                                        <span class="input-group-text">%</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="data.simultaneous.salesPolicyID == 6">
                                <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                                    <label for="drp_sales_percentage_excess_policy">Excess Premium</label>
                                    <premium-picker
                                        automation_id="drp_sales_percentage_excess_policy"
                                        dialog-title="Premium Rates"
                                        :disabled="readOnly"
                                        v-model="data.simultaneous.salesPercentageExcessPolicy"
                                    />
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="drp_sales_percentage_excess_calc_method_id">Excess Method</label>
                                    <dx-select-box
                                        :input-attr="{ automation_id: 'drp_sales_percentage_excess_calc_method_id', id: 'drp_sales_percentage_excess_calc_method_id' }"
                                        :items="excessMethods"
                                        value-expr="id"
                                        display-expr="name"
                                        v-model="data.simultaneous.salesPercentageExcessCalcMethodID"
                                    />
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-show="data.simultaneous.salesPercentageExcessCalcMethodID === 1">
                                    <label for="txt_sales_percentage_excess_calc_pct_diff">Excess % Diff</label>
                                    <div class="input-group">
                                        <rqInputNumber
                                            v-model="data.simultaneous.salesPercentageExcessCalcPctDiff"
                                            defaultValue="0"
                                            decimals="2"
                                            minValue="0"
                                            cssClass="form-control"
                                            automation_id="txt_sales_percentage_excess_calc_pct_diff">
                                        </rqInputNumber>
                                        <span class="input-group-text">%</span>
                                    </div>
                                </div>
                            </div>
                            <!-- Sales FlatFeePlusPercentage -->
                            <div class="row" v-if="data.simultaneous.salesPolicyID == 7">
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_sales_flat_fee_percentage_amount">Flat Fee Amount</label>
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <rqInputNumber
                                            v-model="data.simultaneous.salesFlatFeePercentageAmount"
                                            defaultValue="0"
                                            decimals="2"
                                            cssClass="form-control"
                                            automation_id="txt_sales_flat_fee_percentage_amount">
                                        </rqInputNumber>
                                    </div>
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3">
                                   <rq-action-form-group
                                        action-class="overridden"
                                        label="Flat Fee UW Split Override"
                                        labelFor="txt_sales_flat_fee_uw_split_percent_7"
                                        :show-action="data.simultaneous.salesFlatFeeUWSplitOverride"
                                    >
                                        <template #default>
                                            <rqInputNumber
                                                id="txt_sales_flat_fee_uw_split_percent_7"
                                                automation_id="txt_sales_flat_fee_uw_split_percent_7"
                                                v-model="data.simultaneous.salesFlatFeeUWSplitPercent"
                                                defaultValue="0"
                                                decimals="2"
                                                minValue="0"
                                                maxValue="100"
                                                cssClass="form-control"
                                                :disabled="!data.simultaneous.salesFlatFeeUWSplitOverride"
                                                input-group
                                                append-text="%"
                                            />
                                        </template>
                                    </rq-action-form-group>
                                </div>
                                <div class="col col-sm-12 col-lg-6 col-xl-3 form-group pt-label">
                                    <b-form-checkbox
                                        automation_id="chk_sales_split_uw_override_3"
                                        id="chk_sales_split_uw_override_3"
                                        @click="onSalesFlatFeeUnderwriterSplitOverrideClicked"
                                        v-model="data.simultaneous.salesFlatFeeUWSplitOverride"
                                        >Use Flat Fee UW Split Override</b-form-checkbox>
                                </div>
                            </div>
                            <div class="row" v-if="data.simultaneous.salesPolicyID == 7">
                                <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                                    <label for="drp_sales_flat_fee_percentage_pct_policy">Percent Premium</label>
                                    <premium-picker
                                        automation_id="drp_sales_flat_fee_percentage_pct_policy"
                                        dialog-title="Premium Rates"
                                        :disabled="readOnly"
                                        v-model="data.simultaneous.salesFlatFeePercentagePctPolicy"
                                    />
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_sales_flat_fee_percentage_pct">Percent</label>
                                    <div class="input-group">
                                        <rqInputNumber
                                            v-model="data.simultaneous.salesFlatFeePercentagePct"
                                            defaultValue="0"
                                            decimals="2"
                                            minValue="0"
                                            maxValue="100"
                                            cssClass="form-control"
                                            automation_id="txt_sales_flat_fee_percentage_pct">
                                        </rqInputNumber>
                                        <span class="input-group-text">%</span>
                                    </div>
                                </div>
                            </div>
                            <!-- Sales FlatFeePlusPercentagePlusExcess -->
                            <div class="row" v-if="data.simultaneous.salesPolicyID == 8">
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_sales_flat_fee_percentage_excess_amount">Flat Fee Amount</label>
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <rqInputNumber
                                            v-model="data.simultaneous.salesFlatFeePercentageExcessAmount"
                                            defaultValue="0"
                                            decimals="2"
                                            cssClass="form-control"
                                            automation_id="txt_sales_flat_fee_percentage_excess_amount">
                                        </rqInputNumber>
                                    </div>
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3">
                                    <rq-action-form-group
                                        action-class="overridden"
                                        label="Flat Fee UW Split Override"
                                        labelFor="txt_sales_flat_fee_uw_split_percent_8"
                                        :show-action="data.simultaneous.salesFlatFeeUWSplitOverride"
                                    >
                                        <template #default>
                                            <rqInputNumber
                                                id="txt_sales_flat_fee_uw_split_percent_8"
                                                automation_id="txt_sales_flat_fee_uw_split_percent_8"
                                                v-model="data.simultaneous.salesFlatFeeUWSplitPercent"
                                                defaultValue="0"
                                                decimals="2"
                                                minValue="0"
                                                maxValue="100"
                                                cssClass="form-control"
                                                :disabled="!data.simultaneous.salesFlatFeeUWSplitOverride"
                                                input-group
                                                append-text="%"
                                            />
                                        </template>
                                    </rq-action-form-group>
                                </div>
                                <div class="col col-sm-12 col-lg-6 col-xl-3 form-group pt-label">
                                    <b-form-checkbox
                                        automation_id="chk_sales_split_uw_override_4"
                                        id="chk_sales_split_uw_override_4"
                                        @click="onSalesFlatFeeUnderwriterSplitOverrideClicked"
                                        v-model="data.simultaneous.salesFlatFeeUWSplitOverride"
                                        >Use Flat Fee UW Split Override</b-form-checkbox>
                                </div>
                            </div>
                            <div class="row" v-if="data.simultaneous.salesPolicyID == 8">
                                <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                                    <label for="drp_sales_flat_fee_percentage_excess_pct_policy">Percent Premium</label>
                                   <premium-picker
                                        automation_id="drp_sales_flat_fee_percentage_excess_pct_policy"
                                        dialog-title="Premium Rates"
                                        :disabled="readOnly"
                                        v-model="data.simultaneous.salesFlatFeePercentageExcessPctPolicy"
                                    />
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_sales_flat_fee_percentage_excess_pct">Percent</label>
                                    <div class="input-group">
                                        <rqInputNumber
                                            v-model="data.simultaneous.salesFlatFeePercentageExcessPct"
                                            defaultValue="0"
                                            decimals="2"
                                            minValue="0"
                                            maxValue="100"
                                            cssClass="form-control"
                                            automation_id="txt_sales_flat_fee_percentage_excess_pct">
                                        </rqInputNumber>
                                        <span class="input-group-text">%</span>
                                    </div>
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="drp_sales_flat_fee_percentage_excess_based_on">Based On</label>
                                    <dx-select-box
                                        :input-attr="{ automation_id: 'drp_sales_flat_fee_percentage_excess_based_on', id: 'drp_sales_flat_fee_percentage_excess_based_on' }"
                                        :items="premiumPercentageExcessBasedOnOptions"
                                        value-expr="id"
                                        display-expr="name"
                                        v-model="data.simultaneous.salesFlatFeePercentageExcessBasedOn"
                                    />
                                </div>
                            </div>
                            <div class="row" v-if="data.simultaneous.salesPolicyID == 8">
                                <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                                    <label for="drp_sales_flat_fee_percentage_excess_policy">Excess Premium</label>
                                    <premium-picker
                                        automation_id="drp_sales_flat_fee_percentage_excess_policy"
                                        dialog-title="Premium Rates"
                                        :disabled="readOnly"
                                        v-model="data.simultaneous.salesFlatFeePercentageExcessPolicy"
                                    />
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="drp_sales_flat_percentage_excess_calc_method_id">Excess Method</label>
                                    <dx-select-box
                                        :input-attr="{ automation_id: 'drp_sales_flat_percentage_excess_calc_method_id', id: 'drp_sales_flat_percentage_excess_calc_method_id' }"
                                        :items="excessMethods"
                                        :disabled="readOnly"
                                        value-expr="id"
                                        display-expr="name"
                                        v-model="data.simultaneous.salesFlatFeePercentageExcessCalcMethodID"
                                    />
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-show="data.simultaneous.salesFlatFeePercentageExcessCalcMethodID === 1">
                                    <label for="txt_sales_flat_percentage_excess_calc_pct_diff">Excess % Diff</label>
                                    <div class="input-group">
                                        <rqInputNumber
                                            v-model="data.simultaneous.salesFlatFeePercentageExcessCalcPctDiff"
                                            defaultValue="0"
                                            decimals="2"
                                            minValue="0"
                                            cssClass="form-control"
                                            automation_id="txt_sales_flat_percentage_excess_calc_pct_diff">
                                        </rqInputNumber>
                                        <span class="input-group-text">%</span>
                                    </div>
                                </div>
                            </div>
                            <!-- Sales ConcurrentPremium -->
                            <div class="row" v-if="data.simultaneous.salesPolicyID == 9">
                                <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                                    <label for="drp_sales_concurrent_policy">Concurrent Premium</label>
                                    <premium-picker
                                        automation_id="drp_sales_concurrent_policy"
                                        dialog-title="Premium Rates"
                                        :disabled="readOnly"
                                        v-model="data.simultaneous.salesConcurrentPolicy"
                                    />
                                </div>
                            </div>
                            <!-- Sales FlatFeePlusDifference -->
                            <div class="row" v-if="data.simultaneous.salesPolicyID == 10">
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_sales_flat_fee_difference_amount">Flat Fee Amount</label>
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <rqInputNumber
                                            v-model="data.simultaneous.salesFlatFeeDifferenceAmount"
                                            defaultValue="0"
                                            decimals="2"
                                            cssClass="form-control"
                                            automation_id="txt_sales_flat_fee_difference_amount">
                                        </rqInputNumber>
                                    </div>
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_sales_flat_fee_difference_owners_min">Owner's Minimum</label>
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <rqInputNumber
                                            v-model="data.simultaneous.salesFlatFeeDifferenceOwnersMin"
                                            defaultValue="0"
                                            decimals="2"
                                            cssClass="form-control"
                                            automation_id="txt_sales_flat_fee_difference_owners_min">
                                        </rqInputNumber>
                                    </div>
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3">
                                    <rq-action-form-group
                                        action-class="overridden"
                                        label="Flat Fee UW Split Override"
                                        labelFor="txt_sales_flat_fee_uw_split_percent_10"
                                        :show-action="data.simultaneous.salesFlatFeeUWSplitOverride"
                                    >
                                        <template #default>
                                            <rqInputNumber
                                                id="txt_sales_flat_fee_uw_split_percent_10"
                                                automation_id="txt_sales_flat_fee_uw_split_percent_10"
                                                v-model="data.simultaneous.salesFlatFeeUWSplitPercent"
                                                defaultValue="0"
                                                decimals="2"
                                                minValue="0"
                                                maxValue="100"
                                                cssClass="form-control"
                                                :disabled="!data.simultaneous.salesFlatFeeUWSplitOverride"
                                                input-group
                                                append-text="%"
                                            />
                                        </template>
                                    </rq-action-form-group>
                                </div>
                                <div class="col col-sm-12 col-lg-6 col-xl-3 form-group pt-label">
                                    <b-form-checkbox
                                        automation_id="chk_sales_split_uw_override_5"
                                        id="chk_sales_split_uw_override_5"
                                        @click="onSalesFlatFeeUnderwriterSplitOverrideClicked"
                                        v-model="data.simultaneous.salesFlatFeeUWSplitOverride"
                                        >Use Flat Fee UW Split Override</b-form-checkbox>
                                </div>
                            </div>

                            <div class="row pt-4" v-if="data.simultaneous.salesPolicyID == 2 || data.simultaneous.salesPolicyID == 8 || data.simultaneous.salesPolicyID == 6">
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <b-form-checkbox
                                        automation_id="chk_sales_use_excess_premium_uw_split"
                                        id="chk_sales_use_excess_premium_uw_split"
                                        v-model="data.simultaneous.salesUseExcessPremiumUWSplit"
                                        >Use Excess Premium UW Split</b-form-checkbox>
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <b-form-checkbox
                                        automation_id="chk_sales_round_before_excess_calculation"
                                        id="chk_sales_round_before_excess_calculation"
                                        v-model="data.simultaneous.salesRoundBeforeExcessCalc"
                                        >Round before Excess Calculation</b-form-checkbox>
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <b-form-checkbox
                                        automation_id="chk_sales_round_after_calculation"
                                        id="chk_sales_round_after_calculation"
                                        v-model="data.simultaneous.salesRoundingAfterCalc"
                                        >Round after Calculation</b-form-checkbox>
                                </div>
                            </div>
                        </fieldset>
                    </rq-section-card>
                    <rq-section-card
                        :title="sections.simultaneous.labels[1]"
                        :section-group="sections.simultaneous.group"
                        collapsible>
                        <fieldset :disabled="readOnly">
                            <div class="row">
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="drp_loan_policy_id">Simultaneous Policy</label>
                                    <dx-select-box
                                        :input-attr="{ automation_id: 'drp_loan_policy_id', id: 'drp_loan_policy_id' }"
                                        :items="simultaneousPolicyTypes"
                                        value-expr="id"
                                        display-expr="name"
                                        :disabled="readOnly"
                                        v-model="data.simultaneous.loanPolicyID"
                                        @value-changed="onLoanPolicyChange"
                                    />
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-show="data.simultaneous.loanPolicyID != 9">
                                    <label for="txt_loan_policy_code">Report Code</label>
                                    <input automation_id="txt_loan_policy_code" v-model="data.simultaneous.loanPolicyCode" type="text" class="form-control" placeholder="Report Code" maxlength="50">
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group pt-4" v-show="data.simultaneous.loanPolicyID != 9">
                                    <b-form-checkbox
                                        automation_id="chk_loan_use_premium_rounding"
                                        id="chk_loan_use_premium_rounding"
                                        v-model="data.simultaneous.loanUsePremiumRounding"
                                        >Use Premium Rounding</b-form-checkbox>
                                </div>
                            </div>
                            <!-- Sales FlatFee -->
                            <div class="row" v-if="data.simultaneous.loanPolicyID == 1">
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_loan_flat_fee_amount">Flat Fee Amount</label>
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <rqInputNumber
                                            v-model="data.simultaneous.loanFlatFeeAmount"
                                            defaultValue="0"
                                            decimals="2"
                                            cssClass="form-control"
                                            automation_id="txt_loan_flat_fee_amount">
                                        </rqInputNumber>
                                    </div>
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3">
                                    <rq-action-form-group
                                        action-class="overridden"
                                        label="Flat Fee UW Split Override"
                                        labelFor="txt_loan_flat_fee_uw_split_percent_1"
                                        :show-action="data.simultaneous.loanFlatFeeUWSplitOverride"
                                    >
                                        <template #default>
                                            <rqInputNumber
                                                id="txt_loan_flat_fee_uw_split_percent_1"
                                                automation_id="txt_loan_flat_fee_uw_split_percent_1"
                                                v-model="data.simultaneous.loanFlatFeeUWSplitPercent"
                                                defaultValue="0"
                                                decimals="2"
                                                minValue="0"
                                                maxValue="100"
                                                cssClass="form-control"
                                                :disabled="!data.simultaneous.loanFlatFeeUWSplitOverride"
                                                input-group
                                                append-text="%"
                                            />
                                        </template>
                                    </rq-action-form-group>
                                </div>
                                <div class="col col-sm-12 col-lg-6 col-xl-3 form-group pt-label">
                                    <b-form-checkbox
                                        automation_id="chk_loan_split_uw_override_1"
                                        id="chk_loan_split_uw_override_1"
                                        @click="onLoanFlatFeeUnderwriterSplitOverrideClicked"
                                        v-model="data.simultaneous.loanFlatFeeUWSplitOverride"
                                        >Use Flat Fee UW Split Override</b-form-checkbox>
                                </div>
                            </div>
                            <!-- Sales FlatFeePlusExcess -->
                            <div class="row" v-if="data.simultaneous.loanPolicyID == 2">
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_loan_flat_fee_excess_amount">Flat Fee Amount</label>
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <rqInputNumber
                                            v-model="data.simultaneous.loanFlatExcessAmount"
                                            defaultValue="0"
                                            decimals="2"
                                            cssClass="form-control"
                                            automation_id="txt_loan_flat_fee_excess_amount">
                                        </rqInputNumber>
                                    </div>
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3">
                                    <rq-action-form-group
                                        action-class="overridden"
                                        label="Flat Fee UW Split Override"
                                        labelFor="txt_loan_flat_fee_uw_split_percent_2"
                                        :show-action="data.simultaneous.loanFlatFeeUWSplitOverride"
                                    >
                                        <template #default>
                                            <rqInputNumber
                                                id="txt_loan_flat_fee_uw_split_percent_2"
                                                automation_id="txt_loan_flat_fee_uw_split_percent_2"
                                                v-model="data.simultaneous.loanFlatFeeUWSplitPercent"
                                                defaultValue="0"
                                                decimals="2"
                                                minValue="0"
                                                maxValue="100"
                                                cssClass="form-control"
                                                :disabled="!data.simultaneous.loanFlatFeeUWSplitOverride"
                                                input-group
                                                append-text="%"
                                            />
                                        </template>
                                    </rq-action-form-group>
                                </div>
                                <div class="col col-sm-12 col-lg-6 col-xl-3 form-group pt-label">
                                    <b-form-checkbox
                                        automation_id="chk_loan_split_uw_override_2"
                                        id="chk_loan_split_uw_override_2"
                                        @click="onLoanFlatFeeUnderwriterSplitOverrideClicked"
                                        v-model="data.simultaneous.loanFlatFeeUWSplitOverride"
                                        >Use Flat Fee UW Split Override</b-form-checkbox>
                                </div>
                            </div>
                            <div class="row" v-if="data.simultaneous.loanPolicyID == 2">
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_loan_flat_excess_minimum_amount">Minimum Amount</label>
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <rqInputNumber
                                            v-model="data.simultaneous.loanFlatExcessMinimumAmount"
                                            defaultValue="0"
                                            decimals="2"
                                            cssClass="form-control"
                                            automation_id="txt_loan_flat_excess_minimum_amount">
                                        </rqInputNumber>
                                    </div>
                                </div>
                                <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                                    <label for="drp_loan_flat_excess_policy">Excess Premium</label>
                                    <premium-picker
                                        automation_id="drp_loan_flat_excess_policy"
                                        dialog-title="Premium Rates"
                                        :disabled="readOnly"
                                        v-model="data.simultaneous.loanFlatExcessPolicy"
                                    />
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="drp_loan_flat_excess_calc_method_id">Excess Method</label>
                                    <dx-select-box
                                        :input-attr="{ automation_id: 'drp_loan_flat_excess_calc_method_id', id: 'drp_loan_flat_excess_calc_method_id' }"
                                        :items="excessMethods"
                                        value-expr="id"
                                        display-expr="name"
                                        v-model="data.simultaneous.loanFlatExcessCalcMethodID"
                                    />
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-show="data.simultaneous.loanFlatExcessCalcMethodID === 1">
                                    <label for="txt_loan_flat_excess_calc_pct_diff">Excess % Diff</label>
                                    <div class="input-group">
                                        <rqInputNumber
                                            v-model="data.simultaneous.loanFlatExcessCalcPctDiff"
                                            defaultValue="0"
                                            decimals="2"
                                            minValue="0"
                                            cssClass="form-control"
                                            automation_id="txt_loan_flat_excess_calc_pct_diff">
                                        </rqInputNumber>
                                        <span class="input-group-text">%</span>
                                    </div>
                                </div>
                            </div>
                            <!-- Sales Percentage -->
                            <div class="row" v-if="data.simultaneous.loanPolicyID == 4">
                                <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                                    <label for="drp_loan_flat_excess_policy">Percent Premium</label>
                                   <premium-picker
                                        automation_id="drp_loan_flat_excess_policy"
                                        dialog-title="Premium Rates"
                                        :disabled="readOnly"
                                        v-model="data.simultaneous.loanPercentagePctPolicy"
                                    />
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_loan_percentage_pct">Percent</label>
                                    <div class="input-group">
                                        <rqInputNumber
                                            v-model="data.simultaneous.loanPercentagePct"
                                            defaultValue="0"
                                            decimals="2"
                                            minValue="0"
                                            maxValue="100"
                                            cssClass="form-control"
                                            automation_id="txt_loan_percentage_pct">
                                        </rqInputNumber>
                                        <span class="input-group-text">%</span>
                                    </div>
                                </div>
                            </div>
                            <!-- Sales PercentagePlusExcess -->
                            <div class="row" v-if="data.simultaneous.loanPolicyID == 6">
                                <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                                    <label for="drp_loan_percentage_excess_pct_policy">Percent Premium</label>
                                    <premium-picker
                                        automation_id="drp_loan_percentage_excess_pct_policy"
                                        dialog-title="Premium Rates"
                                        :disabled="readOnly"
                                        v-model="data.simultaneous.loanPercentageExcessPctPolicy"
                                    />
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_loan_percentage_excess_pct">Percent</label>
                                    <div class="input-group">
                                        <rqInputNumber
                                            v-model="data.simultaneous.loanPercentageExcessPct"
                                            defaultValue="0"
                                            decimals="2"
                                            minValue="0"
                                            maxValue="100"
                                            cssClass="form-control"
                                            automation_id="txt_loan_percentage_excess_pct">
                                        </rqInputNumber>
                                        <span class="input-group-text">%</span>
                                    </div>
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_loan_percentage_excess_minimum_amount">Minimum Amount</label>
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <rqInputNumber
                                            v-model="data.simultaneous.loanPercentageExcessMinimumAmount"
                                            defaultValue="0"
                                            decimals="2"
                                            cssClass="form-control"
                                            automation_id="txt_loan_percentage_excess_minimum_amount">
                                        </rqInputNumber>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="data.simultaneous.loanPolicyID == 6">
                                <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                                    <label for="drp_loan_percentage_excess_policy">Excess Premium</label>
                                    <premium-picker
                                        automation_id="drp_loan_percentage_excess_policy"
                                        dialog-title="Premium Rates"
                                        :disabled="readOnly"
                                        v-model="data.simultaneous.loanPercentageExcessPolicy"
                                    />
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="drp_loan_percentage_excess_calc_method_id">Excess Method</label>
                                    <dx-select-box
                                        :input-attr="{ automation_id: 'drp_loan_percentage_excess_calc_method_id', id: 'drp_loan_percentage_excess_calc_method_id' }"
                                        :items="excessMethods"
                                        value-expr="id"
                                        display-expr="name"
                                        v-model="data.simultaneous.loanPercentageExcessCalcMethodID"
                                    />
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-show="data.simultaneous.loanPercentageExcessCalcMethodID === 1">
                                    <label for="txt_loan_percentage_excess_calc_pct_diff">Excess % Diff</label>
                                    <div class="input-group">
                                        <rqInputNumber
                                            v-model="data.simultaneous.loanPercentageExcessCalcPctDiff"
                                            defaultValue="0"
                                            decimals="2"
                                            minValue="0"
                                            cssClass="form-control"
                                            automation_id="txt_loan_percentage_excess_calc_pct_diff">
                                        </rqInputNumber>
                                        <span class="input-group-text">%</span>
                                    </div>
                                </div>
                            </div>
                            <!-- Sales FlatFeePlusPercentage -->
                            <div class="row" v-if="data.simultaneous.loanPolicyID == 7">
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_loan_flat_fee_percentage_amount">Flat Fee Amount</label>
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <rqInputNumber
                                            v-model="data.simultaneous.loanFlatFeePercentageAmount"
                                            defaultValue="0"
                                            decimals="2"
                                            cssClass="form-control"
                                            automation_id="txt_loan_flat_fee_percentage_amount">
                                        </rqInputNumber>
                                    </div>
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3">
                                    <rq-action-form-group
                                        action-class="overridden"
                                        label="Flat Fee UW Split Override"
                                        labelFor="txt_loan_flat_fee_uw_split_percent_7"
                                        :show-action="data.simultaneous.loanFlatFeeUWSplitOverride"
                                    >
                                        <template #default>
                                            <rqInputNumber
                                                id="txt_loan_flat_fee_uw_split_percent_7"
                                                automation_id="txt_loan_flat_fee_uw_split_percent_7"
                                                v-model="data.simultaneous.loanFlatFeeUWSplitPercent"
                                                defaultValue="0"
                                                decimals="2"
                                                minValue="0"
                                                maxValue="100"
                                                cssClass="form-control"
                                                :disabled="!data.simultaneous.loanFlatFeeUWSplitOverride"
                                                input-group
                                                append-text="%"
                                            />
                                        </template>
                                    </rq-action-form-group>
                                </div>
                                <div class="col col-sm-12 col-lg-6 col-xl-3 form-group pt-label">
                                    <b-form-checkbox
                                        automation_id="chk_loan_split_uw_override_3"
                                        id="chk_loan_split_uw_override_3"
                                        @click="onLoanFlatFeeUnderwriterSplitOverrideClicked"
                                        v-model="data.simultaneous.loanFlatFeeUWSplitOverride"
                                        >Use Flat Fee UW Split Override</b-form-checkbox>
                                </div>
                            </div>
                            <div class="row" v-if="data.simultaneous.loanPolicyID == 7">
                                <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                                    <label for="drp_loan_flat_fee_percentage_pct_policy">Percent Premium</label>
                                    <premium-picker
                                        automation_id="drp_loan_flat_fee_percentage_pct_policy"
                                        dialog-title="Premium Rates"
                                        :disabled="readOnly"
                                        v-model="data.simultaneous.loanFlatFeePercentagePctPolicy"
                                    />
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_loan_flat_fee_percentage_pct">Percent</label>
                                    <div class="input-group">
                                        <rqInputNumber
                                            v-model="data.simultaneous.loanFlatFeePercentagePct"
                                            defaultValue="0"
                                            decimals="2"
                                            minValue="0"
                                            maxValue="100"
                                            cssClass="form-control"
                                            automation_id="txt_loan_flat_fee_percentage_pct">
                                        </rqInputNumber>
                                        <span class="input-group-text">%</span>
                                    </div>
                                </div>
                            </div>
                            <!-- Sales FlatFeePlusPercentagePlusExcess -->
                            <div class="row" v-if="data.simultaneous.loanPolicyID == 8">
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_loan_flat_fee_percentage_excess_amount">Flat Fee Amount</label>
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <rqInputNumber
                                            v-model="data.simultaneous.loanFlatFeePercentageExcessAmount"
                                            defaultValue="0"
                                            decimals="2"
                                            cssClass="form-control"
                                            automation_id="txt_loan_flat_fee_percentage_excess_amount">
                                        </rqInputNumber>
                                    </div>
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3">
                                    <rq-action-form-group
                                        action-class="overridden"
                                        label="Flat Fee UW Split Override"
                                        labelFor="txt_loan_flat_fee_uw_split_percent_8"
                                        :show-action="data.simultaneous.loanFlatFeeUWSplitOverride"
                                    >
                                        <template #default>
                                            <rqInputNumber
                                                id="txt_loan_flat_fee_uw_split_percent_8"
                                                automation_id="txt_loan_flat_fee_uw_split_percent_8"
                                                v-model="data.simultaneous.loanFlatFeeUWSplitPercent"
                                                defaultValue="0"
                                                decimals="2"
                                                minValue="0"
                                                maxValue="100"
                                                cssClass="form-control"
                                                :disabled="!data.simultaneous.loanFlatFeeUWSplitOverride"
                                                input-group
                                                append-text="%"
                                            />
                                        </template>
                                    </rq-action-form-group>
                                </div>
                                <div class="col col-sm-12 col-lg-6 col-xl-3 form-group pt-label">
                                    <b-form-checkbox
                                        automation_id="chk_loan_split_uw_override_4"
                                        id="chk_loan_split_uw_override_4"
                                        v-model="data.simultaneous.loanFlatFeeUWSplitOverride"
                                        @click="onLoanFlatFeeUnderwriterSplitOverrideClicked"
                                        >Use Flat Fee UW Split Override</b-form-checkbox>
                                </div>
                            </div>
                            <div class="row" v-if="data.simultaneous.loanPolicyID == 8">
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_loan_flat_fee_percentage_excess_minimum_amount">Minimum Amount</label>
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <rqInputNumber
                                            v-model="data.simultaneous.loanFlatFeePercentageExcessMinimumAmount"
                                            defaultValue="0"
                                            decimals="2"
                                            cssClass="form-control"
                                            automation_id="txt_loan_flat_fee_percentage_excess_minimum_amount">
                                        </rqInputNumber>
                                    </div>
                                </div>
                                <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                                    <label for="drp_loan_flat_fee_percentage_excess_pct_policy">Percent Premium</label>
                                    <premium-picker
                                        automation_id="drp_loan_flat_fee_percentage_excess_pct_policy"
                                        dialog-title="Premium Rates"
                                        :disabled="readOnly"
                                        v-model="data.simultaneous.loanFlatFeePercentageExcessPctPolicy"
                                    />
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_loan_flat_fee_percentage_excess_pct">Percent</label>
                                    <div class="input-group">
                                        <rqInputNumber
                                            v-model="data.simultaneous.loanFlatFeePercentageExcessPct"
                                            defaultValue="0"
                                            decimals="2"
                                            minValue="0"
                                            maxValue="100"
                                            cssClass="form-control"
                                            automation_id="txt_loan_flat_fee_percentage_excess_pct">
                                        </rqInputNumber>
                                        <span class="input-group-text">%</span>
                                    </div>
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="drp_loan_flat_fee_percentage_excess_based_on">Based On</label>
                                    <dx-select-box
                                        :input-attr="{ automation_id: 'drp_loan_flat_fee_percentage_excess_based_on', id: 'drp_loan_flat_fee_percentage_excess_based_on' }"
                                        :items="premiumPercentageExcessBasedOnOptions"
                                        value-expr="id"
                                        display-expr="name"
                                        v-model="data.simultaneous.loanFlatFeePercentageExcessBasedOn"
                                    />
                                </div>
                            </div>
                            <div class="row" v-if="data.simultaneous.loanPolicyID == 8">
                                <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                                    <label for="drp_loan_flat_fee_percentage_excess_policy">Excess Premium</label>
                                    <premium-picker
                                        automation_id="drp_loan_flat_fee_percentage_excess_policy"
                                        dialog-title="Premium Rates"
                                        :disabled="readOnly"
                                        v-model="data.simultaneous.loanFlatFeePercentageExcessPolicy"
                                    />

                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="drp_loan_flat_percentage_excess_calc_method_id">Excess Method</label>
                                    <dx-select-box
                                        :input-attr="{ automation_id: 'drp_loan_flat_percentage_excess_calc_method_id', id: 'drp_loan_flat_percentage_excess_calc_method_id' }"
                                        :items="excessMethods"
                                        value-expr="id"
                                        display-expr="name"
                                        v-model="data.simultaneous.loanFlatFeePercentageExcessCalcMethodID"
                                    />
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-show="data.simultaneous.loanFlatFeePercentageExcessCalcMethodID === 1">
                                    <label for="txt_loan_flat_percentage_excess_calc_pct_diff">Excess % Diff</label>
                                    <div class="input-group">
                                        <rqInputNumber
                                            v-model="data.simultaneous.loanFlatFeePercentageExcessCalcPctDiff"
                                            defaultValue="0"
                                            decimals="2"
                                            minValue="0"
                                            cssClass="form-control"
                                            automation_id="txt_loan_flat_percentage_excess_calc_pct_diff">
                                        </rqInputNumber>
                                        <span class="input-group-text">%</span>
                                    </div>
                                </div>
                            </div>
                            <!-- Sales ConcurrentPremium -->
                            <div class="row" v-if="data.simultaneous.loanPolicyID == 9">
                                <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                                    <label for="drp_loan_concurrent_policy">Concurrent Premium</label>
                                    <premium-picker
                                        automation_id="drp_loan_concurrent_policy"
                                        dialog-title="Premium Rates"
                                        :disabled="readOnly"
                                        v-model="data.simultaneous.loanConcurrentPolicy"
                                    />
                                </div>
                            </div>
                            <!-- Sales FlatFeePlusDifference -->
                            <div class="row" v-if="data.simultaneous.loanPolicyID == 10">
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_loan_flat_fee_difference_amount">Flat Fee Amount</label>
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <rqInputNumber
                                            v-model="data.simultaneous.loanFlatFeeDifferenceAmount"
                                            defaultValue="0"
                                            decimals="2"
                                            cssClass="form-control"
                                            automation_id="txt_loan_flat_fee_difference_amount">
                                        </rqInputNumber>
                                    </div>
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_loan_flat_fee_difference_owners_min">Owner's Minimum</label>
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <rqInputNumber
                                            v-model="data.simultaneous.loanFlatFeeDifferenceOwnersMin"
                                            defaultValue="0"
                                            decimals="2"
                                            cssClass="form-control"
                                            automation_id="txt_loan_flat_fee_difference_owners_min">
                                        </rqInputNumber>
                                    </div>
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3">
                                    <rq-action-form-group
                                        action-class="overridden"
                                        label="Flat Fee UW Split Override"
                                        labelFor="txt_loan_flat_fee_uw_split_percent_10"
                                        :show-action="data.simultaneous.loanFlatFeeUWSplitOverride"
                                    >
                                        <template #default>
                                            <rqInputNumber
                                                id="txt_loan_flat_fee_uw_split_percent_10"
                                                automation_id="txt_loan_flat_fee_uw_split_percent_10"
                                                v-model="data.simultaneous.loanFlatFeeUWSplitPercent"
                                                defaultValue="0"
                                                decimals="2"
                                                minValue="0"
                                                maxValue="100"
                                                cssClass="form-control"
                                                :disabled="!data.simultaneous.loanFlatFeeUWSplitOverride"
                                                input-group
                                                append-text="%"
                                            />
                                        </template>
                                    </rq-action-form-group>
                                </div>
                                <div class="col col-sm-12 col-lg-6 col-xl-3 form-group pt-label">
                                    <b-form-checkbox
                                        automation_id="chk_loan_split_uw_override_5"
                                        id="chk_loan_split_uw_override_5"
                                        @click="onLoanFlatFeeUnderwriterSplitOverrideClicked"
                                        v-model="data.simultaneous.loanFlatFeeUWSplitOverride"
                                        >Use Flat Fee UW Split Override</b-form-checkbox>
                                </div>
                            </div>
                            <div class="row pt-4" v-if="data.simultaneous.loanPolicyID == 2 || data.simultaneous.loanPolicyID == 8 || data.simultaneous.loanPolicyID == 6">
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <b-form-checkbox
                                        automation_id="chk_loan_use_excess_premium_uw_split"
                                        id="chk_loan_use_excess_premium_uw_split"
                                        v-model="data.simultaneous.loanUseExcessPremiumUWSplit"
                                        >Use Excess Premium UW Split</b-form-checkbox>
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <b-form-checkbox
                                        automation_id="chk_loan_rounc_before_excess_calc"
                                        id="chk_loan_rounc_before_excess_calc"
                                        v-model="data.simultaneous.loanRoundBeforeExcessCalc"
                                        >Round before Excess Calculation</b-form-checkbox>
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <b-form-checkbox
                                        automation_id="chk_loan_round_after_calc"
                                        id="chk_loan_round_after_calc"
                                        v-model="data.simultaneous.loanRoundingAfterCalc"
                                        >Round after Calculation</b-form-checkbox>
                                </div>
                            </div>
                        </fieldset>
                    </rq-section-card>
                </rq-page-section>
            </template>
            <template #premiumRounding>
                <rq-page-section headerSize="md" borderless>
                    <template #header-actions>
                        <ul v-if="hasLinkedPremium" class="nav">
                            <li class="nav-item form-group">
                                <b-form-checkbox
                                    automation_id="chk_is_linked_premium_rounding"
                                    v-show="hasLinkedPremium"
                                    :disabled="readOnly"
                                    v-model="data.rateEffectiveDate.isLinked_PremiumRounding"
                                    >Use Linked Premiums Rate</b-form-checkbox>
                            </li>
                        </ul>
                    </template>
                    <div class="row">
                        <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                            <label for="drp_premium_rounding_method">Rounding Method</label>
                            <dx-select-box
                                :input-attr="{ automation_id: 'drp_premium_rounding_method', id: 'drp_premium_rounding_method' }"
                                value-expr="id"
                                display-expr="name"
                                :items="roundingMethods"
                                :search-enabled="true"
                                :disabled="readOnly || useLinkedPremiumForPremRounding"
                                v-model="data.rateEffectiveDate.premiumRoundingMethodID"
                            />
                        </div>
                    </div>

                    <!-- Custom Premium Rounding -->
                    <rqdx-action-data-grid
                        v-if="data.rateEffectiveDate.premiumRoundingMethodID === 4 && !useLinkedPremiumForPremRounding"
                        ref="premiumRoundingDataGrid"
                        :automation_id="elementName('tbl', premiumRoundingName)"
                        :actions="premiumRoundingSelectionActions"
                        :config="premiumRoundingGridConfig"
                        :data-source="premiumRoundingGridDataSource"
                        v-model:validation-errors="premiumRoundingValidationErrors"
                        :export-file-name="elementName('', premiumRoundingName, 'data')"
                        :rq-editable="!readOnly"
                        focus-after-insert="new-row"
                        @delete="onDeletePremiumRounding"
                        hide-show-column-chooser
                        hide-search>
                        <template #toolbar>
                            <ul class="nav">
                                <li class="nav-item">
                                    <b-btn
                                        automation_id="btn_add_premium_rounding"
                                        variant="theme"
                                        :disabled="readOnly"
                                        @click="onAddGridItem($event, 'PremiumRounding')">Add
                                    </b-btn>
                                </li>
                            </ul>
                        </template>
                    </rqdx-action-data-grid>
                </rq-page-section>
            </template>
            <template #splitCalculation>
                <rq-page-section headerSize="md" borderless>
                    <template #header-actions>
                        <ul v-if="hasLinkedPremium" class="nav" >
                            <li class="nav-item form-group">
                                <b-form-checkbox
                                    automation_id="chk_is_linked_split_calculation"
                                    id="chk_is_linked_split_calculation"
                                    v-show="hasLinkedPremium"
                                    :disabled="readOnly"
                                    v-model="data.rateEffectiveDate.isLinked_SplitCalculation"
                                    >Use Linked Premiums Rate</b-form-checkbox>
                            </li>
                        </ul>
                    </template>
                    <fieldset :disabled="readOnly">
                        <div class="row" v-if="!data.rateEffectiveDate.isLinked_SplitCalculation">
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_calculation_basis">Calculation Basis</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_calculation_basis', id: 'drp_calculation_basis' }"
                                    :items="effectiveRateCalculationBasisOptions"
                                    value-expr="id"
                                    display-expr="name"
                                    :disabled="readOnly"
                                    v-model="data.rateEffectiveDate.calculationBasis"
                                    @value-changed="onCalculationBasisChange"
                                />
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group pt-4">
                                <b-form-checkbox
                                    automation_id="chk_use_underwriter_default_split"
                                    id="chk_use_underwriter_default_split"
                                    :disabled="readOnly"
                                    v-model="data.rateEffectiveDate.useUnderwriterDefaultSplit"
                                    >Use Underwriter Default Split</b-form-checkbox>
                            </div>
                        </div>
                        <div class="row" v-if="!data.rateEffectiveDate.isLinked_SplitCalculation">
                            <div class="col">
                                <rqdx-action-data-grid
                                    ref="underwriterSplitDataGrid"
                                    :automation_id="elementName('tbl', underwriterSplitName)"
                                    :actions="underwriterSplitSelectionActions"
                                    :config="underwriterSplitGridConfig"
                                    :data-source="underwriterSplitGridDataSource"
                                    v-model:validation-errors="underwriterSplitValidationErrors"
                                    :export-file-name="elementName('', underwriterSplitName, 'data')"
                                    :persist-state="false"
                                    :rq-editable="!readOnly"
                                    @delete="onDeleteUnderwriterSplit"
                                    hide-show-column-chooser
                                    focus-after-insert="new-row"
                                    hide-search>
                                    <template #toolbar>
                                        <ul class="nav">
                                            <li class="nav-item">
                                                <b-btn
                                                    automation_id="btn_add_split_calculation"
                                                    variant="theme"
                                                    :disabled="readOnly"
                                                    @click="onAddGridItem($event, 'UnderwriterSplit')">Add
                                                </b-btn>
                                            </li>
                                            <li class="nav-item">
                                                <b-btn
                                                    automation_id="btn_split_copy_liability_ranges"
                                                    variant="theme"
                                                    :disabled="readOnly"
                                                    @click="onCopyUnderwriterSplitsFromRate">Copy Liability Ranges from Rate
                                                </b-btn>
                                            </li>
                                        </ul>
                                    </template>
                                </rqdx-action-data-grid>
                            </div>
                        </div>
                        <div class="row" v-show="data.rateEffectiveDate.isLinked_SplitCalculation">
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_plus_minus_split_calc_percent">+/- Percent</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_plus_minus_split_calc_percent', id: 'drp_plus_minus_split_calc_percent' }"
                                    :items="plusMinusOptions"
                                    :defaultItemEnabled="false"
                                    v-model="data.rateEffectiveDate.splitCalc_PCT"
                                    value-expr="id "
                                    display-expr="name"
                                />
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-show="data.rateEffectiveDate.splitCalc_PCT === 1">
                                <label for="txt_split_calc_increase_adjustment_pct">Percent</label>
                                <div class="input-group">
                                    <rqInputNumber
                                        v-model="data.rateEffectiveDate.splitCalc_IncreaseAdjustmentPCT"
                                        defaultValue="0"
                                        decimals="2"
                                        minValue="0"
                                        maxValue="100"
                                        cssClass="form-control"
                                        automation_id="txt_split_calc_increase_adjustment_pct">
                                    </rqInputNumber>
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-show="data.rateEffectiveDate.splitCalc_PCT === -1">
                                <label for="txt_split_calc_adjustment_pct">Percent</label>
                                <div class="input-group">
                                    <rqInputNumber
                                        v-model="data.rateEffectiveDate.splitCalc_AdjustmnetPCT"
                                        defaultValue="0"
                                        decimals="2"
                                        minValue="0"
                                        maxValue="100"
                                        cssClass="form-control"
                                        v-show="data.rateEffectiveDate.splitCalc_PCT == -1"
                                        automation_id="txt_split_calc_adjustment_pct">
                                    </rqInputNumber>
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_plus_minus_split_calc_amount">+/- Amount</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_plus_minus_split_calc_amount', id: 'drp_plus_minus_split_calc_amount' }"
                                    :items="plusMinusOptions"
                                    :defaultItemEnabled="false"
                                    v-model="data.rateEffectiveDate.splitCalc_Amount"
                                    value-expr="id "
                                    display-expr="name"
                                />
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-show="data.rateEffectiveDate.splitCalc_Amount === 1">
                                <label for="txt_split_calc_increase_adjustment_amount">Amount</label>
                                <div class="input-group">
                                    <span class="input-group-text">$</span>
                                    <rqInputNumber
                                        v-model="data.rateEffectiveDate.splitCalc_IncreaseAdjustmentAmount"
                                        defaultValue="0"
                                        decimals="2"
                                        cssClass="form-control"
                                        automation_id="txt_split_calc_increase_adjustment_amount">
                                    </rqInputNumber>
                                </div>
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-show="data.rateEffectiveDate.splitCalc_Amount === -1">
                                <label for="txt_split_calc_adjustment_amount">Amount</label>
                                <div class="input-group">
                                    <span class="input-group-text">$</span>
                                    <rqInputNumber
                                        v-model="data.rateEffectiveDate.splitCalc_AdjustmentAmount"
                                        defaultValue="0"
                                        decimals="2"
                                        cssClass="form-control"
                                        automation_id="txt_split_calc_adjustment_amount">
                                    </rqInputNumber>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </rq-page-section>
            </template>
            <rq-back-to-top-button />
        </rq-tabs>
    </div>
</template>

<script>
    import { ref, computed } from "vue";
    import { mapGetters, mapState } from "vuex";
    import { GlobalEventManager } from "@/app.events";
    import PremiumFormAdd from "./PremiumFormAdd.vue";
    import { RateEngineReissueCreditDto }  from "@settlement/models";
    import { RateDataDto, RateEngineCoverageRoundingDto, RateEnginePremiumRoundingDto, RateEngineCoverageRoundingReissueDto, RateEnginePremiumRoundingReissueDto, RateEngineTitleInsuranceRateDto, RateEngineUnderwriterSplitDto }  from "../models";
    import { PremiumPercentageExcessBasedOn, TaxType, EffectiveDateBasedOn, RateCreateType, RateEngineFileTypeName, EffectiveRateCalculationBasis, RateEngineReissueCreditCalculationMethod, TaxRateBasedOn, TaxRateEffectiveBasis, RateSimultaneousPolicyType, ExcessMethod, CoverageRoundingMethod, RoundingMethod, RoundingRule }  from "../../enums";
    import { SystemLookupItem } from "@/shared/models/models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import PremiumPicker from './PremiumPicker';
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";
    import { dateTimeCalculated } from "@/shared/utilities/VueHelpers";
    import { required } from "validators";
    import { useVuelidate } from "@vuelidate/core";
    import { DateTime } from "luxon";
    import { useRqTabAlerts } from "@/shared/composables/useRqTabAlerts";

    // const premiumRequired = (value, vm) => _.isNullOrEmpty(value) && vm.data.rate.rateCreateType !=2;
    // const premiumRegionRequired = (value, vm) => _.size(value) == 0 && vm.data.rate.rateCreateType !=2;


    export default {
        name: 'PremiumForm',
        components: { PremiumPicker },
        setup() {
            const errLists = {
                coverageRoundingValidationErrors: ref([]),
                titleInsuranceValidationErrors: ref([]),
                reissueCreditValidationErrors: ref([]),
                coverageRoundingReissueValidationErrors: ref([]),
                premiumRoundingReissueValidationErrors: ref([]),
                premiumRoundingValidationErrors: ref([]),
                underwriterSplitValidationErrors: ref([]),
            };
            const reissueCreditTabErrorCount = computed(() => (errLists.reissueCreditValidationErrors.value?.length || 0) + (errLists.premiumRoundingReissueValidationErrors.value?.length || 0));
            const v$ = useVuelidate();
            const alertMapping = {
                generalTabAlert: {
                    type: "v$",
                    keys: [
                        "effectiveDateBasisID",
                        "rateEngineFileTypeNameID",
                        "state",
                        "code",
                        "effectiveDate",
                        "regionIDs"
                    ]
                },
                coverageRoundingTabAlert: { type: "list-ref", list: errLists.coverageRoundingValidationErrors },
                titleInsuranceTabAlert: [
                    { type: "v$", keys: ["maximumCharge"] },
                    { type: "list-ref", list: errLists.titleInsuranceValidationErrors }
                ],
                reissueCreditTabAlert: { type: "count-ref", count: reissueCreditTabErrorCount },
                premiumRoundingTabAlert: { type: "list-ref", list: errLists.premiumRoundingValidationErrors },
                underwriterSplitTabAlert: { type: "list-ref", list: errLists.underwriterSplitValidationErrors },
            };
            const tabAlerts = useRqTabAlerts({ v$, alertMapping });

            return {
                v$,
                ...errLists,
                ...tabAlerts
            };
        },
        data() {
            return {
                data: {},
                originalData: {},
                validationErrors: [],
                verifiedItem: {},
                regionIDs: [],
                underwriters: [],
                activeEffectiveRateID: 0,
                activeEffectiveRateName: "",
                //maxDecimal: 922337203685477.58, //no references; throws "no-loss-of-precision" eslint error
                sections:[],
                currentTabIndex: 0,
                isCancelled: false,
                tabsVisited: [],
                hasValidationErrors: false
            };
        },

        /*
        if (_.isNullOrEmpty(this.rateEngineFileTypeNameID) && this.rateCreateType !=2) {
                errorList.push("File Type is required.");
            }
            if (_.isNullOrEmpty(this.state) && this.rateCreateType !=2) {
                errorList.push("State is required.");
            }
            if (_.isNullOrEmpty(this.code) && this.rateCreateType !=2) {
                errorList.push("Code is required.");
            }
            if (_.size(this.regionIDs) == 0 && this.rateCreateType !=2) {
                errorList.push("Region is required.");
            }
        */
        validations: () => ({
            // data: {
            //     rate: {
            //         effectiveDateBasisID: { required },
            //         rateEngineFileTypeNameID: { premiumRequired },
            //         state: { premiumRequired },
            //         code: {
            //             function(value) {
            //                 return _.isNullOrEmpty(value) && this.data.rate.rateCreateType !=2;
            //             }
            //         }
            //     },
            //     rateEffectiveDate: {
            //         effectiveDate: { required }
            //     }
            // },
            // regionIDs: { premiumRegionRequired }
            data: {
                rate: {
                    effectiveDateBasisID: { required },
                    rateEngineFileTypeNameID: { required },
                    state: { required },
                    code: { required }
                },
                rateEffectiveDate: {
                    effectiveDate: {
                        required,
                        uniqueEffectiveDate(val) {
                            var dateString = DateTime.fromISO(val).toFormat("MM/dd/yyyy");
                            return this.activeEffectiveRateName === dateString
                                || !_.some(this.effectiveRates, i => i.name === dateString);
                        }
                    },
                    maximumCharge: {
                        isMaxGreaterThanMin() {
                            return _.getNumber(this, "data.rateEffectiveDate.maximumCharge", 0) === 0
                            || _.getNumber(this, "data.rateEffectiveDate.maximumCharge", 0) >= _.getNumber(this, "data.rateEffectiveDate.minimumCharge", 0);
                        }
                    },
                }
            },
            regionIDs: { required }
        }),
        computed: {
            ...mapState({
                readOnly: state => state.isPageReadOnly
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "premiumNames"
            ]),
            premiumName() { return `${this.getData("rate.code", "(Blank)")} - ${this.getData("rate.state", "(Blank)")} - ${this.getData("rate.extendedDescription", "(Blank)")}`; },
            editTitle() { return (!this.readOnly) ? `Edit Premium: ${this.premiumName}` : `View Premium: ${this.premiumName}`; },
            rateEffectiveDateID() { return _.parseNumber(_.get(this.data, "rate.rateEffectiveDateID", 0), 0); },
            hasRate() { return !_.isEmpty(_.get(this.data, "rate")); },
            hasPriorRates() { return _.parseNumber(_.get(this, "effectiveRates.length", 0), 0) > 0; },
            hasLinkedPremium() { return _.parseNumber(_.get(this.data, "rate.linkedPremiumRateID", 0), 0) > 0; },
            isNew() { return this.rateEffectiveDateID === 0; },
            useLinkedPremiumForCoverage(){
                return _.parseBool(_.get(this.data, "rateEffectiveDate.isLinked_CoverageRounding", 0), 0) > 0;
            },
            useLinkedPremiumForPremRounding(){
                return _.parseBool(_.get(this.data, "rateEffectiveDate.isLinked_PremiumRounding", 0), 0) > 0;
            },
            isAlta() { return _.get(this.data, "rate.state", "") != 'TX'; },
            // showRequiredErrorBanner(){ return this.validationErrors.length > 0; },
            coverageRoundingDataGrid() {
                return _.get(this, "$refs.coverageRoundingDataGrid.gridInstance", {});
            },
            coverageRoundingReissueDataGrid() {
                return _.get(this, "$refs.coverageRoundingReissueDataGrid.gridInstance", {});
            },
            titleInsuranceRateDataGrid() {
                return _.get(this, "$refs.titleInsuranceRateDataGrid.gridInstance", {});
            },
            reissueCreditDataGrid() {
                return _.get(this, "$refs.reissueCreditDataGrid.gridInstance", {});
            },
            premiumRoundingReissueDataGrid() {
                return _.get(this, "$refs.premiumRoundingReissueDataGrid.gridInstance", {});
            },
            premiumRoundingDataGrid() {
                return _.get(this, "$refs.premiumRoundingDataGrid.gridInstance", {});
            },
            //lookups
            stateCounties() { return this.lookupHelpers.getCountiesByState(_.get(this, "data.rate.state", null)); },
            loanAccountingCodes() { return this.lookupHelpers.getLookupItems(this.lookupItems.ACCOUNTING_CODES, null, _.getNumber(this, "originalData.rate.loan_AccountingCode", 0)); },
            ownerAccountingCodes() { return this.lookupHelpers.getLookupItems(this.lookupItems.ACCOUNTING_CODES, null, _.getNumber(this, "originalData.rate.owner_AccountingCode", 0)); },
            orderServices() { return this.lookupHelpers.getOrderServices(this.isAlta); },
            orderCategories() { return this.lookupHelpers.getLookupItems(this.lookupItems.ORDER_CATEGORIES); },
            originalSelectedRateValues() {
                return _.filter(_.flatMap(_.pick(this.originalData, [
                    "rate.associatedRateID",
                    "rateEffectiveDate.excessPremiumRateID",
                    "simultaneous.cdfRateID",
                    "simultaneous.salesConcurrentPolicy",
                    "simultaneous.salesFlatExcessPolicy",
                    "simultaneous.salesPercentagePctPolicy",
                    "simultaneous.salesPercentageExcessPolicy",
                    "simultaneous.salesPercentageExcessPctPolicy",
                    "simultaneous.salesFlatFeePercentagePctPolicy",
                    "simultaneous.salesFlatFeePercentageExcessPolicy",
                    "simultaneous.salesFlatFeePercentageExcessPctPolicy",
                    "simultaneous.loanConcurrentPolicy",
                    "simultaneous.loanFlatExcessPolicy",
                    "simultaneous.loanPercentagePctPolicy",
                    "simultaneous.loanPercentageExcessPolicy",
                    "simultaneous.loanPercentageExcessPctPolicy",
                    "simultaneous.loanFlatFeePercentagePctPolicy",
                    "simultaneous.loanFlatFeePercentageExcessPolicy",
                    "simultaneous.loanFlatFeePercentageExcessPctPolicy"
                ]), _.values), val => _.parseNumber(val, 0) > 0);
            },
            availablePremiumNames() {
                return _.filter(this.premiumNames, item => !item.inactive || _.includes(this.originalSelectedRateValues, item.id));
            },
            ...dateTimeCalculated({ field: { calcLastModified: 'data.rate.lastModified' }}),
            ...dateTimeCalculated({ field: { calcImportDate: 'data.rate.importDate' }}),
            tabItems() {
                return [
                    { automation_id: "btn_tab_general", name: "general", title: "General", alertCount: this.generalTabAlert.alertCount },
                    { automation_id: "btn_tab_coverageRounding", name: "coverageRounding", title: "Coverage Rounding", alertCount: this.coverageRoundingTabAlert.alertCount },
                    { automation_id: "btn_tab_titleInsuranceRate", name: "titleInsurance", title: "Title Insurance Rate", alertCount: this.titleInsuranceTabAlert.alertCount },
                    { automation_id: "btn_tab_reIssueCredit", name: "reIssueCredit", title: "Re-Issue Credit", alertCount: this.reissueCreditTabAlert.alertCount },
                    { automation_id: "btn_tab_simultaneous", name: "simultaneous", title: "Simultaneous"},
                    { automation_id: "btn_tab_premiumRounding", name: "premiumRounding", title: "Premium Rounding", alertCount: this.premiumRoundingTabAlert.alertCount },
                    { automation_id: "btn_tab_splitCalculation", name: "splitCalculation", title: "Split Calculation", alertCount: this.underwriterSplitTabAlert.alertCount }
                ];
            }
        },
        watch: {
            currentTabIndex(newValue, oldValue){
                if(newValue == oldValue) return;
                let self = this;

                self.save()
                    .then(() => {
                        if ((newValue === 1 && self.data.rateEffectiveDate.coverageRoundingMethodID !== CoverageRoundingMethod.Custom)
                        || (newValue === 5 && self.data.rateEffectiveDate.premiumRoundingMethodID !== CoverageRoundingMethod.Custom)
                        || _.includes(self.tabsVisited, newValue)) return;

                        self.$nextTick(() => {
                            switch(newValue) {
                                case 1: self.refreshGrid("CoverageRounding"); break;
                                case 2: self.refreshGrid("TitleInsuranceRate"); break;
                                case 3: self.refreshGrid("ReissueCredit"); break;
                                case 5: self.refreshGrid("PremiumRounding"); break;
                                case 6: self.refreshGrid("UnderwriterSplit"); break;
                            }
                            if(!_.includes(self.tabsVisited, newValue)) self.tabsVisited.push(newValue);
                        });
                    });
            },
            "data.rateEffectiveDate.reissueCreditCalculationMethodID": {
                handler: function (newValue, oldValue) {
                    if (newValue == oldValue) return;
                    this.onReissueCreditCalculationMethodChange(newValue);
                },
            }
        },
        created() {
            GlobalEventManager.onSave(this, this.onSave);
            let rateEffectiveDateID = _.parseNumber(_.get(this.$route, "params.rateEffectiveDateID", 0), 0);
            this.initSections();
            this.fetchData(rateEffectiveDateID);
            this.initNonReactiveVariables();
        },
        beforeRouteLeave(to, from, next) {
            const self = this;
            let changes = self.getAuditChanges(self.originalData.toDataObject(), self.data.toDataObject());
            if ((self.isCancelled || self.readOnly) || (changes.length == 0 && !self.childComponentsHaveChanges())){
                GlobalEventManager.saveCompleted({ success: true });
                next();
                return;
            } else {
                self.save({ userInitiated: false })
                    .then((isValid) => {
                        if(!isValid) return;
                        next();
                        return;
                    });
            }
        },
        beforeUnmount() {
            GlobalEventManager.unregister(this);
        },
        methods: {
            cancelChildComponentChanges(){
                const self = this;
                if(!_.isEmpty(self.coverageRoundingDataGrid)) self.coverageRoundingDataGrid.cancelEditData();
                if(!_.isEmpty(self.coverageRoundingReissueDataGrid)) self.coverageRoundingReissueDataGrid.cancelEditData();
                if(!_.isEmpty(self.titleInsuranceRateDataGrid)) self.titleInsuranceRateDataGrid.cancelEditData();
                if(!_.isEmpty(self.reissueCreditDataGrid)) self.reissueCreditDataGrid.cancelEditData();
                if(!_.isEmpty(self.premiumRoundingReissueDataGrid)) self.premiumRoundingReissueDataGrid.cancelEditData();
                if(!_.isEmpty(self.premiumRoundingDataGrid)) self.premiumRoundingDataGrid.cancelEditData();
                self.invokeGridComponentMethod("underwriterSplitDataGrid", "cancelEditData");
            },
            childComponentsHaveChanges(){
                const self = this;
                if(!_.isEmpty(self.coverageRoundingDataGrid) && self.coverageRoundingDataGrid.hasEditData()) return true;
                if(!_.isEmpty(self.coverageRoundingReissueDataGrid) && self.coverageRoundingReissueDataGrid.hasEditData()) return true;
                if(!_.isEmpty(self.titleInsuranceRateDataGrid) && self.titleInsuranceRateDataGrid.hasEditData()) return true;
                if(!_.isEmpty(self.reissueCreditDataGrid) && self.reissueCreditDataGrid.hasEditData()) return true;
                if(!_.isEmpty(self.premiumRoundingReissueDataGrid) && self.premiumRoundingReissueDataGrid.hasEditData()) return true;
                if(!_.isEmpty(self.premiumRoundingDataGrid) && self.premiumRoundingDataGrid.hasEditData()) return true;
                if(self.invokeGridMethod("underwriterSplitDataGrid", "hasEditData")) return true;
                return false;
            },
            onFieldChange (e) {
                this.$emit('change');
            },
            initSections(){
                this.sections = {
                    reissue: {
                        group: "reissue-credit-group",
                        labels: [
                            "Calculations",
                            "Coverage Rounding",
                            "Calculate Amount Rounding"
                        ]
                    },
                    simultaneous: {
                        group: "simultaneous-group",
                        labels: [
                            "When Sales Price Is Greater Than Or Equal To Loan Amount",
                            "When Sales Price Less Than Loan Amount"
                        ]
                    }
                };
            },
            initData(result) {
                const self = this;
                self.data = new RateDataDto(_.cloneDeep(result));
                self.originalData = new RateDataDto(_.cloneDeep(result));
                self.regionIDs = _.clone(self.data.regionIDs);
                self.underwriters = self.lookupHelpers.getUnderwriters(_.clone(self.data.regionIDs));
                self.effectiveRates = _.map(result.effectiveRates, i => new SystemLookupItem(i));
                self.activeEffectiveRateID = self.data.rate.rateEffectiveDateID;
                var effRate = _.find(this.effectiveRates, r => r.id === self.activeEffectiveRateID);
                self.activeEffectiveRateName = effRate?.name || "";

                self.rateEngineCoverageRoundings = _.map(result.rateEngineCoverageRoundings, i => new RateEngineCoverageRoundingDto(i)) || [];
                self.rateEnginePremiumRoundings = _.map(result.rateEnginePremiumRoundings, i => new RateEnginePremiumRoundingDto(i)) || [];
                self.rateEngineCoverageRoundingReissues = _.map(result.rateEngineCoverageRoundingReissues, i => new RateEngineCoverageRoundingReissueDto(i)) || [];
                self.rateEnginePremiumRoundingReissues = _.map(result.rateEnginePremiumRoundingReissues, i => new RateEnginePremiumRoundingReissueDto(i)) || [];
                self.rateEngineTitleInsuranceRates = _.map(result.rateEngineTitleInsuranceRates, i => new RateEngineTitleInsuranceRateDto(i)) || [];
                self.rateEngineReissueCredits = _.map(result.rateEngineReissueCredits, i => new RateEngineReissueCreditDto(i)) || [];
                self.rateEngineUnderwriterSplits = _.map(result.rateEngineUnderwriterSplits, i => new RateEngineUnderwriterSplitDto(i)) || [];
            },
            elementName(prefix="", item="", suffix="") { return _.snakeCase(`${prefix} ${item} ${suffix}`); },
            create(item, form){
                const self = this;
                let apiPromise = self.$api.RatesApi.create(item);
                self.$rqBusy.wait(apiPromise)
                    .then(data => {
                        self.$router.push({ name: 'cfg:sscalc:premium', params: { rateEffectiveDateID: data.rate.rateEffectiveDateID }});
                        self.fetchData(data.rate.rateEffectiveDateID);
                        self.$toast.success({ message: `Record for ${data.rate.code} was created.` });
                    }).catch(e => {
                        form.validationErrors = [e.errorMessage];
                    });
            },
            createTitleInsuranceRateData() {
                const self = this;
                let data = "To,Rate,Tier,Per\n";
                _.each(self.rateEngineTitleInsuranceRates, rate => {
                    data += `${rate.toAmount},${rate.rate},${rate.usePerRate ? "0" : "1"},${rate.per}\n`;
                });
                return data;
            },
            fetchData(rateEffectiveDateID) {
                const self = this;
                let apiPromise = self.$api.RatesApi.get(rateEffectiveDateID);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.initData(result);
                        //grids are predicated on rate data, so wait to define grid until the data is there.
                        self.initGridConfig();
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.rateItemNamePlural}.` });
                    });
            },
            getData(node="", ifEmpty="") {
                let info = _.get(this.data, node);
                if (_.isEmpty(info)) {
                    return ifEmpty;
                } else {
                    return info;
                }
            },
            getDate(node="") {
                let date = _.get(this, node) || null;
                return _.isNil(date) ? "" : DateTime.fromJSDate(new Date(date)).toFormat('MM/dd/yyyy, t');
            },
            getGrid(type) {
                return _.get(this.$refs, `${_.camelCase(type)}DataGrid.gridInstance`, null) || {};
            },
            getNextFromAmount(listType){
                let list = _.get(this, `rateEngine${listType}s`);
                let maxAmount = _.maxBy(list, 'toAmount');
                return maxAmount ? maxAmount.toAmount + .01 : 0;
            },
            getNextFromYear(listType){
                let list = _.get(this, `rateEngine${listType}s`);
                let maxtoYear = _.maxBy(list, 'toYears');
                return maxtoYear ? maxtoYear.toYears : 0;
            },
            getRoundingColumns(itemKey) {
                return _.clone([
                        {
                            dataField: itemKey,
                            visible: false,
                            allowEditing: false
                        },
                        {
                            dataField: "fromAmount",
                            caption: "From",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            allowEditing: false,
                            sortIndex: 0,
                            sortOrder: "asc",
                        },
                        {
                            dataField: "toAmount",
                            caption: "To",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "custom",
                                    validationCallback: this.isToAmountLessThanFromAmount,
                                    message: "To Amount must be greater than From Amount"
                                }
                            ]
                        },
                        {
                            dataField: "rateEngineRoundingRuleID",
                            caption: "Rounding",
                            dataType: "number",
                            lookup: {
                                dataSource: this.roundingRules,
                                displayExpr: "name",
                                valueExpr: "id"
                            }, validationRules: [{ type: "required" }]
                        },
                        {
                            dataField: "toNearest",
                            caption: "Nearest",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                        },
                        { type: "buttons", visible: false, showInColumnChooser: false }
                    ]);
            },
            getRoundingSelectionActions(itemName) {
                return _.clone([
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Delete ${itemName}`
                    }
                ]);
            },
            getTitleInsuranceColumns(itemKey, isUsingGrossCalculation) {
                return _.clone([
                        {
                            dataField: itemKey,
                            visible: false,
                            allowEditing: false
                        },
                        {
                            dataField: "fromAmount",
                            caption: "From",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            allowEditing: false,
                            sortIndex: 0,
                            sortOrder: "asc",
                        },
                        {
                            dataField: "toAmount",
                            caption: "To",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "custom",
                                    validationCallback: this.isToAmountLessThanFromAmount,
                                    message: "To Amount must be greater than From Amount"
                                }
                            ]
                        },
                        {
                            dataField: "rate",
                            caption: "Rate",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 5
                            },
                            editorOptions: {
                                format: "$ #,##0.#####"
                            },
                        },
                        {
                            dataField: "usePerRate",
                            caption: "Tier",
                            dataType: "boolean",
                            allowEditing: !isUsingGrossCalculation,
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            setCellValue: function(rowData, value) {
                                rowData.usePerRate = value;
                                rowData.per = 1000;
                            },
                        },
                        {
                            dataField: "per",
                            caption: "Per",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                        },
                        {
                            dataField: "maxRate",
                            caption: "Max",
                            allowEditing: false,
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                            visible: !isUsingGrossCalculation,
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                        },
                        {
                            caption: "Max",
                            allowEditing: false,
                            dataType: "number",
                            calculateCellValue: function(data) {
                                return data.per == 0 ? 0 : (data.toAmount / data.per) * data.rate;
                            },
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            visible: isUsingGrossCalculation,
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                        },
                        { type: "buttons", visible: false, showInColumnChooser: false }
                    ]);
            },
            getReissueCreditColumns(itemKey, reissueCreditCalculationMethodID, useReissueExcessUWSplit) {
                return _.clone([
                        {
                            dataField: itemKey,
                            visible: false,
                            allowEditing: false
                        },
                        {
                            dataField: "fromYears",
                            caption: "Greater Than From Year",
                            dataType: "number",
                            allowEditing: false,
                            format: {
                                type: "fixedPoint",
                                precision: 2
                            },
                            editorOptions: {
                                format: "####.##"
                            },
                            visible: _.includes([RateEngineReissueCreditCalculationMethod.YearAndPercentage, RateEngineReissueCreditCalculationMethod.YearAndAmount, RateEngineReissueCreditCalculationMethod.YearAndRatePer], reissueCreditCalculationMethodID)
                        },
                        {
                            dataField: "toYears",
                            caption: "Less Than or Equal To Year",
                            dataType: "number",
                            format: {
                                type: "fixedPoint",
                                precision: 2
                            },
                            editorOptions: {
                                format: "####.##"
                            },
                            visible: _.includes([RateEngineReissueCreditCalculationMethod.YearAndPercentage, RateEngineReissueCreditCalculationMethod.YearAndAmount, RateEngineReissueCreditCalculationMethod.YearAndRatePer], reissueCreditCalculationMethodID)
                        },
                        {
                            dataField: "fromAmount",
                            caption: "From Amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            allowEditing: false,
                            sortIndex: 0,
                            sortOrder: "asc",
                            visible: _.includes([RateEngineReissueCreditCalculationMethod.Amount, RateEngineReissueCreditCalculationMethod.Percentage, RateEngineReissueCreditCalculationMethod.YearAndAmount], reissueCreditCalculationMethodID)
                        },
                        {
                            dataField: "toAmount",
                            caption: "To Amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            validationRules: _.includes([RateEngineReissueCreditCalculationMethod.Amount, RateEngineReissueCreditCalculationMethod.Percentage, RateEngineReissueCreditCalculationMethod.YearAndAmount], reissueCreditCalculationMethodID)
                                            ? [ { type: "required"},
                                                {
                                                    type: "custom",
                                                    validationCallback: this.isToAmountLessThanFromAmount,
                                                    message: "To Amount must be greater than From Amount"
                                                }
                                            ]
                                            : [],
                            visible: _.includes([RateEngineReissueCreditCalculationMethod.Amount, RateEngineReissueCreditCalculationMethod.Percentage, RateEngineReissueCreditCalculationMethod.YearAndAmount], reissueCreditCalculationMethodID)
                        },
                        {
                            dataField: "ratePerThousand",
                            caption: "Rate",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 5
                            },
                            editorOptions: {
                                format: "$ #,##0.#####"
                            },
                            visible: _.includes([RateEngineReissueCreditCalculationMethod.Amount, RateEngineReissueCreditCalculationMethod.YearAndAmount, RateEngineReissueCreditCalculationMethod.YearAndRatePer], reissueCreditCalculationMethodID)
                        },
                        {
                            dataField: "usePerRate",
                            caption: "Tier",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            setCellValue: function(rowData, value) {
                                rowData.usePerRate = value;
                                rowData.per = 1000;
                            },
                            visible: _.includes([RateEngineReissueCreditCalculationMethod.Amount], reissueCreditCalculationMethodID)
                        },
                        {
                            dataField: "per",
                            caption: "Per",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            visible: _.includes([RateEngineReissueCreditCalculationMethod.Amount, RateEngineReissueCreditCalculationMethod.YearAndAmount, RateEngineReissueCreditCalculationMethod.YearAndRatePer], reissueCreditCalculationMethodID)
                        },
                        {
                            dataField: "percentCredit",
                            caption: "Percent",
                            cellTemplate: DxGridUtils.rateCellTemplate,
                            format: {
                                type: "fixedPoint",
                                precision: 2
                            },
                            editorOptions: {
                                format: "##0.000"
                            },
                            validationRules: _.includes([RateEngineReissueCreditCalculationMethod.Percentage, RateEngineReissueCreditCalculationMethod.YearAndPercentage], reissueCreditCalculationMethodID) ? [
                                                { type: "required"},
                                                {
                                                    type: "range",
                                                    min: 0,
                                                    max: 100,
                                                    message: "Invalid Percent."}
                                            ] : [],
                            visible: _.includes([RateEngineReissueCreditCalculationMethod.Percentage, RateEngineReissueCreditCalculationMethod.YearAndPercentage], reissueCreditCalculationMethodID)
                        },
                        {
                            dataField: "maxRate",
                            caption: "Max",
                            allowEditing: false,
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            visible: _.includes([RateEngineReissueCreditCalculationMethod.Amount], reissueCreditCalculationMethodID),
                        },
                        {
                            dataField: "reportCodes",
                            dataType: "string",
                            caption: "Report Code",
                            editorOptions: { maxLength: 50 },
                        },
                        {
                            dataField: "uwSplitPercent",
                            caption: "Split Percent",
                            cellTemplate: DxGridUtils.rateCellTemplate,
                            format: {
                                type: "fixedPoint",
                                precision: 2
                            },
                            editorOptions: {
                                    format: "##0.000"
                            },
                            validationRules: useReissueExcessUWSplit
                                            ? [
                                            { type: "required"},
                                            {
                                                type: "range",
                                                min: 0,
                                                max: 100,
                                                message: "Invalid Percent."}
                                            ]
                                            : [],
                            visible: useReissueExcessUWSplit
                        },
                        { type: "buttons", visible: false, showInColumnChooser: false }
                    ]);
            },
            getUnderwriterSplitColumns(itemKey, calculationBasis) {
                return _.clone([
                        {
                            dataField: itemKey,
                            visible: false,
                            allowEditing: false
                        },
                        {
                            dataField: "fromAmount",
                            caption: "From",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            allowEditing: false,
                            sortIndex: 0,
                            sortOrder: "asc",
                        },
                        {
                            dataField: "toAmount",
                            caption: "To",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "custom",
                                    validationCallback: this.isToAmountLessThanFromAmount,
                                    message: "To Amount must be greater than From Amount"
                                }
                            ]
                        },
                        {
                            dataField: "usePerRate",
                            caption: "Tier",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            setCellValue: function(rowData, value) {
                                rowData.usePerRate = value;
                                rowData.per = 1000;
                            },
                        },
                        {
                            dataField: "splitPercent",
                            caption: "Underwriter Percent",
                            dataType: "number",
                            cellTemplate: DxGridUtils.rateCellTemplate,
                            format: {
                                type: "fixedPoint",
                                precision: 4
                            },
                            editorOptions: {
                                    format: "##0.0000"
                            },
                            validationRules: calculationBasis ? [
                                                { type: "required"},
                                                {
                                                    type: "range",
                                                    min: 0,
                                                    max: 100,
                                                    message: "Invalid Percent."}
                                            ] : [],
                            visible: calculationBasis,
                        },
                        {
                            dataField: "rate",
                            caption: "Amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 5
                            },
                            editorOptions: {
                                format: "$ #,##0.#####"
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            visible: !calculationBasis,
                        },
                        {
                            dataField: "per",
                            caption: "Per",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            visible: !calculationBasis,
                        },
                        { type: "buttons", visible: false, showInColumnChooser: false }
                    ]);
            },
            isToAmountLessThanFromAmount(item) {
                return _.gt(item.data.toAmount, item.data.fromAmount);
            },
            isValidTitleInsuranceRateData(data) {
                const self = this;
                self.importRates = [];
                let lines = _.split(data, /\r?\n/);
                if (lines.length > 0 && _.trim(lines[0]) != "To,Rate,Tier,Per") {
                    self.$toast.error("First row must contain the header [To,Rate,Tier,Per], without the brackets.");
                    return false;
                }
                if (lines.length == 1) {
                    self.$toast.error("File Contains no data.");
                    return false;
                }
                for(let i = 1 ; i < lines.length; i++)
                {
                    let fields = _.split(_.trim(lines[i]), ",");
                    if (fields.length != 4 && fields[0] != "") {
                        self.$toast.error(`File Contains extra commas near line ${i}, format money fields without commas, i.e. 10,000.00 would be 10000.00`);
                        self.importRates = [];
                        return false;
                    }
                    if (_.isNullOrEmpty(fields[0])) {continue;}//catch for empty last row
                    let options = {toAmount: fields[0], rate: fields[1], usePerRate: _.isEqual(fields[2], "0"), per: fields[3], rateEffectiveDateID: self.rateEffectiveDateID};
                    self.importRates.push(new RateEngineTitleInsuranceRateDto(options));
                }
                return true;
            },
            initNonReactiveVariables() {
                const self = this;
                self.entityName = _.get(self.$route.meta, "itemTypeName");
                self.entityDescription = _.get(self.$route.meta, "itemTypeDescription");
                self.coverageRoundingKey = "rateEngineCoverageRoundingID";
                self.coverageRoundingName = "Coverage Rounding";
                self.coverageRoundingNamePlural = "Coverage Roundings";
                self.coverageRoundingSelectionActions = self.getRoundingSelectionActions(self.coverageRoundingName);
                self.coverageRoundingReissueKey = "rateEngineCoverageRoundingReissueID";
                self.coverageRoundingReissueName = "Coverage Rounding Reissue";
                self.coverageRoundingReissueNamePlural = "Coverage Rounding Reissues";
                self.coverageRoundingReissueSelectionActions = self.getRoundingSelectionActions(self.coverageRoundingReissueName);
                self.premiumRoundingKey = "rateEnginePremiumRoundingID";
                self.premiumRoundingName = "Premium Rounding";
                self.premiumRoundingNamePlural = "Premium Roundings";
                self.premiumRoundingSelectionActions = self.getRoundingSelectionActions(self.premiumRoundingName);
                self.premiumRoundingReissueKey = "rateEnginePremiumRoundingReissueID";
                self.premiumRoundingReissueName = "Premium Rounding Reissue";
                self.premiumRoundingReissueNamePlural = "Premium Rounding Reissues";
                self.premiumRoundingReissueSelectionActions = self.getRoundingSelectionActions(self.premiumRoundingReissueName);
                self.titleInsuranceKey = "rateEngineTitleInsuranceRateID";
                self.titleInsuranceName = "Title Insurance Rate";
                self.titleInsuranceNamePlural = "Title Insurance Rates";
                self.titleInsuranceSelectionActions = self.getRoundingSelectionActions(self.titleInsuranceName);
                self.reissueCreditKey = "rateEngineReissueCreditID";
                self.reissueCreditName = "Liability Range";
                self.reissueCreditNamePlural = "Liability Ranges";
                self.reissueCreditSelectionActions = self.getRoundingSelectionActions(self.reissueCreditName);
                self.underwriterSplitKey = "rateEngineUnderwriterSplitID";
                self.underwriterSplitName = "Split Calculation";
                self.underwriterSplitNamePlural = "Split Calculations";
                self.underwriterSplitSelectionActions = self.getRoundingSelectionActions(self.underwriterSplitName);

                self.plusMinusOptions = [{id: 1, name:"+"}, {id: -1, name:"-"}];
                self.premiumPercentageExcessBasedOnOptions = PremiumPercentageExcessBasedOn.lookupItems;
                self.effectiveDateBasedOnOptions = EffectiveDateBasedOn.lookupItems;
                self.taxRateEffectiveBasisOptions = TaxRateEffectiveBasis.lookupItems;
                self.taxRateBasedOnOptions = TaxRateBasedOn.lookupItems;
                self.taxContactRoles = self.lookupHelpers.getRoles();
                self.taxTypes = TaxType.lookupItems;
                self.rateEngineFileTypeNames = RateEngineFileTypeName.lookupItems;
                self.excessMethods = ExcessMethod.lookupItems;
                self.coverageRoundingMethods = CoverageRoundingMethod.lookupItems;
                self.roundingMethods = RoundingMethod.lookupItems;
                self.roundingRules = RoundingRule.lookupItems;
                self.reissueCreditCalculationMethods = RateEngineReissueCreditCalculationMethod.lookupItems;
                self.simultaneousPolicyTypes = RateSimultaneousPolicyType.lookupItems;
                self.effectiveRateCalculationBasisOptions = EffectiveRateCalculationBasis.lookupItems;
                self.regions = self.lookupHelpers.getRegions();
                self.usStates = self.lookupHelpers.getStates();
            },
            initGridConfig(){
                const self = this;
                self.premiumRoundingGridConfig = {
                    paging: { enabled: false },
                    remoteOperations: { sorting: false, paging: false },
                    columns: self.getRoundingColumns(self.premiumRoundingKey),
                    onInitNewRow: function(e) {
                        e.data.fromAmount = self.getNextFromAmount("PremiumRounding")
                    }
                };
                self.premiumRoundingGridDataSource = {
                    key: self.premiumRoundingKey,
                    loadMode: "raw",
                    load () {
                        return Promise.resolve(self.rateEnginePremiumRoundings);
                    },
                    insert: self.onPremiumRoundingGridInsert,
                    update: self.onPremiumRoundingGridUpdate
                };

                self.premiumRoundingReissueGridConfig = {
                    paging: { enabled: false },
                    remoteOperations: { sorting: false, paging: false },
                    columns: self.getRoundingColumns(self.premiumRoundingReissueKey),
                    onInitNewRow: function(e) {
                        e.data.fromAmount = self.getNextFromAmount("PremiumRoundingReissue")
                    }
                };
                self.premiumRoundingReissueGridDataSource = {
                    key: self.premiumRoundingReissueKey,
                    loadMode: "raw",
                    load () {
                        return Promise.resolve(self.rateEnginePremiumRoundingReissues);
                    },
                    insert: self.onPremiumRoundingReissueGridInsert,
                    update: self.onPremiumRoundingReissueGridUpdate
                };

                self.coverageRoundingGridConfig = {
                    paging: { enabled: false },
                    remoteOperations: { sorting: false, paging: false },
                    columns: self.getRoundingColumns(self.coverageRoundingKey),
                    onInitNewRow: function(e) {
                        e.data.fromAmount = self.getNextFromAmount("CoverageRounding")
                    }
                };
                self.coverageRoundingGridDataSource = {
                    key: self.coverageRoundingKey,
                    loadMode: "raw",
                    load () {
                        return Promise.resolve(self.rateEngineCoverageRoundings);
                    },
                    insert: self.onCoverageRoundingGridInsert,
                    update: self.onCoverageRoundingGridUpdate
                };

                self.coverageRoundingReissueGridConfig = {
                    paging: { enabled: false },
                    remoteOperations: { sorting: false, paging: false },
                    columns: self.getRoundingColumns(self.coverageRoundingReissueKey),
                    onInitNewRow: function(e) {
                        e.data.fromAmount = self.getNextFromAmount("CoverageRoundingReissue")
                    }
                };
                self.coverageRoundingReissueGridDataSource = {
                    key: self.coverageRoundingReissueKey,
                    loadMode: "raw",
                    load () {
                        return Promise.resolve(self.rateEngineCoverageRoundingReissues);
                    },
                    insert: self.onCoverageRoundingReissueGridInsert,
                    update: self.onCoverageRoundingReissueGridUpdate
                };

                self.titleInsuranceGridConfig = {
                    paging: { enabled: false },
                    remoteOperations: { sorting: false, paging: false },
                    height: 400,
                    columns: self.getTitleInsuranceColumns(self.titleInsuranceKey, self.data.rateEffectiveDate.isUsingGrossCalculation),
                    onInitNewRow: function(e) {
                        let tierChecked = _.some(self.rateEngineTitleInsuranceRates, (i) => i.usePerRate === true);
                        e.data.usePerRate = tierChecked;
                        e.data.per = self.data.rateEffectiveDate.isUsingGrossCalculation || tierChecked ? 1000 : 0;
                        e.data.fromAmount = self.getNextFromAmount("TitleInsuranceRate")
                    }
                };
                self.titleInsuranceGridDataSource = {
                    key: self.titleInsuranceKey,
                    loadMode: "raw",
                    load () {
                        return Promise.resolve(self.rateEngineTitleInsuranceRates);
                    },
                    insert: self.onTitleInsuranceGridInsert,
                    update: self.onTitleInsuranceGridUpdate
                };

                self.reissueCreditGridConfig = {
                    paging: { enabled: false },
                    remoteOperations: { sorting: false, paging: false },
                    height: 300,
                    columns: self.getReissueCreditColumns(self.reissueCreditKey, self.data.rateEffectiveDate.reissueCreditCalculationMethodID, self.data.rateEffectiveDate.useReissueExcessUWSplit),
                    onInitNewRow: function(e) {
                        var reissueCreditCalculationMethodID = self.data.rateEffectiveDate.reissueCreditCalculationMethodID;
                        let tierChecked = _.includes([RateEngineReissueCreditCalculationMethod.YearAndAmount, RateEngineReissueCreditCalculationMethod.YearAndRatePer], reissueCreditCalculationMethodID)
                                || (reissueCreditCalculationMethodID == RateEngineReissueCreditCalculationMethod.Amount
                                    && _.some(self.rateEngineReissueCredits, (i) => i.usePerRate
                                    )
                                );
                        e.data.usePerRate = tierChecked;
                        e.data.per = tierChecked ? 1000 : 0;
                        e.data.fromAmount = self.getNextFromAmount("ReissueCredit");
                        e.data.fromYears = self.getNextFromYear("ReissueCredit");
                    }
                };
                self.reissueCreditGridDataSource = {
                    key: self.reissueCreditKey,
                    loadMode: "raw",
                    load () {
                        return Promise.resolve(self.rateEngineReissueCredits);
                    },
                    insert: self.onReissueCreditGridInsert,
                    update: self.onReissueCreditGridUpdate
                };

                self.underwriterSplitGridConfig = {
                    paging: { enabled: false },
                    remoteOperations: { sorting: false, paging: false },
                    height: 300,
                    columns: self.getUnderwriterSplitColumns(self.underwriterSplitKey, self.data.rateEffectiveDate.calculationBasis),
                    onInitNewRow: function(e) {
                        let tierChecked = _.some(self.rateEngineUnderwriterSplits, (i) => i.usePerRate === true);
                        e.data.usePerRate = tierChecked;
                        e.data.per = tierChecked ? 1000 : 0;
                        e.data.fromAmount = self.getNextFromAmount("UnderwriterSplit")
                    }
                };
                self.underwriterSplitGridDataSource = {
                    key: self.underwriterSplitKey,
                    loadMode: "raw",
                    load () {
                        return Promise.resolve(self.rateEngineUnderwriterSplits);
                    },
                    insert: self.onUnderwriterSplitGridInsert,
                    update: self.onUnderwriterSplitGridUpdate
                };
            },
            onAddEffectiveDate(e) {
                let newRateData = new RateDataDto(this.data);
                let newRateShortData = newRateData.toShortObject();
                newRateShortData.effectiveDate = DateTimeHelper.nowClientStartOfDay();
                newRateShortData.rateCreateType = RateCreateType.NewEffectiveDate;
                this.showAddRate(newRateShortData);
            },
            onAddGridItem(e, type) {
                let grid = this.getGrid(type);
                if(!grid) return;
                grid.addRow();
            },
            onChangeState(e) {
                if (_.isNullOrEmpty(e.selectedValue)) return;
                this.data.countyIDs = [];
                let newAlta = e.selectedValue !== "TX";
                let oldAlta = e.previousValue !== "TX";
                if (newAlta != oldAlta) {
                    this.data.premiumTypeIDs = [];
                }
            },
            onCopyReissueCreditsFromRate(e) {
                const self = this;
                let newRateData = new RateDataDto(self.data);
                let newRateShortData = newRateData.toShortObject();
                newRateShortData.rateCreateType = RateCreateType.ReissueCreditsFromRate;
                let ok = function (args) {
                    let apiPromise = self.$api.RatesApi.copyRates(newRateShortData);
                    self.$rqBusy.wait(apiPromise)
                        .then(result => {
                            self.rateEngineReissueCredits = _.map(result.rateEngineReissueCredits, i => new RateEngineReissueCreditDto(i));
                            self.refreshGrid("ReissueCredit");
                        }).catch(error => {
                            console.error(error);
                            self.$toast.error({ message: `Error Copying Rates.` });
                        });
                }

                self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the existing ${self.reissueCreditNamePlural} and copy from ${newRateShortData.hasLinkedPremium ? 'Linked Premium Title Insurance Rates' : 'Title Insurance Rates'}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            onCopyUnderwriterSplitsFromRate(e) {
                const self = this;
                let newRateData = new RateDataDto(self.data);
                let newRateShortData = newRateData.toShortObject();
                newRateShortData.rateCreateType = RateCreateType.UnderwriterSplitsFromRate;
                let ok = function (args) {
                    let apiPromise = self.$api.RatesApi.copyRates(newRateShortData);
                    self.$rqBusy.wait(apiPromise)
                        .then(result => {
                            self.rateEngineUnderwriterSplits = _.map(result.rateEngineUnderwriterSplits, i => new RateEngineUnderwriterSplitDto(i));
                            self.refreshGrid("UnderwriterSplit");
                        }).catch(error => {
                            console.error(error);
                            self.$toast.error({ message: `Error Copying Rates.` });
                        });
                }

                self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the existing ${self.underwriterSplitNamePlural} and copy from ${newRateShortData.hasLinkedPremium ? 'Linked Premium Title Insurance Rates' : 'Title Insurance Rates'}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            onJumpToEffectiveDateChange(e) {
                if(_.isNil(e.event)) return;
                this.save()
                    .then(result => {
                        this.$router.push({ name: 'cfg:sscalc:premium', params: { rateEffectiveDateID: e.value}});
                        this.fetchData(e.value);
                    }).catch(error => {
                        this.$toast.error(`Error saving ${this.entityName}.`);
                        console.error(error);
                    });
            },
            onCalculationBasisChange(e) {
                let grid = this.getGrid("UnderwriterSplit");
                if(_.isEmpty(grid)) return;
                let columns = this.getUnderwriterSplitColumns(this.underwriterSplitKey, e.value);
                grid.beginUpdate();
                grid.option("columns", columns);
                grid.endUpdate();
                grid.updateDimensions();
            },
            onIsUsingGrossCalculationChange(e) {
                let grid = this.getGrid("TitleInsuranceRate");
                if(_.isEmpty(grid)) return;
                let columns = this.getTitleInsuranceColumns(this.titleInsuranceKey, e);
                grid.beginUpdate();
                grid.option("columns", columns);
                grid.endUpdate();
            },
            onDeleteCoverageRounding(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let toBeDeletedKeys = _.map(items, self.coverageRoundingKey);
                    let apiPromise = self.$api.RatesApi.deleteCoverageRounding(toBeDeletedKeys);
                    self.$rqBusy.wait(apiPromise)
                        .then(key => {
                            self.removeGridItems(items, "CoverageRounding", self.coverageRoundingKey);
                            let message = key.length > 1 ? `${key.length} ${self.coverageRoundingNamePlural} were deleted.` : `${self.coverageRoundingName} was deleted.`
                            self.$toast.success({ message: message });
                        })
                        .catch(error => {
                            self.$toast.error({ message: `Error deleting ${self.coverageRoundingName}.` });
                        })
                        .finally(() => {
                            self.refreshGrid("CoverageRounding");
                        });
                }

                 self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.coverageRoundingNamePlural : self.coverageRoundingName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            onDeleteCoverageRoundingReissue(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let toBeDeletedKeys = _.map(items, self.coverageRoundingReissueKey);
                    let apiPromise = self.$api.RatesApi.deleteCoverageRoundingReissue(toBeDeletedKeys);
                    self.$rqBusy.wait(apiPromise)
                        .then(key => {
                            self.removeGridItems(items, "CoverageRoundingReissue", self.coverageRoundingReissueKey);
                            let message = key.length > 1 ? `${key.length} ${self.coverageRoundingNameReissuePlural} were deleted.` : `${self.coverageRoundingReissueName} was deleted.`
                            self.$toast.success({ message: message });
                        })
                        .catch(error => {
                            self.$toast.error({ message: `Error deleting ${self.coverageRoundingReissueName}.` });
                        })
                        .finally(() => {
                            self.refreshGrid("CoverageRoundingReissue");
                        });
                }

                 self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.coverageRoundingReissueNamePlural : self.coverageRoundingReissueName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            onDeletePremiumRounding(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let toBeDeletedKeys = _.map(items, self.premiumRoundingKey);
                    let apiPromise = self.$api.RatesApi.deletePremiumRounding(toBeDeletedKeys);
                    self.$rqBusy.wait(apiPromise)
                        .then(key => {
                            self.removeGridItems(items, "PremiumRounding", self.premiumRoundingKey);
                            let message = key.length > 1 ? `${key.length} ${self.premiumRoundingNamePlural} were deleted.` : `${self.premiumRoundingName} was deleted.`
                            self.$toast.success({ message: message });
                        })
                        .catch(error => {
                            self.$toast.error({ message: `Error deleting ${self.premiumRoundingName}.` });
                        })
                        .finally(() => {
                            self.refreshGrid("PremiumRounding");
                        });
                }

                 self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.premiumRoundingNamePlural : self.premiumRoundingName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            onDeletePremiumRoundingReissue(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let toBeDeletedKeys = _.map(items, self.premiumRoundingReissueKey);
                    let apiPromise = self.$api.RatesApi.deletePremiumRoundingReissue(toBeDeletedKeys);
                    self.$rqBusy.wait(apiPromise)
                        .then(key => {
                            self.removeGridItems(items, "PremiumRoundingReissue", self.premiumRoundingReissueKey);
                            let message = key.length > 1 ? `${key.length} ${self.premiumRoundingNameReissuePlural} were deleted.` : `${self.premiumRoundingReissueName} was deleted.`
                            self.$toast.success({ message: message });
                        })
                        .catch(error => {
                            self.$toast.error({ message: `Error deleting ${self.premiumRoundingReissueName}.` });
                        })
                        .finally(() => {
                            self.refreshGrid("PremiumRoundingReissue");
                        });
                }

                 self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.premiumRoundingReissueNamePlural : self.premiumRoundingReissueName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            onDeleteReissueCredit(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let toBeDeletedKeys = _.map(items, self.reissueCreditKey);
                    let apiPromise = self.$api.RatesApi.deleteReissueCredit(toBeDeletedKeys);
                    self.$rqBusy.wait(apiPromise)
                        .then(key => {
                            self.removeGridItems(items, "ReissueCredit", self.reissueCreditKey);
                            let message = key.length > 1 ? `${key.length} ${self.reissueCreditNamePlural} were deleted.` : `${self.reissueCreditName} was deleted.`
                            self.$toast.success({ message: message });
                        })
                        .catch(error => {
                            self.$toast.error({ message: `Error deleting ${self.reissueCreditName}.` });
                        })
                        .finally(() => {
                            self.refreshGrid("ReissueCredit");
                        });
                }

                 self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.reissueCreditNamePlural : self.reissueCreditName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            onDeleteTitleInsurance(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let toBeDeletedKeys = _.map(items, self.titleInsuranceKey);
                    let apiPromise = self.$api.RatesApi.deleteTitleInsuranceRate(toBeDeletedKeys);
                    self.$rqBusy.wait(apiPromise)
                        .then(key => {
                            self.removeGridItems(items, "TitleInsuranceRate", self.titleInsuranceKey);
                            let message = key.length > 1 ? `${key.length} ${self.titleInsuranceNamePlural} were deleted.` : `${self.titleInsuranceName} was deleted.`
                            self.$toast.success({ message: message });
                        })
                        .catch(error => {
                            self.$toast.error({ message: `Error deleting ${self.titleInsuranceName}.` });
                        })
                        .finally(() => {
                            self.refreshGrid("TitleInsuranceRate");
                        });
                }

                 self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.titleInsuranceNamePlural : self.titleInsuranceName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            onDeleteUnderwriterSplit(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let toBeDeletedKeys = _.map(items, self.underwriterSplitKey);
                    let apiPromise = self.$api.RatesApi.deleteUnderwriterSplit(toBeDeletedKeys);
                    self.$rqBusy.wait(apiPromise)
                        .then(key => {
                            self.removeGridItems(items, "UnderwriterSplit", self.underwriterSplitKey);
                            let message = key.length > 1 ? `${key.length} ${self.underwriterSplitNamePlural} were deleted.` : `${self.underwriterSplitName} was deleted.`
                            self.$toast.success({ message: message });
                        })
                        .catch(error => {
                            self.$toast.error({ message: `Error deleting ${self.underwriterSplitName}.` });
                        })
                        .finally(() => {
                            self.refreshGrid("UnderwriterSplit");
                        });
                }

                 self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.underwriterSplitNamePlural : self.underwriterSplitName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            onCancel(e) {
                const self = this;
                let changes = self.getAuditChanges(self.originalData.toDataObject(), self.data.toDataObject());
                let ok = () => {
                    self.isCancelled = true;
                    self.cancelChildComponentChanges();
                    self.clearGridValidationErrors();
                    self.$router.push({ name: "cfg:sscalc:premiums" });
                    return true;
                }
                if ((changes.length === 0 || self.readOnly) && !self.childComponentsHaveChanges()) {
                    ok();
                } else {
                    self.$dialog.confirm("Confirm Cancel", "Discard changes and return to premiums list?", ok);
                }
            } ,
            onCoverageRoundingGridInsert(values) {
                const self = this;
                let newItem = new RateEngineCoverageRoundingDto(values);
                newItem.rateEffectiveDateID = self.rateEffectiveDateID;
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                return self.saveCoverageRounding(newItem, changes)
                    .then(result => {
                        self.rateEngineCoverageRoundings.push(new RateEngineCoverageRoundingDto(_.clone(result)));
                        self.coverageRoundingValidationErrors = [];
                    }).catch(error => {
                        self.$toast.error(`Error saving ${self.coverageRoundingName}.`);
                        console.error(error.errorMessage);
                    }).finally(() => {
                        self.hasValidationErrors = self.checkValidationErrors();
                        self.refreshGrid("CoverageRounding");
                    });
            },
            onCoverageRoundingGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.rateEngineCoverageRoundings, item => _.parseNumber(_.get(item, self.coverageRoundingKey), -1) === key);
                if(itemIndex < 0) return self.onPremiumRateGridInsert(values);

                let originalItem = _.cloneDeep(self.rateEngineCoverageRoundings[itemIndex]);
                let updatedItem = new RateEngineCoverageRoundingDto(_.assign({}, self.rateEngineCoverageRoundings[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                return self.saveCoverageRounding(updatedItem, changes)
                    .then(result => {
                        self.rateEngineCoverageRoundings[itemIndex] = new RateEngineCoverageRoundingDto(_.clone(result));
                        self.coverageRoundingValidationErrors = [];
                    }).catch(error => {
                        self.$toast.error(`Error saving ${self.coverageRoundingName}.`);
                        console.error(error.errorMessage);
                    }).finally(() => {
                        self.hasValidationErrors = self.checkValidationErrors();
                        self.refreshGrid("CoverageRounding");
                    });
            },
            onCoverageRoundingReissueGridInsert(values) {
                const self = this;
                let newItem = new RateEngineCoverageRoundingReissueDto(values);
                newItem.rateEffectiveDateID = self.rateEffectiveDateID;
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                return self.saveCoverageRoundingReissue(newItem, changes)
                    .then(result => {
                        self.rateEngineCoverageRoundingReissues.push(new RateEngineCoverageRoundingReissueDto(_.clone(result)));
                        self.coverageRoundingReissueValidationErrors = [];
                        self.hasValidationErrors = self.checkValidationErrors();
                    }).catch(error => {
                        self.$toast.error(`Error saving ${self.CoverageRoundingReissueName}.`);
                        console.error(error.errorMessage);
                    }).finally(() => {
                        self.refreshGrid("CoverageRoundingReissue");
                    });
            },
            onCoverageRoundingReissueGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.rateEngineCoverageRoundingReissues, item => _.parseNumber(_.get(item, self.coverageRoundingKey), -1) === key);
                if(itemIndex < 0) return self.onPremiumRateGridInsert(values);

                let originalItem = _.cloneDeep(self.rateEngineCoverageRoundingReissues[itemIndex]);
                let updatedItem = new RateEngineCoverageRoundingReissueDto(_.assign({}, self.rateEngineCoverageRoundingReissues[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                return self.saveCoverageRounding(updatedItem, changes)
                    .then(result => {
                        self.rateEngineCoverageRoundingReissues[itemIndex] = new RateEngineCoverageRoundingReissueDto(_.clone(result));
                        self.coverageRoundingReissueValidationErrors = [];
                        self.hasValidationErrors = self.checkValidationErrors();
                    }).catch(error => {
                        self.$toast.error(`Error saving ${self.CoverageRoundingReissueName}.`);
                        console.error(error.errorMessage);
                    }).finally(() => {
                        self.refreshGrid("CoverageRoundingReissue");
                    });
            },
            onLoanPolicyChange(e) {
                this.data.simultaneous.loanConcurrentPolicy = 0;
            },
            onSalesPolicyChange(e) {
                this.data.simultaneous.salesConcurrentPolicy = 0;
            },
            onLoanFlatFeeUnderwriterSplitOverrideClicked(e) {
                this.data.simultaneous.loanFlatFeeUWSplitOverride = !this.data.simultaneous.loanFlatFeeUWSplitOverride;
                this.data.simultaneous.loanFlatFeeUWSplitPercent = 0;
            },
            onPremiumRoundingGridInsert(values) {
                const self = this;
                let newItem = new RateEnginePremiumRoundingDto(values);
                newItem.rateEffectiveDateID = self.rateEffectiveDateID;
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                return self.savePremiumRounding(newItem, changes)
                    .then(result => {
                        self.rateEnginePremiumRoundings.push(new RateEnginePremiumRoundingDto(_.clone(result)));
                        self.premiumRoundingValidationErrors = [];
                    }).catch(error => {
                        self.$toast.error(`Error saving ${self.premiumRoundingName}.`);
                        console.error(error.errorMessage);
                    }).finally(() => {
                        self.hasValidationErrors = self.checkValidationErrors();
                        self.refreshGrid("PremiumRounding");
                    });
            },
            onPremiumRoundingGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.rateEnginePremiumRoundings, item => _.parseNumber(_.get(item, self.premiumRoundingKey), -1) === key);
                if(itemIndex < 0) return self.onPremiumRateGridInsert(values);

                let originalItem = _.cloneDeep(self.rateEnginePremiumRoundings[itemIndex]);
                let updatedItem = new RateEnginePremiumRoundingDto(_.assign({}, self.rateEnginePremiumRoundings[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                return self.savePremiumRounding(updatedItem, changes)
                    .then(result => {
                        self.rateEnginePremiumRoundings[itemIndex] = new RateEnginePremiumRoundingDto(_.clone(result));
                        self.premiumRoundingValidationErrors = [];
                    }).catch(error => {
                        self.$toast.error(`Error saving ${self.premiumRoundingName}.`);
                        console.error(error.errorMessage);
                    }).finally(() => {
                        self.hasValidationErrors = self.checkValidationErrors();
                        self.refreshGrid("PremiumRounding");
                    });
            },
            onPremiumRoundingReissueGridInsert(values) {
                const self = this;
                let newItem = new RateEnginePremiumRoundingReissueDto(values);
                newItem.rateEffectiveDateID = self.rateEffectiveDateID;
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                return self.savePremiumRoundingReissue(newItem, changes)
                    .then(result => {
                        self.rateEnginePremiumRoundingReissues.push(new RateEnginePremiumRoundingReissueDto(_.clone(result)));
                        self.premiumRoundingReissueValidationErrors = [];
                        self.hasValidationErrors = self.checkValidationErrors();
                    }).catch(error => {
                        self.$toast.error(`Error saving ${self.premiumRoundingReissueName}.`);
                        console.error(error.errorMessage);
                    }).finally(() => {
                        self.refreshGrid("PremiumRoundingReissue");
                    });
            },
            onPremiumRoundingReissueGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.rateEnginePremiumRoundingReissues, item => _.parseNumber(_.get(item, self.premiumRoundingReissueKey), -1) === key);
                if(itemIndex < 0) return self.onPremiumRateGridInsert(values);

                let originalItem = _.cloneDeep(self.rateEnginePremiumRoundingReissues[itemIndex]);
                let updatedItem = new RateEnginePremiumRoundingReissueDto(_.assign({}, self.rateEnginePremiumRoundingReissues[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                return self.savePremiumRoundingReissue(updatedItem, changes)
                    .then(result => {
                        self.rateEnginePremiumRoundingReissues[itemIndex] = new RateEnginePremiumRoundingReissueDto(_.clone(result));
                        self.premiumRoundingReissueValidationErrors = [];
                        self.hasValidationErrors = self.checkValidationErrors();
                    }).catch(error => {
                        self.$toast.error(`Error saving ${self.PremiumRoundingReissueName}.`);
                        console.error(error.errorMessage);
                    }).finally(() => {
                        self.refreshGrid("PremiumRoundingReissue");
                    });
            },
            onReissueCreditGridInsert(values) {
                const self = this;
                let newItem = new RateEngineReissueCreditDto(values);
                newItem.rateEffectiveDateID = self.rateEffectiveDateID;
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                return self.saveReissueCredit(newItem, changes)
                    .then(result => {
                        self.rateEngineReissueCredits.push(new RateEngineReissueCreditDto(_.clone(result)));
                        self.reissueCreditValidationErrors = [];
                        self.hasValidationErrors = self.checkValidationErrors();
                    }).catch(error => {
                        self.$toast.error(`Error saving ${self.reissueCreditName}.`);
                        console.error(error.errorMessage);
                    }).finally(() => {
                        self.refreshGrid("ReissueCredit");
                    });
            },
            onReissueCreditGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.rateEngineReissueCredits, item => _.parseNumber(_.get(item, self.reissueCreditKey), -1) === key);
                if(itemIndex < 0) return self.onReissueCreditGridInsert(values);

                let originalItem = _.cloneDeep(self.rateEngineReissueCredits[itemIndex]);
                let updatedItem = new RateEngineReissueCreditDto(_.assign({}, self.rateEngineReissueCredits[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                return self.saveReissueCredit(updatedItem, changes)
                    .then(result => {
                        self.rateEngineReissueCredits[itemIndex] = new RateEngineReissueCreditDto(_.clone(result));
                        self.reissueCreditValidationErrors = [];
                        self.hasValidationErrors = self.checkValidationErrors();
                    }).catch(error => {
                        self.$toast.error(`Error saving ${self.coverageRoundingName}.`);
                        console.error(error.errorMessage);
                    }).finally(() => {
                        self.refreshGrid("ReissueCredit");
                    });
            },
            onReissueCreditCalculationMethodChange(newValue) {
                let grid = this.getGrid("ReissueCredit");
                if(_.isEmpty(grid)) return;
                let columns = this.getReissueCreditColumns(this.reissueCreditKey, newValue, this.data.rateEffectiveDate.useReissueExcessUWSplit);
                grid.beginUpdate();
                grid.option("columns", columns);
                grid.endUpdate();
                grid.updateDimensions();
            },
            onRegionChange(e) {
                const self = this;
                self.data.regionIDs = _.clone(e.value);
                if (self.data.regionIDs.length > 0) {
                    self.underwriters = self.lookupHelpers.getUnderwriters(_.clone(e.value));
                    let newUnderWriters = _.map(self.underwriters, 'id');
                    let removeIDs = _.difference(self.data.underwriterIDs, newUnderWriters);
                    _.pullAll(self.data.underwriterIDs, removeIDs);
                } else {
                    self.underwriters = [];
                }
            },
            onUseReissueExcessUWSplitChange(e) {
                let grid = this.getGrid("ReissueCredit");
                if(_.isEmpty(grid)) return;
                let columns = this.getReissueCreditColumns(this.reissueCreditKey, this.data.rateEffectiveDate.reissueCreditCalculationMethodID, e);
                grid.beginUpdate();
                grid.option("columns", columns);
                grid.endUpdate();
                grid.updateDimensions();
            },
            onSalesFlatFeeUnderwriterSplitOverrideClicked(e) {
                this.data.simultaneous.salesFlatFeeUWSplitOverride = !this.data.simultaneous.salesFlatFeeUWSplitOverride;
                this.data.simultaneous.salesFlatFeeUWSplitPercent = 0;
            },
            onTitleInsuranceGridInsert(values) {
                const self = this;
                let newItem = new RateEngineTitleInsuranceRateDto(values);
                newItem.rateEffectiveDateID = self.rateEffectiveDateID;
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                return self.saveTitleInsuranceRate(newItem, changes)
                    .then(result => {
                        self.rateEngineTitleInsuranceRates.push(new RateEngineTitleInsuranceRateDto(_.clone(result)));
                        self.titleInsuranceValidationErrors = [];
                        self.$toast.success({ message: `${self.entityName} was saved.` });
                    }).catch(error => {
                        self.$toast.error(`Error saving ${self.titleInsuranceName}.`);
                        console.error(error.errorMessage);
                    }).finally(() => {
                        self.hasValidationErrors = self.checkValidationErrors();
                        //self.refreshGrid("TitleInsuranceRate");
                    });
            },
            onTitleInsuranceGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.rateEngineTitleInsuranceRates, item => _.parseNumber(_.get(item, self.titleInsuranceKey), -1) === key);
                if(itemIndex < 0) return self.onTitleInsuranceGridInsert(values);

                let originalItem = _.cloneDeep(self.rateEngineTitleInsuranceRates[itemIndex]);
                let updatedItem = new RateEngineTitleInsuranceRateDto(_.assign({}, self.rateEngineTitleInsuranceRates[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                return self.saveTitleInsuranceRate(updatedItem, changes)
                    .then(result => {
                        self.rateEngineTitleInsuranceRates[itemIndex] = new RateEngineTitleInsuranceRateDto(_.clone(result));
                        self.titleInsuranceValidationErrors = [];
                        self.$toast.success({ message: `${self.entityName} was updated.` });
                    }).catch(error => {
                        self.$toast.error(`Error saving ${self.coverageRoundingName}.`);
                        console.error(error.errorMessage);
                    }).finally(() => {
                        self.hasValidationErrors = self.checkValidationErrors();
                        //self.refreshGrid("TitleInsuranceRate");
                    });
            },
            onTitleInsuranceRateExportClick(e) {
                const self = this;
                if(self.rateEngineTitleInsuranceRates.length === 0) {
                    self.$toast.error("No rates to export.");
                    return;
                }
                let data = self.createTitleInsuranceRateData();
                let file = new Blob([data], {type: "text/plain"});
                let filename = `${self.elementName(self.titleInsuranceNamePlural, 'export')}.csv`;
                if (window.navigator.msSaveOrOpenBlob) // IE10+
                    window.navigator.msSaveOrOpenBlob(file, filename);
                else { // Others
                    var a = document.createElement("a"),
                            url = URL.createObjectURL(file);
                    a.href = url;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    setTimeout(function() {
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);
                    }, 0);
                }
            },
            onTitleInsuranceRateImportClick(e) {
                let fileUpload = _.get(this.$refs, "titleInsuranceRateFile", {});
                if (!_.isNil(fileUpload)) {
                    fileUpload.click();
                }
            },
            onTitleInsuranceRateImport(e) {
                const self = this;
                let files = e.target.files;
                self.validationErrors = [];
                if (files.length == 0) {
                    self.$toast.info("No File detected.");
                    return;
                } else if (files.length > 1) {
                    self.$toast.info("Please Upload one file at a time.");
                    return;
                } else if (!_.endsWith(files[0].name, '.csv')) {
                    self.$toast.info("File must be Comma Separated File (.csv).");
                    return;
                }
                var reader = new FileReader();
                reader.onload = function (evt) {
                    let data = evt.target.result;
                    if (self.isValidTitleInsuranceRateData(data)) {
                        if (self.importRates.length === 0) {
                            self.$toast.error("No rates to import.");
                            return;
                        }
                        let ok = function (args) {
                            self.validationErrors = [];
                            self.rateEngineTitleInsuranceRates = [];
                            let apiPromise = self.$api.RatesApi.importRates(self.importRates);
                            self.$rqBusy.wait(apiPromise)
                                        .then(rates => {
                                            self.rateEngineTitleInsuranceRates = _.map(rates, i => new RateEngineTitleInsuranceRateDto(i)) || [];
                                        }).catch(error => {
                                            self.$toast.error(`Error importing ${self.titleInsuranceNamePlural}.`);
                                            console.error(error.errorMessage);
                                        }).finally(() => {
                                            self.refreshGrid("TitleInsuranceRate");
                                        });
                        }

                        self.$dialog.confirm(`Confirm Import`, `Are you sure you to import ${self.importRates.length} ${self.titleInsuranceNamePlural}, are you sure you want to do this?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
                    }
                }
                reader.readAsText(files[0]);
            },
            onUnderwriterSplitGridInsert(values) {
                const self = this;
                let newItem = new RateEngineUnderwriterSplitDto(values);
                newItem.rateEffectiveDateID = self.rateEffectiveDateID;
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                return self.saveUnderwriterSplit(newItem, changes)
                    .then(result => {
                        self.rateEngineUnderwriterSplits.push(new RateEngineUnderwriterSplitDto(_.clone(result)));
                        self.underwriterSplitValidationErrors = [];
                    }).catch(error => {
                        self.$toast.error(`Error saving ${self.underwriterSplitName}.`);
                        console.error(error.errorMessage);
                    }).finally(() => {
                        self.hasValidationErrors = self.checkValidationErrors();
                        self.refreshGrid("UnderwriterSplit");
                    });
            },
            onUnderwriterSplitGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.rateEngineUnderwriterSplits, item => _.parseNumber(_.get(item, self.underwriterSplitKey), -1) === key);
                if(itemIndex < 0) return self.onUnderwriterSplitGridInsert(values);

                let originalItem = _.cloneDeep(self.rateEngineUnderwriterSplits[itemIndex]);
                let updatedItem = new RateEngineUnderwriterSplitDto(_.assign({}, self.rateEngineUnderwriterSplits[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                return self.saveUnderwriterSplit(updatedItem, changes)
                    .then(result => {
                        self.rateEngineUnderwriterSplits[itemIndex] = new RateEngineUnderwriterSplitDto(_.clone(result));
                        self.underwriterSplitValidationErrors = [];
                    }).catch(error => {
                        self.$toast.error(`Error saving ${self.coverageRoundingName}.`);
                        console.error(error.errorMessage);
                    }).finally(() => {
                        self.hasValidationErrors = self.checkValidationErrors();
                        self.refreshGrid("UnderwriterSplit");
                    });
            },
            refreshGrid(type) {
                let grid = this.getGrid(type);

                if (!_.isEmpty(grid)) {
                    grid.option("focusedRowIndex", -1);
                    grid.clearSelection();
                    grid.refresh();
                    grid.updateDimensions();
                }
            },
            removeGridItems(items, listType, key) {
                let list = _.get(this, `rateEngine${listType}s`);
                _.pullAllBy(list, items, key);
            },
            onSave(e) {
                if(this.readOnly || this.isCancelled) {
                    GlobalEventManager.saveCompleted({ success: true });
                    return;
                }
                return this.save(e);
            },
            invokeGridMethod(refName, methodName, ...params){
                return _.invoke(this, `$refs.${refName}.invokeGridMethod`, methodName, ...params);
            },
            invokeGridComponentMethod(refName, methodName, ...params){
                _.invoke(this, `$refs.${refName}.${methodName}`, ...params)
            },
            saveChildComponents(){
                const self = this;
                let promises = [];
                if(!_.isEmpty(self.coverageRoundingDataGrid) && self.coverageRoundingDataGrid.hasEditData()) promises.push(self.coverageRoundingDataGrid.saveEditData());
                if(!_.isEmpty(self.coverageRoundingReissueDataGrid) && self.coverageRoundingReissueDataGrid.hasEditData()) promises.push(self.coverageRoundingReissueDataGrid.saveEditData());
                if(!_.isEmpty(self.titleInsuranceRateDataGrid) && self.titleInsuranceRateDataGrid.hasEditData()) promises.push(self.titleInsuranceRateDataGrid.saveEditData());
                if(!_.isEmpty(self.reissueCreditDataGrid) && self.reissueCreditDataGrid.hasEditData()) promises.push(self.reissueCreditDataGrid.saveEditData());
                if(!_.isEmpty(self.premiumRoundingReissueDataGrid) && self.premiumRoundingReissueDataGrid.hasEditData()) promises.push(self.premiumRoundingReissueDataGrid.saveEditData());
                if(!_.isEmpty(self.premiumRoundingDataGrid) && self.premiumRoundingDataGrid.hasEditData()) promises.push(self.premiumRoundingDataGrid.saveEditData());
                promises.push(self.invokeGridComponentMethod("underwriterSplitDataGrid", "saveRow"));
                return Promise.all(promises);
            },
            save(args={}) {
                const self = this;
                self.v$.$touch();
                let childChanges = self.childComponentsHaveChanges();
                let detailsChanges = self.getAuditChanges(self.originalData.toDataObject(), self.data.toDataObject());

                if (!self.isCancelled && !self.readOnly && (detailsChanges.length > 0 || childChanges)){
                    self.validationErrors = [];
                    self.hasValidationErrors = self.checkValidationErrors();

                    let success = true;

                    return self.saveChildComponents()
                        .then(() => {
                            self.hasValidationErrors = self.checkValidationErrors();
                            if(self.v$.$error)
                            {
                                GlobalEventManager.saveCompleted({ success: false });
                                return Promise.resolve(false);
                            }
                            if (detailsChanges.length > 0 && !self.readOnly) {
                                let apiPromise = self.$api.RatesApi.save(self.data, detailsChanges);
                                return self.$rqBusy.wait(apiPromise)
                                    .then(result => {
                                        self.initData(result);
                                        self.$toast.success({ message: `${self.entityName} was saved.` });
                                    })
                                    .catch(error => {
                                        self.$toast.error(`Error Saving ${self.entityName}`);
                                        success = false;
                                    })
                                    .finally(result => {
                                        GlobalEventManager.saveCompleted({ success });
                                        return Promise.resolve(success);
                                    });
                            } else {
                                if(_.getBool(args, "userInitiated") && !self.readOnly && !childChanges)
                                    self.$toast.info("No changes detected");
                                GlobalEventManager.saveCompleted({ success });
                                self.$toast.success({ message: `${self.entityName} was saved.` });
                                return Promise.resolve(true) ;
                            }
                    });
                }
                else {
                    self.hasValidationErrors = self.checkValidationErrors();
                    GlobalEventManager.saveCompleted({ success: true });
                    return Promise.resolve(true);
                }

                // if ((self.isCancelled || self.readOnly) && (detailsChanges.length === 0 && !childChanges)){

                // }

                // if(self.readOnly || self.isCancelled && !childChanges) {
                //     GlobalEventManager.saveCompleted({ success: true });
                //     return Promise.resolve(true);
                // }


            },
            saveCoverageRounding(item, changes) {
                const self = this;

                if(changes.length === 0 ) {
                    if(!self.readOnly)
                        self.$toast.error("No changes detected.");
                    return Promise.resolve(true);
                }
                self.validationErrors = [];
                let apiPromise = self.$api.RatesApi.saveCoverageRounding(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        return Promise.resolve(result);
                    }).catch(error => {
                        return Promise.reject(error);
                    });
            },
            saveCoverageRoundingReissue(item, changes) {
                const self = this;

                if(changes.length === 0) {
                    if(!self.readOnly)
                        self.$toast.error("No changes detected.");
                    return Promise.resolve(true);
                }
                self.validationErrors = [];
                let apiPromise = self.$api.RatesApi.saveCoverageRoundingReissue(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        return Promise.resolve(result);
                    }).catch(error => {
                        return Promise.reject(error);
                    });
            },
            savePremiumRounding(item, changes) {
                const self = this;

                if(changes.length === 0 ) {
                    if(!self.readOnly)
                        self.$toast.error("No changes detected.");
                    return Promise.resolve(true);
                }
                self.validationErrors = [];
                let apiPromise = self.$api.RatesApi.savePremiumRounding(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        return Promise.resolve(result);
                    }).catch(error => {
                        return Promise.reject(error);
                    });
            },
            savePremiumRoundingReissue(item, changes) {
                const self = this;

                if(changes.length === 0 ) {
                    if(!self.readOnly)
                        self.$toast.error("No changes detected.");
                    return Promise.resolve(true);
                }
                self.validationErrors = [];
                let apiPromise = self.$api.RatesApi.savePremiumRoundingReissue(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        return Promise.resolve(result);
                    }).catch(error => {
                        return Promise.reject(error);
                    });
            },
            saveReissueCredit(item, changes) {
                const self = this;

                if(changes.length === 0) {
                    if(!self.readOnly)
                        self.$toast.error("No changes detected.");
                    return Promise.resolve(true);
                }
                self.validationErrors = [];
                let apiPromise = self.$api.RatesApi.saveReissueCredit(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        return Promise.resolve(result);
                    }).catch(error => {
                        return Promise.reject(error);
                    });
            },
            saveTitleInsuranceRate(item, changes) {
                const self = this;

                if(changes.length === 0) {
                    if(!self.readOnly)
                        self.$toast.error("No changes detected.");
                    return Promise.resolve(true);
                }
                let isNew = item.isNew;
                self.validationErrors = [];
                let apiPromise = self.$api.RatesApi.saveTitleInsuranceRate(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        return Promise.resolve(result);
                    }).catch(error => {
                        return Promise.reject(error);
                    });
            },
            saveUnderwriterSplit(item, changes) {
                const self = this;

                if(changes.length === 0) {
                    if(!self.readOnly)
                        self.$toast.error("No changes detected.");
                    return Promise.resolve(true);
                }
                let isNew = item.isNew;
                self.validationErrors = [];
                let apiPromise = self.$api.RatesApi.saveUnderwriterSplit(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        return Promise.resolve(result);
                    }).catch(error => {
                        return Promise.reject(error);
                    });
            },
            showAddRate(item) {
                const self = this;
                let onOk = (e) => {
                    let form = e.component;
                    let item = form.item;
                    self.validationErrors = form.validationErrors = [];
                    if (!item.isValid || !form.isValid()) {
                        form.validationErrors = _.concat(item.validationErrors);
                        return false;
                    }
                    self.create(item, form);
                };
                self.$dialog.open({
                    title: `Add Record`,
                    width: "500",
                    height: "auto",
                    resizable: false,
                    scrollable: false,
                    adaptive: true,
                    closeOnEsc: true,
                    component: PremiumFormAdd,
                    props: {
                        item: item,
                        effectiveRates: self.effectiveRates
                    },
                    buttons: [
                        { title: "Cancel", automationId: "btn_dm_modal_cancel", cssClass: "btn btn-secondary", closeOnComplete: true },
                        { title: "Save & Edit", automationId: "btn_dm_modal_ok", cssClass: "btn btn-primary", closeOnComplete: true, onClick: onOk }
                    ],
                });
            },
            clearGridValidationErrors() {
                this.coverageRoundingValidationErrors = [];
                this.titleInsuranceValidationErrors = [];
                this.reissueCreditValidationErrors = [];
                this.coverageRoundingReissueValidationErrors = [];
                this.premiumRoundingReissueValidationErrors = [];
                this.premiumRoundingValidationErrors = [];
                this.underwriterSplitValidationErrors = [];
            },
            checkValidationErrors() {
                return this.validationErrors.length > 0
                    || this.coverageRoundingValidationErrors.length > 0
                    || this.titleInsuranceValidationErrors.length > 0
                    || this.reissueCreditValidationErrors.length > 0
                    || this.coverageRoundingReissueValidationErrors.length > 0
                    || this.premiumRoundingReissueValidationErrors.length > 0
                    || this.premiumRoundingValidationErrors.length > 0
                    || this.underwriterSplitValidationErrors.length > 0
                    || this.v$.$error;
            }
        }
    }
</script>
