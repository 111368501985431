export class Ready2CloseSystemDto {
    constructor(options) {
        options = options || {};
        this.id = _.parseNumber(options.id, 0);
        this.name = options.name || null;
        this.ready2CloseUrl = options.ready2CloseUrl || null;
        this.titleCompanyID = _.parseNumber(options.titleCompanyID);
        if (_.size(options.regions || []) == 0 || (options.regions || "").length > 0) {
            this.regions = _.split(options.regions || "", ',').map(Number);
        } else {
            this.regions = options.regions || [];
        }
        if (_.size(options.branches || []) == 0 || (options.branches || "").length > 0) {
            this.branches = _.split(options.branches || "", ',').map(Number);
        } else {
            this.branches = options.branches || [];
        }
        this.allRegions = _.parseBool(options.allRegions, false);
        this.allBranches = _.parseBool(options.allBranches, false);
    }

    get isNew() { return this.id == 0; }

    get validationErrors() {
        let errorList = [];
        if (!_.any(this.regions)) {
            errorList.push("Region is required.");
        }
        if (!_.any(this.branches) && !this.allBranches) {
            errorList.push("Branch is required.");
        }
        return errorList;
    }

    toDataObject() { 
        this.regions = _.toString(this.regions);
        this.branches = _.toString(this.branches);
        return _.toPlainObject(this);
    }
}

export class Ready2CloseConfigurationDto {
    constructor(options) {
        options = options || {};
        this.id = _.parseNumber(options.id, 10);
        this.pollingInterval = _.parseNumber(options.pollingInterval, 10);
        this.active = _.parseBool(options.active, false);
        this.dataPumpUrl = options.dataPumpUrl || null;
        this.ready2CloseUrl = options.ready2CloseUrl || null;
        this.timeZone = options.timeZone || null;
        this.lastPolled = options.lastPolled || null;
        this.dataPumpVersion = options.dataPumpVersion || null;
        this.extensionVersion = options.extensionVersion || null;
    }

    toDataObject() {
        return _.toPlainObject(this);
    }
}
export class ORTEpicorLocationDto {
    constructor(options) {
        options = options || {};
        this.ortEpicorLocationID = _.parseNumber(options.ortEpicorLocationID, 0);
        this.regionID = _.parseNumber(options.regionID, 0)||null;
        this.branchID = _.parseNumber(options.branchID, 0)||null;
        this.seg1 = options.seg1||null;
        this.seg2 = options.seg2||null;
        this.seg3 = options.seg3||null;
        this.seg4 = _.parseNumber(options.seg4, 0)||null;
        this.off1 = options.off1||null;
        this.off2 = options.off2||null;
        this.off3 = options.off3||null;
        this.off4 = _.parseNumber(options.off4, 0)||null;
        this.arOff3 = options.arOff3||null;
        this.defaultState = options.defaultState||null;
        this.stateOverride = options.stateOverride||null;
        this.ref = options.ref||null;
        this.arOff2 = options.arOff2||null;
        this.arRef = options.arRef||null;
        this.useProductSeg4 = _.parseBool(options.useProductSeg4, false);
        this.off4Override = _.parseNumber(options.off4Override, 0)||null;
        this.agentNumber = options.agentNumber||null;
        this.ledgerAccount = options.ledgerAccount||null;
    }
    
    get isNew() { return this.ortEpicorLocationID == 0; }

    toDataObject() {
        return _.toPlainObject(this);
    }
}

export class ORTEpicorProductDto {
    constructor(options) {
        options = options || {};
        this.ortEpicorProductID = _.parseNumber(options.ortEpicorProductID, 0);
        this.regionID = _.parseNumber(options.regionID, 0)||null;
        this.accountingCodeID = _.parseNumber(options.accountingCodeID, 0)||null;
        this.seg1 = options.seg1||null;
        this.seg2 = options.seg2||null;
        this.seg3 = _.parseNumber(options.seg3, 0)||null;
        this.seg4 = _.parseNumber(options.seg4, 0)||null;
        this.SplitPct = _.parseNumber(options.splitPct, 0)||null;
        this.UWPct = _.parseNumber(options.uwPct, 0)||null;
        this.uwSeg1 = options.uwSeg1||null;
        this.uwSeg2 = options.uwSeg2||null;
        this.uwSeg3 = _.parseNumber(options.uwSeg3, 0)||null;
        this.uwSeg4 = _.parseNumber(options.uwSeg4, 0)||null;
        this.off1 = options.off1||null;
        this.off2 = options.off2||null;
        this.off3 = _.parseNumber(options.off3, 0)||null;
        this.off4 = _.parseNumber(options.off4, 0)||null;
        this.uwDesc = options.uwDesc||null;
        this.offDesc = options.offDesc||null;
        this.splitOverride = _.parseBool(options.splitOverride, false);
        this.ignorePremium = _.parseBool(options.ignorePremium, false);
        this.stateOverride = options.stateOverride||null;
        this.naturalGLNo = options.naturalGLNo||null;
        this.spendCode = options.spendCode||null;
        this.revenueCode = options.revenueCode||null;
    }
    
    get isNew() { return this.ortEpicorProductID == 0; }

    toDataObject() {
        return _.toPlainObject(this);
    }
}

export class ORTEpicorBankCodeDto {
    constructor(options) {
        options = options || {};
        this.ortEpicorBankCodeID = _.parseNumber(options.ortEpicorBankCodeID, 0);
        this.regionID = _.parseNumber(options.regionID, 0)||null;
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, 0)||null;
        this.bankCode = _.parseNumber(options.bankCode, 0)||null;
        this.mainBranch = options.mainBranch||null;
        this.off2 = options.off2||null;
    }
    
    get isNew() { return this.ortEpicorBankCodeID == 0; }

    toDataObject() {
        return _.toPlainObject(this);
    }
}

export class ORTEpicorUnderwriterSplitRateDto {
    constructor(options) {
        options = options || {};
        this.ortEpicorUnderwriterSplitRateID = _.parseNumber(options.ortEpicorUnderwriterSplitRateID, 0);
        this.underwriterCompanyID = _.parseNumber(options.underwriterCompanyID, 0)||null;
        this.accountingCodeID = _.parseNumber(options.accountingCodeID, 0)||null;
        this.state = options.state||null;
        this.regionID = _.parseNumber(options.regionID, 0)||null;
        this.rate = _.parseNumber(options.rate, 0)||null;
    }
    
    get isNew() { return this.ortEpicorUnderwriterSplitRateID == 0; }

    toDataObject() {
        return _.toPlainObject(this);
    }
}

export class ORTEpicorValidPayeeDto {
    constructor(options) {
        options = options || {};
        this.ortEpicorValidPayeeID = _.parseNumber(options.ortEpicorValidPayeeID, 0);
        this.payeeCompanyID = _.parseNumber(options.payeeCompanyID, 0)||null;
        this.payeeCompanyName = options.payeeCompanyName||null;
    }
    
    get isNew() { return this.ortEpicorValidPayeeID == 0; }

    toDataObject() {
        return _.toPlainObject(this);
    }
}