<template>
    <rq-section-card v-show="sectionVisible" :title="label" collapsible>
        <div class="row">
            <div class="col-6">
                <div class="row" v-show="fieldVisible('archiveCompanies')">
                    <div class="col">Inactivate Companies</div>
                    <div class="col">
                        <rq-switch type="stop-light"
                            :on="switchOn"
                            :off="switchOff"
                            size="md"
                            automation_id="au_archiveCompanies"
                            v-model="securityData.userGroupSecurity.allowArchiveUnarchiveAgent"
                            :disabled="isArchiveCompaniesReadOnly"
                        />
                    </div>
                </div>
                <div class="row" v-show="fieldVisible('allowAddEditCompany')">
                    <div class="col">Add/Edit Company</div>
                    <div class="col">
                        <rq-switch type="stop-light"
                            :on="switchOn"
                            :off="switchOff"
                            size="md"
                            automation_id="au_allowAddEditCompany"
                            v-model="securityData.userGroupSecurity.allowEditCompanyDetails"
                            :disabled="isAddEditCompanyReadOnly"
                        />
                    </div>
                </div>
                <div class="row" v-show="fieldVisible('allowAddEditContacts')">
                    <div class="col">Add/Edit Contacts</div>
                    <div class="col">
                        <rq-switch type="stop-light"
                            :on="switchOn"
                            :off="switchOff"
                            size="md"
                            automation_id="au_allowAddEditContacts"
                            v-model="securityData.userGroupSecurity.allowEditContacts"
                            :disabled="isAddEditContactsReadOnly"
                        />
                    </div>
                </div>
                <div class="row" v-show="fieldVisible('updateRealtorsInFileEntry')">
                    <div class="col">Update Real Estate Agents in File Entry</div>
                    <div class="col">
                        <rq-switch type="stop-light"
                            :on="switchOn"
                            :off="switchOff"
                            size="md"
                            automation_id="au_updateRealtorsInFileEntry"
                            v-model="securityData.userGroupSecurity.updateRealtorsInOrderEntry"
                            :disabled="isUpdateRealEstateAgentsInFileEntryReadOnly"
                        />
                    </div>
                </div>
                <div class="row" v-show="fieldVisible('selectAlternateVendor')">
                    <div class="col">Select Alternate Vendor</div>
                    <div class="col">
                        <rq-switch type="stop-light"
                            :on="switchOn"
                            :off="switchOff"
                            size="md"
                            automation_id="au_selectAlternateVendor"
                            v-model="securityData.userGroupSecurity.selectAltVendor"
                            :disabled="isSelectAlternativeVendorsReadOnly"
                        />
                    </div>
                </div>
                <div class="row" v-show="fieldVisible('editCompanyOutsideUserRegion')">
                    <div class="col">Edit Companies Outside User’s Region</div>
                    <div class="col">
                        <rq-switch type="stop-light"
                            :on="switchOn"
                            :off="switchOff"
                            size="md"
                            automation_id="au_editCompanyOutsideUserRegion"
                            v-model="securityData.userGroupSecurity.editCompaniesOutsideUsersRegion"
                            :disabled="isEditCompaniesOutsideUsersRegionReadonly"
                        />
                    </div>
                </div>
            </div>
        </div>
    </rq-section-card>
</template>
<script>
    import SecurityRoleSectionMixin from "./SecurityRoleSectionMixin";
    import { mapState } from "vuex";

    export default {
        name: "CompaniesSection",
        mixins: [SecurityRoleSectionMixin],
        data() {
            return {
                sectionTopic: "Companies",
                sectionFields: [
                    { label: "Archive Companies", name: "archiveCompanies", category: "" },
                    { label: "Add/Edit Company", name: "allowAddEditCompany", category: "" },
                    { label: "Add/Edit Contacts", name: "allowAddEditContacts", category: "" },
                    { label: "Update Realtors in File Entry", name: "updateRealtorsInFileEntry", category: "" },
                    { label: "Select Alternate Vendor", name: "selectAlternateVendor", category: "" },
                    { label: "Edit Companies Outside User’s Region", name: "editCompanyOutsideUserRegion", category: "" }
                ]
            };
        },
        computed: {
            ...mapState({
                globalRegionId: state => state.system.globalRegionId,
            }),
            regionId() { return _.getNumber(this, "roleSecurityData.userGroup.regionID", 0) },
            isArchiveCompaniesReadOnly(){ return this.isSysAdmin || this.isAdmin; },
            isAddEditCompanyReadOnly(){ return this.isSysAdmin || this.isAdmin; },
            isAddEditContactsReadOnly(){ return this.isSysAdmin || this.isAdmin; },
            isUpdateRealEstateAgentsInFileEntryReadOnly(){ return this.isSysAdmin || this.isAdmin; },
            isSelectAlternativeVendorsReadOnly(){ return this.isSysAdmin || this.isAdmin; },
            isEditCompaniesOutsideUsersRegionReadonly(){ return ((this.isSysAdmin || this.isAdmin) && this.regionId === this.globalRegionId) || !this.securityData.userGroupSecurity.allowEditCompanyDetails; }
        },
        watch: {
            isSysAdmin: function(newValue){
                this.setDefaultValues();
            },
            isAdmin: function(newValue){
                this.setDefaultValues();
            },
            isEscrowAdmin: function(newValue){
                this.setDefaultValues();
            },
            "securityData.userGroupSecurity.allowEditCompanyDetails": function(newValue){
                if(!newValue){
                    this.securityData.userGroupSecurity.editCompaniesOutsideUsersRegion = false;
                }
            }
        },
        methods:{
            setDefaultValues(){
                const self = this;
                // Admin
                if(self.isSysAdmin || self.isAdmin){
                    self.securityData.userGroupSecurity.allowArchiveUnarchiveAgent = true; 
                    self.securityData.userGroupSecurity.allowEditCompanyDetails = true;
                    self.securityData.userGroupSecurity.allowEditContacts = true;
                    self.securityData.userGroupSecurity.updateRealtorsInOrderEntry = true;
                    self.securityData.userGroupSecurity.selectAltVendor = true;
                    self.securityData.userGroupSecurity.editCompaniesOutsideUsersRegion = true;
                }

                // Escrow Admin
                if(self.isEscrowAdmin && !self.isSysAdmin && !self.isAdmin){
                    self.securityData.userGroupSecurity.allowArchiveUnarchiveAgent = false; 
                    self.securityData.userGroupSecurity.allowEditCompanyDetails = false;
                    self.securityData.userGroupSecurity.allowEditContacts = false;
                    self.securityData.userGroupSecurity.updateRealtorsInOrderEntry = false;
                    self.securityData.userGroupSecurity.selectAltVendor = false; 
                    self.securityData.userGroupSecurity.editCompaniesOutsideUsersRegion = false;       
                }
            },
        }
    }
</script>