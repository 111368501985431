<template>
    <rqdx-action-data-grid
        ref="dataGrid"
        automation_id="tbl_clause_sections"
        class="tab-header"
        :actions="selectionActions"
        :config="gridConfig"
        :data-source="gridDataSource"
        @edit="onEditItem"
        @rowDoubleClick="onEditItem"
        hide-search
        hide-export
        rq-filters
    />
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { StandardLanguageSectionDto, StandardLanguageSectionCategoryDto }  from "../../models";
    import { DxGridUtils } from "@/shared/utilities";
    import GridInvokerMixin from "@/shared/mixins/GridInvokerMixin";

    import StandardLanguageSectionForm from "./StandardLanguageSectionForm";

    export default {
        name:"StandardLanguageSectionList",

        props: {
            categories: { type: Array, default: () => [] },
            categorySections: { type: Array, default: () => [] }
        },

        mixins: [ GridInvokerMixin({ grid:"dataGrid" }) ],

        data() {
            return {
                items: [],
                //customTabs: [],
            };
        },

        computed: {
            ...mapState({
                systemDefaults: state => state.system.systemDefaults,
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            selectionActions() {
                return [
                    { name: "edit", text: "Edit", eventName: "edit", requireSelection: true, tooltip: "Edit Section" },
                    // {
                    //     name: "delete",
                    //     text: "Delete",
                    //     eventName: "delete",
                    //     allowMultiSelection: true,
                    //     tooltip: "Delete Section",
                    //     disabled: (e) => _.some(e.data, item => _.parseNumber(item.titleProdCustomTabID, 0) === 0)
                    //         ? "System sections cannot be deleted."
                    //         : _.some(e.data, item => !item.canDelete) ? "One or more of the selected sections are in use" : false
                    // }
                ];
            },
            noUnassignedCategories() { return _.every(this.categories, "sectionsApplied"); }
        },

        created() {
            this.initGridConfig();
        },

        methods: {
            fetchData () {
                const self = this;
                let apiPromise = self.$api.StandardLanguageApi.getSections();
                return self.$rqBusy.wait(apiPromise)
                    .then(self.mapData)
                    .catch(error => {
                        self.$toast.error("Error loading sections.");
                        console.error(error);
                        return error;
                    });
            },

            mapData(sectionData) {
                const self = this;
                self.items = _.map(sectionData, item => {
                    let sectionLabel = item.sectionLabel;
                    if(_.has(StandardLanguageSectionDto.systemDefaultFields, item.name)) {
                        let systemDefaultField = _.lowerFirst(StandardLanguageSectionDto.systemDefaultFields[item.name]);
                        sectionLabel = self.systemDefaults[systemDefaultField];
                    }
                    return self.setSectionCategories(new StandardLanguageSectionDto({ ...item, sectionLabel }));
                });
                self.refresh();
                return self.sections;
            },

            setSectionCategories(item) {
                const self = this;
                let sectionCategories = _.filter(self.categorySections, sc => sc.standardLanguageSectionID === item.id);
                if(_.isEmpty(sectionCategories)) return item;
                item.categoriesApplied = true;
                item.categories = _.map(sectionCategories, sc => new StandardLanguageSectionCategoryDto(sc));
                _.forEach(item.categories, cat => {
                    cat.updateCategoryInfo(self.categories);
                    if(!cat.isDefault) return;
                    item.defaultCategoryId = cat.standardLanguageCategoryID;
                });
                return item;
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "description",
                            dataType: "string",
                            cellTemplate(cellElement, cellInfo) {
                                let $container = $("<div />")
                                    .addClass("d-flex justify-content-between")
                                    .append($("<span/>").text(cellInfo.value))
                                    .appendTo(cellElement);
                                if(!_.isEmpty(cellInfo.row.data.categories) || !self.noUnassignedCategories) return;
                                $("<span/>")
                                    .addClass("badge badge-pill badge-grid badge-danger")
                                    .text("No Available Categories")
                                    .appendTo($container);
                            }
                        },
                        {
                            dataField: "sectionLabel",
                            dataType: "string"
                        },
                        {
                            dataField: "categoriesApplied",
                            caption: "Categories Applied",
                            alignment: "center",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            width: 200
                        },
                        {
                            dataField: "isCustom",
                            caption: "Custom",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            visible: false
                        }
                    ]
                };

                self.gridDataSource = {
                    key: "id",
                    load: () => _.isEmpty(self.items)
                            ? self.fetchData()
                            : Promise.resolve(self.items)
                };
            },

            onEditItem(e) {
                this.launchFormDialog(e.data);
            },

            //Commenting out until re-implementation of custom tabs/sections
            // onDeleteItems(e) {
            //     const self = this;
            //     self.$dialog.confirm("Confirm", "Delete selected sections?", () => {
            //         let itemKeys = _.map(e.data, item => item.id);
            //         self.deleteItems(itemKeys);
            //         return true;
            //     });
            // },

            // deleteItems(keys) {
            //     const self = this;
            //     let apiPromise = self.$api.TitleProdCustomTabsApi.deleteSections(keys);
            //     return self.$rqBusy.wait(apiPromise)
            //         .then(() => {
            //             self.removeItems(keys);
            //             self.$emit("refresh");
            //             self.$toast.success("Clause sections successfully deleted.");
            //             return true;
            //         })
            //         .catch(error => {
            //             self.$toast.error("Error deleting clause section(s).");
            //             console.error(error);
            //             return false;
            //         });
            // },

            updateItem(itemData, refresh=true) {
                const self = this;
                let itemInstance = new StandardLanguageSectionDto(itemData, self.categoryData);
                let itemIndex = _.findIndex(self.items, { id: itemInstance.id });
                self.items[itemIndex] = itemInstance;
                self.mapData();
                self.$emit("refresh");
            },

            removeItems(keys) {
                const self = this;
                if(!_.isArray(keys) || _.isEmpty(keys)) return;
                _.forEach(keys, key => {
                    let itemIndex = _.findIndex(self.items, item => item.id === key);
                    if(itemIndex < 0) return;
                    self.items.splice(itemIndex, 1);
                });
            },

            launchFormDialog(item=null) {
                const self = this;

                let dialogId = null;
                let onOk = e => {
                    return e.component.save()
                        .then(result => {
                            if(!result.isValid) return false;                            
                            self.updateItem(result.data);                           
                            return true;
                        });
                };

                let isNew = _.isNil(item);
                dialogId = self.$dialog.open({
                    title: "Edit Standard Language Section",
                    height: 700,
                    width: 1000,
                    scrollable: false,
                    component: StandardLanguageSectionForm,
                    props: {
                        item,
                        //customTabs: self.customTabs,
                        categories: self.categories,
                        uniqueValidator: (item, field) => !self.isDuplicate(item, field)
                    },
                    onOk
                });
            },

            isDuplicateLabel(key, val){
                const self = this;
                return _.some(self.items, item => _.trimLower(item.sectionLabel) === _.trimLower(val) && _.parseNumber(item.id, -1) !== key);
            },

            refresh() {
                this.invokeGridMethod("option", "focusedRowIndex", -1);
                this.invokeGridMethod("clearSelection");
                this.invokeGridMethod("refresh");
            },

            updateDimensions() {
                this.$nextTick(() => {
                    this.invokeGridMethod("updateDimensions");
                    this.invokeGridMethod("repaint");
                });
            }
        }
    }
</script>
