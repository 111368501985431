import ThirdPartyCompanies from "./ThirdPartyCompanies";
import Routes from "./routes";
import { Categories } from "../../categories";
import { Topics } from "../../../topics";

let thirdPartyModule = {
    path: "third-party-companies",
    component: ThirdPartyCompanies,
    meta: {
        label: "Third Party Companies",
        itemKey: "",
        itemTypeName: "Third Party Company",
        itemTypeDescription: "Edit external company details here",
        skipSaveOnLeave: true,
        topic: Topics.Main,
        category: Categories.Companies
    },
    children: Routes
};

export default thirdPartyModule;