<template>
    <rq-section-card v-show="sectionVisible" :title="label" collapsible>
        <div class="row">
            <div class="col-6">
                <div class="row" v-show="fieldVisible('adminSetting')">
                    <div class="col">Administrator</div>
                    <div class="col">
                        <rq-switch type="stop-light"
                            :on="switchOn"
                            :off="switchOff"
                            size="md"
                            automation_id="au_adminSetting"
                            v-model="securityData.userGroupSecurity.isAdmin"
                        />
                    </div>
                </div>
                <div class="row" v-show="fieldVisible('escrowAdminSetting')">
                    <div class="col">Escrow Administrator</div>
                    <div class="col">
                        <rq-switch type="stop-light"
                            :on="switchOn"
                            :off="switchOff"
                            size="md"
                            automation_id="au_escrowAdminSetting"
                            :disabled="isEscrowAdministratorReadOnly"
                            v-model="securityData.userGroupSecurity.isEscrowAdmin"
                        />
                    </div>
                </div>
            </div>
        </div>
    </rq-section-card>
</template>
<script>
    import SecurityRoleSectionMixin from "./SecurityRoleSectionMixin";

    export default {
        name: "AdministrationSection",
        mixins: [SecurityRoleSectionMixin],
        data() {
            return {
                sectionTopic: "Administration",
                sectionFields: [
                    { label: "Administrator", name: "adminSetting", category: "" },
                    { label: "Escrow Administrator", name: "escrowAdminSetting", category: "" },
                ]
            };
        },
        computed: {
            isEscrowAdministratorReadOnly(){ return this.isSysAdmin || this.isAdmin; }
        },
        watch:{
            isAdmin: function(newValue){
                this.setDefaultValues();
            }
        },
        methods:{
            setDefaultValues(){
                const self = this;
                if(self.isAdmin){
                    self.securityData.userGroupSecurity.isEscrowAdmin = true;
                }
            }
        }
    }
</script>