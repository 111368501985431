<template>
    <div class="rq-container content-wrapper">
        <workflow-task-packages-list
            ref="workflowTaskPackagesListComponent"
            automation_id="dg_workflowTaskPackageList"
            fixed-header
            :isInDialog="true"
        />
    </div>
</template>

<script>
    import WorkflowTaskPackagesList from "@configuration/workflowDepartments/components/WorkflowTaskPackagesList";


    export default {
        name: "WorkflowTaskPackageDialog",
        mixins: [],
        props:{
        },
        components: { WorkflowTaskPackagesList },
        data(){

        },
        computed: {

        },
        created() {

        },
        methods:{
            selectItems(e) {
                const self = this;
                return _.invoke(self, '$refs.workflowTaskPackagesListComponent.selectItems', e);
            }
        }
    }
</script>