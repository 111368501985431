import IntegrationsMain from "./IntegrationsMain.vue";
import ConfigNav from "../shared/ConfigNav.vue";
import Routes from "./routes";
import { Categories } from "./categories";

export default {
    path: "/configuration/integrations",
    component: IntegrationsMain,
    meta: {
        label: Categories.Integrations,
        include: true,
        skipSaveOnLeave: true
    },
    children: [{
            name: "cfg:integrations",
            path: "",
            component: ConfigNav,
            props: {routes: Routes, category: Categories.Integrations},
            meta: {
                label: Categories.Integrations,
                skipSaveOnLeave: true
            }
        },
        ...Routes
    ]
};