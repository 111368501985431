const WorkflowAutomationWrapper = () => import(/* webpackChunkName: "workflow-automation" */"@/integrations/wrappers/WorkflowAutomationWrapper.vue");

let routes = [
    {
        name: "cfg:automation:workflow-automation",
        path: "workflow-automation",
        component: WorkflowAutomationWrapper,
        meta: {
            label: "Automation Builder",
            itemKey: "",
            itemTypeName: "",
            itemTypeDescription: "Integrated Workflow Automation utilities.",
            topic: "Automation",
            category: "Workflow",
            featureFlag: "workflowAutomation",
            sysAdminRequired: true
        }
    },
];

export default routes;

