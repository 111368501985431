<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            @edit="onEditItem"
            @delete="onDeleteItem"
            @rowDoubleClick="onEditItem"
            @filter-change="onGridFilterChange"
            integrated-search
            rq-filters
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { BranchDto }  from "@/shared/models/models";
    import BranchForm  from "./BranchForm.vue";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { REFRESH_LOOKUP } from "@/store/actions";

    export default {
        name:"BranchList",

        data () {
            return {
                addEventName: "",
                addMode: false,
                stateFilterValue: [],
                items: []
            };
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user
            }),
             ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            itemKey() { return _.get(this.$route.meta, "itemKey") || ""; },
            itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            selectionActions() {
                return [{ name: "edit", text: "Edit", eventName: "edit", requireSelection: true, tooltip: `Edit ${this.itemTypeName}` },
                        { name: "delete", text: "Delete", eventName: "delete", requireSelection: true, allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}` }];
            },
            regions() { return this.lookupHelpers.getRegions(); },
            stateDataSource() { return this.lookupHelpers.getStates(); }
        },

        created(){
            const self = this;
            self.initGridConfig();
            self.initListeners();
        },

        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },

        methods: {

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            onAddItem() {
                const self = this;
                self.loadItem();
            },

            onEditItem(e) {
                const self = this;
                self.loadItem(e.data);
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let apiPromises = [];
                    _.forEach(items, (item) => {
                        let key = _.get(item, self.itemKey);
                        apiPromises.push(self.$api.BranchesApi.deleteBranch(key));
                    });
                    return self.$rqBusy.wait(Promise.all(apiPromises))
                        .then(key => {
                            let message = key.length > 1 ? `${key.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success(message);
                            return true;}
                        )
                        .catch(error => {
                          if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                 self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error(`Error deleting ${self.itemTypeName}.`);
                            }
                            return error;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }

                 self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "regionID",
                            caption: "Region",
                            dataType: "number",
                            calculateSortValue: DxGridUtils.regionDisplaySortValue,
                            lookup: {
                                loadMode: "raw",
                                dataSource: self.regions,
                                displayExpr: "displayName",
                                valueExpr: "regionID"
                            }
                        },
                        {
                            dataField: "name",
                            caption: "Branch Name",
                            dataType: "string"
                        },
                        {
                            dataField: "description",
                            dataType: "string"
                        },
                        {
                            dataField: "state",
                            caption: "State",
                            dataType: "string",
                            lookup: {
                                displayExpr: "id",
                                valueExpr: "id",
                                dataSource: self.stateDataSource
                            }
                        },
                        {
                            dataField: "countyID",
                            caption: "County",
                            dataType: "number",
                            calculateDisplayValue: "countyName",
                            alignment: "left",
                            rqFilter: {
                                displayExpr: "name",
                                valueExpr: "id",
                                dataSource: {
                                    load() {
                                        return _.isEmpty(self.stateFilterValue)
                                            ? []
                                            : self.lookupHelpers.getCountiesInStates(self.stateFilterValue);
                                    }
                                },
                                inputDisabled: () => _.isEmpty(self.stateFilterValue),
                                inputDisabledMessage: "State filter must be selected before filtering by county.",
                                filterType: "tags"
                            }
                        },
                        {
                            dataField: "reportCode",
                            caption: "Report Codes",
                            dataType: "string"
                        },
                        {
                            dataField: "filePoolName",
                            dataType: "string"
                        },
                        {
                            dataField: "workflowProcessTemplateName",
                            caption: "Process Template",
                            dataType: "string"
                        },
                        {
                            dataField: "duplicateOrderSearchSetupName",
                            caption: "Duplicate File Search",
                            dataType: "string"
                        },
                        {
                            dataField: "titleCompanyName",
                            caption: "Title Company",
                            dataType: "string"
                        },
                        {
                            dataField: "underwriterCompanyName",
                            caption: "Underwriter",
                            dataType: "string"
                        },
                        {
                            dataField: "closingPlaceCompanyName",
                            caption: "Place of Closing",
                            dataType: "string"
                        }
                    ],
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load: self.fetchData
                };
            },

            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },

            fetchData() {
                const self = this;
                let apiPromise = self.$api.BranchesApi.getBranches();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$store.dispatch(REFRESH_LOOKUP, { lookupKeys: self.lookupItems.BRANCHES, lookupData: result });
                        self.items = _.map(result, i => new BranchDto(i));
                        self.parseInitialStateFilter();
                        return result;
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },

            loadItem(itemData=null) {
                const self = this;
                let refreshOnExit = false;
                let dialogId = null;
                let onOk = (e, addAnother=false) => {
                    return e.component.save()
                        .then(result => {
                            if (!result) return false;
                            if (addAnother) {
                                self.$dialog.reloadComponent({ dialogId, props: { item: new BranchDto({regionID: self.user.regionID}) } });
                                refreshOnExit = true;
                                return false;
                            }
                            self.$nextTick(() => {
                                self.refresh();
                            });
                            return true;
                        });
                };
                let onCancel = e => {
                    if(!refreshOnExit) return;
                    self.refresh();
                };

                let item = new BranchDto(itemData);
                dialogId = self.$dialog.open({
                    title: (item.isNew ? "Add" : "Edit") + " " + self.itemTypeName + (item.isNew ? "" : `: ${item.description}`) ,
                    height: 670,
                    width: 1000,
                    resizable: true,
                    component: BranchForm,
                    props: {
                        item,
                        uniqueValidator: (item,field) => !self.isDuplicate(item, field)
                    },
                    buttons: [
                        { title: "Cancel", automationId: "btn_dm_modal_cancel", cssClass: "btn btn-secondary", onClick: onCancel },
                        { title: "Save and Add Another", automationId: "btn_dm_modal_save_and_another", cssClass: "btn btn-primary", isVisible: item.isNew, onClick: (e) => { return onOk(e, true); } },
                        { title: "Save", automationId: "btn_dm_modal_save", cssClass: "btn btn-primary", onClick: (e) => { return onOk(e, false); } }
                    ]
                });
            },

            isDuplicate(item, fieldName){
                const self = this;
                let trimLower = val => _.toLower(_.trim(val));
                return _.some(self.items, (i) =>
                    trimLower(i[fieldName]) === trimLower(item[fieldName])
                    && _.parseNumber(item.regionID, -1) === _.parseNumber(i.regionID, -1)
                    && _.parseNumber(i[self.itemKey], -1) !== _.parseNumber(item[self.itemKey], -1)
                );
            },

            refresh() {
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },

            parseInitialStateFilter() {
                let filterExpr = _.invoke(this, "$refs.dataGrid.gridInstance.option", "filterValue");
                this.parseStateFilter(filterExpr);
            },

            parseStateFilter(filterExpr) {
                let filterValue = [];
                let parseFilter = expr => {
                    _.forEach(expr, (item, index, arr) => {
                        if(_.isArray(item)) {
                            parseFilter(item);
                        }
                        else if(item === "state") {
                            filterValue.push(arr[2]);
                        }
                    });
                }
                parseFilter(filterExpr);
                this.stateFilterValue = filterValue;
            },

            onGridFilterChange(e) {
                this.parseStateFilter(e.filterExpr);
            }
        }
    }
</script>
