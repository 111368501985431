<template>
    <div id="company-fix-form" class="content-wrapper">
        <rq-banner
            variant="error"
            :title="selectionErrorMessage"
            icon="fas fa-exclamation-triangle"
            :visible="showErrorMessage"
            dismissable
        />
        <rq-page-section :title="`Company Corrections`" headerSize="lg" borderless>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
            <div class="row">
                <div class="col col-12 col-lg-6 col-pc-3 form-group mb-0">
                    <label class="form-control-label" for="txt_company_contact_lookup_search" >Search Companies:</label>
                    <rq-search-input-group
                        id="txt_company_contact_lookup_search"
                        placeholder="Search..."
                        variant="theme"
                        :min-search-text-length="minSearchTermLength"
                        v-model="searchTerm"
                        @search="search"
                        @clear="search"
                        show-search-button
                        search-on-enter
                        v-focus.input
                    />
                </div>
                <div class="col col-12 col-lg-6 col-pc-3 form-group mb-0">
                    <label for="tag_region_filter">Filter Region(s):</label>
                    <dx-tag-box
                        :element-attr="{ automation_id: 'tag_region_filter', id: 'tag_region_filter' }"
                        class="form-control"
                        :data-source="userAccessibleRegions"
                        display-expr="description"
                        value-expr="regionID"
                        tag-template="tag-display"
                        item-template="item-display"
                        :show-selection-controls="true"
                        :show-clear-button="true"
                        :show-drop-down-button="true"
                        apply-value-mode="useButtons"
                        :search-enabled="true"
                        v-model="regionFilterValue">
                        <template #tag-display="{ data }">
                            <div class="dx-tag-content">
                                {{data.regID}} - {{data.description}}
                                <div class="dx-tag-remove-button"></div>
                            </div>
                        </template>
                        <template #item-display="{ data }">
                            {{data.regID}} - {{data.description}}
                        </template>
                    </dx-tag-box>
                </div>
            </div>
        </rq-page-section>
        <div class="rq-master-detail-container">
            <section class="rq-container">
                <div class="row flex-grow-1 mh-100">
                    <div class="col col-12 col-lg-6 d-flex mh-100">
                        <rqdx-action-data-grid
                            title="Available Companies"
                            title-size="sm"
                            ref="companyGrid"
                            automation_id="tbl_companies"
                            :data-source="companyDataSource1"
                            :config="companyGridConfig"
                            @mouseout="onMasterGridMouseOut"
                            hide-show-column-chooser
                            hide-clear-filters
                            hide-search>
                            <template #toolbar>
                                <ul class="nav">
                                    <li class="nav-item">
                                        <b-btn automation_id="btn_add_company" variant="theme" @click="onAddCompany">Add Company</b-btn>
                                    </li>
                                </ul>
                            </template>
                        </rqdx-action-data-grid>
                    </div>
                    <div class="col col-12 col-lg-6 d-flex mh-100">
                        <rqdx-action-data-grid
                            title="Replace With"
                            title-size="sm"
                            ref="replacementGrid"
                            automation_id="tbl_replacements"
                            :data-source="companyDataSource2"
                            :config="replaceWithGridConfig"
                            @mouseout="onMasterGridMouseOut"
                            hide-show-column-chooser
                            hide-clear-filters
                            hide-search>
                            <template #toolbar>
                                <ul class="nav">
                                    <li class="nav-item">
                                        <b-btn automation_id="btn_add_to_batch" variant="theme" @click="onAddAction" :disabled="addDisabled">Add to Batch </b-btn>
                                    </li>
                                </ul>
                            </template>
                        </rqdx-action-data-grid>
                    </div>
                </div>
            </section>
            <section class="mt-2">
                <rqdx-action-data-grid
                    title="Batch Replacement"
                    title-size="sm"
                    ref="batchGrid"
                    automation_id="tbl_batch"
                    :data-source="batchItems"
                    :config="batchGridConfig"
                    :actions="batchSelectionActions"
                    :persist-state="false"
                    @action="onAction"
                    hide-clear-filters
                    hide-search>
                    <template #toolbar>
                        <ul class="nav">
                            <li class="nav-item">
                                <b-btn automation_id="btn_execute" variant="theme" @click="onExecuteBatch" :disabled="batchIsEmpty">Process Batch</b-btn>
                            </li>
                        </ul>
                    </template>
                </rqdx-action-data-grid>
            </section>
        </div>
        <address-detail-popover
            title="Company Information"
            :target="addressPopover.target"
            container="company-fix-form"
            :display-number="addressPopover.detail.companyID"
            :name="addressPopover.detail.name"
            :address1="addressPopover.detail.address1"
            :address2="addressPopover.detail.address2"
            :city="addressPopover.detail.city"
            :state="addressPopover.detail.state"
            :zip="addressPopover.detail.zip"
            v-model:visible="addressPopover.visible"
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { SearchRequest, SortOption } from "@/shared/models/models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import CompanyEdit from "@utilities/manage-companies/components/CompanyEdit";
    import AddressDetailPopover from "@/shared/components/rq/AddressDetailPopover";

    export default {
        name: "CompanyCorrections",
        components: { AddressDetailPopover },
        data() {
            return {
                searchTerm: "",
                regionFilterValue: [],
                minSearchTermLength: 3,
                searchResult: [],
                ignoreSelectionChange: false,
                batchItems: [],
                selectedBatch: [],
                selectedCompany: {},
                selectedReplacement: {},
                selectionErrorMessage: "",
                selectedRegionId: null,
                addressPopover: { visible: false, target: null, detail: {} }
            };
        },

        computed: {
            ...mapState({
                userAccessibleRegions: state => state.authentication.session.regions,
                globalRegionId: state => state.system.globalRegionId,
            }),
            companyGridInstance() { return this.$refs.companyGrid.getGridInstance(); },
            replacementGridInstance() { return this.$refs.replacementGrid.getGridInstance(); },
            batchGridInstance() { return this.$refs.batchGrid.getGridInstance(); },
            itemTypeDescription() { return _.get(this.$route.meta, "itemTypeDescription") || ""; },
            targetSelected() { return !_.isEmpty(this.selectedCompany); },
            replacementSelected() { return !_.isEmpty(this.selectedReplacement); },
            addDisabled() { return !this.targetSelected || !this.replacementSelected || !_.isEmpty(this.selectionErrorMessage); },
            batchIsEmpty() { return _.isEmpty(this.batchItems); },
            showErrorMessage() { return !_.isEmpty(this.selectionErrorMessage); },
            hasGlobalRegion() { return _.some(this.userAccessibleRegions, { regionID: this.globalRegionId }); },
        },

        watch: {
            selectedRegionId(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.repaintCompanyGrids();
            },
            regionFilterValue(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.$nextTick(() => {
                    this.clearCompanyGridSelections();
                    this.refreshCompanyGrids();
                });
            }
        },

        created() {
            this.initGridConfig();
        },

        methods: {
            initGridConfig(){
                const self = this;
                // self.replaceSelectionActions = [
                //     { name: "add-batch", text: "Add to Batch", requireSelection: true },
                //     { name: "replace-batch", text: "Replace Target Company", requireSelection: true }
                // ];
                self.batchSelectionActions = [
                    { name: "delete-from-batch", text: "Delete", allowMultiSelection: true }
                ];

                let gridConfig = {
                    columns: [
                        { dataField: "companyID", caption:"Company Id" },
                        {
                            dataField: "name",
                            caption:"Company Name",
                            cellTemplate: DxGridUtils.infoIconCellTemplate({
                                handlers: {
                                    mouseover(cellElement, cellInfo, e) {
                                        self.updateAddressPopover(cellInfo.data, e.target);
                                    },
                                    mouseout(cellElement, cellInfo, e) {
                                        self.updateAddressPopover();
                                    }
                                }
                            })
                        },
                        { dataField: "regionDisplay", caption:"Region" }
                    ],
                    columnMinWidth: 125,
                    noDataText: "No matching companies found.",
                    focusedRowEnabled: false,
                    selection: {
                        mode: "multiple",
                        allowSelectAll: false
                    },
                    remoteOperations: { paging: true, sorting: true },
                    onEditorPreparing(e) {
                        if (e.command !== "select" || e.parentType !== "dataRow" || !e.row) return;
                        e.editorOptions.disabled = _.gt(self.selectedRegionId, 0) && self.selectedRegionId !== _.getNumber(e, "row.data.regionID", 0);
                    }
                };
                let gridDataSource = {
                    key: "companyID",
                    load: self.fetchCompanyData
                };

                self.companyGridConfig = { ...gridConfig, onSelectionChanged: self.onGridSelectionChange1 };
                self.replaceWithGridConfig = { ...gridConfig, onSelectionChanged: self.onGridSelectionChange2 };
                self.companyDataSource1 = _.clone(gridDataSource);
                self.companyDataSource2 = _.clone(gridDataSource);

                let companyLabelClass = "d-inline-block font-weight-bold text-uppercase me-2";
                let batchCompanyCellTemplate = function(cellElement, cellInfo, addressKey) {
                    let $nameElement = $("<div />")
                        .append($("<span/>").addClass(companyLabelClass).append(cellInfo.text))
                    let $addressElement = $("<div />")
                        .append($("<span/>").text(cellInfo.data[addressKey]));
                    cellElement
                        .append($nameElement)
                        .append($addressElement);
                };

                self.batchGridConfig = {
                    keyExpr: "clientKey",
                    columns: [
                        {
                            caption: "Original",
                            columns: [
                                { dataField: "companyID", caption:"Company ID", alignment: "left", width:"10%" },
                                {
                                    dataField: "companyName",
                                    caption:"Company",
                                    width:"40%",
                                    cellTemplate: (cellElement, cellInfo) => batchCompanyCellTemplate(cellElement, cellInfo, "companyAddress")
                                }
                            ]
                        },
                        {
                            caption: "Replace With",
                            columns: [
                                { dataField: "replaceWithID", caption:"Company ID", alignment: "left", width: "10%" },
                                {
                                    dataField: "replaceWithName",
                                    caption:"Company",
                                    width: "40%",
                                    cellTemplate: (cellElement, cellInfo) => batchCompanyCellTemplate(cellElement, cellInfo, "replaceWithAddress")
                                }
                            ]
                        }
                    ],
                    noDataText: "Select a target and replacement company then select \"Add\" from \"Batch Operations\".",
                    focusedRowEnabled: false,
                    onSelectionChanged: self.onBatchGridSelectionChanged
                };
            },

            updateAddressPopover(item=null, target=null) {
                const self = this;
                if(self.addressPopover.visible)
                    self.addressPopover = { visible: false, target: null, detail: {} };
                if(_.isNil(target) || _.isNil(item)) return;
                let detail = _.pick(item, ["companyID", "name","address1","address2","city","state","zip"]);
                self.$nextTick(()=>{ self.addressPopover = { target, detail, visible: true }; });
            },

            onMasterGridMouseOut(e) {
                this.addressPopover = { visible: false, target: null, detail: {} };
            },

            onGridSelectionChange1(e) {
                const self = this;
                if(self.ignoreSelectionChange) return;
                self.selectionErrorMessage = "";

                let targetKey = e.currentSelectedRowKeys[0];
                let selection = _.find(e.selectedRowsData, { companyID:targetKey }) || {};

                let currentGridId = e.component.option("wrapperAttr.automation_id");
                if(currentGridId === "tbl_companies")
                    self.selectedCompany = selection;
                else
                    self.selectedReplacement = selection;

                self.ignoreSelectionChange = true;
                self.ignoreSelectionChange = false;

                self.$nextTick(() => {
                    self.validateSelections();
                });
            },

            onGridSelectionChange2(e) {
                const self = this;
                if(self.ignoreSelectionChange) return;
                self.selectionErrorMessage = "";

                let targetKey = e.currentSelectedRowKeys[0];
                let selection = _.find(e.selectedRowsData, { companyID:targetKey }) || {};

                let currentGridId = e.component.option("wrapperAttr.automation_id");
                if(currentGridId === "tbl_companies")
                    self.selectedCompany = selection;
                else
                    self.selectedReplacement = selection;

                self.ignoreSelectionChange = true;
                e.component.selectRows(targetKey, false);
                self.ignoreSelectionChange = false;

                self.$nextTick(() => {
                    self.validateSelections();
                });
            },

            fetchCompanyData(loadOptions) {
                const self = this;

                // If a filter is selected (which is downstream from what the user is ABLE to edit), use it.
                // Otherwise, if a user is not associated to region 0, we would filter them out.
                let filter = !_.isEmpty(this.regionFilterValue)
                    ? ["RegionID", "IN", this.regionFilterValue.slice()]
                    : self.hasGlobalRegion
                        ? null
                        : ["RegionID", "!=", self.globalRegionId];

                let request = new SearchRequest({
                    searchTerm: self.searchTerm,
                    filter: filter,
                    parameters: { activeOnly: true }
                });
                request.parseLoadOptions(loadOptions);
                let apiPromise = self.$api.CompaniesApi.search(request);
                return self.$rqBusy.wait(apiPromise)
                    .then(response => {
                        return {
                            data: response.results,
                            totalCount: response.totalRecords
                        };
                    }).catch(err => {
                        console.error(err);
                        return { data: [], totalCount: 0 };
                    });
            },

            onAction(e) {
                switch(e.action.name) {
                    // case "add-batch":
                    //     this.addToBatch();
                    //     break;
                    // case "replace-batch":
                    //     this.replaceBatch();
                    //     break;
                    case "delete-from-batch":
                        this.removeFromBatch(e.data);
                        break;
                }
            },

            onAddAction() {
                this.addToBatch();
            },

            onAddCompany(){
                const self = this;
                self.$dialog.open({
                    title: "Add Company",
                    height: "85%",
                    width: "85%",
                    adaptive: true,
                    component: CompanyEdit,
                    props: {
                        modalMode: true
                    },
                    onOk (e) {
                        return e.component.modalSave();
                    },
                    onCancel (e) { return true;}
                });
            },

            onExecuteBatch(){
                const self = this;
                if (self.batchIsEmpty){
                    self.selectionErrorMessage = "No items available in the current batch.";
                    return false;
                }
                let saveRequest = { data: self.batchItems };
                let apiPromise = self.$api.CompaniesApi.fix(saveRequest);
                return self.$rqBusy.wait(apiPromise)
                    .then(response => {
                        self.batchItems = [];
                        self.companyGridInstance.refresh();
                        self.replacementGridInstance.refresh();
                        self.$toast.success("Companies have been successfully corrected.");
                        return;
                    }).catch(error => {
                        console.error(error);
                        self.$toast.error("Batch operation failed.");
                    });
            },

            addToBatch(){
                const self = this;
                if(!self.validateSelections()) return;
                let selectedCompanies = _.invoke(self, "$refs.companyGrid.gridInstance.getSelectedRowsData");
                let batchItems = _.map(selectedCompanies, c => new BatchItem(c, self.selectedReplacement));
                self.batchItems.push(...batchItems);
                self.resetSelections();
            },

            removeFromBatch(items){
                const self = this;
                if(!_.isArray(items) || _.isEmpty(items)) return;
                _.forEach(items, delItem => {
                    let itemIndex = _.findIndex(self.batchItems, item => item.clientKey === delItem.clientKey);
                    if(itemIndex >= 0) self.batchItems.splice(itemIndex, 1);
                });
                self.batchGridInstance.refresh();
            },

            validateSelections() {
                const self = this;
                if(_.isEmpty(self.selectedCompany) || _.isEmpty(self.selectedReplacement)) return;
                self.selectionErrorMessage = "";

                //var invalidRegion = self.selectedCompany.regionID !== self.selectedReplacement.regionID;
                var invalidRegion =  _.findIndex(self.$refs.companyGrid.selectedRows, item => item.regionID !== self.selectedReplacement.regionID) > -1;

                if (invalidRegion) {
                    self.selectionErrorMessage = "Companies must be assigned to the same region.";
                    return false;
                }

                if (_.findIndex(self.$refs.companyGrid.selectedRows, item => item.companyID === self.selectedReplacement.companyID)  > -1) {
                    self.selectionErrorMessage = "The company to replace must be a different company.";
                    return false;
                }

                let batchCompanyIDs = _.uniq(_.flatMap(self.batchItems, item => [item.companyID, item.replaceWithID]));
                let existsInBatch = _.includes(batchCompanyIDs, self.selectedCompany.companyID)
                    || _.includes(batchCompanyIDs, self.selectedReplacement.companyID);

                if (existsInBatch){
                    self.selectionErrorMessage = "One of the selected companies already exists in the current batch.";
                    return false;
                }

                // Check if the user was attempting to modify global region without being associated to it.
                // This should NOT happen but just a general safety measure in case we erroneously ever show global region to non-global region users.
                if (!self.hasGlobalRegion && self.selectedReplacement.companyID == self.globalRegionId ) {
                    self.selectionErrorMessage = "You are not able to adjust companies for Region 0.";
                    return false;
                }

                return true;
            },

            resetSelections(){
                const self = this;
                self.selectedBatch = {};
                self.selectionErrorMessage = "";
                self.clearCompanyGridSelections();
                self.repaintCompanyGrids();
                self.batchGridInstance.deselectAll();
                self.batchGridInstance.refresh();
            },

            search() {
                const self = this;
                self.clearCompanyGridSelections();
                self.refreshCompanyGrids();
            },

            clearCompanyGridSelections() {
                this.companyGridInstance.deselectAll();
                this.replacementGridInstance.deselectAll();
            },

            repaintCompanyGrids() {
                this.companyGridInstance.repaint();
                this.replacementGridInstance.repaint();
            },

            refreshCompanyGrids() {
                this.companyGridInstance.refresh();
                this.replacementGridInstance.refresh();
            },

            onBatchGridSelectionChanged(e) {
                const self = this;
                self.selectionErrorMessage = "";
                self.selectedBatch = e.selectedRowsData;
            }
        }
    }

    const parseAddress = co => {
        if(_.isNil(co)) return "";
        let addressParts = [ co.address1, co.address2, co.city, co.state, co.zip ];
        return _.joinParts(addressParts, ", ");
    };

    class BatchItem {
        constructor(company, replacement) {
            this.clientKey = _.uniqueId();
            this.companyID = _.parseNumber(company.companyID, 0);
            this.companyName = company.name || null;
            this.companyAddress = parseAddress(company);
            this.companyRegion = company.regionDisplay || null;
            this.replaceWithID = _.parseNumber(replacement.companyID, 0);
            this.replaceWithName = replacement.name || null;
            this.replaceWithAddress = parseAddress(replacement);
            this.replaceWithRegion = replacement.regionDisplay || null;
        }
        toDataObject() { return _.toPlainObject(this); }
    }


</script>