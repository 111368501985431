<template>
    <perfect-scrollbar
        v-if="menuEnabled"
        :class="{ 'mega-menu-container': true, 'active': scrollActive }"
        :options="psOptions">
        <b-collapse class="mm-collapse mm-dropdown-menu"
            :visible="menuActive"
            @show="onMenuOpen"
            @hidden="onMenuClose"
            v-click-away="onClickAway">
            <section class="mm-filters">
                <div class="row">
                    <div class="col-auto form-group">
                        <rq-search-input-group
                            automation_id="txt_mm_search"
                            placeholder="Search..."
                            :filterMode="true"
                            input-css-class="rq-placeholder-visible"
                            v-model="searchText"
                        />
                    </div>
                </div>
            </section>
            <section class="row">
                <ul class="col-auto mm-category" v-for="(category, index) in groupedCategories" :key="index">
                    <li class="mm-category-title">{{category.name}}</li>
                    <li v-for="route in category.routes" :key="route.name">
                        <b-btn variant="link" tabindex="0" :automation_id="$utils.lodash.snakeCase(route.name)" @click="onMenuItemClick(route)"> {{route.itemTypeNamePlural}} </b-btn>
                    </li>
                </ul>
            </section>
            <b-btn v-show="menuActive" variant="close" @click="closeMenu">
                <FontAwesomeIcon icon="fal fa-times" />
            </b-btn>
        </b-collapse>
    </perfect-scrollbar>
</template>
<script>
    import { mixin as VueClickAway } from "vue3-click-away";
    import { mapState, mapGetters } from "vuex";
    import { ConfigRouteViewModel }  from "../models";
    import { NAV_MUTATIONS } from "@/store/mutations";
    import { UserScreenAccessLevel } from "@/shared/models/enums";
    import { flattenedRoutes as ConfigRoutes } from "../routes";
    import { useLicenseStore } from "@/store/modules/license";

    export default {
        name:"ConfigurationMegaMenu",
        mixins: [ VueClickAway ],
        data() {
            return {
                searchText: null,
                configRoutes: [],
                selectedTopics: [],
                scrollActive: false
            }
        },
        computed: {
            ...mapState({
                menuActive: state => state.ui.navigation.megaMenuActive
            }),
            ...mapGetters(["getRouteAccess"]),
            topics() {
                return _.uniq(_.map(this.configRoutes, "topic"));
            },
            groupedCategories() {
                let groups = _.groupBy(this.getFilteredItems(), "category");
                return _.map(groups, (routes, name) => ({ name, routes }));
            },
            menuEnabled() { return !_.getBool(this, "$route.meta.hideMegaMenuToggle"); },
            psOptions() {
                return {
                    maxScrollbarLength: 200,
                    minScrollbarLength: 40,
                    suppressScrollX: true,
                    wheelPropagation: false,
                    interceptRailY: styles => ({ ...styles, height: 0 })
                }
            }
        },

        created(){
            const self = this;
            const licenseStore = useLicenseStore();
            const routeFilter = r => {
                let explicitlyHidden = _.getBool(r, "meta.hideFromSearch");
                let hasAccess = self.hasRouteAccess(r);
                let featureFlag = r?.meta?.featureFlag || "";
                let hasFeatureEnabled = (_.isEmpty(featureFlag) || licenseStore.checkFeature(featureFlag));

                return !explicitlyHidden
                    && hasAccess
                    && hasFeatureEnabled;
            };
            let searchRoutes = _.filter(ConfigRoutes, routeFilter);
            self.configRoutes = _.map(searchRoutes,
                r => new ConfigRouteViewModel({
                    itemTypeNamePlural: r.meta.label,
                    itemTypeDescription: r.meta.itemTypeDescription,
                    name: r.name || _.get(r, "children[0].name", ""),
                    topic: r.meta.topic,
                    category: r.meta.category
                })
            );
        },

        beforeUnmount() {
            this.closeMenu();
        },

        methods: {

            getFilteredItems() {
                let items = this.configRoutes;
                if(!_.isEmpty(this.selectedTopics)) {
                    items = _.filter(items, r => _.includes(this.selectedTopics, r.topic));
                }
                if (!_.isNullOrEmpty(this.searchText)) {
                    const includesSearchText = itemText => _.includes(_.toLower(itemText), _.toLower(this.searchText));
                    items = _.filter(items, r => includesSearchText(r.itemTypeNamePlural) || includesSearchText(r.itemTypeDescription));
                }
                return items;
            },

            hasRouteAccess(route) {
                const self = this;
                let routeAccess = self.getRouteAccess(route, UserScreenAccessLevel.None);
                return _.getBool(routeAccess, "hasAccess", true);
            },

            closeMenu() {
                this.$store.commit(NAV_MUTATIONS.SET_MEGA_MENU_STATE, false);
            },

            onMenuItemClick(route) {
                this.navigateOnCollapse = route;
                this.closeMenu();
            },

            onMenuOpen() {
                this.scrollActive = true;
                this.navigateOnCollapse = null;
            },

            onMenuClose() {
                this.scrollActive = false;
                if(_.isEmpty(this.navigateOnCollapse)) return;

                let targetRoute = this.navigateOnCollapse;
                if(!_.isNil(this.navigateOnCollapse.name))
                    targetRoute = { name: this.navigateOnCollapse.name };
                else if(!_.isEmpty(this.navigateOnCollapse.children))
                    targetRoute = { name: this.navigateOnCollapse.children[0].name };

                this.$router.push(targetRoute);
            },

            onClickAway(e) {
                let targetAutomationId = _.invoke(e, "target.getAttribute", "automation_id");
                let parentAutomationId = _.invoke(e, "target.parentElement.getAttribute", "automation_id");
                let isDxPopup = _.some(e.path, el => _.invoke(el, "classList.contains", "dx-popup-normal"));
                if(targetAutomationId === "btn_mega_menu_toggle" || parentAutomationId === "btn_mega_menu_toggle" || isDxPopup) return;
                this.closeMenu();
            }
        }
    }
</script>
