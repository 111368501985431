<template>
    <div class="rq-container">
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showRequiredErrorBanner"
        />
        <div class="row" >
            <div class="col col-12 form-group form-required" :class="{'has-error' : v$.item.label.$error}">
                <label for="txt_label">LABEL</label>
                <input automation_id="txt_code" v-model="v$.item.label.$model" type="text" class="form-control" placeholder="label" maxlength="255">
                <rq-validation-feedback
                    message="Label is required."
                />                
            </div>
        </div>
       
        <div class="row" >
            <div class="col col-12 form-group form-required" :class="{'has-error' : v$.item.customDataType.$error}">
                  <label for="drp_custom_data_type">DATA TYPE</label> 
                <dx-select-box
                    :input-attr="{ automation_id: 'drp_custom_data_type', id: 'drp_custom_data_type' }"
                    :items="customDataTypeLookup"
                    value-expr="id"
                    display-expr="name" 
                    :search-enabled="false"       
                    @valueChanged="onChangeDataType"                  
                    v-model="v$.item.customDataType.$model"
                />
               <rq-validation-feedback
                    message="Data Type is required"
                />                                 
            </div>
        </div>

        <div class="row" v-show="displaylistValueOptions">     
            <div class="col col-12 form-required d-flex flex-column">    
                  <label for="drp_options_List" class="form-group-label form-required">Options</label>          
                   <div class="grid-container list-grid-container">
                    <rqdx-data-grid 
                        ref="listValueDataGrid"
                        id="dg_list_value_data_grid"
                        automation_id="dg_list_value_data_grid"
                        :data-source="listValueGridDataSource"
                        :config="listValueGridConfig"                        
                    />
                    <div class="rq-grid-action-footer ">
                        <ul class="nav">
                            <li class="nav-item">
                                <b-btn
                                    variant="blend"
                                    @click="onAddListValueItem">
                                        <FontAwesomeIcon icon="fas fa-plus" />Add option
                                </b-btn>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-show="displayParentPickList">
            <div class="col col-12 form-group form-required" :class="{'has-error' : v$.item.parentID.$error}">
                <label for="drp_parent_pick_List">Parent Pick List</label>
                <dx-select-box
                    :input-attr="$utils.idAttrs('drp_parent')"
                    :items="parentLookup"
                    value-expr="id"
                    display-expr="name"
                    :search-enabled="false"
                    v-model="v$.item.parentID.$model"                  
                    @valueChanged="onParentChanged"
                />                
            </div>    
        </div>
        <div class="row" v-show="displayParentPickList" >
            <div class="col col-12 form-group form-required" :class="{'has-error' : v$.item.parentSelectionID.$error}">
                <label for="drp_parent_selection">Parent Selection</label>
                 <dx-select-box
                    :input-attr="$utils.idAttrs('drp_parent_selection')"
                    :items="parentSelectionLookup"
                    value-expr="id"
                    display-expr="name"
                    :search-enabled="false"
                    v-model="v$.item.parentSelectionID.$model" 
                    
                />              
            </div>
        </div>        
        <div class="row" v-show="displayMaxLength">
            <div class="col col-12 form-group">
                <label for="txt_max_length">MAX LENGTH</label>
                <rq-input-number
                    v-model="item.maxLength"
                    defaultValue="1"
                    decimals="0"
                    minValue="1"
                    :maxValue="maxLengthValue"
                    cssClass="form-control"
                    automation_id="txt_max_length"
                    id="txt_max_length"                    
                />
            </div>
        </div>         
        <div class="row" v-show="displayRequired">
            <div class="col col-12 form-group">
                 <b-form-checkbox
                    automation_id="chk_check_data_definition_required"
                    id="chk_check_data_definition_required"
                    v-model="item.gridListValueRequired">Required</b-form-checkbox>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapGetters } from "vuex";
    import { required } from "validators";
    import { useVuelidate } from "@vuelidate/core";
    import { SystemLookupItem } from "@/shared/models/models";
    import { CustomDataType, CustomDataListValue }  from "@/shared/components/customdata/models";

    export default {
        name:"CustomDataDefinitionForm",        
        props: {
            item: {type: Object, default: null},
            itemTypeName: {type: String, default: null},
            uniqueValidator: { type: Function, default: (() => true) }
        },

        setup: () => ({ v$: useVuelidate() }),

        data () {
            return {
                selectedItem: {},
                validationErrors: [],
                verifiedItem: {},  
                originalListValues: [],          
                listValueLookup: [],
                parentLookup: [new SystemLookupItem()],
                parentSelectionLookup: [new SystemLookupItem()],
            };
        },

        validations: () => ({
            item: {
                label: { required }, 
                customDataType: { required },                
                parentID: { required, greaterThanZero: (val, vm) => {
                    return vm.customDataType === CustomDataType.ChildPickList ?   !_.isNil(val) && val > 0 : true;
                }},
                parentSelectionID: {required, greaterThanZero: (val, vm) => {
                    return vm.customDataType === CustomDataType.ChildPickList ? !_.isNil(val) && val > 0 : true;
                }},
              
            }
        }),

        computed: {  
            ...mapGetters([
                "lookupHelpers"               
            ]),            
            validateChildPickListParentID () { return !this.item.customDataType === CustomDataType.PickList; },
            disabled() { return !this.item.isNew;},
            showRequiredErrorBanner(){ return this.validationErrors.length > 0;},         
            customDataTypeLookup() { return  CustomDataType.lookupItems},    
            listValueKey() { return "customDataListValueID"; },
            displayMaxLength() { return this.item.customDataType === CustomDataType.Text || this.item.customDataType === CustomDataType.Memo; },   
            maxLengthValue() { return  this.item.customDataType === CustomDataType.Text ? 25 : this.item.customDataType === CustomDataType.Memo ? 500 : 1 ;},
            listValueGridInstance() { return _.get(this, "$refs.listValueDataGrid.gridInstance", null) || {}; },
            displaylistValueOptions(){ return this.item.customDataType === CustomDataType.PickList || this.item.customDataType === CustomDataType.ChildPickList; },
            displayParentPickList(){ return this.item.customDataType === CustomDataType.ChildPickList; },     
            displayRequired() { return  this.item.customDataType === CustomDataType.ChildPickList || this.item.customDataType === CustomDataType.Company 
                                    || this.item.customDataType === CustomDataType.Date || this.item.customDataType === CustomDataType.DateTime || this.item.customDataType === CustomDataType.Decimal 
                                    || this.item.customDataType === CustomDataType.Integer || this.item.customDataType === CustomDataType.Memo || this.item.customDataType === CustomDataType.Money
                                    || this.item.customDataType === CustomDataType.PickList || this.item.customDataType === CustomDataType.Text 
                                    || this.item.customDataType === CustomDataType.Time;}       
        },

        created(){
            this.initGridConfigs();
            if (this.displaylistValueOptions){
                this.fetchlistValueData();
                this.fetchParentData();
                this.fetchParentSelectiontData();
                
            }            
        },

        watch: {
            "item.customDataType"(newVal, oldVal) {
                if(newVal === CustomDataType.Text) {
                   this.item.maxLength = this.item.maxLength > 25 ? 25 : this.item.maxLength
                }
            },
        },

        methods:{

            initGridConfigs(){
                const self = this;
                self.listValueGridConfig = {
                    keyExpr: self.listValueKey,
                    height: "100%",
                    columns: [
                        { dataField: "availableValue", caption: "Name", dataType: "string", allowEditing: true, },
                        { type: "buttons", buttons: [{ name: "delete", icon: "fas fa-xmark" }] }
                    ],
                    editing: {
                        mode: "row",
                        allowEditing: false,
                        allowUpdating: false,
                        allowAdding: true,
                        allowDeleting: true
                    },
                    showColumnLines: false,
                    toolbar: { visible: false },
                    sorting: { mode: "none" },
                    selection: { mode: "none" },
                    // scrolling: { useNative: false },
                    rowDragging: {
                        allowReordering: true,
                        dropFeedbackMode: "push",
                        onReorder(e) {
                            self.repositionListValues(e.fromIndex, e.toIndex);                            
                        }
                    },
                    onInitNewRow(e) {
                        e.data.id = _.parseNumber(_.uniqueId()) * -1;
                    }
                };
                self.listValueGridDataSource = {
                    key: self.listValueKey,
                    load (loadOptions) {
                        return Promise.resolve(self.listValueLookup);
                    },
                    insert: self.onGridInsert,
                    remove: self.onGridRemove
                };

            },
            
            onAddListValueItem() {
                this.listValueGridInstance.addRow();
            },

            onGridInsert(values){
                console.log('onGridInsert');
                const self = this;
                let newItem = new CustomDataListValue({
                                customDataListValueID: values.id,
                                customDataDefinitionID: self.item.customDataDefinitionID,
                                availableValue: values.availableValue,
                                parentID: self.item.parentID
                });
                self.listValueLookup.push(newItem);
                self.originalListValues.push(newItem);
            },

            onGridRemove(key){
                console.log('onGridRemove');
                const self = this;
                self.removeListValue( key);
                self.listValueGridInstance.refresh();
            },
            
            removeListValue(key) {
                let item = _.find(this.listValueLookup, (i) => {
                    return _.parseNumber(_.get(i, this.listValueKey, -1), -1) == _.parseNumber(key, -1);
                });
                _.remove(this.listValueLookup, (i) => {return _.parseNumber(_.get(i, this.listValueKey, -1), -1) == key;});
                if (key < 0) {
                    _.remove(this.originalListValues, (i) => {return _.parseNumber(_.get(i, this.listValueKey, -1), -1) == key;});
                } else {
                    let editItem = _.find(this.originalListValues, (i) => {
                        return _.parseNumber(_.get(i, this.listValueKey, -1), -1) == _.parseNumber(key, -1);
                    });
                    item.customDataDefinitionID = -1; 
                    _.assign(editItem, item);
                }                
            },

            updatePostionOrdinalItem(key, positionOrdinal) {
                let editItem = _.find(this.originalListValues, (i) => {
                    return _.parseNumber(_.get(i, this.listValueKey, -1), -1) == _.parseNumber(key);
                });
                let item = new CustomDataListValue(editItem);
                item.positionOrdinal = positionOrdinal;
                _.assign(editItem, item);
             },


            onChangeDataType(e){
                console.log('onChangeDataType');
                const self = this; 
                self.validationErrors = [];
                self.verifiedItem = {};      
                if (!self.displayRequired) {
                    self.item.isRequired = false;
                }         
                if (self.item.customDataType === CustomDataType.PickList){                    
                    self.fetchlistValueData();
                    return;
                }
                if (self.item.customDataType === CustomDataType.ChildPickList){
                    self.fetchlistValueData();
                    this.fetchParentData();
                    return;
                }
            },

            onParentChanged(){
                this.fetchParentSelectiontData();
            },

            fetchlistValueData(){
                const self = this;
                if (self.item.isNew) {return;}
                let apiPromise = self.$api.CustomDataApi.getListValues(self.item.customDataDefinitionID);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.originalListValues = _.map(result, i => new CustomDataListValue(i));
                        self.listValueLookup = _.map(result, i => new CustomDataListValue(i));
                        self.item.parentSelectionID = self.originalListValues.length ===0 || _.isNil(self.originalListValues[0].parentID) ? 0 : self.originalListValues[0].parentID;
                        self.listValueGridInstance.refresh();
                        return { data: self.listValueLookup, totalCount: self.listValueLookup.length };
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading List Value lookup information.` });
                        return error;
                    });
            },

            fetchParentData(){                
                const self = this;                
                let apiPromise = self.$api.CustomDataApi.getDefinitionParents();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.parentLookup = _.map(result, i => new SystemLookupItem({id: i.customDataDefinitionID, name: i.label}));                             
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading Parent Pick List information.` });
                        return error;
                    });
            },

            fetchParentSelectiontData(){                
                const self = this;     
                if (_.isNil(self.item.parentID) || self.item.parentID == 0) { return; }
                let apiPromise = self.$api.CustomDataApi.getListValueByParentID(self.item.parentID);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.parentSelectionLookup = _.map(result, i => new SystemLookupItem({id: i.customDataListValueID, name: i.availableValue}));

                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading Parent Selection Pick List information.` });
                        return error;
                    });
            },

            onCreatePicklistValuetOption(data){                
                console.log('onCreateListValuetOption');
                this.listValueLookup.push( new SystemLookupItem({id:-1, name: data.text}));
                this.listValueLookup = _.filter(this.listValueLookup, item => item.name !== '');
                let item = new CustomDataListValue({customDataListValueID: 0, 
                                                    customDataDefinitionID : self.item.customDataTypeLookup,
                                                    availableValue: data.text,})
                self.save(item, self.picklistValueLookup);            
            },

            repositionListValues(fromIndex, toIndex) {
                const self = this;
                let removed = self.listValueLookup.splice(fromIndex, 1);
                self.listValueLookup.splice(toIndex, 0, removed[0]);
                _.forEach(self.listValueLookup, (item, index) => {
                    item.sequence = index+1;  
                    self.updatePostionOrdinalItem(item.customDataListValueID, item.sequence);                  
                });
                self.listValueGridInstance.refresh();                
            },

            saveListValues(){
                const self = this;
                let items = [];
                 _.forEach(this.originalListValues, (item) => {
                    items.push(item.toDataObject())
                });
                let apiPromise = self.$api.CustomDataApi.saveListValues(items, null);
                self.$rqBusy.wait(apiPromise)
                    .then(item => {                        
                    })
                    .catch(err => {
                        self.$toast.error(err.errorMessage);
                    })
            },

            saveListValue(item, changes){
                const self = this;               
                let apiPromise = self.$api.CustomDataApi.saveListValue(item.toDataObject(), changes);
                self.$rqBusy.wait(apiPromise)
                    .then(item => {
                        self.addItem(new SystemLookupItem({id: item.customDataListValueID, name: item.availableValue}));
                        self.$toast.success({ message: `Option was saved.` });
                    })
                    .catch(err => {
                        self.$toast.error({ message: `Error saving option.` });                        
                    })
                    .finally(() => {
                        self.listValueGridInstance.refresh();
                    });
            },

            onDeleteListValues(key) {               
                const self = this;                
                let keys = [key];
                let ok = function (args) {
                    let apiPromise = self.$api.CustomDataApi.deleteListValues(keys);
                    return self.$rqBusy.wait(apiPromise)
                        .then(key => {
                            self.deleteItem( keys);
                            return true;
                        })
                        .catch(error => {
                            if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.tabnNamePlural} are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.tabName}.` });
                            }
                            return error;
                        })
                        .finally(() => {
                            self.listValueGridInstance.refresh();
                        });
                }

                self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected optioin?`, ok, null, { cancelTitle: "No", okTitle: "Yes"});
            },

            addItem(item) {
                this.listValueLookup.push(item);
            },

          

            deleteItem(keys) {
                _.each(keys, k => {
                    _.remove(this.listValueLookup, (i) => {return _.parseNumber(_.get(i, "id", -1), -1) == k;});
                });
            },


            fetchParentListData(){
                const self = this;
                self.parentLooku = [new SystemLookupItem()];       
            },
             fetchParentSelectionListData(){
                const self = this;
                self.parentLookup = [new SystemLookupItem()];

            },          
        }
    }
</script>
