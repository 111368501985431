const toggleSwitch = { No: false, Yes: true };
export class ToggleSwitch {
    static get On () { return true; }
    static get Off () { return false; }

    static get lookupItems () {
        return _.map(toggleSwitch, c => { return { id: c, name: toggleSwitch.displayValue(c) }; });
    }

    static displayValue (id) {
		let enumKey = _.findKey(toggleSwitch, cm => cm === id);
		return (enumKey && _.startCase(enumKey)) || "";
    }
}