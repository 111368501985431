<template>
    <rq-tree-view
        ref="fieldTreeView"
        :id="id"
        :automation_id="automation_id"
        :title="title"
        :data-source="treeDataSource"
        :show-border="showBorder"
        :show-title-border="showTitleBorder"
        :disabled="disabled"
        :wrap-labels="wrapLabels"
        v-model="selectedItemKey"
        expand-selected
        @item-selected="onItemSelected">
        <template #item-label="{ item }">
            <FontAwesomeIcon v-if="item.isFolder && item.isExpanded" icon="fas fa-folder-open" />
            <FontAwesomeIcon v-else-if="item.isFolder" icon="fas fa-folder" />
            <FontAwesomeIcon v-else icon="fas fa-file" />
            <span :title="item.displayName">
                {{item.displayName}}
            </span>
        </template>
    </rq-tree-view>
</template>

<script>
    import { BiReportTreeItem } from "../models.js";

    export default {
        name: "BiReportsTree",
        props: {
            id: { default:null },
            automation_id: { type: String, default: "" },
            markedItems: { type: Array, default: () => [] },
            title: { type: String, default: null },
            showBorder: { type: Boolean, default: false },
            showTitleBorder: { type: Boolean, default: false },
            modelValue: { type: String, default: null },
            disabled: { type: Boolean, default: false },
            wrapLabels: { type: Boolean, default: false },
        },
        data () {
            const self = this;
            return {
                selectedItemKey: null,
                selectedItem: null
            };
        },

        watch:{
            modelValue(newValue, oldValue) {
                // newValue === oldValue ||
                if(newValue === this.selectedItemKey) return;
                this.selectedItemKey = newValue;
            },
            selectedItemKey(newValue, oldValue) {
                // newValue === oldValue ||
                if(newValue === this.modelValue) return;
                this.$emit("update:modelValue", newValue);
            },
        },

        created() {
            const self = this;
            self.treeDataSource = { load: self.fetchData };
        },

        methods: {
            fetchData(parent) {
                const self = this;
                let parentData = null;
                if(!_.isNil(parent)) {
                    return Promise.resolve(parentData.children);
                }
                let promise = self.$api.ExagoApi.getReportTree();

                return self.$rqBusy.wait(promise)
                    .then(result => {
                        let items = _.map(result, item => new BiReportTreeItem(item));
                        return items;
                    });
            },

            getFieldTreeItems() {
                const self = this;

                return _.map(
                    _.get(self, "$refs.fieldTreeView.treeItems", null) || [],
                    item => new BiReportTreeItem(item)
                );
            },

            refresh() {
                const self = this;

                let tree = _.get(self, '$refs.fieldTreeView');

                if (!tree) return;

                tree.refresh(true);
            },

            onItemSelected(e) {
                const self = this;
                this.selectedItem = e.selectedItem;
                this.$emit("item-selected", e);
            }
        }
    };
</script>
