<template>
    <div class="content-wrapper">
        <rq-page-section :title="itemTypeNamePlural" headerSize="lg" borderless>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
        </rq-page-section>
        <RqTabs
            :tabs="tabs"
            @activate-tab="onTabActivated">
            <template v-for="(tab,index) in tabs" :key="index" v-slot:[tab.name]>
                <component :is="tab.component" :ref="tab.ref"></component>
            </template>
        </RqTabs>
    </div>
</template>

<script>
    import DefaultHUDLine1974List from "./DefaultHUDLine1974List.vue";
    import DefaultHUDLine2010List from "./DefaultHUDLine2010List.vue";

    export default {
        name:"DefaultHUDLinesList",
        components: {
            DefaultHUDLine1974List,
            DefaultHUDLine2010List
        },
        data () {
            return {
                tabs: [
                    { automation_id: "btn_tab_2_page", name: "2-Page", title: "2-Page", component: "DefaultHUDLine1974List", ref: "listComponent0" },
                    { automation_id: "btn_tab_3_page", name: "3-Page", title: "3-Page", component: "DefaultHUDLine2010List", ref: "listComponent1" }
                ]
            };
        },
        created(){
            this.initNonReactiveVariables();
        },
        methods: {
            onTabActivated({tab}) {
                if(!tab.ref) return;
                this.$refs?.[tab.ref]?.refresh();
            },
            initNonReactiveVariables() {
                const self = this;
                self.itemTypeDescription = _.get(self.$route.meta, "itemTypeDescription");
                self.itemTypeName = _.get(self.$route.meta, "itemTypeName");
                self.itemTypeNamePlural = _.get(self.$route.meta, "label");
            },
        }
    }
</script>