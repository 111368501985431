<template>
    <div class="content-wrapper">

        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            v-model:validation-errors="validationErrors"
            @delete="onDeleteItem"
            @reset="onReset"
            integrated-search
            rq-editable
            rq-filters
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { FileNumberPoolDto }  from "../models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name:"FileNumberPoolList",
        data () {
            return {
                items: [],
                validationErrors: [],
                addEventName: "",
            };
        },
        created(){
            this.initNonReactiveVariables();
            this.fetchData();
            this.initGridConfig();
            this.initListeners();
        },
        beforeUnmount() {
            this.$events.off(this.addEventName, this.onAddItem);
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                user: state => state.authentication.session.user
            }),
            gridInstance() { return _.get(this.$refs, "dataGrid.gridInstance", null) || {}; },
        },
        watch: {
            validationErrors: function () {
                const self = this;
                self.$events.emit("update-config-error", { message: "Please correct the highlighted errors on screen to continue.", hasError: self.validationErrors.length > 0 });
            }
        },

        methods: {
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            fetchData() {
                const self = this;
                let apiPromise = self.$api.FileNumberPoolsApi.get();
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new FileNumberPoolDto(i));
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        { dataField: self.itemKey, visible: false, showInColumnChooser: false},
                        {
                            dataField: "regionID",
                            dataType: "number",
                            caption: "Region",
                            calculateSortValue: DxGridUtils.regionDisplaySortValue,
                            lookup: {
                                loadMode: "raw",
                                dataSource: self.regions,
                                displayExpr: "displayName",
                                valueExpr: "regionID"
                            },
                            setCellValue: function(rowData, value) {
                                rowData.regionID = value;
                            },
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "custom",
                                    validationCallback: self.isUniqueItem,
                                    message: "That Region/Description combination already exists"
                                }
                            ]
                        },
                        {
                            dataField: "description",
                            dataType: "string",
                            sortIndex: 0,
                            sortOrder: "asc",
                            editorOptions: { maxLength: 20 },
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "custom",
                                    validationCallback: self.isUniqueItem,
                                    message: "That Region/Description combination already exists"
                                }
                            ]
                        },
                        { dataField: "prefix", dataType: "string", editorOptions: { maxLength: 5 } },
                        {
                            dataField: "nextFileNumber",
                            datatype: "numeric",
                             validationRules: [
                                { type: "required" }
                             ]
                        },
                        { dataField: "suffix", dataType: "string", editorOptions: { maxLength: 5 }},
                        { dataField: "fileNumberPadding", width: 100, caption: "Length", datatype: "numeric" },
                        { dataField: "appendYearToFileNumber", caption: "Append Year", dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate },
                    ],
                    onInitNewRow: function(e) {
                        _.set(e.data, self.itemKey, 0);
                        e.data.regionID = self.user.regionID;
                        e.data.appendYearToFileNumber = false;
                    }
                };

                self.gridDataSource = {
                    loadMode: "raw",
                    key: self.itemKey,
                    load () {
                        return Promise.resolve(self.items);
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },

            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },

            initNonReactiveVariables() {
                const self = this;
                self.regions = self.lookupHelpers.getRegions();
                self.itemTypeName = _.get(self.$route.meta, "itemTypeName");
                self.itemTypeNamePlural = _.get(self.$route.meta, "label");
                self.itemKey = _.get(self.$route.meta, "itemKey");
                self.selectionActions = [
                        { name: "delete", text: "Delete", eventName: "delete", requireSelection: true, allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}` }
                    ];
            },

            isUniqueItem(row){
                const self = this;
                let dup = {};
                if (_.trim(_.get(row.data, "description"), "") == '') return true;
                dup = _.find(self.items, (i) => {
                    return _.toLower(_.trim(i.description)) === _.toLower(_.trim(_.get(row.data, "description"), ""))
                            && _.parseNumber(i.regionID, -1) === _.parseNumber(_.get(row.data, "regionID"), -1)
                            && _.parseNumber(_.get(i, self.itemKey, -1), -1) != _.parseNumber(_.get(row.data, self.itemKey, -1), -1);
                });
                return _.isEmpty(dup);
            },

            onAddItem() {
                this.gridInstance.clearSelection();
                this.gridInstance.addRow();
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let toBeDeletedKeys = _.map(items, self.itemKey);

                    let apiPromise = self.$api.FileNumberPoolsApi.delete(toBeDeletedKeys);
                    return self.$rqBusy.wait(apiPromise)
                        .then(keys => {
                            _.pullAllBy(self.items, items, self.itemKey);
                            let message = keys.length > 1 ? `${keys.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success({ message: message });
                            return true;
                        })
                        .catch(error => {
                          if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                 self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            }
                            return error;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onGridInsert(values) {
                const self = this;
                let newItem = new FileNumberPoolDto(values);
                if (!self.isUniqueItem(newItem)) {
                    self.validationErrors = [`${newItem.description} already Exists for that Region.`];
                    return;
                }
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                self.save(newItem, changes)
                    .then(item => {
                        self.items.push(new FileNumberPoolDto(item));
                        self.$toast.success({ message: `${self.itemTypeName} ${item.description} was saved.` });
                    })
                    .catch(err => {
                        self.validationErrors = [err.errorMessage];
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => _.parseNumber(_.get(item, self.itemKey), -1) === key);
                if(itemIndex < 0) return self.onGridInsert(values);

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new FileNumberPoolDto(_.assign({}, self.items[itemIndex], values));
                if (!self.isUniqueItem(updatedItem)) {
                    self.validationErrors = [`${updatedItem.description} already Exists for that Region.`];
                    return;
                }
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());
                self.save(updatedItem, changes)
                    .then(item => {
                        _.assign(self.items[itemIndex], item);
                        self.$toast.success({ message: `${self.itemTypeName} ${item.description} was saved.` });
                    })
                    .catch(err => {
                        self.validationErrors = [err.errorMessage];
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            onReset(e) {
                this.fetchData();
            },

            save(item, changes){
                const self = this;
                self.validationErrors = [];
                if (changes.length == 0) {
                    self.$toast.info({ message: "No changes detected" });
                    return Promise.resolve(item);
                }
                let apiPromise = self.$api.FileNumberPoolsApi.save(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise)
            },

            refresh() {
                if (!_.isEmpty(this.gridInstance)) {
                    this.gridInstance.option("focusedRowIndex", -1);
                    this.gridInstance.clearSelection();
                    this.gridInstance.refresh();
                }
            },
        }
    }
</script>
