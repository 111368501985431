<template>
    <div :class="{ 'premium-picker': true, 'input-group': showSearchButton, 'input-group-sm': small && showSearchButton, 'w-100': !showSearchButton }">
        <dx-select-box
            ref="rateSelectBox"
            :data-source="selectBoxDataSource"
            value-expr="id"
            display-expr="name"
            :search-enabled="true"
            :show-clear-button="true"
            :min-search-length="2"
            :no-data-text="noDataText"
            v-model:text="searchText"
            v-model="selectedValue"
            @valueChanged="onSelectBoxValueChanged"
            @optionChanged="onSelectBoxEvent"
        />
        <b-btn
            v-if="showSearchButton"
            variant="theme"
            :disabled="disabled"
            @click="onSearchClick"
            v-rq-tooltip.hover.left
             title="Search Premiums">
            <FontAwesomeIcon icon="fa fa-search" aria-hidden="true" />
        </b-btn>
    </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import PremiumSearch from './PremiumSearch';
    import { RATE_ACTIONS } from '@/store/actions';
    export default {
        name:"PremiumPicker",
        props: {
            dialogTitle: { type: String, default: "" },
            disabled: { default: false },
            small: { type: Boolean, default: false },
            modelValue: { type: Number, default: null },
            rateName: { type: String, default: "[Premium Name Not Provided]" },
            showSearchButton: { type: Boolean, default: true }
        },
        data () {
            return {
                selectBoxDataSource: [],
                selectedValue: null,
                displayValue: null,
                selectedItem: {},
                noDataText: "Enter a value to search for a premium",
                searchText: ""
            };
        },
        computed: {
            ...mapGetters(["premiumNames"])
        },
        watch: {
            modelValue: {
                handler(newVal, oldVal) {
                    if(this.skipNumWatch(newVal, oldVal, this.selectedValue)) return;
                    this.selectedValue = newVal;
                },
                immediate: true
            },
            selectedValue(newVal, oldVal) {
                if(this.skipNumWatch(newVal, oldVal, this.modelValue)) return;
                this.$emit("update:modelValue", newVal);
            },
            displayValue(newVal, oldVal) {
                if(this.skipStringWatch(newVal, oldVal, this.rateName)) return;
                this.$emit("update:rateName", newVal);
            },
            searchText(newVal, oldVal) {
                if(newVal === oldVal || !_.isEmpty(newVal)) return;
                this.noDataText = "Enter a value to search for a premium";
            }
        },
        created(){
            const self = this;
            self.initDataSource();
        },
        methods: {
            initDataSource() {
                const self = this;

                let initPromise = _.isEmpty(self.premiumNames)
                    ? self.refreshRates()
                    : Promise.resolve([]);

                return initPromise
                    .then(() => {
                        self.selectedItem = self.modelValue > 0 ? _.find(self.premiumNames, p => p.id === self.modelValue) : {};
                        self.selectBoxDataSource = {
                            // paginate: true,
                            // pageSize: 50,
                            load: self.fetchPremiums,
                            byKey: key => self.$store.dispatch(RATE_ACTIONS.GET_RATE_BY_KEY, key).then(item => _.toPlainObject(item))
                        };
                        return self.premiumNames;
                    })
            },
            refreshRates(effectiveDate=null) {
                const self = this;
                return self.$store.dispatch(RATE_ACTIONS.GET_RATES_BY_DATE, { effectiveDate, forceRefresh: true })
            },
            fetchPremiums(loadOptions) {
                const self = this;
                if(_.get(loadOptions, "filter[0]", null) === "id") {
                    let filterKey = _.getNumber(loadOptions, "filter[2]");
                    return self.$store.dispatch(RATE_ACTIONS.GET_RATE_BY_KEY, filterKey)
                        .then(item => [_.toPlainObject(item)]);
                }
                if(_.isNil(loadOptions) || _.isEmpty(loadOptions.searchValue)) {
                    self.noDataText = "Enter a value to search for a premium";
                    return Promise.resolve([]);
                }
                self.noDataText = "Searching...";
                return self.$store.dispatch(RATE_ACTIONS.SEARCH_RATES, loadOptions.searchValue)
                    .then(response => {
                        self.noDataText = _.isEmpty(response.results)
                            ? "No data to display"
                            : "Enter a value to search for a premium";
                        //return { data: _.map(response.results, r => new RateDto(r)), totalCount: response.totalRecords };
                        return response;
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error("Premium search failed.");
                    });
            },
            getSelectBoxOption(option) {
                return this.invokeSelectBoxMethod("option", option);
            },
            invokeSelectBoxMethod(method, ...params) {
                return _.invoke(this, `$refs.rateSelectBox.instance.${method}`, ...params);
            },
            skipStringWatch(newVal, oldVal, altVal) { return newVal === oldVal || newVal === altVal; },
            skipNumWatch(newVal, oldVal, altVal) {
                return _.parseNumber(newVal, 0) === _.parseNumber(oldVal, 0)
                    || _.parseNumber(newVal, 0) === _.parseNumber(altVal, 0);
            },
            onSelectBoxValueChanged(e) {
                if(_.isNil(e.event)) return;
                if(_.parseNumber(e.value, 0) === 0) {
                    this.selectedItem = {};
                    this.displayValue = null;
                    return;
                }
                this.selectedItem = _.find(this.premiumNames, p => p.id === e.value);
                this.displayValue = this.selectedItem.name;
            },
            onSelectBoxEvent(e) {
                if(e.name !== "isActive" || e.value || !_.isNil(this.selectedValue)) return;
                let searchText = e.component.option("text");
                if(!_.isEmpty(searchText)) return;
                this.noDataText = "Enter a value to search for a premium";
            },
            onSearchClick () {
                const self = this;
                self.$dialog.open({
                    title: self.dialogTitle,
                    height: "80%",
                    width: "80%",
                    scrollable: false,
                    component: PremiumSearch,
                    onOk(e) {
                        if (!e?.component?.selectedItem?.rateID)  return true;
                        self.displayValue = e.component.selectedItem.displayName;
                        self.selectedValue = e.component.selectedItem.rateID;
                        self.selectedItem = {
                            id: self.selectedValue,
                            name: self.displayValue
                        };
                        return true;
                    }
                });
            }
        }
    }
</script>
