<template>    
    <div class="content-wrapper">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        beforeRouteUpdate(to, from, next) {
            this.scrollTop();
            next();
        },

        methods: {
            scrollTop() {
                let parentElement = this.$el.parentElement;
                if(!parentElement || !parentElement.scrollTop) return;
                parentElement.scrollTop = 0;
            }
        }
    };

</script>