import { ComingSoon } from "@/pages";
import ConfigurationSearch from "./ConfigurationSearch.vue";
import MainModule from "./enterprise";
import CheckWritingModule from "./checkWriting";
import DocumentReportModule from "./documentsReports";
import WorkflowModule from "./workflowDepartments";
import IntegrationModule from "./integrations";
import TitleProdModule from "./titleProduction";
import SSandCalcsModule from "./ssAndCalcs";
import AutomationModule from "./automation";

import CheckWritingRoutes from "./checkWriting/routes";
import DocumentsReportsRoutes from "./documentsReports/routes";
import IntegrationsRoutes from "./integrations/routes";
import MainRoutes from "./enterprise/routes";
import SsAndCalcsRoutes from "./ssAndCalcs/routes";
import TitleProductionRoutes from "./titleProduction/routes";
import WorkflowDepartmentsRoutes from "./workflowDepartments/routes";
import AutomationRoutes from "./automation/routes";

let configRoutes = [
    {
        name: "cfg:search",
        path: "search",
        component: ConfigurationSearch,
        meta: {
            label: "Search",
            skipSaveOnLeave: true,
            scrollLayout: true,
            hideMegaMenuToggle: true,
            accessKey: "ProcessTemplateSetup_ScreenAccess",
            permissionKeys: [
                "appWordDocHelper_AppAccess",
                "appStandardLanguage_AppAccess",
                "AllowAddEditRates",
                "AllowAddEditRecordingFees",
                "AllowAddEditTransferTaxes",
                "AllowEditEndorsementSettlementFees",
                "FileScanAllowDescriptionManagement",
                "FileScanAllowCategoryManagement"
            ]
            // title: "Configuration Search" ,
            // itemTypeDescription: "Browse or search for Configuration Settings"
        }
    },
    MainModule,
    CheckWritingModule,
    DocumentReportModule,
    WorkflowModule,
    IntegrationModule,
    TitleProdModule,
    SSandCalcsModule,
    AutomationModule
];

export const flattenedRoutes = _.concat(
    MainRoutes,
    CheckWritingRoutes,
    DocumentsReportsRoutes,
    TitleProductionRoutes,
    IntegrationsRoutes,
    SsAndCalcsRoutes,
    WorkflowDepartmentsRoutes,
    AutomationRoutes
);

export default configRoutes;
