import { Categories } from "./categories"
import { Topics } from "../topics"
import { ComingSoon } from "@/pages";
import FileScanDescriptionList from "./components/FileScanDescriptionList";
import ListCodesList from "./components/ListCodesList";
import ReportBISettingsForm from "./components/ReportBISettingsForm";
import FileScanCategoryList from "./components/FileScanCategoryList";
import FileScanSettingsForm from "./components/FileScanSettingsForm";
import FileScanDescriptionTaskList from "./components/FileScanDescriptionTaskList";
import BiReportsForm from "./components/BiReportsForm";
import RecordingStatusList from "./components/RecordingStatusList";
import RecordingTypeList from "./components/RecordingTypeList";
import RecordingDocumentList from "./components/RecordingDocumentList";
import RecordingRejectionList from "./components/RecordingRejectionList";
import DocumentTemplateMain from "./components/DocumentTemplateMain";
import TagsList from "./components/TagsList";
import LookupNames from "@/store/lookupNames";

const ArcIntegrationWrapper = () => import(/* webpackChunkName: "arc-horizon-doc-templates" */"@/integrations/wrappers/ArcIntegrationWrapper.vue");


let routes = [
    {
        name: "cfg:doc:listCodes",
        path: "listCodes",
        component: ListCodesList,
        meta: {
            label: "List Codes",
            itemKey: "listCodeID",
            itemTypeName: "List Code",
            itemTypeDescription: "Configure the list codes for companies to be used for reporting.",
            skipSaveOnLeave: true,
            topic: Topics.DocumentsReports,
            category: Categories.Reports,
            refreshLookup: LookupNames.LIST_CODES,
            sysAdminRequired: true
        }
    },
    {
        name: "cfg:doc:reportpermissions",
        path: "reportpermissions",
        component: BiReportsForm,
        meta: {
            label: "Report Permissions",
            itemKey: "",
            itemTypeName: "Report Permission",
            itemTypeDescription: "Configure Report Permission Options",
            skipSaveOnLeave: true,
            topic: Topics.DocumentsReports,
            category: Categories.Reports,
            scrollLayout: false
        }
    },
    {
        name: "cfg:doc:reportLists",
        path: "reportLists",
        component: ComingSoon,
        meta: {
            label: "Report Lists",
            itemKey: "",
            itemTypeName: "Report List",
            itemTypeDescription: "Maintain Default System Reports",
            skipSaveOnLeave: true,
            topic: Topics.DocumentsReports,
            category: Categories.Reports
        }
    },
    {
        name: "cfg:doc:workflowDept",
        path: "workflowDept",
        component: ComingSoon,
        meta: {
            label: "Report Manager",
            itemKey: "",
            itemTypeName: "Report Manager",
            itemTypeDescription: "Maintain the structure and security for all Reports",
            skipSaveOnLeave: true,
            topic: Topics.DocumentsReports,
            category: Categories.Reports
        }
    },
    {
        name: "cfg:doc:reportSettings",
        path: "reportSettings",
        component: ReportBISettingsForm,
        meta: {
            label: "Report Settings",
            itemKey: "",
            itemTypeName: "Report Setting",
            itemTypeDescription: "Set the default Report settings",
            skipSaveOnLeave: true,
            topic: Topics.DocumentsReports,
            category: Categories.Reports
        }
    },
    {
        name: "cfg:ent:docTemplates",
        path: "docTemplates",
        component: DocumentTemplateMain,
        meta: {
            label: "Document Templates",
            itemKey: "",
            itemTypeName: "Document Template",
            itemTypeDescription: "Create, edit, categorize and group document templates.",
            // skipSaveOnLeave: true,
            topic: Topics.DocumentsReports,
            category: Categories.Documents,
            scrollLayout: true,
            permissionKeys: ["appWordDocHelper_AppAccess"],
            refreshLookup: LookupNames.DOCUMENT_CATEGORIES
        },
    },
    {
        name: "cfg:ent:docTemplates:marketplace",
        path: "marketplace",
        component: ArcIntegrationWrapper,
        meta: {
            label: "Marketplace",
            itemKey: "",
            itemTypeName: "Marketplace",
            itemTypeDescription: "Select Document Templates from a live marketplace.",
            // skipSaveOnLeave: true,
            topic: Topics.DocumentsReports,
            category: Categories.Documents,
            hideFromSearch: true,
            featureFlag: "arcIntegration"
        }
    },
    {
        name: "cfg:ent:docmgmtCat",
        path: "docmgmtCat",
        component: FileScanCategoryList,
        meta: {
            label: "Document Management Categories",
            itemKey: "",//do this to trick out the ConfigurationMain page to NOT show the add button section
            itemTypeName: "Document Management Category",
            itemTypeDescription: "Define the categories to display in Document Management.",
            skipSaveOnLeave: true,
            topic: Topics.DocumentsReports,
            category: Categories.FileScan,
            permissionKeys: ["FileScanAllowCategoryManagement"],
            refreshLookup: LookupNames.FILE_SCAN_CATEGORIES
        }
    },
    {
        name: "cfg:ent:docmgmtDesc",
        path: "docmgmtDesc",
        component: FileScanDescriptionList,
        meta: {
            label: "Document Management Descriptions",
            itemKey: "",//do this to trick out the ConfigurationMain page to NOT show the add button section
            itemTypeName: "Document Management Description",
            itemTypeDescription: "Define the descriptions to be used for document templates in Document Management.",
            skipSaveOnLeave: true,
            topic: Topics.DocumentsReports,
            category: Categories.FileScan,
            permissionKeys: ["FileScanAllowDescriptionManagement"],
            refreshLookup: LookupNames.FILE_SCAN_DESCRIPTIONS
        }
    },
    // {
    //     name: "cfg:doc:dbmgmt",
    //     path: "dbmgmt",
    //     component: ComingSoon,
    //     meta: {
    //     label: "Database Management",
    //         itemKey: "",
    //         itemTypeName: "Database Management",
    //         itemTypeDescription: "Setup the Database Storage Devices and Storage Volumes where Document Management data is saved",
    //         skipSaveOnLeave: true,
    //         topic: Topics.DocumentsReports,
    //         category: Categories.FileScan
    //     }
    // },
    {
        name: "cfg:doc:fsTaskByDesc",
        path: "fsTaskByDesc",
        component: FileScanDescriptionTaskList,
        meta: {
            label: "Document Management Task by Description",
            itemKey: "fileScanDescriptionTaskID",
            itemTypeName: "Document Management Task by Description",
            itemTypeDescription: "Define actions for tasks when using Document Management descriptions.",
            skipSaveOnLeave: true,
            topic: Topics.DocumentsReports,
            category: Categories.FileScan
        }
    },
    {
        name: "cfg:doc:fsTags",
        path: "tags",
        component: TagsList,
        meta: {
            label: "Tags",
            itemKey: "tagID",
            itemTypeName: "Tag",
            itemTypeDescription: "Define tags to be used in Document Management.",
            skipSaveOnLeave: true,
            topic: Topics.DocumentsReports,
            category: Categories.FileScan,
            sysAdminRequired: true,
            refreshLookup: LookupNames.DOCUMENT_TAGS,
        }
    },
    {
        name: "cfg:doc:recDocs",
        path: "recDocs",
        component: RecordingDocumentList,
        meta: {
            label: "Recording Documents",
            itemKey: "recordingDocumentID",
            itemTypeName: "Recording Document",
            itemTypeDescription: "Define recording documents to be used for Recording Information.",
            skipSaveOnLeave: true,
            topic: Topics.DocumentsReports,
            category:"Recording",
            refreshLookup: LookupNames.RECORDING_DOCUMENTS
        }
    },
    {
        name: "cfg:doc:recordingRejections",
        path: "recordingRejections",
        component: RecordingRejectionList,
        meta: {
            label: "Recording Rejection Reasons",
            itemKey: "recordingRejectionID",
            itemTypeName: "Recording Rejection Reasons",
            itemTypeDescription: "Define the reasons that recordable documents are rejected.",
            skipSaveOnLeave: true,
            category:"Recording",
            topic: Topics.DocumentsReports,
            refreshLookup: LookupNames.RECORDING_REJECTION_REASONS
        }
    },
    {
        name: "cfg:doc:recordingStatuses",
        path: "recordingStatuses",
        component: RecordingStatusList,
        meta: {
            label: "Recording Statuses",
            itemKey: "recordingStatusID",
            itemTypeName: "Recording Status",
            itemTypeDescription: "Define the statuses of recordings to select on the Recording page.",
            skipSaveOnLeave: true,
            category:"Recording",
            topic: Topics.DocumentsReports,
            refreshLookup: LookupNames.RECORDING_STATUSES
        }
    },
    {
        name: "cfg:doc:recordingTypes",
        path: "recordingTypes",
        component: RecordingTypeList,
        meta: {
            label: "Recording Types",
            itemKey: "recordingTypeID",
            itemTypeName: "Recording Type",
            itemTypeDescription: "Define the types of recordings to select on the Recording page.",
            skipSaveOnLeave: true,
            topic: Topics.DocumentsReports,
            category: "Recording",
            refreshLookup: LookupNames.RECORDING_TYPES
        }
    },
];

export default routes;
