<template>
    <div class="content-wrapper">
        <rq-page-section :title="itemTypeNamePlural" headerSize="lg" borderless>
             <template #header-actions>
                <ul class="nav config-actions ms-auto">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_cancel"
                            variant="action"
                            v-rq-tooltip.hover.top title="Discard changes and reload data?"
                            @click="onCancel">CANCEL</b-btn>
                    </li>
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_save"
                            variant="action"
                            @click="onSave">SAVE</b-btn>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
            <rq-section-card title="Dashboard" collapsible>
                <div class="row">
                    <div class="col col-sm-12 col-md-6 col-lg-4 form-group">
                        <label for="drp_exago_dashboard_report">Dashboard Report</label>
                        <dx-select-box
                            :input-attr="{ automation_id:'drp_exago_dashboard_report' }"
                            :items="reportList"
                            display-expr="displayName"
                            value-expr="fullPath"
                            :search-enabled="true"
                            v-model="systemDefaults.exagoDashboardReport"
                        />
                    </div>
                </div>
            </rq-section-card>
        </rq-page-section>
    </div>
</template>

<script>
    import { GlobalEventManager } from "@/app.events";
    import { computed } from "vue";
    import { mapState, mapGetters, useStore } from "vuex";
    import SystemDefaultsFormMixin from "../../shared/SystemDefaultsFormMixin";
    import { EXAGO_SESSION_ACTIONS } from "@/store/actions";

    export default {
        name: "ReportBISettingsForm",
        mixins: [SystemDefaultsFormMixin],
        computed: {
            ...mapState({
                globalRegionId: state => state.system.globalRegionId,
            }),
            ...mapGetters([
                "reportList"
            ])
        },
        created() {
            this.$store.dispatch(EXAGO_SESSION_ACTIONS.GET_REPORTS, { refresh: true });
        }
    }
</script>
