<template>
    <div class="content-wrapper">
        <rqValidationSummary :dataSource="validationErrors" />
        <rq-page-section borderless>
            <div class="row">
                <div class="col col-12 form-group text-right" >
                    <b-form-checkbox
                        automation_id="chk_isEnabled"
                        id="isEnabled"
                        v-model="item.isEnabled">Enabled</b-form-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="col col-6 form-group" >
                    <label for="template_types">Format</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_template_types', id: 'drp_template_types' }"
                        :items="templateTypes"
                        value-expr="id"
                        display-expr="name"
                        v-model="item.templateType"
                        :search-enabled="true"
                    />
                </div>
                <div class="col col-6 form-group" >
                    <label for="txt_region">Region</label>
                    <input readonly="true" automation_id="txt_region" v-model="item.regionDisplay" type="text" class="form-control" id="region" >
                </div>
            </div>
            <div class="row">
                <div class="col col-12 form-group" >
                    <label for="fromLine">From</label>
                    <input automation_id="txt_fromLine" v-model="item.fromLine" type="text" class="form-control" id="fromLine" placeholder="From Line" maxlength="1000" >
                </div>
            </div>
            <div class="row">
                <div class="col col-12 form-group" >
                    <label for="subject">Subject</label>
                    <input automation_id="txt_subject" v-model="item.subjectLine" type="text" class="form-control" id="subject" placeholder="Subject" maxlength="1000" >
                </div>
            </div>
            <div class="row">
                <div class="col col-12 form-group" >
                    <label for="rtf_template">Template</label>
                    <rq-html-editor
                        ref="htmlEditor"
                        id="rtf_template"
                        automationId="rtf_template"
                        placeholder="Template"
                        :custom-menu-items="customMenuItems"
                        :height="200"
                        v-model="item.editorTemplate"
                        include-restore
                    />
                </div>
            </div>
        </rq-page-section>
    </div>
</template>
<script>
    import { TemplateTypes } from '../../enums';
    import RqHtmlEditor from '@/shared/components/rq/editors/RqHtmlEditor';

    export default {
        name: 'EmailNotificationForm',
        components: { RqHtmlEditor },
        props: {
            item: {type: Object, default: null},
            itemTypeName: {type: String, default: null},
            mergeFields: {type: Array, default: () => []}
        },

        data() {
            return {
                validationErrors: [],
            };
        },

        computed: {
            templateTypes (){ return TemplateTypes.lookupItems; },
            customMenuItems() {
                const self = this;
                return [{
                    id: "mergefields",
                    type: "dropdown",
                    parent: "insert",
                    icon: "fas fa-brackets",
                    text: "Merge Field",
                    getSubmenuItems() {
                        return _.map(self.mergeFields, mf => ({
                            type: "menuitem",
                            text: mf.label,
                            onAction: () => _.invoke(self, "$refs.htmlEditor.insertContent", mf.value)
                        }));
                    }
                }];
            }
        },
    }
</script>