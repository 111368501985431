<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="tbl_document_template_security_users"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            @full-access="onFullAccess"
            @read-only="onReadOnly"
            @no-access="onNoAccess"
            rq-filters
            integrated-search
            fixed-header
        />
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { DocumentSecurityUserDto }  from "../models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { DefaultUserSecurity } from "@config/enums";

    export default {
        name:"DocumentTemplateUserSecurityForm",
        props: {
            documentTemplateID: { type: Number, default: 0, required: true },
            userGroups: { type: Array, default: () => [] }
        },
        data () {
            return {
                items: [],
                changedItems: {}
            };
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            regions() { return _.map(this.lookupHelpers.getRegions(), r => ({ ...r, displayLabel: `${r.regID} - ${r.description}` })); },
            selectionActions() {
                return [{
                    name: "full-access",
                    eventName: "full-access",
                    text: "Set Full Access",
                    allowMultiSelection: false,
                    tooltip: "Full Access"
                },
                {
                    name: "read-only",
                    eventName: "read-only",
                    text: "Set Read Only",
                    allowMultiSelection: true,
                    tooltip: "Read Only"
                },
                {
                    name: "noAccess",
                    eventName: "no-access",
                    text: "Set No Access",
                    allowMultiSelection: true,
                    tooltip: "No Access"
                }];
            },
            securityAccess() {
                return DefaultUserSecurity.lookupItems;
            }
        },

        created(){
            const self = this;
            self.initGridConfig();
            self.fetchData();
        },

        beforeUnmount() {
            this.$events.off(this.addEventName, this.onAddItem);
        },
        methods: {
            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        { dataField: "userFullName", dataType: "string" },
                        {
                            dataField: "regionDisplay",
                            dataType: "string",
                            calculateSortValue: DxGridUtils.regionDisplaySortValue,
                            caption: "User Default Region",
                            rqFilter: {
                                displayExpr: "displayLabel",
                                valueExpr: "displayLabel",
                                filterType: "tags",
                                dataSource: {
                                    loadMode: "raw",
                                    load() {
                                        return self.regions;
                                    }
                                }
                            }
                        },
                        {
                            dataField: "userGroupName",
                            dataType: "string",
                            caption: "User Security Role",
                            rqFilter: {
                                displayExpr: "login",
                                valueExpr: "login",
                                filterType: "tags",
                                dataSource: {
                                    loadMode: "raw",
                                    load() {
                                        return self.userGroups;
                                    }
                                }
                            }
                        },
                        {
                            dataField: "securityAccessName",
                            dataType: "string",
                            caption: "Security Access",
                            rqFilter: {
                                displayExpr: "name",
                                valueExpr: "name",
                                dataSource: {
                                    loadMode: "raw",
                                    load() {
                                        return self.securityAccess;
                                    }
                                }
                            }
                        },
                    ]
                };
                self.gridDataSource = {
                    key: "usersID",
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },

                };
            },

            fetchData() {
                const self = this;
                if(self.documentTemplateID === 0) {
                    console.warn("DocumentTemplateUserSecurityForm -- Invalid DocumentTemplateID, %s", self.documentTemplateID);
                    return;
                }
                let apiPromise = self.$api.DocumentSecurityUsersApi.getDocumentSecurityUsers(self.documentTemplateID);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new DocumentSecurityUserDto(i));
                        self.gridInstance.refresh();
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error("Error loading document template security users.");
                        return error;
                    });
            },

            onNoAccess(e){
                const self = this;
                var items = e.data;
                self.setAccess(items, null, "No Access");
                self.setChangedItems(items);
            },

            onFullAccess(e){
                const self = this;
                var items = e.data;
                self.setAccess(items, 2, "Full Access");
                self.setChangedItems(items);
            },

            onReadOnly(e){
                const self = this;
                var items = e.data;
                self.setAccess(items, 1, "Read Only");
                self.setChangedItems(items);
            },

            setChangedItems(items){
                const self = this;
                self.changedItems = items;
                let currentItems = self.changedItems.slice();
                _.remove(currentItems, item => _.some(items, { usersID: item.usersID }));
                self.changedItems = _.concat(currentItems, items);
                self.gridInstance.clearSelection();
                self.gridInstance.refresh();
            },

            setAccess(items,value,text)
            {
                const self = this;
                self.clearCurrent(items);
                _.forEach(items, (item) =>  {
                    _.set(item, "securityAccess", value);
                    _.set(item, "securityAccessName", text);
                });
            },

            clearCurrent(items){
                _.forEach(items, (item) =>  {
                    _.set(item, "securityAccess", null);
                });
            },
        }
    }
</script>
