<template>
    <div class="content-wrapper">
        <rq-banner
            variant="error"
            :title="errorMessage"
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && (v$.$error || !isImageValid)"
        />
        <div class="rq-container my-3">
            <div class="row">
                <div class="col col-12 col-lg-4 form-group form-required" :class="{ 'has-error' : v$.item.regionID.$error }">
                    <label for="region">Region</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_region' }"
                        :items="regionsLookup"
                        value-expr="regionID"
                        display-expr="displayName"
                        v-model="v$.item.regionID.$model"
                        v-focus
                        :search-enabled="true"
                    />
                    <rq-validation-feedback
                        message="Region is required."
                    />
                </div>
                <div class="col col-12 col-lg-4 form-group form-required" :class="{ 'has-error' : v$.item.description.$error || v$.item.uniqueDescription.$invalid }">
                    <label for="description">Description</label>
                    <input
                        automation_id="txt_description"
                        type="text"
                        class="form-control"
                        placeholder="Description"
                        maxlength="150"
                        v-model="v$.item.description.$model">
                    <rq-validation-feedback
                        :messages="{
                            'Description is required.': v$.item.description.$error,
                            'A Title Unit already exists with that description.': v$.item.uniqueDescription.$invalid
                        }"
                    />
                </div>
                <div class="col col-12 col-lg-4 form-group form-required" :class="{ 'has-error' : v$.item.shortDescription.$error || v$.item.uniqueName.$invalid }">
                    <label for="name">Name</label>
                    <input
                        automation_id="txt_name"
                        type="text"
                        class="form-control"
                        placeholder="Name"
                        maxlength="100"
                        v-model="v$.item.shortDescription.$model">
                    <rq-validation-feedback
                        :messages="{
                            'Name is required.': v$.item.shortDescription.$error,
                            'A Title Unit already exists with that name.': v$.item.uniqueName.$invalid
                        }"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col col-12 col-lg-4 form-group mb-0">
                    <label for="drp_file_number_pool">File Pool</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_file_number_pool' }"
                        :items="fileNumberPools"
                        value-expr="id"
                        display-expr="name"
                        :search-enabled="true"
                        v-model="v$.item.fileNumberPoolID.$model"
                    />
                </div>
                <div class="col col-12 col-lg-4 form-group mb-0">
                    <label for="prefix">Prefix</label>
                    <input
                        automation_id="txt_prefix"
                        type="text"
                        class="form-control"
                        id="prefix"
                        placeholder="Prefix"
                        maxlength="5"
                        v-model="v$.item.prefix.$model"
                    >
                </div>
                <div class="col col-12 col-lg-4 form-group mb-0">
                    <label for="suffix">Suffix</label>
                    <input
                        automation_id="txt_suffix"
                        type="text"
                        class="form-control"
                        placeholder="Suffix"
                        maxlength="5"
                        v-model="v$.item.suffix.$model"
                    >
                </div>
            </div>
            <div class="row">
                <div class="col col-12 col-lg-4">
                    <rq-page-section
                        title="Signature"
                        header-class="pb-0"
                        body-class="p-0"
                        borderless>
                        <rq-image-preview
                            ref="signatureImage"
                            class="mt-2"
                            v-model="v$.item.signatureImage.$model"
                        />
                    </rq-page-section>
                </div>
                <div class="col col-12 col-lg-8">
                    <user-member-selection
                        :key-name="itemKey"
                        :key-value="item.titleUnitID"
                        :region-id="item.regionID"
                        v-model:leader-id="v$.item.leaderUsersID.$model"
                        v-model:leader-name="v$.item.leaderDisplay.$model"
                        v-model="v$.item.memberUserIDs.$model"
                    />
                </div>
            </div>
            <slot></slot>
        </div>
    </div>

</template>
<script>
    import { mapState, mapGetters } from "vuex";
    import { required } from "validators";
    import { useVuelidate } from "@vuelidate/core";
    import { TitleUnitDto }  from "../models";
    import { ImageDataModel } from "@/shared/models/models";
    import UserMemberSelection from "../../shared/UsersMemberSelection";

    const defaultErrorMessage = "Please correct the highlighted errors on screen to continue.";

    export default {
        name: "TitleUnitsForm",
        components: { UserMemberSelection },
        props: {
            item: { type: Object, default: null },
            uniqueValidator: { type: Function, default: (() => true) }
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            const self = this;
            return {
                altError: "",
                showBanner: false,
                isImageValid: true
            }
        },

        provide(){
            return {
                imageInfoCallback: {
                    onImageChanged: (data) => {
                        if(data.validImage){
                            //reset to default behavior
                            this.showBanner = false;
                            this.isImageValid = true;
                            this.altError = "";
                        }
                        else{
                            this.showBanner = true;
                            this.isImageValid = false;
                            this.altError = data.message;
                        }

                    }
                }
            }
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "regionsLookup"
            ]),
            errorMessage() { return _.isEmpty(this.altError) ? defaultErrorMessage : this.altError; },
            itemKey() { return _.get(this, "$route.meta.itemKey") || ""; },
            imageExists () { return (this.item.signatureImage && this.item.signatureImage.dataUrl); },
            altDescriptionError() { return _.includes(this.altError, "description"); },
            altNameError() { return _.includes(this.altError, "name"); },
            fileNumberPools() { return this.lookupHelpers.getLookupItems(this.lookupItems.FILE_NUMBER_POOLS); }
        },

        validations: () => ({
            item: {
                regionID: { required },
                description: { required },
                shortDescription: { required },
                fileNumberPoolID: {},
                prefix: {},
                suffix: {},
                signatureImage: {},
                leaderUsersID: {},
                leaderDisplay: {},
                memberUserIDs: {},
                uniqueName: (val, vm) => vm.uniqueValidator(val, "shortDescription"),
                uniqueDescription: (val, vm) => vm.uniqueValidator(val, "description")
            }
        }),

        methods: {
            async save(){
                const self = this;
                self.showBanner = true;
                self.v$.$touch();
                if (!self.v$.$anyDirty) { return true; }
                if (self.v$.$error) return false;
                if(!_.includes(self.item.memberUserIDs, self.item.leaderUsersID)) {
                    self.item.leaderUsersID = null;
                }
                try{
                    let apiPromise = self.$api.TitleUnitsApi.saveTitleUnit(self.item.toDataObject());
                    await self.$rqBusy.wait(apiPromise);
                    self.$toast.success("Title unit saved successfully.");
                    return true;
                }
                catch(err) {
                    self.$toast.error("An error occurred while saving this title unit.");
                    console.error(err);
                    return false;
                }
            }
        }
    }
</script>