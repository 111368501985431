<template>
    <document-editor-dialog
        ref="editorDialog"
        :document-template-id="itemId"
        :document-name="`${clauseName}.docx`"
        :document-content="documentContent"
        :file-type="documentFileType"
        :readOnly="readOnly"
        show-advanced-toolbar
    />
</template>

<script>
    import { DocumentEditorDialog } from "@/shared/components/rq";
    import { DocumentFileType } from "@documents/enums";

    export default {
        name: "StandardLanguageEditor",
        props: {
            itemId: { type: Number, default: 0 },
            readOnly: { type: Boolean, default: false },
            clauseName: { type: String, default: "Untitled" }
        },
        components: { DocumentEditorDialog },
        data() {
            return {
                documentContent: "",
                documentFileType: DocumentFileType.DOCX,
            };
        },

        computed: {
            editorComponent() { return _.get(this, "$refs.editorDialog", null); }
        },

        created() {
            this.fetchData();
        },

        mounted() {
            if (this.readOnly) this.$emit("disable-ok");
        },

        methods: {
            fetchData() {
                const self = this;
                if(self.itemId <= 0) return;
                let apiPromise = self.$api.StandardLanguageApi.getDocxContent(self.itemId);
                self.$rqBusy.wait(apiPromise)
                    .then(content => {
                        self.documentContent = content;
                    });
            },

            save() {
                const self = this;
                if (self.itemId <= 0 || !self.editorComponent || !self.editorComponent.isDirty()) return Promise.resolve(true);
                let savePromise = self.editorComponent.getContent({ rtf: true })
                    .then(content => {
                        if(!content.changed || _.isNil(content.rtf)) return null;
                        return self.$api.StandardLanguageApi.saveContent(self.itemId, content.rtf);
                    });
                return self.$rqBusy.wait(savePromise)
                    .then(result => {
                        if(_.isNil(result)) return true;
                        self.$toast.success(`The clause, ${self.clauseName}, was saved successfully.`);
                        return true;
                    }).catch(error => {
                        self.$toast.error(`Error saving clause.`);
                        console.error(error);
                        return error;
                    });
            }
        }
    };

</script>
