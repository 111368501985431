<template>
    <div class="content-wrapper">
        <rq-banner
            variant="error"
            title="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && (v$.$error || v$.$invalid)"/>
        <div class="rq-container">
            <div class="row my-3">
                <div class="col col-auto ms-auto">
                    <b-form-checkbox
                        automation_id="chk_autoEmailYN"
                        id="autoEmailYN"
                        v-model="item.autoEmailYN"
                        >Auto Email</b-form-checkbox>
                </div>
                <div class="col col-auto">
                    <b-form-checkbox
                        automation_id="chk_isPredefinedTask"
                        id="isPredefinedTask"
                        v-model="item.isPredefinedTask"
                        >Predefined Task</b-form-checkbox>
                </div>
                <div class="col col-auto">
                    <b-form-checkbox
                        automation_id="chk_inactive"
                        id="chk_inactive"
                        v-model="item.isInActive"
                        >Inactive</b-form-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="col col-2 col-sm-6 col-md-6 col-lg-6 form-group form-required" :class="{'has-error' : v$.item.regionID.$anyDirty && v$.item.regionID.$error}">
                    <label for="region">Region</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_region', id: 'drp_region' }"
                        :items="regions"
                        :defaultItemEnabled="false"
                        v-model="item.regionID"
                        value-expr="regionID"
                        display-expr="displayName"
                        :search-enabled="true"
                    />
                    <rq-validation-feedback
                        message="Region is required."
                    />
                </div>
                <div class="col col-2 col-sm-6 col-md-6 col-lg-6 form-group form-required" :class="{'has-error' :  v$.item.taskName.$error || v$.item.uniqueTask.$invalid }">
                    <label for="taskName">Task Name</label>
                    <input
                        automation_id="txt_taskName"
                        v-model="item.taskName"
                        type="text"
                        class="form-control"
                        id="taskName"
                        placeholder="Task Name"
                        maxlength="50">
                    <rq-validation-feedback
                        :messages="{
                            'Task Name is required.': v$.item.taskName.$error,
                            'A Task already exists with that name.': v$.item.uniqueTask.$invalid
                        }"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col col-2 col-sm-12 col-md-6 col-lg-6 form-group">
                    <label for="workflowTaskBasedOnID">Based On Data Type</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_workflowTaskBasedOnID', id: 'drp_workflowTaskBasedOnID' }"
                        :items="this.workflowBasedOn"
                        :defaultItemEnabled="false"
                        v-model="item.workflowTaskBasedOnID"
                        value-expr="id"
                        display-expr="name"
                        :search-enabled="true"
                    />
                </div>
                <div class="col col-2 col-sm-12 col-md-6 col-lg-6 form-group" v-if="!suppressField">
                    <label for="associatedTask">Associated Task</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_startAfterWorkflowTaskID', id: 'drp_startAfterWorkflowTaskID' }"
                        data-text-field="data"
                        :items="this.availableTasks"
                        v-model="item.startAfterWorkflowTaskID"
                        value-expr="id"
                        display-expr="name"
                        :search-enabled="true"
                    />
                </div>
            </div>
                <div class="row">
                <div class="col col-2 col-sm-12 col-md-6 col-lg-6 form-group">
                    <label for="department">Department</label>
                    <dx-select-box
                        :element-attr="{ automation_id: 'drp_department', id: 'drp_department' }"
                        :items="this.availableDepartments"
                        v-model="item.workflowDepartmentID"
                        value-expr="id"
                        display-expr="name"
                        :search-enabled="true"
                    />
                </div>
                <div class="col col-2 col-sm-12 col-md-6 col-lg-6 form-group">
                    <label for="assignedPerson">Assigned Person</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_assignedPerson', id: 'drp_assignedPerson' }"
                        :defaultItemEnabled="false"
                        :items="this.availablePersons"
                        v-model="item.assignedPerson"
                        value-expr="id"
                        display-expr="name"
                        :search-enabled="true"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col col-2 col-sm-12 col-md-6 col-lg-6 form-group">
                    <label for="lagDays">Start Lag Days</label>
                    <rqInputNumber
                        id="txt_lagDays"
                        automation_id="txt_lagDays"
                        defaultValue="0"
                        decimals="0"
                        maxValue="1000"
                        cssClass="form-control"
                        v-model="item.startLagDays"
                        :commas="false">
                    </rqInputNumber>
                </div>
                <div class="col col-2 col-sm-6 col-md-6 col-lg-6 form-group">
                    <label for="lagHours">Start Lag Hours</label>
                    <rqInputNumber
                        id="txt_lagHours"
                        automation_id="txt_lagHours"
                        defaultValue="0"
                        decimals="0"
                        maxValue="1000"
                        cssClass="form-control"
                        v-model="item.startLagHours"
                        :commas="false">
                    </rqInputNumber>
                </div>
            </div>
            <div class="row">
                <div class="col col-2 col-sm-6 col-md-6 col-lg-6 form-group">
                    <label for="durationDays">Duration Days</label>
                    <rqInputNumber
                        id="txt_durationDays"
                        automation_id="txt_durationDays"
                        defaultValue="0"
                        decimals="0"
                        minValue="-3"
                        maxValue="1000"
                        cssClass="form-control"
                        v-model="item.durationDays"
                        :commas="false">
                    </rqInputNumber>
                </div>
                <div class="col col-2 col-sm-6 col-md-6 col-lg-6 form-group">
                    <label for="durationHours">Duration Hours</label>
                    <rqInputNumber
                        id="txt_durationHours"
                        automation_id="txt_durationHours"
                        defaultValue="0"
                        decimals="0"
                        minValue="0"
                        maxValue="1000"
                        cssClass="form-control"
                        v-model="item.durationHours"
                        :commas="false">
                    </rqInputNumber>
                </div>
                <div class="row">
                    <div class="col-12 form-group">
                        <label for="txt_notes" class="form-control-label">Notes</label>
                        <textarea id="txt_notes" automation_id="txt_notes" maxlength="2000" rows="5" name="notes"  v-model="item.notes" class="form-control"></textarea>
                    </div>
                </div>
            </div>
            <rq-section-card title="Closing Market" class="mb-0" collapsible>
                <div class="row">
                    <div class="col col-2 col-sm-12 col-md-12 col-lg-12 form-group ">
                        <b-form-checkbox
                            automation_id="chk_closingMarketAuto"
                            id="closingMarketAuto"
                            v-model="item.cmAuto"
                            >CM Auto</b-form-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-2 col-sm-4 col-md-4 col-lg-4 form-group">
                        <label for="cmEnterpriseService">Service</label>
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_cmEnterpriseService', id: 'drp_cmEnterpriseService' }"
                            :defaultItemEnabled="false"
                            :items="this.cmEnterpriseServices"
                            v-model="item.cmEnterpriseServiceID"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                        />
                    </div>
                    <div class="col col-2 col-sm-4 col-md-4 col-lg-4 form-group">
                        <label for="cmOrderCategory">File Category</label>
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_cmOrderCategory', id: 'drp_cmOrderCategory' }"
                            :defaultItemEnabled="false"
                            :items="this.cmOrderCategories"
                            v-model="item.cmOrderCategoryID"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                        />
                    </div>
                    <div class="col col-2 col-sm-4 col-md-4 col-lg-4 form-group">
                        <label for="cmEventCode">Event Code</label>
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_cmEventCode', id: 'drp_cmEventCode' }"
                            :defaultItemEnabled="false"
                            :items="this.cmEventCodes"
                            v-model="item.cmEventCodeID"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                        />
                    </div>
                </div>
            </rq-section-card>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { WorkflowBasedOnType } from '../../enums';
    import { SystemLookupItem } from '@/shared/models/models';
    import { minValue, required } from "validators";
    import { useVuelidate } from "@vuelidate/core";

    export default {
        name: 'WorkflowTasksForm',
        props: {
            item: { type: Object, default: () => ({}) },
            itemTypeName: {type: String, default: null },
            uniqueValidator: { type: Function, default: (() => true) }
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                verifiedItem: {},
                suppressField: true,
                availablePersons: [],
                sourceAvailablePersons: [],
                cmEventCodes:[],
                sourceCMEventCodes:[],
                availableTasks: [],
                sourceAvailableTasks: [],
                availableDepartments: [],
                cmEnterpriseServices: [],
                cmOrderCategories:[],
                showBanner: false
            }
        },
        validations: () => ({
            item: {
                regionID: { required, minValue: minValue(1) },
                taskName: { required },
                uniqueTask: (val, vm) => vm.uniqueValidator(val)  
            }
        }),
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            workflowBasedOn() { return _.map(WorkflowBasedOnType.lookupItems, r => ({ ...r, displayLabel: `${r.name}` })); },
            errorTaskName(){ return _.has(this.verifiedItem, "requiredTaskName"); },
            errorRegion(){ return _.has(this.verifiedItem, "requiredRegion"); },
            regions() { return this.lookupHelpers.getRegions(); }
        },
        created() {
            const self = this;
            self.fetchLookups();

            if(self.item.workflowTaskBasedOnID == 3) //always 3!
            {
                self.suppressField = false;
            }
            else
            {
                self.suppressField = true;
                self.item.startAfterWorkflowTaskID = 0;
            }
        },
        watch: {
            //Assigned Person or Workflow Department ID :: updateOrderWorkflow true
            "item.regionID" (newValue, oldValue) {
                const self = this;
                if (newValue === oldValue ) return;
                self.item.regionDisplay = "";
                let filteredValue =  _.filter(self.regions, item => item.regionID === newValue);
                self.item.regionDisplay = filteredValue[0].displayName;

            },

            "item.workflowTaskBasedOnID" (newValue, oldValue) {
                const self = this;
                if (newValue === oldValue ) return;
                self.item.workflowTaskBasedOnDisplay = "";
                if(newValue == 3)
                {
                    self.suppressField = false;
                }
                else
                {
                    self.suppressField = true;
                    self.item.startAfterWorkflowTaskID = 0;
                }
                let filteredValue =  _.filter(self.workflowBasedOn, item => item.id === newValue);
                self.item.workflowTaskBasedOnDisplay = filteredValue[0].name;
            },

            "item.startAfterWorkflowTaskID" (newValue, oldValue) {
                const self = this;
                if (newValue === oldValue ) return;
                self.item.preceedingTaskDisplay = "";
                let filteredValue =  _.filter(self.availableTasks, item => item.id === newValue);
                self.item.preceedingTaskDisplay = filteredValue[0].name;

            },

            "item.workflowDepartmentID" (newValue, oldValue) {
                const self = this;
                if (newValue === oldValue ) return;
                self.item.updateOrderWorkflow = true;
                self.item.departmentName ="";

                let filteredValue =  _.filter(self.availableDepartments, item => item.id === newValue);
                self.item.departmentName = filteredValue[0].name;

                self.availablePersons = [];
                let list = self.sourceAvailablePersons;
                let noDepartment = _.filter(list, item => item.additionalIdentity === 0);
                self.availablePersons = noDepartment;
                if(newValue > 0)
                {
                    let thisDepartment =  _.filter(list, item => item.additionalIdentity === newValue);
                    for (let i = 0; i < thisDepartment.length; i++) {
                        self.availablePersons.push(thisDepartment[i]);
                    }
                }
            },
            "item.cmOrderCategoryID" (newValue, oldValue) {
                const self = this;
                if (newValue === oldValue ) return;
                if(newValue > 0)
                {
                    self.cmEventCodes = [];
                    let list = self.sourceCMEventCodes;
                    let thisEventCodes =  _.filter(list, item => item.additionalIdentity === newValue);
                    let noneEventCode =  _.filter(list, item => item.additionalIdentity === 0);
                    self.cmEventCodes.push(noneEventCode[0]);
                    for (let i = 0; i < thisEventCodes.length; i++) {
                        self.cmEventCodes.push(thisEventCodes[i]);
                    }
                }
                if(newValue === 0)
                {
                    self.cmEventCodes= [];
                    let list = self.sourceCMEventCodes;
                    let noneEventCode =  _.filter(list, item => item.additionalIdentity === 0);
                    self.cmEventCodes.push(noneEventCode[0]);
                }
            },

            "item.assignedPerson" (newValue, oldValue) {
                const self = this;
                if (newValue === oldValue ) return;
                self.item.assignedPersonDisplay = "";
                self.item.updateOrderWorkflow = true;
                let filteredValue =  _.filter(self.availablePersons, item => item.id === newValue);
                self.item.assignedPersonDisplay = filteredValue[0].name;
            }
        },
        methods: {
            fetchLookups() {
                const self = this;
                let apiPromise = self.$api.ConfigWorkflowTasksApi.getLookups();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.availableDepartments = _.map(_.filter(result.allDepartments, ["inactive", false]), i => new SystemLookupItem(i));
                        self.sourceAvailablePersons = _.map(_.filter(result.allPersons, ["inactive", false]), i => new SystemLookupItem(i));

                        let noDepartment = _.filter(self.sourceAvailablePersons, item => item.additionalIdentity === 0);
                        self.availablePersons = noDepartment;
                        if(self.item.workflowDepartmentID > 0)
                        {
                            let thisDepartment =  _.filter(self.sourceAvailablePersons, item => item.additionalIdentity === self.item.workflowDepartmentID );
                            for (let i = 0; i < thisDepartment.length; i++) {
                                self.availablePersons.push(thisDepartment[i]);
                            }
                        }

                        self.sourceAvailableTasks = _.map(result.allTasks, i => new SystemLookupItem(i));
                        self.availableTasks = _.filter(self.sourceAvailableTasks, item => item.id != self.item.workflowTaskID);
                        self.cmEnterpriseServices = _.map(result.cmEnterpriseServices, i => new SystemLookupItem(i));
                        self.sourceCMEventCodes = _.map(result.cmEventCodes, i => new SystemLookupItem(i));

                        let eventCodes = _.filter(self.sourceCMEventCodes, item => item.additionalIdentity === self.item.cmOrderCategoryID);
                        let noneEventCode =  _.filter(self.sourceCMEventCodes, item => item.additionalIdentity === 0);
                        self.cmEventCodes = noneEventCode;
                        for(let x = 0; x< eventCodes.length; x++)
                        {
                            self.cmEventCodes.push(eventCodes[x]);
                        }

                        self.cmOrderCategories = _.map(result.cmOrderCategories, i => new SystemLookupItem(i));
                    })
                    .catch(error => {
                        self.$toast.error("Error loading Workflow Lookups.");
                        console.error(error);
                        return error;
                    });
            },
            isValid() {
                const self = this;
                self.v$.$touch();
                if (self.v$.$error || self.v$.$invalid) {
                    self.showBanner = true;
                    return false;
                }
                return true;
            }
        }
    }
</script>