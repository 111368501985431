<template>
    <rq-section-card v-show="sectionVisible" :title="label" collapsible>
        <div class="row">
            <div class="col-2 col-sm-6">
                <rq-page-section  title="Document Generation" headerSize="sm" borderless v-show="categoryVisible('Document Generation')">
                    <div class="row" v-show="fieldVisible('documentsGeneratedAsReadOnly')">
                        <div class="col">Documents generated as Read Only</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_documentsGeneratedAsReadOnly"
                                v-model="securityData.userGroupSecurity.rtfReadOnlyDocuments"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('editPromptsFilledByOtherUser')">
                        <div class="col">Edit prompts filled by other User</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_editPromptsFilledByOtherUser"
                                v-model="securityData.userGroupSecurity.allowEditPromptsFilledByOthers"
                                :disabled="isAllowEditPromptsFilledByOthers"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('restrictSentToOption')">
                        <div class="col">Restrict Send To PDF Only</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_restrictSentToOption"
                                v-model="securityData.userGroupSecurity.restrictSendToOptionsToPDFEntry"
                            />
                        </div>
                    </div>
                </rq-page-section>
                <rq-page-section  title="Document Management Locked Files" headerSize="sm" borderless v-show="categoryVisible('Document Management Locked Files')">
                    <div class="row" v-show="fieldVisible('canEditDocuments')">
                        <div class="col">Edit Document Properties</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_canEditDocuments"
                                v-model="securityData.userGroupSecurity.canEditDocuments"
                                :disabled="isCanEditDocuments"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('canOpenDocuments')">
                        <div class="col">Edit Documents</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_canOpenDocuments"
                                v-model="securityData.userGroupSecurity.canOpenDocuments"
                                :disabled="isCanOpenDocuments"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('canPrintDocuments')">
                        <div class="col">Print Documents</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_canPrintDocuments"
                                v-model="securityData.userGroupSecurity.canPrintDocuments"
                                :disabled="isCanPrintDocuments"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('canEmailDocuments')">
                        <div class="col">Email Documents</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_canEmailDocuments"
                                v-model="securityData.userGroupSecurity.canEmailDocuments"
                                :disabled="isCanEmailDocuments"
                            />
                        </div>
                    </div>
                    <!-- <div class="row" v-show="fieldVisible('publishDocuments')">
                        <div class="col">Publish Documents</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_publishDocuments"
                                v-model="securityData.userGroupSecurity.canPublishDocuments"
                            />
                        </div>
                    </div> -->
                    <div class="row" v-show="fieldVisible('canMergeDocuments')">
                        <div class="col">Merge Documents</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_canMergeDocuments"
                                v-model="securityData.userGroupSecurity.canMergeDocuments"
                                :disabled="isCanMergeDocuments"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('canDeleteDocuments')">
                        <div class="col">Delete Documents</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_canDeleteDocuments"
                                v-model="securityData.userGroupSecurity.canDeleteDocuments"
                                :disabled="isCanDeleteDocuments"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('canCopyDocuments')">
                        <div class="col">Copy Documents</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_canCopyDocuments"
                                v-model="securityData.userGroupSecurity.canCopyDocuments"
                                :disabled="isCanCopyDocuments"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('canCopyDocumentsToLocked')">
                        <div class="col">Copy Documents to Locked Files</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_canCopyDocumentsToLocked"
                                v-model="securityData.userGroupSecurity.canCopyDocumentsToLocked"
                                :disabled="isCanCopyDocumentsToLocked"
                            />
                        </div>
                    </div>
                </rq-page-section>
            </div>
            <div class="col-2 col-sm-6">
                <rq-page-section  title="Document Management" headerSize="sm" borderless  v-show="categoryVisible('Document Management')">
                    <div class="row" v-show="fieldVisible('attachFiles')">
                        <div class="col">Attach Files</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_attachFiles"
                                v-model="securityData.userGroupSecurity.fileScanCanAttachFilesAndDocuments"
                                :disabled="isFileScanCanAttachFilesAndDocuments"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('barcodeXMLImport')">
                        <div class="col">Barcode/XML Import</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_barcodeXMLImport"
                                v-model="securityData.userGroupSecurity.canUseXMLImport"
                                :disabled="isCanUseXMLImport"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('createNewFile')">
                        <div class="col">Create New File</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_createNewFile"
                                v-model="securityData.userGroupSecurity.fileScanCanCreateNewOrders"
                                :disabled="isFileScanCanCreateNewOrders"
                            />
                        </div>
                    </div>
                    <!-- <div class="row" v-show="fieldVisible('databaseManagement')">
                        <div class="col">Database Management</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_databaseManagement"
                                v-model="securityData.userGroupSecurity.fileScanAllowFileManagement"
                            />
                        </div>
                    </div> -->
                    <div class="row" v-show="fieldVisible('canReplaceDocuments')">
                        <div class="col">Can Replace Documents</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_canReplaceDocuments"
                                v-model="securityData.userGroupSecurity.canReplaceDocuments"
                                :disabled="isCanReplaceDocuments"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('usePredefinedDescriptionsOnly')">
                        <div class="col">
                            Use Predefined Descriptions Only
                        </div>
                        <div class="col">
                            <rq-switch type="stop-light"

                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_usePredefinedDescriptionsOnly"
                                v-model="securityData.userGroupSecurity.predefinedDescriptions"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('editDetails')">
                        <div class="col">Edit Details</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_editDetails"
                                v-model="securityData.userGroupSecurity.fileScanAllowEditMode"
                                :disabled="isFileScanAllowEditMode"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('editDocuments')">
                        <div class="col">Edit Documents</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_editDocuments"
                                v-model="securityData.userGroupSecurity.fileScanAllowEditDocuments"
                                :disabled="isFileScanAllowEditDocuments"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('deleteDocuments')">
                        <div class="col">Delete Documents</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_deleteDocuments"
                                v-model="securityData.userGroupSecurity.fileScanCanDeleteDocuments"
                                :disabled="isFileScanCanDeleteDocuments"
                            />
                        </div>
                    </div>
                    <!-- <div class="row" v-show="fieldVisible('allowUserToBurnToCD')">
                        <div class="col">Allow User to Burn to CD</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_allowUserToBurnToCD"
                                v-model="securityData.userGroupSecurity.fileScanAllowBurnToCD"
                            />
                        </div>
                    </div> -->
                </rq-page-section>
            </div>
        </div>
    </rq-section-card>
</template>
<script>
    import SecurityRoleSectionMixin from "./SecurityRoleSectionMixin";
    export default {
        name: "DocsReportsSection",
        mixins: [SecurityRoleSectionMixin],
        data() {
            return {
                sectionFields: [
                    { label: "Documents generated as Read Only", name: "documentsGeneratedAsReadOnly", category: "Document Generation" },
                    { label: "Edit prompts filled by other User", name: "editPromptsFilledByOtherUser", category: "Document Generation" },
                    { label: "Restrict Send To Option", name: "restrictSentToOption", category: "Document Generation" },
                    { label: "Edit Document Properties", name: "canEditDocuments", category: "Document Management Locked Files" },
                    { label: "Edit Documents", name: "canOpenDocuments", category: "Document Management Locked Files" },
                    { label: "Print Documents", name: "canPrintDocuments", category: "Document Management Locked Files" },
                    { label: "Email Documents", name: "canEmailDocuments", category: "Document Management Locked Files" },
                    // { label: "Publish Documents", name: "publishDocuments", category: "Document Management Locked Files" },
                    { label: "Merge Documents", name: "canMergeDocuments", category: "Document Management Locked Files" },
                    { label: "Delete Documents", name: "canDeleteDocuments", category: "Document Management Locked Files" },
                    { label: "Copy Documents", name: "canCopyDocuments", category: "Document Management Locked Files" },
                    { label: "Copy Documents to Locked", name: "canCopyDocumentsToLocked", category: "Document Management Locked Files" },
                    { label: "Attach Files", name: "attachFiles", category: "Document Management" },
                    { label: "Barcode/XML Import", name: "barcodeXMLImport", category: "Document Management" },
                    // { label: "Create New File", name: "createNewFile", category: "Document Management" },
                    { label: "Description Management", name: "descriptionManagement", category: "Document Management" },
                    { label: "Can Replace Documents", name: "canReplaceDocuments", category: "Document Management" },
                    { label: "Use Predefined Descriptions Only", name: "usePredefinedDescriptionsOnly", category: "Document Management" },
                    { label: "Edit Details", name: "editDetails", category: "Document Management" },
                    { label: "Edit Documents", name: "editDocuments", category: "Document Management" },
                    { label: "Delete Documents", name: "deleteDocuments", category: "Document Management" },
                    // { label: "Allow User to Burn to CD", name: "allowUserToBurnToCD", category: "Document Management" }
                ]
            };
        },
        watch:{
            isSysAdmin: function(newValue){
                this.setDefaultValues();
            },
            isAdmin: function(newValue){
                this.setDefaultValues();
            },
            isEscrowAdmin: function(newValue){
                this.setDefaultValues();
            },
            isCanOpenDocuments: function(newValue){
                if(!this.securityData.userGroupSecurity.fileScanAllowEditDocuments)
                    this.securityData.userGroupSecurity.canOpenDocuments = false;
            }
        },
        computed: {
            isAllowEditPromptsFilledByOthers() { return this.isSysAdmin || this.isAdmin },
            isCanEditDocuments() { return this.isSysAdmin || this.isAdmin },
            isCanOpenDocuments() { return !this.securityData.userGroupSecurity.fileScanAllowEditDocuments || this.isSysAdmin || this.isAdmin },
            isCanPrintDocuments() { return this.isSysAdmin || this.isAdmin },
            isCanEmailDocuments() { return this.isSysAdmin || this.isAdmin },
            isCanMergeDocuments() { return this.isSysAdmin || this.isAdmin },
            isCanDeleteDocuments() { return this.isSysAdmin || this.isAdmin },
            isCanCopyDocuments() { return this.isSysAdmin || this.isAdmin },
            isCanCopyDocumentsToLocked() { return this.isSysAdmin || this.isAdmin },
            isFileScanCanAttachFilesAndDocuments() { return this.isSysAdmin || this.isAdmin || this.isEscrowAdmin },
            isCanUseXMLImport() { return this.isSysAdmin || this.isAdmin || this.isEscrowAdmin },
            isFileScanCanCreateNewOrders() { return this.isSysAdmin || this.isAdmin || this.isEscrowAdmin },
            isCanReplaceDocuments() { return this.isSysAdmin || this.isAdmin },
            isFileScanAllowEditMode() { return this.isSysAdmin || this.isAdmin || this.isEscrowAdmin },
            isFileScanAllowEditDocuments() { return this.isSysAdmin || this.isAdmin || this.isEscrowAdmin },
            isFileScanCanDeleteDocuments() { return this.isSysAdmin || this.isAdmin },
       },
        methods: {
            setDefaultValues(){
                const self = this;
                if (self.isSysAdmin || self.isAdmin) {
                    self.securityData.userGroupSecurity.rtfReadOnlyDocuments = false;
                    self.securityData.userGroupSecurity.allowEditPromptsFilledByOthers = true;
                    self.securityData.userGroupSecurity.restrictSendToOptionsToPDFEntry = false;
                    self.securityData.userGroupSecurity.canEditDocuments = true;
                    self.securityData.userGroupSecurity.canOpenDocuments = true;
                    self.securityData.userGroupSecurity.canPrintDocuments = true;
                    self.securityData.userGroupSecurity.canEmailDocuments = true;
                    self.securityData.userGroupSecurity.canMergeDocuments = true;
                    self.securityData.userGroupSecurity.canDeleteDocuments = true;
                    self.securityData.userGroupSecurity.canCopyDocuments = true;
                    self.securityData.userGroupSecurity.canCopyDocumentsToLocked = true;
                    self.securityData.userGroupSecurity.fileScanCanAttachFilesAndDocuments = true;
                    self.securityData.userGroupSecurity.canUseXMLImport = true;
                    self.securityData.userGroupSecurity.fileScanCanCreateNewOrders = true;
                    self.securityData.userGroupSecurity.canReplaceDocuments = true;
                    self.securityData.userGroupSecurity.predefinedDescriptions = false;
                    self.securityData.userGroupSecurity.fileScanAllowEditMode = true;
                    self.securityData.userGroupSecurity.fileScanAllowEditDocuments = true;
                    self.securityData.userGroupSecurity.fileScanCanDeleteDocuments = true;
                }
                // Escrow Admin
                if (self.isEscrowAdmin && !self.isSysAdmin && !self.isAdmin) {
                    self.securityData.userGroupSecurity.rtfReadOnlyDocuments = false;
                    self.securityData.userGroupSecurity.allowEditPromptsFilledByOthers = false;
                    self.securityData.userGroupSecurity.restrictSendToOptionsToPDFEntry = false;
                    self.securityData.userGroupSecurity.canEditDocuments = true;
                    self.securityData.userGroupSecurity.canOpenDocuments = true;
                    self.securityData.userGroupSecurity.canPrintDocuments = true;
                    self.securityData.userGroupSecurity.canEmailDocuments = true;
                    self.securityData.userGroupSecurity.canMergeDocuments = true;
                    self.securityData.userGroupSecurity.canDeleteDocuments = true;
                    self.securityData.userGroupSecurity.canCopyDocuments = true;
                    self.securityData.userGroupSecurity.canCopyDocumentsToLocked = true;
                    self.securityData.userGroupSecurity.fileScanCanAttachFilesAndDocuments = true;
                    self.securityData.userGroupSecurity.canUseXMLImport = true;
                    self.securityData.userGroupSecurity.fileScanCanCreateNewOrders = true;
                    self.securityData.userGroupSecurity.canReplaceDocuments = false;
                    self.securityData.userGroupSecurity.predefinedDescriptions = false;
                    self.securityData.userGroupSecurity.fileScanAllowEditMode = true;
                    self.securityData.userGroupSecurity.fileScanAllowEditDocuments = true;
                    self.securityData.userGroupSecurity.fileScanCanDeleteDocuments = false;
                }
            }
        }
    }
</script>