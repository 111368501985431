<template>
    <div class="rq-container">
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showRequiredErrorBanner"
        />
        <div class="row" >
            <div class="col col-12 form-group form-required" :class="{'has-error' : v$.item.label.$error}">
                <label for="txt_label">LABEL</label>
                <input automation_id="txt_code" v-model="v$.item.label.$model" type="text" class="form-control" placeholder="label" maxlength="20">
                <rq-validation-feedback
                    message="Label is required."
                />                
            </div>
        </div>
       
        <div class="row" >
            <div class="col col-12 form-group form-required" :class="{'has-error' : v$.item.regionID.$error}">
               <label for="regionCombo">Region</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_region', id: 'drp_region' }"
                        value-expr="regionID"
                        display-expr="displayName"
                        :items="regions"
                        :search-enabled="false"
                        v-model="v$.item.regionID.$model"
                    />
                    <rq-validation-feedback
                        message="Region is required."
                    />
            </div>
        </div>        
        <div class="row" >
            <div class="col col-12 form-group">
                 <b-form-checkbox
                    automation_id="chk_custom_data_tab_enabled"
                    id="chk_custom_data_tab_enabled"
                    v-model="item.display">Enabled</b-form-checkbox>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapGetters } from "vuex";
    import { required, minValue } from "validators";    
    import { useVuelidate } from "@vuelidate/core";
    export default {
        name:"CustomDataTabForm",
        props: {
            item: {type: Object, default: null},
            itemTypeName: {type: String, default: null},
            uniqueValidator: { type: Function, default: (() => true) }
         },

        setup: () => ({ v$: useVuelidate() }),

        data () {
            return {
                validationErrors: [],
                verifiedItem: {},
            };
        },
        validations: () => ({
            item: {
                label: { required },
                regionID: { required, minValue: minValue(1) },                
            }
        }),
        computed: {  
            ...mapGetters([
                "lookupHelpers"               
            ]),     
            regions() { return this.lookupHelpers.getRegions(); },             
            disabled() { return !this.item.isNew;},
            showRequiredErrorBanner(){ return this.validationErrors.length > 0;},            
        },
    }
</script>
