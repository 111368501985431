<template>
    <div class="rq-container">
        <rq-banner
            variant="error"
            message="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && v$.$error"
        />
        <div class="row mt-2">
            <div class="col col-12 col-lg-6 form-group form-required" :class="{ 'has-error' : v$.workflowProcessTemplateCopy.processTemplateName.$error }">
                <label for="txt_process_template_name">Process Template Name</label>
                <input
                    id="txt_process_template_name"
                    automation_id="txt_process_template_name"
                    type="text"
                    class="form-control"
                    placeholder="Process Template Name"
                    maxlength="50"
                    spellcheck="false"
                    v-model="v$.workflowProcessTemplateCopy.processTemplateName.$model">
                <rq-validation-feedback
                    :messages="{
                        'Process Template Name is required': v$.workflowProcessTemplateCopy.processTemplateName.required.$invalid
                    }"
                />
            </div>
            <div class="col col-4 form-group form-required" :class="{ 'has-error' : v$.workflowProcessTemplateCopy.regionID.$error }">
                <label for="txt_region_name">Region</label>
                <dx-select-box
                    :input-attr="{ automation_id: 'drp_regions', id: 'drp_regions' }"
                    :items="regionSelectItems"
                    value-expr="regionID"
                    display-expr="displayName"
                    v-model="v$.workflowProcessTemplateCopy.regionID.$model"                   
                />
                <rq-validation-feedback>Region is required</rq-validation-feedback>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { required  } from "validators";
    import { useVuelidate } from "@vuelidate/core";

    export default {
        name: 'WorkflowProcessTemplateCopyForm',
        props: {
            item: { type: Object, default: null }            
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                workflowProcessTemplateCopy: null,
                showBanner: false
            };
        },
        created() {
            this.workflowProcessTemplateCopy = _.cloneDeep(this.item);
            this.workflowProcessTemplateCopy.processTemplateName = "";
            this.workflowProcessTemplateCopy.regionID = null;
        },
        computed: {
            ...mapGetters([
                "lookupHelpers"
            ]),
            regionSelectItems() { return this.lookupHelpers.getRegions(); },
        },
        validations: () => ({
            workflowProcessTemplateCopy: {
                processTemplateName: { required},
                regionID: { required },                
            }
        }),
        
        methods: {
            save() {
                const self = this;
                self.v$.$touch();
                self.showBanner = true;
                if(self.v$.$error) return Promise.resolve({ success: false });

                let apiPromise = self.$api.WorkflowProcessTemplatesApi.copyWorkflowProcessTemplate(self.workflowProcessTemplateCopy);
                return self.$rqBusy.wait(apiPromise)
                    .then(data => {
                        self.$toast.success("Workflow Process Template was copied successfully.");
                        return { data, success: true };
                    }).catch(error => {
                         if (error.errorMessage.indexOf("Please choose a different name/region") > 0) {
                            self.$toast.error(error.errorMessage);
                        } else {
                            self.$toast.error("Error copying Workflow Process Template.");
                        }
                        console.error(error);
                    });
            },
        }
    }
</script>