<template>
    <div class="content-wrapper" :key="reloadHack">
        <rq-page-section title="Report Permissions" header-size="lg" >
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
            <template #header-actions>
                <ul class="nav config-actions ms-auto">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_cancel"
                            variant="action"
                            v-rq-tooltip.hover.top title="Discard changes and reload data"
                            @click="onCancel">Cancel</b-btn>
                    </li>
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_save"
                            variant="action"
                            @click="onSave">Save</b-btn>
                    </li>
                </ul>
            </template>
        </rq-page-section>
        <div class="rq-doc-dialog rq-tv-layout">
            <div class="row">
                <div class="col col-3 col-pc-3 rq-col-tree">
                    <bi-reports-tree
                        ref="tree"
                        id="rq-reports-tree-view"
                        v-model="selectedItemKey"
                        @item-selected="onItemSelected"
                        :disabled="disableTree"
                    />
                </div>
                <div class="col col-9 col-pc-9 rq-col-form">
                    <bi-report-edit-form
                        ref="edit"
                        class="h-100"
                        v-model:item="selectedItem"
                        @update-config-error="onError"
                        @modified="onModified"
                        :lookup-users="lookupUsers"
                        :lookup-groups="lookupGroups"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BiReportsTree from "./BiReportsTree";
    import BiReportEditForm from './BiReportEditForm';
    import { GlobalEventManager } from "@/app.events";
    import { mapGetters} from "vuex";
    import { ExagoPartyType } from "../models.js";

    export default {
        name: "BiReportsForm",
        components: { BiReportsTree, BiReportEditForm },
        data () {
            const self = this;
            return {
                selectedItem: {},
                selectedItemKey: null,
                disableTree: false,
                reloadHack: 1,
                lookupUsers: [],
                lookupGroups: [],
            };
        },

        computed: {
            ...mapGetters([
                "tenantName"
            ]),
            noSelection() { return _.isEmpty(this.selectedItem); },
            noDataText() {
                if(this.noSelection) return "Select an item from the tree to the left.";
                return "";
            },
            itemTypeDescription() { return _.get(this.$route.meta, "itemTypeDescription") || ""; },
        },

        created() {
            const self = this;
            GlobalEventManager.onSave(self, self.save);

            self.initLookups();
        },

        beforeUnmount() {
            GlobalEventManager.unregister(this);
        },

        methods: {

            initLookups() {
                const self = this;

                let userPromise = self.$api.UsersApi
                    .getUsers()
                    .then((results) => {
                        let lookups =_.map(results, (entity) => {
                            return {
                                type: ExagoPartyType.User,
                                usersID: `${self.tenantName}-${_.parseNumber(entity.usersID)}`,
                                displayName: entity.displayName,
                                isInactive: _.getBool(entity, 'isInactive', false),
                            }
                        });
                        self.lookupUsers = lookups;
                    });

                let groupPromise = self.$api.UsersApi
                    .getGroups()
                    .then((results) => {
                        let lookups = _.map(results, (entity) => {
                            return {
                                type: ExagoPartyType.Group,
                                usersID: `${self.tenantName}-${_.parseNumber(entity.groupUsersID)}`,
                                displayName: entity.login,
                                isInactive: _.getBool(entity, 'isInactive', false),
                            }
                        });
                        self.lookupGroups = lookups;
                    });

                self.$rqBusy.wait(Promise.all([userPromise, groupPromise]));
            },

            onError(e) {
                const self = this;
                self.$events.emit("update-config-error", e);
            },

            onItemSelected(e) {
                const self = this;

                // Attempt to commit the grid.
                self.$refs.edit.save()
                    .then((response) => {
                        //if (response) {
                            self.selectedItem = e.selectedItem;
                        //}
                    })
            },

            getTreeState() {
                const self = this;
                return {
                    selectedKey: self.selectedItemKey,
                    selectedItem: self.selectedItem,
                    items: _.invoke(self, "$refs.tree.getFieldTreeItems", null) || [],
                };
            },

            onCancel() {
                const self = this;
                self.refresh();
            },

            onModified(e) {
                const self = this;
                self.selectedItem.isModified = true;
            },

            refresh() {
                const self = this;
                //self.reloadHack++;
                self.$refs.tree.refresh();
                self.selectedItem = {};
                self.selectedItemKey = "";
                self.$events.emit("update-config-error", { hasError: false });
            },

            onSave(e) {
                const self = this;
                return self.save(true);
            },

            save(userInitiated) {
                const self = this;

                let promise = new Promise((resolve, reject) => {
                    self.$refs.edit
                        .save()
                        .then((response) => {
                            if (!response) throw 'Error';
                        })
                        .then(() => {
                            let items = self.getTreeState().items;

                            self.$api.ExagoApi
                                .updatePermissions(items)
                                .then(() => {
                                    //self.refresh();
                                    self.disableTree = false;
                                    return resolve(true);
                                })
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })

                return self.$rqBusy.wait(promise)
                    .then((response) => {
                        self.$toast.success(`Report Permissions saved.`);
                        GlobalEventManager.saveCompleted({ success: true })
                        return true;
                    })
                    .catch((error) => {
                        self.$toast.error("Error Saving report permissions");
                        GlobalEventManager.saveCompleted({ success: false })
                        return false;
                    });
            },
        }
    };
</script>
