<template>
    <div class="row">
        <div class="col col-2 col-sm-12 col-md-6 col-lg-6 form-group">
            <label for="drp_cmServiceUsersID">Service User</label>
            <dx-select-box
                :input-attr="{ automation_id: 'drp_cmServiceUsersID', id: 'drp_cmServiceUsersID' }"
                :items="users"
                value-expr="id"
                display-expr="name"
                v-model="editedData.cmServiceUsersID"
                :search-enabled="true"
                :disabled="readOnly"
            />
        </div>
    </div>
</template>

<script>
    import { GlobalEventManager } from "@/app.events";
    import { mapState, mapGetters } from "vuex";
    import WebDefaultsFormMixin from "../../shared/WebDefaultsFormMixin";

    export default {
        name: "ClosingMarketWebDefaultsForm",
        mixins: [WebDefaultsFormMixin],
        data() {
            return {
                users: [],
            }
        },
        computed: {
            ...mapState({
                globalRegionId: state => state.system.globalRegionId,
                readOnly: state => state.isPageReadOnly,
            }),
        },
        created() {
            const self = this;

            self.fetchLookups();
        },
        methods: {
            fetchLookups() {
                const self = this;

                let allPromises = [];

                allPromises.push(
                    self.$api.UsersApi.getUsers()
                        .then((result) => {
                            let sorted = _.sortBy(result, ['login', 'displayName']);
                            self.users = _.map(sorted, i => { return { id: i.usersID, name: '(' + i.login + ') ' + i.displayName }; });
                        }));

                return Promise.all(allPromises);
                //self.$rqBusy.wait(Promise.all(allPromises));
            },
        }
    }
</script>