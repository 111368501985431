<template>
    <div class="content-wrapper">
        <rq-banner
            variant="error"
            title="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && v$.$error"
        />
        <rq-page-section borderless>
            <div class="row">
                <div class="col col-3 col-sm-12 col-md-6 col-lg-6 form-group">
                    <label for="pic_payee_company_id">Payee</label>
                    <company-picker
                        automation_id="pic_payee_company_id"
                        dialogTitle="Payee"
                        v-model="selectedPayeeCompany">
                    </company-picker>
                </div>
            </div>
        </rq-page-section>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import { ORTEpicorValidPayeeDto }  from "../models";
    import { required } from "validators";
    import { useVuelidate } from "@vuelidate/core";
    import { EpicorStateList } from "@config/enums";
    import { EpicorStateListAbbr } from "@config/enums";
    import {CompanyPicker} from "@/shared/components";

    export default {
        name: 'EpicorValidPayeesForm',
        components: { CompanyPicker },
        props: {
            item: {type: Object, default: null},
            uniqueValidator: { type: Function, default: (() => true) }
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                lookups: {bankCodes: []},
                originalData: new ORTEpicorValidPayeeDto(this.item),
                showBanner: false
            };
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),

            ...mapState({
                globalRegionId: state => state.system.globalRegionId,
            }),
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            isAdmin() { return this.item.regID === 0;},
            regionId() { return _.getNumber(this, "item.regionID", null) || -1; },
            selectedPayeeCompany: {
               get() { return { companyID: this.item.payeeCompanyID, companyName: this.item.payeeCompanyName } },
               set(val) {
                   this.item.payeeCompanyID = val && val.companyID;
                   this.item.payeeCompanyName = val && val.companyName;
               }
           },
        },

        validations: () => ({
            /*
            item: {
                regID: { required },
                description: { required },
                additionalDepositLabel: { required: (val, vm) => {
                    if(vm.isAdditionalDeposit && val === "")
                        return false;
                    else
                        return true;}
                },
                uniqueRegID: (val, vm) => vm.uniqueValidator(val, "regID"),
                uniqueDescription: (val, vm) => vm.uniqueValidator(val, "description")
            }
            */
        }),

        created() {
            this.initNonReactiveVariables();
        },

        methods: {
            save(){
                const self = this;

                self.showBanner = true;
                self.v$.$touch();

                if (self.v$.$error) return Promise.resolve({ item:null, isValid: false });

                let itemData = self.item.toDataObject();
                let changes = self.getAuditChanges(self.originalData.toDataObject(), itemData);

                if (changes.length === 0) return Promise.resolve(true);

                let apiPromise = self.$api.EpicorValidPayeesApi.saveEpicorValidPayee(itemData, changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(item => {
                        self.item.ortEpicorValidPayeeID = item.ortEpicorValidPayeeID
                        self.$toast.success(`${self.itemTypeName} was saved.`);
                        return { item, isValid: true };
                    }).catch(e => {
                        self.$toast.error({ message: `Save failed for ${self.itemTypeName}.` });
                        return { item:null, isValid: false };
                    });
            },
            onBlurDescription(e){
                this.item.description = _.trim(this.item.description);
            },
            onRegionChanged(e) {
                const self = this;
            },
            initNonReactiveVariables() {
                const self = this;
            },
            initLookups() {
                const self = this;
            },
        }
    }
</script>