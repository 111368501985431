import { ImageDataModel } from "@/shared/models/models";
import {DeliveryAction, TemplateTypes, EndorsementOptionList, CheckPrintingOptionsForUser} from "../enums";

export class ActiveUserDto {
	constructor(options) {
        options = options || {};
        this.id = _.parseNumber(options.usersID, 0);
        this.usersID = _.parseNumber(options.usersID, null);
        this.userDisplayName = options.userDisplayName || null;
        this.gfNo = options.gfNo || null;
        this.routeDescription = options.routeDescription || null;
        this.ignoreConcurrentFileLock = _.parseBool(options.ignoreConcurrentFileLock, false);
        this.lastActiveTime = options.lastActiveTime || null;
        this.loginTime = options.loginTime || null;
    }
}
export class AddressTypeDto {
    constructor(options) {
        options = options || {};
        this.addressTypeID = _.parseNumber(options.addressTypeID, 0);
        this.name = options.name || null;
        this.description = options.description || null;
        this.isSystem = _.parseBool(options.isSystem, false);
        this.inactive = _.parseBool(options.inactive, false);
    }

    get isNew() { return this.addresstypeID <= 0; }

    get validationErrors() {

        let errorList = [];
        if (_.isEmpty(this.name)) {
            errorList.push("Name is required.");
        }
        if(_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}
export class OrderCategoryDto {
	constructor(options) {
        options = options || {};
        this.orderCategoryID = _.parseNumber(options.orderCategoryID, 0);
        this.description = options.description || null;
        this.regionID = _.parseNumber(options.regionID, 0);
        this.regionDisplay = options.regionDisplay || null;
        this.inactive = _.parseBool(options.inactive, false);
    }

    get isNew() { return this.orderCategoryID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (this.regionID == 0) {
            errorList.push("Region is required.");
        }
        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}
export class FileNumberPoolDto {
	constructor(options) {
        options = options || {};
        this.fileNumberPoolID = _.parseNumber(options.fileNumberPoolID, 0);
        this.description = options.description || null;
        this.prefix = options.prefix || null;
        this.suffix = options.suffix || null;
        this.appendYearToFileNumber = _.parseBool(options.appendYearToFileNumber, false);
        this.fileNumberPadding = _.parseNumber(options.fileNumberPadding, null);
        this.nextFileNumber = _.parseNumber(options.nextFileNumber, null);
        this.regionID = _.parseNumber(options.regionID, 0);
        this.regionDisplay = options.regionDisplay || null;
    }

    get isNew() { return this.fileNumberPoolID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (this.regionID == 0) {
            errorList.push("Region is required.");
        }
        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}
export class PropertyTypeDto {
	constructor(options) {
        options = options || {};
        this.propertyTypeID = _.parseNumber(options.propertyTypeID, 0);
        this.description = options.description || null;
        this.code = options.code || null;
        this.name = options.name || null;
        this.isSystem = _.parseBool(options.isSystem);
        this.inactive = _.parseBool(options.inactive);
        this.type = _.parseNumber(options.type, 0);
    }

    get isNew() { return this.propertyTypeID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.code)) {
            errorList.push("Code is required.");
        }
        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}
export class AccountingCodeDto {
	constructor(options) {
        options = options || {};
        this.accountingCodeID = _.parseNumber(options.accountingCodeID, 0);
        this.description = options.description || null;
        this.accountCode = _.parseNumber(options.accountCode, null);
        this.codeType = options.codeType || null;
        this.regionID = _.parseNumber(options.regionID, 0);
        this.regionDisplay = options.regionDisplay || null;
        this.inactive = _.parseBool(options.inactive);
    }

    get isNew() { return this.accountingCodeID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isNil(this.accountCode) || _.parseNumber(this.accountCode, 0) == 0) {
            errorList.push("Account Code is required.");
        }
        if (this.regionID == 0) {
            errorList.push("Region is required.");
        }
        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}
export class SourceOfBusinessDto {
	constructor(options) {
        options = options || {};
        this.sourceOfBusinessID = _.parseNumber(options.sourceOfBusinessID, 0);
        this.description = options.description || null;
        this.inactive = _.parseNumber(options.inactive, 0);
    }

    get isNew() { return this.sourceOfBusinessID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.description)) {
            errorList.push("Name is required.");
        }

        return errorList;
    }


    toDataObject() { return _.toPlainObject(this); }
    toListItem() {
        return { itemID: this.sourceOfBusinessID, itemName: this.description };
    }
    static fromListItem(listItem) {
        return new SourceOfBusinessDto({ sourceOfBusinessID: listItem.itemID || 0, description: listItem.itemName || "" });
    }
}
export class TitleUnitDto {
	constructor(options) {
        options = options || {};
        this.titleUnitID = _.parseNumber(options.titleUnitID, 0);
        this.shortDescription = options.shortDescription || null;
        this.description = options.description || null;
        this.prefix = options.prefix;
        this.suffix = options.suffix;
        this.fileNumberPoolID = _.parseNumber(options.fileNumberPoolID, null);
        this.filePoolDisplay = options.filePoolDisplay;
        this.leaderUsersID = _.parseNumber(options.leaderUsersID, null);
        this.leaderDisplay = options.leaderDisplay;
        this.regionID = _.parseNumber(options.regionID, 0);
        this.regionDisplay = options.regionDisplay;
        this.scannedSignature = options.scannedSignature || null;
        this.memberUserIDs = options.memberUserIDs;
        this.signatureImage = new ImageDataModel(options.signatureImage);
    }

    get isNew() { return this.titleUnitID === 0; }

    toDataObject() {
        let result = _.omit(this, ["signatureImage"]);
        let dataUrl = this.signatureImage.dataUrl;
        result.scannedSignature = _.isEmpty(dataUrl)
            ? ''
            : dataUrl.substring(dataUrl.indexOf("base64,") + 7)
        return result;
    }
}

export class PropertyLayoutDto {
	constructor(options) {
        options = options || {};
        this.propertyLayoutID = _.parseNumber(options.propertyLayoutID, 0);
        this.description = options.description || null;
        this.regionID = _.parseNumber(options.regionID, null);
        this.regionDisplay = options.regionDisplay || null;
        this.briefLegal = options.briefLegal || null;
        this.hideAcreage = _.parseBool(options.hideAcreage);
        this.hideARBNum = _.parseBool(options.hideARBNum);
        this.hideBlock = _.parseBool(options.hideBlock);
        this.hideBook = _.parseBool(options.hideBook);
        this.hideBuilding = _.parseBool(options.hideBuilding);
        this.hideDistrict = _.parseBool(options.hideDistrict);
        this.hideInstrNum = _.parseBool(options.hideInstrNum);
        this.hideLot = _.parseBool(options.hideLot);
        this.hideMunicipality = _.parseBool(options.hideMunicipality);
        this.hideNumber = _.parseBool(options.hideNumber);
        this.hideOutLot = _.parseBool(options.hideOutLot);
        this.hidePage = _.parseBool(options.hidePage);
        this.hideParcel = _.parseBool(options.hideParcel);
        this.hidePart = _.parseBool(options.hidePart);
        this.hidePhase = _.parseBool(options.hidePhase);
        this.hidePlatname = _.parseBool(options.hidePlatname);
        this.hideQtr1 = _.parseBool(options.hideQtr1);
        this.hideQtr2 = _.parseBool(options.hideQtr2);
        this.hideQtr3 = _.parseBool(options.hideQtr3);
        this.hideRange = _.parseBool(options.hideRange);
        this.hideSection = _.parseBool(options.hideSection);
        this.hideSectionGroup = _.parseBool(options.hideSectionGroup);
        this.hideSubDivCode = _.parseBool(options.hideSubDivCode);
        this.hideTownship = _.parseBool(options.hideTownship);
        this.hideTract = _.parseBool(options.hideTract);
        this.hideUnit = _.parseBool(options.hideUnit);
        this.hideGarage = _.parseBool(options.hideGarage);
        this.hideCarport = _.parseBool(options.hideCarport);
        this.hideBoatSlip = _.parseBool(options.hideBoatSlip);
        this.hideStorage = _.parseBool(options.hideStorage);
        this.hideRecordOfSurvey = _.parseBool(options.hideRecordOfSurvey);
        this.hideMaps = _.parseBool(options.hideMaps);
        this.hideIncorporated = _.parseBool(options.hideIncorporated);
    }

    get isNew() { return this.propertyLayoutID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (this.regionID == 0) {
            errorList.push("Region is required.");
        }
        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }

        return errorList;
    }
    get verifiedItem() {
        let errorObject = {};
        if (this.regionID == 0) {
            errorObject.requiredRegion = true;
        }
        if (_.isEmpty(this.description)) {
            errorObject.requiredDesc = true;
        }

        return errorObject;
    }

    toDataObject() { return _.toPlainObject(this); }
}
export class EmailNotificationDto {
	constructor(options) {
        options = options || {};
        this.notificationEventID = _.parseNumber(options.notificationEventID, 0);
        this.name = options.name || null;
        this.isEnabled = _.parseBool(options.isEnabled);
        this.templateType = _.parseNumber(options.templateType, null);
        this.fromLine = options.fromLine || null;
        this.subjectLine = options.subjectLine || null;
        this.template = options.template || "";
        this.editorTemplate = (options.template || "").includes("<html>") ? options.template : `<html><body>${options.template}</body></html>`;
        this.regionID = _.parseNumber(options.regionID, null);
        this.regionDisplay = options.regionDisplay || null;
        this.notificationEventTypeID = options.notificationEventTypeID || null;
    }

    get isNew() { return this.notificationEventID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (this.regionID == 0) {
            errorList.push("Region is required.");
        }
        if (_.isEmpty(this.name)) {
            errorList.push("Name is required.");
        }

        return errorList;
    }
    get templateValue() { return (this.templateType === 1) ? this.templateText : this.templateHtml; }

    get templateText() { return $(this.templateHtml).text().trim(); }

    get templateHtml() { return this.editorTemplate.includes("<html>") ? this.editorTemplate : `<html><body>${this.editorTemplate}</body></html>`; }

    get templateTypeDisplay() { return TemplateTypes.displayValue(this.templateType); }

    toDataObject() { return _.omit(_.toPlainObject(this), ["editorTemplate"]); }
}

export class NoteCategoryDto{
    constructor(options) {
        options = options || {};
        this.noteCategoryID = _.parseNumber(options.noteCategoryID, 0);
        this.name = options.name || null;
        this.isSystem = _.parseBool(options.isSystem);
        this.inactive = _.parseBool(options.inactive);
    }
}

export class DuplicateSearchField{
	constructor(options) {
        options = options || {};
        this.duplicateOrderSearchSetupDetailID = _.parseNumber(options.duplicateOrderSearchSetupID, 0);
        this.duplicateOrderSearchSetupID = _.parseNumber(options.duplicateOrderSearchSetupID, 0);
        this.field = _.parseNumber(options.field, 0);
        this.description = options.description || null;
        this.required = _.parseBool(options.required, false);
        this.sequence = _.parseNumber(options.sequence,0);
    }
}

export class DuplicateSearchDto {
	constructor(options) {
        options = options || {};
        this.duplicateOrderSearchSetupID = _.parseNumber(options.duplicateOrderSearchSetupID, 0);
        this.description = options.description || null;
        this.regionID = options.regionID;
        this.regionDisplay = options.regionDisplay;
        this.numberOfRequiredFields = _.parseNumber(options.numberOfRequiredFields,0);
        this.fields = _.isArray(options.fields) ? _.map(options.fields, f => new DuplicateSearchField(f)) : [];
    }

    get isNew() { return this.duplicateOrderSearchSetupID === 0; }

    toDataObject() {
        let result = _.toPlainObject(this);
        _.omit(result, "fields");
        result.fields = _.map(this.fields, _.toPlainObject);
        return result;
    }
}
export class VestingInformationDto {
	constructor(options) {
        options = options || {};
        this.vestingInformationID = _.parseNumber(options.vestingInformationID, 0);
        this.description = options.description || null;
        this.regionID = _.parseNumber(options.regionID, 0);
        this.regionDisplay = options.regionDisplay || null;
        this.inactive = _.parseBool(options.inactive, false);
    }

    get isNew() { return this.vestingInformationID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (this.regionID == 0) {
            errorList.push("Region is required.");
        }
        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}
export class BuyerSellerRelationshipDto {
	constructor(options) {
        options = options || {};
        this.buyerSellerRelationshipID = _.parseNumber(options.buyerSellerRelationshipID, 0);
        this.description = options.description || null;
        this.relationship = options.relationship || null;
        this.regionID = _.parseNumber(options.regionID, 0);
        this.regionDisplay = options.regionDisplay || null;
        this.inactive = _.parseBool(options.inactive, false);
    }

    get isNew() { return this.buyerSellerRelationshipID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (this.regionID == 0) {
            errorList.push("Region is required.");
        }
        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }
        // if (_.isEmpty(this.relationship)) {
        //     errorList.push("Relationship is required.");
        // }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class RegionDto {
	constructor(options) {
        options = options || {};
        this.regionID = _.parseNumber(options.regionID, 0);
        this.regID =  _.parseNumber(options.regID, null);
        this.description = options.description || null;
        this.alarmBranchFilter = _.parseBool(options.alarmBranchFilter);
        this.duplicateOrderSearchSetupID = _.parseNumber(options.duplicateOrderSearchSetupID, null);
        this.duplicateOrderSearchSetupName = options.duplicateOrderSearchSetupName || "";
        this.workflowProcessTemplateID = _.parseNumber(options.workflowProcessTemplateID, null);
        this.workflowProcessTemplateName = options.workflowProcessTemplateName || "";
        this.sellerPaysPercentageOwnersPremium =  _.parseNumber(options.sellerPaysPercentageOwnersPremium, 100);
        this.buyerPaysPercentageMortgagePremium =  _.parseNumber(options.buyerPaysPercentageMortgagePremium, 100);
        this.breakoutEndorsementHUD = _.parseBool(options.breakoutEndorsementHUD);
        this.defaultLinesBetweenParagraphs = _.parseNumber(options.defaultLinesBetweenParagraphs, null);
        this.oneTimeUseCompanyNotification = options.oneTimeUseCompanyNotification || null;
        this.isCombineEndorsements = _.parseBool(options.isCombineEndorsements);
        this.paperlessCloserBaseURL = options.paperlessCloserBaseURL || null;
        this.combinePrompt = _.parseBool(options.combinePrompt);
        this.defaultSSType = _.parseNumber(options.defaultSSType, 0);
        this.isDefault = _.parseBool(options.isDefault);
        this.isAdminRegion = _.parseBool(options.isAdminRegion);
        this.isAdditionalDeposit = _.parseBool(options.isAdditionalDeposit);
        this.additionalDepositLabel = options.additionalDepositLabel || "Option Fee";
    }
    get breakoutEndorsement() { return this.breakoutEndorsementHUD ? 1 : this.isCombineEndorsements ? 2 : 0 ;}
    set breakoutEndorsement(val) {
        if (val == EndorsementOptionList.None){
            this.breakoutEndorsementHUD = false;
            this.isCombineEndorsements = false;
        }
        if (val == EndorsementOptionList.BreakoutEndorsement){
            this.breakoutEndorsementHUD = true;
            this.isCombineEndorsements = false;
        }
        if (val == EndorsementOptionList.CombineEndorsement){
            this.breakoutEndorsementHUD = false;
            this.isCombineEndorsements = true;
        }
    }

    get displayName() { return `${this.regID} - ${this.description}`; }

    get isNew() { return this.regionID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isNil(this.regID) || _.parseNumber(this.regID, -1) == -1) {
            errorList.push("Reg ID is required.");
        }
        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }
        if (this.isAdditionalDeposit === true && _.isEmpty(this.additionalDepositLabel)) {
            errorList.push("Additional Deposit Label is required.")
        }

        return errorList;
    }
    get verifiedItem() {
        let errorObject = {};
        if (_.isNil(this.regID) || _.parseNumber(this.regID, -1) == -1) {
            errorObject.requiredRegID = true;
        }
        if (_.isEmpty(this.description)) {
            errorObject.requiredDesc = true;
        }
        if (this.isAdditionalDeposit === true && _.isEmpty(this.additionalDepositLabel)) {
            errorObject.requiredDepositLabel = true;
        }

        return errorObject;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class WorkflowServiceRequestDeliveryMethodDto {
	constructor(options) {
        options = options || {};
        this.workflowServiceRequestDeliveryMethodID = _.parseNumber(options.workflowServiceRequestDeliveryMethodID, 0);
        this.description = options.description || null;
        this.deliveryAction = options.deliveryAction || null;
        this.inactiveYN = _.parseBool(options.inactiveYN);
        // this.lastModified = options.lastModified || null;
        // this.lastModifiedBy = options.lastModifiedBy || null;
    }

    get isNew() { return this.workflowServiceRequestDeliveryMethodID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }
        if (_.isEmpty(this.deliveryAction)) {
            errorList.push("Delivery Action is required.");
        }

        return errorList;
    }
    get deliveryActionName() { return DeliveryAction.displayValue(this.deliveryAction); }

    toDataObject() { return _.toPlainObject(this); }
}

export class UsersDto {
	constructor(options) {
        options = options || {};
        this.usersID = _.parseNumber(options.usersID, 0);
        this.login = options.login || null;
        this.cmPassword = options.cmPassword || null;
        this.cmUsername = options.cmUsername || null;
        this.emailAddress = options.emailAddress || null;
        this.fullName = options.fullName || null;
        this.displayName = options.displayName || null;
        this.isGroup = false;
        this.isInactive = _.parseBool(options.isInactive);
        this.branchID = _.parseNumber(options.branchID, null);
        this.branchDisplay = options.branchDisplay || null;
        this.escrowUnitID = _.parseNumber(options.escrowUnitID, null);
        this.escrowUnitFileNumberPoolID = _.parseNumber(options.escrowUnitFileNumberPoolID, null);
        this.escrowUnitDisplay = options.escrowUnitDisplay || null;
        this.regionID = _.parseNumber(options.regionID, null);
        this.regionDisplay = options.regionDisplay || null;
        this.securityPin = options.securityPin || null;
        this.titleUnitID = _.parseNumber(options.titleUnitID, null);
        this.titleUnitFileNumberPoolID = _.parseNumber(options.titleUnitFileNumberPoolID, null);
        this.titleUnitDisplay = options.titleUnitDisplay || null;
        this.groupUsersID = _.parseNumber(options.groupUsersID, null);
        this.groupDisplay = options.groupDisplay || null;
        this.phone = options.phone || null;
        this.fax = options.fax || null;
        this.isPaperlessCloserUser = _.parseBool(options.isPaperlessCloserUser);
        this.defaultCategory = _.parseNumber(options.defaultCategory, null);
        this.cellPhone = options.cellPhone || null;
        this.initials = options.initials || null;
        this.title = options.title || null;
        this.multiFactorAuthEnabled = _.parseBool(options.multiFactorAuthEnabled, false);
        this.multiFactorAuthType = _.parseNumber(options.multiFactorAuthType, null);
        this.allowOpenFileInUse = _.parseBool(options.allowOpenFileInUse);
        this.modifiedBy = options.modifiedBy || null;
        this.modifiedDate = options.modifiedDate || null;
        this.checksPrinter = options.checksPrinter || null;
        this.depositSlipPrinter = options.depositSlipPrinter || null;
        this.currentForm = _.parseNumber(options.currentForm, null);
        this.lastActive = options.lastActive || null;
        this.gfNo = options.gfNo || null;
        this.token = options.token || null;
        this.workFlowDashboardIndicator = _.parseNumber(options.workFlowDashboardIndicator, null);
        this.checkDisplay = _.parseNumber(options.checkDisplay, CheckPrintingOptionsForUser.UseSystemDefault);
        this.biRoleID = _.parseNumber(options.biRoleID, null);
        this.biRoleDisplay = options.biRoleDisplay || null;
        this.exagoDashboardReport = options.exagoDashboardReport || null;
        this.lastOrderId = _.parseNumber(options.lastOrderId, null);
        this.status = _.parseNumber(options.status, 0);
        this.hasWorkflowAutomationRole = _.parseBool(options.hasWorkflowAutomationRole, false);
    }

    get isNew() { return _.parseNumber(this.usersID, 0) === 0; }

    toDataObject() { return _.toPlainObject(this); }
}
export class UserGroupDto {
	constructor(options) {
        options = options || {};
        this.groupUsersID = _.parseNumber(options.groupUsersID, 0);
        this.login = options.login || null;
        this.isInactive = _.parseBool(options.isInactive);
        this.regionID = _.parseNumber(options.regionID, null);
        this.regionDisplay = options.regionDisplay || null;
        this.modifiedBy = options.modifiedBy || null;
        this.modifiedDate = options.modifiedDate || null;
        this.userOnboardingRoleIDs = options.userOnboardingRoleIDs || [];
    }

    get isNew() { return this.groupUsersID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isNullOrEmpty(this.login)) {
            errorList.push("Group Name is required.");
        }
        if (_.isNullOrEmpty(this.regionID)) {
            errorList.push("Region is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}
export class UserRegionBranchDto {
	constructor(options) {
        options = options || {};
        this.userRegionBranchID = _.parseNumber(options.userRegionBranchID, 0);
        this.regionID = _.parseNumber(options.regionID, null);
        this.branchID = _.parseNumber(options.branchID, null);
        this.usersID = _.parseNumber(options.usersID, 0);
        this.defaultRegionBranch = _.parseBool(options.defaultRegionBranch);
    }
}
export class UserDataDto {
	constructor(options) {
        options = options || {};
        this.user = new UsersDto(options.user);
        this.userRegionBranches = _.map(options.userRegionBranches, i => new UserRegionBranchDto(i)) || [];
        this.signature = new ImageDataModel(options.signature);
        this.userOnboardingRoleIDs = options.userOnboardingRoleIDs || [];
    }
    get isNew() { return this.user.isNew; }

    toDataObject() {
        let object = _.omit(this, ["userOnboardingRoleIDs"]);
        let result = {};
        result = _.mapValues(object, val => _.isArray(val) ? _.map(val, item => _.toPlainObject(item)) : _.toPlainObject(val));
        result.userOnboardingRoleIDs = this.userOnboardingRoleIDs;
    return result; }
}
export class UserGroupDataDto {
	constructor(options) {
        options = options || {};
        this.userGroup = new UserGroupDto(options.userGroup);
        this.userGroupSecurity = new UserGroupSecurityDto(options.userGroupSecurity);
        this.userGroupScreenAccess = new UserGroupScreenAccessDto(options.userGroupScreenAccess);
        this.userGroupApplicationAccess = new UserApplicationAccessDto(options.userGroupApplicationAccess);
    }

    get isNew() { return this.userGroup.isNew; }

    toDataObject() { return _.mapValues(this, val => _.toPlainObject(val)); }
}
export class UserGroupScreenAccessDto {
	constructor(options) {
        options = options || {};
        this.userScreenAccessID = _.parseNumber(options.userScreenAccessID, 0);
        this.usersID = _.parseNumber(options.usersID, 0);
        this.companyList = _.parseNumber(options.companyList, 0);
        this.checkWriting = _.parseNumber(options.checkWriting, 0);
        this.commitment = _.parseNumber(options.commitment, 0);
        this.genericSettlementStatement = _.parseNumber(options.genericSettlementStatement, 0);
        this.commercialSettlementStatement = _.parseNumber(options.commercialSettlementStatement, 0);
        this.depositSlip = _.parseNumber(options.depositSlip, 0);
        this.documents = _.parseNumber(options.documents, 0);
        this.rateRules = _.parseNumber(options.rateRules, 0);
        this.additionalFields = _.parseNumber(options.additionalFields, 0);
        this.prorationOverrides = _.parseNumber(options.prorationOverrides, 0);
        this.hud = _.parseNumber(options.hud, 0);
        this.invoices = _.parseNumber(options.invoices, 0);
        this.orderEntry = _.parseNumber(options.orderEntry, 0);
        this.premiums = _.parseNumber(options.premiums, 0);
        this.updateWorkflow = _.parseNumber(options.updateWorkflow, 0);
        this.subdivision = _.parseNumber(options.subdivision, 0);
        this.survey = _.parseNumber(options.survey, 0);
        this.webPublishing = _.parseNumber(options.webPublishing, 0);
        this.investmentAccountIndividual = _.parseNumber(options.investmentAccountIndividual, 0);
        this.processTemplateSetup = _.parseNumber(options.processTemplateSetup, 0);
        this.payoff = _.parseNumber(options.payoff, 0);
        this.hudChangeLog = _.parseNumber(options.hudChangeLog, 0);
        this.escrowDocuments = _.parseNumber(options.escrowDocuments, 0);
        this.policy = _.parseNumber(options.policy, 0);
        this.notes = _.parseNumber(options.notes, 0);
        this.superSearch = _.parseNumber(options.superSearch, 0);
        this.internetAlerts = _.parseNumber(options.internetAlerts, 0);
        this.cdf = _.parseNumber(options.cdf, 0);
        this.companyList = _.parseNumber(options.companyList,0);
        this.consolidated = _.parseNumber(options.consolidated,0);
        this.workflowDashboard = _.parseNumber(options.workflowDashboard,0);
        }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        //add checks later
        // if (_.isEmpty(this.user.displayName)) {
        //     errorList.push("Display Name is required.");
        // }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}
export class UserGroupSecurityDto {
	constructor(options) {
        options = options || {};
        this.userSecurityID = _.parseNumber(options.userSecurityID, 0);
        this.usersID = _.parseNumber(options.usersID, 0);
        this.isAdmin = _.parseBool(options.isAdmin, false);
        this.isCommitmentPolicyAdmin = _.parseBool(options.isCommitmentPolicyAdmin, false);
        this.isDocumentAdmin = _.parseBool(options.isDocumentAdmin, false);
        this.isEscrowAdmin = _.parseBool(options.isEscrowAdmin, false);
        this.isTemplateManager = _.parseBool(options.isTemplateManager, false);
        this.checkPostDateDays = _.parseNumber(options.checkPostDateDays, null);
        this.checkPreDateDays = _.parseNumber(options.checkPreDateDays, null);
        this.creditMemoEditing = _.parseNumber(options.creditMemoEditing, null);
        this.depositPostDateDays = _.parseNumber(options.depositPostDateDays, null);
        this.depositPreDateDays = _.parseNumber(options.depositPreDateDays, null);
        this.duplicateOrderSearchSetupID = _.parseNumber(options.duplicateOrderSearchSetupID, null);
        this.functionRestrict = _.parseNumber(options.functionRestrict, null);
        this.invoiceDeletion = _.parseNumber(options.invoiceDeletion, null);
        this.invoiceEditing = _.parseNumber(options.invoiceEditing, null);
        this.invoicePayments = _.parseNumber(options.invoicePayments, null);
        this.orderNotesPublishing = _.parseNumber(options.orderNotesPublishing, null);
        this.orderNotesSecurity = _.parseNumber(options.orderNotesSecurity, null);
        this.policyNumberCreation = _.parseNumber(options.policyNumberCreation, null);
        this.policyNumberEditing = _.parseNumber(options.policyNumberEditing, null);
        this.allowDepositDelete = _.parseBool(options.allowDepositDelete, false);
        this.allowUndoCheck = _.parseBool(options.allowUndoCheck, false);
        this.allowDeleteCheck = _.parseBool(options.allowDeleteCheck, false);
        this.allowCheckVoid = _.parseBool(options.allowCheckVoid, false);
        this.allowWires = _.parseBool(options.allowWires, false);
        this.allowChecksWhenUnbalanced = _.parseBool(options.allowChecksWhenUnbalanced, false);
        this.allowFileFundTransfer = _.parseBool(options.allowFileFundTransfer, false);
        this.allowRecreateReceiptsAndDisbursements = _.parseBool(options.allowRecreateReceiptsAndDisbursements, false);
        this.allowSetDepartmentAlarms = _.parseBool(options.allowSetDepartmentAlarms, false);
        this.allowPrintChecks = _.parseBool(options.allowPrintChecks, false);
        this.allowCreateOrder = _.parseBool(options.allowCreateOrder, false);
        this.allowSecondaryFileCreate = _.parseBool(options.allowSecondaryFileCreate, false);
        this.allowViewBranches = _.parseBool(options.allowViewBranches, false);
        this.allowFileTrackingMove = _.parseBool(options.allowFileTrackingMove, false);
        this.allowSetPaperlessCloserSecurity = _.parseBool(options.allowSetPaperlessCloserSecurity, false);
        this.allowSetPaperlessCloserUserIDAndPassword = _.parseBool(options.allowSetPaperlessCloserUserIDAndPassword, false);
        this.allowDocumentPublishToPaperlessCloser = _.parseBool(options.allowDocumentPublishToPaperlessCloser, false);
        this.allowCommitmentPolicyLogging = _.parseBool(options.allowCommitmentPolicyLogging, false);
        this.allowViewPaperlessCloserUserIDAndPassword = _.parseBool(options.allowViewPaperlessCloserUserIDAndPassword, false);
        this.allowSetFriendlyPlaceOfClosing = _.parseBool(options.allowSetFriendlyPlaceOfClosing, false);
        this.allowEscrowAdminBrowserChange = _.parseBool(options.allowEscrowAdminBrowserChange, false);
        this.allowSetMemberAgent = _.parseBool(options.allowSetMemberAgent, false);
        this.allowDepositSlipMaintenance = _.parseBool(options.allowDepositSlipMaintenance, false);
        this.allowAccessToQuickCheckQuickDepositEntry = _.parseBool(options.allowAccessToQuickCheckQuickDepositEntry, false);
        this.allowCheckWritingEscrowOutOfRegion = _.parseBool(options.allowCheckWritingEscrowOutOfRegion, false);
        this.allowFixSynchProblems = _.parseBool(options.allowFixSynchProblems, false);
        this.allowClearSynchAlarm = _.parseBool(options.allowClearSynchAlarm, false);
        this.allowEditPromptsFilledByOthers = _.parseBool(options.allowEditPromptsFilledByOthers, false);
        this.allowArchiveUnarchiveAgent = _.parseBool(options.allowArchiveUnarchiveAgent, false);
        this.allowOneTimeUseAgent = _.parseBool(options.allowOneTimeUseAgent, false);
        this.allowCheckNumberChange = _.parseBool(options.allowCheckNumberChange, false);
        this.allowFinalizedReconModifications = _.parseBool(options.allowFinalizedReconModifications, false);
        this.canAccessConsolidatedChecks =  _.parseBool(options.canAccessConsolidatedChecks, false);
        this.allowChangePayee = _.parseBool(options.allowChangePayee, false);
        this.allowEnterAgentNumber = _.parseBool(options.allowEnterAgentNumber, false);
        this.allowCheckPrintOnPaper = _.parseBool(options.allowCheckPrintOnPaper, false);
        this.allowChangeCheckToWire = _.parseBool(options.allowChangeCheckToWire, false);
        this.canLockEscrow = _.parseBool(options.canLockEscrow, false);
        this.canUnlockEscrow = _.parseBool(options.canUnlockEscrow, false);
        this.canChangeEscrowAcct = _.parseBool(options.canChangeEscrowAcct, false);
        this.canCreateReports = _.parseBool(options.canCreateReports, false);
        this.canUseOrderTemplate = _.parseBool(options.canUseOrderTemplate, false);
        this.canCancelClosing = _.parseBool(options.canCancelClosing, false);
        this.canEditClosing = _.parseBool(options.canEditClosing, false);
        this.canEnterEditEscrowMemo = _.parseBool(options.canEnterEditEscrowMemo, false);
        this.canBuildCommitPolicyPrompts = _.parseBool(options.canBuildCommitPolicyPrompts, false);
        this.canUnlockPolicyRemitDates = _.parseBool(options.canUnlockPolicyRemitDates, 0);
        this.canEditAgentsAndBuyerSeller = _.parseBool(options.canEditAgentsAndBuyerSeller, false);
        this.canUseXMLImport = _.parseBool(options.canUseXMLImport, false);
        this.canScheduleBusyTimeForClosingAreas = _.parseBool(options.canScheduleBusyTimeForClosingAreas, false);
        this.canScheduleBusyTimeForClosers = _.parseBool(options.canScheduleBusyTimeForClosers, false);
        this.canScheduleOverTentativeTime = _.parseBool(options.canScheduleOverTentativeTime, false);
        this.canModifyScheduleReminderList = _.parseBool(options.canModifyScheduleReminderList, false);
        this.checkDepositSecurityAppliesToInvestmentAccount = _.parseBool(options.checkDepositSecurityAppliesToInvestmentAccount, false);
        this.canAddChecks = _.parseBool(options.canAddChecks, false);
        this.canAddDeposits = _.parseBool(options.canAddDeposits, false);
        this.fileScanCanCreateNewOrders = _.parseBool(options.fileScanCanCreateNewOrders, false);
        this.fileScanCanImportFilesAndDocuments = _.parseBool(options.fileScanCanImportFilesAndDocuments, false);
        this.fileScanCanAttachFilesAndDocuments = _.parseBool(options.fileScanCanAttachFilesAndDocuments, false);
        this.fileScanAllowEditMode = _.parseBool(options.fileScanAllowEditMode, false);
        this.fileScanAllowEditDocuments = _.parseBool(options.fileScanAllowEditDocuments, false);
        this.fileScanAllowFileManagement = _.parseBool(options.fileScanAllowFileManagement, false);
        this.fileScanAllowBlindVerification = _.parseBool(options.fileScanAllowBlindVerification, false);
        this.anticipatedDeposits = _.parseNumber(options.anticipatedDeposits, null);
        this.rtfReadOnlyDocuments = _.parseBool(options.rtfReadOnlyDocuments, false);
        this.forcePayeeOnChecks = _.parseBool(options.forcePayeeOnChecks, false);
        this.showFileStatusOverDueItems = _.parseBool(options.showFileStatusOverDueItems, false);
        this.depositChangeOption = _.parseNumber(options.depositChangeOption, null);
        this.allowAddInvestmentAccount = _.parseBool(options.allowAddInvestmentAccount, 1);
        this.allowDeleteInvestmentAccount = _.parseBool(options.allowDeleteInvestmentAccount, 1);
        this.allowTransferToInvestmentAccount = _.parseBool(options.allowTransferToInvestmentAccount, 1);
        this.allowTransferFromInvestmentAccount = _.parseBool(options.allowTransferFromInvestmentAccount, 1);
        this.schedulerDefaultReminder = _.parseBool(options.schedulerDefaultReminder, false);
        this.schedulerDefaultReminderTime = _.parseNumber(options.schedulerDefaultReminderTime, null);
        this.schedulerBusinessDaySunday = _.parseBool(options.schedulerBusinessDaySunday, false);
        this.schedulerBusinessDayMonday = _.parseBool(options.schedulerBusinessDayMonday, false);
        this.schedulerBusinessDayTuesday = _.parseBool(options.schedulerBusinessDayTuesday, false);
        this.schedulerBusinessDayWednesday = _.parseBool(options.schedulerBusinessDayWednesday, false);
        this.schedulerBusinessDayThursday = _.parseBool(options.schedulerBusinessDayThursday, false);
        this.schedulerBusinessDayFriday = _.parseBool(options.schedulerBusinessDayFriday, false);
        this.schedulerBusinessDaySaturday = _.parseBool(options.schedulerBusinessDaySaturday, false);
        this.schedulerBusinessStartTime = options.schedulerBusinessStartTime || null;
        this.schedulerBusinessEndTime = options.schedulerBusinessEndTime || null;
        this.schedulerCalendarStartTime = options.schedulerCalendarStartTime || null;
        this.schedulerCalendarEndTime = options.schedulerCalendarEndTime || null;
        this.schedulerReminderDuration = _.parseNumber(options.schedulerReminderDuration, null);
        this.allowAddWorkflowTask = _.parseBool(options.allowAddWorkflowTask, false);
        this.allowEditWorkflowTask = _.parseBool(options.allowEditWorkflowTask, false);
        this.allowDeleteWorkflowTask = _.parseBool(options.allowDeleteWorkflowTask, false);
        this.allowCommitmentPolicyLoggingAccess = _.parseBool(options.allowCommitmentPolicyLoggingAccess, false);
        this.allowHUDLoggingAccess = _.parseBool(options.allowHUDLoggingAccess, false);
        this.allowCompanyLoggingAccess = _.parseBool(options.allowCompanyLoggingAccess, false);
        this.allowEscrowLoggingAccess = _.parseBool(options.allowEscrowLoggingAccess, false);
        this.allowWebUsageLogggingAccess = _.parseBool(options.allowWebUsageLogggingAccess, false);
        this.allowDocumentPromptLoggingAccess = _.parseBool(options.allowDocumentPromptLoggingAccess, false);
        this.allowSchedulerLoggingAccess = _.parseBool(options.allowSchedulerLoggingAccess, false);
        this.fileScanCanDeleteDocuments = _.parseBool(options.fileScanCanDeleteDocuments, false);
        this.allowCheckStop = _.parseBool(options.allowCheckStop, false);
        this.invoicePreDateDays = _.parseNumber(options.invoicePreDateDays, null);
        this.invoicePostDateDays = _.parseNumber(options.invoicePostDateDays, null);
        this.paymentPreDateDays = _.parseNumber(options.paymentPreDateDays, null);
        this.paymentPostDateDays = _.parseNumber(options.paymentPostDateDays, null);
        this.escrowLockOption = _.parseNumber(options.escrowLockOption, null);
        this.canDeleteBookedDeposits = _.parseBool(options.canDeleteBookedDeposits, false);
        this.editPolicyPools = _.parseBool(options.editPolicyPools, false);
        this.createNewPolicyNumberPools = _.parseBool(options.createNewPolicyNumberPools, false);
        this.allowChangePayeeNameOnInvoice = _.parseBool(options.allowChangePayeeNameOnInvoice, false);
        this.allowMakeDefaultInvoiceLines = _.parseBool(options.allowMakeDefaultInvoiceLines, false);
        this.allowPaperlessCloserPublishDocuments = _.parseBool(options.allowPaperlessCloserPublishDocuments, false);
        this.checkPrintingOption = _.parseNumber(options.checkPrintingOption, null);
        this.fileScanAllowCategoryManagement = _.parseBool(options.fileScanAllowCategoryManagement, false);
        this.allowMakeCSSDefaultTemplate = _.parseBool(options.allowMakeCSSDefaultTemplate, false);
        this.promptForCategory = _.parseBool(options.promptForCategory, false);
        this.predefinedDescriptions = _.parseBool(options.predefinedDescriptions, false);
        this.fileScanAllowDescriptionManagement = _.parseBool(options.fileScanAllowDescriptionManagement, false);
        this.allowEditCompanyDetails = _.parseBool(options.allowEditCompanyDetails, false);
        this.allowEditContacts = _.parseBool(options.allowEditContacts, false);
        this.schedulerDefaultDuration = _.parseNumber(options.schedulerDefaultDuration, null);
        this.canViewSSN = _.parseNumber(options.canViewSSN, 1);
        this.canEditSSN = options.canEditSSN || null;
        this.canEditPositivePay = _.parseBool(options.canEditPositivePay, false);
        this.canProcessPositivePay = _.parseBool(options.canProcessPositivePay, false);
        this.allowEditSalesRep = _.parseBool(options.allowEditSalesRep, false);
        this.markFileGlobal = _.parseBool(options.markFileGlobal, false);
        this.allowDeleteCheckFromEscrowAccounting = _.parseBool(options.allowDeleteCheckFromEscrowAccounting, false);
        this.manageSchedulerFilters = _.parseBool(options.manageSchedulerFilters, false);
        this.allowVoidTransfers = _.parseBool(options.allowVoidTransfers, false);
        this.orderLockOption = _.parseNumber(options.orderLockOption, null);
        this.allowEditRecordingInformation = _.parseBool(options.allowEditRecordingInformation, false);
        this.allowInitiateDataConversion = _.parseBool(options.allowInitiateDataConversion, false);
        this.useExpectedWireOutDate = _.parseBool(options.useExpectedWireOutDate, false);
        this.useOutlookForEmail = _.parseBool(options.useOutlookForEmail, false);
        this.allowEditEndorsementSettlementFees = _.parseBool(options.allowEditEndorsementSettlementFees, false);
        this.allowConsolidatedDisbursementAdjustment = _.parseBool(options.allowConsolidatedDisbursementAdjustment, false);
        this.allowDepositAdjustment = _.parseBool(options.allowDepositAdjustment, false);
        this.includeAdjustmentTypeFunds = _.parseBool(options.includeAdjustmentTypeFunds, false);
        this.allowAddEditRates = _.parseBool(options.allowAddEditRates, false);
        this.allowChangeBranch = _.parseBool(options.allowChangeBranch, false);
        this.allowChangeRegion = _.parseBool(options.allowChangeRegion, false);
        this.canProcessEBankRecon = _.parseBool(options.canProcessEBankRecon, false);
        this.restrictSendToOptionsToPDFEntry = _.parseBool(options.restrictSendToOptionsToPDFEntry, false);
        this.allowChangeTaxRateOnInvoice = _.parseBool(options.allowChangeTaxRateOnInvoice, false);
        this.allowResetHUDorCDF = _.parseBool(options.allowResetHUDorCDF, false);
        this.orderRateNotesSecurity = _.parseNumber(options.orderRateNotesSecurity, null);
        this.allowCDFLoggingAccess = _.parseBool(options.allowCDFLoggingAccess, false);
        this.allowDisbursementWithoutGoodFunds = _.parseBool(options.allowDisbursementWithoutGoodFunds, false);
        this.allowAnonymousAccess = _.parseBool(options.allowAnonymousAccess, false);
        this.voidCheckDateDays = _.parseNumber(options.voidCheckDateDays, null);
        this.allowRemoveColumnInOrderWorkflow = _.parseBool(options.allowRemoveColumnInOrderWorkflow, false);
        this.updateRealtorsInOrderEntry = _.parseBool(options.updateRealtorsInOrderEntry, false);
        this.allowCSSLoggingAccess = _.parseBool(options.allowCSSLoggingAccess, false);
        this.seeOthersInBranchesGlobal = _.parseBool(options.seeOthersInBranchesGlobal, false);
        this.fileScanAllowBurnToCD = _.parseBool(options.fileScanAllowBurnToCD, false);
        this.allowMaskTaskNA = _.parseBool(options.allowMaskTaskNA, false);
        this.canChangeCSSType = _.parseBool(options.canChangeCSSType, false);
        this.canEditDocuments = _.parseBool(options.canEditDocuments, false);
        this.canOpenDocuments = _.parseBool(options.canOpenDocuments, false);
        this.canPrintDocuments = _.parseBool(options.canPrintDocuments, false);
        this.canEmailDocuments = _.parseBool(options.canEmailDocuments, false);
        this.canPublishDocuments = _.parseBool(options.canPublishDocuments, false);
        this.canMergeDocuments = _.parseBool(options.canMergeDocuments, false);
        this.canDeleteDocuments = _.parseBool(options.canDeleteDocuments, false);
        this.canReplaceDocuments = _.parseBool(options.canReplaceDocuments, false);
        this.canCopyDocuments = _.parseBool(options.canCopyDocuments, false);
        this.canCopyDocumentsToLocked = _.parseBool(options.canCopyDocumentsToLocked, false);
        this.allowResetCSS = _.parseBool(options.allowResetCSS, false);
        this.allowAddPredefinedTasksOnly = _.parseBool(options.allowAddPredefinedTasksOnly, 0);
        this.allowAddEditRecordingFees = _.parseBool(options.allowAddEditRecordingFees, false);
        this.allowAddEditTransferTaxes = _.parseBool(options.allowAddEditTransferTaxes, false);
        this.selectAltVendor = _.parseBool(options.selectAltVendor, 0);
        this.editCompaniesOutsideUsersRegion = _.parseBool(options.editCompaniesOutsideUsersRegion, false);
        this.allowDeleteLoan = _.parseBool(options.allowDeleteLoan, 0);
        this.allowLiveChat = _.parseBool(options.allowLiveChat, false);
        this.sendChecksToFileScan = _.parseBool(options.sendChecksToFileScan, 0);
        this.wireOutRequirement = _.parseNumber(options.wireOutRequirement, 0);
        this.fileScanCheckCategory = _.parseNumber(options.fileScanCheckCategory, null);
        this.canVoidPolicies = _.parseBool(options.canVoidPolicies, false);
        this.workFlowDashboardIndicator = options.workFlowDashboardIndicator || null;
        this.allowOpenFileInUse = _.parseBool(options.allowOpenFileInUse);
        this.editTaskStartDate = _.parseBool(options.editTaskStartDate);
        this.intentionallyDeletedClauses = _.parseNumber(options.intentionallyDeletedClauses, null);
        this.canChangeFileNumber = _.parseBool(options.canChangeFileNumber, false);
        this.canEnterEditAccountingEscrowMemo = _.parseBool(options.canEnterEditAccountingEscrowMemo, false);
        this.allowCreateAdvance = _.parseBool(options.allowCreateAdvance, false);
        this.allowCreateLoss = _.parseBool(options.allowCreateLoss, false);
        this.allowAutomationBuilder = _.parseBool(options.allowAutomationBuilder, false);
        this.securityPinRequired = _.parseBool(options.securityPinRequired, false);
        this.titleProductionSecurityPinRequired = _.parseBool(options.titleProductionSecurityPinRequired, false);
    }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        //add checks later
        // if (_.isEmpty(this.user.displayName)) {
        //     errorList.push("Display Name is required.");
        // }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}
export class RoleSettingDto {
	constructor(options) {
        options = options || {};
    }

    get isNew() { return this.buyerSellerRelationshipID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        // if (this.regionID == 0) {
        //     errorList.push("Region is required.");
        // }
        // if (_.isEmpty(this.description)) {
        //     errorList.push("Description is required.");
        // }
        // if (_.isEmpty(this.relationship)) {
        //     errorList.push("Relationship is required.");
        // }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}
export class UserApplicationAccessDto {
	constructor(options) {
        options = options || {};
        this.userApplicationAccessID = _.parseNumber(options.userApplicationAccessID,0);
        this.usersID = _.parseNumber(options.usersID,0);
        this.app1099 = _.parseBool(options.app1099);
        this.appTracker = _.parseBool(options.appTracker);
        this.appStandardLanguage = _.parseBool(options.appStandardLanguage);
        this.appWordDocHelper = _.parseBool(options.appWordDocHelper);
        this.appReports = _.parseBool(options.appReports);
        this.appEscrowAccounting = _.parseBool(options.appEscrowAccounting);
        this.appFileScan = _.parseBool(options.appFileScan);
        this.appScheduler = _.parseBool(options.appScheduler);
        this.appInternetAlerts = _.parseBool(options.appInternetAlerts);
        this.appPositivePay = _.parseBool(options.appPositivePay);
        this.appEBankRecon = _.parseBool(options.appEBankRecon);
        this.appWorkflowDashboard = _.parseBool(options.appWorkflowDashboard);
        this.appBatchInvoicePayments = _.parseBool(options.appBatchInvoicePayments);
        this.appFinancialApprovals = _.parseBool(options.appFinancialApprovals);
        this.appPortfolioManager = _.parseBool(options.appPortfolioManager);
    }

    get isNew() { return this.userApplicationAccessID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}


export class StaffDto {
	constructor(options) {
        options = options || {};
        this.staffID = _.parseNumber(options.staffID,0);
        this.cellPhone = options.cellPhone || null;
        this.directPhone = options.directPhone || null;
        this.eMailAddress = options.eMailAddress || null;
        this.initials = options.initials || null;
        this.name = options.name || null;
        this.regionID = options.regionID || null;
        this.usersID = _.parseNumber(options.usersID,0);
        this.scannedSignature = options.scannedSignature || null;
        this.fax = options.fax || null;
        this.createdFromUserCopy = _.parseBool(options.createdFromUserCopy, false);
        this.title = options.title || null;
        this.isInactive = _.parseBool(options.isInactive);
        this.fullName = options.fullName || null;
        this.regionIDs = options.regionIDs || [];
        this.jobDutyIDs = options.jobDutyIDs || [];
        this.notary = new NotaryDto(options.notary);
        this.notary.staffID = this.staffID;
        this.signatureImage = new ImageDataModel(options.signatureImage);
    }

    toDataObject() {
        let result = _.omit(this, ["signatureImage"]);
        let dataUrl = this.signatureImage.dataUrl;
        result.scannedSignature = _.isEmpty(dataUrl)
            ? ''
            : dataUrl.substring(dataUrl.indexOf("base64,") + 7)
        return result;
    }
}


export class NotaryDto {
	constructor(options) {
        options = options || {};
        this.notaryID = _.parseNumber(options.notaryID,0);
        this.commissionName = options.commissionName || null;
        this.regionID = options.regionID || null ;
        this.commissionExpires = options.commissionExpires || null;
        this.commissionNumber = options.commissionNumber || null;
        this.state = options.state || null;
        this.countyID = options.countyID || null;
        this.additionalState = options.additionalState || null;
        this.additionalCountyID = options.additionalCountyID || null;
        this.additionalCommissionNumber = options.additionalCommissionNumber || null;
        this.signature = options.signature || null;
        this.staffID = _.parseNumber(options.staffID,0);
        this.signatureImage = new ImageDataModel(options.signatureImage);
    }

    get isNew() { return this.notaryID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        return errorList;
    }

    toDataObject() {
        let result = _.omit(this, ["signatureImage"]);
        let dataUrl = this.signatureImage.dataUrl;
        result.signature = _.isEmpty(dataUrl)
            ? ''
            : dataUrl.substring(dataUrl.indexOf("base64,") + 7)
    return result; }
}

export class CompanyRole {
	constructor(options) {
        options = options || {};
        this.roleTypeID = _.parseNumber(options.roleTypeID, 0);
        this.name = options.name || null;
        this.isSystem = _.parseBool(options.isSystem);
        this.inactive = _.parseBool(options.inactive);
        this.isInUse = _.parseBool(options.isInUse)
    }

    get isNew() { return this.roleTypeID === 0; }

    toDataObject() { return _.toPlainObject(this); }
}

export class ClosingType {
	constructor(options) {
        options = options || {};
        this.closingTypeID = _.parseNumber(options.closingTypeID, 0);
        this.name = options.name || null;
        this.description = options.description || null;
        this.isSystem = _.parseBool(options.isSystem);
        this.inactive = _.parseBool(options.inactive);
    }

    get isNew() { return this.closingTypeID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.name)) {
            errorList.push("Name is required.");
        }
        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }
        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}
export class MortgageType {
	constructor(options) {
        options = options || {};
        this.mortgageTypeID = _.parseNumber(options.mortgageTypeID, 0);
        this.name = options.name || null;
        this.description = options.description || null;
        this.isSystem = _.parseBool(options.isSystem);
        this.inactive = _.parseBool(options.inactive);
    }

    get isNew() { return this.mortgageTypeID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.name)) {
            errorList.push("Name is required.");
        }
        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }
        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}
export class TransactionType {
	constructor(options) {
        options = options || {};
        this.transactionTypeID = _.parseNumber(options.transactionTypeID, 0);
        this.name = options.name || null;
        this.description = options.description || null;
        this.isSystem = _.parseBool(options.isSystem);
        this.inactive = _.parseBool(options.inactive);
    }

    get isNew() { return this.transactionTypeID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.name)) {
            errorList.push("Name is required.");
        }
        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }
        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class DoNotDoBusinessWithDto {
    constructor(options){
        options = options || {};
        this.doNotDoBusinessWithID = _.parseNumber(options.doNotDoBusinessWithID, 0);
        this.effectiveDate = options.effectiveDate || null;
        this.expirationDate = options.expirationDate || null;
        this.name = options.name || null;
        this.address = options.address || null;
        this.address1 = options.address1 || null;
        this.address2 = options.address2 || null;
        this.city = options.city || null;
        this.state = options.state || null;
        this.zip = options.zip || null;
        this.entity = options.entity || null;
        this.company = options.company || null;
        this.type = _.parseNumber(options.type, null);
        this.source = options.source || null;
        this.isInactive = options.isInactive || false;
        this.regionIDs = options.regionIDs || null;
        this.branchIDs = options.branchIDs || null;
        this.files = options.files || [];
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class DoNotDoBusinessWithFileDto{
    constructor(options){
        this.doNotDoBusinessWithFileID = options?.doNotDoBusinessWithFileID || 0;
        this.doNotDoBusinessWithID = options?.doNotDoBusinessWithID || 0;
        this.fileName = options?.fileName || null;
        this.fileType = options?.fileType || null;
        this.fileSizeBytes = options?.fileSizeBytes || 0;
        this.dataUrl = options?.dataUrl || null;
        this.updated = options?.updated || false;
    }

    toDataObject() { return _.toPlainObject(this); }
}
export class DoNotDoBusinessWithSearchRequestDto {
	constructor(options) {
        options = options || {};
        this.effectiveDateFrom = options.effectiveDateFrom || null;
        this.effectiveDateTo = options.effectiveDateTo || null;
        this.name = options.name || null;
        this.address = options.address || null;
        this.type = options.type || null;
        this.source = options.source || null;
        this.regionIDs = options.regionIDs || [];
        this.branchIDs = options.branchIDs || []
    }
}

export class SigningStatus {
	constructor(options) {
        options = options || {};
        this.signingStatusID = _.parseNumber(options.signingStatusID, 0);
        this.name = options.name || null;
        this.description = options.description || null;
        this.isSystem = _.parseBool(options.isSystem);
        this.inactive = _.parseBool(options.inactive);
    }

    get isNew() { return this.signingStatusID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.name)) {
            errorList.push("Name is required.");
        }
        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }
        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}
export class MortgageClauseType {
	constructor(options) {
        options = options || {};
        this.mortgageClauseTypeID = _.parseNumber(options.mortgageClauseTypeID, 0);
        this.name = options.name || null;
        this.description = options.description || null;
        this.isSystem = _.parseBool(options.isSystem);
        this.inactive = _.parseBool(options.inactive);
    }

    get isNew() { return this.mortgageTypeID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.name)) {
            errorList.push("Name is required.");
        }
        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }
        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class OrderTimeTrackingCategory{
	constructor(options) {
        options = options || {};
        this.orderTimeTrackingCategoryID = _.parseNumber(options.orderTimeTrackingCategoryID, 0);
        this.usStatesID = _.parseNumber(options.usStatesID, 0);
        this.name = options.name || null;
        this.description = options.description || null;
        this.displayOrder = _.parseNumber(options.displayOrder, 0);
        this.inactive = _.parseBool(options.inactive);
    }

    get isNew() { return this.orderTimeTrackingCategoryID === 0; }
    get isValid() { return this.validationErrors.length === 0; }
    get validationErrors() {
        let errorList = [];

        if (_.isEmpty(this.usStatesID)) {
            errorList.push("State is required.");
        }

        if (_.isEmpty(this.name)) {
            errorList.push("Name is required.");
        }

        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }
        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }

}

export class OrderTimeTrackingAccountCode{
	constructor(options) {
        options = options || {};
        this.orderTimeTrackingAccountCodeID = _.parseNumber(options.orderTimeTrackingAccountCodeID, 0);
        this.usStatesID = _.parseNumber(options.usStatesID, 0);
        this.accountCode = options.accountCode || null;
        this.description = options.description || null;
        this.displayOrder = _.parseNumber(options.displayOrder, 0);
        this.inactive = _.parseBool(options.inactive);
    }

    get isNew() { return this.orderTimeTrackingAccountCodeID === 0; }
    get isValid() { return this.validationErrors.length === 0; }
    get validationErrors() {
        let errorList = [];

        if (_.isEmpty(this.usStatesID)) {
            errorList.push("State is required.");
        }

        if (_.isEmpty(this.accountCode)) {
            errorList.push("Account code is required.");
        }

        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }
        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }

}
