<template>
    <rqdx-action-data-grid
        ref="dataGrid"
        automation_id="tbl_clause_packages"
        class="tab-header"
        :actions="selectionActions"
        :config="gridConfig"
        :data-source="gridDataSource"
        @edit="onEditItem"
        @rowDoubleClick="onEditItem"
        @delete="onDeleteItems"
        integrated-search
        rq-filters
    />
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { StandardLanguagePackageDto }  from "../../models";
    import GridInvokerMixin from "@/shared/mixins/GridInvokerMixin";
    import ClausePackageForm from "./ClausePackageForm";
    import { DxGridUtils } from '@/shared/utilities';

    export default {
        name:"StandardLanguagePackageList",

        mixins: [ GridInvokerMixin({ grid:"dataGrid" }) ],

        props: {
            categories: { Type: Array, default: () => [] }
        },

        data() {
            return {
                items: [],
                dataLoaded: false
            };
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            regions() { return this.lookupHelpers.getRegions(); },
            selectionActions() {
                return [
                    { name: "edit", text: "Edit", eventName: "edit", requireSelection: true, tooltip: "Edit Package" },
                    { name: "delete", text: "Delete", eventName: "delete", allowMultiSelection: true, tooltip: "Delete Package" }
                ];
            }
        },

        watch: {
             validationErrors: function () {
                const self = this;
                self.$events.emit("update-config-error", { message: "Please correct the highlighted errors on screen to continue.", hasError: self.validationErrors.length > 0 });
            },
        },

        created() {
            this.initGridConfig();
        },

        methods: {
            fetchData () {
                const self = this;
                let apiPromise = self.$api.StandardLanguageApi.getPackages();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, item => new StandardLanguagePackageDto(item));
                        self.dataLoaded = true;
                        return self.items;
                    }).catch(error => {
                        self.$toast.error("Error Loading Clause Packages.");
                        console.error(error);
                        return error;
                    });
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [{
                        dataField: "regionID",
                        caption: "Region",
                        dataType: "number",
                        calculateSortValue: rowData => {
                                let rowRegion = _.find(self.regions, r => r.regionID === rowData.regionID) || {};
                                return rowRegion.regID || "";
                            },
                        lookup: {
                            loadMode: "raw",
                            dataSource: self.regions,
                            displayExpr: "displayName",
                            valueExpr: "regionID"
                        }
                    },
                    {
                        dataField: "name",
                        dataType: "string"
                    },
                    {
                        dataField: "description",
                        dataType: "string",
                        editorOptions: { maxLength: 50 }
                    },
                    {
                        dataField: "standardLanguageCategoryID",
                        caption: "Category",
                        dataType: "number",
                        lookup: {
                            loadMode: "raw",
                            dataSource: self.categories,
                            displayExpr: "name",
                            valueExpr: "id"
                        },
                        ...DxGridUtils.lookupSortDisplayExpr,
                    }]
                };

                self.gridDataSource = {
                    key: "id",
                    load: () => self.dataLoaded
                        ? Promise.resolve(self.items)
                        : self.fetchData()
                };
            },

            onAddItem() {
                this.launchFormDialog();
            },

            onEditItem(e) {
                this.launchFormDialog(e.data);
            },

            onDeleteItems(e) {
                const self = this;
                self.$dialog.confirm("Confirm", "Delete selected clause packages?", () => {
                    let itemKeys = _.map(e.data, item => item.id);
                    self.deleteItems(itemKeys);
                    return true;
                });
            },

            removeItems(keys) {
                const self = this;
                if(!_.isArray(keys) || _.isEmpty(keys)) return;
                _.forEach(keys, key => {
                    let itemIndex = _.findIndex(self.items, item => item.id === key);
                    if(itemIndex < 0) return;
                    self.items.splice(itemIndex, 1);
                });
                self.refresh();
            },

            updateItem(itemData, refresh=true) {
                const self = this;
                let itemInstance = new StandardLanguagePackageDto(itemData);
                let itemIndex = _.findIndex(self.items, { id: itemInstance.id });
                if(itemIndex < 0)
                    self.items.push(itemInstance);
                else
                    self.items[itemIndex] = itemInstance;

                if(!refresh) return itemInstance;

                self.refresh();
                return itemInstance;
            },

            deleteItems(keys) {
                const self = this;
                let apiPromise = self.$api.StandardLanguageApi.deletePackages(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.removeItems(keys);
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error("Error deleting clause package(s).");
                        console.error(error);
                        return false;
                    });
            },

            launchFormDialog(item=null) {
                const self = this;

                let dialogId = null;
                let onOk = (e, addAnother=false) => {
                    return e.component.save()
                        .then(result => {
                            if(!result.isValid) return false;
                            self.updateItem(result.data);
                            if (addAnother) {
                                self.$dialog.reloadComponent({ dialogId, props: { item: new StandardLanguagePackageDto({ regionID: self.user.regionID }) } });
                                return false;
                            }
                            return true;
                        });
                };

                let isNew = _.isNil(item);
                let buttons = _.dialogButtons({ showAddAnother: isNew, onOk });
                dialogId = self.$dialog.open({
                    title: `${isNew ? "Add": "Edit"} Clause Package${(isNew ? "" : `: ${item.description}`)}`,
                    width: "90%",
                    height: "90%",
                    scrollable: false,
                    component: ClausePackageForm,
                    props: {
                        item,
                        categories: self.categories,
                        uniqueValidator: (item, field) => !self.isDuplicate(item, field)
                    },
                    buttons
                });
            },

            isDuplicate(item, fieldName){
                const self = this;
                return _.some(self.items, pkg =>
                    _.trimLower(pkg[fieldName]) === _.trimLower(item[fieldName])
                    && _.parseNumber(pkg.regionID, -1) === _.parseNumber(item.regionID, -1)
                    && _.parseNumber(pkg.id, -1) !== _.parseNumber(item.id, -1)
                );
            },

            refresh() {
                this.invokeGridMethod("option", "focusedRowIndex", -1);
                this.invokeGridMethod("clearSelection");
                this.invokeGridMethod("refresh");
            },

            updateDimensions() {
                this.$nextTick(() => {
                    this.invokeGridMethod("updateDimensions");
                    this.invokeGridMethod("repaint");
                });
            }
        }
    }
</script>
