
/**
    Example Usage:
        import { mapState, mapGetters } from "vuex";
        import SystemDefaultsFormMixin from "../shared/SystemDefaultsFormMixin";
        export default {
            name: "SomeSystemDefaultsSubset",
            mixins: [SystemDefaultsFormMixin],
            computed: {
                ...mapGetters([
                    "lookupHelpers",
                    "lookupItems"
                ]),
                someLookupItems(){ return SomeEnum.LookupItems; },
                someOtherLookupItems(){ return SomeOtherEnum.lookupItems; }
            }
        }
**/
import { GlobalEventManager } from "@/app.events";
import { SET_SYSTEM_DEFAULT_DATA } from "@/store/mutations";
import { SystemDefaultDto, CDFSystemDefaultDto }  from "../models";
import { NAV_ACTIONS } from "@/store/actions";

export default {
    data() {
        return {
            originalData: {
                systemDefaults: {},
                cdfSystemDefaults: {},
                settlementStatementDocuments: []
            },
            systemDefaults: {},
            cdfSystemDefaults: {},
            includeCdfDefaults: false,
            includeSettlementStatementDocuments: false
        }
    },
    computed: {
        itemKey() { return _.get(this.$route.meta, "itemKey") || ""; },
        itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
        itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
        itemTypeDescription() { return _.get(this.$route.meta, "itemTypeDescription") || ""; },
        settlementStatementDocuments() {
            if(!this.includeSettlementStatementDocuments) return [];
            let ssDocs = this.systemDefaults.getSsDocData();
            if(this.includeCdfDefaults) ssDocs.push(...this.cdfSystemDefaults.getSsDocData());
            return ssDocs;
        }
    },
    created(){
        const self = this;
        GlobalEventManager.onSave(self, self.onSave);
        self.fetchData();
        self.fetchAdditionalData();
    },
    beforeUnmount() {
        GlobalEventManager.unregister(this);
    },
    methods :{
        onSave: _.debounce(function(e){
            const self = this;
            if (self.hasChanges() || self.hasAdditionalChanges()) {
                return Promise.all([
                        self.save(),
                        self.saveAdditional()
                    ])
                    .then(results => {
                        let overallResult = _.every(results);
                        if(!overallResult) return overallResult;
                        self.$toast.success(`${this.itemTypeNamePlural} were saved.`);
                        GlobalEventManager.saveCompleted({ success: false });
                        return overallResult;
                    })
                    .catch(error => {
                        GlobalEventManager.saveCompleted({ success: false });
                        self.$toast.error(`Error updating ${self.itemTypeNamePlural}.`);
                        console.error(error);
                        return error;
                    });
            } else {
                if (!_.isEmpty(e)) { self.$toast.info("No changes detected"); }
                GlobalEventManager.saveCompleted({ success: true });
                return Promise.resolve(true) ;
            }
        }, 500, { leading: true, trailing: false }),

        onCancel(){
            const self = this;
            if (self.hasChanges() || self.hasAdditionalChanges()) {
                self.$dialog.confirm("Confirm Cancel", "Discard changes and reload data?", function () {
                    self.restoreOriginalData();
                    self.restoreAdditionalData();
                });
            } else {
                self.$toast.info("No changes detected");
            }
        },

        hasChanges() {
            const self = this;
            let originalSd = self.originalData.systemDefaults.toDataObject();
            let currentSd = self.systemDefaults.toDataObject();
            let sdChanges = self.getAuditChanges(originalSd, currentSd);

            if(!this.includeCdfDefaults) return !_.isEmpty(sdChanges);

            let originalCdf = self.originalData.cdfSystemDefaults.toDataObject();
            let currentCdf = self.cdfSystemDefaults.toDataObject();
            let cdfChanges = self.getAuditChanges(originalCdf, currentCdf);

            return !_.isEmpty([...sdChanges, ...cdfChanges]);
        },

        //for use by implementing component
        hasAdditionalChanges() { return false; },

        fetchData () {
            const self = this;
            let apiPromise = self.$api.SystemDefaultApi.getSystemDefaultData();
            return self.$rqBusy.wait(apiPromise)
                .then(result => {
                    let ssDocs = self.includeSettlementStatementDocuments ? result.settlementStatementDocuments : [];
                    self.originalData.systemDefaults = new SystemDefaultDto(result.systemDefault, ssDocs);
                    self.systemDefaults = new SystemDefaultDto(result.systemDefault, ssDocs);
                    if(self.includeCdfDefaults){
                        self.originalData.cdfSystemDefaults = new CDFSystemDefaultDto(result.cdfSystemDefault, ssDocs);
                        self.cdfSystemDefaults = new CDFSystemDefaultDto(result.cdfSystemDefault, ssDocs);
                    }
                    if(self.includeSettlementStatementDocuments) {
                        self.originalData.settlementStatementDocuments = ssDocs;
                    }
                    return result;
                })
                .catch(error => {
                    console.error(error);
                    self.$toast.error(`Error loading ${this.itemTypeNamePlural}.`);
                    return error;
                });
        },

        //for use by implementing component
        fetchAdditionalData() { return Promise.resolve([]); },

        save() {
            const self = this;
            if (_.isFunction(_.get(self, 'v$.$touch'))) {
                self.v$.$touch();
                if(self.v$.$error) return Promise.reject();
            }
            if(!self.hasChanges()) return Promise.resolve(true);
            let requestData = self.getRequestData();
            let apiPromise = self.$api.SystemDefaultApi.updateSystemDefault(requestData);
            return self.$rqBusy.wait(apiPromise)
                .then(result => {
                    self.commitCurrentData();
                    self.$store.commit(SET_SYSTEM_DEFAULT_DATA, result);
                    self.$store.dispatch(NAV_ACTIONS.UPDATE_ORDER_NAV);
                    return true;
                });
        },

        //for use by implementing component
        saveAdditional() { return Promise.resolve(true); },

        getRequestData() {
            const self = this;
            return {
                systemDefault: self.systemDefaults.toDataObject(),
                cdfSystemDefault: self.includeCdfDefaults ? self.cdfSystemDefaults.toDataObject() : null,
                settlementStatementDocuments: self.settlementStatementDocuments
            };
        },

        commitCurrentData() {
            const self = this;
            self.originalData = {
                systemDefaults: new SystemDefaultDto(self.systemDefaults, self.settlementStatementDocuments),
                cdfSystemDefaults: self.includeCdfDefaults
                    ? new CDFSystemDefaultDto(self.cdfSystemDefaults, self.settlementStatementDocuments)
                    : null,
                settlementStatementDocuments: self.settlementStatementDocuments
            };
        },

        restoreOriginalData() {
            const self = this;
            self.systemDefaults = new SystemDefaultDto(self.originalData.systemDefaults, self.originalData.settlementStatementDocuments);
            self.cdfSystemDefaults = new CDFSystemDefaultDto(self.originalData.cdfSystemDefaults, self.originalData.settlementStatementDocuments);
            self.restoreAdditionalData();
        },

        //for use by implementing component
        restoreAdditionalData() { }
    }
}