<template>
    <div class="content-wrapper">
        <rq-page-section :title="itemTypeNamePlural" headerSize="lg" borderless>
            <template #header-actions>
                <ul class="nav config-actions ms-auto">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_cancel"
                            variant="action"
                            v-rq-tooltip.hover.top title="Discard changes and reload data"
                            @click="onCancel"
                            :disabled="readOnly">Cancel
                        </b-btn>
                    </li>
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_save"
                            variant="action"
                            @click="onSave"
                            :disabled="readOnly">Save
                        </b-btn>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
            <div class="row mb-1">
                <div class="col-auto ms-auto">
                    <rq-section-expand-collapse-all />
                </div>
            </div>
            <rq-section-card title="Title Production Options" collapsible>
                <div class="row">
                    <div class="col-md-12 col-lg-6">
                        <label for="txt_interestInLand">Interest In Land</label>
                        <input
                            id="txt_interestInLand"
                            automation_id="txt_interestInLand"
                            type="text"
                            class="form-control"
                            placeholder="Interest in Land"
                            maxlength="150"
                            v-model="systemDefaults.interestInLand"
                            :disabled="readOnly"
                        >
                    </div>
                </div>
            </rq-section-card>
            <rq-section-card title="General Options" collapsible>
                <div class="row px-1">
                    <div class="col-12 checkbox-group label-lg">
                        <b-form-checkbox automation_id="chk_commitmentsInUnderwriterReport" id="chk_commitmentsInUnderwriterReport" v-model="systemDefaults.commitmentsInUnderwriterReport" :disabled="readOnly">Commitments in Underwriter Report</b-form-checkbox>
                        <b-form-checkbox automation_id="chk_logCommitmentPolicyChanges" id="chk_logCommitmentPolicyChanges" v-model="systemDefaults.logCommitmentPolicyChanges" :disabled="readOnly">Log Changes to Text Fields</b-form-checkbox>
                        <b-form-checkbox automation_id="chk_allowPreviewStandardLanguage" id="chk_allowPreviewStandardLanguage" v-model="systemDefaults.allowPreviewStandardLanguage" :disabled="readOnly">Preview Standard Language Clauses w\o Document Creator Code during Selection</b-form-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 col-lg-6">
                        <div class="form-group">
                            <label for="cmb_defaultCMT">Default Title Production / Policy Type</label>
                            <dx-select-box
                                :input-attr="{ automation_id: 'drp_defaultCMT', id: 'drp_defaultCMT' }"
                                :default-item-enabled="false"
                                :items="policyTypes"
                                value-expr="id"
                                display-expr="name"
                                v-model="defaultPolicyType"
                                :search-enabled="true"
                                :disabled="readOnly"
                            />
                        </div>
                        <div class="form-group">
                            <label for="cmb_appliesToType">Manually Added Exceptions by Default Apply to</label>
                            <dx-select-box
                                :input-attr="{ automation_id: 'drp_appliesToType', id: 'drp_appliesToType' }"
                                :default-item-enabled="false"
                                :items="appliesToType"
                                value-expr="id"
                                display-expr="name"
                                v-model="defaultAppliesToType"
                                :search-enabled="true"
                                :disabled="readOnly"
                            />
                        </div>
                        <div class="form-group">
                            <label for="dtp_defaultEffectiveTime">Default Effective Time</label>
                            <rqdx-date-box
                                id="dtp_defaultEffectiveTime"
                                v-model="systemDefaults.defaultEffectiveTime"
                                type="time"
                                :disabled="readOnly"
                            />
                        </div>
                    </div>
                </div>
            </rq-section-card>
         </rq-page-section>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import SystemDefaultsFormMixin from "../../shared/SystemDefaultsFormMixin";
    import { CommitmentPolicyTypes } from "../../enums";
    import { AppliesToType } from "@/modules/file/title-policy/enums";

    export default {
        name: "TitleProdPolicySettingsForm",
        mixins: [SystemDefaultsFormMixin],
        computed: {
            ...mapState({
                readOnly: state => state.isPageReadOnly,
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            appliesToType(){ return AppliesToType.lookupItems; },
			policyTypes(){ return CommitmentPolicyTypes.lookupItems;},
			defaultPolicyType: {
				get() { return this.systemDefaults.defaultCMT ? CommitmentPolicyTypes.ALTA : CommitmentPolicyTypes.Texas; },
				set(val) { this.systemDefaults.defaultCMT = val === CommitmentPolicyTypes.ALTA; }
            },
            defaultAppliesToType: {
                get() { return _.parseNumber(this.systemDefaults.defaultAppliesToType, 0); },
                set(val) { this.systemDefaults.defaultAppliesToType = val; }
            }
        }
    }
</script>
