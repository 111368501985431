
export const Actions = {
    SAVE: {
        automation_id: 'btn_save',
        key: "save",
        text: "Save",
        tooltip: "Save [entity]",
        disableWhenReadOnly: true
    },
    CANCEL: {
        automation_id: 'btn_cancel',
        key: "cancel",
        text: "Cave",
        tooltip: "Cancel",
        disableWhenReadOnly: true
    },
};

export const ConfigurationActions = {
    MAIN: [        
        Actions.CANCEL,        
        Actions.SAVE,    
    ],
  
};

