<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            v-model:validation-errors="validationErrors"
            :strikethrough-if-true="['inactiveYN']"
            target-inactive-column="inactiveYN"
            @delete="onDeleteItem"
            @activate="onActivateItem"
            integrated-search
            hide-show-column-chooser
            :rq-editable="!readOnly"
            show-include-inactive
            rq-filters
        />
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { DeliveryAction } from '../../enums';
    import { WorkflowServiceRequestDeliveryMethodDto }  from "../models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name:"DeliveryMethodList",
        data () {
            return {
                items: [],
                selectedItem: {},
                validationErrors: [],
                addEventName: ""
            };
        },
        created(){
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.initListeners();
            this.fetchData();
        },
        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },
        computed: {
            ...mapState({
                readOnly: state => state.isPageReadOnly,
            }),
            gridInstance() {
                return _.get(this, "$refs.dataGrid.gridInstance", null) || {};
            },
            deliveryActions() {
                return  DeliveryAction.lookupItems;
            },
        },
        watch: {
            validationErrors: function () {
                const self = this;
                self.$events.emit("update-config-error", { message: "Please correct the highlighted errors on screen to continue.", hasError: self.validationErrors.length > 0 });
            }
        },
        methods: {
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },
            initNonReactiveVariables() {
                this.itemTypeName = _.get(this.$route.meta, "itemTypeName");
                this.itemTypeNamePlural = _.get(this.$route.meta, "label");
                this.itemKey = _.get(this.$route.meta, "itemKey");
                this.selectionActions = [
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Delete ${this.itemTypeName}`,
                        disabled: function(e) {
                            return this.readOnly;
                        }
                    },
                    {
                        name: "activate",
                        text: "Activate",
                        eventName: "activate",
                        requireSelection: true,
                        tooltip: `Activate ${this.itemTypeName}`,
                        allowMultiSelection: true,
                        disabled: function(e) {
                            return !_.every(e.data, ['inactiveYN', true]) || this.readOnly;
                        }
                    },
                    {
                        name: "inactivate",
                        text: "Inactivate",
                        eventName: "activate",
                        requireSelection: true,
                        tooltip: `Inactivate ${this.itemTypeName}`,
                        allowMultiSelection: true,
                        disabled: function(e) {
                            return !_.every(e.data, ['inactiveYN', false])  || this.readOnly;
                        }
                    }
                ];
            },
            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "description",
                            dataType: "string",
                            caption: "Service Delivery Description",
                            sortIndex: 0,
                            sortOrder: "asc",
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "custom",
                                    validationCallback: self.isNotDuplicateDescription,
                                    message: "Description already exists"
                                }
                            ],
                            editorOptions: {
                                maxLength: 50
                            },
                        },
                        {
                            dataField: "deliveryAction",
                            dataType: "string",
                            caption: "Delivery Action",
                            lookup: {
                                dataSource: self.deliveryActions,
                                displayExpr: "name",
                                valueExpr: "id"
                            }, validationRules: [{ type: "required" }]
                        },
                        {
                            caption: "Inactive",
                            dataField: "inactiveYN",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate
                        }
                    ],
                    onRowUpdating(e) {
                        e.cancel = new Promise((resolve, reject) => {
                            self.$dialog.confirm(
                                "Confirm Edit",
                                `Updating ${self.itemTypeNamePlural} will change existing files. Do you want to continue?`,
                                () => resolve(false), //continue with edit
                                () => resolve(true), //cancel edit
                                { cancelTitle: 'No', okTitle: 'Yes'});
                        });
                    }
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },
            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },
            onAddItem() {
                if(!this.gridInstance) return;
                this.gridInstance.addRow()
            },
            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let apiPromises = [];
                    _.forEach(items, (item) => {
                        let key = _.get(item, self.itemKey);
                        apiPromises.push(self.$api.DeliveryMethodsApi.deleteDeliveryMethod(key));
                    });
                    return self.$rqBusy.wait(Promise.all(apiPromises))
                        .then(key => {
                            self.deleteItem([...key]);
                            let message = key.length > 1 ? `${key.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success({ message: message });
                            return true;}
                        )
                        .catch(error => {
                          if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                 self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            }
                            return error;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }

                 self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            activate(keys, verb) {
                const self = this;
                let apiPromise = self.$api.DeliveryMethodsApi.toggleActivate(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.fetchData();
                        let message = keys.length > 1
                            ? `${keys.length} ${self.itemTypeNamePlural} were ${verb}d.`
                            : `${self.itemTypeName} was ${verb}d.`
                        self.$toast.success(message);
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error(`Error trying to ${verb} ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },
            onActivateItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 1
                    ? self.itemTypeNamePlural
                    : self.itemTypeName;
                let verb = _.every(items, ['inactiveYN', true]) ? "Activate" : "Inactivate";

                let okHandler = function (args) {
                    let keys = _.map(items, self.itemKey);
                    self.activate(keys, verb);
                    return true;
                }

                self.$dialog.confirm(
                    `Confirm ${verb}`,
                    `Are you sure you want to ${verb} the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            fetchData() {
                const self = this;
                let apiPromise = self.$api.DeliveryMethodsApi.getDeliveryMethods();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new WorkflowServiceRequestDeliveryMethodDto(i));
                        self.refresh();
                    })
                    .catch(error => {
                        self.items = [];
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },
            save(item, changes){
                const self = this;

                if(changes.length === 0) {
                    return Promise.resolve(item);
                }

                let apiPromise = self.$api.DeliveryMethodsApi.saveDeliveryMethod(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise)
                        .then(result => {
                            self.$toast.success({ message: `${self.itemTypeName} ${item.description} was saved.` });
                            return result;
                        }).catch(error => {
                            self.$toast.error(`Error saving ${self.itemTypeName}.`);
                            return error;
                        })
                        .finally(() => {
                            self.refresh();
                        });
            },
            refresh() {
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },
            deleteItem(keys) {
                _.each(keys, k => {
                    _.remove(this.items, (i) => {return _.parseNumber(_.get(i, this.itemKey, -1), -1) == k;});
                });
            },
            isNotDuplicateDescription(item) {
                const self = this;
                let dup = {};
                dup = _.find(self.items, (i) => {
                    return _.toLower(_.trim(i.description)) === _.toLower(_.trim(item.data.description))
                            && _.parseNumber(_.get(i, self.itemKey, -1), -1) != _.parseNumber(_.get(item.data, self.itemKey, -1), -1);
                });

                return dup ? false : true;
            },
            onGridInsert(values) {
                const self = this;
                let newItem = new WorkflowServiceRequestDeliveryMethodDto(values);
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                return self.save(newItem, changes)
                    .then(result => {
                        self.items.push(new WorkflowServiceRequestDeliveryMethodDto(result));
                    });
            },
            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item.workflowServiceRequestDeliveryMethodID === key);
                if(itemIndex < 0) return self.onGridInsert(values);

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new WorkflowServiceRequestDeliveryMethodDto(_.assign({}, self.items[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                return self.save(updatedItem, changes)
                    .then(result => {
                        self.items[itemIndex] = updatedItem;
                    });
            }
        }
    }
</script>
