<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            v-model:validation-errors="validationErrors"
            :strikethrough-if-true="['inactive']"
            focus-after-insert="none"
            @activate="onActivateItem"
            @delete="onDeleteItem"
            integrated-search
            hide-show-column-chooser
            rq-filters
            show-include-inactive
            target-inactive-column="inactive"
            :rq-editable="!readOnly"
        />
    </div>
</template>
<script>
import { CompanyRole }  from "../models";
import DxGridUtils from "@/shared/utilities/DxGridUtils";
import { mapState } from "vuex";

export default {
    data () {
        return {
            items: [],
            selectedItem: {},
            validationErrors: [],
            addEventName: ""
        };
    },
    computed: {
        ...mapState({
                readOnly: state => state.isPageReadOnly,
            }),
        gridInstance() {
            return _.get(this, "$refs.dataGrid.gridInstance", null) || {};
        }
    },
    watch: {
        validationErrors(newValue, oldValue) {
            if(newValue === oldValue) return;
            this.$events.emit("update-config-error", { message: _.isEmpty(newValue) ? "" : "Please correct the highlighted errors on screen to continue.", hasError: !_.isEmpty(newValue) });
        }
    },
    created(){
        this.initNonReactiveVariables();
        this.initGridConfig();
        this.initListeners();
        this.fetchData();
    },
    beforeUnmount () {
        this.$events.off(this.addEventName, this.onAddItem);
    },
    methods: {
        elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },
        initNonReactiveVariables() {
            this.itemTypeName = _.get(this.$route.meta, "itemTypeName");
            this.itemTypeNamePlural = _.get(this.$route.meta, "label");
            this.itemKey = _.get(this.$route.meta, "itemKey");
            this.selectionActions = [
                {
                    name: "delete",
                    text: "Delete",
                    eventName: "delete",
                    requireSelection: true,
                    allowMultiSelection: true,
                    tooltip: `Delete ${this.itemTypeName}`,
                    disabled: function(e) { return _.some(e.data, ['isSystem', true]) || _.some(e.data, ['isInUse', true]) || this.readOnly;}
                },
                {
                    name: "activate",
                    text: "Activate",
                    eventName: "activate",
                    requireSelection: true,
                    tooltip: `Activate ${this.itemTypeName}`,
                    allowMultiSelection: true,
                    disabled: function(e) { return _.some(e.data, ['isSystem', true]) || !_.every(e.data, ['inactive', true]) || this.readOnly; }
                },
                {
                    name: "inactivate",
                    text: "Inactivate",
                    eventName: "activate",
                    requireSelection: true,
                    tooltip: `Inactivate ${this.itemTypeName}`,
                    allowMultiSelection: true,
                    disabled: function(e) { return _.some(e.data, ['isSystem', true]) || !_.every(e.data, ['inactive', false])  || this.readOnly; }
                }
            ];
        },
        initGridConfig(){
            const self = this;
            self.gridConfig = {
                columns: [
                    {
                        dataField: "name",
                        dataType: "string",
                        caption: "Role Name",
                        sortIndex: 0,
                        sortOrder: "asc",
                        validationRules: [
                            { type: "required" },
                            {
                                type: "custom",
                                validationCallback: self.isNotDuplicateName,
                                message: "Name already exists"
                            }
                        ],
                        editorOptions: {
                            maxLength: 50
                        },
                    },
                    {
                        caption: "Is System",
                        dataField: "isSystem",
                        dataType: "boolean",
                        cellTemplate: DxGridUtils.boolCellTemplate
                    },
                    {
                        caption: "Inactive",
                        dataField: "inactive",
                        dataType: "boolean",
                        cellTemplate: DxGridUtils.boolCellTemplate
                    }
                ],
                onInitNewRow: e => { e.data.inactive = false;e.data.isSystem = false; },
                onRowUpdating(e) {
                    e.cancel = new Promise((resolve, reject) => {
                        self.$dialog.confirm(
                            "Confirm Edit",
                            `Updating ${self.itemTypeNamePlural} will change existing files. Do you want to continue?`,
                            () => resolve(false), //continue with edit
                            () => resolve(true), //cancel edit
                            { cancelTitle: 'No', okTitle: 'Yes'});
                    });
                },
                onEditorPreparing: e => {
                        if (e.parentType !== "dataRow") return;
                        if (e.dataField === "name") e.editorOptions.disabled = e.row.data.isSystem;
                        if (e.dataField === "isSystem") e.editorOptions.disabled = true;
                    }
            };

            self.gridDataSource = {
                key: self.itemKey,

                load (loadOptions) {
                    return Promise.resolve(self.items);
                },
                insert: self.onGridInsert,
                update: self.onGridUpdate
            };
        },
        initListeners(){
            this.addEventName = `add:${this.elementName()}`;
            this.$events.on(this.addEventName, this.onAddItem);
        },
        activate(keys, verb) {
            const self = this;
            let apiPromise = self.$api.CompanyRoleApi.toggleActivate(keys);
            return self.$rqBusy.wait(apiPromise)
                .then(() => {
                    self.fetchData();
                    let message = keys.length > 1
                        ? `${keys.length} ${self.itemTypeNamePlural} were ${verb}d.`
                        : `${self.itemTypeName} was ${verb}d.`
                    self.$toast.success(message);
                    return true;
                })
                .catch(error => {
                    self.$toast.error(`Error trying to ${verb} ${self.itemTypeName}.`);
                    console.error(error);
                    return error;
                });
        },
        onActivateItem(e) {
            if(!e || !e.data) return;
            const self = this;
            let items = e.data;
            let itemLabel = items.length > 1
                ? self.itemTypeNamePlural
                : self.itemTypeName;
            let verb = _.every(items, ['inactive', true]) ? "Activate" : "Inactivate";

            let okHandler = function (args) {
                let keys = _.map(items, self.itemKey);
                self.activate(keys, verb);
                return true;
            }

            self.$dialog.confirm(
                `Confirm ${verb}`,
                `Are you sure you want to ${verb} the selected ${itemLabel}?`,
                okHandler,
                null, { cancelTitle: 'No', okTitle: 'Yes'});
        },
        onAddItem() {
            if(!this.gridInstance) return;
            this.gridInstance.addRow()
        },
        onDeleteItem(e) {
            if(!e || !e.data) return;
            const self = this;
            let items = e.data;
            let ok = function (args) {
                let apiPromises = [];
                _.forEach(items, (item) => {
                    let key = _.get(item, self.itemKey);
                    apiPromises.push(self.$api.CompanyRoleApi.deleteCompanyRoles(key));
                });
                return self.$rqBusy.wait(Promise.all(apiPromises))
                    .then(key => {
                        self.deleteItem([...key]);
                        let message = key.length > 1 ? `${key.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                        self.$toast.success({ message: message });
                        return true;}
                    )
                    .catch(error => {
                        if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                        } else {
                            self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                        }
                        return error;
                    })
                    .finally(() => {
                        self.refresh();
                    });
            }
            self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
        },
        onGridInsert(values) {
            const self = this;
            let newItem = new CompanyRole(values);
            let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
            return self.save(newItem, changes)
                .then(result => {
                    self.items.push(new CompanyRole(result));
                });
        },
        onGridUpdate(key, values) {
            const self = this;
            let itemIndex = _.findIndex(self.items, item => item.roleTypeID === key);
            if(itemIndex < 0) return self.onGridInsert(values);

            let originalItem = _.cloneDeep(self.items[itemIndex]);
            let updatedItem = new CompanyRole(_.assign({}, self.items[itemIndex], values));
            let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

            return self.save(updatedItem, changes)
                .then(result => {
                    self.items[itemIndex] = updatedItem;
                });
        },
        fetchData() {
            const self = this;
            let apiPromise = self.$api.CompanyRoleApi.getCompanyRoles();
            return self.$rqBusy.wait(apiPromise)
                .then(result => {
                    self.items = _.map(result, i => new CompanyRole(i));
                    self.refresh();
                })
                .catch(error => {
                    self.items = [];
                    self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                    return error;
                });
        },
        save(item, changes){
            const self = this;

            if(changes.length === 0) {
                return Promise.resolve(item);
            }

            let apiPromise = self.$api.CompanyRoleApi.saveCompanyRoles(item.toDataObject(), changes);
            return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$toast.success({ message: `${self.itemTypeName} ${item.name} was saved.` });
                        return result;
                    }).catch(error => {
                        self.$toast.error(`Error saving ${self.itemTypeName}.`);
                        return error;
                    })
                    .finally(() => {
                        self.refresh();
                    });
        },
        refresh() {
            this.gridInstance.clearSelection();
            this.gridInstance.refresh();
        },
        deleteItem(keys) {
            _.each(keys, k => {
                _.remove(this.items, (i) => {return _.parseNumber(_.get(i, this.itemKey, -1), -1) == k;});
            });
        },
        isNotDuplicateName(item) {
            const self = this;
            let dup = {};
            dup = _.find(self.items, (i) => {
                return _.toLower(_.trim(i.name)) === _.toLower(_.trim(item.data.name))
                        && _.parseNumber(_.get(i, self.itemKey, -1), -1) != _.parseNumber(_.get(item.data, self.itemKey, -1), -1);
            });

            return dup ? false : true;
        }
    }
}
</script>
