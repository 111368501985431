import EnterpriseMain from "./EnterpriseMain.vue";
import ConfigNav from "../shared/ConfigNav.vue";
import Routes from "./routes";
import { Categories } from "./categories";

export default {
    path: "/configuration/main",
    component: EnterpriseMain,
    meta: {
        label: "Main",
        include: true,
        skipSaveOnLeave: true
    },
    children: [{
            name: "cfg:main:enterprise",
            path: "enterprise",
            component: ConfigNav,
            props: {routes: Routes, category : Categories.Enterprise},
            meta: {
                label: Categories.Enterprise,
                skipSaveOnLeave: true
            }
        },{
            name: "cfg:main:files",
            path: "files",
            component: ConfigNav,
            props: {routes: Routes, category : Categories.Files},
            meta: {
                label: Categories.Files,
                skipSaveOnLeave: true
            }
        },{
            name: "cfg:main:companies",
            path: "companies",
            component: ConfigNav,
            props: {routes: Routes, category : Categories.Companies},
            meta: {
                label: Categories.Companies,
                skipSaveOnLeave: true
            }
        },{
            name: "cfg:main:communication",
            path: "communication",
            component: ConfigNav,
            props: {routes: Routes, category : Categories.Communication},
            meta: {
                label: Categories.Communication,
                skipSaveOnLeave: true
            }
        },
        ...Routes,
    ]
};