<template>
    <div class="content-wrapper">
        <rq-page-section :title="itemTypeNamePlural" headerSize="lg" header-only borderless>
             <template #header-actions>
                <ul class="nav">
                    <li class="nav-item" v-rq-tooltip.hover :title="!canDoAnything ? '(Access Restricted)' : ''">
                        <b-button
                            :automation_id="elementName('btn_add', this.itemTypeName)"
                            :ref="elementName('btn_add', this.itemTypeName)"
                            variant="theme"
                            @click="onAddItem"
                            :disabled=!canDoAnything
                            >
                            Add
                        </b-button>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            target-inactive-column="inactive"
            @contentReady="onGridContentReady"
            @activate="onActivateItem($event, 'Activate')"
            @inactivate="onActivateItem($event, 'Inactivate')"
            @edit="onEditItem"
            @delete="onDeleteItem"
            @copy="onCopyItem"
            @rowDoubleClick="onEditItem"
            :strikethrough-if-true="['inactive']"
            v-model:search-value="searchText"
            integrated-search
            show-include-inactive
            rq-filters
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { RecordingFeeConfigurationDto }  from "../models";
    import RecordingFeeConfigurationForm from "./RecordingFeeConfigurationForm.vue";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { RecordingFeeEffectiveDateBasedOn } from '../../enums';

    export default {
        name:"RecordingFeeConfigurationList",
        data () {
            return {
                items: [],
                selectedItem: {},
                validationErrors: [],
                verifiedItem: {},
                addEventName: "",
                gridReady: false,
                searchText:""
            };
        },
        computed: {
            ...mapState({
                user: state => state.authentication.session.user
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "regionsLookup"
            ]),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            itemKey() { return "recordingFeeConfigurationID";},
            itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            itemTypeDescription() { return _.get(this.$route.meta, "itemTypeDescription") || ""; },
            selectionActions() {
                const self = this;
                return [{ name: "edit", text: (!self.canDoAnything) ? "View":"Edit", eventName: "edit", requireSelection: true, tooltip: (!self.canDoAnything) ? `View ${this.itemTypeName}`: `Edit ${this.itemTypeName}`},
                        { name: "delete", text: `Delete`, eventName: "delete", requireSelection: true, allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}` , disabled:!self.canDoAnything  },
                        { name: "copy", text: `Copy`, eventName: "copy", requireSelection: true, allowMultiSelection: false, tooltip: `Copy ${this.itemTypeName}` , disabled:!self.canDoAnything  },
                        { name: "activate", text: "Activate", eventName: "activate", requireSelection: true, tooltip: `Activate ${this.itemTypeName}`, allowMultiSelection: true,
                            disabled: function(e) {
                                   return (!self.canDoAnything) ? true : !_.every(e.data, ['inactive', true]); }
                            },
                        { name: "inactivate", text: "Inactivate", eventName: "inactivate", requireSelection: true, tooltip: `Inactivate ${this.itemTypeName}`, allowMultiSelection: true,
                            disabled: function(e) {
                                   return (!self.canDoAnything) ? true : !_.every(e.data, ['inactive', false]); }
                            }
                        ];
            },
            localSecurity()
            {
                 return this.securitySettings.findValues(["AllowAddEditRecordingFees"]);
            },
            canDoAnything(){
                return this.localSecurity.AllowAddEditRecordingFees;
            },
        },
        created(){
            this.initGridConfig();
            this.initListeners();
        },
        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },
        methods: {
            activate(keys, verb) {
                const self = this;
                let apiPromise = self.$api.RecordingFeeConfigurationApi.activate(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.refresh();
                        let message = keys.length > 1
                            ? `${keys.length} ${self.itemTypeNamePlural} were ${verb}d.`
                            : `${self.itemTypeName} was ${verb}d.`
                        self.$toast.success(message);
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error(`Error trying to ${verb} ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            onGridContentReady (e) {
                const self = this;
                self.gridReady = true;
            },

            onActivateItem(e, verb) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 1
                    ? self.itemTypeNamePlural
                    : self.itemTypeName;
                    
                let okHandler = function (args) {
                    let keys = _.map(items, self.itemKey);
                    self.activate(keys, verb);
                    return true;
                }

                self.$dialog.confirm(
                    `Confirm ${verb}`,
                    `Are you sure you want to ${verb} the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onAddItem() {
                const self = this;

                let dialogId = null;
                let props = { isModal: true };
                let refreshOnCancel = false;
                let onOk = (e, addAnother=false) => e.component.save()
                    .then(result => {
                        if(!result.success) return false;
                        if (addAnother) {
                            self.$dialog.reloadComponent({ dialogId, props });
                            refreshOnCancel = true;
                            return false;
                        }
                        self.refresh();
                        return result.success;
                    });
                let onCancel = () => {
                    if(!refreshOnCancel) return true;
                    self.refresh();
                    return true;
                };

                dialogId = self.$dialog.open({
                    title: "Add Recording Fee",
                    height: 500,
                    width: 1200,
                    component: RecordingFeeConfigurationForm,
                    props,
                    buttons: [
                        { title: "Cancel", automationId: "btn_dm_modal_cancel", cssClass: "btn btn-secondary", closeOnComplete: true, onClick: onCancel },
                        { title: "Save & Add Another", automationId: "btn_dm_modal_save_and_another", cssClass: "btn btn-primary", closeOnComplete: false, onClick: (e) => { return onOk(e, true); } },
                        { title: "Save", automationId: "btn_dm_modal_save", cssClass: "btn btn-primary", closeOnComplete: true, onClick:  (e) => { return onOk(e, false); } }
                    ]
                });
            },

            onEditItem(e) {
                const self = this;
                var message = "";
                if(!self.localSecurity) return ;
                this.$router.push({ name: 'cfg:sscalc:recordingfee', params: { recordingFeeConfigurationID: e.data.recordingFeeConfigurationID, recordingFeeName: e.data.description }});
            },

            onCopyItem(e){
                if(!e || !e.data) return;
                const self = this;
                let item = e.data;
                self.selectedItem = new RecordingFeeConfigurationDto(item);
                let onOk = (e, edit=false) => {
                    let form = e.component;
                    item.description = form.newDescription;
                    self.validationErrors = [];
                    self.verifiedItem = {};
                    if (!item.isValid ) {
                        form.validationErrors = _.concat(item.validationErrors, self.validationErrors);
                        form.verifiedItem = _.merge(item.verifiedItem, self.verifiedItem);
                        return false;
                    }
                    self.copyItem(item, edit);
                };
                let onCancel = e => {
                    self.refresh();
                };

                self.$dialog.open({
                    title: `Copy ${self.itemTypeName}: ${item.description}`,
                    height: "auto",
                    width: "600",
                    adaptive: true,
                    component: {
                        template: `
                            <div class="content-wrapper">
                            <rqValidationSummary :dataSource="validationErrors" />
                                <div class="row ps-3 pe-3">
                                    <div class="col col-12 form-group form-required">
                                        <label for="txt_description">New Description</label>
                                        <input automation_id="txt_description" v-model="newDescription" type="text" class="form-control" placeholder="Description">
                                    </div>
                                </div>
                            </div>`,
                        data () {
                            return {
                                newDescription: item.description,
                                validationErrors: []
                            };
                        },
                    },
                    closeOnEsc: true,
                    buttons: [
                        { title: "Cancel", automationId: "btn_dm_modal_cancel", cssClass: "btn btn-secondary", closeOnComplete: true, onClick: onCancel },
                        { title: "Save & Edit", automationId: "btn_dm_modal_save_and_edit", cssClass: "btn btn-primary", closeOnComplete: true, onClick: (e) => { onOk(e, true); } },
                        { title: "Save", automationId: "btn_dm_modal_save", cssClass: "btn btn-primary", closeOnComplete: true, onClick:  (e) => { onOk(e, false); } }
                    ]

                });
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let keys = _.map(items, self.itemKey);
                    let apiPromise = self.$api.RecordingFeeConfigurationApi.delete(keys);
                    return self.$rqBusy.wait(apiPromise)
                        .then(key => {
                            let message = items.length > 1 ? `${items.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success({ message: message });
                            return true;
                        })
                        .catch(error => {
                            if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                    self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            }
                            return error;
                        })
                        .finally(() => {
                            self.refresh();
                        });

                }

                 self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "regionID",
                            dataType: "number",
                            caption: "Region",
                            calculateSortValue: "regionDisplay",
                            lookup: {
                                dataSource: self.regionsLookup,
                                displayExpr: "displayName",
                                valueExpr: "regionID",
                            },
                        },
                        { dataField: "description", dataType: "string"},
                        { dataField: "countyList", dataType: "string", caption: "County", cellTemplate: DxGridUtils.truncateCellTemplate, width: 150, },
                        { dataField: "stateAssociated", dataType: "string", caption: "State" },
                        { dataField: "cityAssociated", dataType: "string", caption: "City" },
                        { dataField: "effectiveBasedOn", dataType: "number", caption: "Effective Based On",
                            lookup: {
                                dataSource: RecordingFeeEffectiveDateBasedOn.lookupItems,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                        },
                        {
                            dataField: 'effectiveDate',
                            dataType: 'date'
                        },
                        { dataField: "recordingTypeDescription", dataType: "string", caption: "Recording Type" },
                        { dataField: "calculationBasisDescription", dataType: "string", caption: "Calculation Basis" },
                        { dataField: "inactive", dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate }
                    ]
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load: self.fetchData,
                };
            },

            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },

            fetchData() {
                const self = this;
                let apiPromise = self.$api.RecordingFeeConfigurationApi.getList();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        let items = _.map(result, i => new RecordingFeeConfigurationDto(i));
                        self.originalItems = items;
                        return { data: items, totalCount: items.length };
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },

            copyItem(item, edit) {
                const self = this;
                let apiPromise = self.$api.RecordingFeeConfigurationApi.copy(item);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items.push(new RecordingFeeConfigurationDto(result));
                        self.$toast.success({ message: `${self.itemTypeName} was copied.` });
                        if (edit) self.onEditItem({data: result});
                    })
                    .catch(error => {
                        self.$toast.error({ message: `Error copying ${self.itemTypeName}.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });

            },

            refresh() {
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            }
        }
    }
</script>
