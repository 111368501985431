<template>
    <div class="content-wrapper">
        <rq-page-section :title="itemTypeNamePlural" headerSize="lg" borderless>
             <template #header-actions>
                <ul class="nav config-actions ms-auto">
                    <li class="nav-item">
                        <b-button
                            automation_id="btn_cancel"
                            variant="action"
                            @click="onCancel">Cancel
                        </b-button>
                    </li>
                    <li class="nav-item">
                        <b-button
                            automation_id="btn_save"
                            variant="action"
                            @click="onSave({ userInitiated: true })">Save
                        </b-button>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
            <rqValidationSummary :dataSource="validationErrors" />
            <div class="row mb-1">
            </div>
            <div class="row">
                <div class="col col-4 form-group">
                    <label for="drp_region">Filter on Region</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_region', id: 'drp_region' }"
                        :items="regions"
                        :default-item-enabled="false"
                        @valueChanged="onRegionChange"
                        v-model="regionID"
                        value-expr="id"
                        display-expr="name"
                        :search-enabled="true"
                    />
                </div>
                <div class="col-auto ms-auto">
                    <rq-section-expand-collapse-all section-group="prorate-defaults" />
                </div>
            </div>
            <rq-page-section ref="regionDefaults" title="Region Defaults" class="mt-n1" section-group="prorate-defaults" collapsible hide-collapse-tooltip borderless >
                <div class="row">
                    <div class="col col-3 col-sm-6 col-md-3 col-lg-3 form-group">
                        <label for="drp_proration_method">Proration Method</label>
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_proration_method', id: 'drp_proration_method' }"
                            :items="prorationMethods"
                            :default-item-enabled="false"
                            v-model="regionDefault.prorationMethod"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                        />
                    </div>
                    <div class="col col-3 col-sm-6 col-md-3 col-lg-3 form-group">
                        <label for="drp_periods_per_year">Billing Periods per Year</label>
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_periods_per_year', id: 'drp_periods_per_year' }"
                            :items="billingPeriods"
                            :default-item-enabled="false"
                            v-model="regionDefault.periodsPerYear"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                            @value-changed="periodsPerYearChanged"
                        />
                    </div>
                    <div class="col col-3 col-sm-6 col-md-3 col-lg-3 form-group">
                        <label for="drp_payment_due">Payment Due</label>
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_payment_due', id: 'drp_payment_due' }"
                            :items="paymentDueTypes"
                            :default-item-enabled="false"
                            v-model="regionDefault.paymentDue"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                        />
                    </div>
                    <div class="col col-3 col-sm-6 col-md-3 col-lg-3 form-group">
                        <label for="drp_proration_type">Proration Type</label>
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_proration_type', id: 'drp_proration_type' }"
                            :items="prorationTypes"
                            :default-item-enabled="false"
                            v-model="regionDefault.daysInYear"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                            :disabled="billingDaily"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col col-6 col-sm-6 col-md-6 col-lg-6 form-group">
                        <b-form-checkbox
                            automation_id="chk_applyTaxDescriptionToSection1000"
                            id="chk_applyTaxDescriptionToSection1000"
                            v-model="regionDefault.applyTaxDescriptionToSection1000"
                            >Apply Tax Descriptions to Settlement Statements
                        </b-form-checkbox>
                    </div>
                </div>
            </rq-page-section>
            <rq-page-section ref="defaultProrations" title="Default Prorations" class="mt-n1" section-group="prorate-defaults" collapsible hide-collapse-tooltip borderless >
                <rqdx-action-data-grid
                    ref="dataGrid"
                    :automation_id="elementName('tbl')"
                    :config="gridConfig"
                    :data-source="gridDataSource"
                    :export-file-name="elementName('', 'data')"
                    v-model:validation-errors="validationErrors"
                    hide-search
                    rq-editable
                    hide-settings
                />
            </rq-page-section>
        </rq-page-section>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import { GlobalEventManager } from "@/app.events";
    import { ProrateRegionDefaultDto, ProrateDefaultDto }  from "../models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name: "ProrateDefaultsForm",
        data () {
            return {
                regionID: 0,
                regions: [],
                regionDefault: new ProrateRegionDefaultDto(),
                originalRegionDefault: new ProrateRegionDefaultDto(),
                items: [],
                validationErrors: [],
                expandAllSections: true,
                rqSections: [],
                regionDefaultsCollapsed: false,
                defaultProrationsCollapsed: false,
            };
        },
        computed: {
            ...mapState({
                globalRegionId: state => state.system.globalRegionId
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance() {
                return _.get(this, "$refs.dataGrid.gridInstance", null) || {};
            },
            billingDaily() {
                return this.periodsPerYear == 0;
            },
            periodsPerYear() {
                return _.parseNumber(_.get(this.regionDefault, "periodsPerYear"), 0);
            },

        },
        created() {
            GlobalEventManager.onSave(this, this.onSave);
            GlobalEventManager.onCancel(this, this.onCancel);
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.fetchData();
        },
        beforeUnmount() {
            GlobalEventManager.unregister(this);
        },
        methods: {
            onCancel(){
                const self = this;
                let original = new ProrateRegionDefaultDto(self.originalRegionDefault);
                let newValues = new ProrateRegionDefaultDto(self.regionDefault);
                let changes = self.getAuditChanges(original, newValues);
                let ok = function (args) {
                    self.cancelChildComponentChanges();
                    self.fetchData();
                };
                if (changes.length === 0 && !self.childComponentsHaveChanges()) {
                    self.$toast.info("No changes detected");
                } else {
                    self.$dialog.confirm("Confirm Cancel", "Discard changes and reload data?", ok);
                }
            },
            cancelChildComponentChanges(){
                this.gridInstance.cancelEditData();
            },
            childComponentsHaveChanges(){
                return this.gridInstance.hasEditData();
            },
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },
            initNonReactiveVariables() {
                this.itemTypeName = _.get(this.$route.meta, "itemTypeName");
                this.itemTypeDescription = _.get(this.$route.meta, "itemTypeDescription");
                this.itemTypeNamePlural = _.get(this.$route.meta, "label");
                this.itemKey = "prorateDefaultID";
                this.regions = this.lookupHelpers.getLookupItems(this.lookupItems.REGIONS, this.globalRegionId);
                this.regionID = this.regions.length > 0 ? this.regions[0].id : 0;
                this.selectionActions = [];
                this.prorationTypes = this.lookupHelpers.getLookupItems(this.lookupItems.PRORATION_TYPES);
                this.prorationMethods = this.lookupHelpers.getLookupItems(this.lookupItems.PRORATION_METHODS);
                this.billingPeriods = this.lookupHelpers.getLookupItems(this.lookupItems.PRORATION_BILLING_PERIODS);
                this.paymentDueTypes = this.lookupHelpers.getLookupItems(this.lookupItems.PRORATION_PAYMENT_DUE_TYPES);
            },
            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    selection: { mode: "none" },
                    columns: [
                        {
                            dataField: self.itemKey,
                            visible: false,
                            allowEditing: false
                        },
                        {
                            dataField: "taxCategoryDisplay",
                            caption: "Tax Cat",
                            dataType: "string",
                            sortIndex: 0,
                            sortOrder: "asc",
                            allowEditing: false
                        },
                        {
                            dataField: "overrideDescription",
                            caption: "OR",
                            dataType: "boolean",
                            setCellValue: function(newData, value, currentRowData) {
                                newData.description = value ? null : currentRowData.defaultDescription;
                                newData.overrideDescription = currentRowData.overrideDescription = value;
                            },
                            cellTemplate: DxGridUtils.boolCellTemplate
                        },
                        {
                            dataField: "description",
                            caption: "Description",
                            dataType: "string",
                            editorOptions: { maxLength: 40 },
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "custom",
                                    validationCallback: self.isNotDuplicateDescription,
                                    message: "Description already exists"
                                }
                            ]
                        },
                        {
                            dataField: "daysInYear",
                            dataType: "string",
                            caption: "Days In Year",
                            lookup: {
                                dataSource: self.prorationTypes,
                                displayExpr: "name",
                                valueExpr: "id"
                            }, validationRules: [{ type: "required" }],
                            ...DxGridUtils.lookupSortDisplayExpr,
                        },
                        {
                            dataField: "paymentDue",
                            dataType: "string",
                            caption: "Payment Due",
                            setCellValue: function(newData, value, currentRowData) {
                                newData.paymentDue = value;
                            },
                            lookup: {
                                dataSource: self.paymentDueTypes,
                                displayExpr: "name",
                                valueExpr: "id"
                            }, validationRules: [{ type: "required" }],
                            ...DxGridUtils.lookupSortDisplayExpr,
                        },
                        {
                            dataField: "periodsPerYear",
                            dataType: "string",
                            caption: "Periods/Year",
                            lookup: {
                                dataSource: self.billingPeriods,
                                displayExpr: "name",
                                valueExpr: "id"
                            }, validationRules: [{ type: "required" }],
                            ...DxGridUtils.lookupSortDisplayExpr,
                        },
                        {
                            dataField: "periodsInArrears",
                            dataType: "number",
                            caption: "Periods in Arrears",
                            setCellValue: function(newData, value, currentRowData) {
                                newData.periodsInArrears = value;
                            },
                            format: {
                                type: "fixedPoint",
                                precision: 0
                                },
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "range",
                                    min: 0,
                                    max: 100,
                                    message: "Must be between 0 and 100"
                                },
                                {
                                    type: "custom",
                                    validationCallback: self.isValidPeriodInArrears,
                                    reevaluate: true,
                                    message: "Periods In Arrears only applies when Payment Due is [Not yet due]"
                                }
                            ],
                        },
                        DxGridUtils.dateColumn({
                            dataField: 'firstPeriodStartDate',
                            caption: 'First Period',
                            cellTemplate: DxGridUtils.dateCellTemplateMMDD,
                            ...DxGridUtils.dateCellTemplateSortValue('firstPeriodStartDate','MM/dd'),
                            format: {
                                type: "monthAndDay"
                            }, required: true,
                        }),
                        {
                            caption: "Credit",
                            dataField: "includein1099",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate
                        }
                    ],
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                    update: self.onGridUpdate
                };
            },
            isValidPeriodInArrears(item) {
                if (_.parseNumber(item.data.periodsInArrears, -1) === 0) return true;
                return !(_.parseNumber(item.data.paymentDue, -1) !== 0 && _.parseNumber(item.data.periodsInArrears, -1) !== 0);
            },
            isNotDuplicateDescription(item) {
                const self = this;
                let dup = {};
                dup = _.find(self.items, (i) => {
                    return _.toLower(_.trim(i.description)) === _.toLower(_.trim(item.data.description))
                            && _.parseNumber(_.get(i, self.itemKey, -1), -1) != _.parseNumber(_.get(item.data, self.itemKey, -1), -1);
                });

                return dup ? false : true;
            },
            fetchData() {
                const self = this;
                if (self.regionID <= 0) return;
                let apiPromise = self.$api.ProrateRegionDefaultsApi.get(self.regionID);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.originalRegionDefault = new ProrateRegionDefaultDto(result.regionDefault);
                        self.regionDefault = new ProrateRegionDefaultDto(result.regionDefault);
                        self.items = _.map(result.regionDetails, d => new ProrateDefaultDto(d));
                        self.refresh();
                    })
                    .catch(error => {
                        self.items = [];
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },
            onRegionChange(e) {
                this.fetchData();
            },
            onSave(eventArgs){
                return this.save(eventArgs);
            },
            saveChildComponents(){
                return this.gridInstance.saveEditData();
            },
            save(args={}) {
                const self = this;
                self.validationErrors = [];
                let changes = self.getAuditChanges(self.originalRegionDefault.toDataObject(), self.regionDefault.toDataObject());
                let childChanges = self.childComponentsHaveChanges();

                if (_.isEmpty(args) && changes.length < 1 && !childChanges){
                    GlobalEventManager.saveCompleted({ success: true });
                    return Promise.resolve(true);
                }
                self.saveChildComponents().then(() => {
                    if (changes.length > 0) {
                        let item = self.regionDefault.toDataObject();
                        let apiPromise = self.$api.ProrateRegionDefaultsApi.save(item, changes);
                        return self.$rqBusy.wait(apiPromise)
                            .then(result => {
                                self.$toast.success({ message: `${self.itemTypeNamePlural} were saved.` });
                                self.originalRegionDefault = new ProrateRegionDefaultDto(result);
                                self.regionDefault = new ProrateRegionDefaultDto(result);
                                self.refresh();
                                GlobalEventManager.saveCompleted({ success: true });
                                return true;
                            })
                            .catch(error => {
                                self.validationErrors.push(error.errorMessage);
                                GlobalEventManager.saveCompleted({ success: false });
                                return error;
                            });
                    } else {
                        if (!childChanges) self.$toast.info("No changes detected");
                        GlobalEventManager.saveCompleted({ success: false });
                        return Promise.resolve(false) ;
                    }
                });
            },
            saveItem(item, changes){
                const self = this;

                if(changes.length === 0) {
                    return Promise.resolve(item);
                }

                let apiPromise = self.$api.ProrateRegionDefaultsApi.saveItem(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$toast.success(`${self.itemTypeName} ${item.description} was saved.`);
                        self.originalRegionDefault = new ProrateRegionDefaultDto(result.regionDefault);
                        self.regionDefault = new ProrateRegionDefaultDto(result.regionDefault);
                        self.items = _.map(result.regionDetails, d => new ProrateDefaultDto(d));
                        self.refresh();
                        return Promise.resolve(result);
                    }).catch(error => {
                        self.$toast.error(`Error saving ${self.itemTypeName}.`);
                        console.error(error);
                        return Promise.reject(error);
                    });
            },
            refresh() {
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },
            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => _.parseNumber(_.get(item, self.itemKey), 0)  === key);

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new ProrateDefaultDto(_.assign({}, self.items[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                if(_.has(values, "description")) {
                    updatedItem.overrideDescription = values.description !== updatedItem.defaultDescription; // set override flag based on comparison
                }
                self.saveItem(updatedItem, changes);
            },
            periodsPerYearChanged() {
                this.regionDefault.daysInYear = this.billingDaily ? 0 : this.regionDefault.daysInYear;
            }

        }
    }
</script>
