<template>
    <rq-page-section
        :title="title"
        class="clause-list-manager"
        borderless
        flex-full
        scrollable>
        <template #header-actions>
            <ul class="nav">
                <li class="nav-item">
                    <b-button variant="theme" @click="onAddClause">Add</b-button>
                </li>
            </ul>
            <ul v-if="totalItemCount > 1"
                class="nav ml-auto">
                <li class="nav-item">
                    <rq-expand-collapse-all
                        :all-expanded="allItemsExpanded"
                        :all-collapsed="allItemsCollapsed"
                        expand-all-tooltip="Expand All Clauses"
                        collapse-all-tooltip="Collapse All Clauses"
                        @expand-all="setAllIsCollapsed(false)"
                        @collapse-all="setAllIsCollapsed(true)"
                        inline
                    />
                </li>
            </ul>
        </template>
        <ClauseList
            v-if="hasItems"
            ref="clauseList"
            :group-id="packageId"
            :item-actions="clauseActions"
            v-model:items="items"
            @item-action="onItemAction"
            @list-changed="onChanged"
            @item-changed="onChanged"
            sort-enabled
        />
        <rq-no-data v-else
            class="mt-5"
            :text='`Click "Add" above to add clauses to this package.`'
        />
    </rq-page-section>
</template>

<script>
    import { ClauseList, ClauseListFormat } from "@/shared/components/rq/";
    import StandardLanguageSelectionDialog from "./StandardLanguageSelectionDialog";
    import { StandardLanguageItemModel } from "@/shared/models/clause-management";
    import { useClauseDragAndDrop } from "@/shared/composables/clause-management";

    export default {
        name: "ClauseListManager",

        components: { ClauseList },

        props: {
            title: { type: String, default: "Clauses" },
            modelValue: { type: Array, default: () => [] },
            packageId: { type: Number, default: null },
            categoryId: { type: Number, default: null }
        },

        provide() {
            return {
                groupId: this.packageId
            };
        },

        setup(props, context) {

            const onChanged = itemList => context.emit("update:modelValue", itemList);

            const {
                items,
                deletedItems,
                totalItemCount,
                setItemList,
                appendListItems,
                updateListItem,
                deleteListItem,
                refreshListValues,
                getFlattenedList
            } = useClauseDragAndDrop(props.modelValue, StandardLanguageItemModel, onChanged, true);

            return {
                items,
                deletedItems,
                totalItemCount,
                setItemList,
                appendListItems,
                updateListItem,
                deleteListItem,
                refreshListValues,
                getFlattenedList
            };
        },

        computed: {
            hasItems() { return !_.isEmpty(this.items); },
            rootItemCount() { return _.sumBy(this.items, ({ parentID }) => Number(_.parseNumber(parentID, 0) === 0)); },
            allItemsExpanded() { return _.every(this.items, "allExpanded"); },
            allItemsCollapsed() { return _.every(this.items, "allCollapsed"); },
            clauseActions() {
                return [
                    { key:"adv-opts", title:"Advanced Options", icon:"fas fa-cog", showAlways: false },
                    { key:"delete-item", title: "Delete Clause(s)", icon:"fas fa-times-circle", showAlways: true },
                ];
            }
        },

        methods: {
            onAddClause() {
                this.launchStandardLanguageDialog();
            },

            onItemAction(e) {
                const self = this;
                let { actionKey, item } = e;
                switch(actionKey) {
                    case "delete-item":
                        self.deleteListItem(item.id);
                        break;
                    case "adv-opts":
                        self.launchAdvancedOptions(item);
                        break;
                }
            },

            onChanged() {
                this.refreshListValues();
            },

            setAllIsCollapsed(val) {
                _.invoke(this, "$refs.clauseList.setAllCollapsedValue", val);
            },

            launchStandardLanguageDialog(){
                const self = this;
                let okHandler = (e) => {
                    self.appendStandardLanguages(e.component.selectedStandardLanguages);
                    return true;
                };
                self.$dialog.open({
                    title: "Add Clause(s)",
                    height: "85%",
                    width: "80%",
                    component: StandardLanguageSelectionDialog,
                    props: { categoryId: self.categoryId },
                    onOk: okHandler
                });
            },

            launchAdvancedOptions(item){
                const self = this;
                if(_.isNil(item)) return;
                self.$dialog.open({
                    title: `List Format: ${item.code}`,
                    width: 800,
                    adaptive: true,
                    props:{
                        listFormat: item.getListFormat(),
                        nullDefaults: true
                    },
                    component: ClauseListFormat,
                    onOk(e) {
                        let { listFormat } = e.component.getResult();
                        listFormat.keepCurrentLevel = listFormat.level !== item.level;
                        item.setListFormat(listFormat);
                        self.updateListItem(item);
                    }
                });
            },

            appendStandardLanguages(items) {
                const self = this;
                let newItems = _.map(items, item => new StandardLanguageItemModel({
                    standardLanguagePackageDetailID: -_.parseNumber(_.uniqueId()),
                    ..._.pick(item, ["standardLanguageID", "code", "description", "text", "html"]),
                    parentID: 0,
                    standardLanguagePackageID: self.packageId,
                    level: 1
                }));
                self.appendListItems(newItems);
            }
        }
    };
</script>
