<template>
    <div class="rq-container standard-language-dialog">
        <standard-language-selection
            :category-id="categoryId"
            v-model:standard-language-selected="selectedStandardLanguages"
            exclude-packages
            dialog-mode
            allow-duplicates
        />
    </div>
</template>

<script>
    import StandardLanguageSelection from "@documents/components/prompts/StandardLanguageSelection";
    export default {
        name: "StandardLanguageSelectionDialog",

        components: {
            StandardLanguageSelection
        },

        props: {
            categoryId: { type: Number, default: 0 }
        },

        data() {
            return {
                selectedStandardLanguages: []
            };
        },

        computed: {
            hasSelection() { return !_.isEmpty(this.selectedStandardLanguages); }
        },

        watch: {
            hasSelection: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    let buttonStateEvent = newValue ? "enable-ok" : "disable-ok";
                    this.$emit(buttonStateEvent);
                },
                immediate: true
            }
        }

    };
</script>