<template>
    <div class="content-wrapper">
        <rq-banner
            variant="error"
            title="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="(v$.$error || v$.$invalid) && showBanner"/>
        <div class="rq-container mt-3">
            <div class="row">
                <div class="col col-12 col-lg-4 form-group form-required" :class="{ 'has-error' : v$.item.regionID.$error }">
                    <label for="region">Region</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_region' }"
                        :items="regionsLookup"
                        value-expr="regionID"
                        display-expr="displayName"
                        :search-enabled="true"
                        v-model="v$.item.regionID.$model"
                    />
                    <rq-validation-feedback
                        message="Region is required."
                    />
                </div>
                <div class="col col-12 col-lg-4 form-group form-required" :class="{ 'has-error' : v$.item.description.$error || v$.item.uniqueDescription.$invalid }">
                    <label for="description">Description</label>
                    <input
                        automation_id="txt_description"
                        type="text"
                        class="form-control"
                        placeholder="Description"
                        maxlength="25"
                        v-model="v$.item.description.$model">
                    <rq-validation-feedback
                        :messages="{
                            'Description is required.': v$.item.description.$error,
                            'A Duplicate Search already exists with that description.': v$.item.uniqueDescription.$invalid
                        }"
                    />
                </div>
                <div class="col col-12 col-lg-4 form-group" :class="{ 'has-error' : v$.item.numberOfRequiredFields.$error || v$.item.requiredCountValid.$invalid }">
                    <label for="numberOfRequiredFields">Number Of Required Fields</label>
                    <rq-input-number
                        id="txt_numberOfRequiredFields"
                        decimals="0"
                        placeholder="Number Of Required Fields"
                        v-model="v$.item.numberOfRequiredFields.$model"
                    />
                    <rq-validation-feedback
                        message="Please enter a value greater than or equal<br/>to the number of selected required fields."
                    />
                </div>
            </div>
            <div class="row">
                <div class="col col-2 col-sm-12 form-group">
                    <rq-selection-list-box
                        left-label="Available Fields"
                        right-label="Selected Fields"
                        v-model:items="fieldItemData"
                        :actions="listActions"
                        @extra-action="onExtraAction"
                        @change="onFieldListChange"
                        sequence-enabled
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import { required } from "@vuelidate/validators";
    import { useVuelidate } from "@vuelidate/core";
    import { DuplicateSearchField }  from "../models";
    import RqSelectionListBox from "@/shared/components/rq/list-box/RqSelectionListBox";
    import { ListBoxItemModel } from "@/shared/models/models";

    export default {
        name: 'DuplicateSearchForm',

        components: { RqSelectionListBox },

        props: {
            item: { type: Object, default: () => ({}) },
            uniqueValidator: { type: Function, default: (() => true) }
        },

        setup: () => ({ v$: useVuelidate() }),

        data() {
            return {
                fieldItemData: [],
                showBanner: false
            }
        },

        computed: {
            ...mapGetters([
                "duplicateSearchFields",
                "regionsLookup"
            ]),
            selectedFields() { return _.sortBy(_.filter(this.fieldItemData, { parentIndex: 1 }), "sequence"); },
            listActions() {
                let isDisabled = function(args, isMarked) {
                    let selected = _.get(args, "selectedItems", null) || [];
                    return _.every(selected, { isMarked });
                }
                return [
                    { automation_id: "btn_mark_required", text: "Mark Required", name: "mark-required", disabled: args => isDisabled(args, true) },
                    { automation_id: "btn_remove_mark_required", text: "Remove Required", name: "remove-mark-required", disabled: args => isDisabled(args, false) }
                ];
            }
        },

        validations: () => ({
            item: {
                description: { required },
                regionID: { required },
                numberOfRequiredFields: {},
                fields: {},
                requiredCountValid: (val,vm) => {
                    let requiredCount = _.sumBy(vm.selectedFields, f => f.isMarked ? 1 : 0);
                    return val.numberOfRequiredFields === 0 || _.gte(val.numberOfRequiredFields, requiredCount);
                },
                uniqueDescription: (val, vm) => vm.uniqueValidator(val, "description")
            }
        }),

        created(){
            this.parseFields();
        },

        methods: {
            parseFields() {
                const self = this;
                let itemFields = self.mapItems(self.item.fields, "field", "description", 1, "required");
                _.each(itemFields, item => {
                    let fieldItem = _.find(self.duplicateSearchFields, { fieldID: item.itemID });
                    if(_.isNil(fieldItem)) return;
                    item.itemName = fieldItem.fieldDisplay;
                })
                let available = _.filter(self.duplicateSearchFields, f => _.every(itemFields, item => item.itemID !== f.fieldID));
                let availableFields = self.mapItems(available, "fieldID", "fieldDisplay", 0);
                self.fieldItemData = _.concat(availableFields, itemFields);
            },

            mapItems(data, valueExpr, displayExpr, parentIndex, markedExpr=null) {
                let duplicateOrderSearchSetupID = this.item.duplicateOrderSearchSetupID;
                return _.map(data, item => new ListBoxItemModel({
                    itemID: item[valueExpr],
                    itemName: item[displayExpr],
                    sequence: _.getNumber(item, "sequence", 0),
                    isMarked: _.isNil(markedExpr) ? false : item[markedExpr],
                    parentIndex
                }));
            },

            commitListChanges(items) {
                this.item.fields = _.map(this.selectedFields, item => new DuplicateSearchField({
                    field: item.itemID,
                    description: item.itemName,
                    required: item.isMarked,
                    sequence: item.sequence
                }));
            },

            save(){
                const self = this;
                self.showBanner = true;
                if(self.item.isNew || self.v$.$error || self.v$.$invalid) self.v$.$touch();
                if(!self.v$.$anyDirty) return Promise.resolve(true);
                if(self.v$.$error || self.v$.$invalid) return Promise.resolve(false);

                self.commitListChanges();

                let requestData = self.item.toDataObject();
                let apiPromise = self.$api.DuplicateSearchApi.saveDuplicateSearchSetup(requestData);
                return self.$rqBusy.wait(apiPromise)
                    .then(item => {
                        self.$toast.success("Duplicate search setup was saved successfully.");
                        return true;
                    })
                    .catch(err => {
                        console.error(err);
                        self.$toast.error("An error occurred while saving duplicate search setup.");
                    });
            },

            onFieldListChange(e) {
                this.v$.$touch();
            },

            onExtraAction(e) {
                let markedValue = e.action.name === "mark-required";
                _.each(this.selectedFields, item => {
                    if(!_.includes(e.selectedKeys, item.itemID)) return;
                    item.isMarked = markedValue;
                    if (!this.v$.$anyDirty) this.v$.$touch();
                });
            }
        }
    }
</script>