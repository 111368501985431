<template>
    <div class="content-wrapper">
        <rq-page-section :title="itemTypeName" borderless headerSize="lg">
            <template #header-actions>
                <ul class="nav config-actions ms-auto">
                    <li class="nav-item">
                        <b-btn automation_id="btn_cancel" variant="action" v-rq-tooltip.hover.top
                            title="Discard changes and reload data" @click="onCancel">Cancel
                        </b-btn>
                    </li>
                    <li class="nav-item">
                        <b-btn automation_id="btn_save" variant="action" @click="onSave" :disabled="readOnly || v$.$error">Save
                        </b-btn>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{ itemTypeDescription }}</div>
            </template>
            <div class="row">
                <div class="col-md-3 col-lg-3 col-xl-3 form-group label-lg">
                    <b-form-checkbox automation_id="chk_ready2closeEnabled" id="ready2closeEnabled"
                        v-model="configuration.active" :disabled="readOnly">Enable ready2close</b-form-checkbox>
                </div>
            </div>
            <div class="row" v-if="configuration.active">
                <div class="col-md-4 col-lg-4 col-xl-4 form-group ms-3">
                    <div class="row">
                        <label class="col-auto col-form-label with-info" for="txt_pollingInterval">ready2close Polling
                            Interval</label>
                        <div class="col-auto ms-auto"
                            title="Select how frequently your Horizon file data is synced with ready2close"
                            v-rq-tooltip.hover.right>
                            <FontAwesomeIcon icon="fas fa-info-circle" class="text-dark" />
                        </div>
                    </div>
                    <input id="txt_pollingInterval"
                        type="number"
                        :class="{ 'form-control':true, 'has-error': v$.configuration.pollingInterval.isInRange.$invalid }"
                        v-model="v$.configuration.pollingInterval.$model"
                        title="Select minutes 5-60"
                            v-rq-tooltip.hover.bottom
                        maxlength="2"
                        min="5"
                        max="60"
                    />
                    <rq-validation-feedback
                        :messages="{ 'Polling Interval must be between 5 and 60 minutes.': v$.configuration.pollingInterval.isInRange }"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-3 col-lg-3 col-xl-3 form-group ms-3">
                    <b-button automation_id="btn_push_config"
                            ref="btn_push_config"
                            variant="theme"
                            @click="pushConfiguration"
                            :disabled="readOnly">Push Configuration Changes</b-button>
                </div>
            </div>
        </rq-page-section>
    </div>
</template>
<script>
import { mapState } from "vuex";
import SystemDefaultsFormMixin from "../../shared/SystemDefaultsFormMixin";
import { GlobalEventManager } from "@/app.events";
import { Ready2CloseConfigurationDto } from "../models";
import { required } from "validators";
import { useVuelidate } from "@vuelidate/core";

export default {
    name: "ready2closeForm",
    components: {
    },
    mixins: [SystemDefaultsFormMixin],
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            configuration: {},
            originalData: {},
            validationErrors: [],
        };
    },
    computed: {
        ...mapState({
            readOnly: state => state.isPageReadOnly,
        }),
    },
    created() {
        const self = this;
        GlobalEventManager.onSave(self, self.onSave);
        self.fetchData();
    },
    beforeUnmount() {
        GlobalEventManager.unregister(this);
    },
    validations() {
        return {
            configuration: {
                pollingInterval: {
                    required,
                    isInRange(val) {
                        if (_.isNil(val)) return false;
                        return _.inRange(val, 5, 60);
                    }
                },
            }
        }
    },
    methods: {
        fetchData() {
            const self = this;

            let apiPromise = self.$api.Ready2CloseConfigurationApi.getConfiguration();
            return self.$rqBusy.wait(apiPromise)
                .then(result => {
                    self.configuration = new Ready2CloseConfigurationDto(_.clone(result));
                    self.originalData = new Ready2CloseConfigurationDto(_.clone(result));
                })
                .catch(error => {
                    self.configuration = [];
                    self.$toast.error({ message: `Error loading ${self.itemTypeName}.` });
                    return error;
                })
        },
        onSave(e) {
            return this.save(e);
        },
        onCancel(e) {
                const self = this;
                let changes = self.getAuditChanges(self.originalData, self.configuration);
                let ok = function (args) {
                    self.configuration = new Ready2CloseConfigurationDto(_.clone(self.originalData));
                };
                if (changes.length === 0) {
                    self.$toast.info("No changes detected");
                } else {
                    self.$dialog.confirm("Confirm Cancel", "Discard changes and reload data?", ok);
                }
            },
        save(args = {}) {
            const self = this;
            if (_.isFunction(_.get(self, 'v$.$touch'))) {
                self.v$.$touch();
                if (self.v$.$error) return Promise.reject();
            }
            self.validationErrors = [];
            let changes = self.getAuditChanges(self.originalData, self.configuration);
            if (_.isEmpty(args) && changes.length < 1) {
                GlobalEventManager.saveCompleted({ success: true });
                return Promise.resolve(true);
            }
            if (changes.length > 0) {
                let apiPromise = self.$api.Ready2CloseConfigurationApi.saveR2CConfiguration(self.configuration, changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$toast.success({ message: `${self.itemTypeName} were saved.` });
                        self.configuration = new Ready2CloseConfigurationDto(_.clone(result));
                        self.originalData = new Ready2CloseConfigurationDto(_.clone(result));
                        GlobalEventManager.saveCompleted({ success: true });
                        return Promise.resolve(true);
                    })
                    .catch(error => {
                        self.$toast.error(`Error Saving ${self.itemTypeName}`);
                        GlobalEventManager.saveCompleted({ success: false });
                        return Promise.resolve(false);
                    });
            } else {
                self.$toast.info("No changes detected");
                GlobalEventManager.saveCompleted({ success: true });
                return Promise.resolve(true);
            }
        },
        pushConfiguration(){
            const self = this;
        
            let apiPromise = self.$api.Ready2CloseConfigurationApi.pushConfiguration();
            return self.$rqBusy.wait(apiPromise)
                .then(result => {
                    self.$toast.success(`Pushed configuration successfully.`);
                    return result;
                }).catch(error => {
                    self.$toast.error({ message: `Error pushing configuration settings.` });
                    return error;
                });
        }
    }
}
</script>
