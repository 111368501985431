<template>
    <div class="content-wrapper" v-if="hasTaxConfiguration">
        <rq-page-section :title="itemTypeNamePlural" headerSize="md" :id="elementName('rq-page')" borderless>

        <fieldset :disabled="readOnly">
            <div class="row">
                <div class="col col-3 col-sm-6 col-md-3 col-lg-3 form-group">
                    <label for="drp_calculationBasis">Calculation Basis</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_calculationBasis', id: 'drp_calculationBasis' }"
                        :items="transferTaxCalculationBasisOptions"
                        v-model="transferTaxConfiguration.calculationBasis"
                        value-expr="id"
                        display-expr="name"
                        :disabled="readOnly"
                        :search-enabled="true"
                    />
                </div>
            </div>
        </fieldset>

        <fieldset :disabled="readOnly">
            <div class="row" v-if="transferTaxConfiguration.calculationBasis == 0">
                <div class="col col-3 col-sm-12 col-md-3 col-lg-3 form-group">
                    <label for="txt_flat_fee_amount">Flat Fee Amount</label>
                    <div class="input-group">
                        <span class="input-group-text">$</span>
                        <rqInputNumber
                            automation_id="txt_flat_fee_amount"
                            defaultValue="0"
                            decimals="2"
                            cssClass="form-control"
                            v-model="transferTaxConfiguration.flatFeeAmount">
                        </rqInputNumber>
                    </div>
                </div>
            </div>
        </fieldset>

        <fieldset :disabled="readOnly">
            <div class="row" v-if="transferTaxConfiguration.calculationBasis == 1">
                <div class="col col-3 col-sm-6 col-md-3 col-lg-3 form-group">
                    <label for="txt_percentage_percent">Percentage</label>
                    <div class="input-group">
                        <rqInputNumber v-model="transferTaxConfiguration.percentagePercent"
                                        defaultValue="0"
                                        decimals="4"
                                        minValue="0"
                                        maxValue="100"
                                        cssClass="form-control"
                                        automation_id="txt_percentage_percent">
                        </rqInputNumber>
                        <span class="input-group-text">%</span>
                    </div>
                </div>
               <div class="col col-3 col-sm-6 col-md-3 col-lg-3 form-group">
                    <label for="drp_charge_percentage_basis_id">Percentage Basis</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_charge_percentage_basis_id', id: 'drp_charge_percentage_basis_id' }"
                        :items="transferTaxPercentageBasedOnOptions"
                        v-model="transferTaxConfiguration.chargePercentageBasisID"
                        value-expr="id"
                        display-expr="name"
                        :disabled="readOnly"
                        :search-enabled="true"
                    />
                </div>
                 <div class="col col-3 col-sm-6 col-md-3 col-lg-3 form-group">
                    <label for="txt_percentage_minimum">Min. Charge</label>
                    <div class="input-group">
                        <span class="input-group-text">$</span>
                        <rqInputNumber
                            automation_id="txt_percentage_minimum"
                            defaultValue="0"
                            decimals="2"
                            cssClass="form-control"
                            v-model="transferTaxConfiguration.percentageMinimum">
                        </rqInputNumber>
                    </div>
                </div>
               <div class="col col-3 col-sm-6 col-md-3 col-lg-3 form-group">
                    <label for="txt_percentage_maximum">Max. Charge</label>
                    <div class="input-group">
                        <span class="input-group-text">$</span>
                        <rqInputNumber
                            automation_id="txt_percentage_maximum"
                            defaultValue="0"
                            decimals="2"
                            cssClass="form-control"
                            v-model="transferTaxConfiguration.percentageMaximum">
                        </rqInputNumber>
                    </div>
                </div>
            </div>
        </fieldset>

        <fieldset :disabled="readOnly">
            <div class="row" v-if="transferTaxConfiguration.calculationBasis == 2">
                <div class="col col-3 col-sm-6 col-md-3 col-lg-3 form-group">
                    <label for="drp_scalable_basis_id">Based On</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_scalable_basis_id', id: 'drp_scalable_basis_id' }"
                        :items="transferTaxPercentageBasedOnOptions"
                        v-model="transferTaxConfiguration.scalableBasisID"
                        value-expr="id"
                        display-expr="name"
                        :disabled="readOnly"
                        :search-enabled="true"
                    />
                </div>
                <div class="col col-3 col-sm-6 col-md-3 col-lg-3 form-group">
                    <label for="txt_scalable_minimum">Min. Charge</label>
                    <div class="input-group">
                        <span class="input-group-text">$</span>
                        <rqInputNumber
                            automation_id="txt_scalable_minimum"
                            defaultValue="0"
                            decimals="2"
                            cssClass="form-control"
                            v-model="transferTaxConfiguration.scalableMinimum">
                        </rqInputNumber>
                    </div>
                </div>
               <div class="col col-3 col-sm-6 col-md-3 col-lg-3 form-group">
                    <label for="txt_scalable_maximum">Max. Charge</label>
                    <div class="input-group">
                        <span class="input-group-text">$</span>
                        <rqInputNumber
                            automation_id="txt_scalable_maximum"
                            defaultValue="0"
                            decimals="2"
                            cssClass="form-control"
                            v-model="transferTaxConfiguration.scalableMaximum">
                        </rqInputNumber>
                    </div>
                </div>
            </div>
        </fieldset>

        <fieldset :disabled="readOnly">
            <div class="row" v-if="transferTaxConfiguration.calculationBasis == 2">
                <div class="col col-12 form-group">
                    <rqdx-action-data-grid
                        ref="dataGrid"
                        :automation_id="elementName('tbl')"
                        :actions="selectionActions"
                        :config="gridConfig"
                        :data-source="gridDataSource"
                        :export-file-name="elementName('', 'data')"
                        v-model:validation-errors="validationErrors"
                        @delete="onGridDelete"
                        :persist-state="false"
                        hide-search
                        hide-show-column-chooser
                        :rq-editable="!readOnly"
                        focus-after-insert="new-row"
                        >
                        <template #toolbar>
                            <ul class="nav me-auto pt-2 pb-2" >
                                <li class="nav-item form-group">
                                    <b-button :automation_id="elementName('btn_add')"
                                        type="button"
                                        class="btn btn-sm btn-theme me-1"
                                        variant="theme"
                                        v-rq-tooltip.hover.top="{ title: `Add ${itemTypeName}` }"
                                        :disabled="readOnly"
                                        @click="onGridAdd">
                                        Add
                                    </b-button>
                                </li>
                            </ul>
                        </template>
                    </rqdx-action-data-grid>
                </div>
            </div>
        </fieldset>
        </rq-page-section>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { TransferTaxRatesDto }  from "../models";
import DxGridUtils from "@/shared/utilities/DxGridUtils";
import { TransferTaxCalculationBasis, TransferTaxPercentageBasedOn } from '../../enums';


export default {
    name: 'TransferTaxConfigurationCalculationForm',
    props: {
        transferTaxConfiguration: { type: Object, required: true, default: () => {} },
        items: {type: Array, default: () => []},
        validationList: {type: Array, default: () => []}
    },
    data() {
        return {
            validationErrors: []
        }
    },

    computed: {

        ...mapState({
            readOnly: state => state.isPageReadOnly,
        }),
        ...mapGetters([
            "lookupHelpers",
            "lookupItems"
        ]),
        hasTaxConfiguration() { return !_.isEmpty(_.get(this, "transferTaxConfiguration")); },
        transferTaxConfigurationID() { return _.parseNumber(_.get(this, "transferTaxConfiguration.transferTaxConfigurationID", 0), 0); },
        selectionActions() {
            return [{
                name: "delete",
                text: "Delete",
                eventName: "delete",
                requireSelection: true,
                allowMultiSelection: true,
                tooltip: `Delete ${this.itemTypeName}`,
                // disabled(e) {
                //     //let selectedItem = _.get(e, "data", null) || [];
                //     if(selectedItem.disableDelete) {
                //       //  return "Only templates with the source, \"User\", can be deleted";
                //         return true;
                //     }
                //     return false;
                // }
            }];
        }
    },

    watch: {
        validationErrors(newValue) {
            this.$emit("update:validationList", this.validationErrors);
        }
    },

    created() {
        this.initNonReactiveVariables();
        this.initGridConfig();
        this.initListeners();
        this.setDeleteAccess();
    },

    beforeUnmount () {
        this.$events.off(this.addEventName, this.onAddItem);
    },

    methods: {

        setDeleteAccess(){
            if(this.items.length > 0)
            {
                _.each(this.items, itm => _.set(itm, 'disableDelete', 'true'));
                let maxitem = _.maxBy(this.items, 'fromAmount');
                maxitem.disableDelete = false;
                var index = _.findIndex(this.items, {fromAmount: maxitem.fromAmount});
                this.items.splice(index, 1, maxitem);
            }
        },

        elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },
        getGrid() {
            return _.get(this.$refs, "dataGrid.gridInstance", {});
        },
        getNextFromAmount(){
            let maxAmount = _.maxBy(this.items, 'toAmount');
            return maxAmount ? maxAmount.toAmount + .01 : 0;
        },
        isToAmountLessThanFromAmount(item) {
            return _.gt(item.data.toAmount, item.data.fromAmount);
        },
        initNonReactiveVariables() {
            const self = this;
            self.addEventName = `add:transfer-tax-rate-calculation`;
            self.itemKey = "transferTaxRatesID";
            self.itemTypeName = "Calculation";
            self.itemTypeNamePlural = "Calculations";
            self.transferTaxCalculationBasisOptions = TransferTaxCalculationBasis.lookupItems;
            self.transferTaxPercentageBasedOnOptions = TransferTaxPercentageBasedOn.lookupItems;
        },
        initGridConfig(){
            const self = this;
            self.gridConfig = {
                paging: { enabled: false },
                remoteOperations: { sorting: false, paging: false },
                columns: [
                    {
                        dataField: self.itemKey,
                        dataType: "number",
                        visible: false,
                        allowEditing: false
                    },
                    {
                        dataField: "fromAmount",
                        caption: "From",
                        dataType: "number",
                        allowEditing: false,
                        cellTemplate: DxGridUtils.moneyCellTemplate,
                    },
                    {
                        dataField: "toAmount",
                        caption: "To",
                        dataType: "number",
                        format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                        cellTemplate: DxGridUtils.moneyCellTemplate,
                        validationRules: [
                            { type: "required" },
                            // {
                            //     type: "custom",
                            //     validationCallback: self.isToAmountLessThanFromAmount,
                            //     message: "To Amount must ge greater than From Amount"
                            // }
                        ]
                    },
                    {
                        dataField: "rate",
                        cellTemplate: DxGridUtils.moneyCellTemplate,
                        dataType: "number",
                        format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                    },
                    {
                        dataField: "usePerRate",
                        caption: "Range",
                        dataType: "boolean",
                        cellTemplate: DxGridUtils.boolCellTemplate
                    },
                    {
                        dataField: "per",
                        dataType: "number",
                        format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##",
                                valueChangeEvent: "input"
                            },
                    },
                    {
                        dataField: "maxRate",
                        caption: "Max",
                        dataType: "number",
                        allowEditing: false,
                        format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                        cellTemplate: DxGridUtils.moneyCellTemplate,
                    },
                ],
                onInitNewRow: function(e) {
                    e.data.usePerRate = false;
                    e.data.fromAmount = self.getNextFromAmount();
                }
            };

            self.gridDataSource = {
                key: self.itemKey,
                loadMode: "raw",
                load () {
                    return Promise.resolve(self.items);
                },
                insert: self.onGridInsert,
                update: self.onGridUpdate
            };

        },
        initListeners(){
            this.$events.on(this.addEventName, this.onGridAdd);
        },
        onGridAdd() {
            let grid = this.getGrid();
            if(_.isEmpty(grid)) return;
            grid.addRow();
        },
        onGridDelete(e) {
            if(!e || !e.data) return;
            const self = this;
            let items = e.data;
            let ok = function (args) {
                let toBeDeletedKeys = _.map(items, self.itemKey);
                let apiPromise = self.$api.TransferTaxConfigurationApi.deleteRate(toBeDeletedKeys);
                self.$rqBusy.wait(apiPromise)
                    .then(key => {
                        self.removeGridItems(items);
                        self.setDeleteAccess();
                        let message = key.length > 1 ? `${key.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                        self.$toast.success({ message: message });
                    })
                    .catch(error => {
                        self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                    })
                    .finally(() => {
                        self.refreshGrid();
                    });
            }

                self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
        },
        onGridInsert(values) {
            const self = this;
            let newItem = new TransferTaxRatesDto(values);
            newItem.transferTaxConfigurationID = self.transferTaxConfigurationID;
            let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
            return self.saveItem(newItem, changes)
                .then(result => {
                    self.items.push(new TransferTaxRatesDto(_.clone(result)));
                    self.setDeleteAccess();
                }).catch(error => {
                    self.validationErrors.push(error.errorMessage);
                    self.$toast.error(`Error saving ${self.itemTypeName}.`);
                    console.error(error.errorMessage);
                }).finally(() => {
                    self.refreshGrid();
                });
        },
        onGridUpdate(key, values) {
            const self = this;
            let itemIndex = _.findIndex(self.items, item => _.parseNumber(_.get(item, self.itemKey), -1) === key);
            if(itemIndex < 0) return self.onGridInsert(values);

            let originalItem = _.cloneDeep(self.items[itemIndex]);
            let updatedItem = new TransferTaxRatesDto(_.assign({}, self.items[itemIndex], values));
            let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

            return self.saveItem(updatedItem, changes)
                .then(result => {
                    self.items[itemIndex] = new TransferTaxRatesDto(_.clone(result));
                    self.setDeleteAccess();
                }).catch(error => {
                    self.validationErrors.push(error.errorMessage);
                    self.$toast.error(`Error saving ${self.itemTypeName}.`);
                    console.error(error.errorMessage);
                }).finally(() => {
                    self.refreshGrid();
                });
        },
        refreshGrid() {
            let grid = this.getGrid();

            if (!_.isEmpty(grid)) {
                grid.clearSelection();
                grid.option("focusedRowIndex", -1);
                grid.refresh();
                grid.updateDimensions();
            }
        },
        removeGridItems(items) {
            _.pullAllBy(this.items, items, this.itemKey);
        },
        saveItem(item, changes) {
            const self = this;

            if(changes.length === 0) {
                self.$toast.error("No changes detected.");
                return Promise.resolve(true);
            }
            self.validationErrors = [];
            let apiPromise = self.$api.TransferTaxConfigurationApi.saveRate(item.toDataObject(), changes);
            return self.$rqBusy.wait(apiPromise)
                .then(result => {
                    return Promise.resolve(result);
                }).catch(error => {
                    return Promise.reject(error);
                });
        },
    }
}
</script>
<style lang="scss" scoped>
</style>
