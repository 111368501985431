<template>
    <div class="property-layout-form content-wrapper">
        <rq-banner
            variant="error"
            title="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="showRequiredErrorBanner"/>
        <div class="rq-container mt-2">
            <div class="row">
                 <div class="col col-12 col-md-6 form-group form-required" :class="{'has-error' : v$.item.regionID.$error}">
                    <label for="drp_region">Region</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_region', id: 'drp_region' }"
                        :items="regionsLookup"
                        :defaultItemEnabled="false"
                        value-expr="regionID"
                        display-expr="displayName"
                        v-model="v$.item.regionID.$model"
                        :search-enabled="true"
                    />
                    <rq-validation-feedback
                        message="Region is required."
                    />
                </div>
                <div class="col col-6 form-group form-required" :class="{ 'has-error' : v$.item.description.$error || v$.item.uniqueDescription.$invalid }">
                    <label for="description">Description</label>
                    <input automation_id="txt_description" v-model="item.description" type="text" class="form-control" id="description" placeholder="Description" maxlength="25" >
                    <rq-validation-feedback
                        :messages="{
                            'Description is required.': v$.item.description.$error,
                            'A Property Layout already exists with that description.': v$.item.uniqueDescription.$invalid
                        }"
                    />
                </div>
                <div class="col col-12 form-group">
                    <label for="briefLegal">Brief Legal Description</label>
                    <textarea rows="2" automation_id="ta_briefLegal" v-model="item.briefLegal" type="text" class="form-control" id="ta_briefLegal" placeholder="Enter Brief Legal Layout" maxlength="675"></textarea>
                </div>
            </div>
            <div class="row mb-1">
                <div class="col-auto ms-auto">
                    <rq-section-expand-collapse-all section-group="property-layout-group" />
                </div>
            </div>
            <rq-section-card title="Instructions" section-group="property-layout-group" collapsible>
                <div class="row">
                    <div class="col col-6 font-xs pe-0">
                        <div class="row">
                            <div class="col-3 pe-0">{&lt;tag&gt;}[tag]{*}</div>
                            <div class="col ps-0">If tag inside the first set of curly brackets is not blank, print the content between {&lt;tag&gt;} and {*}.</div>
                            <div class="w-100"></div>
                            <div class="col-3 pe-0">[&lt;tag&gt;]</div>
                            <div class="col ps-0">Prints the value of the tag contained inside the square brackets.</div>
                        </div>
                        <h6>Example:</h6>
                        <p>
                            <strong>{ADD}[ADD]{*},{c} [c]{*}, {ST}[ST]{*},{ZIP}[ZIP]{*}, Block {BL}[BL]{*} would read the following if all fields contained a value:</strong>
                            <span class="d-block py-1"><em>2745 North Dallas Pkwy, Plano, TX 75093, Block 3</em></span>
                        </p>
                        <p>
                            <strong>If the block field was empty, the brief legal description would read:</strong>
                            <span class="d-block py-1"><em>2745 North Dallas Pkwy, Plano, TX 75093,</em></span>
                        </p>
                    </div>
                    <div class="col col-6 form-group h-theme">
                        <span class="h6">List of fields and their corresponding tags:</span>
                        <table class="table table-sm table-borderless">
                            <colgroup>
                                <col span="2">
                                <col span="2" style="background-color:#C6E9F6">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>Field</th>
                                    <th>Tag</th>
                                    <th>Field</th>
                                    <th>Tag</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr><td>Acreage</td><td>ACE</td><td>Parcel</td><td>PCL</td></tr>
                                <tr><td>Address1 Input</td><td>AD1</td><td>Part</td><td>PRT</td></tr>
                                <tr><td>Address2 Input</td><td>AD2</td><td>Phase</td><td>PH</td></tr>
                                <tr><td>ARB Number</td><td>ARB</td><td>PlatName</td><td>PTN</td></tr>
                                <tr><td>Block</td><td>BL</td><td>Property Layout</td><td>LAY</td></tr>
                                <tr><td>Boat Slip</td><td>BTS</td><td>QTR 1</td><td>QT1</td></tr>
                                <tr><td>Book</td><td>BK</td><td>QTR 2</td><td>QT2</td></tr>
                                <tr><td>Building</td><td>BLD</td><td>QTR 3</td><td>QT3</td></tr>
                                <tr><td>Carport</td><td>CAR</td><td>Range</td><td>RNG</td></tr>
                                <tr><td>City</td><td>C</td><td>Record of Survey</td><td>REC</td></tr>
                                <tr><td>County Name</td><td>CTY</td><td>Section</td><td>SEC</td></tr>
                                <tr><td>District</td><td>DIS</td><td>State</td><td>ST</td></tr>
                                <tr><td>Full Address</td><td>ADD</td><td>Storage</td><td>STG</td></tr>
                                <tr><td>Garage</td><td>GAR</td><td>Street Number</td><td>STN</td></tr>
                                <tr><td>Incorporated</td><td>INC</td><td>Subdivision</td><td>SDC</td></tr>
                                <tr><td>Instrument Number</td><td>INO</td><td>Survey ID</td><td>NUM</td></tr>
                                <tr><td>Lot</td><td>LOT</td><td>Survey</td><td>SUR</td></tr>
                                <tr><td>Maps</td><td>MAP</td><td>Township</td><td>TOW</td></tr>
                                <tr><td>Municipality</td><td>MUN</td><td>Tract</td><td>TRT</td></tr>
                                <tr><td>OutLot</td><td>OUT</td><td>Unit</td><td>UT</td></tr>
                                <tr><td>Page</td><td>PAG</td><td>Zip</td><td>ZIP</td></tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </rq-section-card>
            <rq-section-card title="Hide Fields" section-group="property-layout-group" collapsible>
                <div class="row">
                    <div class="col col-12 form-group">
                        <i>The selected fields will not display on the Property screen</i>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_acreage" automation_id="chk_hide_acreage" v-model="item.hideAcreage">Acreage</b-form-checkbox>
                    </div>
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_incorporated" automation_id="chk_hide_incorporated" v-model="item.hideIncorporated">Incorporated</b-form-checkbox>
                    </div>
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_part" automation_id="chk_hide_part" v-model="item.hidePart">Part</b-form-checkbox>
                    </div>
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_section" automation_id="chk_hide_section" v-model="item.hideSection">Section</b-form-checkbox>
                    </div>
                <!-- </div>
                <div class="row"> -->
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_arb_num" automation_id="chk_hide_arb_num" v-model="item.hideARBNum">ARB Number</b-form-checkbox>
                    </div>
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_instr_num" automation_id="chk_hide_instr_num" v-model="item.hideInstrNum">Instr Num</b-form-checkbox>
                    </div>
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_phase" automation_id="chk_hide_phase" v-model="item.hidePhase">Phase</b-form-checkbox>
                    </div>
                    <!-- RQO-18468 removed Section Group  -->
                    <!-- <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_section_group" automation_id="chk_hide_section_group" v-model="item.hideSectionGroup">Section Group</b-form-checkbox>
                    </div> -->
                <!-- </div>
                <div class="row"> -->
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_block" automation_id="chk_hide_block" v-model="item.hideBlock">Block</b-form-checkbox>
                    </div>
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_lot" automation_id="chk_hide_lot" v-model="item.hideLot">Lot</b-form-checkbox>
                    </div>
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_platname" automation_id="chk_hide_platname" v-model="item.hidePlatname">PlatName</b-form-checkbox>
                    </div>
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_storage" automation_id="chk_hide_storage" v-model="item.hideStorage">Storage</b-form-checkbox>
                    </div>
                <!-- </div>
                <div class="row"> -->
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_boatslip" automation_id="chk_hide_boatslip" v-model="item.hideBoatSlip">Boat Slip</b-form-checkbox>
                    </div>
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_maps" automation_id="chk_hide_maps" v-model="item.hideMaps">Maps</b-form-checkbox>
                    </div>
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_qtr1" automation_id="chk_hide_qtr1" v-model="item.hideQtr1">Qtr1</b-form-checkbox>
                    </div>
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_sub_div_code" automation_id="chk_hide_sub_div_code" v-model="item.hideSubDivCode">Subdivision</b-form-checkbox>
                    </div>
                <!-- </div>
                <div class="row"> -->
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_book" automation_id="chk_hide_book" v-model="item.hideBook">Book</b-form-checkbox>
                    </div>
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_municipality" automation_id="chk_hide_municipality" v-model="item.hideMunicipality">Municipality</b-form-checkbox>
                    </div>
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_qtr2" automation_id="chk_hide_qtr2" v-model="item.hideQtr2">Qtr2</b-form-checkbox>
                    </div>
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_number" automation_id="chk_hide_number" v-model="item.hideNumber">Survey/Abstract</b-form-checkbox>
                    </div>
                <!-- </div>
                <div class="row"> -->
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_building" automation_id="chk_hide_building" v-model="item.hideBuilding">Building</b-form-checkbox>
                    </div>
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_outlot" automation_id="chk_hide_outlot" v-model="item.hideOutLot">OutLot</b-form-checkbox>
                    </div>
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_qtr3" automation_id="chk_hide_qtr3" v-model="item.hideQtr3">Qtr3</b-form-checkbox>
                    </div>
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_township" automation_id="chk_hide_township" v-model="item.hideTownship">Township</b-form-checkbox>
                    </div>
                <!-- </div>
                <div class="row"> -->
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_carport" automation_id="chk_hide_carport" v-model="item.hideCarport">Carport</b-form-checkbox>
                    </div>
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_page" automation_id="chk_hide_page" v-model="item.hidePage">Page</b-form-checkbox>
                    </div>
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_range" automation_id="chk_hide_range" v-model="item.hideRange">Range</b-form-checkbox>
                    </div>
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_tract" automation_id="chk_hide_tract" v-model="item.hideTract">Tract</b-form-checkbox>
                    </div>
                <!-- </div>
                <div class="row"> -->
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_district" automation_id="chk_hide_district" v-model="item.hideDistrict">District</b-form-checkbox>
                    </div>
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_parcel" automation_id="chk_hide_parcel" v-model="item.hideParcel">Parcel</b-form-checkbox>
                    </div>
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_record_of_survey" automation_id="chk_hide_record_of_survey" v-model="item.hideRecordOfSurvey">Record Of Survey</b-form-checkbox>
                    </div>
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_unit" automation_id="chk_hide_unit" v-model="item.hideUnit">Unit</b-form-checkbox>
                    </div>
                <!-- </div>
                <div class="row"> -->
                    <div class="col col-2 col-sm-3 col-md-3 col-lg-3 label-lg">
                        <b-form-checkbox id="chk_hide_garage" automation_id="chk_hide_garage" v-model="item.hideGarage">Garage</b-form-checkbox>
                    </div>
                </div>
            </rq-section-card>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { PropertyLayoutDto }  from "../models";
import { required } from "validators";
import { useVuelidate } from "@vuelidate/core";

export default {
    name: 'PropertyLayoutForm',
    props: {
        item: {type: Object, default: null},
        itemTypeName: {type: String, default: null},
        uniqueValidator: { type: Function, default: (() => true) }
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            validationErrors: [],
            verifiedItem: {},
        };
    },
    validations: () => ({
            item: {
                regionID: { required },
                description: { required },
                uniqueDescription: (val, vm) => vm.uniqueValidator(val)
            }
        }),
    computed: {
        ...mapGetters([
            "regionsLookup"
        ]),
        showRequiredErrorBanner(){ return this.validationErrors.length > 0;},
        errorRegion(){
            return _.has(this.verifiedItem, "requiredRegion");
        }
    }
}
</script>
<style lang="scss">
    .property-layout-form {
        .table {
            font-size: 0.75rem;
            td {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
</style>
