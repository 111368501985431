<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGridSections"
            automation_id="tbl_category_section"
            :config="gridConfig"
            :data-source="gridDataSource"
            export-file-name="category_section_data"
            hide-search
            rq-filters
            fixed-header
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { StandardLanguageCategorySectionDto }  from "../../models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import GridInvokerMixin from "@/shared/mixins/GridInvokerMixin";

    export default {
        name:"StandardLanguageCategorySectionList",
        mixins: [ GridInvokerMixin({ grid:"dataGridSections" }) ],
        props: {
            categorySections: { type: Array, default: () => [] },

        },
        data () {
            const self = this;
            return {
                items: _.map(self.categorySections, item => new StandardLanguageCategorySectionDto(item)),
                hasSections: !_.isEmpty(self.categorySections)
            };
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            sections() { return this.lookupHelpers.getLookupItems(this.lookupItems.STANDARD_LANGUAGE_SECTIONS, 1); },
            regions() { return this.lookupHelpers.getRegions(); }
        },

        created() {
            const self = this;
            self.initGridConfig();
        },

        methods: {
            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "regionID",
                            caption: "Region",
                            calculateSortValue: rowData => {
                                let rowRegion = _.find(self.regions, r => r.regionID === rowData.regionID) || {};
                                return rowRegion.displayName || "";
                            },
                            lookup: {
                                dataSource: self.regions,
                                displayExpr: "displayName",
                                valueExpr: "regionID"
                            },
                            validationRules: [{ type: "required" }]
                        },
                        {
                            dataField: "standardLanguageSectionID",
                            caption: "Section",
                            lookup: {
                                dataSource: self.sections,
                                displayExpr: "name",
                                valueExpr: "id"
                            }
                        },
                        {
                            dataField: "isDefault",
                            caption: "Default",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate
                        }
                    ],
                    selection:{ mode:"none" },
                };

                self.gridDataSource = {
                    key: "standardLanguageCategorySectionID",
                    load: () => Promise.resolve(self.items)
                };
            },

            updateDimensions: _.debounce(function() {
                this.invokeGridMethod("updateDimensions");
            }, 300, { leading: true, trailing: true })
        }
    }
</script>