<template>
    <rq-section-card v-show="sectionVisible" :title="label" collapsible>
        <div class="row">
            <div class="col-6">
                <div class="row" v-show="fieldVisible('addWorkflowTasks')">
                    <div class="col">Add Workflow Tasks</div>
                    <div class="col">
                        <rq-switch type="stop-light"
                            :on="switchOn"
                            :off="switchOff"
                            size="md"
                            automation_id="au_addWorkflowTasks"
                            :disabled="isAddWorkflowTasksReadOnly"
                            v-model="securityData.userGroupSecurity.allowAddWorkflowTask"
                        />
                    </div>
                </div>
                <div class="row" v-show="fieldVisible('userPredefinedTaskOnly')">
                    <div class="col">Use Predefined Tasks only</div>
                    <div class="col">
                        <rq-switch type="stop-light"
                            :on="switchOn"
                            :off="switchOff"
                            size="md"
                            automation_id="au_userPredefinedTaskOnly"
                            v-model="securityData.userGroupSecurity.allowAddPredefinedTasksOnly"
                        />
                    </div>
                </div>
                <div class="row" v-show="fieldVisible('editTasks')">
                    <div class="col">Edit Tasks</div>
                    <div class="col">
                        <rq-switch type="stop-light"
                            :on="switchOn"
                            :off="switchOff"
                            size="md"
                            automation_id="au_editTasks"
                            :disabled="isEditTaskReadOnly"
                            v-model="securityData.userGroupSecurity.allowEditWorkflowTask"
                        />
                    </div>
                </div>
                <!-- <div class="row" v-show="fieldVisible('canRemoveColumns')">
                    <div class="col">Can Remove Columns</div>
                    <div class="col">
                        <rq-switch type="stop-light"
                            :on="switchOn"
                            :off="switchOff"
                            size="md"
                            automation_id="au_canRemoveColumns"
                            v-model="securityData.userGroupSecurity.allowRemoveColumnInOrderWorkflow"
                        />
                    </div>
                </div> -->
                <div class="row" v-show="fieldVisible('allowMarkingTasksNA')">
                    <div class="col">Marking Tasks N/A</div>
                    <div class="col">
                        <rq-switch type="stop-light"
                            :on="switchOn"
                            :off="switchOff"
                            size="md"
                            automation_id="au_allowMarkingTasksNA"
                            :disabled="isMarkTasksNaReadOnly"
                            v-model="securityData.userGroupSecurity.allowMaskTaskNA"
                        />
                    </div>
                </div>
                <div class="row" v-show="fieldVisible('deleteTasks')">
                    <div class="col">Delete Tasks</div>
                    <div class="col">
                        <rq-switch type="stop-light"
                            :on="switchOn"
                            :off="switchOff"
                            size="md"
                            automation_id="au_deleteTasks"
                            :disabled="isDeleteTasksReadOnly"
                            v-model="securityData.userGroupSecurity.allowDeleteWorkflowTask"
                        />
                    </div>
                </div>
                <div class="row" v-show="fieldVisible('editStartDate')">
                    <div class="col">Edit Start Date</div>
                    <div class="col">
                        <rq-switch type="stop-light"
                            :on="switchOn"
                            :off="switchOff"
                            size="md"
                            automation_id="au_editTaskStartDate"
                            :disabled="isEditTaskStartDateReadOnly"
                            v-model="securityData.userGroupSecurity.editTaskStartDate"
                        />
                    </div>
                </div>
                <div class="row" v-show="fieldVisible('allowAutomationBuilder')">
                    <div class="col">Automation Builder</div>
                    <div class="col">
                        <rq-switch type="stop-light"
                            :on="switchOn"
                            :off="switchOff"
                            size="md"
                            automation_id="au_allowAutomationBuilder"
                            :disabled="isAutomationBuilderReadOnly"
                            v-model="securityData.userGroupSecurity.allowAutomationBuilder"
                        />
                    </div>
                </div>
                <div class="row" v-show="fieldVisible('workflowDashboardIndicator')">
                    <div class="col-7 drpdwnColumn form-group">
                        <label for="drp_invoiceDeletion">WORKFLOW DASHBOARD INDICATOR</label>
                        <rq-select-box
                            id="drp_invoiceDeletion"
                            :items="workflowDashboardIndicator"
                            v-model="securityData.userGroupSecurity.workFlowDashboardIndicator"
                        />
                    </div>
                </div>
            </div>
        </div>
    </rq-section-card>
</template>
<script>
    import { WorkflowDashboardIndicator } from "@config/enums";
    import SecurityRoleSectionMixin from "./SecurityRoleSectionMixin";

    export default {
        name: "WorkflowSection",
        mixins: [SecurityRoleSectionMixin],
        data() {
            return {
                sectionFields: [
                    { label: "Add Workflow Tasks", name: "addWorkflowTasks", category: "Workflow" },
                    { label: "User predefined Task only", name: "userPredefinedTaskOnly", category: "Workflow" },
                    { label: "Edit Tasks", name: "editTasks", category: "Workflow" },
                    { label: "Can Remove Columns", name: "canRemoveColumns", category: "Workflow" },
                    { label: "Marking Tasks N/A", name: "allowMarkingTasksNA", category: "Workflow" },
                    { label: "Delete Tasks", name: "deleteTasks", category: "Workflow" },
                    { label: "WORKFLOW DASHBOARD INDICATOR", name: "workflowDashboardIndicator", category: "Workflow" },
                    { label: "Edit Start Date", name: "editStartDate", category: "Workflow" },
                    { label: "Automation Builder", name: "allowAutomationBuilder", category: "Workflow" }
                ]
            };
        },
        watch:{
            isSysAdmin: function(newValue){
                this.setDefaultValues();
            },
            isAdmin: function(newValue){
                this.setDefaultValues();
            },
            isEscrowAdmin: function(newValue){
                this.setDefaultValues();
            },
            "securityData.userGroupSecurity.allowEditWorkflowTask": {
                handler(newValue, oldValue) {
                    if(newValue === oldValue || _.parseBool(newValue)) return;
                    this.securityData.userGroupSecurity.editTaskStartDate = false;
                }
            },
        },
        computed: {
            workflowDashboardIndicator() { return  WorkflowDashboardIndicator.lookupItems; },
            isAddWorkflowTasksReadOnly() { return this.isSysAdmin || this.isAdmin; },
            isEditTaskReadOnly(){ return this.isSysAdmin || this.isAdmin; },
            isMarkTasksNaReadOnly(){ return this.isSysAdmin || this.isAdmin; },
            isDeleteTasksReadOnly(){ return this.isSysAdmin || this.isAdmin; },
            isAutomationBuilderReadOnly(){ return this.isSysAdmin || this.isAdmin; },
            isEditTaskStartDateReadOnly(){ return !this.securityData.userGroupSecurity.allowEditWorkflowTask || this.isSysAdmin || this.isAdmin; }
        },
        methods: {
            setDefaultValues(){
                const self = this;
                if(self.isSysAdmin || self.isAdmin){
                    self.securityData.userGroupSecurity.allowAddWorkflowTask = true;
                    self.securityData.userGroupSecurity.allowEditWorkflowTask = true;
                    self.securityData.userGroupSecurity.allowMaskTaskNA = true;
                    self.securityData.userGroupSecurity.allowDeleteWorkflowTask = true;
                    self.securityData.userGroupSecurity.allowAddPredefinedTasksOnly = false;
                    self.securityData.userGroupSecurity.workFlowDashboardIndicator = null;
                    self.securityData.userGroupSecurity.allowAutomationBuilder = true;
                }
                if(self.isEscrowAdmin && !self.isSysAdmin && !self.isAdmin){
                    self.securityData.userGroupSecurity.allowAddWorkflowTask = false;
                    self.securityData.userGroupSecurity.allowEditWorkflowTask = false;
                    self.securityData.userGroupSecurity.allowMaskTaskNA = false;
                    self.securityData.userGroupSecurity.allowDeleteWorkflowTask = false;
                    self.securityData.userGroupSecurity.allowAddPredefinedTasksOnly = false;
                    self.securityData.userGroupSecurity.workFlowDashboardIndicator = null;
                    self.securityData.userGroupSecurity.allowAutomationBuilder = false;
                }
                if(self.isEscrowAdmin && self.isSysAdmin && self.isAdmin){
                    self.securityData.userGroupSecurity.editTaskStartDate = true;
                }
            }
        }
    }
</script>