<template>
<div class="content-wrapper">
    <div class="row p-2">
        <div class="col col-12 form-group">
            <textarea
                automation_id="txt_note"
                :maxlength="maxlength"
                :rows="rows"
                placeholder="Enter notes here..."
                class="form-control co-notes"
                v-model="localNotes"></textarea>
        </div>
    </div>
</div>
</template>

<script>

    export default {
        name: "NoteDialog",
        props: {
            notes: { Type: String, default: null },
            maxlength: { Type: Number, default: 2000 },
            rows: { Type: Number, default: 7 },
        },

        data () {
            return {
                localNotes: null
            };
        },

        created() {
            this.localNotes = this.notes;
        },

        computed: {
            hasChanged() { return !_.isEqual(this.localNotes, this.notes); }
        },
    }
</script>
