<template>
    <div class="content-wrapper">
        <rq-banner
            message= "Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && hasValidationErrors"
        />
        <rq-page-section :title="title" headerSize="lg" header-only borderless>
            <template #header-actions>
                <ul class="nav config-actions ms-auto">
                    <li class="nav-item form-group">
                        <b-form-checkbox automation_id="chk_inactive"
                        v-model="data.transferTaxConfiguration.inactive"
                        :disabled="readOnly">
                        Inactive</b-form-checkbox>
                    </li>
                    <li class="nav-item">
                        <b-btn automation_id="btn_cancel" variant="action" @click="onCancel">Cancel</b-btn>
                    </li>
                    <li class="nav-item" v-rq-tooltip.hover :title="readOnly ? '(Access Restricted)' : ''">
                        <b-btn
                            automation_id="btn_save"
                            variant="action"
                            :disabled="readOnly"
                            @click="onSave({ userInitiated:true })">
                            Save
                        </b-btn>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <b-btn automation_id="lnk_back" variant="link" :to="{ name:'cfg:sscalc:transfertaxes'}">View Transfer Taxes</b-btn>
            </template>
        </rq-page-section>
        <RqTabs
            v-if="hasTaxConfiguration"
            :tabs="tabItems"
            v-model="tab"
            scrollable>
            <template #general>
                <rq-page-section title="General" headerSize="md" id="stlstmt" borderless>
                    <div class="row">
                        <div class="col col-12 col-md-6 col-lg-3 form-group form-required" :class="{ 'has-error': v$.data.transferTaxConfiguration.regionID.$error }">
                            <label for="drp_region">Region</label>
                            <dx-select-box
                                :input-attr="{ automation_id: 'drp_region', id: 'drp_region' }"
                                class="form-control"
                                :items="regions"
                                :defaultItemEnabled="false"
                                value-expr="regionID"
                                display-expr="displayName"
                                :search-enabled="true"
                                :disabled="readOnly"
                                v-model="v$.data.transferTaxConfiguration.regionID.$model"
                            />
                            <rq-validation-feedback>Region is required</rq-validation-feedback>
                        </div>
                        <div class="col col-12 col-md-6 col-lg-3 form-group form-required" :class="{ 'has-error': v$.data.transferTaxConfiguration.stateAssociated.$error }">
                            <label for="drp_state">State</label>
                            <dx-select-box
                                :input-attr="{ automation_id: 'drp_state', id: 'drp_state' }"
                                class="form-control"
                                :items="usStates"
                                value-expr="id"
                                display-expr="id"
                                :search-enabled="true"
                                @valueChanged="onStateChange"
                                :disabled="readOnly"
                                v-model="v$.data.transferTaxConfiguration.stateAssociated.$model"
                            />
                            <rq-validation-feedback>State is required</rq-validation-feedback>
                        </div>
                        <div class="col col-12 col-md-6 col-lg-3 form-group">
                            <label for="tb_county_ids">County</label>
                                <dx-tag-box
                                    automation_id="tb_county_ids"
                                    class="form-control"
                                    :data-source="stateCounties"
                                    display-expr="name"
                                    value-expr="id"
                                    :defer-rendering="false"
                                    :show-selection-controls="true"
                                    :show-clear-button="true"
                                    :search-enabled="true"
                                    :max-displayed-tags="1"
                                    :show-drop-down-button="true"
                                    :disabled="readOnly"
                                    v-model="data.countyIDs"
                                />
                        </div>
                        <div class="col col-12 col-md-6 col-lg-3 form-group">
                            <label for="txt_city">City</label>
                            <input
                                id="txt_city"
                                automation_id="txt_city"
                                type="text"
                                class="form-control"
                                placeholder="City"
                                maxlength="150"
                                :disabled="readOnly"
                                v-model="data.transferTaxConfiguration.cityAssociated"
                            >
                        </div>
                    </div>

                    <div class="row">
                        <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group form-required" :class="{ 'has-error': v$.data.transferTaxConfiguration.description.$error }">
                            <label for="description">Description</label>
                            <input
                                automation_id="txt_description"
                                type="text"
                                class="form-control"
                                id="description"
                                placeholder="Description"
                                maxlength="250"
                                :disabled="readOnly"
                                v-model="v$.data.transferTaxConfiguration.description.$model"
                            >
                            <rq-validation-feedback>Description is required</rq-validation-feedback>
                        </div>
                        <div class="col col-3 col-sm-6 col-md-6 col-lg-3 form-group form-required" :class="{ 'has-error': v$.data.transferTaxConfiguration.effectiveDate.$error }">
                            <label for="dtp_effective_date">Effective Date</label>
                            <rqdx-date-box
                                id="dtp_effective_date"
                                :disabled="readOnly"
                                v-model="v$.data.transferTaxConfiguration.effectiveDate.$model"
                            />
                            <rq-validation-feedback>Effective Date is required</rq-validation-feedback>
                        </div>
                        <div class="col col-3 col-sm-6 col-md-6 col-lg-3 form-group form-required" :class="{ 'has-error': v$.data.transferTaxConfiguration.effectiveBasedOn.$error }">
                            <label for="drp_effective_date_based_on">Effective Date Based On</label>
                            <dx-select-box
                                :input-attr="{ automation_id: 'drp_effective_date_based_on', id: 'drp_effective_date_based_on' }"
                                class="form-control"
                                :defaultItemEnabled="false"
                                :items="effectiveDateBasedOnOptions"
                                value-expr="id"
                                display-expr="name"
                                :search-enabled="true"
                                :disabled="readOnly"
                                v-model="v$.data.transferTaxConfiguration.effectiveBasedOn.$model"
                            />
                            <rq-validation-feedback>Effective Date Based On is required</rq-validation-feedback>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col col-12 col-md-6 col-lg-3 form-group">
                            <label for="drp_transfer_tax_type">Transfer Tax Type</label>
                            <dx-select-box
                                :input-attr="{ automation_id: 'drp_transfer_tax_type', id: 'drp_transfer_tax_type' }"
                                class="form-control"
                                :items="transferTaxTypes"
                                value-expr="id"
                                display-expr="name"
                                :disabled="readOnly"
                                :search-enabled="true"
                                v-model="data.transferTaxConfiguration.transferTaxTypeID"
                            />
                        </div>
                        <div class="col col-12 col-md-6 col-lg-3 form-group">
                            <label for="drp_who_pays">Who Pays</label>
                            <dx-select-box
                                :input-attr="{ automation_id: 'drp_who_pays', id: 'drp_who_pays' }"
                                class="form-control"
                                :items="whoPaysOptions"
                                v-model="data.transferTaxConfiguration.whoPays"
                                value-expr="id"
                                :disabled="readOnly"
                                display-expr="name"
                                :search-enabled="true"
                            />
                        </div>
                        <div class="col col-12 col-md-6 col-lg-3 form-group">
                            <label for="txt_interest_rate">Who Pays Percentage</label>
                            <div class="input-group">
                                <rq-input-number
                                    id="txt_interest_rate"
                                    defaultValue="0"
                                    decimals="3"
                                    minValue="0"
                                    maxValue="100"
                                    :disabled="readOnly"
                                    v-model="data.transferTaxConfiguration.percentage"
                                />
                                <span class="input-group-text">%</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-12 col-md-6 col-lg-3 form-group">
                            <label for="pic_payee">Payee</label>
                            <company-picker
                                automation_id="pic_payee"
                                companyRoleName="Payee"
                                dialogTitle="Select Payee"
                                :disabled="readOnly"
                                v-model="data.transferTaxConfiguration.payeeCompany">
                            </company-picker>
                        </div>
                        <div class="col col-12 col-md-6 col-lg-3 form-group">
                            <label for="drp_accounting_code">Account Code</label>
                            <dx-select-box
                                :input-attr="{ automation_id: 'drp_accounting_code', id: 'drp_accounting_code' }"
                                class="form-control"
                                :items="accountingCodes"
                                value-expr="id"
                                display-expr="name"
                                :disabled="readOnly"
                                :search-enabled="true"
                                v-model="data.transferTaxConfiguration.accountCodeId"
                            />
                        </div>
                        <div class="col col-12 col-md-6 col-lg-3 form-group">
                            <label for="txt_friendlyName">Friendly Name</label>
                            <input
                                automation_id="txt_friendlyName"
                                type="text"
                                class="form-control"
                                id="txt_friendlyName"
                                placeholder="Friendly Name"
                                maxlength="250"
                                :disabled="readOnly"
                                v-model="data.transferTaxConfiguration.friendlyName"
                            >
                        </div>
                    </div>
                </rq-page-section>
            </template>

            <template #detail>
                <rq-page-section title="Settlement Statements" headerSize="md" id="stlstmt" borderless>
                    <div class="row">
                        <div class="col col-12 col-md-6 col-lg-3 form-group">
                            <label for="drp_hud_1974_line_type">2-Page HUD</label>
                            <dx-select-box
                                :input-attr="{ automation_id: 'drp_hud_1974_line_type', id: 'drp_hud_1974_line_type' }"
                                class="form-control"
                                :items="huD1974TaxTypes"
                                v-model="data.transferTaxConfiguration.huD1974LineType"
                                value-expr="id"
                                :disabled="readOnly"
                                display-expr="name"
                                :search-enabled="true"
                            />
                        </div>
                        <div class="col col-12 col-md-6 col-lg-3 form-group">
                            <label for="drp_hud_2010_line_type">3-Page HUD</label>
                            <dx-select-box
                                :input-attr="{ automation_id: 'drp_hud_2010_line_type', id: 'drp_hud_2010_line_type' }"
                                class="form-control"
                                :items="huD2010TaxTypes"
                                v-model="data.transferTaxConfiguration.huD2010LineType"
                                value-expr="id"
                                display-expr="name"
                                :disabled="readOnly"
                                :search-enabled="true"
                            />
                        </div>
                        <div class="col col-12 col-md-6 col-lg-3 form-group">
                            <label for="drp_cdf_line_type">CDF</label>
                            <dx-select-box
                                :input-attr="{ automation_id: 'drp_cdf_line_type', id: 'drp_cdf_line_type' }"
                                class="form-control"
                                :items="cdfTaxTypes"
                                v-model="data.transferTaxConfiguration.cdfLineType"
                                value-expr="id"
                                display-expr="name"
                                :disabled="readOnly"
                                :search-enabled="true"
                            />
                        </div>
                        <div class="col col-12 col-md-6 col-lg-3 form-group">
                            <label for="drp_settlement_statement_line_type">Settlement Statement</label>
                            <dx-select-box
                                :input-attr="{ automation_id: 'drp_settlement_statement_line_type', id: 'drp_settlement_statement_line_type' }"
                                class="form-control"
                                :items="cssTaxTypes"
                                v-model="data.transferTaxConfiguration.cssLineType"
                                value-expr="id"
                                display-expr="name"
                                :disabled="readOnly"
                                :search-enabled="true"
                            />
                        </div>
                    </div>
                </rq-page-section>
                <transferTaxConfigurationRatesForm
                    ref="ratesForm"
                    v-model:transferTaxConfiguration="data.transferTaxConfiguration"
                    :items="transferTaxRates"
                    v-model:validation-list="detailTabErrorList"
                />
            </template>
            <template #coverage-rounding>
                <transferTaxConfigurationCoverageRoundingForm
                    ref="coverageRoundingForm"
                    v-model:transferTaxConfiguration="data.transferTaxConfiguration"
                    :items="transferTaxCoverageRoundings"
                    v-model:validation-list="coverageTabErrorList"
                />
            </template>
            <template #calculation-rounding>
                <transferTaxConfigurationCalculationRoundingForm
                    ref="calculationRoundingForm"
                    v-model:transferTaxConfiguration="data.transferTaxConfiguration"
                    :items="transferTaxCalculationRoundings"
                    v-model:validation-list="calculationTabErrorList"
                />
            </template>
        </RqTabs>
    </div>
</template>

<script>
    import { ref } from "vue";
    import { mapState, mapGetters } from "vuex";
    import { required } from "validators";
    import { useVuelidate } from "@vuelidate/core";
    import { GlobalEventManager } from "@/app.events";
    import { TransferTaxConfigurationDataDto, TransferTaxRatesDto, TransferTaxCoverageRoundingDto, TransferTaxCalculationRoundingDto }  from "../models";
    import TransferTaxConfigurationRatesForm  from "./TransferTaxConfigurationRatesForm.vue";
    import TransferTaxConfigurationCoverageRoundingForm  from "./TransferTaxConfigurationCoverageRoundingForm.vue";
    import TransferTaxConfigurationCalculationRoundingForm  from "./TransferTaxConfigurationCalculationRoundingForm.vue";
    import { TransferTaxEffectiveDateBasedOn, WhoPays, InitialPageNumber, TransferTaxType, Hud1974TaxType, Hud2010TaxType, CDFTaxType, CSSTaxType } from '../../enums';
    import { useRqTabAlerts } from "@/shared/composables/useRqTabAlerts";

    export default {
        name: "TransferTaxConfigurationForm",
        props: {
            transferTaxConfigurationID: { type: Number, default: 0 },
            isModal: { type: Boolean, default: false }
        },
        components: {
            TransferTaxConfigurationRatesForm,
            TransferTaxConfigurationCoverageRoundingForm,
            TransferTaxConfigurationCalculationRoundingForm
        },
        setup() {
            const detailTabErrorList = ref([]);
            const coverageTabErrorList = ref([]);
            const calculationTabErrorList = ref([]);

            const v$ = useVuelidate();
            const alertMapping = {
                generalTabAlert: { type: "v$" },
                detailsTabAlert: { type: "list-ref", list: detailTabErrorList },
                coverageTabAlert: { type: "list-ref", list: coverageTabErrorList },
                calculationTabAlert: { type: "list-ref", list: calculationTabErrorList }
            };
            const tabAlerts = useRqTabAlerts({ v$, alertMapping });

            return {
                v$,
                detailTabErrorList,
                coverageTabErrorList,
                calculationTabErrorList,
                ...tabAlerts
            };
        },
        data() {
            return {
                data: {
                    transferTaxConfiguration:{}
                },
                originalData: {},
                transferTaxDescription: "",
                showBanner: false,
                isCancelled: false,
                tab: 0
            }
        },

        computed: {
            ...mapState({
                readOnly: state => state.isPageReadOnly,
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            hasTaxConfiguration() { return !_.isEmpty(_.get(this.data, "transferTaxConfiguration")); },
            title() {
                let desc = _.get(this, "data.transferTaxConfiguration.description", null);
                if(desc ===  null)
                    desc = "Transfer Tax"
                return (this.readOnly) ? `View: ${desc}`: `Edit: ${desc}`;
            },
            stateCounties() { return this.lookupHelpers.getCountiesByState(this.data.transferTaxConfiguration.stateAssociated); },
            accountingCodes() { return this.lookupHelpers.getLookupItems(this.lookupItems.ACCOUNTING_CODES, this.originalData.accountCodeId); },
            ratesGrid() {
                return _.get(this, "$refs.ratesForm.$refs.dataGrid.gridInstance", {});
            },
            hasValidationErrors() {
                return this.v$.$error
                || this.detailTabErrorList.length > 0
                || this.coverageTabErrorList.length > 0
                || this.calculationTabErrorList.length > 0
            },
            tabItems() {
                return [
                    { title: "General", name: "general", alertCount: this.generalTabAlert.alertCount },
                    { title: "Detail", name: "detail", alertCount: this.detailsTabAlert.alertCount },
                    { title: "Coverage Rounding", name: "coverage-rounding", alertCount: this.coverageTabAlert.alertCount },
                    { title: "Calculation Rounding", name: "calculation-rounding", alertCount: this.calculationTabAlert.alertCount },
                ];
            }
        },

        watch: {
            tab(newValue, oldValue) {
                if(newValue === oldValue) return;
                let self = this;
                self.save({ tabInitiated: true });
            }
        },

        validations: () => ({
            data: {
                transferTaxConfiguration: {
                    regionID: { required },
                    description: { required },
                    effectiveDate: { required },
                    effectiveBasedOn: { required },
                    stateAssociated: { required }
                }
            }
        }),

        created() {
            GlobalEventManager.onSave(this, this.onSave);
            GlobalEventManager.onCancel(this, this.onCancel);
            this.fetchData();
            this.initNonReactiveVariables();
        },

        beforeUnmount() {
            GlobalEventManager.unregister(this);
        },

        methods: {
            cancelChildComponentChanges(){
                const self = this;
                if(!_.isEmpty(self.ratesGrid)) self.ratesGrid.cancelEditData();
                self.invokeGridComponentMethod("coverageRoundingForm", "cancelEditData");
                self.invokeGridComponentMethod("calculationRoundingForm", "cancelEditData");
            },

            childComponentsHaveChanges(){
                const self = this;
                if(!_.isEmpty(self.ratesGrid) && self.ratesGrid.hasEditData()) return true;
                if(self.invokeGridMethod("coverageRoundingForm", "hasEditData")) return true;
                if(self.invokeGridMethod("calculationRoundingForm", "hasEditData")) return true;
                return false;
            },

            fetchData() {
                const self = this;
                if(self.transferTaxConfigurationID === 0){
                    self.item = new TransferTaxConfigurationDataDto();
                    self.originalData = new TransferTaxConfigurationDataDto();
                    return;
                }
                let apiPromise = self.$api.TransferTaxConfigurationApi.get(self.transferTaxConfigurationID);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.data = new TransferTaxConfigurationDataDto(result);
                        self.originalData = new TransferTaxConfigurationDataDto(result);
                        self.transferTaxRates = _.map(result.transferTaxRates, i => new TransferTaxRatesDto(i)) || [];
                        self.transferTaxCoverageRoundings = _.map(result.transferTaxCoverageRoundings, i => new TransferTaxCoverageRoundingDto(i)) || [];
                        self.transferTaxCalculationRoundings = _.map(result.transferTaxCalculationRoundings, i => new TransferTaxCalculationRoundingDto(i)) || [];
                        self.hUD1974LineTypes = _.map(_.filter(result.recordingDocumentLineTypes, (i) => {return i.seedType === 1;}), i => ({id: i.recordingDocumentID, name: i.description}));
                        self.hUD2010LineTypes = _.map(_.filter(result.recordingDocumentLineTypes, (i) => {return i.seedType === 2;}), i => ({id: i.recordingDocumentID, name: i.description}));
                        self.cdfLineTypes = _.map(_.filter(result.recordingDocumentLineTypes, (i) => {return i.seedType === 3;}), i => ({id: i.recordingDocumentID, name: i.description}));
                        self.settlementStatementLineTypes = _.map(_.filter(result.recordingDocumentLineTypes, (i) => {return i.seedType === 4;}), i => ({id: i.recordingDocumentID, name: i.description}));
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeName}.` });
                    });
            },

            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = _.get(self.$route.meta, "itemTypeName");
                self.itemTypeDescription = _.get(self.$route.meta, "itemTypeDescription");
                self.itemComponents = ["ratesForm", "coverageRoundingForm", "calculationRoundingForm"];
                self.regions = self.lookupHelpers.getRegions();
                self.usStates = self.lookupHelpers.getStates();
                self.effectiveDateBasedOnOptions = TransferTaxEffectiveDateBasedOn.lookupItems;
                self.transferTaxTypes = TransferTaxType.lookupItems;
                self.whoPaysOptions = WhoPays.lookupItems;
                self.initialPageNumbers = InitialPageNumber.lookupItems;
                self.huD1974TaxTypes = Hud1974TaxType.lookupItems;
                self.huD2010TaxTypes = Hud2010TaxType.lookupItems;
                self.cdfTaxTypes = CDFTaxType.lookupItems;
                self.cssTaxTypes = CSSTaxType.lookupItems;
            },

            onCancel(){
                const self = this;
                let changes = self.getAuditChanges(self.originalData, self.data);
                let ok = () => {
                    self.isCancelled = true;
                    self.cancelChildComponentChanges();
                    self.$router.push({ name: "cfg:sscalc:transfertaxes" });
                    return true;
                }
                if ((changes.length === 0 || self.readOnly) && !self.childComponentsHaveChanges()) {
                    ok();
                }
                else
                {
                    self.$dialog.confirm("Confirm Cancel", "Discard changes and return data?", ok);
                }
            },

            onSave(e) {
                const self = this;
                if(this.readOnly || this.isCancelled) {
                    GlobalEventManager.saveCompleted({ success: true });
                    return;
                }
                self.save({userInitiated: _.getBool(e, "userInitiated")});
            },

            onStateChange(e) {
                if (_.isNullOrEmpty(e.selectedValue) || _.isNil(e.event)) return;
                this.data.countyIDs = [];
            },

            onTransferTaxRatesUpdated(e) {
                this.transferTaxTypes = e;
            },
            invokeGridMethod(refName, methodName, ...params){
                return _.invoke(this, `$refs.${refName}.$refs.dataGrid.invokeGridMethod`, methodName, ...params);
            },
            invokeGridComponentMethod(refName, methodName, ...params){
                _.invoke(this, `$refs.${refName}.$refs.dataGrid.${methodName}`, ...params)
            },
            saveChildComponents(){
                const self = this;
                let promises = [];
                if(!_.isEmpty(self.ratesGrid) && self.ratesGrid.hasEditData()) promises.push(self.ratesGrid.saveEditData());
                promises.push(self.invokeGridComponentMethod("coverageRoundingForm", "saveRow"));
                promises.push(self.invokeGridComponentMethod("calculationRoundingForm", "saveRow"));
                return Promise.all(promises);
            },

            save(args={}) {
                const self = this;
                self.showBanner = true;
                self.v$.$touch();

                let userInitiated = _.get(args, "userInitiated", false);

                let saveResult = { success: true };

                return self.saveChildComponents()
                    .then(() => {
                        if (self.hasValidationErrors) return Promise.resolve(false);
                        self.showBanner = false;
                        let changes = self.getAuditChanges(self.originalData.toDataObject(), self.data.toDataObject());
                        if (changes.length > 0 && !self.readOnly) {
                            let apiPromise = self.$api.TransferTaxConfigurationApi.save(self.data.toDataObject(), changes);
                            return self.$rqBusy.wait(apiPromise)
                                .then(result => {
                                    self.$toast.success(`${result.transferTaxConfiguration.description} was saved.`);
                                    self.data = new TransferTaxConfigurationDataDto(result);
                                    self.originalData = new TransferTaxConfigurationDataDto(result);
                                    GlobalEventManager.saveCompleted(saveResult);
                                    return saveResult;
                                })
                                .catch(error => {
                                    self.$toast.error("Error saving transfer tax");
                                    saveResult.success = false;
                                    if(!self.isModal) GlobalEventManager.saveCompleted(saveResult);
                                    return error;
                                });
                        }
                        else {
                            let childChanges = self.childComponentsHaveChanges();
                            if(userInitiated && !childChanges) self.$toast.info("No changes detected");
                            GlobalEventManager.saveCompleted(saveResult);
                            return Promise.resolve(saveResult);
                        }
                    });
            }
        }
    }
</script>
