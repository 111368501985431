import { Categories } from "./categories";
import { Topics } from "../topics";
import ProrateDefaultsForm from "./components/ProrateDefaultsForm";
import DefaultCDFLinesList from "./components/DefaultCDFLinesList";
import DefaultHUDLinesList from "./components/DefaultHUDLinesList";
import EndorsementsList from "./components/EndorsementsList";
import EndorsementForm from "./components/EndorsementForm";
import PayoffDescriptionsList from "./components/PayoffDescriptionsList";
import PremiumsList from "./components/PremiumsList";
import PremiumForm from "./components/PremiumForm";
import RateEngineSettingsForm from "./components/RateEngineSettingsForm";
import SettlementStatementSettingsForm from "./components/SettlementStatementSettingsForm";
import TaxRateList from "./components/TaxRateList";
import WatermarkStatusList from "./components/WatermarkStatusList";
import ImportUpdateList from "./components/ImportUpdateList";
import ExportList from "./components/ExportList";
import RecordingFeeConfigurationList from "./components/RecordingFeeConfigurationList";
import RecordingFeeConfigurationForm from "./components/RecordingFeeConfigurationForm";
import TransferTaxConfigurationForm from "./components/TransferTaxConfigurationForm";
import TransferTaxConfigurationList from "./components/TransferTaxConfigurationList";

import LookupNames from "@/store/lookupNames";

let routes = [

    {
        name: "cfg:sscalc:rateEnginesettings",
        path: "rateEnginesettings",
        component: RateEngineSettingsForm,
        meta: {
                label: "Rate Engine Settings",
            itemKey: "",
            itemTypeName: "Rate Engine Setting",
            itemTypeDescription: "Define default account codes, calculation of recording fees and premium options.",
            topic: Topics.CalculatorsSettlementStatements,
            category: Categories.Calculators,
            scrollLayout: true,
            sysAdminRequired: true
        }
    },
    {
        name: "cfg:sscalc:premiums",
        path: "premiums",
        component: PremiumsList,
        meta: {
                label: "Premium Rates",
            itemKey: "",
            itemTypeName: "Premium",
            itemTypeDescription: "Define premium calculations to be used in the Premiums page.",
            skipSaveOnLeave: true,
            topic: Topics.CalculatorsSettlementStatements,
            category: Categories.Calculators,
            permissionKeys: ["AllowAddEditRates"]
        }
    },
    {
        name: "cfg:sscalc:premium",
        path: "premium/:rateEffectiveDateID",
        component: PremiumForm,
        meta: {
                label: "Premiums",
            itemKey: "",//set to empty to trick out Config Main from using the add button
            itemTypeName: "Premium",
            itemTypeDescription: "Show the Rate’s effective date and detail",
            topic: Topics.CalculatorsSettlementStatements,
            category: Categories.Calculators,
            skipSaveOnLeave: false,
            hideFromSearch: true,
            scrollLayout: false,
            permissionKeys: ["AllowAddEditRates"]
        }
    },
    {
        name: "cfg:sscalc:importUpdateRateCalc",
        path: "importUpdateRateCalc",
        component: ImportUpdateList,
        meta: {
                label: "Import/Update Premium Rates",
            itemKey: "", //no add button
            itemTypeName: "Import/Update Premium Rate Calculator",
            itemTypeDescription: "Allows the user to import new or updated premium rates and endorsements.",
            skipSaveOnLeave: true, //batch saves.
            topic: Topics.CalculatorsSettlementStatements,
            category: Categories.Calculators
        }
    },
    {
        name: "cfg:sscalc:rateExport",
        path: "rateExport",
        component: ExportList,
        meta: {
                label: "Premium Rate Exporter",
            itemKey: "",
            itemTypeName: "Premium Rate Exporter",
            itemTypeDescription: "Allows the user to export premium rates and endorsements.",
            skipSaveOnLeave: true,
            topic: Topics.CalculatorsSettlementStatements,
            category: Categories.Calculators
        }
    },
    {
        name: "cfg:sscalc:endorsementsandfees",
        path: "endorsementsandfees",
        component: EndorsementsList,
        meta: {
                label: "Endorsement/Settlement Fees",
            itemKey: "",//set to empty to trick out Config Main from using the add button
            itemTypeName: "Endorsement/Settlement Fee",
            itemTypeDescription: "Define endorsements and settlement fee calculations and associate documents.",
            skipSaveOnLeave: true,
            topic: Topics.CalculatorsSettlementStatements,
            category: Categories.Calculators,
            scrollLayout: true,
            permissionKeys: ["AllowEditEndorsementSettlementFees"]
        }
    },
    {
        name: "cfg:sscalc:endorsementsandfee",
        path: "endorsementsandfee/:endorsementID",
        component: EndorsementForm,
        meta: {
                label: "Endorsement/Settlement Fees",
            itemKey: "",//set to empty to trick out Config Main from using the add button
            itemTypeName: "Endorsement/Settlement Fee",
            itemTypeDescription: "Enter Endorsement data, select charge calculation and associate documents",
            topic: Topics.CalculatorsSettlementStatements,
            category: Categories.Calculators,
            hideFromSearch: true,
            scrollLayout: false,
            permissionKeys: ["AllowEditEndorsementSettlementFees"]
        }
    },
    {
        name: "cfg:sscalc:taxRates",
        path: "taxRates",
        component: TaxRateList,
        meta: {
                label: "Tax Rates",
            itemKey: "taxRateID",
            itemTypeName: "Tax Rate",
            itemTypeDescription: "Configure tax rate and effective dates to be used in the Premiums page.",
            skipSaveOnLeave: true,
            topic: Topics.CalculatorsSettlementStatements,
            category: Categories.Calculators,
            sysAdminRequired: true

        }
    },
    {
        name: "cfg:sscalc:recordingfees",
        path: "recordingfees",
        component: RecordingFeeConfigurationList,
        meta: {
                label: "Recording Fees",
            itemKey: "",
            itemTypeName: "Recording Fee",
            itemTypeDescription: "Configure recording fees to be used on the Settlement Statements.",
            skipSaveOnLeave: true,
            topic: Topics.CalculatorsSettlementStatements,
            category: Categories.Calculators,
            permissionKeys: ["AllowAddEditRecordingFees"]
        }
    },
    {
        name: "cfg:sscalc:recordingfee",
        path: "recordingfee/:recordingFeeConfigurationID",
        component: RecordingFeeConfigurationForm,
        props: route => ({
            recordingFeeConfigurationID: _.getNumber(route, "params.recordingFeeConfigurationID", 0)
        }),
        meta: {
                label: "Recording Fees",
            itemKey: "",//set to empty to trick out Config Main from using the add button
            itemTypeName: "Recording Fee",
            itemTypeDescription: "Manage Recording Feess",
            topic: Topics.CalculatorsSettlementStatements,
            category: Categories.Calculators,
            hideFromSearch: true,
            scrollLayout: true,
            permissionKeys: ["AllowAddEditRecordingFees"]
        }
    },
    {
        name: "cfg:sscalc:transfertaxes",
        path: "transfertaxes",
        component: TransferTaxConfigurationList,
        meta: {
                label: "Transfer Taxes",
            itemKey: "",
            itemTypeName: "Transfer Tax",
            itemTypeDescription: "Configure transfer taxes to be used on the Settlement Statements.",
            skipSaveOnLeave: true,
            topic: Topics.CalculatorsSettlementStatements,
            category: Categories.Calculators,
            scrollLayout: false,
            permissionKeys: ["AllowAddEditTransferTaxes"]
        }
    },
    {
        name: "cfg:sscalc:transfertax",
        path: "transfertax/:transferTaxConfigurationID",
        component: TransferTaxConfigurationForm,
        props: route => ({
            transferTaxConfigurationID: _.getNumber(route, "params.transferTaxConfigurationID", 0)
        }),
        meta: {
                label: "Transfer Taxes",
            itemKey: "",//set to empty to trick out Config Main from using the add button
            itemTypeName: "Transfer Tax",
            itemTypeDescription: "Manage Transfer Taxes",
            topic: Topics.CalculatorsSettlementStatements,
            category: Categories.Calculators,
            hideFromSearch: true,
            scrollLayout: true,
            permissionKeys: ["AllowAddEditTransferTaxes"]
        }
    },
    {
        name: "cfg:sscalc:sssettings",
        path: "sssettings",
        component: SettlementStatementSettingsForm,
        meta: {
                label: "Settlement Statement Settings",
            itemKey: "",
            itemTypeName: "Settlement Statement Setting",
            itemTypeDescription: "Define Settlement Statement options and header labels for the Commercial Settlement Statement.",
            skipSaveOnLeave: false,
            topic: Topics.CalculatorsSettlementStatements,
            category: Categories.SettlementStatement,
            scrollLayout: true,
            sysAdminRequired: true
        }
    },
    {
        name: "cfg:sscalc:prorations",
        path: "prorations",
        component: ProrateDefaultsForm,
        meta: {
                label: "Prorations",
            itemKey: "",//set to empty to trick out Config Main from using the add button
            itemTypeName: "Proration",
            itemTypeDescription: "Configure default proration methods to be used on the Settlement Statements.",
            topic: Topics.CalculatorsSettlementStatements,
            category: Categories.SettlementStatement,
            refreshLookup: LookupNames.PRORATION_DEFAULTS,
            scrollLayout: true
        }
    },
    {
        name: "cfg:sscalc:watermarkstatus",
        path: "watermarkstatus",
        component: WatermarkStatusList,
        meta: {
                label: "Watermark Statuses",
            itemKey: "hudStatusID",
            itemTypeName: "Watermark Status",
            itemTypeDescription: "Define statuses to be used on the HUD/CDF/ALTA Settlement Statements.",
            skipSaveOnLeave: true,
            topic: Topics.CalculatorsSettlementStatements,
            category: Categories.SettlementStatement,
            sysAdminRequired: true
        }
    },
    {
        name: "cfg:sscalc:payoffdesc",
        path: "payoffdesc",
        component: PayoffDescriptionsList,
        meta: {
                label: "Payoff Descriptions",
            itemKey: "payoffDescriptionID",
            itemTypeName: "Payoff Description",
            itemTypeDescription: "Configure additional items to be used on the Payoffs page.",
            skipSaveOnLeave: true,
            topic: Topics.CalculatorsSettlementStatements,
            category: Categories.SettlementStatement,
            refreshLookup: LookupNames.PAYOFF_DESCRIPTIONS,
            sysAdminRequired: true
        }
    },
    {
        name: "cfg:sscalc:defaultcdflines",
        path: "defaultcdflines",
        component: DefaultCDFLinesList,
        meta: {
                label: "Default CDF Lines",
            itemKey: "id",
            itemTypeName: "Default CDF Line",
            itemTypeDescription: "Define the standard lines that display on the CDF.",
            skipSaveOnLeave: true,
            topic: Topics.CalculatorsSettlementStatements,
            category: Categories.SettlementStatement,
            sysAdminRequired: true
        }
    },
    {
        name: "cfg:sscalc:defaulthudlines",
        path: "defaulthudlines",
        component: DefaultHUDLinesList,
        meta: {
                label: "Default HUD Lines",
            itemKey: "",
            itemTypeName: "Default HUD Line",
            itemTypeDescription: "Define the standard lines that display on the 2-Page and 3-Page HUD.",
            skipSaveOnLeave: true,
            topic: Topics.CalculatorsSettlementStatements,
            category: Categories.SettlementStatement,
            sysAdminRequired: true
        }
    },
];

export default routes;

