<template>
    <div class="rq-container">
        <rq-banner
            variant="error"
            message="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && v$.$error"
        />
        <!--Premium notes -->
        <div class="row" v-if="isRateImport">
            <div class="col col-12">
                <label for="endorsementInfo">Imported premium rates require a manual update to the following:</label>
            </div>
            <div class="col col-6">
                <ul>
                    <li>Owner Account Code</li>
                    <li>Loan Account Code</li>
                </ul>
            </div>
            <div class="col col-6">
                <ul>
                    <li>Tax Contact Role</li>
                    <li>Tax Type</li>
                </ul>
            </div>
        </div>
        <!--Endorsement notes -->
        <div class="row" v-if="isEndorsementImport">
            <div class="col col-12">
                <label for="endorsementInfo">Imported endorsements require a manual update to the following:</label>
            </div>
            <div class="col col-6">
                <ul>
                    <li>Tax Contact Role</li>
                    <li>Tax Type</li>
                </ul>
            </div>
            <div class="col col-6">
                <ul>
                    <li>Any linked premium fields</li>
                    <li>Documents</li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col col-12 form-group form-required" :class="{ 'has-error': v$.selectedRegions.$error }">
                <label>Region</label>
                <dx-tag-box
                    :input-attr="$utils.idAttrs('tb_regions')"
                    class="form-control"
                    :items="regions"
                    display-expr="displayName"
                    value-expr="regionID"
                    :show-selection-controls="true"
                    :show-clear-button="true"
                    :max-displayed-tags="3"
                    :show-multi-tag-only="false"
                    :show-drop-down-button="true"
                    apply-value-mode="useButtons"
                    v-model:value="v$.selectedRegions.$model"
                    :search-enabled="true"
                />
                <rq-validation-feedback
                    :container="`#${dialogId}`"
                    :boundary="`#${dialogId}`">
                    One or more Regions are required.
                </rq-validation-feedback>
            </div>
            <div class="col col-12 form-group form-required" :class="{ 'has-error': v$.selectedUnderwriters.$error }">
                <label for="underwriter">Underwriter</label>
                <dx-tag-box
                    :input-attr="$utils.idAttrs('drp_underwriter')"
                    class="form-control"
                    :items="underwriters"
                    display-expr="name"
                    value-expr="id"
                    :show-selection-controls="true"
                    :show-clear-button="true"
                    :max-displayed-tags="3"
                    :show-multi-tag-only="false"
                    :show-drop-down-button="true"
                    apply-value-mode="useButtons"
                    v-model:value="v$.selectedUnderwriters.$model"
                    :search-enabled="true"
                />
                <rq-validation-feedback
                    :container="`#${dialogId}`"
                    :boundary="`#${dialogId}`">
                    One or more Underwriters are required.
                </rq-validation-feedback>
            </div>
            <div class="col col-12 form-group">
                <label for="drp_counties">County</label>
                <dx-tag-box
                    :input-attr="$utils.idAttrs('drp_counties')"
                    class="form-control"
                    :items="counties"
                    display-expr="name"
                    value-expr="id"
                    :show-selection-controls="true"
                    :show-clear-button="true"
                    :max-displayed-tags="3"
                    :show-multi-tag-only="false"
                    :show-drop-down-button="true"
                    apply-value-mode="useButtons"
                    :defer-rendering="false"
                    v-model:value="selectedCounties"
                    :search-enabled="true"
                />
            </div>
            <div class="col col-12 form-group form-required" :class="{ 'has-error': v$.selectedOrderCategories.$error }">
                <label for="orderCategories">File Category</label>
                <dx-tag-box
                    :input-attr="$utils.idAttrs('drp_orderCategories')"
                    class="form-control"
                    :items="orderCategories"
                    display-expr="name"
                    value-expr="id"
                    :show-selection-controls="true"
                    :show-clear-button="true"
                    :max-displayed-tags="3"
                    :show-multi-tag-only="false"
                    :show-drop-down-button="true"
                    apply-value-mode="useButtons"
                    v-model:value="v$.selectedOrderCategories.$model"
                    :search-enabled="true"
                />
                <rq-validation-feedback
                    :container="`#${dialogId}`"
                    :boundary="`#${dialogId}`">
                    One or more File Categories are required.
                </rq-validation-feedback>
            </div>
            <div class="col col-12 form-group form-required" :class="{ 'has-error': v$.selectedOrderServices.$error }">
                <label for="orderServices">Services</label>
                <dx-tag-box
                    :input-attr="$utils.idAttrs('drp_orderServices')"
                    class="form-control"
                    :items="orderServices"
                    display-expr="name"
                    value-expr="id"
                    :show-selection-controls="true"
                    :show-clear-button="true"
                    :max-displayed-tags="3"
                    :show-multi-tag-only="false"
                    :show-drop-down-button="true"
                    apply-value-mode="useButtons"
                    item-template="item-display"
                    tag-template="tag-display"
                    v-model:value="v$.selectedOrderServices.$model"
                    :search-enabled="true">
                    <template #item-display="{ data }">{{getServiceItemDisplay(data)}}</template>
                    <template #tag-display="{ data }">
                        <div class="dx-tag-content">
                            {{getServiceItemDisplay(data)}}
                            <div class="dx-tag-remove-button"></div>
                        </div>
                    </template>
                </dx-tag-box>
                <rq-validation-feedback
                    :container="`#${dialogId}`"
                    :boundary="`#${dialogId}`">
                    One or more Services are required.
                </rq-validation-feedback>
            </div>
            <div v-if="isEndorsementImport" class="col col-12 form-group">
                <label for="accountingCode">Account Code</label>
                <dx-select-box
                    :input-attr="$utils.idAttrs('drp_accountingCode')"
                    value-expr="id"
                    display-expr="name"
                    :items="accountingCodes"
                    :show-clear-button="true"
                    v-model="selectedAccountingCode"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { required } from "validators";
    import { useVuelidate } from "@vuelidate/core";

    const IMPORT_TYPE = { Rate: 1, Endorsement: 2 };
    const CHUNK_SIZE = 2000;

    export default {
        name: "ImportUpdateForm",
        props: {
            importType: { type: Number, default: null },
            selectedItems: { type: Array, default: () => [] },
            usState: { type: String, default: null }
        },
        inject: ["dialogId"],
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                showBanner: false,
                selectedRegions: [],
                selectedAccountingCode: 0,
                selectedCounties: [],
                selectedUnderwriters: [],
                selectedOrderCategories: [],
                selectedOrderServices: [],
                linkedIds: [],
                linkedFromIds: [],
                chunks: [],
                chunkIndex: 0,
                importedCount: 0,
            }
        },
        computed: {
            ...mapState({
                globalRegionId: state => state.system.globalRegionId,
                user: state => state.authentication.session.user,
                regions: state => state.system.lookups.regions,
                userRegions: state => state.authentication.session.regions
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            globalRegionId() { return _.getNumber(this, "$store.state.system.globalRegionId", 0); },
            isRateImport() { return this.importType === IMPORT_TYPE.Rate; },
            isEndorsementImport() { return this.importType === IMPORT_TYPE.Endorsement; },
            regions() { return this.lookupHelpers.getRegions(); },
            counties() { return this.lookupHelpers.getCountiesByState(this.usState); },
            accountingCodes() { return this.lookupHelpers.getLookupItems(this.lookupItems.ACCOUNTING_CODES,  this.globalRegionId); },
            underwriters() { return this.mapUnderwriters(); },
            orderCategories() { return this.mapOrdercategories(); },
            orderServices() { return this.lookupHelpers.getLookupItems(this.lookupItems.ORDER_SERVICES); },
            totalCount() { return _.getNumber(this, "selectedItems.length", null) || 0; },
            itemTypeLabel() { return this.isRateImport ? "Rates" : "Endorsements"; },
            displayProgress() { return this.totalCount > CHUNK_SIZE; },
            progressMessage() { return this.displayProgress ? `Imported <strong>${this.importedCount} of ${this.totalCount}</strong> ${this.itemTypeLabel}` : null; }
        },

        validations: () => ({
            selectedRegions: { required },
            selectedUnderwriters: { required },
            selectedOrderCategories: { required },
            selectedOrderServices: { required }
        }),

        methods: {
            getServiceItemDisplay(item) {
                if(_.isString(item)) return item;
                return `${item.name} ${_.parseBool(item.data) ? "(ALTA)" : "(TX)"}`;
            },

            mapOrdercategories() {
                let orderCategory = this.lookupHelpers.getLookupItems(this.lookupItems.ORDER_CATEGORIES);

                // RQO-18928: filter category based on user region access
                if (this.user.regionID !== 1) {
                    let orderCategoryFiltered = []
                    let userRegionIds = _.map(this.userRegions, "regionID");
                    _.forEach(orderCategory, o => {
                           if (userRegionIds.includes(o.additionalIdentity)) orderCategoryFiltered.push(o);
                    })
                    orderCategory = orderCategoryFiltered;
                }
               
                orderCategory = _.sortBy(orderCategory, ['name']);
                
                return orderCategory;
            },

            mapUnderwriters() {
                let underwriters = this.lookupHelpers.getUnderwriters(this.globalRegionId);

                // RQO-18928: filter underwriters based on user region access
                if (this.user.regionID !== 1) {
                    let underwritersFiltered = []
                    let userRegionIds = _.map(this.userRegions, "regionID");
                    _.forEach(underwriters, u => {
                           if (userRegionIds.includes(u.additionalIdentity)) underwritersFiltered.push(u);
                    })
                    underwriters = underwritersFiltered;
                }
                return underwriters;
            },

            getRequestData(items=null) {
                const self = this;
                let listAttrName = self.isRateImport ? "rateDetails" : "endorsementDetails";
                let requestItems = _.isNil(items) ? self.selectedItems : items;
                return {
                    [listAttrName]: requestItems,
                    selectedRegions: self.selectedRegions,
                    selectedUnderwriters: self.selectedUnderwriters,
                    selectedOrderCategories: self.selectedOrderCategories,
                    selectedOrderServices: self.selectedOrderServices,
                    selectedAccountingCode: _.parseNumber(self.selectedAccountingCode, 0),
                    selectedCounties: self.selectedCounties,
                    importType: self.importType
                };
            },

            parseLinkedRates() {
                const self = this;
                _.forEach(self.selectedItems, item => {
                    let links = [item.importedAssociatedRateId, item.importedLinkedId];
                    _.forEach(item.rateEffectiveDate, re => {
                        if(_.isEmpty(re.rateEngineSimultaneous)) return;
                        _.forEach(re.rateEngineSimultaneous, sim => {
                            let policies = _.values(_.pickBy(sim, (v,k) => _.endsWith(k, "Policy") || _.isEqual(k,"cdfRateID")));
                            links.push(...policies);
                        });
                    });
                    let filteredLinks = _.filter(links); //filters out null/empty values
                    if(_.isEmpty(filteredLinks)) return;
                    self.linkedFromIds.push(item.importedID);
                    self.linkedIds.push(...filteredLinks);
                });
            },

            buildChunks() {
                const self = this;
                if(!self.isRateImport) {
                    self.chunks = _.chunk(self.selectedItems, CHUNK_SIZE);
                    return;
                }
                self.parseLinkedRates();
                let linkedIds = _.uniq(self.linkedIds);
                let linkedToFromIds = _.uniq(_.concat(linkedIds, self.linkedFromIds));
                let items = _.sortBy(self.selectedItems, item => _.includes(linkedIds, item.importedID) ? 0 : _.includes(self.linkedFromIds, item.importedID) ? 1 : 2);
                let chunkSize = linkedToFromIds.length > CHUNK_SIZE ? linkedToFromIds.length : CHUNK_SIZE;
                self.chunks = _.chunk(items, chunkSize);
            },

            save() {
                const self = this;
                self.showBanner = true;
                self.v$.$touch();

                if(self.v$.$error) return Promise.resolve(false);

                if(_.isEmpty(self.selectedItems)) return Promise.resolve(true);

                self.buildChunks();
                let savePromise = self.saveNextChunk();
                return self.$rqBusy.wait(savePromise, { message: self.progressMessage })
                    .then(() => {
                        return true;
                    })
                    .catch((err) => {
                        let message = err.errorMessage ? err.errorMessage : "A problem occurred during import.";
                        self.$toast.error({ message, autoHideEnabled: false });
                        console.error(err);
                        return false;
                    });
            },

            saveNextChunk() {
                const self = this;
                if(self.displayProgress) self.$rqBusy.setMessage(self.progressMessage);
                if(self.chunkIndex >= self.chunks.length) {
                    return Promise.resolve(true);
                }
                let items = self.chunks[self.chunkIndex];
                let requestData = self.getRequestData(items);
                return self.$api.ImportUpdateApi.save(requestData)
                    .then(() => {
                        self.chunkIndex++;
                        self.importedCount += items.length;
                        return self.saveNextChunk();
                    });
            },
        }
    }
</script>