<template>
    <div class="content-wrapper">
        <rq-banner
            variant="error"
            title="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && (v$.$error || v$.$invalid)"
            sticky
        />
        <div class="rq-container mt-3">
            <div class="row">
                <div class="col col-12 col-lg-5 form-group form-required" :class="{ 'has-error' : v$.item.departmentName.$error || v$.item.uniqueName.$invalid }">
                    <label for="dept">Department Name</label>
                    <input
                        id="dept"
                        automation_id="txt_departmentName"
                        type="text"
                        class="form-control"
                        placeholder="Department Name"
                        maxlength="50"
                        v-model="v$.item.departmentName.$model"
                        :disabled="!isSysAdmin">
                    <rq-validation-feedback
                        :container="tooltipContainer"
                        :boundary="tooltipContainer"
                        :messages="{
                            'Department Name is required.': v$.item.departmentName.$error,
                            'A department already exists with that name.': v$.item.uniqueName.$invalid
                        }"
                    />
                </div>
                <div class="col col-12 col-lg-5 form-group form-required" :class="{'has-error' : v$.item.description.$error || v$.item.uniqueDescription.$invalid }">
                    <label for="desc">Description</label>
                    <input
                        id="desc"
                        automation_id="txt_description"
                        type="text"
                        class="form-control"
                        placeholder="Description"
                        maxlength="50"
                        v-model="v$.item.description.$model"
                        :disabled="!isSysAdmin">
                    <rq-validation-feedback
                        :container="tooltipContainer"
                        :boundary="tooltipContainer"
                        :messages="{
                            'Description is required.': v$.item.description.$error,
                            'A department already exists with that description.': v$.item.uniqueDescription.$invalid
                        }"
                    />
                </div>
                <div class="col col-12 col-lg-2 form-group">
                    <label></label>
                    <b-form-checkbox
                        automation_id="chk_markInactive"
                        v-model="v$.item.inactiveYN.$model"
                        :disabled="!isSysAdmin">Inactive</b-form-checkbox>
                </div>
            </div>
        </div>
        <rq-page-section title="Members" header-size="lg" borderless header-only/>
        <rq-selection-list-box
            ref="selectionList"
            left-label="Available Users"
            right-label="Selected Users"
            v-model:items="memberListItems"
            @change="onMemberListChange"
        >
            <template #left-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_region' }"
                            :items="regions"
                            value-expr="regionID"
                            display-expr="displayName"
                            :search-enabled="true"
                            class="form-control form-control-sm"
                            placeholder="Filter by Region..."
                            v-model="regionId"
                        />
                    </li>
                    <li class="nav-item ms-2">
                        <rq-search-input-group
                            id="txt_config_search_user"
                            placeholder="Search..."
                            tabindex="0"
                            size="sm"
                            input-css-class="rq-placeholder-visible"
                            v-model="memberSearchText"
                            @search="onMemberSearch"
                            search-on-enter
                            show-search-button
                        />
                    </li>
                </ul>
            </template>
        </rq-selection-list-box>
    </div>

</template>
<script>
    import { mapState, mapGetters } from "vuex";
    import { required } from "validators";
    import { useVuelidate } from "@vuelidate/core";
    import { WorkflowDepartmentDto, WorkflowDepartmentUserDto }  from "../models";
    import RqSelectionListBox from "@/shared/components/rq/list-box/RqSelectionListBox";
    import { ListBoxItemModel, UserSecuritySettings } from "@/shared/models/models";

    export default {
        name: "WorkflowDepartmentsForm",

        components: { RqSelectionListBox, },

        props: {
            item: { type: Object, default: () => ({}) },
            uniqueValidator: { type: Function, default: (() => true) },
            users: { type: Array, default: () => [] }
        },

        inject: ["dialogId"],

        setup: () => ({ v$: useVuelidate() }),

        data() {
            const self = this;
            return {
                regionId: 0,
                memberListItems: [],
                showBanner: false,
                memberSearchText: ""
            }
        },

        computed: {
            ...mapState({ 
                user: state => state.authentication.session.user,
                authSettings: state => state.authentication.session.settings,
            }),
            ...mapGetters(["lookupHelpers"]),
            regions() { return this.lookupHelpers.getRegions(); },
            selectedMembers() { return _.sortBy(_.filter(this.memberListItems, { parentIndex: 1 }), "sequence"); },
            isSysAdmin(){
                const self = this;
                let settings = new UserSecuritySettings(self.authSettings);
                let value = settings.findValue("IsSysAdmin");
                return value;
            },
            tooltipContainer() { return `#${this.dialogId}`; },
        },

        watch: {
            regionId(newValue, oldValue) {
                if(newValue === oldValue && !_.isEmpty(this.memberListItems)) return;
                if(_.isNil(newValue)) {
                    this.regionId = _.parseNumber(this.user.regionID, 1);
                    return;
                }
                this.parseData();
            },
            memberSearchText(newValue, oldValue) {
                if(newValue === oldValue || (newValue.length > 0 && newValue.length < 3)) return;
                this.onMemberSearch();
            }
        },

        validations: () => ({
            item: {
                departmentName: { required },
                description: { required },
                inactiveYN: {},
                uniqueName: (val, vm) => vm.uniqueValidator(val, "departmentName"),
                uniqueDescription: (val, vm) => vm.uniqueValidator(val, "description")
            }
        }),

        created(){
            this.regionId = _.parseNumber(this.user.regionID, 1);
        },

        methods: {
            searchMembers: _.debounce(function(members=[]) {
                const self = this;
                if(self.regionId === 0) self.regionId = self.user.regionID;
                let memberItems = !_.isEmpty(members) || _.isEmpty(self.selectedMembers) ? self.mapItems(members, 1) : self.selectedMembers.slice();
                let regionUsers = _.filter(self.users, u => u.regionID === self.regionId && !u.isInactive && !_.some(memberItems, m => m.itemID === u.usersID));
                let searchedUsers = _.isEmpty(self.memberSearchText) ? regionUsers : _.search(regionUsers, self.memberSearchText, ["login", "fullName", "displayName"]);
                let userItems = self.mapItems(searchedUsers, 0);
                self.memberListItems = _.concat(userItems, memberItems);
            }, 300, { leading: true, trailing: true }),

            parseData() {
                const self = this;
                let memberItems = _.isEmpty(self.selectedMembers) ? self.mapItems(self.item.members, 1) : self.selectedMembers.slice();
                let regionUsers = _.filter(self.users, u => u.regionID === self.regionId && !u.isInactive && !_.some(memberItems, m => m.itemID === u.usersID));
                let userItems = self.mapItems(regionUsers, 0);
                self.memberListItems = _.concat(userItems, memberItems);
            },

            mapItems(items, parentIndex) {
                const self = this;
                return _.map(items, item => new ListBoxItemModel({
                    itemID: item.usersID,
                    itemName: _.trim((item.fullName && item.fullName.length > 2) ? item.fullName : item.displayName),
                    isMarked: _.parseBool(item.hasSupervisorPrivilegeYN),
                    parentIndex,
                    data: new WorkflowDepartmentUserDto({ ...item, workflowDepartmentID: self.item.workflowDepartmentID })
                }));
            },

            save(){
                const self = this;
                self.showBanner = true;
                if(self.item.isNew || self.v$.$error) self.v$.$touch();
                if(self.v$.$error || self.v$.$invalid) {
                    return Promise.resolve({ success: false });
                }
                if(!self.v$.$anyDirty) return Promise.resolve({ data:self.item, success: true });

                self.item.members = _.map(self.selectedMembers, m => m.data);

                let apiPromise = self.$api.WorkflowDepartmentsApi.saveWorkflowDepartment(self.item.toDataObject());
                return self.$rqBusy.wait(apiPromise)
                    .then(data => {
                        self.showBanner = false;
                        self.$toast.success("Workflow department saved successfully.");
                        return { data, success: true };
                    }).catch(err => {
                        console.error(err);
                        self.$toast.error("An error occurred while saving this workflow department.");
                    });
            },

            onMemberListChange(e) {
                this.v$.$touch();
            },
            onMemberSearch(e) {
                this.searchMembers();
            },
        }
    }
</script>