import CheckWritingMain from "./CheckWritingMain.vue";
import ConfigNav from "../shared/ConfigNav.vue";
import Routes from "./routes";
import { Categories } from "./categories";

export default {    
    path: "/configuration/checkWriting", 
    component: CheckWritingMain, 
    meta: {
        label: "Check Writing & Escrow",      
        skipSaveOnLeave: true
    },    
    children: [{
            name: "cfg:chk:root",
            path: "",
            component: ConfigNav,
            props: {routes: Routes, category : Categories.CheckWriting},
            meta: {
                label: Categories.CheckWriting,
                skipSaveOnLeave: true
            }
        },
        ...Routes
    ]
};