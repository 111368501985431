<template>
    <div class="content-wrapper">
        <rq-page-section
            :title="itemTypeNamePlural"
            :description="itemTypeDescription"
            headerSize="lg"
            borderless>
            <template #header-actions>
                <ul class="nav config-actions ms-auto">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_cancel"
                            variant="action"
                            @click="onCancel"
                            :disabled="readOnly">Cancel</b-btn>
                    </li>
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_save"
                            variant="action"
                            @click="onSave"
                            :disabled="readOnly">Save</b-btn>
                    </li>
                </ul>
            </template>
            <div class="row mb-1">
                <div class="col-auto ms-auto">
                    <rq-section-expand-collapse-all />
                </div>
            </div>
            <rq-section-card title="Options" collapsible>
                <div class="row">
                    <div class="col col-3 col-sm-6 col-md-3 col-lg-3 form-group label-lg">
                        <b-form-checkbox
                            automation_id="chk_showTasksforOrdersWithTheStatusOfClosed"
                            id="showTasksforOrdersWithTheStatusOfClosed"
                            v-model="systemDefaults.showTasksForClosedOrdersInDashboard"
                            :disabled="readOnly">Show Tasks for Files with the Status of Closed</b-form-checkbox>
                    </div>
                    <div class="col col-3 col-sm-6 col-md-3 col-lg-3"></div>
                    <div class="col col-3 col-sm-6 col-md-3 col-lg-3 form-group label-lg">
                        <b-form-checkbox
                            automation_id="chk_showTasksWithNoDueDate"
                            id="showTasksWithNoDueDate"
                            v-model="systemDefaults.showTasksWithNoDueDateInDashboard"
                            :disabled="readOnly">Show Tasks with No Due Date</b-form-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-3 col-sm-6 col-md-3 col-lg-3 form-group">
                        <label for="fileOpenDate">Show Files and Tasks with File Open Date</label>
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_fileOpenDate' }"
                            :items="fileOpenDateOptions"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                            v-model="fileOpenDateValue"
                            :disabled="readOnly"
                        />
                    </div>
                    <div class="col col-12 col-sm-12 col-md-6 col-lg-6 form-group" v-if="fileOpenDateValue == 4">
                        <label for="customDate">Custom Date</label>
                        <rqdx-date-box
                            id="dtp_customDate"
                            v-model="systemDefaults.customDate"
                            :disabled="readOnly"
                        />
                    </div>
                </div>
            </rq-section-card>
            <rq-section-card title="Departments Visible on the Workflow Dashboard" collapsible>
                <div class="row">
                    <div class="col col-12 col-sm-12 col-md-6 col-lg-8 form-group">
                        <rq-selection-list-box
                            left-label="Exclude these departments"
                            right-label="Include these departments"
                            v-model:items="workflowDepartmentItems"
                            :disabled="readOnly"
                        />
                    </div>
                </div>
            </rq-section-card>
        </rq-page-section>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { FileOpenDateOptions } from "../../enums";
    import { WorkflowDepartmentDto }  from "../models";
    import RqSelectionListBox from "@/shared/components/rq/list-box/RqSelectionListBox";
    import SystemDefaultsFormMixin from "../../shared/SystemDefaultsFormMixin";
    import { ListBoxItemModel } from "@/shared/models/models";

    export default {
        name: "WorkflowDashboardSettingsForm",
        mixins: [SystemDefaultsFormMixin],
        components: {
            RqSelectionListBox
        },
        props: { },
        data() {
            return {
                originalWorkflowDepartments: [],
                workflowDepartments: [],
                workflowDepartmentItems: []
            }
        },

        computed: {
            ...mapState({
                readOnly: state => state.isPageReadOnly,
            }),
            fileOpenDateOptions(){ return FileOpenDateOptions.lookupItems; },
            fileOpenDateValue: {
                get() { return _.isNil(this.systemDefaults.fileOpenDate) ? 1 : this.systemDefaults.fileOpenDate; },
                set(val) { this.systemDefaults.fileOpenDate = _.parseNumber(val, 1); }
            },
            originalDeptIds() { return _.map(_.filter(this.originalWorkflowDepartments, "excludeFromDashboard"), "workflowDepartmentID"); },
            excludedDeptIds() { return _.map(_.filter(this.workflowDepartmentItems, { parentIndex: 0 }), "itemID"); }
        },

        methods :{
            fetchAdditionalData() {
                const self = this;
                let apiPromise = self.$api.WorkflowDepartmentsApi.getWorkflowDepartments();
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.initListData(result);
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error("Error loading workflow departments.");
                    });
            },

            initListData(depts) {
                const self = this;
                self.originalWorkflowDepartments = _.map(depts, i => new WorkflowDepartmentDto(i));
                self.workflowDepartments = _.map(depts, i => new WorkflowDepartmentDto(i));
                self.workflowDepartmentItems = _.map(depts, dept => new ListBoxItemModel({
                    itemID: dept.workflowDepartmentID,
                    itemName: dept.departmentName,
                    data: dept
                }));
                _.updateAll(self.workflowDepartmentItems, "parentIndex", item => item.data.excludeFromDashboard ? 0 : 1);
            },

            saveAdditional(e) {
                const self = this;
                if(!self.hasAdditionalChanges()) return Promise.resolve(true);
                let apiPromise = self.$api.WorkflowDepartmentsApi.updateDashboardExclusions(self.excludedDeptIds);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.commitAdditionalData();
                        return true;
                    });
            },

            hasAdditionalChanges() {
                return !this.compareLists(this.originalDeptIds, this.excludedDeptIds);
            },

            commitAdditionalData() {
                const self = this;
                _.updateAll(self.workflowDepartments, "excludeFromDashboard", dept => _.includes(self.excludedDeptIds, dept.workflowDepartmentID));
                self.initListData(self.workflowDepartments);
            },

            restoreAdditionalData() {
                this.initListData(this.originalWorkflowDepartments);
            },

            compareLists(listA, listB) {
                return listA.length === listB.length && _.isEmpty(_.differenceWith(listA, listB, _.isEqual));
            }
        }
    }
</script>