<template>
    <div class="content-wrapper">
        <rq-banner
            variant="error"
            title="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && v$.$error"
        />
        <rq-page-section borderless>
            <div class="row">
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="drp_region">Region</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_region', id: 'drp_region' }"
                        value-expr="regionID"
                        display-expr="displayName"
                        :items="regions"
                        :search-enabled="true"
                        v-model="item.regionID"
                        @valueChanged="onRegionChanged"
                    />
                </div>
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="drp_bank_company_codes">Bank Company</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_bank_company_codes', id: 'drp_bank_company_codes'  }"
                        :items="lookups.bankCodes"
                        value-expr="id"
                        display-expr="name"
                        :search-enabled="true"
                        v-model="item.bankCompanyID"
                    />
                </div>
            </div>
             <div class="row">
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="txt_bank_code">Bank Code</label>
                    <rqInputNumber
                        id="txt_bank_code" 
                        automation_id="txt_bank_code"
                        decimals="0"
                        defaultValue="0"
                        v-model="item.bankCode"
                    />
                </div>
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="txt_main_branch">Main Branch</label>
                    <input
                        automation_id="txt_main_branch"
                        type="text"
                        class="form-control"
                        maxlength="8"
                        v-model="item.mainBranch"
                    >
                </div>
            </div>
            <div class="row">
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="txt_off2">Off2</label>
                    <input
                        automation_id="txt_off2"
                        type="text"
                        class="form-control"
                        maxlength="8"
                        v-model="item.off2"
                    >
                </div>
            </div>
        </rq-page-section>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import { ORTEpicorBankCodeDto }  from "../models";
    import { required } from "validators";
    import { useVuelidate } from "@vuelidate/core";
    import { EpicorStateList } from "@config/enums";

    export default {
        name: 'EpicorBankCodesForm',
        props: {
            item: {type: Object, default: null},
            uniqueValidator: { type: Function, default: (() => true) }
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                lookups: {bankCodes: []},
                originalData: new ORTEpicorBankCodeDto(this.item),
                showBanner: false
            };
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),

            ...mapState({
                globalRegionId: state => state.system.globalRegionId,
            }),
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            isAdmin() { return this.item.regID === 0;},
            regionId() { return _.getNumber(this, "item.regionID", null) || -1; },
            epicorStateList() { return EpicorStateList.lookupItems; },
            accountingCodes() { return this.lookupHelpers.getLookupItems(this.lookupItems.ACCOUNTING_CODES); },
            regions() { return this.lookupHelpers.getRegions(); },
            payeeCompany: {
                get() {
                    return {
                        companyID: this.this.payeeCompanyID,
                        companyName: this.this.payeeCompanyName
                    };
                },
                set(val) {
                    this.this.payeeCompanyID = _.get(val, "companyID", null);
                    this.this.payeeCompanyName = _.get(val, "companyName", null);
                }
            },
        },

        validations: () => ({
            /*
            item: {
                regID: { required },
                description: { required },
                additionalDepositLabel: { required: (val, vm) => {
                    if(vm.isAdditionalDeposit && val === "")
                        return false;
                    else
                        return true;}
                },
                uniqueRegID: (val, vm) => vm.uniqueValidator(val, "regID"),
                uniqueDescription: (val, vm) => vm.uniqueValidator(val, "description")
            }
            */
        }),

        created() {
            this.initNonReactiveVariables();
        },

        methods: {
            save(){
                const self = this;

                self.showBanner = true;
                self.v$.$touch();

                if (self.v$.$error) return Promise.resolve({ item:null, isValid: false });

                let itemData = self.item.toDataObject();
                let changes = self.getAuditChanges(self.originalData.toDataObject(), itemData);

                if (changes.length === 0) return Promise.resolve(true); 

                let apiPromise = self.$api.EpicorBankCodesApi.saveEpicorBankCode(itemData, changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(item => {
                        self.item.ortEpicorBankCodeID = item.ortEpicorBankCodeID
                        self.$toast.success(`${self.itemTypeName} was saved.`);
                        return { item, isValid: true };
                    }).catch(e => {
                        self.$toast.error({ message: `Save failed for ${self.itemTypeName}.` });
                        return { item:null, isValid: false };
                    });
            },
            onBlurDescription(e){
                this.item.description = _.trim(this.item.description);
            },
            onRegionChanged(e) {
                const self = this;
                self.lookups.bankCodes = self.createDropdownGroup(self.lookupHelpers.getAllLookupItems(self.lookupItems.ESCROW_ACCOUNTS, self.item.regionID), self.item.regionID);
            },
            initNonReactiveVariables() {
                const self = this;
                //this.regions = this.lookupHelpers.getLookupItems(this.lookupItems.REGIONS, this.globalRegionId);
                self.lookups.bankCodes = self.createDropdownGroup(self.lookupHelpers.getAllLookupItems(self.lookupItems.ESCROW_ACCOUNTS, self.item.regionID), self.item.regionID);
            },
            initLookups() {
                const self = this;
            },
            createDropdownGroup(items, regionID) {
                _.each(items, i => {
                    i.additionalIdentity = i.inactive ? 'Inactive' : 'Active';
                });
                items = _.filter(items, item=>item.regionID == regionID);
                return items;
            },
            

        }
    }
</script>