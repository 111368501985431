<template>
    <router-view v-slot="{ Component }">
        <keep-alive include="CompanyContactList">
            <component
                :is="Component"
                @company-update-success="goToList"
                @company-cancel-add="goToList"
                @back-to-company-list="goToListTab(0)"
                @view-company-contacts="viewCompanyContacts"
                @view-company-detail="goToCompanyDetail"
                @view-contact-detail="onViewContactDetail"
                @contact-update-success="onBackToCompanyContactList"
                @contact-cancel-add="onBackToCompanyContactList"
            />
        </keep-alive>
    </router-view>
</template>

<script>
    import ManageCompanies from "@utilities/manage-companies/ManageCompanies";
    import { useManageCompaniesStore } from "@/store/modules/manage-companies";
    export default {
        name: "ThirdPartyCompanies",
        extends: ManageCompanies,
        setup() {
            return useManageCompaniesStore();
        },
        data() {
            return {
                routePrefix: "cfg:ent:tpc:"
            };
        },
        methods: {
            onViewContactDetail(params={}) {
                let tabIndex = _.parseNumber(params?.tabIndex, -1);
                if (tabIndex >= 0) {
                    this.activeTabIndex = tabIndex;
                    this.targetCompany = _.keys(params).length >= 4
                        ? _.pick(params, ["companyId", "companyName", "regionId", "companySalesRepStaffId"])
                        : {};
                }
                this.goToContactDetail(params);
            },
            onBackToCompanyContactList(srcParams={}) {
                if (this.activeTabIndex >= 2) {
                    let company = _.isEmpty(srcParams)
                        ? this.targetCompany
                        : srcParams;
                    this.viewCompanyContacts(company);
                } else if (this.activeTabIndex < 0) {
                    this.goToList({});
                } else {
                    this.goToListTab(this.activeTabIndex);
                }
                this.$nextTick(() => {
                    this.resetState();
                });
            },
        }
    }
</script>