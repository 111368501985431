<template>
    <div class="content-wrapper">
        <rq-banner
            variant="error"
            title="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && v$.$error"
        />
        <div class="rq-container mb-1">
            <div class="row">
                <div class="col col-12 col-md-6 form-group form-required" :class="{'has-error' : v$.item.regionID.$error}">
                    <label for="regionCombo">Region</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_region', id: 'drp_region' }"
                        value-expr="regionID"
                        display-expr="displayName"
                        :items="regions"
                        :search-enabled="true"
                        v-model="v$.item.regionID.$model"
                    />
                    <rq-validation-feedback
                        message="Region is required."
                    />
                </div>
                <div class="col col-12 col-md-6 form-group form-required" :class="{ 'has-error': v$.item.name.$error || v$.item.uniqueName.$invalid }" v-rq-tooltip.hover.top="{ title: isNameReadOnly ? 'Cannot rename Branch None or Internet' : ''}">
                    <label for="name">Name</label>
                    <input automation_id="txt_name"
                        v-model="v$.item.name.$model"
                        type="text"
                        class="form-control"
                        id="name"
                        placeholder="Name"
                        maxlength="40"
                        @blur="onBlurName"
                        :readonly="isNameReadOnly"
                    >
                    <rq-validation-feedback
                        :messages="{
                            'Name is required.': v$.item.name.$error,
                            'A Branch already exists with this Name within the selected Region.': v$.item.uniqueName.$invalid
                        }"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col col-12 form-group form-required" :class="{'has-error' : v$.item.description.$error}">
                    <label for="description">Description</label>
                    <input automation_id="txt_description"
                        v-model="v$.item.description.$model"
                        type="text"
                        class="form-control"
                        id="description"
                        placeholder="Description"
                        maxlength="40"
                        @blur="onBlurDescription"
                    >
                    <rq-validation-feedback
                        message="Description is required."
                    />
                </div>
            </div>
            <div class="row">
                <div class="col col-12 col-md-6 form-group">
                    <label for="drp_file_number_pool">File Pool</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_file_number_pool', id: 'drp_file_number_pool' }"
                        value-expr="id"
                        display-expr="name"
                        :items="filePools"
                        :search-enabled="true"
                        v-model="item.fileNumberPoolID"
                    />
                </div>
                <div class="col col-12 col-md-6 form-group">
                    <label for="report_code">Report Code</label>
                    <input automation_id="txt_report_code" v-model="item.reportCode" type="text" class="form-control" id="report_code" placeholder="Report Code" >
                </div>
            </div>
            <div class="row">
                <div class="col col-12 col-md-6 label-lg">
                    <b-form-checkbox
                        automation_id="chk_multiple_bank_accounts"
                        id="multiple_bank_accounts"
                        v-model="item.multipleBankAccount">Multiple Bank Accounts</b-form-checkbox>

                </div>
                    <!--RQO-24436 = when prompt is enabled, owner policy screen doesn't load. comment out per suggestion
                    <b-form-checkbox
                        automation_id="chk_audit_log_version_prompt"
                        id="chk_audit_log_version_prompt"
                        v-model="item.promptForAuditLogVersion">Prompt for Title Production Version</b-form-checkbox>
                    -->
                <div class="col col-12 col-md-6 form-group">
                    <label for="default_sellers_for_1099">Default Sellers for 1099</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_default_sellers_for_1099', id: 'drp_default_sellers_for_1099', ref: 'defaultsellersfor1099' }"
                        value-expr="id"
                        display-expr="name"
                        :items="defaultSellersFor1099"
                        :search-enabled="true"
                        v-model="item.defaultSellers1099"
                    />
                </div>
            </div>

            <div class="row mb-1">
                <div class="col-auto ms-auto">
                    <rq-section-expand-collapse-all section-group="branch-form" />
                </div>
            </div>
            <rq-section-card title="Property Defaults" section-group="branch-form" collapsible collapsed>
                <div class="row">
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-6 form-group">
                        <label for="default_property_state">Default Property State</label>
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_property_state', id: 'drp_property_state', ref: 'stateSelectBox' }"
                            value-expr="id"
                            display-expr="id"
                            :items="states"
                            :search-enabled="true"
                            v-model="item.state"
                        />
                    </div>
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-6 form-group">
                        <label for="county_name">Default County Name</label>
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_property_county', id: 'drp_property_county', ref: 'countySelectBox' }"
                            value-expr="id"
                            display-expr="name"
                            :items="stateCounties"
                            :search-enabled="true"
                            v-model="item.countyID"
                            :disabled="countiesDisabled"
                        />
                    </div>
                </div>
            </rq-section-card>

            <rq-section-card title="Branch Defaults" section-group="branch-form" collapsible collapsed>
                <div class="row">
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-6 form-group">
                        <label for="default_duplicate_file_checking">Default Duplicate File Search</label>
                         <dx-select-box
                            :input-attr="{ automation_id: 'drp_system_default_duplicate_file_checking', id: 'drp_system_default_duplicate_file_checking' }"
                            value-expr="id"
                            display-expr="name"
                            :items="dupeSearchSetups"
                            :search-enabled="true"
                            v-model="item.duplicateOrderSearchSetupID"
                        />
                    </div>
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-6 form-group">
                        <label for="default_file_process">Default Process Template</label>
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_workflow_process_template_id', id: 'drp_workflow_process_template_id' }"
                            value-expr="id"
                            display-expr="name"
                            :items="processTemplates"
                            :search-enabled="true"
                            v-model="item.workflowProcessTemplateID"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-6 form-group">
                        <label for="title_company">Title Company</label>
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_titleCompany', id: 'drp_titleCompany', ref: 'titleCompanyDropDownBox' }"
                            value-expr="id"
                            display-expr="name"
                            :items="titleCompanies"
                            :search-enabled="true"
                            v-model="item.titleCompanyCompanyID"
                        />
                    </div>
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-6 form-group">
                        <label for="escrow_account">Escrow Account</label>
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_escrow_account', id: 'drp_escrow_account' }"
                            value-expr="id"
                            display-expr="name"
                            :items="escrowAccounts"
                            :search-enabled="true"
                            v-model="item.bankCompanyID"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-6 form-group">
                        <label for="underwriter">Underwriter</label>
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_underwriter', id: 'drp_underwriter' }"
                            value-expr="id"
                            display-expr="name"
                            :items="underwriters"
                            :search-enabled="true"
                            v-model="item.underwriterCompanyID"
                        />
                    </div>
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-6 form-group">
                        <label for="legal_paperwork_prepared_by">Legal Paperwork Prepared By</label>
                        <company-picker
                            ref="refLegalPaperworkPreparedBy"
                            automation_id="pic_legal_paperwork_prepared_by"
                            dialogTitle="Select Legal Paperwork Prepared By"
                            :companyRegionId="item.regionID"
                            v-model="item.legalPaperworkPreparedBy">
                        </company-picker>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-6 form-group">
                        <label for="place_of_closing">Place of Closing</label>
                        <company-picker
                            ref="refPlaceOfClosing"
                            :companyRoleId="9"
                            automation_id="pic_place_of_closing_company"
                            dialogTitle="Select Place of Closing"
                            :companyRegionId="item.regionID"
                            v-model="item.placeOfClosingCompany">
                        </company-picker>
                    </div>
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-6 form-group">
                        <label for="Settlement Agent">Settlement Agent</label>
                        <company-picker
                            ref="refSettlementAgent"
                            :companyRoleId="28"
                            automation_id="pic_settlement_agent_company"
                            dialogTitle="Select Settlement Agent Company"
                            :companyRegionId="item.regionID"
                            v-model="item.settlementAgentCompany">
                        </company-picker>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-6 form-group">
                        <label for="default_premium_payee">Default Premium Payee</label>
                        <company-picker
                            ref="refDefaultPremiumPayee"
                            automation_id="pic_default_premium_payee"
                            dialogTitle="Select Premium Payee"
                            :companyRegionId="item.regionID"
                            v-model="item.premiumPayeeCompany">
                        </company-picker>
                    </div>
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-6 form-group">
                        <label for="Settlement Agent">Document Preview</label>
                        <div class="input-group">
                            <rq-switch
                                automation_id="au_documentPreview"
                                type="stop-light"
                                v-model="item.documentPreview"
                            />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-6 form-group">
                        <label for="default_tax_rate_%_for_invoice">Default Tax Rate % for Invoice</label>
                        <div class="input-group">
                            <rqInputNumber v-model="item.invoiceTaxRate" defaultValue="0" decimals="3" minValue="0" maxValue="100" cssClass="form-control" automation_id="txt_sale_interest_rate"></rqInputNumber>
                            <span class="input-group-text">%</span>
                        </div>
                    </div>
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-6 form-group">
                        <label for="Settlement Agent">Include $0.00 Endorsements on Invoice</label>
                        <div class="input-group">
                            <rq-switch
                                automation_id="au_include_$0.00_Endorsements_on_Invoice"
                                type="stop-light"
                                v-model="item.include0EndorsementsOnInvoice"
                            />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-6 form-group">
                        <label for="underwriter">Standby Fees and Tax Year</label>
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_standbyFeesAndTaxYear', id: 'drp_standbyFeesAndTaxYear' }"
                            value-expr="id"
                            display-expr="name"
                            :items="standbyFeesAndTaxYears"
                            :search-enabled="true"
                            v-model="item.standbyFeesAndTaxYear"
                        />
                    </div>
                </div>
            </rq-section-card>

            <rq-section-card title="Vesting Information" section-group="branch-form" collapsible collapsed>
                <div class="row">
                    <div class="col col-12 col-md-6 label-lg">
                        <b-form-checkbox
                            automation_id="chk_override_order_options_vesting_info_settings"
                            id="override_order_options_vesting_info_settings"
                            v-model="item.overrideOrderOptionsVestingInformationSettings">Override Order/Options Vesting Info Settings</b-form-checkbox>
                    </div>
                    <div class="col col-12 col-md-6 label-lg">
                        <b-form-checkbox
                            automation_id="chk_show_on_mortagage_borrower"
                            id="show_on_mortagage_borrower"
                            v-model="item.showOnMortgageBorrower">Show on Mortgage Borrower</b-form-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12 col-md-6 label-lg">
                        <b-form-checkbox
                            automation_id="chk_show_on_title_vested_in_seller"
                            id="show_on_title_vested_in_seller"
                            v-model="item.showOnTitleVestedInSeller">Show on Title Vested In/Seller</b-form-checkbox>
                    </div>
                    <div class="col col-12 col-md-6 label-lg">
                        <b-form-checkbox
                            automation_id="chk_show_on_current_title_holder_sellers"
                            id="show_on_current_title_holder_sellers"
                            v-model="item.showOnCurrentTitleHoldersSeller">Show on Current Title Holders/Sellers</b-form-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12 col-md-6 label-lg">
                        <b-form-checkbox
                            automation_id="chk_show_on_title_vested_in_buyer"
                            id="show_on_title_vested_in_buyer"
                            v-model="item.showOnTitleVestedInBuyer">Show on Title Vested In/Buyer</b-form-checkbox>
                    </div>
                    <div class="col col-12 col-md-6 label-lg">
                        <b-form-checkbox
                            automation_id="chk_show_on_current_title_holder_buyers"
                            id="show_on_current_title_holder_buyers"
                            v-model="item.showOnCurrentTitleHoldersBuyer">Show on Current Title Holders/Buyers</b-form-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12 col-md-6 label-lg">
                        <b-form-checkbox
                            automation_id="chk_show_on_owner_insured"
                            id="show_on_owner_insured"
                            v-model="item.showOnOwnerInsured">Show on Owner Insured</b-form-checkbox>
                    </div>
                </div>
            </rq-section-card>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import { required } from "validators";
    import { useVuelidate } from "@vuelidate/core";
    import { BranchDto }  from "@/shared/models/models";
    import { DefaultSellersFor1099 } from '../../enums';
    import { CompanyPicker } from '@/shared/components/rq';
    import { ORDER_MUTATIONS } from '@/store/mutations';
    import { StandbyFeesAndTaxYearType } from "@config/enums";
    export default {
        name: "BranchForm",

        props: {
            item: { type: Object, default: () => new BranchDto() },
            uniqueValidator: { type: Function, default: (() => true) }
        },

        components: { CompanyPicker },

        setup: () => ({ v$: useVuelidate() }),

        data() {
            return {
                originalData: new BranchDto(this.item),
                showBanner: false
            }
        },

        computed: {
            ...mapState({
                orderBranch: state => state.orders.branch || {},
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            defaultSellersFor1099() { return  DefaultSellersFor1099.lookupItems; },
            countiesDisabled() { return _.isEmpty(this.stateCounties); },
            regions() { return this.lookupHelpers.getRegions(); },
            states() { return this.lookupHelpers.getStates(); },
            stateCounties() { return _.isEmpty(this.item.state) ? [] : this.lookupHelpers.getCountiesByState(this.item.state); },
            dupeSearchSetups() { return this.lookupHelpers.getLookupItems(this.lookupItems.DUPLICATE_ORDER_SEARCH_SETUP, this.item.regionID); },
            filePools() { return this.lookupHelpers.getLookupItems(this.lookupItems.FILE_NUMBER_POOLS, this.item.regionID); },
            processTemplates() { return this.lookupHelpers.getLookupItems(this.lookupItems.WORKFLOW_PROCESS_TEMPLATES, this.item.regionID); },
            titleCompanies() { return this.lookupHelpers.getLookupItems(this.lookupItems.TITLE_COMPANIES, this.item.regionID); },
            escrowAccounts() { return this.lookupHelpers.getLookupItems(this.lookupItems.ESCROW_ACCOUNTS, this.item.regionID); },
            underwriters() { return this.lookupHelpers.getUnderwriters(this.item.regionID); },
            isNameReadOnly(){ return this.originalData.name === "None" || this.originalData.name === "Internet"; },
            standbyFeesAndTaxYears() { return StandbyFeesAndTaxYearType.lookupItems; }
        },

        watch: {
            "item.state"(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.item.countyID = null;
            }
        },

        validations: () => ({
            item: {
                regionID: { required },
                name: { required },
                description: { required },
                uniqueName: (val, vm) => vm.uniqueValidator(val, "name")
            }
        }),

        methods: {
            save(){
                const self = this;
                self.showBanner = true;
                self.v$.$touch();

                if (self.v$.$error) return Promise.resolve(false);

                let itemData = self.item.toDataObject();
                let changes = self.getAuditChanges(self.originalData.toDataObject(), itemData);
                if (changes.length === 0) return Promise.resolve(true);

                let success = false;
                let apiPromise = self.$api.BranchesApi.saveBranch(itemData, changes);
                return self.$rqBusy.wait(apiPromise, { targetElement: self.$el.parentElement })
                    .then(item => {
                        self.$toast.success({ message: `${self.itemTypeName} was saved.` });
                        success = true;
                        return item;
                    })
                    // Investigate branch saved and see if it matches the current order.
                    // If it does, we need to update the store.
                    .then((item) => {
                        if (_.get(self.orderBranch, 'branchID') == item.branchID) {
                            self.$store.commit(ORDER_MUTATIONS.SET_ORDER_BRANCH, item);
                        }
                        return item;
                    })
                    .then(() => success)
                    .catch(e => {
                        self.$toast.error({ message: `Save failed for ${self.itemTypeName}.` });
                        return Promise.resolve(false) ;
                    });
            },
            onBlurDescription(e){
                this.item.description = _.trim(this.item.description);
            },
            onBlurName(e){
                this.item.name = _.trim(this.item.name);
            }
        }
    }
</script>
