<template>
  <div class="content-wrapper">
    <rqdx-action-data-grid
      ref="dataGrid"
      :automation_id="elementName('tbl')"
      :actions="selectionActions"
      :config="gridConfig"
      :data-source="gridDataSource"
      v-model:search-value="searchText"
      v-model:validationErrors="validationErrors"
      integrated-search
      rq-filters
      rq-editable
      @delete="onDeleteItem"
    >
    </rqdx-action-data-grid>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import DxGridUtils from "@/shared/utilities/DxGridUtils";
import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";
import { CheckCoverLetterDto } from "../models";
import {
  useGridInvokerMethods,
  useGridDocumentTemplatePicker,
} from "@/shared/composables/";
import { h } from "vue";

export default {
  name: "CheckCoverLettersList",
  mixins: [GridSystemLookupMixin],
  setup() {
    const { dataGrid, invokeGridMethod, invokeGridComponentMethod } =
      useGridInvokerMethods();

    const { getDocumentTemplateGridColumn } = useGridDocumentTemplatePicker();

    return {
      dataGrid,
      invokeGridMethod,
      invokeGridComponentMethod,
      getDocumentTemplateGridColumn,
    };
  },
  data() {
    return {
      items: [],
      selectedItem: {},
      validationErrors: [],
      addEventName: "",
      validationContext: {},
      searchText: "",
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.authentication.session.user,
      allBranches: (state) => _.get(state, "system.lookups.branches", []),
    }),
    ...mapGetters(["lookupHelpers", "lookupItems"]),
    itemKey() {
      return _.get(this.$route.meta, "itemKey") || "";
    },
    itemTypeNamePlural() {
      return _.get(this.$route.meta, "label") || "";
    },
    itemTypeName() {
      return _.get(this.$route.meta, "itemTypeName") || "";
    },
    itemTypeDescription() {
      return _.get(this.$route.meta, "itemTypeDescription") || "";
    },
    regions() {
      return this.lookupHelpers.getRegions();
    },
    gridInstance() {
      return _.get(this.$refs, "dataGrid.gridInstance", null);
    },
    selectionActions() {
      return [
        {
          name: "delete",
          text: "Delete",
          eventName: "delete",
          allowMultiSelection: true,
          requireSelection: true,
          tooltip: `Delete ${this.itemTypeName}`,
        },
      ];
    },
  },

  watch: {
    validationErrors(newValue, oldValue) {
      if (newValue === oldValue) return;
      this.$events.emit("update-config-error", {
        message: _.isEmpty(newValue)
          ? ""
          : "Please correct the highlighted errors on screen to continue.",
        hasError: !_.isEmpty(newValue),
      });
    },
  },

  created() {
    const self = this;
    self.initGridConfig();
    self.initListeners();
    self.fetchData();
  },

  beforeUnmount() {
    this.$events.off(this.addEventName, this.onAddItem);
  },

  methods: {
    elementName(prefix = "", suffix = "") {
      return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`);
    },

    initGridConfig() {
      const self = this;

      let branchDataSource = _.map(self.allBranches, (b) => {
        let branchItem = _.clone(b);
        let branchRegion = self.lookupHelpers.getRegion(b.regionID);
        branchItem.regionDisplay = _.isNil(branchRegion)
          ? "Region not found"
          : branchRegion.description;
        return branchItem;
      });

      let uniqueRowRule = {
        type: "custom",
        message:
          "The selected branch/coverletter/document combination already exists for the selected region.",
        validationCallback: (params) =>
          !self.checkCoverLetterExists(params.data),
      };

      self.gridConfig = {
        sorting: { enabled: true },
        columns: [
          {
            dataField: "regionID",
            dataType: "number",
            caption: "Region",
            calculateSortValue: DxGridUtils.regionDisplaySortValue,
            setCellValue(rowData, value) {
              rowData.regionID = value;
              rowData.branchID = null;
              rowData.branchIDs = [];
              rowData.documentTemplateID = null;
              rowData.documentTemplateName = null;
            },
            lookup: {
              dataSource: self.regions,
              displayExpr: "displayName",
              valueExpr: "regionID",
            },
            validationRules: [{ type: "required" }, uniqueRowRule],
          },
          {
            dataField: "branchIDs",
            dataType: "object",
            caption: "Branch",
            width: 200,
            minWidth: 100,
            calculateFilterExpression: (filterValue, operator) => {
                if(_.isNil(operator)) return _.isString(filterValue)
                    ? rowData => {
                        let displayText = self.getBranchDisplayText(rowData);
                        return _.includes(_.toLower(displayText), _.toLower(filterValue))
                    }
                    : () => true;
                return rowData => {
                    let ids = rowData.allBranches
                        ? _.map(_.filter(self.allBranches, b => b.regionID === rowData.regionID), "id")
                        : rowData.branchIDs;
                    return _.includes(ids, filterValue);
                };
            },
            rqFilter: {
                valueExpr: "branchID",
                displayExpr: "description",
                dataSource: branchDataSource,
                listOperator: "or",
                valueOperator: "contains",
                itemTemplate: {
                    name: "BranchItemTemplate",
                    props: { item: Object, index: Number },
                    setup(props) {
                        return () => h("div", { class: "d-inline-flex align-items-baseline" }, [
                            h("span", {class:"text-truncate"}, props.item.name),
                            h("span", { class: "text-muted font-italic font-xs font-weight-light ms-auto text-truncate" }, props.item.regionDisplay)
                        ]);
                    }
                }
            },
            cellTemplate: function(cellElement, cellInfo) {
                if((_.isEmpty(cellInfo.value) && !cellInfo.data.allBranches) || _.isNil(cellInfo.data.regionID)) return;
                const searchHighlightTemplate = '<span class="dx-datagrid-search-text">$&</span>';

                let displayText = self.getBranchDisplayText(cellInfo.data);

                let searchHighlighted = _.isEmpty(_.trim(self.searchText)) ? displayText : _.replace(displayText, new RegExp(self.searchText, "ig"), searchHighlightTemplate);
                $("<span />")
                    .addClass("text-truncate")
                    .attr("title", displayText)
                    .append(searchHighlighted)
                    .appendTo(cellElement);
            },
            setCellValue(rowData, value) {
                rowData.allBranches = value.allBranches;
                rowData.branchIDs = value.branchIDs;
                if(rowData.branchIDs.length > 0){
                    rowData.branchID = rowData.branchIDs[0];
                }
                else{
                    rowData.branchID = null;
                }
            },
            editCellTemplate: function(cellElement, cellInfo) {
                if(_.isNil(cellInfo.data.regionID)) {
                    $("<div/>")
                        .addClass("d-flex align-items-center text-muted ps-1")
                        .height(cellElement[0].clientHeight)
                        .text("Select a region first...")
                        .appendTo(cellElement);
                    return;
                }
                let branchItems = _.filter(branchDataSource, b => b.regionID === cellInfo.data.regionID);
                let initialValue = (_.isEmpty(cellInfo.value) && cellInfo.data.allBranches) ? _.map(branchItems, "branchID") : cellInfo.value;
                $("<div />").dxTagBox({
                    dataSource: branchItems,
                    displayExpr: "name",
                    valueExpr: "id",
                    value: initialValue,
                    showSelectionControls: true,
                    showDropDownButton: true,
                    applyValueMode: "useButtons",
                    maxDisplayedTags: 1,
                    onValueChanged(e) {
                        cellInfo.setValue({
                            branchIDs: e.value,
                            allBranches: e.value.length === branchItems.length
                        });
                    }
                }).appendTo(cellElement);
            },
            validationRules: [{
                type: "custom",
                message: "Branch is required.",
                validationCallback: e => _.parseBool(e.data.allBranches) || !_.isEmpty(e.data.branchIDs)
            }]
        },
          {
            dataField: "coverLetterName",
            dataType: "string",
            caption: "Cover Letter Name",
            validationRules: [{ type: "required" }, uniqueRowRule],
          },
          self.getSystemLookupGridColumn({
            column: {
              dataField: "roleTypeID",
              dataType: "number",
              caption: "Role",
              alignment: "left",
              editorOptions: { showClearButton: true },
            },
            lookupKey: self.lookupItems.ROLES,
            customSort: function (i) {
              return _.parseNumber(_.get(i, "data"));
            },
          }),
          self.getSystemLookupGridColumn({
            column: {
              dataField: "payoffDescriptionID",
              dataType: "number",
              caption: "Payoff Description",
              editorOptions: { showClearButton: true },
            },
            lookupKey: self.lookupItems.PAYOFF_DESCRIPTIONS,
            customSort: function (i) {
              return _.parseNumber(_.get(i, "data"));
            },
          }),
          self.getDocumentTemplateGridColumn({
            column: {
              dataField: "documentTemplateID",
              caption: "Template",
              dataType: "number",
              alignment: "left",
              calculateDisplayValue: "documentTemplateName",
              validationRules: [{ type: "required" }, uniqueRowRule],
            },
            documentTemplateIDExpr: "documentTemplateID",
            documentTemplateNameExpr: "documentTemplateName"
          }),
        ],
        onRowUpdating: self.onRowUpdating,
        onInitNewRow: (e) => {
          e.data.inactive = false;
        },
      };

      self.gridDataSource = {
        key: self.itemKey,
        load(loadOptions) {
          return Promise.resolve(self.items);
        },
        insert: self.onGridInsert,
        update: self.onGridUpdate,
      };
    },

    initListeners() {
      this.addEventName = `add:${this.elementName()}`;
      this.$events.on(this.addEventName, this.onAddItem);
    },

    fetchData() {
      const self = this;
      let apiPromise = self.$api.CheckCoverLettersApi.getCoverLetters();

      return self.$rqBusy
        .wait(apiPromise)
        .then((result) => {
          self.items = _.map(result, (i) => new CheckCoverLetterDto(i));
          self.refresh();
        })
        .catch((error) => {
          console.log(error);
          self.$toast.error({
            message: `Error loading ${self.itemTypeNamePlural}.`,
          });
          return error;
        });
    },

    onAddItem() {
      if (!this.gridInstance) return;
      this.gridInstance.addRow();
    },

    onDeleteItem(e) {
      if (!e || !e.data) return;

      const self = this;
      let items = e.data;
      let itemLabel =
        items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName;

      let okHandler = function (args) {
        let keys = _.map(items, self.itemKey);
        self.delete(keys);
        return true;
      };

      self.$dialog.confirm(
        "Confirm Delete",
        `Are you sure you want to delete the selected ${itemLabel}?`,
        okHandler,
        null,
        { cancelTitle: "No", okTitle: "Yes" }
      );
    },

    onGridInsert(values) {
      const self = this;
      let originalItem = new CheckCoverLetterDto();
      let newItem = new CheckCoverLetterDto(values);
      let changes = self.getAuditChanges(
        originalItem.toDataObject(),
        newItem.toDataObject()
      );
      return self.save(newItem, changes).then((result) => {
        self.fetchData();
      });
    },

    onGridUpdate(key, values) {
      const self = this;
      let itemIndex = _.findIndex(
        self.items,
        (item) => item.checkCoverLetterID === key
      );
      if (itemIndex < 0) return self.onGridInsert(values);

      let originalItem = _.cloneDeep(self.items[itemIndex]);
      let updatedItem = new CheckCoverLetterDto(
        _.assign({}, self.items[itemIndex], values)
      );

      let changes = self.getAuditChanges(
        originalItem.toDataObject(),
        updatedItem.toDataObject()
      );

      self.save(updatedItem, changes).then((result) => {
        self.fetchData();
      });
    },

    save(item, changes) {
      const self = this;
      if (changes.length == 0) {
        return Promise.resolve(item);
      }

      let apiPromise = self.$api.CheckCoverLettersApi.saveCoverLetter(
        item.toDataObject(),
        changes
      );
      return self.$rqBusy
        .wait(apiPromise)
        .then((result) => {
          self.$toast.success(`${self.itemTypeName} was saved.`);
          return result;
        })
        .catch((error) => {
          self.$toast.error({
            message: `Error saving ${self.itemTypeNamePlural}.`,
          });
          return error;
        });
    },

    delete(keys) {
      const self = this;
      let apiPromise = self.$api.CheckCoverLettersApi.deleteCoverLetters(keys);
      return self.$rqBusy
        .wait(apiPromise)
        .then(() => {
          self.removeItems(keys);
          let message =
            keys.length > 1
              ? `${keys.length} ${self.itemTypeNamePlural} were deleted.`
              : `${self.itemTypeName} was deleted.`;
          self.$toast.success(message);
          return true;
        })
        .catch((error) => {
          if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
            self.$dialog.messageBox(
              `Delete Error`,
              `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`
            );
          } else {
            self.$toast.error(`Error deleting ${self.itemTypeName}.`);
          }
          console.error(error);
          return error;
        });
    },

    removeItems(keys) {
      const self = this;
      _.forEach(keys, (key) => {
        let itemIndex = _.findIndex(
          self.items,
          (item) => item[self.itemKey] === key
        );
        if (itemIndex >= 0) self.items.splice(itemIndex, 1);
      });
      self.refresh();
    },

    refresh() {
      if (_.isNull(this.gridInstance)) return;
      this.gridInstance.clearSelection();
      this.gridInstance.refresh();
    },

    getBranchDisplayText(item) {
        const self = this;
        if(_.isEmpty(item.branchIDs) && _.isNil(item.regionID)) return;
        let selectedBranches = _.filter(self.allBranches, b => _.isEmpty(item.branchIDs)
            ? item.regionID === b.regionID
            : _.includes(item.branchIDs, b.id));
        return _.joinParts(_.map(selectedBranches, "name"), ", ");
    },

    checkCoverLetterExists(targetItem) {
      const self = this;
      return _.some(
        self.items,
        (item) =>
          item.checkCoverLetterID !== targetItem.checkCoverLetterID &&
          item.regionID === targetItem.regionID &&
          item.branchID === targetItem.branchID &&
          item.coverLetterName === targetItem.coverLetterName &&
          item.documentTemplateID === targetItem.documentTemplateID
      );
    },
  },
};
</script>