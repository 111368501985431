<template>
    <div class="content-wrapper">
        <rq-banner
            variant="error"
            title="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && v$.$error"
        />
        <rq-page-section borderless>
            <div class="row">
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="drp_region">Region</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_region', id: 'drp_region' }"
                        value-expr="id"
                        display-expr="name"
                        :items="regions"
                        :search-enabled="true"
                        v-model="item.regionID"
                        @valueChanged="onRegionChanged"
                    />
                </div>
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="drp_underwriters">Underwriter</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_underwriters', id: 'drp_underwriters'  }"
                        :items="underwriters"
                        value-expr="id"
                        display-expr="name"
                        :search-enabled="true"
                        v-model="item.underwriterCompanyID"
                    />
                </div>
            </div>
             <div class="row">
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="drp_accounting_codes">Accounting Code</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_accounting_codes', id: 'drp_accounting_codes'  }"
                        :items="accountingCodes"
                        value-expr="id"
                        display-expr="name"
                        :search-enabled="true"
                        v-model="item.accountingCodeID"
                    />
                </div>
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="drp_state">Default State</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_state' }"
                        value-expr="id"
                        display-expr="name"
                        :items="epicorStateListAbbr"
                        :search-enabled="true"
                        v-model="item.state"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="txt_rate">Rate</label>
                    <rqInputNumber
                        id="txt_rate" 
                        automation_id="txt_rate"
                        decimals="0"
                        defaultValue="0"
                        v-model="item.rate"
                    />
                </div>
            </div>
        </rq-page-section>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import { ORTEpicorUnderwriterSplitRateDto }  from "../models";
    import { required } from "validators";
    import { useVuelidate } from "@vuelidate/core";
    import { EpicorStateList } from "@config/enums";
    import { EpicorStateListAbbr } from "@config/enums";

    export default {
        name: 'EpicorUnderwriterSplitRatesForm',
        props: {
            item: {type: Object, default: null},
            uniqueValidator: { type: Function, default: (() => true) }
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                lookups: {bankCodes: []},
                originalData: new ORTEpicorUnderwriterSplitRateDto(this.item),
                showBanner: false
            };
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),

            ...mapState({
                globalRegionId: state => state.system.globalRegionId,
            }),
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            isAdmin() { return this.item.regID === 0;},
            regionId() { return _.getNumber(this, "item.regionID", null) || -1; },
            epicorStateList() { return EpicorStateList.lookupItems; },
            epicorStateListAbbr() { return EpicorStateListAbbr.lookupItems; },
            accountingCodes() { return this.lookupHelpers.getLookupItems(this.lookupItems.ACCOUNTING_CODES); },
        },

        validations: () => ({
            /*
            item: {
                regID: { required },
                description: { required },
                additionalDepositLabel: { required: (val, vm) => {
                    if(vm.isAdditionalDeposit && val === "")
                        return false;
                    else
                        return true;}
                },
                uniqueRegID: (val, vm) => vm.uniqueValidator(val, "regID"),
                uniqueDescription: (val, vm) => vm.uniqueValidator(val, "description")
            }
            */
        }),

        created() {
            this.initNonReactiveVariables();
        },

        methods: {
            save(){
                const self = this;

                self.showBanner = true;
                self.v$.$touch();

                if (self.v$.$error) return Promise.resolve({ item:null, isValid: false });

                let itemData = self.item.toDataObject();
                let changes = self.getAuditChanges(self.originalData.toDataObject(), itemData);

                if (changes.length === 0) return Promise.resolve(true);

                let apiPromise = self.$api.EpicorUnderwriterSplitRatesApi.saveEpicorUnderwriterSplitRate(itemData, changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(item => {
                        self.item.ortEpicorUnderwriterSplitRateID = item.ortEpicorUnderwriterSplitRateID
                        self.$toast.success(`${self.itemTypeName} was saved.`);
                        return { item, isValid: true };
                    }).catch(e => {
                        self.$toast.error({ message: `Save failed for ${self.itemTypeName}.` });
                        return { item:null, isValid: false };
                    });
            },
            onBlurDescription(e){
                this.item.description = _.trim(this.item.description);
            },
            onRegionChanged(e) {
                const self = this;
            },
            initNonReactiveVariables() {
                const self = this;
                this.regions = this.lookupHelpers.getLookupItems(this.lookupItems.REGIONS, this.globalRegionId);
                self.underwriters = self.lookupHelpers.getUnderwriters(_.clone(this.item.regionID));
            },
            initLookups() {
                const self = this;
            },
        }
    }
</script>