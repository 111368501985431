import { Categories } from "./categories"
import { Topics } from "../topics"
import AccountingCodeList from "./components/AccountingCodeList";
import ActiveUserList from "./components/ActiveUserList";
import BranchList from "./components/BranchList";
import BuyerSellerRelationshipList from "./components/BuyerSellerRelationshipList";
import ClosingTypeList from "./components/ClosingTypeList";
import CompanyFix from "./components/CompanyFixForm";
import DoNotDoBusinessWithList from "./components/DoNotDoBusinessWithList";
import CompanyList from "./components/CompanyList";
import CompanyRoles from "./components/CompanyRoles";
import DeliveryMethodList from "./components/DeliveryMethodList";
import CustomData from "./components/CustomData";
import DuplicateSearchList from "./components/DuplicateSearchList";
import EmailAutomationForm from "./components/EmailAutomationForm";
import EmailNotificationList from "./components/EmailNotificationList";
import FileNumberPoolList from "./components/FileNumberPoolList";
import FileSettingsForm from "./components/FileSettingsForm";
import AddressTypeList from "./components/AddressTypeList.vue";
import MortgageTypeList from "./components/MortgageTypeList";
import OrderCategoryList from "./components/OrderCategoryList";
import PropertyLayoutList from "./components/PropertyLayoutList";
import PropertyTypeList from "./components/PropertyTypeList";
import RegionList from "./components/RegionList";
import SourceOfBusinessList from "./components/SourceOfBusinessList";
import TitleUnitsList from "./components/TitleUnitsList";
import TransactionTypeList from "./components/TransactionTypeList.vue";
import VestingInformationList from "./components/VestingInformationList";
import ThirdPartyCompaniesModule from "./components/thirdPartyCompanies";
import UsersAndGroupsList from "./components/UsersAndGroupsList";
import UserGroupForm from "./components/UserGroupForm";
import Staff from "./components/Staff";
import WebDefaultsEnterprise from './components/WebDefaultsEnterprise';
import SigningStatusList from "./components/SigningStatusList"
import MortgageClauseTypeList from "./components/MortgageClauseTypeList";
import OrderTimeTrackingCategoryList from "./components/OrderTimeTrackingCategoryList";
import OrderTimeTrackingAccountCodeList from "./components/OrderTimeTrackingAccountCodeList";
import NoteCategoriesList from "./components/NoteCategoriesList";

import LookupNames from "@/store/lookupNames";

let routes = [
    {
        name: "cfg:ent:application",
        path: "application",
        component: WebDefaultsEnterprise,
        meta: {
            label: "Application",
            itemKey: "",
            itemTypeName: "Application",
            itemTypeDescription: "Set up Application Web Defaults",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Enterprise,
            scrollLayout: true,
            sysAdminRequired: true,
        }
    },
    {
        name: "cfg:ent:activeUsers",
        path: "activeUsers",
        component: ActiveUserList,
        meta: {
            label: "Active Users",
            itemKey: "",
            itemTypeName: "Active User",
            itemTypeDescription: "View Active Users in the Application",
            topic: Topics.Main,
            category: Categories.Enterprise,
            scrollLayout: true,
            skipSaveOnLeave: true,
            showAdd: false
        }
    },
    {
        name: "cfg:ent:regions",
        path: "regions",
        component: RegionList,
        meta: {
            label: "Regions",
            itemKey: "regionID",
            itemTypeName: "Region",
            itemTypeDescription: "Define regions to be used for files.",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Enterprise,
            showAdd: true
        }
    },

    {
        name: "cfg:ent:branches",
        path: "branches",
        component: BranchList,
        meta: {
            label: "Branches",
            itemKey: "branchID",
            itemTypeName: "Branch",
            itemTypeDescription: "Define branches and default settings to be used for files.",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Enterprise,
            showAdd: true
        }
    },
    {
        name: "cfg:ent:filenumberpools",
        path: "filenumberpools",
        component: FileNumberPoolList,
        meta: {
            label: "File Number Pools",
            itemKey: "fileNumberPoolID",
            itemTypeName: "File Number Pool",
            itemTypeDescription: "Configure file numbering assigned to new files.",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Enterprise,
            showAdd: true,
            refreshLookup: LookupNames.FILE_NUMBER_POOLS
        }
    },
    {
        name: "cfg:ent:users",
        path: "users",
        component: UsersAndGroupsList,
        meta: {
            label: "Users & Security Roles",
            itemKey: "",
            itemTypeName: "Users & Security Roles",
            itemTypeDescription: "Configure one or more Users or Security Roles",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Enterprise
        }
    },
    {
        name: "cfg:ent:securityRole",
        path: "securityRole/:groupUsersID",
        component: UserGroupForm,
        meta: {
            label: "Security Role",
            itemKey: "",
            itemTypeName: "Security Roles",
            itemTypeDescription: "Configure Security Role",
            skipSaveOnLeave: false,
            topic: Topics.Main,
            category: Categories.Enterprise,
            hideFromSearch: true,
            scrollLayout: false
        }
    },
    {
        name: "cfg:ent:fileSettings",
        path: "fileSettings",
        component: FileSettingsForm,
        meta: {
            label: "File Settings",
            itemKey: "",
            itemTypeName: "File Setting",
            itemTypeDescription: "System level settings for new files.",
            topic: Topics.Main,
            category: Categories.Enterprise,
            scrollLayout: true,
            sysAdminRequired: true
        }
    },
    {
        name: "cfg:ent:accountingcodes",
        path: "accountingcodes",
        component: AccountingCodeList,
        meta: {
            label: "Account Codes",
            itemKey: "accountingCodeID",
            itemTypeName: "Account Code",
            itemTypeDescription: "Define account codes for use in the Settlement Statements, Invoices and Check Writing.",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Enterprise,
            refreshLookup: LookupNames.ACCOUNTING_CODES
        }
    },
    {
        name: "cfg:ent:businesssource",
        path: "businesssource",
        component: SourceOfBusinessList,
        meta: {
            label: "Business Sources",
            itemKey: "sourceOfBusinessID",
            itemTypeName: "Business Source",
            itemTypeDescription: "Define methods used to generate business or attract customers",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Enterprise,
            refreshLookup: LookupNames.SOURCE_OF_BUSINESSES
        }
    },
    {
        name: "cfg:ent:titleUnits",
        path: "titleUnits",
        component: TitleUnitsList,
        meta: {
            label: "Title Units",
            itemKey: "titleUnitID",
            itemTypeName: "Title Unit",
            itemTypeDescription: "Define title units to represent groups or teams that can control the file pool assigned to new files.",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Enterprise,
            refreshLookup: LookupNames.TITLE_UNITS
        }
    },
    {
        name: "cfg:ent:category",
        path: "categories",
        component: OrderCategoryList,
        meta: {
            label: "Categories",
            itemKey: "orderCategoryID",
            itemTypeName: "Category",
            itemTypeDescription: "Define the file categories to display on the File Main page.",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Files,
            refreshLookup: LookupNames.ORDER_CATEGORIES
        }
    },
    {
        name: "cfg:ent:propertylayouts",
        path: "propertylayouts",
        component: PropertyLayoutList,
        meta: {
            label: "Property Layouts",
            itemKey: "propertyLayoutID",
            itemTypeName: "Property Layout",
            itemTypeDescription: "Define the fields to display on the Property page.",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Files,
            refreshLookup: LookupNames.PROPERTY_LAYOUTS
        }
    },
    {
        name: "cfg:ent:propertytypes",
        path: "propertytypes",
        component: PropertyTypeList,
        meta: {
            label: "Property Types",
            itemKey: "propertyTypeID",
            itemTypeName: "Property Type",
            itemTypeDescription: "Define the types of properties to display on the Property page.",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Files,
            refreshLookup: LookupNames.PROPERTY_TYPES,
            sysAdminRequired: true
        }
    },
    {
        name: "cfg:ent:vestinginfo",
        path: "vestinginfo",
        component: VestingInformationList,
        meta: {
            label: "Vesting Information",
            itemKey: "vestingInformationID",
            itemTypeName: "Vesting Information",
            itemTypeDescription: "Configure the vesting information to display on the Buyer and Seller pages.",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Files,
            refreshLookup: LookupNames.VESTING_INFORMATION_TYPES
        }
    },
    {
        name: "cfg:ent:buyersellerrelationship",
        path: "buyersellerrelationship",
        component: BuyerSellerRelationshipList,
        meta: {
            label: "Buyer & Seller Relationships",
            itemKey: "buyerSellerRelationshipID",
            itemTypeName: "Buyer & Seller Relationship",
            itemTypeDescription: "Configure the relationship to display on the Buyer and Seller pages.",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Files,
            refreshLookup: LookupNames.BUYER_SELLER_RELATIONSHIP_TYPES
        }
    },
    {
        name: "cfg:ent:duplicateSearches",
        path: "duplicateSearches",
        component: DuplicateSearchList,
        meta: {
            label: "Duplicate File Search",
            itemKey: "duplicateOrderSearchSetupID",
            itemTypeName: "Duplicate File Search",
            itemTypeDescription: "Configure layouts to determine what fields are available/required when creating new files.",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Files,
            refreshLookup: LookupNames.DUPLICATE_ORDER_SEARCH_SETUP
        }
    },
    {
        name: "cfg:ent:customData",
        path: "customData",
        component: CustomData,
        meta: {
            label: "Custom Data",
            itemKey: "",
            itemTypeName: "Custom Data",
            itemTypeDescription: "Custom Data",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Files,
            scrollLayout: true,
            // refreshLookup: LookupNames.DUPLICATE_ORDER_SEARCH_SETUP
        }
    },
    {
        name: "cfg:ent:companyRoles",
        path: "companyRoles",
        component: CompanyRoles,
        meta: {
            label: "Company Roles",
            itemKey: "roleTypeID",
            itemTypeName: "Company Role",
            itemTypeDescription: "Define roles to be used to categorize companies",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Companies,
            refreshLookup: LookupNames.ROLES,
            sysAdminRequired: true
        }
    },
    {
        name: "cfg:ent:titleCompany",
        path: "titleCompany",
        component: CompanyList,
        meta: {
            label: "Title Companies",
            itemKey: "",
            itemTypeName: "Title Company",
            itemTypeDescription: "Define title companies to be used on files.",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Companies,
            refreshLookup: LookupNames.TITLE_COMPANIES
        },
        props: { roleTypeId: 2 }
    },
    {
        name: "cfg:ent:underwriter",
        path: "underwriter",
        component: CompanyList,
        meta: {
            label: "Underwriters",
            itemKey: "",
            itemTypeName: "Underwriter",
            itemTypeDescription: "Define underwriters to be used on files.",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Companies,
            refreshLookup: LookupNames.UNDERWRITERS
        },
        props: { roleTypeId: 3 }
    },
    {
        name: "cfg:ent:deliveryMethods",
        path: "deliveryMethods",
        component: DeliveryMethodList,
        meta: {
            label: "Delivery Methods",
            itemKey: "workflowServiceRequestDeliveryMethodID",
            itemTypeName: "Delivery Method",
            itemTypeDescription: "Allows for the definition of ways in which services can be provided to an outside vendor",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Companies,
            sysAdminRequired: true
        }
    },
    ThirdPartyCompaniesModule,
    {
        name: "cfg:ent:companyCorrection",
        path: "companyCorrection",
        component: CompanyFix,
        meta: {
            label: "Company Corrections",
            itemKey: "",
            itemTypeName: "Company Correction",
            itemTypeDescription: "Allows users to merge duplicate/multiple company entries into a single company.",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Companies,
            scrollLayout: true
        }
    },
    {
        name: "cfg:ent:doNotDoBusinessWith",
        path: "doNotDoBusinessWith",
        component: DoNotDoBusinessWithList,
        meta: {
            label: "Do Not Do Business With",
            itemKey: "",
            itemTypeName: "Do Not Do Business With",
            itemTypeDescription: "Define entities that users shouldn't do business with.",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Companies,
            scrollLayout: true
        }
    },
    {
        name: "cfg:ent:addressTypes",
        path: "addressTypes",
        component: AddressTypeList,
        meta: {
            label: "Address Types",
            itemKey: "addressTypeID",
            itemTypeName: "Address Type",
            itemTypeDescription: "Define Labels to be used to categorize Address Types.",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Companies,
            refreshLookup: LookupNames.ADDRESS_TYPES,
            sysAdminRequired: true
        }
    },
    {
        name: "cfg:ent:emailAutomation",
        path: "emailAutomation",
        component: EmailAutomationForm,
        meta: {
            label: "E-mail Automation",
            itemKey: "",
            itemTypeName: "E-mail Automation",
            itemTypeDescription: "Define the location and parameters for the e-mail relay of automated messages.",
            skipSaveOnLeave: false,
            topic: Topics.Main,
            category: Categories.Communication,
            scrollLayout: true,
            sysAdminRequired: true
        }
    },
    {
        name: "cfg:ent:noteCategories",
        path: "noteCategories",
        component: NoteCategoriesList,
        meta: {
            label: "Note Categories",
            itemKey: "noteCategoryID",
            itemTypeName: "Note Categories",
            itemTypeDescription: "Define the categories available to users when creating notes.",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Communication,
            sysAdminRequired: true,
            refreshLookup: LookupNames.NOTE_CATEGORIES
        }
    },
    // RQO-33456: Hide this section
    // {
    //     name: "cfg:ent:notifications",
    //     path: "notifications",
    //     component: EmailNotificationList,
    //     meta: {
    //         label: "Notifications",
    //         itemKey: "",//do this to trick out the ConfigurationMain page to NOT show the add button section
    //         itemTypeName: "Notification",
    //         itemTypeDescription: "Configure event notification templates.",
    //         skipSaveOnLeave: true,
    //         topic: Topics.Main,
    //         category: Categories.Communication,
    //         scrollLayout: true
    //     }
    // },
    {
        name: "cfg:ent:staff",
        path: "staff",
        component: Staff,
        meta: {
            label: "Staff",
            itemKey: "",
            itemTypeName: "Staff",
            itemTypeDescription: "Define staff to specific job functions.",
            skipSaveOnLeave: true,
            logRouteAccess: true,
            topic: Topics.Main,
            category: Categories.Enterprise,
            scrollLayout: true,
            refreshLookup: LookupNames.STAFF
        }
    },
    {
        name: "cfg:ent:signingTypes",
        path: "signingTypes",
        component: ClosingTypeList,
        meta: {
            label: "Signing Types",
            itemKey: "closingTypeID",
            itemTypeName: "Signing Type",
            itemTypeDescription: "Define signing types.",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Files,
            refreshLookup: LookupNames.CLOSING_TYPES
        }
    },
    {
        name: "cfg:ent:mortgageTypes",
        path: "mortgageTypes",
        component: MortgageTypeList,
        meta: {
            label: "Mortgage Types",
            itemKey: "mortgageTypeID",
            itemTypeName: "Mortgage Type",
            itemTypeDescription: "Define the types of mortgages to select on the Loans page.",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Files,
            refreshLookup: LookupNames.MORTGAGE_TYPES
        }
    },
    {
        name: "cfg:ent:transactionTypes",
        path: "transactionTypes",
        component: TransactionTypeList,
        meta: {
            label: "Transaction Types",
            itemKey: "transactionTypeID",
            itemTypeName: "Transaction Type",
            itemTypeDescription: "Define the types of transactions to select on the file Main page.",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Files,
            refreshLookup: LookupNames.TRANSACTION_TYPES
        }
    },
    {
        name: "cfg:ent:signingStatuses",
        path: "signingStatuses",
        component: SigningStatusList,
        meta: {
            label: "Signing Statuses",
            itemKey: "signingStatusID",
            itemTypeName: "Signing Status",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Files,
            refreshLookup: [LookupNames.SIGNING_STATUSES]
        }
    },
    {
        name: "cfg:ent:mortgageClauseTypes",
        path: "mortgageClauseTypes",
        component: MortgageClauseTypeList,
        meta: {
            label: "Mortgage Clause Types",
            itemKey: "mortgageClauseTypeID",
            itemTypeName: "Mortgage Clause Type",
            itemTypeDescription: "Define the types of mortgage clauses to select on the Loans page.",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Files,
            refreshLookup: LookupNames.MORTGAGE_CLAUSE_TYPES
        }
    },
    {
        name: "cfg:ent:orderTimeTrackingCategories",
        path: "orderTimeTrackingCategories",
        component: OrderTimeTrackingCategoryList,
        meta: {
            label: "Time Tracking Categories",
            itemKey: "orderTimeTrackingCategoryID",
            itemTypeName: "Time Tracking Category",
            itemTypeDescription: "Define the time tracking categories to select on the orders page.",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Files,
            featureFlag: "orderTimeTracking",
            refreshLookup: LookupNames.ORDER_TIME_TRACKING_CATEGORIES
        }
    },
    {
        name: "cfg:ent:orderTimeTrackingAccountCodes",
        path: "orderTimeTrackingAccountCodes",
        component: OrderTimeTrackingAccountCodeList,
        meta: {
            label: "Time Tracking Account Codes",
            itemKey: "orderTimeTrackingAccountCodeID",
            itemTypeName: "Time Tracking Account Code",
            itemTypeDescription: "Define the time tracking account code to select on the orders page.",
            skipSaveOnLeave: true,
            topic: Topics.Main,
            category: Categories.Files,
            featureFlag: "orderTimeTracking",
            refreshLookup: LookupNames.ORDER_TIME_TRACKING_ACCOUNT_CODES
        }
    },

];

export default routes;