<template>
    <div class="content-wrapper">
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && v$.$error"
        />

        <rq-page-section v-if="!isModal" :title="title" header-size="lg" header-only borderless>
            <template #header-actions>
                <ul class="nav config-actions ms-auto">
                    <li class="nav-item form-group">
                        <b-form-checkbox
                            automation_id="chk_inactive"
                            id="chk_inactive"
                            :disabled="readOnly"
                            v-model="item.inactive">Inactive</b-form-checkbox>
                    </li>
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_cancel"
                            variant="action"
                            @click="onCancel">Cancel</b-btn>
                    </li>
                    <li class="nav-item" v-rq-tooltip.hover :title="readOnly ? '(Access Restricted)' : ''">
                        <b-btn
                            automation_id="btn_save"
                            variant="action"
                            :disabled="readOnly || v$.$error"
                            @click="onSave({ userInitiated:true })">Save</b-btn>
                    </li>

                </ul>
            </template>
            <template #header-secondary>
                <b-btn automation_id="lnk_back" variant="link" :to="{ name:'cfg:sscalc:recordingfees'}">View Recording Fees</b-btn>
            </template>
        </rq-page-section>

        <RqTabs
            :tabs="tabItems"
        >
            <template v-if="isModal" #tabs-end>
                <div class="ms-auto pt-1">
                    <b-form-checkbox
                        automation_id="chk_inactive"
                        id="chk_inactive"
                        v-model="item.inactive">Inactive</b-form-checkbox>
                </div>
            </template>
            <template #general>
                <rq-page-section title="General" header-size="md" borderless>
                    <fieldset :disabled="readOnly">
                        <div class="row">
                            <div class="col col-12 col-md-6 col-lg-3 form-group form-required" :class="{ 'has-error': v$.item.regionID.$error }">
                                <label for="drp_region">Region</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_region', id: 'drp_region' }"
                                    class="form-control"
                                    :items="regions"
                                    value-expr="regionID"
                                    display-expr="displayName"
                                    :search-enabled="true"
                                    :disabled="readOnly"
                                    v-model="v$.item.regionID.$model"
                                />
                                <rq-validation-feedback>Region is required</rq-validation-feedback>
                            </div>
                            <div class="col col-12 col-md-6 col-lg-3 form-group form-required" :class="{ 'has-error': v$.item.description.$error }">
                                <label for="txt_description">Description</label>
                                <input
                                    id="txt_description"
                                    automation_id="txt_description"
                                    type="text"
                                    class="form-control"
                                    placeholder="Description"
                                    maxlength="250"
                                    v-model="v$.item.description.$model"
                                >
                                <rq-validation-feedback>Description is required</rq-validation-feedback>
                            </div>
                            <div class="col col-12 col-md-6 col-lg-3 form-group  form-required" :class="{ 'has-error': v$.item.effectiveDate.$error }">
                                <label for="dtp_effective_date">Effective Date</label>
                                <rqdx-date-box
                                    id="dtp_effective_date"
                                    :disabled="readOnly"
                                    v-model="v$.item.effectiveDate.$model"
                                />
                                <rq-validation-feedback>Effective Date is required</rq-validation-feedback>
                            </div>
                            <div class="col col-12 col-md-6 col-lg-3 form-group form-required" :class="{ 'has-error': v$.item.effectiveBasedOn.$error }">
                                <label for="drp_effective_date_based_on">Effective Date Based On</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_effective_date_based_on', id: 'drp_effective_date_based_on' }"
                                    class="form-control"
                                    :items="effectiveDateBasedOn"
                                    value-expr="id"
                                    display-expr="name"
                                    :disabled="readOnly"
                                    :search-enabled="true"
                                    v-model="v$.item.effectiveBasedOn.$model"
                                />
                                <rq-validation-feedback>Effective Date Based On is required</rq-validation-feedback>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset :disabled="readOnly">
                        <div class="row">
                            <div class="col col-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_recording_type">Recording Type</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_recording_type', id: 'drp_recording_type' }"
                                    class="form-control"
                                    :items="recordingType"
                                    value-expr="id"
                                    display-expr="name"
                                    :search-enabled="true"
                                    :disabled="readOnly"
                                    v-model="item.recordingTypeID"
                                />
                            </div>
                            <div class="col col-12 col-md-6 col-lg-3 form-group form-required" :class="{ 'has-error': v$.item.stateAssociated.$error }">
                                <label for="drp_state">State</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_state', id: 'drp_state' }"
                                    class="form-control"
                                    :items="usStates"
                                    value-expr="id"
                                    display-expr="id"
                                    :search-enabled="true"
                                    :disabled="readOnly"
                                    v-model="v$.item.stateAssociated.$model"
                                />
                                <rq-validation-feedback>State is required</rq-validation-feedback>
                            </div>
                            <div class="col col-12 col-md-6 col-lg-3 form-group">
                                <label for="tb_county">County</label>
                                <dx-tag-box
                                    id="tb_county"
                                    automation_id="tb_county"
                                    class="form-control"
                                    :data-source="stateCounties"
                                    display-expr="name"
                                    value-expr="id"
                                    :defer-rendering="false"
                                    :show-selection-controls="true"
                                    :show-clear-button="true"
                                    :search-enabled="true"
                                    :max-displayed-tags="3"
                                    :show-drop-down-button="true"
                                    :disabled="readOnly"
                                    v-model:value="item.countyIDs"
                                />
                            </div>
                            <div class="col col-12 col-md-6 col-lg-3 form-group">
                                <label for="txt_city">City</label>
                                <input
                                    id="txt_city"
                                    automation_id="txt_city"
                                    type="text"
                                    class="form-control"
                                    placeholder="City"
                                    maxlength="150"
                                    v-model="item.cityAssociated"
                                >
                            </div>
                        </div>
                    </fieldset>
                    <fieldset :disabled="readOnly">
                        <div class="row">
                            <div class="col col-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_who_pays">Who Pays</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_who_pays', id: 'drp_who_pays' }"
                                    class="form-control"
                                    :items="whoPays"
                                    value-expr="id"
                                    display-expr="name"
                                    :search-enabled="true"
                                    :disabled="readOnly"
                                    v-model="item.whoPays"
                                />
                            </div>
                            <div class="col col-12 col-md-6 col-lg-3 form-group">
                                <label for="txt_interest_rate">Who Pays Percentage</label>
                                <div class="input-group">
                                    <rq-input-number
                                        id="txt_interest_rate"
                                        defaultValue="0"
                                        decimals="3"
                                        minValue="0"
                                        maxValue="100"
                                        cssClass="form-control"
                                        v-model="item.percentage"
                                    />
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                            <div class="col col-12 col-md-6 col-lg-3 form-group">
                                <label for="pic_payee">Payee</label>
                                <company-picker
                                    id="pic_payee"
                                    automation_id="pic_payee"
                                    dialogTitle="Select Payee"
                                    :disabled="readOnly"
                                    v-model="item.payeeCompany">
                                </company-picker>
                            </div>
                            <div class="col col-12 col-md-6 col-lg-3 form-group" :class="{ 'has-error': v$.item.accountCodeId.$error }">
                                <label for="drp_accounting_code">Account Code</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_accounting_code', id: 'drp_accounting_code' }"
                                    class="form-control"
                                    :items="accountingCodes"
                                    value-expr="id"
                                    display-expr="name"
                                    :search-enabled="true"
                                    :show-clear-button="true"
                                    :disabled="readOnly"
                                    v-model="v$.item.accountCodeId.$model"
                                />
                                <rq-validation-feedback>Account Code does not belong to current Region, select another</rq-validation-feedback>
                            </div>
                        </div>
                    </fieldset>
                </rq-page-section>
            </template>
            <template #detail>
                <rq-page-section title="Settlement Statements" header-size="md" borderless>
                    <fieldset :disabled="readOnly">
                        <div class="row">
                            <div class="col col-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_hud_1974_line_type">2-Page HUD</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_hud_1974_line_type', id: 'drp_hud_1974_line_type' }"
                                    :items="hud1974LineTypes"
                                    v-model="item.huD1974LineType"
                                    value-expr="id"
                                    display-expr="name"
                                    :disabled="readOnly"
                                    :search-enabled="true"
                                />
                            </div>
                            <div class="col col-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_hud_2010_line_type">3-Page HUD</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_hud_2010_line_type', id: 'drp_hud_2010_line_type' }"
                                    :items="hud2010LineTypes"
                                    v-model="item.huD2010LineType"
                                    value-expr="id"
                                    display-expr="name"
                                    :disabled="readOnly"
                                    :search-enabled="true"
                                />
                            </div>
                            <div class="col col-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_cdf_line_type">CDF</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_cdf_line_type', id: 'drp_cdf_line_type' }"
                                    :items="cdfLineTypes"
                                    v-model="item.cdfLineType"
                                    value-expr="id"
                                    display-expr="name"
                                    :disabled="readOnly"
                                    :search-enabled="true"
                                />
                            </div>
                            <div class="col col-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_settlement_statement_line_type">Settlement Statement</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_settlement_statement_line_type', id: 'drp_settlement_statement_line_type' }"
                                    :items="settlementStatementLineTypes"
                                    v-model="item.cssLineType"
                                    value-expr="id"
                                    display-expr="name"
                                    :disabled="readOnly"
                                    :search-enabled="true"
                                />
                            </div>
                        </div>
                    </fieldset>
                </rq-page-section>
                <rq-page-section title="Calculations" header-size="md" borderless>
                    <fieldset :disabled="readOnly">
                        <div class="row">
                            <div class="col col-3 col-md-4 col-lg-3 form-group">
                                <label for="drp_calculationBasis">Calculation Basis</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_calculationBasis', id: 'drp_calculationBasis' }"
                                    :items="calculationBasis"
                                    v-model="item.calculationBasis"
                                    value-expr="id"
                                    display-expr="name"
                                    :disabled="readOnly"
                                    :search-enabled="true"
                                />
                            </div>
                            <div v-show="!item.calculationBasis" class="col col-12 col-md-6 col-lg-3 form-group">
                                <label for="txt_flat_fee_calculation">Flat Fee Calculation</label>
                                <div class="input-group">
                                    <span class="input-group-text">$</span>
                                    <rq-input-number
                                        id="txt_flat_fee_calculation"
                                        defaultValue="0"
                                        decimals="2"
                                        cssClass="form-control"
                                        v-model="item.flatFeeAmount"
                                    />
                                </div>
                            </div>
                            <div v-show="item.calculationBasis" class="col col-12 col-md-6 col-lg-3 form-group">
                                <label for="txt_initial_page_count">Initial Page Count</label>
                                <rq-input-number
                                    id="txt_initial_page_count"
                                    v-model="item.initialPages"
                                    display-expr="name"
                                    :search-enabled="true"
                                    default-value="1"
                                    min-value="1"
                                    max-value="99"
                                    decimals="0"
                                    :readonly="readOnly"
                                />
                            </div>
                            <div v-show="item.calculationBasis" class="col col-12 col-md-6 col-lg-3 form-group">
                                <label for="txt_initial_page_amount">Amount</label>
                                <div class="input-group">
                                    <span class="input-group-text">$</span>
                                    <rq-input-number
                                        id="txt_initial_page_amount"
                                        defaultValue="0"
                                        decimals="2"
                                        cssClass="form-control"
                                        v-model="item.initialPageAmount"
                                    />
                                </div>
                            </div>
                            <div v-show="item.calculationBasis" class="col col-12 col-md-6 col-lg-3 form-group">
                                <label for="txt_additional_pages">Additional Pages</label>
                                <div class="input-group">
                                    <span class="input-group-text">$</span>
                                    <rq-input-number
                                        id="txt_additional_pages"
                                        defaultValue="0"
                                        decimals="2"
                                        cssClass="form-control"
                                        v-model="item.additionalPageAmount"
                                    />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </rq-page-section>
            </template>
        </RqTabs>

    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { required } from "validators";
    import { useVuelidate } from "@vuelidate/core";
    import { GlobalEventManager } from "@/app.events";
    import { RecordingFeeConfigurationDto, RecordingDocumentDto }  from "../models";
    // import { AccountingCodeDto }  from "../../enterprise/models";
    import { RecordingFeeEffectiveDateBasedOn, RecordingType, WhoPays, InitialPageNumber, CalculationBasis } from "../../enums";
    import { useRqTabAlerts } from "@/shared/composables/useRqTabAlerts";

    export default {
        name: "RecordingFeeConfigurationForm",
        props: {
            recordingFeeConfigurationID: { type: Number, default: 0 },
            isModal: { type: Boolean, default: false }
        },
        setup() {
            const v$ = useVuelidate();
            const alertMapping = { generalTabAlert: { type: "v$" } };
            const { generalTabAlert } = useRqTabAlerts({ v$, alertMapping });
            return { v$, generalTabAlert };
        },
        data() {
            return {
                item: {},
                recordingDocumentLineTypes: [],
                hud1974LineTypes: [],
                hud2010LineTypes: [],
                cdfLineTypes: [],
                settlementStatementLineTypes: [],
                showBanner: false,
                isCancelled: false
            }
        },
        computed: {
            ...mapState({
                readOnly: state => state.isPageReadOnly,
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            title() { return (this.readOnly) ? `View: ${_.isEmpty(this.item.description) ? "Recording Fee" : this.item.description}`: `Edit: ${_.isEmpty(this.item.description) ? "Recording Fee" : this.item.description}`; },
            usStates() { return this.lookupHelpers.getStates(); },
            accountingCodes() { return this.lookupHelpers.getLookupItems(this.lookupItems.ACCOUNTING_CODES, _.getNumber(this, "item.regionID", null), _.getNumber(this, "item.accountCodeId", null)); },
            activeAccountingCodes() { return this.lookupHelpers.getLookupItems(this.lookupItems.ACCOUNTING_CODES, _.getNumber(this, "item.regionID", null)); },//active per Region
            stateCounties() { return this.lookupHelpers.getCountiesByState(_.get(this, "item.stateAssociated", "")); },
            regions() { return this.lookupHelpers.getRegions(); },
            effectiveDateBasedOn() { return  RecordingFeeEffectiveDateBasedOn.lookupItems; },
            recordingType() { return  RecordingType.lookupItems; },
            whoPays() { return  WhoPays.lookupItems; },
            calculationBasis() { return  CalculationBasis.lookupItems; },
            initialPageNumber() { return  InitialPageNumber.lookupItems; },
            tabItems() {
                const self = this;
                return [
                    { automation_id: "btn_tab_general", name: "general", title: "General", alertCount: self.generalTabAlert.alertCount },
                    { automation_id: "btn_tab_detail", name: "detail", title: "Detail" }
                ];
            }
        },

        watch: {
            stateCounties(newValue, oldValue) {
                if(_.isEmpty(this.item.countyIDs) || newValue === oldValue) return;
                if(_.isEmpty(newValue)) {
                    this.item.countyIDs = [];
                    return;
                }
                let countyIdList = [];
                _.forEach(this.item.countyIDs, id => {
                    if(!_.some(newValue, { id })) return;
                    countyIdList.push(id);
                });
                this.item.countyIDs = countyIdList.slice();
            }
        },

        validations() {
            const self = this;
            return {
                item: {
                    regionID: { required },
                    description: { required },
                    effectiveDate: { required },
                    effectiveBasedOn: { required },
                    stateAssociated: { required },
                    accountCodeId: {
                        isInRegion(val) {
                            if (_.isNil(val)) return true;
                            return _.some(self.activeAccountingCodes, ["id", val]);
                        }
                    },
                }
            };
        },

        created() {
            const self = this;
            self.loadLookUps().then( () => {
                self.fetchData();
            });
            GlobalEventManager.onSave(self, self.onSave);
            GlobalEventManager.onCancel(self, self.onCancel);
        },

        beforeUnmount() {
            GlobalEventManager.unregister(this);
        },

        methods: {
            loadLookUps(){
                const self = this;
                let apiPromises = [];
                // apiPromises.push(self.$api.AccountingCodesApi.getAccountingCodes());
                apiPromises.push(self.$api.RecordingDocumentsApi.getRecordingDocumentLineTypes());
                return self.$rqBusy.wait(Promise.all(apiPromises))
                    .then(results => {
                        // self.accountingCodes = _.map(results[0], i => new AccountingCodeDto(i));
                        self.recordingDocumentLineTypes = _.map(results[0], i => new RecordingDocumentDto(i));
                        // map results
                        self.hud1974LineTypes = self.mapLineTypes(1);
                        self.hud2010LineTypes = self.mapLineTypes(2);
                        self.cdfLineTypes = self.mapLineTypes(3);
                        self.settlementStatementLineTypes = self.mapLineTypes(4);
                        return true;}
                    )
                    .catch(error => {
                        self.$toast.error({ message: `Error loading look up lists.` });
                        return error;
                    });
            },

            fetchData() {
                const self = this;
                if(self.recordingFeeConfigurationID === 0){
                    let regionID = _.getNumber(self, "regions[0].regionID", 0);
                    self.setItemData({ regionID });
                    return;
                }
                let apiPromise = self.$api.RecordingFeeConfigurationApi.get(self.recordingFeeConfigurationID);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.setItemData(result);
                    })
                    .catch(error => {
                        console.log(error);
                        self.$toast.error("Error loading recording fee.");
                    });
            },

            setItemData(itemData) {
                const self = this;
                self.item = new RecordingFeeConfigurationDto(itemData);
                self.originalData = new RecordingFeeConfigurationDto(itemData);
                // self.regionAccountingCodes = self.mapAccountingCodes();
                self.$nextTick(() => {
                    self.v$.$reset();
                });
            },

            onSave(e){
                const self = this;
                if(this.readOnly || this.isCancelled) {
                    GlobalEventManager.saveCompleted({ success: true });
                    return;
                }
                self.save(e?.userInitiated);
            },

            onCancel(){
                const self = this;
                let changes = self.getAuditChanges(self.originalData.toDataObject(), self.item.toDataObject());
                let ok = () => {
                    self.isCancelled = true;
                    self.$router.push({ name: "cfg:sscalc:recordingfees" });
                    return true;
                }
                if (changes.length === 0 || self.readOnly) {
                    ok();
                }
                else {
                    self.$dialog.confirm("Confirm Cancel", "Discard changes and return data?", ok);
                }
            },
            save(userInitiated) {
                const self = this;
                self.v$.$touch();
                self.showBanner = true;

                if (self.v$.$error) return Promise.resolve(false);

                let saveResult = { success: true };
                let changes = self.getAuditChanges(self.originalData.toDataObject(), self.item.toDataObject());
                if (changes.length > 0 && !self.readOnly) {
                    let apiPromise = self.$api.RecordingFeeConfigurationApi.save(self.item.toDataObject(), changes);
                    return self.$rqBusy.wait(apiPromise)
                        .then(result => {
                            self.$toast.success(`${self.item.description} was saved.`);
                            self.originalData = new RecordingFeeConfigurationDto(self.item);
                            if(!self.isModal) GlobalEventManager.saveCompleted(saveResult);
                            return saveResult;
                        })
                        .catch(error => {
                            self.$toast.error("Error Saving recording fee");
                            saveResult.success = false;
                            if(!self.isModal) GlobalEventManager.saveCompleted(saveResult);
                            return error;
                        });
                }
                else {
                    if(!self.readOnly)
                    {
                        if(userInitiated) self.$toast.info("No changes detected");
                    }
                    if(!self.isModal) GlobalEventManager.saveCompleted(saveResult);
                    return Promise.resolve(saveResult);
                }
            },

            mapLineTypes(seedType){
                const self = this;
                let seedLineTypes = _.filter(self.recordingDocumentLineTypes, { seedType });
                return _.map(seedLineTypes, slt => ({ id: slt.recordingDocumentID, name: slt.description }));
            },

            // mapAccountingCodes() {
            //     let regionAcctCodes = _.filter(this.accountingCodes, { regionID: this.item.regionID });
            //     return _.map(regionAcctCodes, ac => ({ id: ac.accountingCodeID, name: `${ac.accountCode} - ${ac.description}` }));
            // }
        }
    }
</script>
