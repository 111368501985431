<template>
    <rq-section-card title="Assigned Check Numbers" class="mt-4" @shown="onShown" collapsible>
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="tbl_escrow_unit_check_range"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            export-file-name="escrow-unit-check-range-data"
            v-model:validation-errors="validationErrors"
            @delete="onDeleteItem"
            hide-search
            hide-show-column-chooser
            rq-editable>
            <template #toolbar>
                <div class="rq-title navbar-text me-auto">
                    <b-btn variant="theme" size="sm" @click="onAddItem">Add </b-btn>
                </div>
            </template>
        </rqdx-action-data-grid>
    </rq-section-card>
</template>
<script>
    import { mapGetters } from "vuex";
    import { EscrowUnitCheckDto }  from "../models";
    import { UnitCheckType } from '../../enums';
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";


    export default {

        name: "EscrowUnitChecksGrid",
        mixins: [GridSystemLookupMixin],
        props: {
            modelValue: { type: Array, default: () => [] },
            escrowUnitId: { type: Number, default: 0 },
            regionId: { type: Number, default: 0 },
            hasErrors: { type: Boolean, default: false }
        },
        data() {
            return {
                initialized: false,
                dataBound: false,
                validationErrors: [],
                items: [],
                escrowUnitsCollapsed: false,
            }
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            selectionActions() {
                return [{ name: "delete", text: "Delete", eventName: "delete", allowMultiSelection: true, tooltip: "Delete Check Number Range" }];
            },
            unitCheckType() { return UnitCheckType.lookupItems; }
        },

        watch: {
            modelValue(val) {
                if(this.dataBound) return;
                this.items = _.sortBy(_.map(val, i => new EscrowUnitCheckDto(i)), ["checkStart", "checkEnd"]);
                this.dataBound = true;
            },
            regionId: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    if(this.initialized)
                        _.invoke(this, "gridInstance.cancelEditData");
                    // this.listOfBanks = this.lookupHelpers.getLookupItems(this.lookupItems.ESCROW_ACCOUNTS, newValue);
                },
                immediate: true
            },
            validationErrors(newValue, oldValue) {
                this.$emit("update:hasErrors", !_.isEmpty(newValue));
            }
        },

        created() {
            this.initGridConfig();
            this.escrowUnitsCollapsed = _.get(this, `$store.state.ui.rqSections.groups["escrow-unit"].escrowUnitsCollapsed`, false);
        },

        methods: {
            initGridConfig(){
                const self = this;
                let isUniqueItem = (e) => {
                    let dup = {};
                    dup = _.find(self.items, (i) => {
                        return  _.parseNumber(i.checkNumberType, -1) == _.getNumber(e, "data.checkNumberType", 0)
                                && _.parseNumber(i.bankCompanyID, -1) == _.getNumber(e, "data.bankCompanyID", 0)
                                && _.parseNumber(i.escrowUnitCheckID, -1) != _.getNumber(e, "data.escrowUnitCheckID", 0);
                    });
                    return _.isEmpty(dup);
                };
                self.gridConfig = {
                    columns: [
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "bankCompanyID",
                                dataType: "number",
                                caption: "Escrow Account",
                                validationRules: [{ type: "required" }],
                            },
                            lookupKey: self.lookupItems.ESCROW_ACCOUNTS,
                            regionID: self.regionId
                        }),
                        // {
                        //     dataField: "bankCompanyID",
                        //     caption: "Bank",
                        //     lookup: {
                        //         dataSource: self.listOfBanks,
                        //         displayExpr: "name",
                        //         valueExpr: "id"
                        //     },
                        //     validationRules: [{ type: "required" }],
                        // },
                        {
                            dataField: "checkStart",
                            dataType: "number",
                            validationRules: [{ type: "required" }]
                        },
                        {
                            dataField: "checkEnd",
                            dataType: "number",
                            validationRules: [{ type: "required" }]
                        },
                        {
                            dataField: "checkNumberType",
                            dataType: "number",
                            caption: "Check Type",
                            lookup: {
                                dataSource: self.unitCheckType,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "custom",
                                    message: "That check type already exists for this bank.",
                                    validationCallback(e){ return isUniqueItem(e); }
                                }
                            ],
                        }
                    ],
                    onRowUpdating(e) {
                        e.cancel = new Promise((resolve, reject) => {
                            self.$dialog.confirm(
                                "Confirm Edit",
                                `Updating check number ranges will change existing files. Do you want to continue?`,
                                () => resolve(false), //continue with edit
                                () => resolve(true), //cancel edit
                                { cancelTitle: 'No', okTitle: 'Yes'});
                        });
                    }
                };
                self.gridDataSource = {
                    key: "escrowUnitCheckID",
                    load() {
                        return Promise.resolve(_.filter(self.items, item => !item.isDeleted));
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
                self.initialized = true;
            },

            onAddItem() {
                if(!this.gridInstance) return;
                this.gridInstance.addRow();
            },

            onGridInsert(values) {
                const self = this;
                let newItem = new EscrowUnitCheckDto(values);
                newItem.escrowUnitCheckID = -(self.items.length+1);
                self.items.push(newItem);
                self.emitValue();
                return Promise.resolve(newItem);
            },

            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item.escrowUnitCheckID === key);
                if(itemIndex < 0) return self.onGridInsert(values);
                let updatedItem = new EscrowUnitCheckDto(_.assign({}, self.items[itemIndex], values));
                self.items[itemIndex] = updatedItem;
                self.emitValue();
                return Promise.resolve(updatedItem);
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let deletedItems = e.data;
                let ok = function (args) {
                    _.forEach(deletedItems, (item) => {
                        let itemIndex = _.findIndex(self.items, { escrowUnitCheckID: item.escrowUnitCheckID });
                        if(itemIndex < 0) return;
                        self.items[itemIndex].isDeleted = true;
                    });
                    self.emitValue();
                    self.gridInstance.refresh();
                    return true;
                };
                self.$dialog.confirm("Confirm Delete",
                    `Are you sure you want to delete the selected check number range${deletedItems.length > 1 ? "s" : ""}?`,
                    ok,
                    null,
                    { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onShown(e){ _.invoke(this, "gridInstance.updateDimensions"); },

            emitValue() {
                let newValue = _.map(this.items, item => item.toDataObject());
                this.$emit("update:modelValue", newValue);
            },

            saveEditData() { return _.invoke(this, "$refs.dataGrid.saveRow");},

            refresh() {
                _.invoke(this, "gridInstance.option", "focusedRowIndex", -1);
                _.invoke(this, "gridInstance.clearSelection");
                _.invoke(this, "gridInstance.refresh");
            },

            itemExists(matchProp, matchValue, excludeProp=null, excludeValue=null) {
                const self = this;
                return _.some(self.items, item => {
                    if(_.isNil(excludeProp)) return item[matchProp] === matchValue;
                    return item[excludeProp] !== excludeValue && item[matchProp] === matchValue;
                });
            },
        }
    }
</script>