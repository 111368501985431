<template>
    <document-editor-dialog
        ref="editorDialog"
        :document-template-id="itemId"
        :document-name="documentName"
        :document-content="documentContent"
        :file-type="documentFileType"
        :document-template-region-id="documentTemplate.regionID"
        :readOnly="readOnly"
        show-advanced-toolbar
    />
</template>

<script>
    import { DocumentEditorDialog } from "@/shared/components/rq";
    import { DocumentTemplateDto } from "@documents/models";
    import { DocumentFileType } from "@documents/enums";

    export default {
        name: "DocumentTemplateEditor",
        components: { DocumentEditorDialog },
        props: {
            itemId: { type: Number, default: 0 },
            readOnly: { type: Boolean, default: true }
        },
        data() {
            const self = this;
            return {
                documentTemplate: {},
                documentName: "",
                documentContent: "",
                documentFileType: DocumentFileType.DOCX,
            };
        },

        computed: {
            editorComponent() { return _.get(this, "$refs.editorDialog", null); }
        },

        created() {
            this.fetchData();
        },

        mounted() {
            if (this.readOnly) this.$emit("disable-ok");
        },

        methods: {
            fetchData() {
                const self = this;
                if(self.itemId <= 0) {
                    self.documentTemplate = new DocumentTemplateDto({ name: "Untitled Document", description: "Untitled Document" });
                    return;
                }
                let apiPromise = self.$api.DocumentTemplatesApi.getDocumentTemplate(self.itemId);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.documentTemplate = new DocumentTemplateDto(result);
                        self.documentName = self.documentTemplate.name || self.documentTemplate.description;
                        self.documentFileType = self.documentTemplate.defaultFileType;
                        let content = self.documentTemplate.getDefaultContent(true);
                        if(_.isNil(content)) return;
                        self.documentContent = content;
                    });
            },

            save() {
                const self = this;
                if (self.itemId <= 0 || !self.editorComponent || !self.editorComponent.isDirty()) return Promise.resolve(true);
                let templateName = self.documentTemplate.name;
                let savePromise = self.editorComponent.getContent({ docx: true })
                    .then(content => {
                        if(!content.changed || _.isNil(content.docx)) return null;
                        return self.$api.DocumentTemplatesApi.saveContent({
                            documentTemplateID: self.itemId,
                            fileType: DocumentFileType.DOCX,
                            content: content.docx
                        });
                    });
                return self.$rqBusy.wait(savePromise)
                    .then(result => {
                        if(_.isNil(result)) return true;
                        self.$toast.success(`The document template, ${templateName}, was saved successfully.`);
                        return true;
                    }).catch(error => {
                        self.$toast.error(`Error saving ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },

            cancel() { return Promise.resolve(true); }
        }
    };

</script>