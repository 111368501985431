import DocumentsReportsMain from "./DocumentsReportsMain.vue";
import ConfigNav from "../shared/ConfigNav.vue";
import Routes from "./routes";
import { Categories } from "./categories";

export default {    
    name: "cfg:documentsReports",
    path: "/configuration/docsAndReports", 
    component: DocumentsReportsMain, 
    meta: {
        label: "Documents & Reports",      
        include: true, 
        skipSaveOnLeave: true
    },    
    children: [{
            name: "cfg:doc:reports",
            path: "reports",
            component: ConfigNav,
            props: {routes: Routes, category : Categories.Reports},
            meta: {
                label: Categories.Reports,
                skipSaveOnLeave: true
            }
        },{
            name: "cfg:doc:documents",
            path: "documents",
            component: ConfigNav,
            props: {routes: Routes, category : Categories.Documents},
            meta: {
                label: Categories.Documents,
                skipSaveOnLeave: true
            }
        },{
            name: "cfg:doc:filescan",
            path: "filescan",
            component: ConfigNav,
            props: {routes: Routes, category : Categories.FileScan},
            meta: {
                label: Categories.FileScan,
                skipSaveOnLeave: true
            }
        },
        ...Routes,
    ]
};