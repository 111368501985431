<template>
    <div class="content-wrapper">
        <rq-banner
            variant="error"
            title="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && v$.$error"
        />
        <rq-page-section borderless>
            <div class="row">
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="drp_region">Region</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_region', id: 'drp_region'  }"
                        value-expr="regionID"
                        display-expr="displayName"
                        :items="regions"
                        :search-enabled="true"
                        v-model="item.regionID"
                        @valueChanged="onRegionChanged"
                    />
                </div>
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="drp_branches">Location (Branch)</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_branches' }"
                        :items="lookups.branches"
                        value-expr="id"
                        display-expr="name"
                        :search-enabled="true"
                        v-model="item.branchID"
                    />
                </div>
            </div>
             <div class="row">
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="txt_seg1">Company Number (Seg1)</label>
                    <input
                        automation_id="txt_seg1"
                        type="text"
                        class="form-control"
                        maxlength="4"
                        v-model="item.seg1"
                    >
                </div>
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="txt_seg2">Work CenterCode (Seg2)</label>
                    <input
                        automation_id="txt_seg2"
                        type="text"
                        class="form-control"
                        maxlength="8"
                        v-model="item.seg2"
                    >
                </div>
            </div>
            <div class="row">
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="txt_seg3">Not Used (Seg3)</label>
                    <input
                        automation_id="txt_seg3"
                        type="text"
                        class="form-control"
                        maxlength="8"
                        :disabled="true"
                        v-model="item.seg3"
                    >
                </div>
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="drp_seg4">State (Seg4)</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_seg4' }"
                        value-expr="id"
                        display-expr="name"
                        :items="epicorStateList"
                        :search-enabled="true"
                        v-model="item.seg4"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="txt_off1"></label>
                    <label for="txt_off1">Total Rec Offset Company Num (Off1) </label>
                    <input
                        automation_id="txt_off1"
                        type="text"
                        class="form-control"
                        maxlength="4"
                        v-model="item.off1"
                    >
                </div>
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="txt_off2">Total Rec Offset Work Ctr Code</label>
                    <label for="txt_off2">(Off2 - Revenue)</label>
                    <input
                        automation_id="txt_off2"
                        type="text"
                        class="form-control"
                        maxlength="8"
                        v-model="item.off2"
                    >
                </div>
            </div>
            <div class="row">
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="txt_ar_off2">Total Rec Offset Work Ctr Code</label>
                    <label for="txt_ar_off2">(Off2 - Receiveables)</label>
                    <input
                        automation_id="txt_ar_off2"
                        type="text"
                        class="form-control"
                        maxlength="8"
                        v-model="item.arOff2"
                    >
                </div>
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="txt_off3"></label>
                    <label for="txt_off3">Total Rec (Off3 - Revenue)</label>
                    <input
                        automation_id="txt_off3"
                        type="text"
                        class="form-control"
                        maxlength="10"
                        v-model="item.off3"
                    >
                </div>
            </div>
            <div class="row">
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="txt_ar_off3">Total Rec (AROff3 - Receiveables)</label>
                    <input
                        automation_id="txt_ar_off3"
                        type="text"
                        class="form-control"
                        maxlength="10"
                        v-model="item.arOff3"
                    >
                </div>
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="txt_off4">Total Rec (Off4)</label>
                    <rqInputNumber
                        id="txt_off4" 
                        automation_id="txt_off4"
                        decimals="0"
                        defaultValue="0"
                        v-model="item.off4"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="txt_ar_off4_override">Total Rec Off4 Override</label>
                    <rqInputNumber
                        id="txt_ar_off4_override" 
                        automation_id="txt_ar_off4_override"
                        decimals="0"
                        defaultValue="0"
                        v-model="item.arOff4Override"
                    />
                </div>
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="drp_default_state">Default State</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_default_state' }"
                        value-expr="id"
                        display-expr="name"
                        :items="epicorStateListAbbr"
                        :search-enabled="true"
                        v-model="item.defaultState"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="txt_ref">Revenue Reference</label>
                    <input
                        automation_id="txt_ref"
                        type="text"
                        class="form-control"
                        maxlength="15"
                        v-model="item.ref"
                    >
                </div>
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="txt_ar_ref">Receiveables Reference</label>
                    <input
                        automation_id="txt_ar_ref"
                        type="text"
                        class="form-control"
                        maxlength="8"
                        v-model="item.arRef"
                    >
                </div>
            </div>
            <div class="row">
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="txt_tax_account">Tax Account</label>
                    <input
                        automation_id="txt_tax_account"
                        type="text"
                        class="form-control"
                        maxlength="8"
                        v-model="item.taxAccount"
                    >
                </div>
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="drp_state_override">State Override</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_state_override' }"
                        value-expr="id"
                        display-expr="name"
                        :items="epicorStateListAbbr"
                        :search-enabled="true"
                        v-model="item.stateOverride"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="drp_tax_state_override">Tax State Override</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_tax_state_override' }"
                        value-expr="id"
                        display-expr="name"
                        :items="epicorStateList"
                        :search-enabled="true"
                        v-model="item.taxStateOverride"
                    />
                </div>
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="txt_agent_number">Agent Number</label>
                    <input
                        automation_id="txt_agent_number"
                        type="text"
                        class="form-control"
                        maxlength="10"
                        v-model="item.agentNumber"
                    >
                </div>
            </div>
            <div class="row">
                <div class="col col-12 col-sm-12 col-md-6 form-group">
                    <label for="txt_ledger_account">Ledger Account</label>
                    <input
                        automation_id="txt_ledger_account"
                        type="text"
                        class="form-control"
                        maxlength="32"
                        v-model="item.ledgerAccount"
                    >
                </div>
                <div class="col col-12 col-lg-6 checkbox-group label-lg">
                    <b-form-checkbox
                        automation_id="chk_use_product_seg4"
                        id="chk_use_product_seg4"
                        v-model="item.useProductSeg4">Use Product Seg4</b-form-checkbox>
                </div>
            </div>
        </rq-page-section>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import { ORTEpicorLocationDto }  from "../models";
    import { required } from "validators";
    import { useVuelidate } from "@vuelidate/core";
    import { EpicorStateList } from "@config/enums";
    import { EpicorStateListAbbr } from "@config/enums";

    export default {
        name: 'EpicorLocationsForm',
        props: {
            item: {type: Object, default: null},
            uniqueValidator: { type: Function, default: (() => true) }
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                lookups: {branches: []},
                originalData: new ORTEpicorLocationDto(this.item),
                showBanner: false
            };
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),

            ...mapState({
                globalRegionId: state => state.system.globalRegionId,
            }),
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            isAdmin() { return this.item.regID === 0;},
            regionId() { return _.getNumber(this, "item.regionID", null) || -1; },
            regions() { return this.lookupHelpers.getRegions(); },
            epicorStateList() { return EpicorStateList.lookupItems; },
            epicorStateListAbbr() { return EpicorStateListAbbr.lookupItems; },
        },

        validations: () => ({
            /*
            item: {
                regID: { required },
                description: { required },
                additionalDepositLabel: { required: (val, vm) => {
                    if(vm.isAdditionalDeposit && val === "")
                        return false;
                    else
                        return true;}
                },
                uniqueRegID: (val, vm) => vm.uniqueValidator(val, "regID"),
                uniqueDescription: (val, vm) => vm.uniqueValidator(val, "description")
            }
            */
        }),

        created() {
            this.initNonReactiveVariables();
        },

        methods: {
            save(){
                const self = this;

                self.showBanner = true;
                self.v$.$touch();

                if (self.v$.$error) return Promise.resolve({ item:null, isValid: false });

                let itemData = self.item.toDataObject();
                let changes = self.getAuditChanges(self.originalData.toDataObject(), itemData);

                if (changes.length === 0) return Promise.resolve(true);

                let apiPromise = self.$api.EpicorLocationsApi.saveEpicorLocation(itemData, changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(item => {
                        self.item.ortEpicorLocationID = item.ortEpicorLocationID
                        self.$toast.success(`${self.itemTypeName} was saved.`);
                        return { item, isValid: true };
                    }).catch(e => {
                        self.$toast.error({ message: `Save failed for ${self.itemTypeName}.` });
                        return { item:null, isValid: false };
                    });
            },
            onBlurDescription(e){
                this.item.description = _.trim(this.item.description);
            },
            onRegionChanged(e) {
                const self = this;
                self.lookups.branches = self.lookupHelpers.getBranches(self.item.regionID);
            },
            initNonReactiveVariables() {
                const self = this;
                //this.regions = this.lookupHelpers.getLookupItems(this.lookupItems.REGIONS, this.globalRegionId);
                self.lookups.branches = self.lookupHelpers.getBranches(self.item.regionID);
            },
            initLookups() {
                const self = this;
            },
        }
    }
</script>