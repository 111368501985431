import {
    CompanyEdit,
    ContactEdit,
    CompanyContactList
} from "@utilities/manage-companies/components";
import { RoleType, companyContactRouteNames } from "@/shared/models/enums";
import LookupNames from "@/store/lookupNames";

let pages = [
    {
        name: companyContactRouteNames.ThirdPartyCompanies,
        path: "",
        component: CompanyContactList,
        props: route => ({
            allowAdd: true,
            allowEdit: true,
            allowDelete: true,
            allowActivate: true,
            showRegion: true,
            excludeRoleTypes: [
                RoleType.Bank
            ],
            tabIndex: _.getNumber(route, "params.tabIndex", -1),
            selectedCompanyKeys: [-1],
            selectedContactKeys: [-1],
            targetCompany: route.params.targetCompanyId != null ? { companyId: _.get(route, "params.targetCompanyId", {}), regionId: _.get(route, "params.targetCompanyRegionId", {}), companyName: _.get(route, "params.targetCompanyName", {}), companySalesRepStaffId: _.get(route, "params.targetCompanySalesRepStaffId", {})  } : {}
        }),
        meta: {
            label: "Third Party Companies",
            sectionTitle: "Third Party Companies",
            skipSaveOnLeave: true,
            include: false,
            refreshLookup: [LookupNames.AUTO_CONSOLIDATE_PAYEES, LookupNames.ESCROW_ACCOUNTS, LookupNames.USER_ESCROW_ACCOUNTS, LookupNames.TITLE_COMPANIES, LookupNames.UNDERWRITERS]
        }
    },
    {
        path: "company/:companyId?",
        name: "cfg:ent:tpc:company",
        component: CompanyEdit,
        props: route => ({
            companyId: _.getNumber(route, "params.companyId", 0),
            showActions: true,
            showCompanyLinks: true
        }),
        meta: {
            label: "Company",
            skipSaveOnLeave: true
        }
    },
    {
        path: "contact/:contactId?",
        name: "cfg:ent:tpc:contact",
        component: ContactEdit,
        props: route => ({
            contactId: _.getNumber(route, "params.contactId", 0),
            companyId: _.getNumber(route, "params.companyId", 0),
            companyName: _.get(route, "params.companyName", ""),
            companySalesRepStaffId: _.getNumber(route, "params.companySalesRepStaffId", null),
            showActions: true,
            showCompanyLinks: true
        }),
        meta: {
            label: "Contact",
            skipSaveOnLeave: true
        }
    }
];

export default pages;
