<template>
    <div class="content-wrapper">
        <rq-banner
            variant="error"
            title="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && hasError"
            sticky
        />
        <div class="rq-container">
            <div class="row">

                <!-- TG - Added functionality forseeing the potential request to fully integrate in some way, but commenting out since this isn't an explicit requirement of the current task -->
                <!-- <div v-if="item.isCustom" class="col col-12 col-lg-4 form-group form-required">
                    <label for="txt_custom_tab">Custom Tab</label>
                    <dx-select-box
                        :input-attr="$utils.idAttrs('txt_custom_tab')"
                        :items="customTabs"
                        value-expr="titleProdCustomTabID"
                        display-expr="tabLabel"
                        v-model="v$.section.titleProdCustomTabID.$model"
                        @value-changed="onCustomTabValueChanged"
                    />
                    <rq-validation-feedback>Custom Tab is required.</rq-validation-feedback>
                </div> -->

                <div class="col col-12 col-lg-6 form-group">
                    <label for="txt_description">Description</label>
                    <input
                        id="txt_description"
                        automation_id="txt_description"
                        type="text"
                        class="form-control"
                        v-model="item.description"
                        disabled>
                </div>
                <div v-if="sectionLabelVisible" class="col col-12 col-lg-6 form-group">
                    <label for="txt_section_label">Section Label</label>
                    <input
                        id="txt_section_label"
                        automation_id="txt_section_label"
                        type="text"
                        class="form-control"
                        placeholder="Section Label"
                        maxlength="50"
                        v-model="section.sectionLabel"
                        disabled>
                </div>
            </div>
        </div>

        <rqdx-action-data-grid
            ref="categoryDataGrid"
            title="Categories"
            title-size="sm"
            automation_id="dg_sections"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            v-model:validation-errors="gridValidationErrors"
            @delete="onDeleteItems"
            hide-show-column-chooser
            hide-clear-filters
            hide-search
            hide-export
            rq-editable>
            <template #toolbar>
                <ul class="nav me-auto">
                    <li class="nav-item">
                        <b-btn variant="theme" @click="onAddItem">Add</b-btn>
                    </li>
                </ul>
            </template>
        </rqdx-action-data-grid>
    </div>

</template>
<script>
    import { mapState, mapGetters } from "vuex";
    // import { required, requiredIf } from "validators";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import GridInvokerMixin from "@/shared/mixins/GridInvokerMixin";
    import { StandardLanguageSectionDto, StandardLanguageCategoryDto, StandardLanguageSectionCategoryDto }  from "../../models";
    
    export default {
        name: "StandardLanguageSectionForm",

        mixins: [ GridInvokerMixin({ grid:"categoryDataGrid" }) ],

        props: {
            item: { type: Object, default: () => ({}) },
            // customTabs: { type: Array, default: () => [] },
            categories: { type: Array, default: () => [] },
            uniqueValidator: { type: Function, default: (() => true) }
        },

        data() {
            const self = this;
            return {
                gridValidationErrors: [],
                section: new StandardLanguageSectionDto(self.item),
                originalData: new StandardLanguageSectionDto(self.item),
                showBanner: false
            }
        },

        // validations: {
        //     section: {
        //         titleProdCustomTabID: { required: requiredIf(vm => vm.isCustom) },
        //         sectionLabel: { required: requiredIf(vm => vm.isCustom) },
        //     }
        // },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            regions() { return this.lookupHelpers.getRegions(); },
            availableCategories() {
                let sectionCategoryIDs = _.map(this.section.categories, "standardLanguageCategoryID");
                return _.filter(this.categories, cat => !_.includes(sectionCategoryIDs, cat.standardLanguageCategoryID));
            },
            sectionLabelVisible() { return _.has(StandardLanguageSectionDto.systemDefaultFields, this.item.name) || this.item.isCustom; },
            selectionActions() {
                return [{
                    name: "delete",
                    text: "Delete",
                    eventName: "delete",
                    requireSelection: true,
                    allowMultiSelection: true,
                    tooltip: "Delete Category"
                }];
            },
            hasError() { return !_.isEmpty(this.gridValidationErrors); }
        },

        created() {
            const self = this;
            self.initGridConfig();
        },

        methods: {

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "regionID",
                            caption: "Region",
                            dataType: "number",
                            lookup: {
                                dataSource: e => self.regions,
                                displayExpr: "displayName",
                                valueExpr: "regionID"
                            },
                            calculateSortValue: rowData => {
                                let rowRegion = _.find(self.regions, r => r.regionID === rowData.regionID) || {};
                                return rowRegion.displayName || "";
                            },
                            validationRules: [{ type: "required" }]
                        },
                        {
                            dataField: "standardLanguageCategoryID",
                            dataType: "number",
                            caption: "Category",
                            lookup: {
                                dataSource: e => self.categories,
                                displayExpr: "description",
                                valueExpr: "standardLanguageCategoryID"
                            },
                            calculateSortValue: rowData => {
                                let rowCategory = _.find(self.categories, c => c.id === rowData.standardLanguageCategoryID) || {};
                                return rowCategory.name || "";
                            },
                            validationRules: [
                                {
                                    type: "custom",
                                    validationCallback: e => self.isUniqueItem(e.data),
                                    message: "Region/Category combination already exists",
                                    reevaluate: true
                                },
                                { type: "required" },
                            ]
                        },
                        {
                            dataField: "isDefault",
                            caption: "Default",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate
                        }
                    ],
                    onInitNewRow(e) {
                        e.data.standardLanguageSectionID = self.item.standardLanguageSectionID;
                        e.data.isDefault = false;
                    },
                };

                self.gridDataSource = {
                    key: "id",
                    load: () => _.filter(self.section.categories, sc => !sc.isDeleted),
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },

            onAddItem() {
                this.invokeGridComponentMethod("setAddRow");
            },

            onDeleteItems(e) {
                const self = this;
                self.$dialog.confirm("Confirm", "Are you sure you want to delete the selected categories for this section?", () => self.deleteItems(e.data));
            },

            onGridInsert(values) {
                const self = this;
                let newItem = new StandardLanguageSectionCategoryDto(values);
                self.section.categories.push(newItem);
                if(newItem.isDefault)
                    self.removeExistingDefault(newItem);
                return newItem;
            },

            onGridUpdate(key, values) {
                const self = this;
                let targetItem = _.find(self.section.categories, item => item.id === key);
                if(_.isNil(targetItem)) {
                    return self.onGridInsert(values);
                }
                _.assign(targetItem, values);
                if(targetItem.isDefault) {
                    self.removeExistingDefault(targetItem);
                }
                return targetItem;
            },

            // TG - Added functionality forseeing the potential request to fully integrate in some way, but commenting out since this isn't an explicit requirement of the current task
            // onCustomTabValueChanged(e) {
            //     if(_.isNil(e.event)) return;
            //     let selectedItem = e.component.option("selectedItem");
            //     if(_.isNil(selectedItem)) return;
            //     this.section.tabLabel = selectedItem.tabLabel;
            //     this.section.displayOrder = 999;
            // },

            deleteItems(items) {
                const self = this;
                if(!_.isArray(items) || _.isEmpty(items)) return;
                _.forEach(items, delItem => {
                    let itemIndex = _.findIndex(self.section.categories, item => item.id === delItem.id);
                    if(itemIndex >= 0 && delItem.id <= 0)
                        self.section.categories.splice(itemIndex, 1);
                    else
                        self.section.categories[itemIndex].isDeleted = true;
                });
                self.refresh();
            },

            removeExistingDefault(newDefault) {
                const self = this;
                let existingDefault = _.find(self.section.categories, item => item.isDefault && item.regionID === newDefault.regionID && item.id !== newDefault.id);
                if(_.isNil(existingDefault)) return;
                existingDefault.isDefault = false;
            },

            isUniqueItem(data){
                const self = this;
                return !_.some(self.section.categories, item =>
                    _.parseNumber(item.standardLanguageCategoryID, -1) === _.parseNumber(data.standardLanguageCategoryID, -1)
                    && _.parseNumber(item.regionID, -1) === _.parseNumber(data.regionID, -1)
                    && _.parseNumber(item.id, -1) !== _.parseNumber(data.id, -1)
                );
            },

            hasChanges() {

                // if((this.sectionLabelVisible && this.section.sectionLabel !== this.originalData.sectionLabel)
                //     || (this.section.isCustom && this.section.titleProdCustomTabID !== this.originalData.titleProdCustomTabID)
                //     || _.some(this.section.categories, c => c.isDeleted || c.id <= 0)) return true;

                if(_.some(this.section.categories, c => c.isDeleted || c.id <= 0)) return true;
                let hasChanges = false;
                _.forEach(this.section.categories, sc => {
                    let original = _.find(this.originalData.categories, oc => oc.id === sc.id);
                    hasChanges = _.isNil(original)
                        || original.regionID !== sc.regionID
                        || original.standardLanguageCategoryID !== sc.standardLanguageCategoryID
                        || original.isDefault !== sc.isDefault;
                    return !hasChanges;
                });
                return hasChanges;
            },

            save(){
                const self = this;
                self.showBanner = true;
                return self.invokeGridMethod("saveEditData")
                    .then(() => {
                        if(self.hasError) return { isValid: false };
                        if(!self.hasChanges()) return { data: self.item, isValid: true };

                        let apiPromise = self.$api.StandardLanguageApi.saveSection(self.section.toDataObject());
                        return self.$rqBusy.wait(apiPromise)
                            .then(data => {
                                self.$toast.success("Section saved successfully.");
                                return { data, isValid: true };
                            }).catch(err => {
                                console.error(err);
                                self.$toast.error("An error occurred while saving this section.");
                                return { data:null, isValid: false };
                            });
                    });
            },

            refresh() {
                this.invokeGridMethod("clearSelection");
                this.invokeGridMethod("refresh");
            },

            updateDimensions: _.debounce(function(e) {
                this.invokeGridMethod("updateDimensions");
            }, 300, { "leading": false, "trailing": true }),
        }
    }
</script>