<template>
    <div class="content-wrapper document-templates">
        <rq-page-section title="Document Templates" header-size="lg" header-only borderless>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">Create, edit, categorize and group document templates.</div>
            </template>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item" v-show="activeTabIndex < tabIndex.States">
                        <b-btn automation_id="btn_add" variant="theme" @click="onAddClicked">Add</b-btn>
                    </li>
                    <li v-if="arcIntegrationEnabled" class="nav-item" v-show="activeTabIndex === tabIndex.Documents">
                        <div class="dropdown rq-section-dropdown">
                            <button class="btn btn-theme dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Import</button>
                            <ul class="dropdown-menu">
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="btn_frm_file" @click="onImportClicked">From File</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="btn_marketplace" @click="onMarketplaceClicked">Marketplace</button>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li v-else class="nav-item" v-show="activeTabIndex === tabIndex.Documents">
                        <b-btn automation_id="btn_import" variant="theme" @click="onImportClicked">Import</b-btn>
                    </li>
                    <!-- <li class="nav-item" v-show="activeTabIndex === 0">
                        <b-form-checkbox
                            ref="checkNoInput"
                            :value="importOverwriteExisting"
                            v-model="boolValue">Overwrite Existing</b-form-checkbox>
                    </li> -->
                </ul>
                <ul v-if="activeTabIndex === tabIndex.States" class="nav config-actions ms-auto">
                    <li class="nav-item">
                        <b-btn automation_id="btn_cancel" variant="action" @click="onCancel">Cancel</b-btn>
                    </li>
                    <li class="nav-item">
                        <b-btn automation_id="btn_save" variant="action" @click="onDocumentStateSave({ userInitiated: true })">Save</b-btn>
                    </li>
                </ul>
            </template>
        </rq-page-section>

        <RqTabs 
            :tabs="tabItems"
            v-model="activeTabIndex"
            @activate-tab="onTabActivated"
            :scrollable="activeTabIndex === tabIndex.States"
        >
            <template #documents>
                <document-template-list
                    :ref="tabComponentRefs[tabIndex.Documents]"
                    :user-groups="userGroups"
                    v-model:is-valid="componentIsValid"
                    v-model:validation-list="validationList"
                />
            </template>
            <template #packages>
                <document-package-list
                    :ref="tabComponentRefs[tabIndex.Packages]"
                    :user-groups="userGroups"
                    v-model:is-valid="componentIsValid"
                    v-model:validation-list="validationList"
                />
            </template>
            <template #categories>
                <document-category-list
                    :ref="tabComponentRefs[tabIndex.Categories]"
                    :user-groups="userGroups"
                    v-model:is-valid="componentIsValid"
                    v-model:validation-list="validationList"
                />
            </template>
            <template #states>
                <document-state-form
                    :ref="tabComponentRefs[tabIndex.States]"
                    :user-groups="userGroups"
                    v-model:is-valid="componentIsValid"
                    v-model:validation-list="validationList"
                />
            </template>
        </RqTabs>

        <form class="d-none" enctype="multipart/form-data" novalidate ref="fileForm">
            <input
                id="uploadFile"
                ref="fileInput"
                type="file"
                automation_id="txt_upload_file"
                name="imports"
                @change="onUploadFileChange"
                accept=".xml"
            >
        </form>
    </div>
</template>
<script>
    import { ref } from "vue";
    import { GlobalEventManager } from "@/app.events";
    import { mapGetters } from "vuex";
    import { UserGroupDto }  from "../../enterprise/models";
    import DocumentTemplateList  from "./DocumentTemplateList.vue";
    import DocumentPackageList  from "./DocumentPackageList.vue";
    import DocumentCategoryList  from "./DocumentCategoryList.vue";
    import DocumentStateForm  from "./DocumentStateForm.vue";
    import { useRqTabAlerts } from "@/shared/composables/useRqTabAlerts";
    import { useLicenseStore } from "@/store/modules/license";
    import { computed } from "vue";

    const TabIndex = {
        Documents: 0,
        Packages: 1,
        Categories: 2,
        States: 3,
    }

    export default {
        components: {
            DocumentTemplateList,
            DocumentPackageList,
            DocumentCategoryList,
            DocumentStateForm
        },
        setup() {
            const licenseStore = useLicenseStore();
            const arcIntegrationEnabled = computed(() => licenseStore.features?.arcIntegration || false);
            const validationList = ref([]);
            const alertMapping = {
                documentsTabAlert: { type: "list-ref", list: validationList }
            };
            const { documentsTabAlert } = useRqTabAlerts({ alertMapping });
            return {
                validationList,
                documentsTabAlert,
                arcIntegrationEnabled
            };
        },
        data() {
            return {
                selectedType:  ".xml",
                activeTabIndex: 0,
                componentIsValid: true,
                validationErrors: [],
                viewLoaded: false,
                tabComponentRefs: [
                    "documentTemplateList",
                    "documentPackageList",
                    "documentCategoryList",
                    "documentStateForm"
                ],
                importOverwriteExisting: true,
                userGroups: [],
                tabIndex: TabIndex
            };
        },

        computed:{
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            activeTabComponent() { return _.get(this, `$refs.${this.tabComponentRefs[this.activeTabIndex]}`, null); },

            tabItems() {
                return [
                    { automation_id: "btn_tab_documents", title: "Documents", name: "documents", alertCount: this.documentsTabAlert.alertCount },
                    { automation_id: "btn_tab_packages", title: "Packages", name: "packages" },
                    { automation_id: "btn_tab_categories", title: "Categories", name: "categories" },
                    { automation_id: "btn_tab_states",title: "States", name: "states" },
                ]
            },
        },

        watch: {
            componentIsValid: function (newValue) {
                const self = this;
                if (!newValue){
                    self.validationErrors.push("Component Errored.");
                }else{
                    self.validationErrors = [];
                }
                self.$events.emit("update-config-error", { message: "Please correct the highlighted errors on screen to continue.", hasError: self.validationErrors.length > 0 });
            },
            activeTabIndex(newValue, oldValue) {
                if(newValue === oldValue || newValue === TabIndex.States) return;
                this.$nextTick(() => {
                    _.invoke(this, `$refs.${this.tabComponentRefs[newValue]}.updateDimensions`);
                });
            },

     },

        created() {
            this.fetchData();
            GlobalEventManager.onSave(this, this.onSave);
        },
        beforeUnmount() {
            GlobalEventManager.unregister(this);
        },


        methods:{
            fetchData() {
                const self = this;
                let apiPromise = self.$api.UsersApi.getGroups();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.userGroups = _.map(_.sortBy(result, 'login'), i => new UserGroupDto(i));
                    })
                    .catch(error => {
                        console.error(error);
                        return error;
                    });
            },

            onDocumentStateSave(e){
                _.invoke(this, "$refs.documentStateForm.save", e);
            },

            onCancel(e){
                _.invoke(this, "$refs.documentStateForm.onCancel");
            },

            onAddClicked(e){
                this.activeTabComponent.onAddItem();
            },

            onTabActivated({index, prevIndex, tab}) {
                if(prevIndex !== TabIndex.States) return;
                this.onDocumentStateSave();
            },

            save(toastIfNoChanges=false, isCalculate=false){
                const self = this;
                if(!self.activeTabComponent){
                    return Promise.resolve(true);
                }
                return self.activeTabComponent.save(toastIfNoChanges, isCalculate)
                    .then(validationResult => {
                        let isValid = self.isValid(validationResult);
                        return isValid;
                    })
                    .catch(saveError => {
                        self.toastError(`${self.currentView.itemTitle} save operation failed.`, saveError);
                        return saveError;
                    });
            },

            refreshDocumentList() {
                const self = this;
                _.invoke(self, `$refs.${self.tabComponentRefs[0]}.refresh`);
            },

            refreshItems(){
                const self = this;
                self.activeTabComponent.save(false).then(()=>{
                    self.activeTabComponent.fetchData();
                });
            },

            isValid(validationResult) {
                this.validationErrors = _.get(validationResult, "errors", []);
                return _.isEmpty(this.validationErrors);
            },

            toastError(message, error) {
                let helpMessage = _.get(error, "message", "");
                let helpButton = !_.isEmpty(helpMessage);
                console.error(error);
                this.$toast.error({ message, helpButton, helpMessage });
            },

            onSave(e) {
                let userInitiated = _.getBool(e, "userInitiated");
                if(this.activeTabIndex !== TabIndex.States) {
                    GlobalEventManager.saveCompleted({ success: true });
                }
                else {
                    this.onDocumentStateSave();
                }
            },

            onImportClicked () {
                this.$refs.fileInput.click();
            },

            onMarketplaceClicked() {
                this.$router.push({ name: 'cfg:ent:docTemplates:marketplace' });
            },

            onUploadFileChange (e) {
                const self = this;
                let cannotImport =[];
                let canImport =[];
                let files = e.target.files;
                if (!files.length) return ;
                for(let i = 0 ; i < files.length; i++)
                {
                    if(!files[i].name.includes(self.selectedType))
                    {
                        cannotImport.push(files[i]);
                    }
                    else
                    {
                        canImport.push(files[i]);
                    }
                }
                if (cannotImport.length > 0)
                {
                    self.$toast.error({message : `Cannot import all files, some of the selected files aren't the correct type for selected import.`});
                }
                var file = canImport[0];
                if (file) {
                    var reader = new FileReader();
                    reader.onload = function (evt) {
                        self.xml = evt.target.result;
                        self.onUpload();
                    }
                    reader.readAsText(file);
                }
            },
            onUpload() {
                const self = this;
                self.items =[];
                let apiPromise = this.$api.ImportUpdateApi.importDocumentTemplates(self.xml, self.importOverwriteExisting);
                this.$rqBusy.wait(apiPromise)
                    .then(result => {
                        if (result.length > 0) {
                            self.clearUploadValue();
                            self.$toast.success("Upload complete.");
                            self.refreshDocumentList();
                        } else {
                            self.clearUploadValue();
                            self.suppressGrid = true;
                            let errMsg = result.message ? result.message : "A problem occurred while uploading.";
                            self.$toast.error({ message: errMsg, autoHideEnabled: false });
                        }
                    })
                    .catch(err => {
                        self.clearUploadValue();
                        let errMsg = err.errorMessage ? err.errorMessage : "A problem occurred while uploading.";
                        self.$toast.error({ message: errMsg, autoHideEnabled: false });
                        return err;
                    });

            },
            clearUploadValue () {
                this.$refs.fileInput.value = null;
                this.$refs.fileForm.reset();
            }
        }

    };
</script>
