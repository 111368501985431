<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            v-model:validation-errors="validationErrors"
            @delete="onDeleteItem"
            integrated-search
            rq-editable
            rq-filters
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { GlobalEventManager } from '@/app.events';
    import { FileScanDescriptionTaskDto }  from "../models";
    import { FileScanTaskAction, FileScanDescriptionTaskMarkAs } from '../../enums';
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";

    export default {
        name: "FileScanDescriptionTaskList",
        mixins: [GridSystemLookupMixin],
        data () {
            return {
                items: [],
                validationErrors: [],
                deletedItems: [],
                addEventName: ""
            };
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                user: state => state.authentication.session.user
            }),
            gridInstance() {
                return _.get(this.$refs, "dataGrid.gridInstance", null) || {};
            },
        },
        watch: {
            validationErrors: function () {
                const self = this;
                self.$events.emit("update-config-error", { message: "Please correct the highlighted errors on screen to continue.", hasError: self.validationErrors.length > 0 });
            }
        },
        created(){
            this.initNonReactiveVariables();
            this.fetchData();
            this.initListeners();
            this.initGridConfig();
        },
        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },
        methods: {
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },
            initNonReactiveVariables() {
                this.itemTypeName = _.get(this.$route.meta, "itemTypeName");
                this.itemTypeNamePlural = _.get(this.$route.meta, "label");
                this.itemKey = _.get(this.$route.meta, "itemKey");
                this.fileScanTaskActions = FileScanTaskAction.lookupItems;
                this.fileScanDescriptionTaskMarkAsItems = FileScanDescriptionTaskMarkAs.lookupItems;
                // this.fileScanDescriptions = this.lookupHelpers.getLookupItems(this.lookupItems.FILE_SCAN_DESCRIPTIONS, 1);
                // this.workFlowTasks = this.lookupHelpers.getLookupItems(this.lookupItems.WORKFLOW_TASKS, 1);
                this.selectionActions = [
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Delete ${this.itemTypeName}`
                    }
                ];
            },
            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: self.itemKey,
                            visible: false,
                            allowEditing: false,
                            showInColumnChooser: false,
                        },
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "fileScanDescriptionID",
                                dataType: "number",
                                caption: "Description",
                                validationRules: [{ type: "required" }],
                            },
                            lookupKey: self.lookupItems.FILE_SCAN_DESCRIPTIONS,
                            regionId: self.user.regionID,
                        }),
                        // {
                        //     dataField: "fileScanDescriptionID",
                        //     caption: "Description",
                        //     calculateSortValue: "fileScanDescription",
                        //     lookup: {
                        //         dataSource: self.fileScanDescriptions,
                        //         displayExpr: "name",
                        //         valueExpr: "id",
                        //     },
                        //     validationRules: [
                        //         { type: "required" },
                        //     ]
                        // },
                        {
                            dataField: "actionEnumValue",
                            dataType: "number",
                            caption: "Action",
                            calculateSortValue: function (rowData) {
                                return FileScanTaskAction.displayValue(rowData.actionEnumValue);
                            },
                            lookup: {
                                dataSource: self.fileScanTaskActions,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            validationRules: [{ type: "required" }]
                        },
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "workflowTaskID",
                                dataType: "number",
                                caption: "Task",
                                validationRules: [{ type: "required" }],
                            },
                            lookupKey: self.lookupItems.WORKFLOW_TASKS
                        }),
                        // {
                        //     dataField: "workflowTaskID",
                        //     caption: "Task",
                        //     calculateSortValue: "workflowTaskName",
                        //     lookup: {
                        //         dataSource: self.workFlowTasks,
                        //         displayExpr: "name",
                        //         valueExpr: "id",
                        //     },
                        //     validationRules: [{ type: "required" }]
                        // },
                        {
                            dataField: "markTaskAsEnumValue",
                            dataType: "number",
                            caption: "Mark Task As",
                            calculateSortValue: function (rowData) {
                                return FileScanDescriptionTaskMarkAs.displayValue(rowData.markTaskAsEnumValue);
                            },
                            lookup: {
                                dataSource: self.fileScanDescriptionTaskMarkAsItems,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            validationRules: [{ type: "required" }]
                        },
                        { dataField: "addNote", dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate },
                        { type: "buttons", visible: false, showInColumnChooser: false }
                    ],
                    onRowUpdating(e) {
                        e.cancel = new Promise((resolve, reject) => {
                            self.$dialog.confirm(
                                "Confirm Edit",
                                `Updating ${self.itemTypeNamePlural} will change existing files. Do you want to continue?`,
                                () => resolve(false), //continue with edit
                                () => resolve(true), //cancel edit
                                { cancelTitle: 'No', okTitle: 'Yes'});
                        });
                    }
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },
            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },
            onAddItem() {
                if(!this.gridInstance) return;
                this.gridInstance.addRow()
            },
            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let itemsToDelete = _.map(items, r => ({ ...r, isDeleted: true }));
                    let apiPromise = self.$api.FileScanDescriptionTasksApi.save(itemsToDelete);
                    return self.$rqBusy.wait(apiPromise)
                        .then(key => {
                            let message = itemsToDelete.length > 1 ? `${itemsToDelete.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success({ message: message });
                            return true;
                        })
                        .catch(error => {
                          if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                 self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            }
                            return error;
                        })
                        .finally(() => {
                            self.fetchData();
                        });
                }

                 self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            isNotDuplicateDescription(item) {
                const self = this;
                let dup = {};
                dup = _.find(self.items, (i) => {
                    return _.toLower(_.trim(i.description)) === _.toLower(_.trim(item.data.description))
                            && _.parseNumber(_.get(i, self.itemKey, -1), -1) != _.parseNumber(_.get(item.data, self.itemKey, -1), -1);
                });

                return dup ? false : true;
            },
            fetchData() {
                const self = this;
                let apiPromise = self.$api.FileScanDescriptionTasksApi.getFileScanCategoryTasks();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new FileScanDescriptionTaskDto(i));
                        self.refresh();
                    })
                    .catch(error => {
                        self.items = [];
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },
            onSaveClick(){
                const self = this;
                self.save();
            },
            save(item, userInitiated=false){
                const self = this;
                 let data =  _.map(item, i => new FileScanDescriptionTaskDto(i));
                if (!item){
                    data  =  _.concat(_.filter(self.items, (i) => { return i.fileScanDescriptionTaskID < 1 || i.isDirty;}), self.deletedItems);
                    if(!data && data.length === 0 && data[0] === undefined) {
                        GlobalEventManager.saveCompleted({success: true});
                        return Promise.resolve(false);
                    }
                }else{
                    data = [item];
                }

                let apiPromise = self.$api.FileScanDescriptionTasksApi.save( data );
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new FileScanDescriptionTaskDto(i));
                        self.refresh();
                        self.$toast.success(`${self.itemTypeName} was saved.`);
                        return result;
                    }).catch(error => {
                        self.$toast.error(`Error saving ${self.itemTypeName}.`);
                        return error;
                    });
            },
            refresh() {
                this.deletedItems = [];
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },
            onGridInsert(values) {

                // const self = this;
                // let newItem = new FileScanDescriptionDto(values);
                // let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                // return self.save(newItem, changes)
                //     .then(result => {
                //         self.items.push(new FileScanDescriptionTaskDto(result));
                //     });

                const self = this;
                console.log('onGridInsert');
                let newItem = new FileScanDescriptionTaskDto(values);
                newItem.fileScanDescriptionTaskID = -self.items.length;
                return self.save(newItem,false);
            },
            onGridUpdate(key, values) {
                // const self = this;
                // let itemIndex = _.findIndex(self.items, item => _.parseNumber(_.get(item, self.itemKey), 0) === key);
                // if(itemIndex < 0) return self.onGridInsert(values);

                // let originalItem = _.cloneDeep(self.items[itemIndex]);
                // let updatedItem = new FileScanDescriptionTaskDto(_.assign({}, self.items[itemIndex], values));
                // let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                // return self.save(updatedItem, changes)
                //     .then(result => {
                //         self.items[itemIndex] = updatedItem;
                //     });


                const self = this;
                let itemIndex = _.findIndex(self.items, item => item.fileScanDescriptionTaskID === key);
                if(itemIndex < 0) return self.onGridInsert(values);
                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new FileScanDescriptionTaskDto(_.assign({}, self.items[itemIndex], values));
                updatedItem.isDirty = true;
                return self.save(updatedItem, false);
            },
        }
    }
</script>

<style lang="scss">
    .list-codes-container {
        .list-manager-container {
            width: 100%;
            max-width: 600px;
        }
    }
</style>
