<template>
    <rq-section-card v-show="sectionVisible" :title="label" collapsible>
        <div class="row">
            <div class="col-6">
                <div class="row" v-show="fieldVisible('buildStandardLanguagePrompts')">
                    <div class="col">Build Standard Language Prompts</div>
                    <div class="col">
                        <rq-switch type="stop-light"
                            :on="switchOn"
                            :off="switchOff"
                            size="md"
                            automation_id="au_buildStandardLanguagePrompts"
                            v-model="securityData.userGroupSecurity.canBuildCommitPolicyPrompts"
                            :disabled="isBuildStandardLanguagePromptsReadOnly"
                        />
                    </div>
                </div>
                <div class="row" v-show="fieldVisible('lockUnlockPolicyDates')">
                    <div class="col">Lock/Unlock Policy Dates</div>
                    <div class="col">
                        <rq-switch type="stop-light"
                            :on="switchOn"
                            :off="switchOff"
                            size="md"
                            automation_id="au_lockUnlockPolicyDates"
                            v-model="securityData.userGroupSecurity.canUnlockPolicyRemitDates"
                            :disabled="isLockUnlockPolicyDatesReadOnly"
                        />
                    </div>
                </div>
                <div class="row" v-show="fieldVisible('voidPolicies')">
                    <div class="col">Void Policies</div>
                    <div class="col">
                        <rq-switch type="stop-light"
                            :on="switchOn"
                            :off="switchOff"
                            size="md"
                            automation_id="au_voidPolicies"
                            v-model="securityData.userGroupSecurity.canVoidPolicies"
                            :disabled="isVoidPoliciesReadOnly"
                        />
                    </div>
                </div>
                <div class="row" v-show="fieldVisible('titleProductionSecurityPinRequired')">
                        <div class="col">Security PIN Required</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au__tp_securityPinRequired"
                                :modelValue="securityData.userGroupSecurity.titleProductionSecurityPinRequired"
                                v-model="securityData.userGroupSecurity.titleProductionSecurityPinRequired"
                            />
                        </div>
                    </div>
                <div class="row" v-show="fieldVisible('editingPolicyNumbers')">
                    <div class="col-7 drpdwnColumn form-group">
                        <label for="drp_policyNumberEditing">Editing Policy Numbers</label>
                        <rq-select-box
                            id="drp_policyNumberEditing"
                            :items="policyEditing"
                            v-model="securityData.userGroupSecurity.policyNumberEditing"
                            :disabled="isEditingPolicyNumbersReadOnly"
                        />
                    </div>
                </div>
                <div class="row" v-show="fieldVisible('creatingPolicyNumbers')">
                    <div class="col-7 drpdwnColumn form-group">
                        <label for="drp_policyNumberCreation">Creating Policy Numbers</label>
                        <rq-select-box
                            id="drp_policyNumberCreation"
                            :items="policyCreation"
                            v-model="securityData.userGroupSecurity.policyNumberCreation"
                            :disabled="isCreatingPolicyNumbersReadOnly"
                        />
                    </div>
                </div>
                <div class="row" v-show="fieldVisible('intentionallyDeletedClauses')">
                    <div class="col-7 drpdwnColumn form-group">
                        <label for="drp_intentionallyDeletedClauses">Intentionally Deleted Clauses</label>
                        <rq-select-box
                            id="drp_intentionallyDeletedClauses"
                            :items="intentionallyDeletedClausesOptions"
                            v-model="securityData.userGroupSecurity.intentionallyDeletedClauses"
                        />
                    </div>
                </div>
            </div>
        </div>
    </rq-section-card>
</template>
<script>
    import SecurityRoleSectionMixin from "./SecurityRoleSectionMixin";
    import { PolicyEditing, PolicyCreation, IntentionallyDeletedClausesOptions } from "@config/enums";
    export default {
        name: "TitlePolicySection",
        mixins: [SecurityRoleSectionMixin],
        data() {
            return {
                sectionFields: [
                    {
                        label: "Build Standard Language Prompts",
                        name: "buildStandardLanguagePrompts",
                        category: "Title Production Policy"
                    },
                    {
                        label: "Lock/Unlock Policy Dates",
                        name: "lockUnlockPolicyDates",
                        category: "Title Production Policy"
                    },
                    {
                        label: "Void Policies",
                        name: "voidPolicies",
                        category: "Title Production Policy"
                    },
                    {   label: "Security PIN Required",
                        name: "titleProductionSecurityPinRequired",
                        category: "Title Production Policy" },
                    {
                        label: "Editing Policy Numbers",
                        name: "editingPolicyNumbers",
                        category: "Title Production Policy"
                    },
                    {
                        label: "Creating Policy Numbers",
                        name: "creatingPolicyNumbers",
                        category: "Title Production Policy"
                    },
                    {
                        label: "Intentionally Deleted Clauses",
                        name: "intentionallyDeletedClauses",
                        category: "Title Production Policy"
                    }
                ]
            };
        },
        computed: {
            policyEditing() { return  PolicyEditing.lookupItems; },
            policyCreation() { return  PolicyCreation.lookupItems; },
            intentionallyDeletedClausesOptions() { return IntentionallyDeletedClausesOptions.lookupItems; },
            isBuildStandardLanguagePromptsReadOnly(){ return this.isAdmin || this.isSysAdmin; },
            isLockUnlockPolicyDatesReadOnly(){ return this.isAdmin || this.isSysAdmin; },
            isVoidPoliciesReadOnly(){ return this.isAdmin || this.isSysAdmin; },
            isEditingPolicyNumbersReadOnly(){ return this.isAdmin || this.isSysAdmin; },
            isCreatingPolicyNumbersReadOnly(){ return this.isAdmin || this.isSysAdmin; },
        },
         watch:{
            isSysAdmin: function(newValue){
                this.setDefaultValues();
            },
            isAdmin: function(newValue) {
                this.setDefaultValues();
            },
            isEscrowAdmin: function(newValue){
                this.setEscrowAdminDefaultValues();
            }
        },
        methods:{
            setDefaultValues(){
                const self = this;
                if(this.isAdmin ||self.isSysAdmin){
                    self.securityData.userGroupSecurity.canBuildCommitPolicyPrompts = true;
                    self.securityData.userGroupSecurity.canUnlockPolicyRemitDates = true;
                    self.securityData.userGroupSecurity.canVoidPolicies = true;
                    self.securityData.userGroupSecurity.titleProductionSecurityPinRequired = false;
                    self.securityData.userGroupSecurity.policyNumberEditing = PolicyEditing.NoRestriction;
                    self.securityData.userGroupSecurity.policyNumberCreation = PolicyCreation.NoRestriction;
                }
            },
            setEscrowAdminDefaultValues(){
                const self = this;
                if(this.isEscrowAdmin){
                    self.securityData.userGroupSecurity.canBuildCommitPolicyPrompts=false;
                    self.securityData.userGroupSecurity.canUnlockPolicyRemitDates=false;
                    self.securityData.userGroupSecurity.canVoidPolicies=false;
                    self.securityData.userGroupSecurity.titleProductionSecurityPinRequired = false;
                    self.securityData.userGroupSecurity.policyNumberEditing=PolicyEditing.CannotEdit;
                    self.securityData.userGroupSecurity.policyNumberCreation=PolicyCreation.CannotGeneratePolicyNumbers;
                }
            }
        }
    }
</script>