<template>
    <div class="rq-container">
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && v$.$error"
        />
        <div class="row">
            <div class="col col-6 form-group form-required" :class="{ 'has-error': v$.item.regionID.$error }">
                <label for="drp_region">Region</label>
                <dx-select-box
                    :input-attr="{ automation_id: 'drp_region', id: 'drp_region' }"
                    class="form-control"
                    :items="regions"
                    value-expr="regionID"
                    display-expr="displayName"
                    :search-enabled="true"
                    v-model="v$.item.regionID.$model"
                />
                <rq-validation-feedback>Region is required</rq-validation-feedback>
            </div>
            <div class="col col-6 form-group form-required" :class="{ 'has-error': v$.item.stateAssociated.$error }">
                <label for="drp_state">State</label>
                <dx-select-box
                    :input-attr="{ automation_id: 'drp_state', id: 'drp_state' }"
                    class="form-control"
                    :items="usStates"
                    value-expr="id"
                    display-expr="id"
                    :search-enabled="true"
                    v-model="v$.item.stateAssociated.$model"
                />
                <rq-validation-feedback>State is required</rq-validation-feedback>
            </div>
            <div class="col col-6 form-group  form-required" :class="{ 'has-error': v$.item.effectiveDate.$error }">
                <label for="dtp_effective_date">Effective Date</label>
                <rqdx-date-box
                    id="dtp_effective_date"
                    v-model="v$.item.effectiveDate.$model"
                />
                <rq-validation-feedback>Effective Date is required</rq-validation-feedback>
            </div>
            <div class="col col-6 form-group form-required" :class="{ 'has-error': v$.item.effectiveBasedOn.$error }">
                <label for="drp_effective_date_based_on">Effective Date Based On</label>
                <dx-select-box
                    :input-attr="{ automation_id: 'drp_effective_date_based_on', id: 'drp_effective_date_based_on' }"
                    class="form-control"
                    :items="effectiveDateBasedOnOptions"
                    value-expr="id"
                    display-expr="name"
                    :search-enabled="true"
                    v-model="v$.item.effectiveBasedOn.$model"
                />
                <rq-validation-feedback>Effective Date Based On is required</rq-validation-feedback>
            </div>
            <div class="col col-12 form-group form-required" :class="{ 'has-error': v$.item.description.$error }">
                <label for="txt_description">Description</label>
                <input
                    id="txt_description"
                    automation_id="txt_description"
                    type="text"
                    class="form-control"
                    placeholder="Description"
                    maxlength="250"
                    v-model="v$.item.description.$model"
                >
                <rq-validation-feedback>Description is required</rq-validation-feedback>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { required } from "validators";
    import { useVuelidate } from "@vuelidate/core";
    import { TransferTaxEffectiveDateBasedOn }  from "../../enums";

    export default {
        name: 'TransferTaxConfigurationFormAdd',
        props: {
            item: {type: Object, default: null}
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                showBanner: false
            }
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            usStates() { return this.lookupHelpers.getStates(); },
            regions() { return this.lookupHelpers.getRegions(); },
            effectiveDateBasedOnOptions() { return TransferTaxEffectiveDateBasedOn.lookupItems; }
        },

        validations: () => ({
            item: {
                regionID: { required },
                description: { required },
                effectiveDate: { required },
                effectiveBasedOn: { required },
                stateAssociated: { required }
            }
        }),

        methods: {
            isValid() {
                this.v$.$touch();
                this.showBanner = true;
                return !this.v$.$error;
            }
        }
    }
</script>