<template>
    <div class="content-wrapper">
        <rq-page-section :title="itemTypeNamePlural" headerSize="lg" borderless>
             <template #header-actions>
                <ul class="nav config-actions ms-auto">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_cancel"
                            variant="action"
                            v-rq-tooltip.hover.top title="Discard changes and reload data"
                            @click="onCancel"
                            :disabled="readOnly">Cancel
                        </b-btn>
                    </li>
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_save"
                            variant="action"
                            @click="onSave"
                            :disabled="readOnly">Save
                        </b-btn>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
            <div class="row mb-1">
                <div class="col-auto ms-auto">
                    <rq-section-expand-collapse-all />
                </div>
            </div>
            <rq-section-card title="Default Account Codes" collapsible>
                <div class="row">
                    <div class="col col-4 col-sm-12 col-md-6 col-lg-4 form-group">
                        <label for="drp_loanAccountingCode">Loan Account Code</label>
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_loanAccountingCode', id: 'drp_loanAccountingCode' }"
                            :items="accountingCodes(systemDefaults.rate_DefaultLoanAccountingCode)"
                            value-expr="id"
                            display-expr="name"
                            v-model="systemDefaults.rate_DefaultLoanAccountingCode"
                            :search-enabled="true"
                            :disabled="readOnly"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col col-4 col-sm-12 col-md-6 col-lg-4 form-group">
                        <label for="drp_ownerAccountingCode">Owner Account Code</label>
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_ownerAccountingCode', id: 'drp_ownerAccountingCode' }"
                            :items="accountingCodes(systemDefaults.rate_DefaultOwnerAccountingCode)"
                            value-expr="id"
                            display-expr="name"
                            v-model="systemDefaults.rate_DefaultOwnerAccountingCode"
                            :search-enabled="true"
                            :disabled="readOnly"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col col-4 col-sm-12 col-md-6 col-lg-4 form-group">
                        <label for="drp_ownerAccountingCode">Underwriter Account Code</label>
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_underwriterAccountingCode', id: 'drp_underwriterAccountingCode' }"
                            :items="accountingCodes(systemDefaults.rate_DefaultUnderwriterAccountingCode)"
                            value-expr="id"
                            display-expr="name"
                            v-model="systemDefaults.rate_DefaultUnderwriterAccountingCode"
                            :search-enabled="true"
                            :disabled="readOnly"
                        />
                    </div>
                </div>
            </rq-section-card>
            <rq-section-card title="Other Settings" collapsible>
                <div class="row">
                    <div class="col col-4 col-sm-12 col-md-6 col-lg-4 form-group label-lg">
                        <b-form-checkbox
                            automation_id="chk_rate_engine_use_web_service"
                            id="chk_rate_engine_use_web_service"
                            v-model="systemDefaults.rateEngineUseWebService"
                            :disabled="readOnly">Calculate Recording Fees Taxes using Web Service</b-form-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-4 col-sm-12 col-md-6 col-lg-4 form-group">
                        <label class="form-control-label" for="txt_state_exemption_percentage">State Level Exemption Percentage</label>
                        <div class="input-group">
                            <rqInputNumber v-model="systemDefaults.stateExemptionPercentage" defaultValue="0" decimals="2" minValue="0" maxValue="100" cssClass="form-control" automation_id="txt_state_exemption_percentage" :disabled="readOnly"></rqInputNumber>
                            <span class="input-group-text">%</span>
                        </div>
                    </div>
                    <div class="col col-4 col-sm-12 col-md-6 col-lg-4 form-group">
                        <label class="form-control-label" for="txt_city_exemption_percentage">City Level Exemption Percentage</label>
                        <div class="input-group">
                            <rqInputNumber v-model="systemDefaults.cityExemptionPercentage" defaultValue="0" decimals="2" minValue="0" maxValue="100" cssClass="form-control" automation_id="txt_city_exemption_percentage" :disabled="readOnly"></rqInputNumber>
                            <span class="input-group-text">%</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-4 col-sm-12 col-md-6 col-lg-4 checkbox-group label-lg">
                        <b-form-checkbox
                            automation_id="chk_display_maryland_taxes"
                            id="chk_display_maryland_taxes"
                            v-model="systemDefaults.displayMarylandTaxes"
                            :disabled="readOnly">Show Maryland Seller Tax Fields</b-form-checkbox>
                        <b-form-checkbox
                            automation_id="chk_display_maryland_taxes"
                            id="chk_display_cfpb_rate_ralculations"
                            v-model="systemDefaults.displayCFPBRateRalculationsOnCDF"
                            :disabled="readOnly">Display CFPB Rate Calculations on CDF</b-form-checkbox>
                    </div>
                    <div class="col col-4 col-sm-8 col-md-8 col-lg-8 checkbox-group label-lg">
                        <b-form-checkbox
                            automation_id="chk_allow_create_checks_for_premium_splits"
                            id="chk_allow_create_checks_for_premium_splits"
                            v-model="systemDefaults.allowCreateChecksForPremiumSplits"
                            :disabled="readOnly">Create Checks for Premium Splits</b-form-checkbox>
                        <b-form-checkbox
                            automation_id="chk_is_create_auto_debit_credit"
                            id="chk_is_create_auto_debit_credit"
                            v-model="systemDefaults.isCreateAutoDebitCreditOnCDFForSellerPaidOTP"
                            :disabled="readOnly">Create Auto Debit/Credit on CDF for Seller Paid OTP</b-form-checkbox>
                    </div>
                </div>
            </rq-section-card>
         </rq-page-section>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import SystemDefaultsFormMixin from "../../shared/SystemDefaultsFormMixin";

    export default {
        name: "RateEngineSettingsForm",
        mixins: [SystemDefaultsFormMixin],
        computed: {
            ...mapState({
                globalRegionId: state => state.system.globalRegionId,
                readOnly: state => state.isPageReadOnly,
            }),
            ...mapGetters([ "lookupHelpers", "lookupItems" ])
        },
        methods :{
            // pass in global region id to get all account codes
            accountingCodes(code) {return this.lookupHelpers.getLookupItems(this.lookupItems.ACCOUNTING_CODES, this.globalRegionId, code); }
        }
    }
</script>
