<template>
    <div class="rq-container">
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && v$.$error"
        />
        <div class="row">
            <div class="col col-6 form-group form-required" :class="{ 'has-error': v$.item.processTemplateName.$error || v$.item.uniqueWorkflowTemplate.$invalid }">
                <label for="txt_process_template_name">Process Template Name</label>
                <input
                    id="txt_process_template_name"
                    automation_id="txt_process_template_name"
                    type="text"
                    class="form-control"
                    placeholder="Process Template Name"
                    maxlength="50"
                    v-model="v$.item.processTemplateName.$model"
                >
                <rq-validation-feedback
                        :messages="{
                            'Process Template Name is required.': v$.item.processTemplateName.$error,
                            'A Process Template already exists with that name.': v$.item.uniqueWorkflowTemplate.$invalid
                        }"
                    />
            </div>
            <div class="col col-6 form-group form-required" :class="{ 'has-error': v$.item.regionID.$error }">
                <label for="drp_region">Region</label>
                <dx-select-box
                    :input-attr="{ automation_id: 'drp_region', id: 'drp_region' }"
                    :items="regions"
                    class="form-control"
                    value-expr="regionID"
                    display-expr="displayName"
                    :search-enabled="true"
                    v-model="v$.item.regionID.$model"
                />
                <rq-validation-feedback>Region is required</rq-validation-feedback>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { required } from "validators";
    import { useVuelidate } from "@vuelidate/core";
    import { WorkflowProcessTemplateDto }  from "../models";

    export default {
        name: 'WorkflowProcessTemplateFormAdd',
        props: {
            item: {type: Object, default: null},
            uniqueValidator: { type: Function, default: (() => true) }
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                showBanner: false
            }
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            regions() { return this.lookupHelpers.getRegions(); }
        },
        validations: () => ({
            item: {
                processTemplateName: { required },
                regionID: { required },
                uniqueWorkflowTemplate: (val, vm) => vm.uniqueValidator(val)
            }
        }),
        methods: {
            isValid() {
                this.v$.$touch();
                this.showBanner = true;
                return !this.v$.$error;
            }
        }
    }
</script>