<template>
    <div class="content-wrapper">
        <rq-page-section :title="itemTypeNamePlural" borderless header-size="lg">
            <template #header-actions>
                <ul class="nav config-actions ms-auto">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_cancel"
                            variant="action"
                            v-rq-tooltip.hover.top title="Discard changes and reload data"
                            @click="onCancel"
                            :disabled="readOnly">Cancel
                        </b-btn>
                    </li>
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_save"
                            variant="action"
                            @click="onSave"
                            :disabled="readOnly">Save
                        </b-btn>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
            <!-- <div class="row">
                <div class="col-md-3 col-lg-3 col-xl-3 form-group label-lg">
                    <b-form-checkbox automation_id="chk_lossApprovalEnabled" id="lossApprovalEnabled" v-model="systemDefaults.lossApprovalEnabled" :disabled="readOnly">Loss Approval Threshold</b-form-checkbox>
                </div>
            </div> -->
            <div class="row">
            <!-- <div class="row" v-if="systemDefaults.lossApprovalEnabled">
                <div class="col-md-3 col-lg-3 col-xl-3 form-group ms-3">
                    <label for="lossApprovalThreshold">Loss Approval Threshold</label>
                    <rqInputNumber
                        id="lossApprovalThreshold"
                        automation_id="txt_lossApprovalThreshold"
                        formatType="money"
                        defaultValue="0"
                        :minValue="0"
                        decimals="2"
                        input-group
                        no-prefix
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="systemDefaults.lossApprovalThreshold"
                        :disabled="readOnly"/>
                </div> -->
                <div class="col-md-3 col-lg-3 col-xl-3 form-group">
                    <label for="lossApprovalOverAccountCodeID">Loss Account Code Over Threshold</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_lossApprovalOverAccountCodeID', id: 'lossApprovalOverAccountCodeID' }"
                        :items="lossOverAccountCodes"
                        value-expr="id"
                        display-expr="name"
                        v-model="systemDefaults.lossApprovalOverAccountCodeID"
                        :search-enabled="true"
                        :disabled="readOnly"
                    />
                </div>
                <div class="col-md-3 col-lg-3 col-xl-3 form-group">
                    <label for="lossApprovalUnderAccountCodeID">Loss Account Code Under Threshold</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_lossApprovalUnderAccountCodeID', id: 'lossApprovalUnderAccountCodeID' }"
                        :items="lossUnderAccountCodes"
                        value-expr="id"
                        display-expr="name"
                        v-model="systemDefaults.lossApprovalUnderAccountCodeID"
                        :search-enabled="true"
                        :disabled="readOnly"
                    />
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-md-3 col-lg-3 col-xl-3 form-group label-lg">
                    <b-form-checkbox automation_id="chk_advanceApprovalEnabled" id="advanceApprovalEnabled" v-model="systemDefaults.advanceApprovalEnabled" :disabled="readOnly">Advance Approval Threshold</b-form-checkbox>
                </div>
            </div> -->
            <div class="row">
            <!-- <div class="row" v-if="systemDefaults.advanceApprovalEnabled">
                <div class="col-md-3 col-lg-3 col-xl-3 form-group ms-3">
                    <label for="advanceApprovalThreshold">Advance Approval Threshold</label>
                    <rqInputNumber
                        id="advanceApprovalThreshold"
                        automation_id="txt_advanceApprovalThreshold"
                        formatType="money"
                        defaultValue="0"
                        :minValue="0"
                        decimals="2"
                        input-group
                        no-prefix
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="systemDefaults.advanceApprovalThreshold"
                        :disabled="readOnly"/>
                </div> -->
                <div class="col-md-3 col-lg-3 col-xl-3 form-group">
                    <label for="advanceApprovalOverAccountCodeID">Advance Account Code Over Threshold</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_advanceApprovalOverAccountCodeID', id: 'advanceApprovalOverAccountCodeID' }"
                        :items="advanceOverAccountCodes"
                        value-expr="id"
                        display-expr="name"
                        v-model="systemDefaults.advanceApprovalOverAccountCodeID"
                        :search-enabled="true"
                        :disabled="readOnly"
                    />
                </div>
                <div class="col-md-3 col-lg-3 col-xl-3 form-group">
                    <label for="advanceApprovalUnderAccountCodeID">Advance Account Code Under Threshold</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_advanceApprovalUnderAccountCodeID', id: 'advanceApprovalUnderAccountCodeID' }"
                        :items="advanceUnderAccountCodes"
                        value-expr="id"
                        display-expr="name"
                        v-model="systemDefaults.advanceApprovalUnderAccountCodeID"
                        :search-enabled="true"
                        :disabled="readOnly"
                    />
                </div>
            </div>
        </rq-page-section>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import SystemDefaultsFormMixin from "../../shared/SystemDefaultsFormMixin";

    export default {
        name: "EscrowAccountSettingsForm",
        mixins: [SystemDefaultsFormMixin],
        computed: {
            ...mapState({
                readOnly: state => state.isPageReadOnly,
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            lossOverAccountCodes() { return this.lookupHelpers.getLookupItems(this.lookupItems.ACCOUNTING_CODES, null, this.systemDefaults.lossApprovalOverAccountCodeID); },
            lossUnderAccountCodes() { return this.lookupHelpers.getLookupItems(this.lookupItems.ACCOUNTING_CODES, null, this.systemDefaults.lossApprovalUnderAccountCodeID); },
            advanceOverAccountCodes() { return this.lookupHelpers.getLookupItems(this.lookupItems.ACCOUNTING_CODES, null, this.systemDefaults.advanceApprovalOverAccountCodeID); },
            advanceUnderAccountCodes() { return this.lookupHelpers.getLookupItems(this.lookupItems.ACCOUNTING_CODES, null, this.systemDefaults.advanceApprovalUnderAccountCodeID); },
        }
    }
</script>
