<template>
    <rq-section-card v-show="sectionVisible" :title="label" collapsible>
        <div class="row">
            <div class="col-2 col-sm-6">
                <rq-page-section  title="Escrow Administrator Options" headerSize="sm" borderless v-show="categoryVisible('Escrow Administrator Options')">
                    <div class="row" v-show="fieldVisible('makeChangesInChecksDepositsBrower')">
                        <div class="col">Make Changes in Disbursement/Receipt Browsers</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_makeChangesInChecksDepositsBrower"
                                v-model="securityData.userGroupSecurity.allowEscrowAdminBrowserChange"
                                :disabled="isMakeChangesInChecksDepositBrowserReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row"  v-show="fieldVisible('accessToQuickCheckDepositEntry')">
                        <div class="col">Access to Quick Disbursement/Receipt Entry</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_accessToQuickCheckDepositEntry"
                                v-model="securityData.userGroupSecurity.allowAccessToQuickCheckQuickDepositEntry"
                                :disabled="isAccessToQuickCheckDepositEntryReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('modificationsToFinalEscrow')">
                        <div class="col">Modifications to Finalized Reconciliations</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_modificationsToFinalEscrow"
                                v-model="securityData.userGroupSecurity.allowFinalizedReconModifications"
                                :disabled="isModificationsToFinalEscrowReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('accessConsolidatedChecks')">
                        <div class="col">Consolidated Checks</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_accessConsolidatedChecks"
                                v-model="securityData.userGroupSecurity.canAccessConsolidatedChecks"
                                :disabled="isConsolidatedChecksReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('enterEditAccountingEscrowMemo')">
                        <div class="col">Enter/Edit Escrow Memo</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_enterEditAccountingEscrowMemo"
                                v-model="securityData.userGroupSecurity.canEnterEditAccountingEscrowMemo"
                                :disabled="isEnterEditEscrowAccountingMemoReadOnly"
                            />
                        </div>
                    </div>
                </rq-page-section>
                <rq-page-section  title="Escrow Options" headerSize="sm" borderless v-show="categoryVisible('Escrow Options')">
                    <div class="row" v-show="fieldVisible('changeCheckToWireEscrowAccounting')">
                        <div class="col">Change Disbursement to Wire (Escrow Accounting)</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_changeCheckToWireEscrowAccounting"
                                v-model="securityData.userGroupSecurity.allowChangeCheckToWire"
                                :disabled="isChangeChecktoWireEscrowAccountingReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('deleteChecksEscrowAccounting')">
                        <div class="col">Delete Disbursements (Escrow Accounting)</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_deleteChecksEscrowAccounting"
                                v-model="securityData.userGroupSecurity.allowDeleteCheckFromEscrowAccounting"
                                :disabled="isDeleteChecksEscrowAccountingReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('changeCheckNumber')">
                        <div class="col">Change Check Number</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_changeCheckNumber"
                                v-model="securityData.userGroupSecurity.allowCheckNumberChange"
                                :disabled="isChangeCheckNumberReadOnly"
                            />
                        </div>
                    </div>
                </rq-page-section>
            </div>
            <div class="col-2 col-sm-6">
                <rq-page-section  title="Positive Pay" headerSize="sm" borderless v-show="categoryVisible('Positive Pay')">
                    <div class="row" v-show="fieldVisible('canEditPositivePay')">
                        <div class="col">Edit</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_canEditPositivePay"
                                v-model="securityData.userGroupSecurity.canEditPositivePay"
                                :disabled="isPositivePayEditReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('canProcessPositivePay')">
                        <div class="col">Process</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_canProcessPositivePay"
                                v-model="securityData.userGroupSecurity.canProcessPositivePay"
                                :disabled="isPositivePayProcessReadOnly"
                            />
                        </div>
                    </div>
                </rq-page-section>
                <rq-page-section  title="eBank Recon" headerSize="sm" borderless v-show="categoryVisible('EBank Recon')">
                    <div class="row" v-show="fieldVisible('canProcessEBank')">
                        <div class="col">Process</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_process"
                                v-model="securityData.userGroupSecurity.canProcessEBankRecon"
                                :disabled="isPositivePayeBankReadOnly"
                            />
                        </div>
                    </div>
                </rq-page-section>
            </div>
        </div>
    </rq-section-card>
</template>
<script>
    import SecurityRoleSectionMixin from "./SecurityRoleSectionMixin";
    export default {
        name: "EscrowSection",
        mixins: [SecurityRoleSectionMixin],
        data() {
            return {
                sectionFields: [
                    { label: "Make Changes in Disbursement/Receipt Browsers", name: "makeChangesInChecksDepositsBrower", category: "Escrow Administrator Options" },
                    { label: "Access to Quick check/Deposit Entry", name: "accessToQuickCheckDepositEntry", category: "Escrow Administrator Options" },
                    { label: "Modifications to Finalized Reconciliations", name: "modificationsToFinalEscrow", category: "Escrow Administrator Options" },
                    { label: "Consolidated Checks", name: "accessConsolidatedChecks", category: "Escrow Administrator Options" },
                    { label: "Enter/Edit Escrow Memo", name: "enterEditAccountingEscrowMemo", category: "Escrow Administrator Options" },
                    { label: "Change Disbursement to Wire (Escrow Accounting)", name: "changeCheckToWireEscrowAccounting", category: "Escrow Options" },
                    { label: "Delete Disbursements (Escrow Accounting)", name: "deleteChecksEscrowAccounting", category: "Escrow Options" },
                    { label: "Change Check Number", name: "changeCheckNumber", category: "Escrow Options" },
                    { label: "Edit", name: "canEditPositivePay", category: "Positive Pay" },
                    { label: "Process", name: "canProcessPositivePay", category: "Positive Pay" },
                    { label: "Process", name: "canProcessEBank", category: "EBank Recon" }
                ]
            };
        },
        computed: {
            isMakeChangesInChecksDepositBrowserReadOnly(){ return this.isSysAdmin || (this.isEscrowAdmin && !this.isAdmin); },
            isAccessToQuickCheckDepositEntryReadOnly(){ return this.isSysAdmin || (this.isEscrowAdmin && !this.isAdmin); },
            isModificationsToFinalEscrowReadOnly(){ return this.isSysAdmin || (this.isEscrowAdmin && !this.isAdmin); },
            isConsolidatedChecksReadOnly(){ return this.isSysAdmin || (this.isEscrowAdmin && !this.isAdmin); },
            isChangeChecktoWireEscrowAccountingReadOnly(){ return this.isSysAdmin || (this.isEscrowAdmin && !this.isAdmin); },
            isDeleteChecksEscrowAccountingReadOnly(){ return this.isSysAdmin || (this.isEscrowAdmin && !this.isAdmin); },
            isEnterEditEscrowAccountingMemoReadOnly() { return this.isSysAdmin || (this.isEscrowAdmin && !this.isAdmin); },
            isChangeCheckNumberReadOnly(){ return this.isSysAdmin || (this.isEscrowAdmin && !this.isAdmin); },
            isPositivePayProcessReadOnly(){ return this.isSysAdmin || (this.isEscrowAdmin && !this.isAdmin); },
            isPositivePayEditReadOnly(){ return this.isSysAdmin || (this.isEscrowAdmin && !this.isAdmin); },
            isPositivePayeBankReadOnly(){ return this.isSysAdmin || (this.isEscrowAdmin && !this.isAdmin); },
        },
        watch: {
            isEscrowAdmin: function() {
                this.setDefaultValues();
            },
            isAdmin: function(newValue){
                this.setDefaultValues();
            },
            isSysAdmin: function(newValue){
                this.setDefaultValues();
            },
        },
        methods:{
            setDefaultValues(){
                const self = this;
                // Escrow Admin
                if(self.isSysAdmin || self.isAdmin || self.isEscrowAdmin){
                    self.securityData.userGroupSecurity.allowEscrowAdminBrowserChange = true;
                    self.securityData.userGroupSecurity.allowAccessToQuickCheckQuickDepositEntry = true;
                    self.securityData.userGroupSecurity.allowFinalizedReconModifications = true;
                    self.securityData.userGroupSecurity.canAccessConsolidatedChecks = true;
                    self.securityData.userGroupSecurity.canEnterEditAccountingEscrowMemo = true;
                    self.securityData.userGroupSecurity.allowChangeCheckToWire = true;
                    self.securityData.userGroupSecurity.allowDeleteCheckFromEscrowAccounting = true;
                    self.securityData.userGroupSecurity.allowCheckNumberChange = true;
                    self.securityData.userGroupSecurity.canEditPositivePay = true;
                    self.securityData.userGroupSecurity.canProcessPositivePay = true;
                    self.securityData.userGroupSecurity.canProcessEBankRecon = true;
                }
            },
        }
    }
</script>
