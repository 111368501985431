<template>
    <div class="content-wrapper">
        <rq-page-section :title="itemTypeNamePlural" borderless headerSize="lg">
            <template #header-actions>
                <ul class="nav config-actions ms-auto">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_cancel"
                            variant="action"
                            v-rq-tooltip.hover.top title="Discard changes and reload data"
                            @click="onCancel"
                            :disabled="readOnly">Cancel
                        </b-btn>
                    </li>
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_save"
                            variant="action"
                            @click="onSave"
                            :disabled="readOnly">Save
                        </b-btn>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
            <div class="row mb-1">
                <div class="col-auto ms-auto">
                    <rq-section-expand-collapse-all />
                </div>
            </div>
            <rq-section-card title="Endpoints" collapsible>
                <div class="row">
                    <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                        <label for="txt_plugin_url">Plug-in Settings URL</label>
                        <input automation_id="txt_plugin_url" v-model="systemDefaults.cmPluginURL" type="text" class="form-control" id="txt_plugin_url" placeholder="Plug-in Settings URL" maxlength="255" :disabled="readOnly">
                    </div>
                </div>
                <div class="row">
                    <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                        <label for="txt_action_service_url">Action Service Settings URL</label>
                        <input automation_id="txt_action_service_url" v-model="systemDefaults.cmActionServiceURL" type="text" class="form-control" id="txt_action_service_url" placeholder="Action Service Settings URL" maxlength="1000" :disabled="readOnly">
                    </div>
                </div>
            </rq-section-card>
            <rq-section-card title="Service" collapsible>
                <closing-market-web-defaults-form ref="webDefaults"/>
            </rq-section-card>
         </rq-page-section>
         <rq-page-section>
            <rq-section-card title="Enterprise ID" collapsible>
                <div class="row">
                    <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                        <label for="txt_enterprise_id">CM Enterprise ID</label>
                        <rq-input-number id="txt_enterprise_id" v-model="systemDefaults.enterpriseID" type="text" maxlength="10" :disabled="readOnly" :commas="false" allow-null />
                    </div>
                </div>
                <div class="row" v-if="showEnterpriseGuid">
                    <div :class="{ 'col col-6 col-sm-12 col-md-6 col-lg-6 form-group':true, 'has-error': v$.systemDefaults.cmApiEnterpriseGuid.$error }">
                        <label for="txt_cm_api_enterprise_guid">CM Api Enterprise Guid</label>
                        <input id="txt_cm_api_enterprise_guid" v-model="v$.systemDefaults.cmApiEnterpriseGuid.$model" type="text" class="form-control" maxlength="36" :disabled="readOnly || !user.isTenantAdmin"/>
                        <rq-validation-feedback :messages="{ 'Invalid GUID': v$.systemDefaults.cmApiEnterpriseGuid.isValid.$invalid}" />
                    </div>
                    <div class="col col-6 col-sm-12 col-md-6 col-lg-6 pt-4" v-if="showGetEnterpriseInfo">
                        <b-btn
                            automation_id="btn_get_enterprise_info"
                            variant="theme"
                            @click="onGetEnterpriseInfo">Get Info</b-btn>
                    </div>
                </div>
            </rq-section-card>

         </rq-page-section>
    </div>
</template>
<script>
    import { mapState } from "vuex";
    import { useVuelidate } from "@vuelidate/core";
    import SystemDefaultsFormMixin from "../../shared/SystemDefaultsFormMixin";
    import ClosingMarketWebDefaultsForm from './ClosingMarketWebDefaultsForm';
    import CmEnterpriseInfo from "../../../../components/CmEnterpriseInfo";
    import { useLicenseStore } from "@/store/modules/license";

    export default {
        name: "ClosingMarketIntegrationForm",
        components: {
            ClosingMarketWebDefaultsForm,
        },
        data() {
            return {
                isValidEnterpriseKey: true,
                showEnterpriseGuid: false
            }
        },
        mixins: [SystemDefaultsFormMixin],
        setup: () => ({ v$: useVuelidate() }),
        computed: {
            ...mapState({
                readOnly: state => state.isPageReadOnly,
                user: state => state.authentication.session.user,
            }),
            showGetEnterpriseInfo() {
                return _.size(_.trim(this.systemDefaults.cmApiEnterpriseGuid)) > 0;
            },
        },
        created() {
            const licenseStore = useLicenseStore();
            this.showEnterpriseGuid = licenseStore.checkFeature('eRemit') || licenseStore.checkFeature('eRemitFragment');
        },
        validations() {
            const self = this;
            var result = {
                systemDefaults: {
                    cmApiEnterpriseGuid: {
                        isValid(val) {
                            return self.isValidEnterpriseKey;
                        },
                    },
                }
            }
            return result;
        },
        methods: {
            restoreAdditionalData() {
                return this.$refs.webDefaults.restoreOriginalData();
            },
            saveAdditional() {
                return this.$refs.webDefaults.save();
            },
            showEnterpriseInfoModal(item) {
                const self = this;
                self.$dialog.open({
                    title: `Enterprise Info`,
                    width: "500",
                    adaptive: true,
                    closeOnEsc: true,
                    component: CmEnterpriseInfo,
                    props: {
                        item: item
                    },
                    closeOnly: true,
                    closeTitle: "Close"
                });
            },
            hasAdditionalChanges() {
                return this.$refs.webDefaults.hasChanges();
            },
            async onGetEnterpriseInfo() {
                const self = this;
                self.isValidEnterpriseKey = true;
                try {
                    let item = await self.$api.CmERemitApi.getEnterprise(self.systemDefaults.cmApiEnterpriseGuid);
                    if (_.getNumber(item, "enterpriseID", 0) > 0) {
                        self.showEnterpriseInfoModal(item);
                    } else {
                        self.isValidEnterpriseKey = false;
                    }
                }
                catch(err) {
                    self.isValidEnterpriseKey = false;
                }
                self.v$.$touch();
            },
        }
    }
</script>