<template>
    <rqdx-action-data-grid
        ref="dataGrid"
        :automation_id="elementName('tbl')"
        :actions="selectionActions"
        :config="gridConfig"
        :data-source="gridDataSource"
        :export-file-name="elementName('', 'data')"
        @edit="onEditItem"
        @delete="onDeleteItem"
        @rowDoubleClick="onEditItem"
        hide-show-column-chooser
        integrated-search
        rq-filters
    />
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { EscrowUnitDto, EscrowUnitCheckDto }  from "../models";
    import EscrowUnitsForm  from "./EscrowUnitsForm.vue";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name:"EscrowUnitsList",
        data () {
            return {
                items: [],
                users: [],
                validationErrors: [],
                addEventName: "",
                dataLoaded: false
            };
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
            ]),
            ...mapState({
                user: state => state.authentication.session.user
            }),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            itemKey() { return _.get(this.$route.meta, "itemKey") || ""; },
            itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            itemTypeDescription() { return _.get(this.$route.meta, "itemTypeDescription") || ""; },
            selectionActions() {
                return [{ name: "edit", text: "Edit", eventName: "edit", requireSelection: true, tooltip: `Edit ${this.itemTypeName}` },
                        { name: "delete", text: "Delete", eventName: "delete", requireSelection: true, allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}` }];
            },
            regions() { return this.lookupHelpers.getRegions(); },
            filePools() { return this.lookupHelpers.getLookupItems(this.lookupItems.FILE_NUMBER_POOLS); },
        },

        created() {
            const self = this;
            self.initGridConfig();
            self.initListeners();
        },

        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },

        methods: {

            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "regionID",
                            dataType: "number",
                            caption: "Region",
                            lookup: {
                                displayExpr: "displayName",
                                valueExpr: "regionID",
                                dataSource: self.regions
                            }
                        },
                        { dataField: "shortDescription", dataType: "string", caption: "Name" },
                        { dataField: "description", dataType: "string" },
                        {
                            dataField: "fileNumberPoolID",
                            dataType: "number",
                            caption: "File Pool",
                            lookup: {
                                displayExpr: "name",
                                valueExpr: "id",
                                dataSource: self.filePools
                            }
                        },
                        { dataField: "prefix", dataType: "string" },
                        { dataField: "suffix", dataType: "string" },
                        {
                            dataField: "leaderUsersID",
                            dataType: "number",
                            caption: "Leader",
                            lookup: {
                                dataSource: {
                                    load: () => self.users
                                },
                                displayExpr: "name",
                                valueExpr: "id"
                            }
                        }
                    ],
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load: self.fetchData
                };
            },

            addNewItem() {
                const self = this;
                self.gridInstance.deselectAll();
                self.loadItem(new EscrowUnitDto({regionID: self.user.regionID}));
            },

            onAddItem() {
                this.addNewItem();
            },

            onEditItem(e) {
                const self = this;
                self.loadItem(new EscrowUnitDto(e.data));
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let apiPromises = [];
                    _.forEach(items, (item) => {
                        let key = _.get(item, self.itemKey);
                        apiPromises.push(self.$api.EscrowUnitsApi.deleteEscrowUnit(key));
                    });
                    return self.$rqBusy.wait(Promise.all(apiPromises))
                        .then(key => {
                            let message = key.length > 1 ? `${key.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`;
                            self.$toast.success(message);
                            return true;}
                        )
                        .catch(error => {
                          if (error.message.includes('400')) {
                                 self.$dialog.messageBox(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error(`Error deleting ${self.itemTypeName}.`);
                            }
                            return error;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }

                 self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelEscrow: 'No', okEscrow: 'Yes'});
            },

            fetchData() {
                const self = this;
                let getUsersPromise = self.dataLoaded
                    ? Promise.resolve([])
                    : self.$api.UsersApi.getUsers();

                let apiPromise = Promise.all([
                    self.$api.EscrowUnitsApi.getEscrowUnits(),
                    getUsersPromise
                ]);

                return self.$rqBusy.wait(apiPromise)
                    .then(results => {
                        self.items = _.map(results[0], i => new EscrowUnitDto(i));

                        if(!self.dataLoaded)
                            self.users =  _.map(results[1], u => ({ id: u.usersID, name: u.displayName }));

                        self.dataLoaded = true;
                        return { data: self.items, totalCount: self.items.length };
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },

            loadItem(item) {
                const self = this;
                let dialogId = null;
                let refreshOnExit = false;
                let onOk = (e, addAnother=false) => {
                    return e.component.save()
                        .then(success => {
                            if(!success) return false;
                            if (addAnother) {
                                refreshOnExit = true;
                                self.$dialog.reloadComponent({ dialogId, props: { item: new EscrowUnitDto({regionID: self.user.regionID}) } });
                                return false;
                            }
                            self.refresh();
                            return success;
                        });
                };

                let onCancel = e => {
                    if(!refreshOnExit) return true;
                    self.refresh();
                }
                dialogId = self.$dialog.open({
                    title: `${item.isNew ? "Add": "Edit"} ${self.itemTypeName}${(item.isNew ? "" : `: ${item.description}`)}`,
                    width: "1200",
                    adaptive: true,
                    component: EscrowUnitsForm,
                    props: {
                        item,
                        uniqueValidator: (item,field) => !self.isDuplicate(item, field),
                    },
                    buttons: [
                        { title: "Cancel", automationId: "btn_dm_modal_cancel", cssClass: "btn btn-secondary", onClick: onCancel },
                        { title: "Save and Add Another", automationId: "btn_dm_modal_save_and_another", cssClass: "btn btn-primary", isVisible: item.isNew, onClick: (e) => { return onOk(e, true); } },
                        { title: "Save", automationId: "btn_dm_modal_save", cssClass: "btn btn-primary", onClick: (e) => { return onOk(e, false); } }
                    ]
                });
            },

            refresh() {
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },

            addItem(item) {
                this.items.push(new EscrowUnitDto(item));
            },

            isDuplicate(item, fieldName){
                const self = this;
                let trimLower = val => _.toLower(_.trim(val));
                return _.some(self.items, unit =>
                    trimLower(unit[fieldName]) === trimLower(item[fieldName])
                    && _.parseNumber(unit.regionID, -1) === _.parseNumber(item.regionID, -1)
                    && _.parseNumber(unit[self.itemKey], -1) !== _.parseNumber(item[self.itemKey], -1)
                );
            },

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },
        }
    }
</script>