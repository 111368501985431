<template>
    <div class="content-wrapper">
        <rq-page-section :title="itemTypeNamePlural" headerSize="lg" borderless>
             <template #header-actions>
                <ul class="nav config-actions ms-auto">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_save"
                            variant="action"
                            v-rq-tooltip.hover.top title="Discard changes and reload data"
                            @click="onSave"
                            :disabled="readOnly">Save</b-btn>
                    </li>
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_cancel"
                            variant="action"
                            @click="onCancel"
                            :disabled="readOnly">Cancel</b-btn>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
            <div class="row mb-1">
                <div class="col-auto ms-auto">
                    <rq-section-expand-collapse-all />
                </div>
            </div>
            <rq-section-card title="Wire Details" collapsible>
                <div class="row">
                    <div class="col col-12 col-sm-4 col-md-4 col-lg-4 ps-5 label-lg">
                        <b-form-checkbox
                            class="d-inline"
                            automation_id="chk_wireOutWireNumberRequired"
                            id="wireOutWireNumberRequired"
                            v-model="systemDefaults.wireOutWireNumberRequired"
                            v-rq-tooltip.hover.topleft="getWireOutWireNumberRequiredToolTip"
                            :disabled="readOnly || systemDefaults.useAutoGeneratedWireNumbers">Wire Number</b-form-checkbox>
                    </div>
                    <div class="col col-12 col-sm-4 col-md-4 col-lg-4 label-lg">
                        <b-form-checkbox
                            automation_id="chk_wire_out_reference_required"
                            id="wire_out_reference_required"
                            v-model="systemDefaults.wireOutReferenceRequired"
                            :disabled="readOnly">Description</b-form-checkbox>
                    </div>
                </div>
            </rq-section-card>
            <rq-section-card title="Receiving Bank Information" collapsible>
                <div class="row">
                    <div class="col col-12 col-sm-4 col-md-4 col-lg-4 ps-5 label-lg">
                    <b-form-checkbox
                        automation_id="chk_wireOutReceivingBankNameRequired"
                        id="wireOutReceivingBankNameRequired"
                        v-model="systemDefaults.wireOutReceivingBankNameRequired"
                        :disabled="readOnly">Bank Name</b-form-checkbox>
                    </div>
                    <div class="col col-12 col-sm-4 col-md-4 col-lg-4 label-lg">
                    <b-form-checkbox
                        automation_id="chk_wireOutReceivingBankCityStateZipRequired"
                        id="wireOutReceivingBankCityStateZipRequired"
                        v-model="systemDefaults.wireOutReceivingBankCityStateZipRequired"
                        :disabled="readOnly">City, State, Zip</b-form-checkbox>
                    </div>
                    <div class="col col-12 col-sm-4 col-md-4 col-lg-4 label-lg">
                    <b-form-checkbox
                        automation_id="chk_wireOutReceivingBankInstructionsRequired"
                        id="wireOutReceivingBankInstructionsRequired"
                        v-model="systemDefaults.wireOutReceivingBankInstructionsRequired"
                        :disabled="readOnly">Wire Instructions</b-form-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12 col-sm-4 col-md-4 col-lg-4 ps-5 label-lg">
                    <b-form-checkbox
                        automation_id="chk_wireOutReceivingBankABARequired"
                        id="wireOutReceivingBankABARequired"
                        v-model="systemDefaults.wireOutReceivingBankABARequired"
                        :disabled="readOnly">Routing Number</b-form-checkbox>
                    </div>
                    <div class="col col-12 col-sm-4 col-md-4 col-lg-4 label-lg">
                    <b-form-checkbox
                        automation_id="chk_wireOutReceivingBankAccountNumberRequired"
                        id="wireOutReceivingBankAccountNumberRequired"
                        v-model="systemDefaults.wireOutReceivingBankAccountNumberRequired"
                        :disabled="readOnly">Account Number</b-form-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12 col-sm-4 col-md-4 col-lg-4 ps-5 label-lg">
                    <b-form-checkbox
                        automation_id="chk_wireOutReceivingBankAddressRequired"
                        id="wireOutReceivingBankAddressRequired"
                        v-model="systemDefaults.wireOutReceivingBankAddressRequired"
                        :disabled="readOnly">Bank Address</b-form-checkbox>
                    </div>
                    <div class="col col-12 col-sm-4 col-md-4 col-lg-4 label-lg">
                        <b-form-checkbox
                        automation_id="chk_wireOutReceivingBankCustomerNameRequired"
                        id="wireOutReceivingBankCustomerNameRequired"
                        v-model="systemDefaults.wireOutReceivingBankCustomerNameRequired"
                        :disabled="readOnly">Customer Name</b-form-checkbox>
                    </div>
                </div>
            </rq-section-card>
            <rq-section-card title="Offline Bank Information" collapsible>
                <div class="row">
                    <div class="col col-12 col-sm-4 col-md-4 col-lg-4 ps-5 label-lg">
                    <b-form-checkbox
                        automation_id="chk_wireOutOfflineBankNameRequired"
                        id="wireOutOfflineBankNameRequired"
                        v-model="systemDefaults.wireOutOfflineBankNameRequired"
                        :disabled="readOnly">Bank Name</b-form-checkbox>
                    </div>
                    <div class="col col-12 col-sm-4 col-md-4 col-lg-4 label-lg">
                    <b-form-checkbox
                        automation_id="chk_wireOutOfflineBankCityStateZipRequired"
                        id="wireOutOfflineBankCityStateZipRequired"
                        v-model="systemDefaults.wireOutOfflineBankCityStateZipRequired"
                        :disabled="readOnly">City, State, Zip</b-form-checkbox>
                    </div>
                    <div class="col col-12 col-sm-4 col-md-4 col-lg-4 label-lg">
                    <b-form-checkbox
                        automation_id="chk_wireOutOfflineBankNotesRequired"
                        id="wireOutOfflineBankNotesRequired"
                        v-model="systemDefaults.wireOutOfflineBankNotesRequired"
                        :disabled="readOnly">Notes</b-form-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12 col-sm-4 col-md-4 col-lg-4 ps-5 label-lg">
                    <b-form-checkbox
                        automation_id="chk_wireOutOfflineBankABARequired"
                        id="wireOutOfflineBankABARequired"
                        v-model="systemDefaults.wireOutOfflineBankABARequired"
                        :disabled="readOnly">Routing Number</b-form-checkbox>
                    </div>
                    <div class="col col-12 col-sm-4 col-md-4 col-lg-4 label-lg">
                    <b-form-checkbox
                        automation_id="chk_wireOutOfflineBankAccountNumberRequired"
                        id="wireOutOfflineBankAccountNumberRequired"
                        v-model="systemDefaults.wireOutOfflineBankAccountNumberRequired"
                        :disabled="readOnly">Account Number</b-form-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12 col-sm-4 col-md-4 col-lg-4 ps-5 label-lg">
                    <b-form-checkbox
                        automation_id="chk_wireOutOfflineBankAddressRequired"
                        id="wireOutOfflineBankAddressRequired"
                        v-model="systemDefaults.wireOutOfflineBankAddressRequired"
                        :disabled="readOnly">Bank Address</b-form-checkbox>
                    </div>
                    <div class="col col-12 col-sm-4 col-md-4 col-lg-4 label-lg">
                        <b-form-checkbox
                        automation_id="chk_wireOutOfflineBankFurtherCreditToRequired"
                        id="wireOutOfflineBankFurtherCreditToRequired"
                        v-model="systemDefaults.wireOutOfflineBankFurtherCreditToRequired"
                        :disabled="readOnly">Further Credit To</b-form-checkbox>
                    </div>
                </div>
            </rq-section-card>
         </rq-page-section>
    </div>
</template>
<script>
    import { mapState } from "vuex";
    import SystemDefaultsFormMixin from "../../shared/SystemDefaultsFormMixin";
    export default {
        name: "RequiredWireFieldsForm",
        mixins: [SystemDefaultsFormMixin],
        computed: {
            ...mapState({
                readOnly: state => state.isPageReadOnly,
            }),
            getWireOutWireNumberRequiredToolTip (){ return this.readonly ? '' : this.systemDefaults.useAutoGeneratedWireNumbers ? 'Use Auto Generated Wire Numbers must be turned off to make Wire Number a required field.' : ''}
        },
    }
</script>
