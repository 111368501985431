import TitleProductionMain from "./TitleProductionMain.vue";
import ConfigNav from "../shared/ConfigNav.vue";
import Routes from "./routes";
import { Categories } from "./categories";

export default {    
    path: "/configuration/titleProduction", 
    component: TitleProductionMain, 
    meta: {
        label: "Title Production & Policy",      
        include: true, 
        skipSaveOnLeave: true
    },    
    children: [{
            name: "cfg:titleprod:title",
            path: "",
            component: ConfigNav,
            props: {routes: Routes, category: Categories.TitleProduction},
            meta: {
                label: Categories.TitleProduction,
                skipSaveOnLeave: true
            }
        },
        ...Routes
    ]
};