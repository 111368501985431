<template>
    <div class="content-wrapper">
        <rq-page-section :title="itemTypeNamePlural" headerSize="lg" header-only borderless v-show="showHeader">
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
        </rq-page-section>
        <div class="row ms-2 mt-1">
            <div :class="showHeader ? 'col col-4 form-group form-required' : 'col col-6 form-group form-required'">
                <label for="dtp_export_formats">Export Format</label>
                <dx-select-box
                    :input-attr="{ automation_id: 'dtp_export_formats', id: 'dtp_export_formats' }"
                    :items="exportFormats"
                    value-expr="id"
                    display-expr="name"
                    v-model="selectedExportFormatID"
                    placeholder="Export Format..."
                    @value-changed="onExportFormatChanged"
                    :search-enabled="true"
                    :show-clear-button="false"
                />
            </div>
        </div>
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            v-model:validationErrors="validationErrors"
            @reset="onReset"
            hide-search
        />
    </div>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        name:"PositivePayExportFormatViewer",
        props: {
            exportFormatID: { type: Number, default: null },
            showHeader: { type: Boolean, default: true },
            // exportFormats: { type: Array, required: false, default: () => [] },
        },
        data () {
            return {
                exportFormats: [],
                items: [],
                validationErrors: [],
                selectedExportFormatID: null
            };
        },
        created(){
            this.selectedExportFormatID = this.exportFormatID;
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.fetchData();
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance() { return _.get(this.$refs, "dataGrid.gridInstance", null) || {}; },
        },
        watch: {
            validationErrors: function () {
                const self = this;
                self.$events.emit("update-config-error", { message: "Please correct the highlighted errors on screen to continue.", hasError: self.validationErrors.length > 0 });
            }
        },
        methods: {
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            fetchData () {
                const self = this;
                if (_.isNil(self.selectedExportFormatID)) return;
                let apiPromise = self.$api.EscrowAccountingApi.getPositivePayLayout(self.selectedExportFormatID);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = [];
                        _.each(result, (v, k) => {
                            self.items.push({fieldName: k, fieldValue: v });
                        });
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    focusedRowEnabled: false,
                    cacheEnabled: false,
                    selection: { mode: "none", showCheckBoxesMode: "never" },
                    columns: [
                        {
                            dataField: "fieldName", dataType: "string", caption: "Description", width: "50%", allowHeaderFiltering: false
                        },
                        {
                            dataField: "fieldValue", dataType: "string", caption: "Field Width (Position)", width: "50%", allowHeaderFiltering: false
                        },
                    ],
                };

                self.gridDataSource = {
                    loadMode: "raw",
                    key: "fieldName",
                    load () {
                        return Promise.resolve(self.items);
                    },
                };
            },

            initNonReactiveVariables() {
                const self = this;
                self.exportFormats = self.lookupHelpers.getLookupItems(self.lookupItems.EXPORT_FORMATS);
                self.itemTypeName = _.get(self.$route.meta, "itemTypeName");
                self.itemTypeDescription = _.get(self.$route.meta, "itemTypeDescription");
                self.itemTypeNamePlural = _.get(self.$route.meta, "label");
                self.itemKey = _.get(self.$route.meta, "itemKey");
            },

            onExportFormatChanged(e) {
                if (_.isNil(e.value)) return;
                this.fetchData();
            },

            onReset(e) {
                this.fetchData();
            },

            refresh() {
                if (!_.isEmpty(this.gridInstance)) {
                    this.gridInstance.option("focusedRowIndex", -1);
                    this.gridInstance.clearSelection();
                    this.gridInstance.refresh();
                }
            },
        }
    }
</script>