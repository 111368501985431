<template>
    <div class="rq-container content-wrapper">
        <div class="row">
            <div class="col col-3 col-sm-12 col-md-4 col-lg-4 form-group form-required">
                <label for="drp_state">State</label>
                <dx-select-box
                    :input-attr="{ automation_id: 'drp_state', id: 'drp_state' }"
                    :items="usStatesLookup"
                    value-expr="id"
                    display-expr="id"
                    v-model="selectedState"
                />
            </div>
        </div>
        <rq-page-section borderless>
            <div class="text-blue font-italic mb-n2">Assign documents to selected state.</div>
            <grid-selector
                ref="gridSelector"
                id="templatesGridSelector"
                class="flex-grow-1"
                :items="allTemplatesList"
                :titles="{
                    available: 'Available Documents',
                    selected: 'Selected Documents'
                }"
                item-key="documentTemplateID"
                item-name="Document"
                sort-description-field="description"
                sort-field="sequence"
                :grid-configs="gridConfigs"
                v-model="selectedTemplateIDs"
                :read-only="readOnly"
                @update:selectedIDs="onUpdateDocumentTemplateIDs"
                hide-reorder
            />
        </rq-page-section>
    </div>
</template>
<script>
    import { GlobalEventManager } from "@/app.events";
    import { mapState, mapGetters } from "vuex";
    import { DocumentTemplateDto, DocumentStateDto }  from "../models";
    import GridSelector from "../../shared/GridSelector";

    export default {
        name: 'DocuementStateForm',
        components: { GridSelector },
        props: {
            isValid: { type: Boolean, default: true }
        },
        data() {
            return {
                selectedState: null,
                originalData: {},
                selectedTemplatesList: [],
                allTemplatesList: [],
                searchText: "",
                selectedTemplateIDs: []
            }
        },

        computed: {
            ...mapGetters([
                "usStatesLookup"
            ]),
            ...mapState({
                globalRegionId: state => state.system.globalRegionId,
                userRegions: state => state.authentication.session.regions
            }),
            readOnly() { return _.isNil(this.selectedState); }
        },

        watch: {
            selectedState(newValue, oldValue) {
                if (newValue === oldValue) return;
                this.searchText = "";
                this.fetchTemplatesByState();
            },
            searchText(newValue) {
                if (!_.isEmpty(newValue)) return;
                this.fetchTemplatesByState();
            },
        },

        created(){
            const self = this;
            self.initGridConfig();
        },

        methods: {

            initGridConfig(){
                const self = this;
                let columns =[{
                    dataField: "description",
                    dataType: "string",
                    sortIndex: 0,
                    sortOrder: "asc"
                }];

                self.gridConfigs = {
                    available: {
                        columns,
                        remoteOperations: { sorting: true, paging: true }
                    },
                    selected: { columns }
                };
            },

            onUpdateDocumentTemplateIDs(e) {
                this.selectedTemplateIDs = e || [];
            },

            refresh(){
                const self = this;
                self.item.usStatesID = null;
                self.selectedTemplatesList = [];
            },

            onCancel(){
                const self = this;
                let changes = self.getChanges();
                if (changes.length === 0) {
                    self.$toast.info("No changes detected");
                } else {
                    let ok = function (args) {
                        self.fetchTemplatesByState();
                    };
                    self.$dialog.confirm("Confirm Cancel", "Discard changes and reload data?", ok);
                }
            },

            getChanges() {
                const self = this;
                let original = new DocumentTemplateDto(self.originalData);
                let newValues = new DocumentTemplateDto(self.selectedTemplateIDs);
                original.usStatesID = self.originalData;
                newValues.usStatesID = self.selectedTemplateIDs;
                return self.getAuditChanges(original,newValues);
            },

            save({ userInitiated=false }={ userInitiated: false }){
                const self = this;

                let changes = self.getChanges();
                if(changes.length === 0) {
                    if(userInitiated) self.$toast.info("No changes detected");
                    GlobalEventManager.saveCompleted({ success: true });
                    return Promise.resolve(true);
                }

                var items = _.map(self.selectedTemplateIDs, i => new DocumentStateDto({ documentTemplateID: i, usState: self.selectedState}));
                if (_.isEmpty(items)) {
                    items = [new DocumentStateDto({documentTemplateID: 0, usState: self.selectedState})];
                }
                let apiPromise = self.$api.DocumentTemplatesApi.saveStates(items);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        GlobalEventManager.saveCompleted({ success: true });
                        self.$toast.success("Document States were saved.");
                        self.fetchTemplatesByState();
                        return result;
                    })
                    .catch(error => {
                        GlobalEventManager.saveCompleted({ success: false });
                        self.$toast.error("Error saving Document States.");
                        return error;
                    });
            },

            fetchTemplatesByState() {
                const self = this;
                if (_.isNil(self.selectedState)) {
                    self.selectedTemplateIDs = [];
                    return;
                }

                let apiPromises = [];
                apiPromises.push(self.$api.DocumentTemplatesApi.getByState(self.selectedState));
                if(_.isEmpty(self.allTemplatesList))
                    apiPromises.push(self.$api.DocumentTemplatesApi.getList());
                return self.$rqBusy.wait(Promise.all(apiPromises))
                    .then(result => {
                        if(result.length > 1) self.mapAvailableTemplates(result[1]);
                        self.selectedTemplateIDs = _.map(_.clone(result[0]),'documentTemplateID');
                        self.originalData = _.map(_.clone(result[0]),'documentTemplateID');
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading Document Templates.` });
                        return error;
                    });
            },

            mapAvailableTemplates(items) {
                const self = this;
                if(!_.isEmpty(self.allTemplatesList)) return;
                let userRegionIds = _.map(self.userRegions, "regionID");
                let isRegion0Admin = _.includes(userRegionIds, self.globalRegionId);
                let userItems = isRegion0Admin ? items : _.filter(items, item => _.includes(userRegionIds, item.regionID));
                self.allTemplatesList = _.map(userItems, item => new DocumentTemplateDto(item));
            }
        }
    }
</script>
