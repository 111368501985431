import { ToggleSwitch } from "./enums";
import { UserScreenAccessLevel } from "@/shared/models/enums";
import { mapState } from "vuex";

export default {
    props: {
        label: { type: String, default: "" },
        searchTerm: { type: String, default: "" },
        roleSecurityData: { type: Object, default: () => ({}) }
    },
    emits: ["update:roleSecurityData"],
    data() {
        return {
            sectionFields: [],
            switchOn: ToggleSwitch.displayValue(true),
            switchOff: ToggleSwitch.displayValue(false),
        };
    },
    computed: {
        ...mapState({
            regions: state => state.authentication.session.regions,
            globalRegionId: state => state.system.globalRegionId
        }),
        securityData: {
            get() { return this.roleSecurityData; },
            set(val) { this.$emit("update:roleSecurityData", val); }
        },
        visibleFields() {
            if (_.isNullOrEmpty(this.searchTerm)) return this.sectionFields;
            const includesSearchText = itemText => _.includes(_.toLower(itemText), _.toLower(this.searchTerm));
            return _.filter(this.sectionFields, r => includesSearchText(r.label));
        },
        sectionVisible() { return !_.isEmpty(this.visibleFields); },
        adminRegionId() {
            let adminRegion = _.find(this.regions, r => r.isAdminRegion);
            return adminRegion ? adminRegion.regionID : 0;
        },
        isSysAdmin(){ return this.securityData.userGroup.regionID === this.adminRegionId && this.securityData.userGroupSecurity.isAdmin; },
        isAdmin(){ return this.securityData.userGroupSecurity.isAdmin; },
        isEscrowAdmin(){ return this.securityData.userGroupSecurity.isEscrowAdmin; }
    },
    methods: {
        categoryVisible(category) { return _.some(this.visibleFields, { category }); },
        fieldVisible(name) { return _.some(this.visibleFields, { name }); },
        getScreenAccessSwitchValue(field) { return _.getNumber(this.securityData, `userGroupScreenAccess.${field}`, 0) === UserScreenAccessLevel.Full; },
        setScreenAccessSwitchValue(field, val) { _.set(this.securityData, `userGroupScreenAccess.${field}`, val ? UserScreenAccessLevel.Full : UserScreenAccessLevel.None); }
    }
}
