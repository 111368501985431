<template>
    <div class="rq-container">
        <rq-banner
            variant="error"
            message="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && v$.$error"
        />
        <div class="row">
            <div class="col col-12 col-lg-6 form-group form-required" :class="{ 'has-error' : v$.item.login.$error }">
                <label for="txt_group_name">Group Name</label>
                <input
                    id="txt_group_name"
                    automation_id="txt_group_name"
                    type="text"
                    class="form-control"
                    placeholder="Display Name"
                    maxlength="45"
                    spellcheck="false"
                    v-model="v$.item.login.$model">
                <rq-validation-feedback
                    :messages="{
                        'Group Name is required': v$.item.login.required.$invalid,
                        'Group Name is in use by another group within the selected region': v$.item.login.isUnique.$invalid
                    }"
                />
            </div>
            <div class="col col-12 col-lg-6 form-group form-required" :class="{ 'has-error' : v$.item.regionID.$error }">
                <label for="drp_region">Region</label>
                <dx-select-box
                    :input-attr="{ automation_id: 'drp_region', id: 'drp_region' }"
                    :items="regionsLookup"
                    value-expr="regionID"
                    display-expr="displayName"
                    v-model="v$.item.regionID.$model"
                    v-focus
                    :search-enabled="true"
                />
                <rq-validation-feedback>Region is required.</rq-validation-feedback>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { required } from "validators";
    import { useVuelidate } from "@vuelidate/core";

    export default {
        name: 'UserGroupCopyForm',
        props: {
            item: { type: Object, default: null },
            uniqueValidator: { type: Function, default: (() => true) }
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                showBanner: false
            };
        },
        computed: {
            ...mapGetters([ "regionsLookup" ])
        },
        validations() {
            return {
                item: {
                    login: {
                        required,
                        isUnique(val) {
                            return this.uniqueValidator(this.item.regionID, val);
                        }
                    },
                    regionID: { required }
                }
            };
        },
        methods: {
            save() {
                const self = this;
                self.v$.$touch();
                self.showBanner = true;
                if(self.v$.$error) return Promise.resolve({ success: false });

                let apiPromise = self.$api.UsersApi.copyGroup(self.item.toDataObject());
                return self.$rqBusy.wait(apiPromise)
                    .then(data => {
                        self.$toast.success("Group was copied successfully.");
                        return { data, success: true };
                    }).catch(error => {
                        self.$toast.error("Error copying group.");
                        console.error(error);
                    });
            },
        }
    }
</script>