<template>

    <div class="content-wrapper">
        <rq-page-section :title="itemTypeNamePlural" headerSize="lg" borderless>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
        </rq-page-section>
        <rqValidationSummary :dataSource="validationErrors" />
        <div class="content-group">
            <b-nav class="rq-nav-tabs">
                <b-nav-item
                    v-for="(view,index) in viewList"
                    :key="`nav-tab-${index}`"
                    :active="view.active"
                    @click.prevent="onTabClicked(view,index)">
                    {{view.tabLabel}}
                </b-nav-item>
                <b-nav-text class="nav-text ms-5">
                    <span>{{currentView.navTitle}}</span>
                </b-nav-text>
            </b-nav>
        </div>

        <component ref="tabComponent"
            :is="currentView.name"
            :selected="selectedItemData"
            v-model:viewTitle="viewTitle"
            v-model:allowRestore="showRestoreButton"
            :noData="!viewHasItems">
        </component>
    </div>

</template>

<script>
    import { mapState, mapGetters } from "vuex";

    import EndorsementExport from "./ExportEndorsementList";
    import RateExport from "./ExportRateList";
    export default {
        components: { RateExport,EndorsementExport},
        data() {
            return {
                loanId: 0,
                listItems: [],
                selectedItemData: {},
                currentViewIndex: 0,
                selectedLoanId: 0,
                viewList: [{
                        name: "RateExport",
                        itemTitle: "Rates",
                        listTitle: "Rates",
                        viewTitle: "Rates",
                        tabLabel: "Rates",
                        selectedIndex: 0,
                        active: false,
                        navTitle: "Rates exported as *.rqr"
                    },
                    {
                        name:"EndorsementExport",
                        itemTitle: "Endorsements",
                        listTitle:"Endorsements",
                        viewTitle:"Endorsements",
                        tabLabel:"Endorsements",
                        selectedIndex: 0,
                        active: false,
                        navTitle: "Endorsements exported as *.rqe"
                    }
                ],
                validationErrors: [],
                viewTitle: "...",
                showRestoreButton: false,
                viewLoaded: false
            };
        },

        computed:{
            ...mapState({
                readOnly: state => state.isPageReadOnly,
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            currentView() { return this.viewList[this.currentViewIndex]; },
            viewHasItems() { return this.listItems.length > 0; },
            currentItemIndex:{
                get() { return _.get(this, "currentView.selectedIndex", 0); },
                set(val) { this.currentView.selectedIndex = val; }
            },
            system() { return this.$store.getters.currentSystem; },
            systemName() {
                let sysName = _.get(this, "system.systemName", "");
                if(!sysName) return "";
                let vmNum = RQ_AZ_VM_NUMBER || "";
                if(_.includes(sysName, "[VM_NUM]") && !_.isNil(vmNum) && vmNum !== "[VM_NUM]") {
                    if(!isNaN(vmNum)) return sysName.replace("[VM_NUM]", vmNum);
                    let vmRegEx = /\b(\S+\[VM_NUM\])/;
                    return sysName.replace(vmRegEx, vmNum);
                }
                return sysName;
            },
            itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
            itemTypeDescription() { return _.get(this.$route.meta, "itemTypeDescription") || ""; },
        },

        created() {
            this.setCurrentView(0);
        },


        methods:{

            onTabClicked(item, index) {
                const self = this;
                self.viewLoaded = false;
                if(self.isValid())
                    self.setCurrentView(index);
                else
                    return;
            },

            setCurrentView(index) {
                const self = this;
                _.forEach(self.viewList, (v,i) => { v.active = (i === index); });
                self.currentViewIndex = index;
            },

            isValid(validationResult) {
                this.validationErrors = _.get(validationResult, "errors", []);
                return _.isEmpty(this.validationErrors);
            },
        }

    };
</script>

<style lang="scss">
</style>