<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            v-model:validation-errors="validationErrors"
            :strikethrough-if-true="['isInactive']"
            target-inactive-column="isInactive"
            @activate="onActivateItem"
            @delete="onDeleteItem"
            @reset="onReset"
            integrated-search
            rq-editable
            rq-filters
            show-include-inactive
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { PolicyNumberPoolDto }  from "../models";
    import { TypeOfPolicies } from '../../enums';
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name:"PolicyNumberPoolList",
        props: { },
        data () {
            return {
                items: [],
                validationErrors: [],
                addEventName: "",
                gridConfig: {},
                gridDataSource: [],
                selectionActions: [],
            };
        },
        created(){
            this.fetchData();
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.initListeners();
        },
        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                globalRegionId: state => state.system.globalRegionId,
                user: state => state.authentication.session.user,
                underwriters: state => state.system.lookups.underwriters
            }),
            gridInstance() { return _.get(this.$refs, "dataGrid.gridInstance", null) || {}; },
        },
        watch: {
            validationErrors: function () {
                const self = this;
                self.$events.emit("update-config-error", { message: "Please correct the highlighted errors on screen to continue.", hasError: self.validationErrors.length > 0 });
            }
        },
        methods: {
            activate(keys, verb) {
                const self = this;
                let apiPromise = self.$api.PolicyPoolApi.activate(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.fetchData();
                        let message = keys.length > 1
                            ? `${keys.length} ${self.itemTypeNamePlural} were ${verb}d.`
                            : `${self.itemTypeName} was ${verb}d.`
                        self.$toast.success(message);
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error(`Error trying to ${verb} ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            fetchData () {
                const self = this;
                let apiPromise = self.$api.PolicyPoolApi.get();
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new PolicyNumberPoolDto(i));
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            initGridConfig(){
                const self = this;
                const uniqueDescriptionRule = {
                    type: "custom",
                    message: "That Underwriter/Description already exists.",
                    validationCallback: params => self.isUniqueDescription(params.data)
                };
                const uniqueRangeRule = {
                    type: "custom",
                    message: "That Underwriter/Prefix/Suffix/Range already exists.",
                    validationCallback: params => self.isUniqueItem(params.data)
                };
                self.gridConfig = {
                    focusedRowEnabled: false,
                    cacheEnabled: false,
                    columns: [
                        {
                            dataField: self.itemKey,
                            visible: false,
                            showInColumnChooser: false,
                        },
                        {
                            dataField: "regionID",
                            dataType: "number",
                            caption: "Region",
                            alignment: "left",
                            calculateSortValue: DxGridUtils.regionDisplaySortValue,
                            lookup: {
                                dataSource: self.regions,
                                displayExpr: "displayName",
                                valueExpr: "regionID"
                            },
                            setCellValue: function(rowData, value) {
                                rowData.regionID = value;
                                rowData.underwriterCompanyID = null;
                            },
                            validationRules: [
                                { type: "required" },
                            ]
                        },
                        {
                            dataField: "underwriterCompanyID",
                            dataType: "number",
                            caption: "Underwriter",
                            alignment: "left",
                            lookup: {
                                dataSource(dsOptions) {
                                    if(!dsOptions.isEditing && !dsOptions.isNewRow) return self.underwriters;
                                    let rowRegionId = _.getNumber(dsOptions, "data.regionID", 0);
                                    let rowUnderwriterCompanyID = _.get(dsOptions, "data.underwriterCompanyID", null);
                                    return _.filter(self.underwriters, u =>
                                        !u.inactive && (
                                            (rowRegionId > 0 && u.regionID === rowRegionId)
                                                || u.regionID === self.globalRegionId
                                                || u.id === rowUnderwriterCompanyID
                                        ));
                                },
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            rqFilter: {
                                displayExpr: "name",
                                valueExpr: "id",
                                filterType: "tags",
                                dataSource: self.underwriters
                            },
                            setCellValue: function(rowData, value) {
                                rowData.underwriterCompanyID = value;
                            },
                            validationRules: [
                                { type: "required" },
                                uniqueDescriptionRule,
                                uniqueRangeRule
                            ],
                            ...DxGridUtils.lookupSortDisplayExpr,
                        },
                        {
                            dataField: "description",
                            dataType: "string",
                            caption: "Description", sortIndex: 0, sortOrder: "asc",
                            editorOptions: { maxLength: 25 },
                            validationRules: [
                                { type: "required" },
                                uniqueDescriptionRule
                            ]
                        },
                        {
                            dataField: "typePolicies",
                            dataType: "number",
                            caption: "Type Of Policy",
                            lookup: {
                                dataSource: self.policyTypes,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            validationRules: [
                                { type: "required" },
                            ]
                        },
                        {
                            dataField: "prefix",
                            dataType: "string",
                            editorOptions: { maxLength: 10 },
                            setCellValue: function(rowData, value, currentRowData) {
                                rowData.prefix = value;
                                rowData.suffix = currentRowData.suffix || null;
                            },
                            validationRules: [ uniqueRangeRule ]
                        },
                        {
                            dataField: "suffix",
                            dataType: "string",
                            editorOptions: { maxLength: 10 },
                            setCellValue: function(rowData, value, currentRowData) {
                                rowData.suffix = value;
                                rowData.prefix = currentRowData.prefix || null;
                            },
                            validationRules: [ uniqueRangeRule ]
                        },
                        {
                            dataField: "startNumber",
                            datatype: "number",
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "range",
                                    min: 1,
                                    max: 2147483647,
                                    message: "Start Number must be between 1 and 2,147,483,647."
                                },
                            ]
                        },
                        {
                            dataField: "endNumber",
                            datatype: "number",
                            setCellValue: function(rowData, value, currentRowData) {
                                rowData.endNumber = value;
                                rowData.numChars = _.isEqual(currentRowData.numChars, 10) ? _.size(_.toString(value)) : currentRowData.numChars;
                            },
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "range",
                                    min: 1,
                                    max: 2147483647,//max integer size
                                    message: "End Number must be between 1 and 2,147,483,647."
                                },
                                {
                                    type: "custom",
                                    validationCallback: self.isInvalidEndNumber,
                                    message: "End Number must be greater than Start Number."
                                },
                            ]
                        },
                        {
                            dataField: "numChars",
                            caption: "Number Characters",
                            datatype: "number",
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "custom",
                                    validationCallback: self.isValidNumChars,
                                    message: "Number Characters must be greater than or equal to the End Number length."
                                }
                            ]
                        },
                        {
                            dataField: "isInactive",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate
                        }
                    ],
                    onInitNewRow: function(e) {
                        _.set(e.data, self.itemKey, 0);
                        e.data.regionID = self.user.regionID;
                        e.data.startNumber = 1;
                        e.data.endNumber = 2147483647;
                        e.data.numChars = 10;
                        e.data.isInactive = false;
                    }
                };

                self.gridDataSource = {
                    loadMode: "raw",
                    key: self.itemKey,
                    load () {
                        return Promise.resolve(self.items);
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },

            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },

            initNonReactiveVariables() {
                const self = this;
                self.regions = self.lookupHelpers.getRegions();
                self.policyTypes = TypeOfPolicies.lookupItems;
                self.itemTypeName = _.get(self.$route.meta, "itemTypeName");
                self.itemTypeNamePlural = _.get(self.$route.meta, "label");
                self.itemKey = _.get(self.$route.meta, "itemKey");
                self.selectionActions = [
                        { name: "delete", text: "Delete", eventName: "delete", requireSelection: true, allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}`, disabled: function(e) { return _.some(e.data, ['readOnly', true]); } },
                        { name: "activate", text: "Activate", eventName: "activate", requireSelection: true, tooltip: `Activate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return !_.every(e.data, ['isInactive', true]); } },
                        { name: "inactivate", text: "Inactivate", eventName: "activate", requireSelection: true, tooltip: `Inactivate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return !_.every(e.data, ['isInactive', false]); }  }
                    ];
            },

            isInvalidEndNumber(item) {
                return _.gt(item.data.endNumber, item.data.startNumber);
            },

            isValidNumChars(item) {
                return _.gte(item.data.numChars, _.size(_.toString(item.data.endNumber)));
            },

            isUniqueDescription(row){
                const self = this;
                let dup = {};
                if (!_.hasIn(row, "description") || _.trim(row.description) == '') return true;
                dup = _.find(self.items, (i) => {
                    return _.toLower(_.trim(i.description)) === _.toLower(_.trim(row.description))
                        && _.parseNumber(i.underwriterCompanyID, -1) === _.parseNumber(row.underwriterCompanyID, -1)
                        && _.parseNumber(_.get(i, self.itemKey, -1), -1) != _.parseNumber(_.get(row, self.itemKey, -1), -1);
                });
                return _.isEmpty(dup);
            },

            isUniqueItem(data){
                const self = this;
                const row = new PolicyNumberPoolDto(data);
                // if (!_.hasIn(row, "underwriterCompanyID") || !_.hasIn(row, "description") || !_.hasIn(row, "suffix") || !_.hasIn(row, "prefix")) return true;
                if (_.parseNumber(row.underwriterCompanyID, -1) <= 0 || _.isNullOrEmpty(row.description)) return true;
                let dup = _.some(self.items, (i) => {
                        return _.toLower(_.trim(i.suffix || "")) === _.toLower(_.trim(row.suffix || ""))
                            && _.toLower(_.trim(i.prefix || "")) === _.toLower(_.trim(row.prefix || ""))
                            && _.parseNumber(i.underwriterCompanyID, -1) === _.parseNumber(row.underwriterCompanyID, -1)
                            && _.parseNumber(_.get(i, self.itemKey, -1), -1) != _.parseNumber(_.get(row, self.itemKey, -1), -1);
                });
                return !dup;
            },

            onActivateItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 1
                    ? self.itemTypeNamePlural
                    : self.itemTypeName;
                let verb = _.every(items, ['inactive', true]) ? "Activate" : "Inactivate";

                let okHandler = function (args) {
                    let keys = _.map(items, self.itemKey);
                    self.activate(keys, verb);
                    return true;
                }

                self.$dialog.confirm(
                    `Confirm ${verb}`,
                    `Are you sure you want to ${verb} the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onAddItem() {
                this.gridInstance.clearSelection();
                this.gridInstance.addRow();
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let toBeDeletedKeys = _.map(items, self.itemKey);

                    let apiPromise = self.$api.PolicyPoolApi.delete(toBeDeletedKeys);
                    return self.$rqBusy.wait(apiPromise)
                        .then(keys => {
                            _.pullAllBy(self.items, items, self.itemKey);
                            let message = keys.length > 1 ? `${keys.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success({ message: message });
                            return true;
                        })
                        .catch(error => {
                          if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                 self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            }
                            return error;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onGridInsert(values) {
                const self = this;
                let newItem = new PolicyNumberPoolDto(values);
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                self.save(newItem, changes)
                    .then(item => {
                        self.items.push(new PolicyNumberPoolDto(item));
                        self.$toast.success({ message: `${self.itemTypeName} ${item.description} was saved.` });
                    })
                    .catch(err => {
                        self.validationErrors = [err.errorMessage];
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => _.parseNumber(_.get(item, self.itemKey), -1) === key);
                if(itemIndex < 0) return self.onGridInsert(values);

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new PolicyNumberPoolDto(_.assign({}, self.items[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());
                self.save(updatedItem, changes)
                    .then(item => {
                        _.assign(self.items[itemIndex], item);
                        self.$toast.success({ message: `${self.itemTypeName} ${item.description} was saved.` });
                    })
                    .catch(err => {
                        self.validationErrors = [err.errorMessage];
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            onReset(e) {
                this.fetchData();
            },

            refresh() {
                if (!_.isEmpty(this.gridInstance)) {
                    this.gridInstance.option("focusedRowIndex", -1);
                    this.gridInstance.clearSelection();
                    this.gridInstance.refresh();
                }
            },

            save(item, changes){
                const self = this;
                self.validationErrors = [];
                if (changes.length == 0) {
                    self.$toast.info({ message: "No changes detected" });
                    return Promise.resolve(item);
                }
                let apiPromise = self.$api.PolicyPoolApi.save(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise)
            },
        }
    }
</script>