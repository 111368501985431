<template>
    <rq-section-card v-show="sectionVisible" :title="label" collapsible>
        <div class="row">
            <div class="col-6">
                <div class="row" v-show="fieldVisible('allowChangePayeeNameOnInvoice')">
                    <div class="col">Change Payee Name On Invoice</div>
                    <div class="col">
                        <rqSwitch type="stop-light"
                            on="Yes"
                            off="No"
                            size="md"
                            automation_id="au_allowChangePayeeNameOnInvoice"
                            v-model="securityData.userGroupSecurity.allowChangePayeeNameOnInvoice"
                            :disabled="isChangePayeeNameOnInvoiceReadOnly"
                        />
                    </div>
                </div>
                <div class="row" v-show="fieldVisible('allowChangeTaxRateOnInvoice')">
                    <div class="col">Change Tax Rate On Invoice</div>
                    <div class="col">
                        <rqSwitch type="stop-light"
                            on="Yes"
                            off="No"
                            size="md"
                            automation_id="au_allowChangeTaxRateOnInvoice"
                            v-model="securityData.userGroupSecurity.allowChangeTaxRateOnInvoice"
                            :disabled="isChangeTaxRateOnInvoiceReadOnly"
                        />
                    </div>
                </div>
                <rq-page-section  title="Invoices" headerSize="sm" borderless v-show="categoryVisible('invoices')">
                    <div class="row">
                        <div class="col-3 form-group">
                            <label for="invoicePreDateDays">Pre-Date</label>
                            <div class="input-group">
                                <rqInputNumber id="txt_invoicePreDateDays" automation_id="au_invoicePreDateDays" defaultValue="0" decimals="0" 
                                               v-model="securityData.userGroupSecurity.invoicePreDateDays"
                                               :disabled="isInvoicesPreDateReadOnly"
                                               />
                                <span class="input-group-text">Days</span>
                            </div>
                        </div>
                        <div class="col-3 form-group">
                            <label for="invoicePostDateDays">Post-Date</label>
                            <div class="input-group">
                                <rqInputNumber id="txt_invoicePostDateDays" automation_id="au_invoicePostDateDays" defaultValue="0" decimals="0" 
                                                v-model="securityData.userGroupSecurity.invoicePostDateDays"
                                                :disabled="isInvoicesPostDateReadOnly"
                                                />
                                <span class="input-group-text">Days</span>
                            </div>
                        </div>
                    </div>
                </rq-page-section>
                <rq-page-section  title="Payments" headerSize="sm" borderless v-show="categoryVisible('payments')">
                    <div class="row">
                        <div class="col-3 form-group">
                            <label for="paymentPreDateDays">Pre-Date</label>
                            <div class="input-group">
                                <rqInputNumber id="txt_paymentPreDateDays" automation_id="au_paymentPreDateDays" defaultValue="0" decimals="0" 
                                               v-model="securityData.userGroupSecurity.paymentPreDateDays"
                                               :disabled="isPaymentsPreDateReadOnly"
                                               />
                                <span class="input-group-text">Days</span>
                            </div>
                        </div>
                        <div class="col-3 form-group">
                            <label for="paymentPostDateDays">Post-Date</label>
                            <div class="input-group">
                                <rqInputNumber id="txt_paymentPostDateDays" automation_id="au_paymentPostDateDays" defaultValue="0" decimals="0" 
                                               v-model="securityData.userGroupSecurity.paymentPostDateDays"
                                               :disabled="isPaymentsPostDateReadOnly"
                                               />
                                <span class="input-group-text">Days</span>
                            </div>
                        </div>
                    </div>
                </rq-page-section>
            </div>
            <div class="col-6">
                <div v-show="fieldVisible('invoiceEditing')" class="form-group">
                    <label for="drp_invoiceEditing">Invoice Editing</label>
                    <rq-select-box
                        id="drp_invoiceEditing"
                        :items="invoiceEditing"
                        v-model="securityData.userGroupSecurity.invoiceEditing"
                        :disabled="isInvoiceEditingReadOnly"
                    />
                </div>
                <div v-show="fieldVisible('invoiceDeletion')" class="form-group">
                    <label for="drp_invoiceDeletion">Invoice Deletion</label>
                    <rq-select-box
                        id="drp_invoiceDeletion"
                        :items="invoiceDeletion"
                        v-model="securityData.userGroupSecurity.invoiceDeletion"
                        :disabled="isInvoiceDeletionReadOnly"
                    />
                </div>
                <div v-show="fieldVisible('invoicePayments')" class="form-group">
                    <label for="drp_invoicePayments">Invoice Payments</label>
                    <rq-select-box
                        id="drp_invoicePayments"
                        :items="invoicePayment"
                        v-model="securityData.userGroupSecurity.invoicePayments"
                        :disabled="isInvoicePaymentsReadOnly"
                    />
                </div>
                <div v-show="fieldVisible('creditMemoEditing')" class="form-group">
                    <label for="drp_creditMemoEditing">Credit Memo Editing</label>
                    <rq-select-box
                        id="drp_creditMemoEditing"
                        :items="creditMemoEdit"
                        v-model="securityData.userGroupSecurity.creditMemoEditing"
                        :disabled="isCreditMemoEditingReadOnly"
                    />
                </div>
            </div>
        </div>
    </rq-section-card>
</template>
<script>
    import { InvoiceEditing, InvoiceDeletion, InvoicePayment, CreditMemoEdit } from "@config/enums";
    import SecurityRoleSectionMixin from "./SecurityRoleSectionMixin";

    export default {
        name: "CalcAndSsSection",
        mixins: [SecurityRoleSectionMixin],
        data() {
            return {
                sectionFields: [
                    { label: "Change Payee Name On Invoice", name: "allowChangePayeeNameOnInvoice", category: "" },
                    { label: "Change Tax Rate On Invoice", name: "allowChangeTaxRateOnInvoice", category: "" },
                    { label: "Pre-Date", name: "invoicePreDateDays", category: "invoices" },
                    { label: "Post-Date", name: "invoicePostDateDays", category: "invoices" },
                    { label: "Pre-Date", name: "paymentPreDateDays", category: "payments" },
                    { label: "Post-Date", name: "paymentPostDateDays", category: "payments" },
                    { label: "Invoice Editing", name: "invoiceEditing", category:  "" },
                    { label: "Invoice Deletion", name: "invoiceDeletion", category:  "" },
                    { label: "Invoice Payments", name: "invoicePayments", category:  "" },
                    { label: "Credit Memo Editing", name: "creditMemoEditing", category:  "" }
                ]
            };
        },
        computed: {
            invoiceEditing() { return  InvoiceEditing.lookupItems; },
            invoiceDeletion() { return  InvoiceDeletion.lookupItems; },
            invoicePayment() { return  InvoicePayment.lookupItems; },
            creditMemoEdit() { return  CreditMemoEdit.lookupItems; },
            isChangePayeeNameOnInvoiceReadOnly() { return this.isSysAdmin || this.isAdmin; },
            isChangeTaxRateOnInvoiceReadOnly()  { return this.isSysAdmin || this.isAdmin; },
            isInvoicesPreDateReadOnly()  { return this.isSysAdmin; },
            isInvoicesPostDateReadOnly()  { return this.isSysAdmin; },
            isPaymentsPreDateReadOnly()  { return this.isSysAdmin; },
            isPaymentsPostDateReadOnly()  { return this.isSysAdmin; },
            isInvoiceEditingReadOnly()  { return this.isSysAdmin || this.isEscrowAdmin; },
            isInvoiceDeletionReadOnly()  { return this.isSysAdmin || this.isEscrowAdmin; },
            isInvoicePaymentsReadOnly()  { return this.isSysAdmin || this.isEscrowAdmin; },
            isCreditMemoEditingReadOnly()  { return this.isSysAdmin || this.isAdmin || this.isEscrowAdmin; },
        },
        watch: {
            isSysAdmin: function(newValue){
                this.setDefaultValues();
            },
            isAdmin: function(newValue){
                this.setDefaultValues();
            },
            isEscrowAdmin: function(newValue){
                this.setDefaultValues();
            },
        },
        methods:{
            setDefaultValues(){
                const self = this;
                // Admin
                if(self.isSysAdmin || self.isAdmin){
                    self.securityData.userGroupSecurity.allowChangePayeeNameOnInvoice = true;                
                    self.securityData.userGroupSecurity.allowChangeTaxRateOnInvoice = true;
                    self.securityData.userGroupSecurity.invoicePreDateDays = -1;
                    self.securityData.userGroupSecurity.invoicePostDateDays = -1;
                    self.securityData.userGroupSecurity.paymentPreDateDays = -1;
                    self.securityData.userGroupSecurity.paymentPostDateDays = -1;
                    self.securityData.userGroupSecurity.invoiceEditing = InvoiceEditing.EditUnrestricted;
                    self.securityData.userGroupSecurity.invoiceDeletion = InvoiceDeletion.DeleteUnrestricted;
                    self.securityData.userGroupSecurity.invoicePayments = InvoicePayment.PaymentUnrestricted;
                    self.securityData.userGroupSecurity.creditMemoEditing = CreditMemoEdit.Unrestricted;
                }
                // Escrow Admin
                if(self.isEscrowAdmin && !self.isSysAdmin && !self.isAdmin) {
                    self.securityData.userGroupSecurity.allowChangePayeeNameOnInvoice = false;                
                    self.securityData.userGroupSecurity.allowChangeTaxRateOnInvoice = false;
                    self.securityData.userGroupSecurity.invoicePreDateDays = 0;
                    self.securityData.userGroupSecurity.invoicePostDateDays = 0;
                    self.securityData.userGroupSecurity.paymentPreDateDays = 0;
                    self.securityData.userGroupSecurity.paymentPostDateDays = 0;
                    self.securityData.userGroupSecurity.invoiceEditing = InvoiceEditing.EditUnrestricted;
                    self.securityData.userGroupSecurity.invoiceDeletion = InvoiceDeletion.DeleteUnrestricted;
                    self.securityData.userGroupSecurity.invoicePayments = InvoicePayment.PaymentUnrestricted;
                    self.securityData.userGroupSecurity.creditMemoEditing = CreditMemoEdit.Unrestricted;
                }
            }
        }
    }
</script>