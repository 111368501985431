import { Categories } from "./categories"
import { Topics } from "../topics"
import WorkflowDashboardSettingsForm from "./components/WorkflowDashboardSettingsForm";
import WorkflowDepartmentsList from "./components/WorkflowDepartmentsList";
import WorkflowProcessTemplateForm from "./components/WorkflowProcessTemplateForm";
import WorkflowProcessTemplatesList from "./components/WorkflowProcessTemplatesList";
import WorkflowTaskMain from "./components/WorkflowTaskMain";
import WorkflowTaskPackageCategoriesList from "./components/WorkflowTaskPackageCategoriesList"

import {ConfigurationActions} from "../ConfigurationActions";

import LookupNames from "@/store/lookupNames";

let routes = [

    {
        name: "cfg:workflow:workflowsettings",
        path: "workflowsettings",
        component: WorkflowDashboardSettingsForm,
        meta: {
            label: "Dashboard Settings",
            itemKey: "",
            itemTypeName: "Dashboard Setting",
            itemTypeDescription: "Configure options and departments for the Workflow Dashboard.",
            skipSaveOnLeave: false,
            topic: Topics.WorkflowVendorServices,
            category: Categories.Workflow,
            scrollLayout: true,
            sysAdminRequired: true
        }
    },
    {
        name: "cfg:workflow:processTemplates",
        path: "processTemplates",
        component: WorkflowProcessTemplatesList,
        meta: {
            label: "Process Templates",
            itemKey: "workflowProcessTemplateID",
            itemTypeName: "Process Template",
            itemTypeDescription: "Assign tasks, required documents, default Settlement Statement and defaults to process templates to be used creating new files.",
            skipSaveOnLeave: true,
            topic: Topics.WorkflowVendorServices,
            category: Categories.Workflow,
            showAdd: true,
            accessKey: "ProcessTemplateSetup_ScreenAccess",
            refreshLookup: LookupNames.WORKFLOW_PROCESS_TEMPLATES
        }
    },
    {
        name: "cfg:workflow:processTemplate",
        path: "processTemplate/:workflowProcessTemplateID",
        component: WorkflowProcessTemplateForm,
        props: true,
        meta: {
            label: "Process Templates",
            itemKey: "",//set to empty to trick out Config Main from using the add button
            // itemKey: "workflowProcessTemplateID",
            itemTypeName: "Process Template",
            itemTypeDescription: "These templates determine required tasks and documents for a file",
            topic: Topics.WorkflowVendorServices,
            category: Categories.Workflow,
            hideFromSearch: true,
            scrollLayout: false,
            accessKey: "ProcessTemplateSetup_ScreenAccess",
            refreshLookup: LookupNames.WORKFLOW_PROCESS_TEMPLATES
        }
    },
    {
        name: "cfg:workflow:workflowDept",
        path: "workflowDept",
        component: WorkflowDepartmentsList,
        meta: {
            label: "Workflow Departments",
            itemTypeName: "Workflow Department",
            itemTypeDescription: "Define workflow departments and assign users to be used in the Workflow Dashboard.",
            skipSaveOnLeave: true,
            topic: Topics.WorkflowVendorServices,
            category: Categories.Workflow,
            refreshLookup: LookupNames.WORKFLOW_DEPARTMENTS,
            sysAdminRequired: true
        }
    },
    {
        name: "cfg:workflow:workflowTasks",
        path: "workflowTasks",
        component: WorkflowTaskMain,
        meta: {
            label: "Workflow Tasks",
            itemKey: "",
            itemTypeName: "Workflow Task",
            itemTypeDescription: "Configure tasks and define assignment, task duration and how they are closed.",
            skipSaveOnLeave: true,
            topic: Topics.WorkflowVendorServices,
            category: Categories.Workflow,
            refreshLookup: [LookupNames.WORKFLOW_TASKS, LookupNames.WORKFLOW_PREDEFINED_TASKS]
        }
    },
    {
        name: "cfg:workflow:workflowTaskPkgCategories",
        path: "workflowTaskPkgCategories",
        component: WorkflowTaskPackageCategoriesList,
        meta: {
            label: "Workflow Task Package Categories",
            itemKey: "workflowTaskPackageCategoryID",
            itemTypeName: "Workflow Task Package Categories",
            itemTypeDescription: "Configure and define task package categories.",
            skipSaveOnLeave: true,
            topic: Topics.WorkflowVendorServices,
            category: Categories.Workflow,
            refreshLookup: [LookupNames.WORKFLOW_TASK_PACKAGE_CATEGORIES]
        }
    }
];

export default routes;

