import SSandCalcsMain from "./SSandCalcsMain.vue";
import ConfigNav from "../shared/ConfigNav.vue";
import Routes from "./routes";
import { Categories } from "./categories";

export default {
    name: "cfg:sscalc",
    path: "/configuration/ssAndCalcs",
    component: SSandCalcsMain,
    meta: {
        label: "Calculators & Settlement Statements",
        include: true,
        skipSaveOnLeave: true
    },
    children: [{
            name: "cfg:sscalc:calculators",
            path: "calculators",
            component: ConfigNav,
            props: {routes: Routes, category : Categories.Calculators},
            meta: {
                        label: Categories.Calculators,
                skipSaveOnLeave: true
            }
        },{
            name: "cfg:sscalc:settlements",
            path: "settlement",
            component: ConfigNav,
            props: {routes: Routes, category : Categories.SettlementStatement},
            meta: {
                        label: Categories.SettlementStatement,
                skipSaveOnLeave: true
            }
        },
        ...Routes,
    ]
};