<template>
    <div id="do-not-do-business" class="content-wrapper escrow-browser">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <rq-page-section title="Filter Do Not Do Business With List" headerSize="lg" class="browser-filter-section" v-model:expanded="filtersExpanded" @keyup.enter="onSearch" collapsible>
            <template #header-actions>
                <transition name="simple-fade">
                    <ul v-if="!filtersExpanded" class="nav browser-filter-display">
                        <li class="nav-item" v-if="dateFilterDisplay.length > 0">
                            <div class="filter-name">Date:</div>
                            <div class="filter-value">{{dateFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="hasActiveFilter">
                            <b-btn
                                variant="link"
                                class="btn-theme"
                                @click="onClearSearch">Clear All Filters
                            </b-btn>
                        </li>
                    </ul>
                </transition>
                <transition name="simple-fade">
                    <ul v-if="filtersExpanded" class="nav ms-auto">
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_clear_search"
                                variant="theme"
                                @click="onClearSearch">Clear</b-btn>
                        </li>
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_search"
                                variant="theme"
                                :disabled="errorMessage.length > 0"
                                @click="onSearch">Search</b-btn>
                        </li>
                    </ul>
                </transition>
            </template>
            <div class="row">
                <div class="col col-3 form-group">
                    <label for="txt_entityName">Name</label>
                    <input automation_id="txt_entityName" v-model="request.name" type="text" class="form-control" id="entityName" >
                </div>
                <div class="col col-3 form-group">
                    <label for="txt_entityAddress">Address</label>
                    <input automation_id="txt_entityAddress" v-model="request.address" type="text" class="form-control" id="entityAddress" >
                </div>
                <div class="col col-3 form-group">
                    <label for="dtp_entityType">Type</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_entityType', id: 'dtp_entityType' }"
                        :items="doNotDoBusinessWithTypes"
                        value-expr="id"
                        display-expr="name"
                        v-model="request.type"
                        placeholder="Types..."
                        :search-enabled="true"
                        :show-clear-button="true"
                    />
                </div>
                <div class="col col-3 form-group">
                    <label for="txt_entitySource">Source</label>
                    <input automation_id="txt_entitySource" v-model="request.source" type="text" class="form-control" id="entitySource" >
                </div>
            </div>
            <div class="row">
                <div class="col col-3 form-group">
                    <label for="date_range">Effective Date Range</label>
                    <rq-date-range
                        id="txt_do_not_do_business_date_range"
                        v-model:start-date="request.effectiveDateFrom"
                        v-model:end-date="request.effectiveDateTo"
                        match-value="before-clear"
                        match-field="end"
                        type="date"
                        display-format="MM/dd/yyyy"
                        show-calendar-drop-downs
                        show-clear-buttons
                        no-calendars
                        borderless
                    />
                </div>
            </div>
        </rq-page-section>
        <div class="rq-master-detail-container">
            <section class="mt-2">
                <rqdx-action-data-grid
                    title="Do Not Do Business With List"
                    ref="dataGrid"
                    automation_id="tbl_do_not_do_business_with"
                    :data-source="items"
                    :config="gridConfig"
                    :actions="selectionActions"
                    @action="onGridAction"
                    target-inactive-column="isInactive"
                    :strikethrough-if-true="['isInactive']"
                    show-include-inactive
                    hide-search
                    rq-filters>
                    <template #toolbar>
                        <ul class="nav">
                            <li class="nav-item">
                                <b-btn automation_id="btn_addAction" variant="theme" @click="onAddAction" >Add</b-btn>
                            </li>
                        </ul>
                    </template>
                </rqdx-action-data-grid>
            </section>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { DoNotDoBusinessWithDto, DoNotDoBusinessWithSearchRequestDto } from "../models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { DoNotDoBusinessWithType } from "@config/enums";
    import DoNotDoBusinessWithForm  from "./DoNotDoBusinessWithForm"
    import { h } from "vue";
    import { DateTime } from "luxon";

    export default {
        name: "DoNotDoBusinessWithList",
        data() {
            return {
                request: new DoNotDoBusinessWithSearchRequestDto(),
                activeRequest: new DoNotDoBusinessWithSearchRequestDto(),
                selectionActions: [],
                filtersExpanded: true,
                items: [],
                errorMessage: ""
            };
        },

        computed: {
            ...mapState({
                allBranches: state => _.get(state, "system.lookups.branches", [])
            }),
            ...mapGetters([
                "lookupHelpers"
            ]),
            regions() { return this.lookupHelpers.getRegions(); },
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            hasActiveFilter(){
                return !_.isEqual(new DoNotDoBusinessWithSearchRequestDto(this.activeRequest), new DoNotDoBusinessWithSearchRequestDto());
            },
            dateFilterDisplay(){
                if (!_.isNil(_.get(this, "activeRequest.effectiveDateTo")) && !_.isNil(_.get(this, "activeRequest.effectiveDateTo", 0))) {
                    return `${DateTime.fromISO(this.activeRequest.effectiveDateFrom).toFormat('MM/dd/yyyy hh:mm a')} to ${DateTime.fromISO(this.activeRequest.effectiveDateTo).toFormat('MM/dd/yyyy hh:mm a')}`;
                }
                return "";
            },
            doNotDoBusinessWithTypes() { return DoNotDoBusinessWithType.lookupItems; }
        },

        watch: {
        },

        created() {
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.fetchData();
        },

        methods: {
            onClearSearch() {
                this.filtersExpanded = true;
                this.request = new DoNotDoBusinessWithSearchRequestDto();
                this.activeRequest = new DoNotDoBusinessWithSearchRequestDto(this.request);
                this.reportParameters = {};
                this.showInactiveColumn = false;
                this.refresh();
            },

            refresh() {
                if(!this.gridInstance) return;
                this.clear();
                this.gridInstance.refresh();
            },

            clear() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            initNonReactiveVariables() {
                const self = this;
                self.itemKey = "doNotDoBusinessWithID";
                self.selectionActions = [
                    {
                        name: "edit",
                        text: "Edit",
                        key: "edit-item",
                        requireSelection: true
                    },
                    {
                        automation_id: `btn_do_not_do_business_with_activate`,
                        name: "activate",
                        text: "Activate",
                        key: "activate-item",
                        requireSelection: true,
                        allowMultiSelection: true,
                        disabled: function(e) {
                            return _.some(e.data, ["isInactive", false]);
                        }
                    },
                    {
                        automation_id: `btn__do_not_do_business_with_inactivate`,
                        name: "inactivate",
                        text: "Inactivate",
                        key: "inactivate-item",
                        requireSelection: true,
                        allowMultiSelection: true,
                        disabled: function(e) {
                            return _.some(e.data, ["isInactive", true]);
                        }
                    }
                ];
            },

            initGridConfig(){
                const self = this;

                let branchDataSource = _.map(self.allBranches, b => {
                    let branchItem = _.clone(b);
                    let branchRegion = self.lookupHelpers.getRegion(b.regionID);
                    branchItem.regionDisplay = _.isNil(branchRegion)
                        ? "Region not found"
                        : branchRegion.description;
                    return branchItem;
                });

                self.gridConfig = {
                    columnHidingEnabled: false,
                    focusedRowEnabled: false,
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,500], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    height: "100%",
                    columns: [
                        {
                            dataField: "name",
                            dataType: "string",
                        },
                        {
                            dataField: "entity",
                            dataType: "string",
                            caption: "Entity Name",
                        },
                        {
                            dataField: "company",
                            dataType: "string",
                            caption: "Company Name",
                        },
                        {
                            dataField: "address",
                            dataType: "string",
                        },
                        {
                            dataField: "type",
                            dataType: "number",
                            align: "left",
                            lookup: {
                                dataSource: self.doNotDoBusinessWithTypes,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            allowEditing: false
                        },
                        {
                            dataField: "source",
                            dataType: "string",
                        },
                        {
                            dataField: "regionIDs",
                            dataType: "object",
                            caption: "Region",
                            width: 300,
                            minWidth: 300,
                            calculateSortValue: rowData => {
                                let regionNames = _.sortBy(_.map(_.filter(self.regions, r => _.includes(rowData.regionIDs, r.regionID)), "description"));
                                return _.join(regionNames, "");
                            },
                            calculateFilterExpression: (filterValue, operator) => {
                                if (_.isString(filterValue)) {
                                    return rowData => {
                                        let regionNames = _.sortBy(_.map(_.filter(self.regions, r => _.includes(rowData.regionIDs, r.regionID)), "description"));
                                        return _.includes(_.toLower(regionNames), _.toLower(filterValue))
                                    }
                                }

                                if(operator !== "contains") return () => false;

                                return rowData => {
                                        let ids = [rowData.regionID, ...rowData.regionIDs];
                                        return _.includes(ids, filterValue);
                                }
                            },
                            rqFilter: {
                                valueExpr: "regionID",
                                displayExpr: "description",
                                dataSource: _.sortBy(self.regions, "description"),
                                listOperator: "or",
                                valueOperator: "contains",
                            },
                            cellTemplate: function(cellElement, cellInfo) {
                                let regions = self.regions;
                                if(_.isEmpty(cellInfo.value) || _.isEmpty(regions)) return;


                                let itemNames = _.sortBy(_.map(cellInfo.value, id =>
                                                        _.find(regions, r => id == r.regionID).description
                                                        ));
                                let displayText = _.joinParts(itemNames, ", ");
                                let displayTitle = itemNames.length === 1
                                    ? displayText
                                    : `${itemNames.length} Regions Selected`;
                                $("<span />")
                                    .addClass("text-truncate")
                                    .attr("title", displayTitle)
                                    .append(displayText)
                                    .appendTo(cellElement);
                            },
                            setCellValue(rowData, value) {
                                rowData.regionIDs = value;
                                rowData.regionID = value?.[0];
                            }
                        },
                        {
                            dataField: "branchIDs",
                            dataType: "object",
                            caption: "Branches",
                            width: 200,
                            minWidth: 100,
                            calculateFilterExpression: (filterValue, operator) => {
                                if(_.isNil(operator)) return _.isString(filterValue)
                                    ? rowData => {
                                        let displayText = self.getBranchDisplayText(rowData);
                                        return _.includes(_.toLower(displayText), _.toLower(filterValue))
                                    }
                                    : () => true;
                                return rowData => {
                                    let ids = rowData.allBranches
                                        ? _.map(_.filter(self.allBranches, b => b.regionID === rowData.regionID), "id")
                                        : rowData.branchIDs;
                                    return _.includes(ids, filterValue);
                                };
                            },
                            rqFilter: {
                                valueExpr: "branchID",
                                displayExpr: "description",
                                dataSource: branchDataSource,
                                listOperator: "or",
                                valueOperator: "contains",
                                itemTemplate: {
                                    name: "BranchItemTemplate",
                                    props: { item: Object, index: Number },
                                    setup(props) {
                                        return () => h("div", { class: "d-inline-flex align-items-baseline" }, [
                                            h("span", props.item.name),
                                            h("span", { class: "text-muted font-italic font-xs font-weight-light ms-auto" }, props.item.regionDisplay)
                                        ]);
                                    }
                                }
                            },
                            cellTemplate: function(cellElement, cellInfo) {
                                if(_.isEmpty(cellInfo.value) && _.isNil(cellInfo.data.regionIDs)) return;
                                const searchHighlightTemplate = '<span class="dx-datagrid-search-text">$&</span>';

                                let displayText = self.getBranchDisplayText(cellInfo.data);

                                let searchHighlighted = _.isEmpty(_.trim(self.searchText)) ? displayText : _.replace(displayText, new RegExp(self.searchText, "ig"), searchHighlightTemplate);
                                $("<span />")
                                    .addClass("text-truncate")
                                    .attr("title", displayText)
                                    .append(searchHighlighted)
                                    .appendTo(cellElement);
                            }
                        },
                        DxGridUtils.dateColumn({
                            dataField: "effectiveDate",
                            caption: "Effective Date",
                        }),
                        DxGridUtils.dateColumn({
                            dataField: "expirationDate",
                            caption: "Expiration Date",
                        }),
                        { dataField: "isInactive", caption: "Inactive", dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate,visible: false },
                    ],
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                };
            },

            isValidPhoneNumber(e){
                let phoneNum = /^\d{10}$/;
                return _.getBool(e, "data.createdFromUserCopy")
                    || _.isEmpty(e.value)
                    || phoneNum.test(e.value);
            },

             getBranchDisplayText(item) {
                const self = this;
                if(_.isEmpty(item.branchIDs) && _.isNil(item.regionIDs)) return;
                let selectedBranches = _.filter(self.allBranches, b => _.isEmpty(item.branchIDs)
                    ? _.includes(item.regionIDs, b.regionID)
                    : _.includes(item.branchIDs, b.id));
                return _.joinParts(_.map(selectedBranches, "name"), ", ");
            },

            onSearch(e) {
                this.fetchData();
            },

            onAddAction(){
                const self = this;
                let onOk = (e) => {
                    let form = e.component;
                    if (!form.isValid()) {
                       return false;
                    }
                    return form.save()
                        .then(() => {
                            self.fetchData();
                            return true;
                        })
                        .catch(error => {
                            e.component.errorMessage = e.errorMessage;
                            return false;
                        });
                };

                let onCancel = (e) => {
                    //if the cancel button is click on the file input
                     return e.originalEvent.target.id !== "do_not_do_business_with_file_input";
                }

                self.$dialog.open({
                    title: "Add Do Not Do Business With",
                    width: 1000,
                    height: "auto",
                    resizable: false,
                    component: DoNotDoBusinessWithForm,
                    onOk: onOk,
                    onCancel: onCancel
                });
            },

            onEditAction(data){
                const self = this;
                let onOk = (e) => {
                    let form = e.component;
                    if (!form.isValid()) {
                       return false;
                    }
                    return form.save()
                        .then(() => {
                            self.fetchData();
                            return true;
                        })
                        .catch(error => {
                            e.component.errorMessage = e.errorMessage;
                            return false;
                        });
                };

                let onCancel = (e) => {
                    //if the cancel button is click on the file input
                     return e.originalEvent.target.id !== "do_not_do_business_with_file_input";
                }

                self.$dialog.open({
                    title: "Edit Do Not Do Business With",
                    width: 1000,
                    height: "auto",
                    resizable: false,
                    component: DoNotDoBusinessWithForm,
                    props: { data: data },
                    onOk: onOk,
                    onCancel: onCancel
                });
            },

            onGridAction(e){
                const self = this;
                switch(e.action.key) {
                    case "edit-item":
                        self.onEditAction(e.data)
                        return;
                    case "activate-item":
                        self.toggleActivation(e.data, "activated");
                        return;
                    case "inactivate-item":
                        self.toggleActivation(e.data, "made inactive");
                        return;
                }
            },

            toggleActivation(selectedData, verb){
                const self = this;
                var doNotDoBusinessWithIDs = _.map(selectedData, d => d.doNotDoBusinessWithID);
                let apiPromise = self.$api.DoNotDoBusinessWithApi.toggleInactive(doNotDoBusinessWithIDs);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.fetchData();
                        self.$toast.success(`Do Not Do Business With(s) ${verb} Successfully.`);
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error changing activation status.` });
                    });
            },

            fetchData() {
                const self = this;
                let effectiveDateFrom = DateTime.fromISO(self.request.effectiveDateFrom).isValid ? DateTime.fromISO(self.request.effectiveDateFrom).toFormat('MM/dd/yyyy hh:mm a') : null;
                let effectiveDateTo = DateTime.fromISO(self.request.effectiveDateTo).isValid ? DateTime.fromISO(self.request.effectiveDateTo).toFormat('MM/dd/yyyy hh:mm a') : null;
                let apiPromise = self.$api.DoNotDoBusinessWithApi.search(self.request.name, self.request.address, self.request.type, self.request.source, effectiveDateFrom, effectiveDateTo);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new DoNotDoBusinessWithDto(i));
                        self.activeRequest = new DoNotDoBusinessWithSearchRequestDto(self.request);
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading Do Not Do Business With List.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },
        }
    }


</script>