<template>
    <div class="rq-container content-wrapper">
        <rq-page-section title="Search Options" headerSize="lg" v-model:expanded="filtersExpanded" @keyup.enter="onSearch" collapsible borderless>
             <template #header-actions>
                <transition name="simple-fade">
                    <ul v-if="!filtersExpanded" class="nav browser-filter-display">
                        <li class="nav-item" v-if="regionFilterDisplay != 'All'">
                            <div class="filter-name">Region:</div>
                            <div class="filter-value">{{regionFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="stateFilterDisplay != 'All'">
                            <div class="filter-name">State:</div>
                            <div class="filter-value">{{stateFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="countyFilterDisplay != 0">
                            <div class="filter-name">County:</div>
                            <div class="filter-value">{{countyFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="underwriterFilterDisplay != 'All'">
                            <div class="filter-name">Underwriter:</div>
                            <div class="filter-value">{{underwriterFilterDisplay}}</div>
                        </li>
                       <li class="nav-item" v-if="premiumCodeFilterDisplay != 'All'">
                            <div class="filter-name">Premium Code:</div>
                            <div class="filter-value">{{premiumCodeFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="hasActiveFilter">
                            <b-btn
                                variant="link"
                                class="btn-theme"
                                @click="onClear">Clear All Filters
                            </b-btn>
                        </li>
                    </ul>
                </transition>
                <transition name="simple-fade">
                    <ul v-if="filtersExpanded" class="nav ms-auto">
                        <li class="nav-item me-2">
                            <b-btn
                                automation_id="btn_search_checks"
                                variant="app-action"
                                @click="onSearch">Search</b-btn>
                        </li>
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_search_clear"
                                variant="app-action"
                                @click="onClear">Clear</b-btn>
                        </li>
                    </ul>
                </transition>
            </template>

            <div class="row">
                 <div class="col col-4 form-group">
                    <label for="dtp_region">Region</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_region', id: 'dtp_region' }"
                        :items="regionSelectItems"
                        value-expr="regionID"
                        display-expr="displayName"
                        :show-clear-button="true"
                        v-model="request.regionID"
                    />
                </div>
                <div class="col col-4 form-group">
                    <label for="dtp_state">State</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_state', id: 'dtp_state' }"
                        :items="usStates"
                        value-expr="id"
                        display-expr="id"
                        :show-clear-button="true"
                        v-model="request.usState"
                    />
                </div>
                <div class="col col-4 form-group">
                    <label for="dtp_county">County</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_county', id: 'drp_county' }"
                        value-expr="id"
                        display-expr="name"
                        :items="stateCounties"
                        :disabled="countiesDisabled"
                        :show-clear-button="true"
                        v-model="request.countyID"
                    />
                </div>

            </div>
            <div class="row">
                <div class="col col-4 form-group">
                    <label for="dtp_underwriter">Underwriter</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_underwriter', id: 'dtp_underwriter' }"
                        :items="underwriters"
                        value-expr="id"
                        display-expr="name"
                        :show-clear-button="true"
                        v-model="request.underwriterID"
                    />
                </div>
                <div class="col col-4 form-group">
                    <label for="txt_premiumCode">Premium Code</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_premiumCode', id: 'dtp_premiumCode' }"
                        :items="premiumCodes"
                        :show-clear-button="true"
                        v-model="request.premiumCode"
                    />
                </div>
                <div class="col col-4 form-group">
                    <label for="txt_premiumDescription">Premium</label>
                    <input automation_id="txt_premiumDescription" v-model="request.premiumDescription" type="text" class="form-control" id="premiumDescription" >
                </div>
            </div>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="tbl_premium_search"
            :config="gridConfig"
            :data-source="gridDataSource"
            @selectionChanged="onGridSelectionChanged"
            single-selection-enabled
            hide-search
            hide-show-column-chooser
            hide-export
            hide-settings
            rq-filters
        />
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import { RateDto, PremiumSearchRequest }  from "../models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name:"PremiumSearch",
        data () {
            return {
                itemKey: "rateEffectiveDateID",
                items: [],
                request: new PremiumSearchRequest(),
                activeRequest: new PremiumSearchRequest(),
                selectedItem: {},
                filtersExpanded: true
            };
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),

            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            regionSelectItems() { return this.lookupHelpers.getRegions(); },
            usStates() {return this.lookupHelpers.getStates(); },
            countiesDisabled() { return _.isEmpty(this.stateCounties); },
            stateCounties() { return _.isEmpty(this.request.usState) ? [] : this.lookupHelpers.getCountiesByState(this.request.usState); },
            underwriters(){ return _.parseNumber(this.request.regionID, 0) === 0 ? this.lookupHelpers.getUnderwriters() : this.lookupHelpers.getUnderwriters([this.request.regionID]); },

            regionFilterDisplay(){
                return _.isNil(this.request.regionID) ? "All" : this.lookupHelpers.getLookupItemName(this.lookupItems.REGIONS, this.request.regionID);
            },
            stateFilterDisplay(){
                return _.isNil(this.request.usState) ? "All" : this.request.usState;
            },
            countyFilterDisplay(){
                return _.isNil(this.request.countyID) || this.activeRequest.countyID === 0 ? "" : this.lookupHelpers.getLookupItemName(this.lookupItems.COUNTIES, this.request.countyID);
            },
            underwriterFilterDisplay(){
                return _.isNil(this.request.underwriter) ? "All" : this.request.underwriter;
            },
            premiumCodeFilterDisplay(){
                return _.isNil(this.request.premiumCode) ? "All" : this.request.premiumCode;
            },
            hasFilter(){
                return !_.isEqual(new PremiumSearchRequest(this.request), new PremiumSearchRequest());
            },
            hasActiveFilter(){
                return !_.isEqual(new PremiumSearchRequest(this.request), new PremiumSearchRequest());
            },
            premiumCodes(){
                return this.items.length === 0 ? [] : _.map(_.uniqBy(this.items, 'code'), 'code').sort();
            }

        },
        watch: {
            // "request.regionID"(newValue, oldValue) {
            //     if (newValue === oldValue) return;
            //     this.request.underwriterID = null;
            // },
            "request.usState"(newValue, oldValue) {
                if (newValue === oldValue) return;
                this.request.countyID = null;
            }
        },
        created(){
            this.initGridConfig();
            this.fetchData();
        },
        methods: {
            onSearch(){
                if(!this.hasFilter) {
                    this.onClear();
                    return;
                }
                this.errorMessage = "";
                this.fetchData();
            },
            onClear(){
                this.request = new PremiumSearchRequest();
                this.activeRequest = new PremiumSearchRequest();
                this.fetchData();
            },
            onGridSelectionChanged(e) {
                this.selectedItem = e.selectedRowsData[0];
            },
            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        DxGridUtils.dateColumn({
                            dataField: 'effectiveDate'
                        }),
                        { dataField: "code", dataType: "string", caption: "Premium Code"},
                        { dataField: "state", dataType: "string" },
                        { dataField: "extendedDescription", dataType: "string", caption: "Premium", cellTemplate: DxGridUtils.truncateCellTemplate, sortIndex: 0, sortOrder: "asc"},
                        { dataField: "underwriterList", dataType: "string", caption: "Underwriter", cellTemplate: DxGridUtils.truncateCellTemplate, width: 150 },
                        { dataField: "countyList", dataType: "string", caption: "County", cellTemplate: DxGridUtils.truncateCellTemplate, width: 150 }
                    ]
                };

                self.gridDataSource = {
                    loadMode: "raw",
                    key: self.itemKey,
                    load () {
                        return Promise.resolve(self.items);
                    },
                };
            },
            fetchData() {
                const self = this;
                let apiPromise = self.$api.RatesApi.searchPremiums(self.request.toDataObject());
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new RateDto(i));
                        self.items = _.sortBy(_.filter(self.items, i => { return !i.isInActive} ), "extendedDescription");
                        self.activeRequest = new PremiumSearchRequest(self.request);
                        self.refresh();

                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading Premiums.` });
                    });
            },
            clear() {
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },
            refresh() {
                // this.clear();
                this.gridInstance.refresh();
            }
        }
    }
</script>