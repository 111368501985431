<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid v-if="hasLoaded"
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            v-model:validation-errors="validationErrors"
            :rq-editable="!readOnly"
            @delete="onDeleteItem"
            integrated-search
            rq-filters
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { DefaultCDFLineDto, CdfSectionLookupDto }  from "../models";
    import GridCompanyPickerMixin from "@/shared/mixins/GridCompanyPickerMixin";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import GridInvokerMixin from "@/shared/mixins/GridInvokerMixin";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";

    export default {
        name:"DeafultCDFLineList",
        mixins: [
            GridInvokerMixin({ grid:"dataGrid" }),
            GridCompanyPickerMixin,
            GridSystemLookupMixin
        ],
        data () {
            return {
                items: [],
                cdfSectionItems: [],
                hasLoaded: false,
                validationErrors: [],
                addEventName: ""
            };
        },
        computed: {
            ...mapState({
                readOnly: state => state.isPageReadOnly,
                user: state => state.authentication.session.user,
                globalRegionId: state => state.system.globalRegionId,
                accountingCodes: state => state.system.lookups?.accountingCodes
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
        },
        watch: {
            validationErrors() {
                const self = this;
                self.$events.emit("update-config-error", { message: "Please correct the highlighted errors on screen to continue.", hasError: self.validationErrors.length > 0 });
            }
        },
        created(){
            this.initNonReactiveVariables();
            this.fetchData();
            this.initListeners();
        },
        beforeUnmount() {
            this.$events.off(this.addEventName, this.onAddItem);
        },
        methods: {

            initNonReactiveVariables() {
                const self = this;
                self.readOnlyColumns = ["systemRequired", "line"];
                self.itemTypeName = _.get(self.$route.meta, "itemTypeName");
                self.itemTypeNamePlural = _.get(self.$route.meta, "label");
                self.itemKey = _.get(self.$route.meta, "itemKey");
                self.selectionActions = [
                    { name: "delete", text: "Delete", eventName: "delete", requireSelection: true, allowMultiSelection: true, tooltip: `Delete ${self.itemTypeName}`, disabled: (e) => _.some(e.data, "systemRequired") || self.readOnly }
                ];
            },

            async fetchData (refreshSectionLookup = true) {
                const self = this;
                let apiPromises = [
                    self.$api.DefaultCDFLinesApi.get()
                ];

                if(refreshSectionLookup){
                    apiPromises.push(self.$api.DefaultCDFLinesApi.cdfSectionLookup());
                }

                try{
                    let results = await self.$rqBusy.wait(Promise.all(apiPromises));

                    self.items = _.map(results[0], i => new DefaultCDFLineDto(i));

                    if(refreshSectionLookup){
                        self.cdfSectionItems = _.map(results[1], i => new CdfSectionLookupDto(i));
                    }
                    self.initGridConfig();
                    self.hasLoaded = true;
                }
                catch(error){
                    console.error(error);
                    self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                }
            },

            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },

            initGridConfig(){
                const self = this;
                let payeePickerInfo = {
                    dialogTitle: "Select Payee",
                    companyIDExpr: "payeeCompanyID",
                    companyNameExpr: "payeeCompanyDisplay",
                    showContactPicker: false,
                };

                // This can be used if we decide to filter out maxed lines instead of validate them
                // const getSectionItems = e => {
                //     if(_.isNil(e?.data)) return self.cdfSectionItems;
                //     return _.filter(self.cdfSectionItems, item => !item.reachedMax || item.id === e.data.sectionCode);
                // };
                const getCdfSectionItems = () => self.cdfSectionItems;
                const getCdfSectionPrefix = id => _.findValue(self.cdfSectionItems, "sectionCodePrefix", { id });
                // const getAccountCodes = e => _.isNil(e.data)
                //     ? self.accountingCodes
                //     : _.filter(self.accountingCodes, i => i.inactive == false || i.id == e.data.acctCode);

                self.gridConfig = {
                    onEditorPreparing: self.prepareEditor,
                    columns: [
                        {
                            dataField: "sectionCode",
                            dataType: "number",
                            caption: "Section",
                            lookup: {
                                dataSource: getCdfSectionItems,
                                displayExpr: "name",
                                valueExpr: "id",
                            },
                            rqFilter: {
                                displayExpr: "name",
                                valueExpr: "id",
                                filterType: "tags",
                                dataSource: {
                                    loadMode: "raw",
                                    load() {
                                        return self.cdfSectionItems;
                                    }
                                }
                            },
                            setCellValue(rowData, value) {
                                rowData.sectionCode = value;
                                rowData.sectionLineDisplay = getCdfSectionPrefix(value);
                            },
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "custom",
                                    validationCallback: self.isValidSectionCode,
                                    message: "Max number of lines for this section has been reached."
                                }
                            ]
                        },
                        { dataField: "line", dataType: "number", allowEditing: false, },
                        {
                            dataField: "description",
                            dataType: "string",
                            editorOptions: { maxLength: 100 },
                            validationRules: [{ type: "required" }],
                            cellTemplate: DxGridUtils.truncateCellTemplate
                        },
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "acctCode",
                                dataType: "number",
                                caption: "Account Code",
                                editorOptions: { showClearButton: true },
                            },
                            lookupKey: self.lookupItems.ACCOUNTING_CODES,
                            customSort: function(i) { return _.parseNumber(_.get(i, "data")); }
                        }),
                        self.getCompanyContactGridColumn({
                            column: {
                                dataField: "payeeCompanyDisplay",
                                dataType: "string",
                                caption: "Payee",
                            },
                            ...payeePickerInfo
                        }),
                        {
                            dataField: "systemRequired",
                            caption: "System Record",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            allowEditing: false
                        },
                        {
                            dataField: "required",
                            caption: "Required",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate
                        }
                    ],
                    onInitNewRow(e) {
                        _.set(e.data, self.itemKey, 0);
                        e.data.systemRequired = false;
                        e.data.required = false;
                    }
                };

                self.gridDataSource = {
                    key: "id",
                    load() {
                        let sortedItems = _.sortBy(self.items, ["sectionCode", "line"]);
                        return Promise.resolve(sortedItems);
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },

            isValidSectionCode(e) {
                if(e.data.id > 0) return true;
                let cdfSection = _.find(this.cdfSectionItems, item => item.id === e.value);
                if(_.isNil(cdfSection)) return true;
                return !cdfSection.reachedMax;
            },

            onAddItem() {
                this.invokeGridMethod("clearSelection");
                this.invokeGridMethod("addRow");
            },

            async onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = async function (args) {
                    let toBeDeletedKeys = _.map(items, self.itemKey);
                    let apiPromise = self.$api.DefaultCDFLinesApi.delete(toBeDeletedKeys);
                    return self.$rqBusy.wait(apiPromise)
                        .then(async keys => {
                            // _.pullAllBy(self.items, items, self.itemKey);//RQO-27243 - line numbers are recalculated so just do a full refresh, instead of pulling from the items
                            let message = keys.length > 1 ? `${keys.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success(message);
                            self.refreshSectionItemsMaxValues();
                            self.invokeGridMethod("clearSelection");
                            await self.fetchData(true);//RQO-27243 - line numbers are recalculated so just do a full refresh, instead of pulling from the items
                            self.invokeGridMethod("refresh");
                            return true;
                        })
                        .catch(error => {
                            if (_.includes(error.errorMessage, "REFERENCE constraint")) {
                                 self.$dialog.messageBox("Delete Error", `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error(`Error deleting ${self.itemTypeName}.`);
                            }
                            return error;
                        });
                }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            async onGridInsert(values) {
                const self = this;
                var item = await self.save(new DefaultCDFLineDto(values));
                if(_.isNil(item)) return;
                self.items.push(new DefaultCDFLineDto(item));
                self.refreshSectionItemsMaxValues();
                await self.fetchData(false);
            },

            async onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => _.getNumber(item, self.itemKey, -1) === key);
                if(itemIndex < 0) return self.onGridInsert(values);

                let originalItem = new DefaultCDFLineDto(self.items[itemIndex]);
                let updatedItem = new DefaultCDFLineDto(_.assign({}, self.items[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                if(_.isEmpty(changes)) return updatedItem;

                _.assign(self.items[itemIndex], updatedItem);
                var item = await self.save(updatedItem)

                if(_.isNil(item)) return;
                self.invokeGridMethod("refresh");
            },

            prepareEditor(e) {
                if(e.parentType !== "dataRow") return;
                let isReadOnlyCell = false;
                if (e.dataField === "sectionCode") {
                    isReadOnlyCell = !e.row.isNewRow;
                }
                if (e.dataField === "required") {
                    let systemRequired = _.get(e.row.data, "systemRequired", false);
                    isReadOnlyCell = systemRequired;
                }
                e.editorOptions.readOnly = isReadOnlyCell;
                if(!isReadOnlyCell) return;
                e.editorElement.parent().addClass("rq-disabled-cell");
            },

            refreshSectionItemsMaxValues() {
                const self = this;
                _.updateAll(this.cdfSectionItems, "reachedMax", sect => {
                    if(sect.maxValue === 0) return false;
                    let sectionItems = _.filter(self.items, item => item.sectionCode === sect.id);
                    return sectionItems.length >= sect.maxValue;
                });
            },

            async save(item){
                const self = this;
                self.validationErrors = [];
                let apiPromise = self.$api.DefaultCDFLinesApi.save(item.toDataObject());

                try
                {
                    let item = await self.$rqBusy.wait(apiPromise);
                    self.$toast.success({ message: `${self.itemTypeName} ${item.description} was saved.` });
                    return item;
                }catch(err){
                    console.error(err);
                    self.$toast.error(`Error saving ${self.itemTypeNamePlural}.`);
                    return null;
                }
            },

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },
        }
    }
</script>