<template>
    <div class="content-wrapper  item-selection">
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            v-model:validation-errors="validationErrors"
            :strikethrough-if-true="['inactive']"
            @delete="onDeleteItem"
            @filter-change="onGridFilterChange"
            v-model:search-value="searchText"
            integrated-search
            :rq-editable="!readOnly"
            rq-filters
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { TaxRateDto }  from "../models";
    import { TaxType } from '../../enums';
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { REFRESH_LOOKUP } from "@/store/actions";

    export default {
        name:"TaxRateList",
        data () {
            return {
                items: [],
                selectedItem: {},
                validationErrors: [],
                addEventName: "",
                stateFilterValue: [],
                searchText: ""
            };
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user,
                readOnly: state => state.isPageReadOnly,
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            itemKey() { return _.get(this.$route.meta, "itemKey") || ""; },
            itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            selectionActions() {
                const self = this;
                return [{ name: "delete", text: "Delete", eventName: "delete", allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}`, disabled: function(e) { return self.readOnly;} }];
            },
            taxType() { return _.map(TaxType.lookupItems, r => ({ ...r, displayLabel: `${r.name}` })); },
            stateDataSource() { return this.lookupHelpers.getStates(); }
        },

        created(){
            const self = this;
            self.initGridConfig();
            self.initListeners();
            self.fetchData();           
        },

        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },
        watch: {
            validationErrors: function () {
                const self = this;
                self.$events.emit("update-config-error", { message: "Please correct the highlighted errors on screen to continue.", hasError: self.validationErrors.length > 0 });
            }
        },

        methods: {
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            initGridConfig(){
                const self = this;                

                self.gridConfig = {    
                    columns: [
                        DxGridUtils.dateColumn({
                            dataField: "effectiveDate",
                            required: true,
                            dataType: "date"
                        }),
                        {
                            dataField: "state",
                            dataType: "string",
                            lookup: {
                                displayExpr: "id",
                                valueExpr: "id",
                                dataSource: self.stateDataSource
                            },
                            setCellValue(rowData, value) {
                                rowData.state = value;
                                rowData.countyIDs = [];
                            },
                            validationRules: [{ type: "required" }]
                        },

                        {
                            dataField: "city",
                            dataType: "string",
                            editorOptions: {
                                maxLength: 50
                            },
                        },
                        {
                            dataField: "countyIDs",
                            dataType: "string",
                            caption: "County",
                            width: 200,
                            minWidth: 100,
                            calculateFilterExpression: (filterValue, operator) => {
                                if(_.isNil(operator))  return _.isString(filterValue)
                                    ? rowData => {
                                         let counties = self.lookupHelpers.getCountiesByState(rowData.state);
                                        let itemNames = _.map(rowData.countyIDs, id => _.find(counties, { id }).name);
                                        let displayText = _.joinParts(itemNames, ", ");
                                        return _.includes(_.toLower(displayText), _.toLower(filterValue));
                                    }
                                    : () => false;
                                    
                                const parseDelimitedIDs = ids => {
                                    let result = _.map(_.split(ids, ","), id => _.parseNumber(_.trim(id), null));
                                    return _.filter(result, id => !_.isNil(id));
                                };
                                return rowData => {
                                    let ids = parseDelimitedIDs(rowData.countyIDs);
                                    return _.includes(ids, filterValue);
                                };
                            },
                            cellTemplate(cellElement, cellInfo) {
                                let stateValue = cellInfo.data.state;
                                if(_.isEmpty(cellInfo.value) || _.isEmpty(stateValue)) return;
                                const searchHighlightTemplate = '<span class="dx-datagrid-search-text">$&</span>';

                                let counties = self.lookupHelpers.getCountiesByState(stateValue);
                                let itemNames = _.map(cellInfo.value, id => _.find(counties, { id }).name);
                                let displayText = _.joinParts(itemNames, ", ");
                                let displayTitle = itemNames.length === 1
                                    ? displayText
                                    : `${itemNames.length} Counties Selected`;

                                let searchHighlighted = _.isEmpty(_.trim(self.searchText)) ? displayText : _.replace(displayText, new RegExp(self.searchText, "ig"), searchHighlightTemplate);
                                $("<span />")
                                    .addClass("text-truncate")
                                    .attr("title", displayTitle)
                                    .append(searchHighlighted)
                                    .appendTo(cellElement);
                            },
                            rqFilter: {
                                displayExpr: "name",
                                valueExpr: "id",
                                dataSource: {
                                    load() {
                                        return _.isEmpty(self.stateFilterValue)
                                            ? []
                                            : self.lookupHelpers.getCountiesInStates(self.stateFilterValue);
                                    }
                                },
                                inputDisabled: () => _.isEmpty(self.stateFilterValue),
                                inputDisabledMessage: "State filter must be selected before filtering by county.",
                                filterType: "tags"
                            },
                            editCellTemplate(cellElement, cellInfo) {
                                let stateValue = cellInfo.data.state;
                                if(_.isEmpty(cellInfo.data.state)) {
                                    cellElement.append(`<span class="text-muted px-2">Select a state...</span>`);
                                    return;
                                }
                                $("<div />").dxTagBox({
                                    dataSource: {
                                        loadMode: "raw",
                                        load() {
                                            return self.lookupHelpers.getCountiesByState(stateValue);
                                        }
                                    },
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    value: cellInfo.value,
                                    showSelectionControls: true,
                                    showDropDownButton: true,
                                    searchEnabled: true,
                                    maxDisplayedTags: 1,
                                    onValueChanged(e) {
                                        cellInfo.setValue(e.value);
                                    }
                                }).appendTo(cellElement);
                            }
                        },
                        {
                            dataField: "taxType",
                            dataType: "number",
                            lookup: {
                                dataSource: TaxType.lookupItems,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            validationRules: [{ type: "required" }]
                        },
                        {
                            dataField: "taxRate",
                            dataType: "number",
                            cellTemplate: DxGridUtils.rateCellTemplate,
                            format: { type: "fixedPoint", precision: 3 },
                            editorOptions: { format: "##0.000" },
                            validationRules: [
                                { type: "required"},
                                { type: "range", min: 0, max: 100, message: "Invalid tax rate."}
                            ]
                        }
                    ],
                    onRowUpdating(e) {
                        e.cancel = new Promise((resolve, reject) => {
                            self.$dialog.confirm(
                                "Confirm Edit",
                                `Updating ${self.itemTypeNamePlural} will change existing files. Do you want to continue?`,
                                () => resolve(false), //continue with edit
                                () => resolve(true), //cancel edit
                                { cancelTitle: 'No', okTitle: 'Yes'});
                        });
                    }
                };
                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };                
            },
            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },
            fetchData() {
                const self = this;
                let apiPromise = self.$api.TaxRatesApi.getTaxRates();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$store.dispatch(REFRESH_LOOKUP, { lookupKeys: self.lookupItems.TAXRATES, lookupData: result });
                        self.items = _.map(result, i => new TaxRateDto(i));
                        self.parseInitialStateFilter();
                        self.gridInstance.refresh();
                        return result;
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },

            onAddItem() {
                if(!this.gridInstance) return;
                this.gridInstance.addRow();
            },

            onGridInsert(values) {
                const self = this;

                let originalItem = new TaxRateDto();
                let newItem = new TaxRateDto(values);
                let changes = self.getAuditChanges(originalItem.toDataObject(), newItem.toDataObject(), ["countyIDs"]);

                return self.save(newItem, changes)
                    .then(result => {
                        self.fetchData();
                    });
            },

            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item.taxRateID === key);
                if(itemIndex < 0) return self.onGridInsert(values);

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new TaxRateDto(_.assign({}, self.items[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                return self.save(updatedItem, changes)
                    .then(result => {
                        self.fetchData();
                    });
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 1
                    ? self.itemTypeNamePlural
                    : self.itemTypeName;

                let okHandler = function (args) {
                    let keys = _.map(items, self.itemKey);
                    self.delete(keys);
                    return true;
                }

                self.$dialog.confirm(
                    "Confirm Delete",
                    `Are you sure you want to delete the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            save(item, changes){
                const self = this;
                if (changes.length == 0) {
                    return Promise.resolve(item);
                }



                if (item.countyIDs.length === self.lookupHelpers.getCountiesByState(item.state).length){
                    item.countyIDs = [];
                    item.allCounties = 1;
                }else {
                    item.allCounties = 0;
                }
                let apiPromise = self.$api.TaxRatesApi.saveTaxRate(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$toast.success(`${self.itemTypeName} was saved.`);
                        return result;
                    }).catch(error => {
                        self.$toast.error(`Error save ${self.itemTypeName}.`);
                        return error;
                    });
            },

            delete(keys) {
                const self = this;
                let apiPromise = self.$api.TaxRatesApi.deleteTaxRates(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.removeItems(keys);
                        let message = keys.length > 1
                            ? `${keys.length} ${self.itemTypeNamePlural} were deleted.`
                            : `${self.itemTypeName} was deleted.`
                        self.$toast.success(message);
                        return true;
                    })
                    .catch(error => {
                        if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                            self.$dialog.messageBox(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                        } else {
                            self.$toast.error(`Error deleting ${self.itemTypeName}.`);
                        }
                        console.error(error);
                        return error;
                    });
            },

            itemExists(matchProp, matchValue, excludeProp=null, excludeValue=null) {
                const self = this;
                return _.some(self.items, item => {
                    if(_.isNil(excludeProp)) return item[matchProp] === matchValue;
                    return item[excludeProp] !== excludeValue && item[matchProp] === matchValue;
                });
            },

            removeItems(keys) {
                const self = this;
                _.forEach(keys, key => {
                    let itemIndex = _.findIndex(self.items, item => item[self.itemKey] === key);
                    if(itemIndex >= 0) self.items.splice(itemIndex, 1);
                });
                self.gridInstance.refresh();
            },

            parseInitialStateFilter() {
                let filterExpr = _.invoke(this, "$refs.dataGrid.gridInstance.option", "filterValue");
                this.parseStateFilter(filterExpr);
            },

            parseStateFilter(filterExpr) {
                let filterValue = [];
                let parseFilter = expr => {
                    _.forEach(expr, (item, index, arr) => {
                        if(_.isArray(item)) {
                            parseFilter(item);
                        }
                        else if(item === "state") {
                            filterValue.push(arr[2]);
                        }
                    });
                }
                parseFilter(filterExpr);
                this.stateFilterValue = filterValue;
            },

            onGridFilterChange(e) {
                this.parseStateFilter(e.filterExpr);
            }
        }
    }
</script>
