<template>
    <div class="content-wrapper">
        <rq-page-section :title="itemTypeNamePlural" borderless headerSize="lg">
            <template #header-actions>
                <ul class="nav config-actions ms-auto">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_cancel"
                            variant="action"
                            v-rq-tooltip.hover.top title="Discard changes and reload data"
                            @click="onCancel"
                            :disabled="readOnly">Cancel
                        </b-btn>
                    </li>
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_save"
                            variant="action"
                            @click="onSave"
                            :disabled="readOnly">Save
                        </b-btn>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
            <div class="row mb-1">
                <div class="col-auto ms-auto">
                    <rq-section-expand-collapse-all />
                </div>
            </div>
            <rq-section-card title="ezJacket (ORT Integration)" collapsible v-if="showEzJacketIntegration">
                <div class="row">
                    <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group form-required" :class="{ 'has-error': v$.systemDefaults.ezJacketUnderwriterCompanyID.$error }" v-rq-tooltip.hover.top="underwriterTooltip"
                    >
                        <label for="ezJacketUnderwriterCompanyID">Underwriter</label>
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_ezJacketUnderwriterCompanyID', id: 'drp_ezJacketUnderwriterCompanyID' }"
                            :items="underwriters"
                            value-expr="id"
                            display-expr="name"
                            v-model="v$.systemDefaults.ezJacketUnderwriterCompanyID.$model"
                            :search-enabled="true"
                            :disabled="readOnly || !user.isTenantAdmin"
                        />
                        <rq-validation-feedback>Underwriter is required.</rq-validation-feedback>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                        <b-form-checkbox automation_id="chk_ezJacketAllowEndorsementOnlyToggle" id="ezJacketAllowEndorsementOnlyToggle" v-model="systemDefaults.ezJacketAllowEndorsementOnlyToggle" :disabled="readOnly">Allow Endorsement Only Checkbox </b-form-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                        <b-form-checkbox automation_id="chk_ezJacketShowAllFieldsToggle" id="ezJacketShowAllFieldsToggle" v-model="systemDefaults.ezJacketShowAllFieldsToggle" :disabled="readOnly">Allow Show All Fields Checkbox </b-form-checkbox>
                    </div>
                </div>
            </rq-section-card>
         </rq-page-section>
    </div>
</template>
<script>
    import { mapGetters, mapState } from "vuex";
    import SystemDefaultsFormMixin from "../../shared/SystemDefaultsFormMixin";
    import { required } from '@vuelidate/validators';
    import { useVuelidate } from "@vuelidate/core";
    import { useLicenseStore } from "@/store/modules/license";

    export default {
        name: "PolicyJacketIntegrationForm",
        components: {
        },
        data() {
            return {
                showEzJacketIntegration: false
            }
        },
        setup: () => ({ v$: useVuelidate() }),
        mixins: [SystemDefaultsFormMixin],
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
            ]),
            ...mapState({
                readOnly: state => state.isPageReadOnly,
                user: state => state.authentication.session.user,
            }),
            underwriters() { return this.lookupHelpers.getUnderwriters(1); },
            underwriterTooltip() {
                return this.user.isTenantAdmin ? {} : {title: `Only Ramquest Support Admins can change this.`};
            },
        },
        validations() {
            var result = {
                systemDefaults: {
                    ezJacketUnderwriterCompanyID: {
                        required
                    },
                }
            }
            return result;
        },
        created() {
            const self = this;
            const licenseStore = useLicenseStore();
            self.showEzJacketIntegration = licenseStore.checkFeature('ezJacket');
        }
    }
</script>
