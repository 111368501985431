<template>
    <div class="content-wrapper workflow-tasks">
        <rq-page-section title="Workflow Tasks" header-size="lg" header-only borderless>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">Configure and define workflow task and task packages.</div>
            </template>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <div class="dropdown rq-section-dropdown">
                            <button class="btn btn-theme dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Add</button>
                            <ul class="dropdown-menu">
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="btn_frm_file" @click="onAddTaskClicked">Task</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="btn_marketplace" @click="onAddPackageClicked">Task Package</button>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </template>
        </rq-page-section>
        <RqTabs
            :tabs="tabItems"
            v-model="activeTabIndex"
            >
            <template #tasks>
                <workflow-tasks-list
                    :ref="tabComponentRefs[tabIndex.Tasks]"
                />
            </template>
            <template #packages>
                <workflow-task-packages-list
                    :ref="tabComponentRefs[tabIndex.Packages]"
                />
            </template>
        </RqTabs>
    </div>
</template>
<script>
    import { ref } from "vue";
    import { GlobalEventManager } from "@/app.events";
    import { mapGetters } from "vuex";
    import WorkflowTasksList  from "./WorkflowTasksList.vue";
    import WorkflowTaskPackagesList  from "./WorkflowTaskPackagesList.vue";
    import { useRqTabAlerts } from "@/shared/composables/useRqTabAlerts";
    import { useLicenseStore } from "@/store/modules/license";
    import { computed } from "vue";

    const TabIndex = {
        Tasks: 0,
        Packages: 1,
    }

    export default {
        components: {
            WorkflowTasksList,
            WorkflowTaskPackagesList
        },
        setup() {

        },

        data() {
            return {
                activeTabIndex: 0,
                tabComponentRefs: [
                    "workflowTasksList",
                    "workflowTaskPackagesList"
                ],
                tabIndex: TabIndex
            };
        },

        computed:{
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            activeTabComponent() { return _.get(this, `$refs.${this.tabComponentRefs[this.activeTabIndex]}`, null); },

            tabItems() {
                return [
                    { automation_id: "btn_tab_workflow_tasks", title: "Tasks", name: "tasks" },
                    { automation_id: "btn_tab_worfklow_task_packages", title: "Packages", name: "packages" },
                ]
            },
        },

        watch: {
            activeTabIndex(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.$nextTick(() => {
                    _.invoke(this, `$refs.${this.tabComponentRefs[newValue]}.updateDimensions`);
                });
            },
        },

        beforeUnmount() {
            GlobalEventManager.unregister(this);
        },


        methods:{

            onAddTaskClicked(e){
                _.invoke(this, `$refs.${this.tabComponentRefs[0]}.onAddItem`);
            },

            onAddPackageClicked(e){
                _.invoke(this, `$refs.${this.tabComponentRefs[1]}.onAddItem`);
            },
        }
    };
</script>
