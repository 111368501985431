<template>
    <rq-section-card v-show="sectionVisible" :title="label" collapsible>
        <div class="row">
                <div class="col-2 col-sm-6">
                <!-- <div class="row" v-show="fieldVisible('functionalDescription')">
                    <div class="col-7 drpdwnColumn form-group">
                        <label for="drp_functionRestrict">Functional Description</label>
                        <rq-select-box
                            id="drp_functionRestrict"
                            :items="functionRestrict"
                            v-model="securityData.userGroupSecurity.functionRestrict"
                        />
                    </div>
                </div> -->
                <div class="row" v-show="fieldVisible('duplicateFileChecking')">
                    <div class="col-7 drpdwnColumn form-group">
                        <label for="drp_duplicateFileChecking">Duplicate File Search</label>
                        <rq-select-box
                            id="drp_duplicateFileChecking"
                            :items="duplicateSearchOptions"
                            v-model="securityData.userGroupSecurity.duplicateOrderSearchSetupID"                            
                        />
                    </div>
                </div>
                <div class="row" v-show="fieldVisible('fileNotesSecurity')">
                    <div class="col-7 drpdwnColumn form-group">
                        <label for="drp_fileNotesSecurity">File Notes Security</label>
                        <rq-select-box
                            id="drp_fileNotesSecurity"
                            :items="fileNoteSecurityOptions"
                            :default-item-enabled="false"
                            v-model="securityData.userGroupSecurity.orderNotesSecurity"
                            :disabled="isFileNotesSecurityReadOnly"
                           
                        />
                    </div>
                </div>               
            </div>
        </div>
    </rq-section-card>
</template>
<script>
    import { SystemLookupItemDto } from "@/shared/models/models";
    import SecurityRoleSectionMixin from "./SecurityRoleSectionMixin";
    import { FunctionRestrict } from "@config/enums";

    export default {
        name: "GeneralSection",
        mixins: [SecurityRoleSectionMixin],
        data() {
            return {
                sectionFields: [
                    // { label: "Functional Description", name: "functionalDescription", category:  "" },
                    { label: "Duplicate File Checking", name: "duplicateFileChecking", category:  "" },
                    { label: "File Notes Security", name: "fileNotesSecurity", category:  "" },                    
                ],
                duplicateSearchOptions: [],
                fileNoteSecurityOptions: [
                    { id: 2, name: "Can change/delete any notes" },
                    { id: 1, name: "Can change/delete any notes owned by user" },
                    { id: 0, name: "Cannot change/delete any notes" }
                ]
            };
        },
        computed: {
            functionRestrict() { return FunctionRestrict.lookupItems; },                
            isFileNotesSecurityReadOnly(){ return this.isSysAdmin || this.isAdmin; }       
        },
        created() {
            this.fetchData();
        },
        watch: {
            isSysAdmin: function(newValue){
                this.setDefaultValues();
            }, 
            isEscrowAdmin: function(newValue){
                this.setDefaultValues();
            },
            isAdmin: function(newValue){
                this.setDefaultValues();
            }
        },
        methods: {
            setDefaultValues(){
                const self = this;
                if(self.isEscrowAdmin){
                    self.securityData.userGroupSecurity.duplicateOrderSearchSetupID = 0; // Non selected
                    self.securityData.userGroupSecurity.orderNotesSecurity = 1; //
                }
                if(self.isSysAdmin || this.isAdmin){
                    self.securityData.userGroupSecurity.duplicateOrderSearchSetupID = null;
                    self.securityData.userGroupSecurity.orderNotesSecurity = 2;
                }
            },
            fetchData(groupUsersID) {
                const self = this;
                let apiPromise =self.$api.DuplicateSearchApi.getDuplicateSearchSetups();
                self.$rqBusy.wait(apiPromise)
                    .then(results => {
                        self.duplicateSearchOptions = _.map(results, r => new SystemLookupItemDto({
                            id: r.duplicateOrderSearchSetupID,
                            name: r.description
                        }));
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.entityDescription}.` });
                    });
            }
        }
    }
</script>