export class WorkflowDashboardSettingsDto {
	constructor(options) {
        options = options || {};
        this.showTasksForClosedOrdersInDashboard = options.showTasksForClosedOrdersInDashboard ;
        this.showTasksWithNoDueDateInDashboard = options.showTasksWithNoDueDateInDashboard ;
        this.fileOpenDate = options.fileOpenDate || null;
        this.customDate = options.customDate || null;
        this.activeDepartments = options.activeDepartments;
        this.excludedDepartments = options.excludedDepartments;
        this.includedDepartments = options.includedDepartments;
    }
}

export class WorkflowDepartmentDto {
	constructor(options) {
        options = options || {};
        this.workflowDepartmentID = _.parseNumber(options.workflowDepartmentID, 0);
        this.departmentName = options.departmentName || null;
        this.description = options.description || null;
        this.inactiveYN = _.parseBool(options.inactiveYN);
        this.regionID = options.regionID; // only needed for the display of data
        this.lastModified = options.lastModified || null;
        this.lastModifiedBy = options.lastModifiedBy || null;
        this.excludeFromDashboard = _.parseBool(options.excludeFromDashboard);
        this.members = (options.members && _.map(options.members, m => new WorkflowDepartmentUserDto(m))) || [];
    }

    get isNew() { return this.workflowDepartmentID === 0; }

    toDataObject() {
        let result = _.toPlainObject(this);
        _.omit(result, "members");
        result.members = _.map(this.members, _.toPlainObject);
        return result;
    }
}

export class WorkflowDepartmentUserDto {
    constructor(options) {
        options = options || {};
        this.workflowDepartmentUserID = _.parseNumber(options.workflowDepartmentUserID, 0);
        this.workflowDepartmentID = _.parseNumber(options.workflowDepartmentID, 0);
        this.usersID = _.parseNumber(options.usersID, 0);
        this.hasSupervisorPrivilegeYN = _.parseBool(options.hasSupervisorPrivilegeYN);
        this.inactive = _.parseBool(options.inactive);
        this.workflowDashboardLastViewDate = options.workflowDashboardLastViewDate || null;
        this.departmentName = options.departmentName || "";
        this.login = options.login || "";
        this.fullName = options.fullName || "";
        this.displayName = options.displayName || "";
    }
}

export class WorkflowTaskPackageDto {
    constructor(options) {
        options = options || {};
        this.workflowTaskPackageCategoryID = _.parseNumber(options.workflowTaskPackageCategoryID, 0);
        this.workflowTaskPackageID = _.parseNumber(options.workflowTaskPackageID, 0);
        this.workflowTaskPackageName = options.workflowTaskPackageName || null;
        this.workflowTaskID = options.workflowTaskID || null;
        this.categoryName = options.categoryName || null;
        this.numberOfTasks = _.parseNumber(options.numberOfTasks, 0);
        this.isInactive =  _.parseBool(options.isInactive,false);
    }

    toDataObject() { return _.toPlainObject(this); }
}



export class WorkflowTaskPackageCategoryDto {
    constructor(options) {
        options = options || {};
        this.workflowTaskPackageCategoryID = _.parseNumber(options.workflowTaskPackageCategoryID, 0);
        this.categoryName = options.categoryName || null;
        this.description = options.description || null;
        this.isInactive =  _.parseBool(options.isInactive,false);
    }
}


export class WorkflowTasksDto {
    constructor(options) {
            options = options || {};
            this.workflowTaskID =  _.parseNumber(options.workflowTaskID,0);
            this.assignedPerson =  _.parseNumber(options.assignedPerson,0);
            this.autoEmailYN =  _.parseBool(options.autoEmailYN,false);
            this.closeTaskUserEventID =  _.parseNumber(options.closeTaskUserEventID,0);
            this.durationDays =  _.parseNumber(options.durationDays,0);
            this.durationHours =  _.parseNumber(options.durationHours,0);
            this.isInActive =  _.parseBool(options.isInActive,false);
            this.isPredefinedTask =  _.parseBool(options.isPredefinedTask,false);
            this.publishYN =  _.parseBool(options.publishYN,false);
            this.regionID =  _.parseNumber(options.regionID,0);
            this.sequence =  _.parseNumber(options.sequence,0);
            this.startAfterWorkflowTaskID =  _.parseNumber(options.startAfterWorkflowTaskID,0);
            this.startLagDays =  _.parseNumber(options.startLagDays,0);
            this.startLagHours =  _.parseNumber(options.startLagHours,0);
            this.taskClosedSystemEventID =  _.parseNumber(options.taskClosedSystemEventID,0);
            this.taskName =  options.taskName || null;
            this.workflowCompanyServiceID =  _.parseNumber(options.workflowCompanyServiceID,0);
            this.workflowDepartmentID =  _.parseNumber(options.workflowDepartmentID,0);
            this.workflowServiceCategoryID =  _.parseNumber(options.workflowServiceCategoryID,0);
            this.workflowTaskBasedOnID =  _.parseNumber(options.workflowTaskBasedOnID,0);
            //dto specific properties
            this.regionDisplay = options.regionDisplay ||null;
            this.workflowTaskBasedOnDisplay  = options.workflowTaskBasedOnDisplay ||null;
            this.preceedingTaskDisplay  = options.preceedingTaskDisplay ||null;
            this.departmentName  = options.departmentName ||null;
            this.assignedPersonDisplay  = options.assignedPersonDisplay ||null;
            this.taskClosedSystemEventDisplay  = options.taskClosedSystemEventDisplay ||null;
            this.closeTaskUserEventDisplay  = options.closeTaskUserEventDisplay ||null;
            this.isDeleted =  _.parseBool(options.isDeleted,false);
            this.updateOrderWorkflow =  _.parseBool(options.updateOrderWorkflow,false);
            this.inUseOrder =  _.parseBool(options.inUseOrder,false);
            this.inUseFileScan =  _.parseBool(options.inUseFileScan,false);
            this.inUseStandardLng =  _.parseBool(options.inUseStandardLng,false);
            this.inUseTemplate =  _.parseBool(options.inUseTemplate,false);
            //Closing market fields
            this.cmAuto = _.parseBool(options.cmAuto, false);
            this.cmOrderCategoryID = _.parseInt(options.cmOrderCategoryID, 0);
            this.cmEventCodeID = _.parseInt(options.cmEventCodeID, 0);
            this.cmEnterpriseServiceID = _.parseInt(options.cmEnterpriseServiceID, 0);
            this.cmEventCodeDisplay = options.cmEventCodeDisplay || null;
            this.cmEnterpriseServiceDisplay = options.cmEnterpriseServiceDisplay || null;
            this.cmOrderCategoryDisplay = options.cmOrderCategoryDisplay || null;
            this.notes = options.notes || null;
        }

        get isNew() { return this.workflowTaskID == 0; }

        get isValid() { return this.validationErrors.length === 0; }

        get validationErrors() {
            let errorList = [];
            if (_.isEmpty(this.taskName)) {
                errorList.push("Task Name is required.");
            }

            if (this.regionID == 0) {
                errorList.push("Region is required.");
            }

            return errorList;
        }
        get verifiedItem() {
            let errorObject = {};
            if (_.isEmpty(this.taskName)) {
                errorObject.requiredTaskName = true;
            }

            if (this.regionID == 0) {
                errorObject.requiredRegion = true;
            }

            return errorObject;
        }

        get afterClose()
        {
            return (this.taskClosedSystemEventID > 0) ? true : false;
        }

        set afterClose(val)
        {
            this.taskClosedSystemEventID = (val) ? 1 : 0;
            this.taskClosedSystemEventDisplay = (val) ? "Lock File" : "";
        }

        get closeWhen()
        {
            return (this.closeTaskUserEventID > 0) ? true : false;
        }

        set closeWhen(val)
        {
            this.closeTaskUserEventID = (val) ? 1 : 0;
            this.closeTaskUserEventDisplay = (val) ? "HUD is Final" : "";
        }

        get hasNotes() { return _.size(_.trim(this.notes)) > 0; }

        toDataObject() { return _.toPlainObject(this); }
}

export class WorkflowProcessTemplateDto {
	constructor(options) {
        options = options || {};
        this.workflowProcessTemplateID = _.parseNumber(options.workflowProcessTemplateID, 0);
        this.processTemplateName = options.processTemplateName || null;
        this.regionID = _.parseNumber(options.regionID, 0);
        this.regionDisplay = options.regionDisplay || null;
        this.gfNo = options.gfNo || null;
        this.lastModified = options.lastModified || null;
        this.lastModifiedBy = options.lastModifiedBy || null;
        this.ordersID = _.parseNumber(options.ordersID, null);
        this.canEdit = _.parseBool(options.canEdit, false);
        this.canDelete = _.parseBool(options.canDelete, false);
        this.openOrderCount = _.parseNumber(options.openOrderCount, 0);
        this.defaultSSType = _.parseNumber(options.defaultSSType, null);
        this.includeAlta = _.parseBool(options.includeAlta);
        this.isInActive = _.parseBool(options.isInActive, false);
        this.isWithOutSeller = _.parseBool(options.isWithOutSeller);
    }

    get isNew() { return this.workflowProcessTemplateID === 0; }

    toDataObject() { return _.toPlainObject(this); }
}

export class WorkflowProcessTemplateDataDto {
    constructor(options) {
        options = options || {};
        this.workflowProcessTemplate = new WorkflowProcessTemplateDto(options.workflowProcessTemplate);
        this.documentTemplateIDs = options.documentTemplateIDs || [];
        this.documentPackageIDs = options.documentPackageIDs || [];
        this.workflowTaskIDs = options.workflowTaskIDs || [];
    }
}

export class WorkflowTaskShortDto {
    constructor(options) {
        options = options || {};
        this.workflowTaskID = _.parseNumber(options.workflowTaskID, null);
        this.taskName = options.taskName || null;
        this.workflowDepartmentName = options.workflowDepartmentName || null;
        this.sequence = _.parseNumber(options.sequence, 0);
        this.description = options.taskName || null;
    }
}

export class DocumentPackageShortDto {
    constructor(options) {
        options = options || {};
        this.documentPackageID = _.parseNumber(options.documentPackageID, 0);
        this.description = options.description || null;
    }
}
