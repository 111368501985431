<template>
    <rqdx-action-data-grid
        ref="dataGrid"
        :automation_id="elementName('tbl')"
        :actions="selectionActions"
        :config="gridConfig"
        :data-source="gridDataSource"
        :export-file-name="elementName('', 'data')"
        @edit="onEditItem"
        @delete="onDeleteItem"
        @rowDoubleClick="onEditItem"
        integrated-search
        rq-filters
    />
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { ORTEpicorBankCodeDto }  from "../models";
    import EpicorBankCodesForm  from "./EpicorBankCodesForm.vue";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { REFRESH_LOOKUP } from "@/store/actions";

    export default {
        name:"EpicorBankCodesList",
        data () {
            return {
                items: [],
                addEventName: ""
            };
        },
        created(){
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.initListeners();
        },
        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            itemKey() { return _.get(this.$route.meta, "itemKey") || ""; },
            itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            itemTypeDescription() { return _.get(this.$route.meta, "itemTypeDescription") || ""; },
            selectionActions() {
                return [{ name: "edit", text: "Edit", eventName: "edit", requireSelection: true, tooltip: `Edit ${this.itemTypeName}` },
                        { name: "delete", text: "Delete", eventName: "delete", requireSelection: true, allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}` }];
            }
        },
        methods: {
            initNonReactiveVariables() {
                const self = this;
                self.regions = self.lookupHelpers.getRegions();
                self.escrowBanks = self.lookupHelpers.getLookupItems(self.lookupItems.ESCROW_ACCOUNTS);
            },

            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },

            addNewItem() {
                const self = this;
                self.loadItem();
            },

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            onAddItem() {
                this.addNewItem();
            },

            onEditItem(e) {
                const self = this;
                self.loadItem(e.data);
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let apiPromises = [];
                    _.forEach(items, (item) => {
                        let key = _.get(item, self.itemKey);
                        apiPromises.push(self.$api.EpicorBankCodesApi.deleteEpicorBankCode(key));
                    });
                    return self.$rqBusy.wait(Promise.all(apiPromises))
                        .then(key => {
                            self.deleteItem([...key]);
                            let message = key.length > 1 ? `${key.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success({ message: message });
                            return true;}
                        )
                        .catch(error => {
                            if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                self.$dialog.messageBox(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            }
                            return error;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }

                 self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            fetchData() {
                const self = this;
                let apiPromise = self.$api.EpicorBankCodesApi.getEpicorBankCodes();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$store.dispatch(REFRESH_LOOKUP, { lookupKeys: self.lookupItems.REGIONS, lookupData: result });
                        self.$store.dispatch(REFRESH_LOOKUP, { lookupKeys: self.lookupItems.BRANCHES });
                        let items = _.map(result, i => new ORTEpicorBankCodeDto(i));
                        self.items = items;
                        return { data: items, totalCount: items.length };
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "ortEpicorBankCodeID",
                            dataType: "number",
                            width: 75,
                            sortIndex: 0,
                            sortOrder: "asc",
                            visible: false,
                            showInColumnChooser: false
                        },
                        {
                            dataField: "regionID",
                            dataType: "number",
                            caption: "Region",
                            lookup: {
                                dataSource: self.regions,
                                displayExpr: "displayName",
                                valueExpr: "regionID",
                            }
                        },
                        /*{
                            dataField: "bankCompanyID",
                            caption: "Escrow Account",
                            lookup: {
                                dataSource: self.escrowBanks,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                        },*/
                        {
                            dataField: "bankCode",
                            dataType: "number",
                            caption: "Bank Code"
                        },
                        {
                            dataField: "mainBranch",
                            dataType: "string",
                            caption: "Main Branch"
                        },
                        {
                            dataField: "off2",
                            dataType: "int",
                            caption: "Off2" 
                        }
                    ],
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load: self.fetchData,
                };
            },

            loadItem(itemData=null) {
                const self = this;
                let refreshOnExit = false;
                let dialogId = null;
                let onOk = (e, addAnother=false) => {
                    return e.component.save()
                        .then(result => {
                            if (_.isNil(result.isValid)) return true;
                            if (!result.isValid) return false;
                            if(item.isNew)
                                self.addItem(result.item);
                            else
                                self.updateItem(result.item);
                            if (addAnother) {
                                self.$dialog.reloadComponent({
                                    dialogId,
                                    props: { item: new ORTEpicorBankCodeDto() }
                                });                               
                                self.refresh();
                                return false;
                            }
                            self.$nextTick(() => {
                                self.refresh();
                            });
                            return true;
                        });
                };
                let onCancel = e => {
                    if(!refreshOnExit) return;
                    self.refresh();
                };

                let item = new ORTEpicorBankCodeDto(itemData);
                dialogId = self.$dialog.open({
                    title: `${(item.isNew ? "Add": "Edit")} ${self.itemTypeName}`,
                    width: 700,
                    height: "auto",
                    adaptive: true,
                    closeOnEsc: true,
                    component: EpicorBankCodesForm,
                    props: {
                        item,
                        uniqueValidator: (item,field) => !self.isDuplicate(item, field)
                    },
                    buttons: [
                        { title: "Cancel", automationId: "btn_dm_modal_cancel", cssClass: "btn btn-secondary", onClick: onCancel },
                        { title: "Save and Add Another", automationId: "btn_dm_modal_save_and_another", cssClass: "btn btn-primary", isVisible: item.isNew, onClick: (e) => { return onOk(e, true); } },
                        { title: "Save", automationId: "btn_dm_modal_save", cssClass: "btn btn-primary", onClick: (e) => { return onOk(e, false); } }
                    ]
                });
            },

            isDuplicate(item, fieldName){
                const self = this;
                let trimLower = val => _.toLower(_.trim(val));
                return _.some(self.items, (i) =>
                    trimLower(i[fieldName]) === trimLower(item[fieldName])
                    && _.parseNumber(i[self.itemKey], -1) !== _.parseNumber(item[self.itemKey], -1)
                );
            },

            refresh() {
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },

            shortenCellTemplate(cellElement, cellInfo, length){
                let shortenedValue = `${cellInfo.value}`.substring(0, length);
                let toolTip = `${cellInfo.value}`;
                $("<span>")
                    .attr("title", toolTip)
                    .text(shortenedValue)
                    .appendTo(cellElement);
            },

            addItem(item) {
                this.items.push(new ORTEpicorBankCodeDto(item));
            },

            deleteItem(keys) {
                _.each(keys, k => {
                    _.remove(this.items, (i) => {return _.parseNumber(_.get(i, this.itemKey, -1), -1) == k;});
                });
            },

            updateItem(item) {
                let editIem = _.find(this.items, (i) => {
                    return _.parseNumber(_.get(i, this.itemKey, -1), -1) == _.parseNumber(_.get(item, this.itemKey, -1), -1);
                });
                _.assign(editIem, item);
            }
        }
    }
</script>
